import { Col, Row, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { PageProtected } from '../../../common/components/PageProtected'
import { MainTheme } from '../../../common/theme/MainTheme'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { BlogsByTagInfiniteList } from './components/BlogsByTagInfiniteList'
import { useEffect, useState } from 'react'
import { HomeCarrousel } from '../../../common/components/HomeCarrousel'
import { CardCourse } from '../blog/components/CardCourse'
import { IconSchool } from '../../../common/assets/svg/icons'
import { useGetOffers } from '../../../bussines/hooks/offer/useGetOffers'
import { BlogFooter } from './components/BlogFooter'
const { Title } = Typography
const URL_BLOGS_BY_TAG_PAGE = '/blogs/:tag/list'
const URL_BLOGS_SEARCH_PAGE = '/blogs/:search/results'
const URL_BLOGS_SEARCH_V2_PAGE = '/blogs/search'

function PageBlogs () {
  const { tag, search } = useParams()
  const [title, setTitle] = useState()
  const { data: offers } = useGetOffers({ status: 'Published', distinctCourse: true, limit: 8 })
  useEffect(() => {
    setTitle(tag ?? search)
  }, [tag, search])
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
    >
      <MainTheme>
        <div className='margin-bloglist'>
          <div className='byp-align-centered'>
            {/* Header */}
            <Row style={{ width: '100%', marginBottom: '1rem' }}>
              <Col className='byp-centered'>
                <span
                  style={{ marginRight: '1rem', cursor: 'pointer' }}
                  onClick={() => window.history.back()}
                >
                  <ArrowLeftOutlined style={{ width: '1rem' }} />
                </span>
              </Col>
              <Col>
                <Title style={{ textTransform: 'capitalize', marginBottom: 0 }}>{title}</Title>
              </Col>
            </Row>
            <BlogsByTagInfiniteList
              tag={tag}
              search={search}
              onSearch={(value) => {
                if (!tag) {
                  setTitle(value)
                }
              }}
            />
            {/* <BlogsByTagList tag={tag} /> */}
          </div>
          <h4 style={{ color: '#373737' }} className='title-with-icon'><IconSchool />Nuestra recomendación de cursos</h4>
          <p style={{ color: '#797979' }}>Descubre el mundo estudiando</p>
          <HomeCarrousel slides={offers?.data ?? []} Slide={CardCourse} className='carrousel_height' />

          <BlogFooter />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { PageBlogs, URL_BLOGS_BY_TAG_PAGE, URL_BLOGS_SEARCH_PAGE, URL_BLOGS_SEARCH_V2_PAGE }
