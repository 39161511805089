import { useEffect, useState } from 'react'
import YouTube from 'react-youtube'
import { VimeoEmbed } from '../../../../common/components/VimeoEmbed'

const BlockVideo = ({ item }) => {
  const [videoId, setVideoId] = useState()
  const [vimeoId, setVimeoId] = useState()
  // console.log('🚀 ~ file: BlockVideo.js:2 ~ BlockVideo ~ item:', item)
  useEffect(() => {
    if (item) {
      if (item.body) {
        const isYoutube = item.body.includes('youtube')
        const isVimeo = item.body.includes('vimeo')
        if (isYoutube) {
          const id = item.body?.split('v=')[1].split('&')[0]
          setVideoId(id)
        } else if (isVimeo) {
          setVimeoId(item.body)
        }
      }
    }
  }, [item])

  if (!videoId && !vimeoId) {
    return null
  }

  return (
    <div className='w-100'>
      {videoId && (
        <YouTube
          videoId={videoId}
          style={{
            width: '100%',
            marginBottom: '1rem'
          }}
          iframeClassName='youtube-w-full'
        />
      )}
      {vimeoId && (
        <div style={{ marginBottom: '1rem' }}>
          <VimeoEmbed
            videoID={vimeoId}
          />
        </div>
      )}
    </div>
  )
}

export { BlockVideo }
