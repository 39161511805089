import { Button, Col, Form, Input, InputNumber, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { PrefixLabelSelector } from './PrefixLabelFormItem'
import { AddAndRemoveListButtons } from './AddAndRemoveListButtons'
import { useEffect, useState } from 'react'
import { SelectTimeFrequencyFormItem } from '../../../../common/components/helper-form/SelectTimeFrequencyFormItem'

function ConceptCostFormItem ({ name, divisa, labelAdd, labelDelete, withOutDelete, program }) {
  const arrayName = name
  const prefixSelector = <PrefixLabelSelector divisa={divisa} />

  const [frequencies, setFrequencies] = useState({ unic: true, day: true, week: true, month: true })

  useEffect(() => {
    if (program === 'graduate') {
      setFrequencies({ unic: true, semester: true, week: true, year: true })
    } else if (program === 'academic_years' || program === 'boarding_schools') {
      setFrequencies({ unic: true, semester: true, year: true })
    } else {
      setFrequencies({ unic: true, day: true, week: true, month: true })
    }
  }, [program])

  return (
    <div>
      <Form.List
        name={arrayName}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, isListField, fieldKey, ...restField }, index) => (
              <Row key={key} gutter={8}>
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                  >
                    <Input
                      className='byp-input'
                      placeholder='Name'
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, 'cost']}
                    >
                      <InputNumber addonBefore={prefixSelector} min={0} />
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={8}>
                  <SelectTimeFrequencyFormItem name={[name, 'frequency']} frequencies={frequencies} />
                </Col>
                <Col span={24}>
                  <Form.Item
                    name={[name, 'description']}
                  >
                    <Input type='text' maxLength={50} placeholder='Description' />
                  </Form.Item>
                </Col>
                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                  labelAdd={labelAdd}
                  labelDelete={labelDelete}
                  withOutDelete={withOutDelete}
                />
              </Row>
            ))}
            {fields.length === 0 && (
              <Form.Item>
                <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                  Add
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </div>
  )
}

export { ConceptCostFormItem }
