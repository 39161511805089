import api from '../../services/api'

class UserRepository {
  async about () {
    const response = await api.get('/user')
    return response.data
  }

  async getUser (params) {
    const response = await api.get('/users', { params })
    return response.data
  }

  async findUser (id) {
    const response = await api.get(`/users/${id}`)
    return response.data
  }

  async findUserByAgency (id) {
    const response = await api.get(`/users_agency/${id}`)
    return response.data
  }

  async acceptPolicy (args) {
    const response = await api.post('/policy', args)
    return response.data
  }

  async getPolicy () {
    const response = await api.get('/policy')
    return response.data
  }

  async findPolicy (name) {
    const response = await api.get('/policy/' + name + '/find')
    return response.data
  }

  async resendEmailVerification () {
    const response = await api.post('/email/resend')
    return response.data
  }

  async validateEmailServer (email) {
    const response = await api.get(`/email/validate?email=${email}`)
    return response.data
  }

  async update ({ id, data }) {
    const response = await api.put(`/users/${id}`, data)
    return response.data
  }

  async active ({ id, userIdToPassQuotes, status }) {
    const response = await api.put(`/users/${id}/status`, { userIdToPassQuotes, status })
    return response.data
  }

  async delete ({ id }) {
    const response = await api.delete(`/users/${id}`)
    return response.data
  }

  async updateProfile (data) {
    const response = await api.put('/user/profile/update', data)
    return response.data
  }

  async updateMyProfilePicture (url) {
    const response = await api.put('/user/update/avatar', { url })
    return response.data
  }

  async find () {
    const response = await api.get('/users/profile')
    return response.data
  }

  async getInfoStep () {
    const response = await api.get('/user/steps')
    return response.data
  }

  async postDeleteLogo () {
    const response = await api.post('/user/delete/logo')
    return response.data
  }

  async continueRegistration (args) {
    const response = await api.post('/continue-registration', args)
    return response.data
  }

  async roles (params) {
    const response = await api.get('/roles', { params })
    return response.data
  }

  async rolesBySede (sede) {
    const response = await api.get('/roles/' + sede)
    return response.data
  }

  async quotesInfo (user, filterQuery) {
    const response = await api.get(`/dashboard/users/${user}/quotes?${filterQuery}`)
    return response.data
  }

  async quotesStatistics (filterQuery) {
    const response = await api.get(`/dashboard/statistics/quotes?${filterQuery}`)
    return response.data
  }

  async quotesStatisticsByProgram (filterQuery) {
    const response = await api.get(`/dashboard/statistics/quotes?${filterQuery}`)
    return response.data
  }

  async usersByRolesAndSede (role, sede) {
    const response = await api.get('/roles/' + role + '/' + sede)
    return response.data
  }

  // invitations related functions
  async getUsersAndInvitations (filters) {
    const response = await api.get('/user-invitations', { params: filters })
    return response.data
  }

  async getUsersAndInvitationsPaginated (filters) {
    const response = await api.get('/user-and-invitations', { params: filters })
    return response.data
  }

  async saveInvitation ({ data }) {
    const response = await api.post('/user-invitations', data)
    return response.data
  }

  async findInvitation (id) {
    const response = await api.get(`/invitations/${id}`)
    return response.data
  }

  async updateInvitation ({ id, data }) {
    const response = await api.put(`/invitations/${id}`, data)
    return response.data
  }

  async updateAndSendInvitation ({ id, data }) {
    const response = await api.put(`/invitations/${id}/send`, data)
    return response.data
  }

  async deleteInvitation ({ id }) {
    const response = await api.delete(`/invitations/${id}`)
    return response.data
  }

  async sendContactUsData (data) {
    const response = await api.post('/contact-us', data)
    return response.data
  }

  async updatePassword (data) {
    const response = await api.put('/update-password', data.data)
    return response
  }

  async getInstitutionByProgram (program) {
    const response = await api.get(`/get/institutions/${program}`)
    return response.data
  }

  async getCampusByInstitutionAndProgram (program, institution, type, isHome) {
    const response = await api.get(`/get/campus/${program}/${institution}/${type}/${isHome}`)
    return response.data
  }

  async registerApplyBoardUser ({ data }) {
    const response = await api.post('/register-user-ab', data)
    return response.data
  }

  async registerApplyBoardAgency ({ data }) {
    const response = await api.post('/registerab', data)
    return response.data
  }

  async getTotalQuotes (filterQuery) {
    const response = await api.get('/dashboard/statistics/quotes', {
      params: {
        ...filterQuery
      }
    })
    return response.data
  }

  async getNotifications (filterQuery) {
    const response = await api.get('/notifications', {
      params: {
        ...filterQuery
      }
    })
    return response.data
  }
}

export default new UserRepository()
