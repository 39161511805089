import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useGetRoles } from '../../../bussines/hooks/user/useGetRoles'
import { useAuth } from '../../../providers/auth/auth-context-provider'
const { Option } = Select

const rolesOrderAgency = ['agency-admin', 'agency-editor', 'agency-office-manager', 'agency-manager', 'agency-consultant']
let canAddAdmin = false
export function SelectRolFormItem ({ name, type, noStyle, showAll, filters, ...props }) {
  const { user: _user } = useAuth()
  const { data: roles } = useGetRoles(filters)
  const [orderRoles, setOrderRoles] = useState()

  useEffect(() => {
    setOrderRoles(roles)
    let orderedData = []
    if (type === 'Agency') {
      orderedData = roles?.sort((a, b) => {
        return rolesOrderAgency.indexOf(a.name) - rolesOrderAgency.indexOf(b.name)
      })
      setOrderRoles(orderedData)
    }

    const rolesUser = _user?.role_names ?? []
    if (rolesUser.includes('agency-admin')) {
      canAddAdmin = true
    }
  }, [roles])

  useEffect(() => {

  }, [roles, _user])

  return (
    <Form.Item
      name={name}
      {...props}
      noStyle={noStyle}
    >
      <Select {...props} defaultActiveFirstOption>
        {showAll && <Option value={null}>All Roles</Option>}
        {orderRoles?.map((role) => (
          <Option key={role.id} disabled={role.name === 'agency-admin' ? !canAddAdmin : false} value={role.id}>{role.label}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}
