import { Modal, Switch, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetDataPrograms, usePutSavePage } from '../../../../bussines/hooks/tables-page/useGetDataTablePage'
import { URL_EDIT_PAGE_PROGRAM_PAGE } from '../../edit-page/EditPageProgramPage'
import { Link } from 'react-router-dom'

const pageTypes = [
  {
    name: 'graduate-diploma',
    label: 'Diploma'
  },
  {
    name: 'bachelor',
    label: 'Bachelor Degree'
  },
  {
    name: 'postgraduate',
    label: 'Postgraduate Degree'
  },
  {
    name: 'master',
    label: 'Master Degree'
  }
]

function TablePrograms () {
  const [programs, setPrograms] = useState([])
  const { data, refetch } = useGetDataPrograms()
  const [modal, contextHolder] = Modal.useModal()
  const { updatePage, isLoading: isLoadingUpdate, error: errorUpdate, data: dataUpdate } = usePutSavePage()

  const handleDateFormat = (date) => {
    const fechaHora = new Date(date)

    const dia = fechaHora.getUTCDate()
    const mes = fechaHora.getUTCMonth() + 1
    const año = fechaHora.getUTCFullYear()
    const horas = fechaHora.getUTCHours()
    const minutos = fechaHora.getUTCMinutes()

    const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${año} a las ${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`

    return fechaFormateada
  }

  useEffect(() => {
    if (!isLoadingUpdate && dataUpdate) {
      modal.success({
        title: 'Succes',
        content: 'Data saved succesfully'
      })
    }
    if (!isLoadingUpdate && errorUpdate) {
      modal.error({
        title: 'Something went wrong',
        content: errorUpdate.response?.data?.message
      })
    }
  }, [isLoadingUpdate, dataUpdate, errorUpdate])

  useEffect(() => {
    if (data) {
      const graduate = data?.find(program => program.name === 'graduate')
      const newArrayPrograms = data?.filter(progra => progra.name !== 'graduate')

      graduate?.pages?.forEach(page => {
        for (const type of pageTypes) {
          if (type.name === page.slug) {
            type.page = page
          }
        }
      })
      const prograsmAndTypes = newArrayPrograms.concat(pageTypes)
      setPrograms(prograsmAndTypes)
    }
  }, [data])

  const handleLink = (name) => {
    return URL_EDIT_PAGE_PROGRAM_PAGE.replace(':program', name)
  }

  const onChange = (checked, pageId) => {
    if (!pageId) {
      modal.error({
        title: 'Not page found'
      })
    } else {
      const status = checked ? 'Published' : 'Deleted'
      updatePage({
        page: pageId,
        status
      })
      refetch()
    }
  }

  const handleStatus = (status) => {
    let res
    if (status === 'Published') {
      res = 'Publicado'
    } else if (status === 'Deleted') {
      res = 'Borrado'
    } else {
      res = 'pulished'
    }
    return res
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <a className='table__link'>
          <Link to={handleLink(record.name)}>{record.label}</Link>
        </a>
      )
    },
    // {
    //   title: 'Description',
    //   key: 'description',
    //   render: (_, record) => (
    //     <span>{record?.page?.body ? record?.page?.body : '-'}</span>
    //   )
    // },
    // {
    //   title: 'Estatus de fotos',
    //   // dataIndex: 'points',
    //   key: 'fotos',
    //   render: (_, record) => (
    //     <span>{record?.page?.images?.length > 0 ? record?.page?.images?.length : '-'}</span>
    //   )
    // },
    {
      title: 'Estado',
      key: 'tags',
      render: (_, record) => (
        // <span className={record?.status}>{record?.status}</span>
        <span className={record?.page?.status ? record?.page?.status + '-table' : 'pendiente-table'}>{record?.page?.status ? handleStatus(record?.page?.status) : 'Pendiente'}</span>
      )
    },
    {
      title: 'Fecha',
      // dataIndex: 'points',
      key: 'fecha',
      render: (_, record) => (
        <span>{record?.page?.updated_at ? handleDateFormat(record?.page?.updated_at) : '-'}</span>
      )
    },
    {
      title: 'Activar',
      key: 'activar',
      render: (_, record) => (
        <Switch onChange={e => onChange(e, record?.page?.id)} checked={record?.page?.status === 'Published'} />
      )
    }
  ]
  return (
    <>
      {contextHolder}
      <h3 className='byp-title'>Programs</h3>
      <Table
        columns={columns}
        dataSource={programs}
        size='small'
        rowKey={'id'}
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'even-row' : 'odd-row'}
        className='table-pages'
      />
    </>
  )
}

export { TablePrograms }
