import { Col, Modal, Row, Switch, Table, Form, Select, AutoComplete, Input, Pagination } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useGetDataCountryCities, usePutSavePage } from '../../../../bussines/hooks/tables-page/useGetDataTablePage'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { URL_EDIT_PAGE_COUNTRY_PAGE, URL_EDIT_PAGE_CITY_PAGE } from '../../edit-page/EditPageCountryPage'
import { useGetCountriesWithOffers } from '../../../../bussines/hooks/places/useGetCountriesWithOffers'
import { useGetUsersByPage } from '../../../../bussines/hooks/page/useGetServices'

const { Option } = Select

function TableCountryCities () {
  const [searchFilter, setSearchFilter] = useState()
  const [stateSearch, setStateSearch] = useState({})
  const [options, setOptions] = useState([])
  const [statusInput, setStatusInput] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [idPage, setIdPage] = useState(null)
  const [input, setInput] = useState(false)
  const [values, setValues] = useState()
  const { data, refetch } = useGetDataCountryCities(stateSearch, statusInput)
  const { data: dataCountries, isLoading } = useGetCountriesWithOffers(searchFilter, { all: true })
  const [modal, contextHolder] = Modal.useModal()
  const { updatePage, isLoading: isLoadingUpdate, error: errorUpdate, data: dataUpdate } = usePutSavePage()
  const { data: users } = useGetUsersByPage()

  const handleDateFormat = (date) => {
    const fechaHora = new Date(date)

    const dia = fechaHora.getUTCDate()
    const mes = fechaHora.getUTCMonth() + 1
    const año = fechaHora.getUTCFullYear()
    const horas = fechaHora.getUTCHours()
    const minutos = fechaHora.getUTCMinutes()

    const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${año} a las ${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`

    return fechaFormateada
  }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      setSearchFilter(value)
      console.log('sending the request :x', key, value)
    }, 500)
  })

  useEffect(() => {
    if (dataCountries && !isLoading) {
      console.log('🚀 ~ file: PersonalizedSearcher.js:22 ~ useEffect ~ dataCountries:', dataCountries)
      const countries = dataCountries.countries?.map((country) => ({
        id: country.id,
        value: country.name,
        iso: country.iso3,
        type: 'country'
      }))
      const cities = dataCountries.cities?.map((city) => ({
        id: city.id,
        value: city.name,
        iso: city.country?.iso3,
        country_id: city.country_id,
        type: 'city'
      }))
      setOptions([
        ...countries, ...cities
      ])
    }
  }, [isLoading, dataCountries])

  useEffect(() => {
    if (!isLoadingUpdate && dataUpdate) {
      modal.success({
        title: 'Succes',
        content: 'Data saved succesfully'
      })
    }
    if (!isLoadingUpdate && errorUpdate) {
      modal.error({
        title: 'Something went wrong',
        content: errorUpdate.response?.data?.message
      })
    }
  }, [isLoadingUpdate, dataUpdate, errorUpdate])

  const handleLink = (record) => {
    let link
    if (record.iso2) {
      link = URL_EDIT_PAGE_COUNTRY_PAGE.replace(':code', record.iso2)
    } else {
      link = URL_EDIT_PAGE_CITY_PAGE.replace(':code', record.country.iso2)
      link = link.replace(':city', record.id)
    }
    return link
  }

  const handleStatus = (status) => {
    switch (status) {
      case 'Published':
        return 'Publicado'
      case 'Deleted':
        return 'Borrado'
      case 'Pending':
        return 'Borrador'
      default:
        return status
    }
  }

  const onChange = (checked, pageId) => {
    if (!pageId) {
      modal.error({
        title: 'Not page found'
      })
    } else {
      const status = checked ? 'Published' : 'Deleted'
      updatePage({
        page: pageId,
        status
      })
      refetch()
    }
  }

  const showModal = (e, id, page) => {
    setInput(e)
    setIdPage(id)
    setValues(page)
    if (e) {
      if (!values?.page?.body || values?.page?.images?.length < 3 || notExistBlock(values, 'course') || notExistBlock(values, 'blog')) {
        setIsModalOpen(true)
      } else {
        onChange(e, id)
      }
    } else {
      onChange(e, id)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleOk = () => {
    if (idPage) {
      onChange(input, idPage)
    }
    setIsModalOpen(false)
  }

  const notExistBlock = (data, type) => {
    const value = data?.page?.blocks?.filter(block => block.type === type)
    if (value) {
      if (type === 'course') {
        if (value[0]?.title && value[0]?.subtitle && value[0]?.courses?.length > 0) {
          return false
        }
      }
      if (type === 'blog') {
        if (value[0]?.title && value[0]?.subtitle && value[0]?.blogs?.length > 0) {
          return false
        }
      }
    }

    return true
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <a className='table__link'>
          <Link to={handleLink(record)}>{record.name}</Link>
        </a>
      )
    },
    // {
    //   title: 'Description',
    //   // dataIndex: 'age',
    //   key: 'description',
    //   render: (_, record) => (
    //     <Typography.Text ellipsis={{ rows: 1, expandable: true }} style={{ maxWidth: '6rem' }}>{record?.page?.body}</Typography.Text>
    //   )
    // },
    // {
    //   title: 'Estatus de fotos',
    //   // dataIndex: 'points',
    //   key: 'status_foto',
    //   render: (_, record) => (
    //     <span className={record?.page?.status ? record?.page?.status + '-table2' : 'pendiente-table'}>{(record?.page?.status === 'Published' && record?.page?.images?.length > 0) ? 'Terminado' : (record?.page?.status === 'Deleted' && record?.page?.images?.length > 0) ? 'En proceso' : 'Pendiente'}</span>
    //   )
    // },
    // {
    //   title: 'Programas',
    //   // dataIndex: 'points',
    //   key: 'programs',
    //   render: (_, record) => (
    //     <span className={record?.page?.status ? record?.page?.status + '-table2' : 'pendiente-table'}>{(record?.page?.status === 'Published' && record?.page?.images?.length > 0) ? 'Terminado' : (record?.page?.status === 'Deleted' && record?.page?.images?.length > 0) ? 'En proceso' : 'Pendiente'}</span>
    //   )
    // },
    // {
    //   title: 'Blog',
    //   // dataIndex: 'points',
    //   key: 'blog',
    //   render: (_, record) => (
    //     <span className={record?.page?.status ? record?.page?.status + '-table2' : 'pendiente-table'}>{(record?.page?.status === 'Published' && record?.page?.images?.length > 0) ? 'Terminado' : (record?.page?.status === 'Deleted' && record?.page?.images?.length > 0) ? 'En proceso' : 'Pendiente'}</span>
    //   )
    // },
    {
      title: 'Estado',
      key: 'status',
      render: (_, record) => (
        // <span className={record?.status}>{record?.status}</span>
        <span className={record?.page?.status ? record?.page?.status + '-table' : 'pendiente-table'}>{record?.page?.status ? handleStatus(record?.page?.status) : 'Pendiente'}</span>
      )
    },
    {
      title: 'Autor',
      // dataIndex: 'points',
      key: 'autor',
      render: (_, record) => (
        <span>{record?.page?.user?.name ? record?.page?.user?.name : '-'}</span>
      )
    },
    {
      title: 'Fecha',
      // dataIndex: 'points',
      key: 'fecha',
      render: (_, record) => (
        <span>{record?.page?.updated_at ? handleDateFormat(record?.page?.updated_at) : '-'}</span>
      )
    },
    {
      title: 'Activar',
      key: 'activar',
      render: (_, record) => (
        <div className='switch__table'>
          <Switch onChange={e => showModal(e, record?.page?.id, record)} size='small' checked={record?.page?.status === 'Published'} />
        </div>
      )
    }
  ]
  return (
    <>
      {contextHolder}
      <Row justify='space-between'>
        <Col span={8}>
          <h3 className='byp-title'>Ubicaciones</h3>
        </Col>

        <Col span={16}>
          <Row gutter={[8]}>
            <Col span={12}>
              <Form.Item>
                {/* <Input placeholder='¿A dónde quieres ir?' size='large' style={{ backgroundColor: '#F7F7F7' }} /> */}
                <AutoComplete
                  className='choose-course__input'
                  size='middle'
                  options={options}
                  filterOption={
                    (inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={(value) => debounceFilter('searchPlace', value)}
                  onSelect={(value, place) => {
                    setStateSearch(currentValues => {
                      currentValues.type = place.type
                      currentValues.idLocation = place.id
                      console.log(place)
                      if (place.type === 'city') {
                        currentValues.country = place.country_id
                      }
                      return currentValues
                    })
                  }}
                >
                  <Input.Search size='middle' placeholder='Pais o ciudad' className='country__search' enterButton />
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Estado'

              >
                <Select onChange={setStatusInput} placeholder='Todos'>
                  <Option value={null}>Todos</Option>
                  <Option value='Pending'>Borrador</Option>
                  <Option value='Published'>Publicado</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='Autor'
              >
                <Select placeholder='Todos' onChange={(user) => setStateSearch({ ...stateSearch, user })}>
                  {users?.map(user => (
                    <Option key={user?.id} value={user?.id}>{user?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <p>Rellena la información que necesitas usar.  Solo podrás ver países y ciudades con cursos y programas publicados.</p>

      <h5>Todas</h5>

      <Table
        columns={columns}
        // dataSource={data}
        dataSource={data?.map((item, index) => ({ ...item, key: index }))}
        size='small'
        className='table-pages'
        pagination={ {showTotal:(total, range) => `Ubicaciones por página ${total}
        ${range[0]}-${range[1]} de ${total}`}}
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'even-row' : 'odd-row'}
        expandable={{
          expandedRowRender: (record, index) => (
            <>
              {record?.ciudades && (
                <Table
                  key={index}
                  columns={columns}
                  dataSource={record.ciudades}
                  pagination={false}
                  showHeader={false}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? 'even-row' : 'odd-row'}
                  scroll={{ x: 'max-content' }}
                />
              )}
            </>
          )
          // onExpand: (_, record) => handleExpand(record)
        }}

      />

      <Modal title='' visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText='Publicar' cancelText='Cancelar'>
        <h5>¿Seguro que quieres publicar?</h5>
        {
          (!values?.page?.body || values?.page?.images?.length < 3 || notExistBlock(values, 'course') || notExistBlock(values, 'blog')) && (
            <>
              <p>Hace falta:</p>
              <ul>
                {!values?.page?.body && <li>Descripcion</li>}
                {values?.page?.images?.length < 3 && <li>Fotos</li>}
                {notExistBlock(values, 'course') && <li>Programas</li>}
                {notExistBlock(values, 'blog') && <li>Blog</li>}
              </ul>
              <p>avisandote que es lo que te hace falta y si quieres publicar asi sin algunos datos</p>
            </>
          )
        }
      </Modal>
    </>
  )
}

export { TableCountryCities }
