import { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Collapse, notification, InputNumber } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { SelectStudentLocation } from '../../../common/components/helper-form/SelectStudentLocation'
import { SelectCountriesByRegionFormItem } from '../../../common/components/places-helper-form/SelectCountriesByRegionFormItem'
import { AddAndRemoveListButtons } from '../Institution/components/AddAndRemoveListButtons'
import { PlusOutlined } from '@ant-design/icons'
import { ConceptCostFormItem } from '../Institution/components/ConceptCostFormItem'
import { SelectPackageFormItem } from './SelectPackageFormItem'
import { useGetPackagePrices } from '../../../bussines/hooks/packages/useGetPackagePrices'
import { useSavePackagePrices } from '../../../bussines/hooks/packages/useSavePackagePrices'
import stateControl from '../../../bussines/utils/state-control'
import { WeeklyCostComponent } from './components/WeeklyCostComponent'
import { PrefixLabelSelector } from '../Institution/components/PrefixLabelFormItem'
import { BasicSpinner } from '../../../common/components/BasicSpinner'
import { useLocation } from 'react-router-dom'

const { Panel } = Collapse

function FormPricesPackages ({ ...props }) {
  const location = useLocation()
  const { packageID, year: yearPrices } = location.state ?? { packageID: undefined, year: undefined }
  const [activePanels, setActivePanels] = useState([1, 2, 3, 4, 5])
  const [priceType, setPriceType] = useState()
  const [currency, setCurrency] = useState()
  const [form] = Form.useForm()
  const packageId = Form.useWatch('package', form)
  const year = Form.useWatch('year', form)
  const [yearId, setYearId] = useState()
  const { data: prices, isLoading: isSearching, isFetching, refetch, error: searcFailed } = useGetPackagePrices(packageId, yearId?.yearId)
  const { isLoading: isSaving, savePrices, currentState, error } = useSavePackagePrices()
  const prefixSelector = <PrefixLabelSelector divisa={currency} />

  const handleFinish = (values) => {
    console.log('🚀 ~ handleFinish ~ values:', values)
    let isAllOk = true
    if (values?.weeks) {
      const weeks = values?.weeks
      weeks.forEach(week => {
        if (week.cost === 0) {
          isAllOk = false
        }
      })
    } else if (values?.cost > 0) {
      isAllOk = true
    } else {
      isAllOk = false
    }

    if (isAllOk) {
      values.year_id = yearId.yearId
      savePrices(packageId, values)
    } else {
      notification.error({
        message: 'Por favor de ingresar un valor válido al costo del curso'
      })
    }
  }

  useEffect(() => {
    if (!isSearching && prices) {
      setActivePanels([1])
      console.log('🚀 ~ file: FormPricesPackages.js:23 ~ useEffect ~ prices:', prices)
      const additionals = prices.additionals?.map(additional => {
        return {
          aditional_information: additional.name,
          id: additional.id
        }
      })
      const extras = prices.extras?.map(extra => {
        return {
          id: extra.id,
          name: extra.concept,
          cost: extra.cost,
          frequency: extra.frequency
        }
      })
      const values = {
        currency: prices.currency?.code,
        region: prices.properties?.regions ?? [],
        subregions: prices.properties?.subregions ?? [],
        exclude_countries: prices.properties?.exclude_countries ?? [],
        student_location: prices.properties?.student_location ?? [],
        additionals,
        extras,
        notes: prices.notes
      }
      const weeks = []
      if (prices.properties?.min_weeks) {
        setPriceType('open')
        const min = prices.properties?.min_weeks
        const max = prices.properties?.max_weeks
        for (let week = min; week <= max; week++) {
          const price = prices.weeks?.find(weeekItem => weeekItem.range?.weeks === week)
          weeks.push({
            week,
            cost: price?.cost ?? 0
          })
        }
        values.weeks = weeks
      } else {
        setPriceType('whole')
        values.cost = prices.cost?.cost
      }
      setCurrency(prices.currency?.code)
      form.setFieldsValue(values)
    }
    if (!isSearching && searcFailed) {
      notification.error({
        message: searcFailed?.response?.data?.message
      })
    }
  }, [isSearching, isFetching])

  useEffect(() => {
    if (!isSaving && currentState === stateControl.STATE_SUCCESS) {
      form.resetFields([
        'weeks', 'region', 'subregions', 'exclude_countries',
        'student_location', 'additionals', 'extras', 'cost'
      ])
      refetch()
      notification.success({
        message: 'Data succesfully saved'
      })
    } else if (!isSaving && currentState === stateControl.STATE_ERROR) {
      notification.error({
        message: error?.response?.data?.message
      })
    }
  }, [isSaving])

  useEffect(() => {
    form.setFieldsValue({
      package: packageID,
      year: yearPrices
    })
  }, [packageID])

  return (
    <Form form={form} onFinish={handleFinish}>
      <div className='byp-dashboard-content'>
        <Row>
          <Col span={18}>
            <h6>
              <b>
                <i>
                  Let's get start to upload the prices! The base prices are the
                  general course prices:
                </i>
              </b>
            </h6>
          </Col>
        </Row>

        <Row style={{ marginBottom: 30, marginTop: 10 }}>
          <Col span={24}>
            <SelectPackageFormItem setYearId={setYearId} form={form} />
          </Col>
        </Row>
        {isSearching && <BasicSpinner style={{ height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />}
        {!isSearching && year && (
          <Collapse defaultActiveKey={activePanels}>
            <Panel header='Applicable nationalities' key='1'>
              <SelectCountriesByRegionFormItem form={form} />
              <SelectStudentLocation
                name='student_location'
                label='Student location'
              />
            </Panel>
            <Panel header='Package cost' key='2'>
              <Row>
                <Col span={24} className='byp-fw-bold byp-mb-1'>
                  {priceType === 'open' && <WeeklyCostComponent showListButtons={false} />}
                  {priceType === 'whole' && (
                    <Row style={{ width: '100%' }} gutter={24}>
                      <Col span={7}>
                        Total cost
                      </Col>
                      <Col span={7}>
                        <Form.Item name='cost'>
                          <InputNumber
                            addonBefore={prefixSelector}
                            min={0}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col span={24}>
                  <></>
                </Col>
              </Row>
            </Panel>
            <Panel header='This package includes' key='3'>
              <h6 className='byp-mt-1'>
                <b>
                  Free concepts that include your costs, that you want to
                  emphasize
                </b>
              </h6>
              <Row style={{ width: '100%' }}>
                <Form.List name='additionals'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Row key={key} style={{ width: '100%' }} gutter={16}>
                          <Col span={20}>
                            <Form.Item {...restField} name={[name, 'aditional_information']}>
                              <Input className='byp-input-border' />
                            </Form.Item>
                          </Col>
                          <AddAndRemoveListButtons
                            fields={fields}
                            index={index}
                            add={add}
                            remove={remove}
                            name={name}
                          />
                        </Row>
                      ))}
                      {fields.length === 0 && (
                        <div className='w-100'>
                          <Form.Item>
                            <Button
                              className='byp-btn-blue-100-outline float-right'
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </div>
                      )}
                    </>
                  )}
                </Form.List>
              </Row>
            </Panel>

            <Panel header='Additional costs' key='4'>
              <Row>
                <Col span={24} className='byp-fw-bold byp-mb-1'>
                  Add any other cost you need{' '}
                </Col>
                <Col span={24}>
                  <ConceptCostFormItem
                    name='extras'
                    divisa='CAD'
                    labelAdd='Add'
                    labelDelete='Delete'
                    program='academic_years'
                  />
                </Col>
              </Row>
            </Panel>

            <Panel header='Additional comments' key='5'>
              <Form.Item name='notes'>
                <TextArea placeholder='Write anything you want to mention' />
              </Form.Item>
            </Panel>
          </Collapse>
        )}
      </div>
      <div className='byp-dashboard-footer'>
        <Row gutter={12} className='byp-form-footer'>
          <Col span={24}>
            <Button
              className='byp-btn-blue-100 float-right byp-mt-1 byp-ml-1'
              htmlType='submit'
              loading={isSaving}
            >
              Approve
            </Button>
            <Button
              className='byp-btn-blue-200 float-right float-right byp-mt-1'
              htmlType='submit'
              loading={isSaving}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export { FormPricesPackages }
