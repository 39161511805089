import { useQuery } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'

function useGetPublicity () {
  return useQuery(['useGetPublicity'],
    () => AgencyRepository.getPublicity(), {
      retry: 2
    })
}

export { useGetPublicity }
