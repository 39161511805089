import { Button, Modal, Row, notification } from "antd";
import { CollectionCard } from "./CollectionCard";
import { HeartOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { IconDocumentAdd } from "../assets/svg/icons";
import { ModalCreateCollection } from "./ModalCreateCollection";
import { useGetCollections } from "../../bussines/hooks/collections/useGetCollections";
import stateControl from "../../bussines/utils/state-control";
import { useLocation, useNavigate } from "react-router-dom";
import { saveInStorage } from "../../utils/localStorageUtils";
import { URL_REGISTER_PAGE_BETA } from "../../pages/register";
import { useAddItemToCollection } from "../../bussines/hooks/collections/useAddItemToCollection";

const buttonProps = {
  style: {},
  text: "Guardar en Favoritos",
  className: "quote-byp__btn-add",
  classNameSpan: "quotation__header-text",
  icon: <HeartOutlined />,
};

/**
 *  Allows the user to choose a collection
 */
const ModalSelectCollection = ({
  itemId,
  onSave,
  isSaving,
  saveState,
  isPublicUser,
  button = buttonProps,
  faileForm,
  setFaileForm,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [collectionSelected, setCollectionSelected] = useState();
  const { data: collections, isLoading, refetch } = useGetCollections();
  const {
    addItem,
    isLoading: isSavingItem,
    currentState: itemState,
    state,
  } = useAddItemToCollection();

  const showModal = () => {
    if (isPublicUser) {
      const pathName = location.pathname;
      saveInStorage("redirectOnLoginSucces", pathName);
      navigate(URL_REGISTER_PAGE_BETA);
      return;
    }
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    if (onSave) {
      onSave(collectionSelected);
    }
  };

  useEffect(() => {
    if (faileForm) {
      console.log("🚀 ~ useEffect ~ itemId:", itemId);
      addItem(collectionSelected, { item_id: itemId });
      setFaileForm(false);
    }
  }, [faileForm]);

  useEffect(() => {
    if (!isLoading && collections) {
      const all = collections.data?.find(
        (collection) => collection.name === "Todos"
      );
      setCollectionSelected(all?.id);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isSaving && saveState === stateControl.STATE_SUCCESS) {
      notification.success({
        message: "Favorito agregado a tu collección",
      });
      setIsModalOpen(false);
      refetch();
    }
  }, [isSaving]);

  useEffect(() => {
    if (!isSavingItem && itemState === state.STATE_SUCCESS) {
      notification.success({
        message: "Favorito agregado a tu collección",
      });
      setIsModalOpen(false);
      refetch();
    }
  }, [isSavingItem]);

  return (
    <>
      <Button className={button.className} style={button.style} onClick={showModal}>
      {button.icon} <span className={button.classNameSpan}>{button.text}</span>
      </Button>
      <Modal
        title="Añadir a favoritos"
        onOk={handleOk}
        onCancel={handleCancel}
        visible={isModalOpen}
        footer={
          <>
            <Row style={{ justifyContent: "center", marginBottom: "15px" }}>
              <Button
                style={{
                  border: "2px solid #5D5D5D",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  gap: "4px",
                  width: "100%",
                  justifyContent: "center",
                }}
                onClick={handleSave}
                loading={isSaving}
              >
                <IconDocumentAdd color="#5D5D5D" width="13px" height="13px" />
                Guardar
              </Button>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <ModalCreateCollection onFinish={() => refetch()} />
            </Row>
          </>
        }
        className="byp-modal"
      >
        <h5>Colecciones</h5>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            columnGap: "15px",
          }}
        >
          {collections?.data?.map((collection) => (
            <CollectionCard
              key={collection.id}
              collection={collection}
              onClick={() => {
                setCollectionSelected(collection.id);
              }}
              className={
                collectionSelected === collection.id ? "byp-card-active" : ""
              }
              imagePreview={false}
              style={{ cursor: "pointer" }}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

export { ModalSelectCollection };
