import { Col, DatePicker, Form, Row, Select } from "antd";
import moment from "moment";
import { format, subDays } from "date-fns";
import "moment/locale/es-mx";
import locale from "antd/es/date-picker/locale/es_ES";
import { usePackage } from "../../../providers/package/package-course-context";
import { useEffect, useState } from "react";
import { formatStringDate } from "../../../utils/formatStringDate";

const { Option } = Select;

function SelectStartAndDuration() {
  const { service, formConfig, setFormConfig, form, course } = usePackage();

  // const disabledDate = (current) => {
  //   // Can not select days before today, today and mondays only
  //   let filteredDates
  //   const day = moment(current).isoWeekday()
  //   const datString = format(moment(current).toDate(), 'Y-LL-dd')
  //   const isHoliday = service.isHoliday(datString)
  //   let disabled
  //   disabled = day > service.getDayStartCourse() || day < service.getDayStartCourse()
  //   if (!disabled && isHoliday) {
  //     const nextDay = service.enableNextDay(datString)
  //     addEnabledDate(nextDay)
  //   }
  //   disabled = (current && disabled) || current < moment().endOf('day')

  //   const program = course?.offer?.program?.name
  //   const startDates = course?.offer?.start_dates
  //   if (startDates && (program === 'language' || program === 'summer_camps') && startDates?.length > 0) {
  //     const today = new Date()
  //     filteredDates = startDates?.filter(d => new Date(d.date) >= today)
  //   }

  //   if (filteredDates && (program === 'language' || program === 'summer_camps') && filteredDates?.length > 0) {
  //     const specificDates = filteredDates?.map(dateObj => dateObj?.date)
  //     const isSpecificDate = specificDates?.some(date => moment(date).isSame(current, 'day'))
  //     if (isSpecificDate) {
  //       return false
  //     }
  //     if (day === 1) {
  //       disabled = true
  //     }
  //   }
  //   return (disabled || isHoliday) && !service.isEnabledDate(datString)
  // }
  const disabledDate = (current) => {
    // Can not select days before today, today and mondays only
    let filteredDates;
    const day = moment(current).isoWeekday();
    const datString = format(moment(current).toDate(), "Y-LL-dd");
    const isHoliday = service.isHoliday(datString);
    let disabled;
    disabled =
      day > service.getDayStartCourse() || day < service.getDayStartCourse();
    if (!disabled && isHoliday) {
      const nextDay = service.enableNextDay(datString);
      addEnabledDate(nextDay);
    }
    disabled = (current && disabled) || current < moment().endOf("day");

    // const program = course?.offer?.program?.name
    const startDates = course?.offer?.start_dates;
    if (startDates && startDates?.length > 0) {
      const today = new Date();
      filteredDates = startDates?.filter((d) => new Date(d.date) >= today);
    } else {
      // Si no hay fechas de inicio, todas las fechas están habilitadas a partir de hoy.
      // const today = moment(course?.offer?.start_at).startOf('day')
      // return current.isBefore(today)

      const startDate = new Date(course?.offer?.start_at);
      const endDate = new Date(course?.offer?.end_at);
      endDate.setDate(endDate.getDate() + 1);
      // Si current es una fecha válida y está fuera del rango, deshabilitarla
      if (current && current.isAfter(startDate) && current.isBefore(endDate)) {
        return false;
      }
    }

    if (filteredDates && filteredDates?.length > 0) {
      const specificDates = filteredDates?.map((dateObj) => dateObj?.date);
      const isSpecificDate = specificDates?.some((date) =>
        moment(date).isSame(current, "day")
      );
      if (isSpecificDate) {
        return false;
      }
      if (day === 1) {
        disabled = true;
      }
    }
    return (disabled || isHoliday) && !service.isEnabledDate(datString);
  };

  const addEnabledDate = (date) => {
    const enabledDates = formConfig?.enabledDates ?? [];
    const found = enabledDates.find((d) => d === date);
    if (found) {
      // console.log('La fecha ' + date + '  ya se había agregado')
      return true;
    }
    enabledDates.push(date);
    setFormConfig({
      ...formConfig,
      enabledDates,
    });
  };

  const onChange = (date) => {
    // TO DO: Check if there is a way of go to the date without select the date
    const lodgingDate = subDays(new Date(date), 2);
    form.setFieldsValue({ lodgingStart: moment(lodgingDate) });
    setFormConfig({
      ...formConfig,
      courseStart: date,
      lodgingStart: moment(lodgingDate),
    });
    service?.searchPrices(date);
    setStartCourse(date);
  };

  const handleDate = (date) => {
    return moment(date).format("dddd, D [de] MMMM, YYYY");
  };

  const [startCourse, setStartCourse] = useState(null);
  useEffect(() => {
    if (formConfig?.courseStart !== startCourse) {
      const program = course?.offer?.program?.name;
      const startDates = course?.offer?.start_dates;
      if (
        startDates &&
        (program === "language" || program === "summer_camps") &&
        startDates?.length > 0
      ) {
        form.setFieldsValue({
          startEvent: moment(startDates[0]?.date),
        });
        setStartCourse(startDates[0]?.date);
        onChange(startDates[0]?.date);
      }
    }
  }, [course, service]);

  function obtenerNombreMes(fecha) {
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return meses[fecha.getMonth()];
  }

  function convertirFecha(fechaStr) {
    const fecha = new Date(fechaStr);

    const nombreMes = obtenerNombreMes(fecha);

    const dia = fecha.getDate();
    const año = fecha.getFullYear();

    const resultado = `${dia} ${nombreMes} ${año}`;

    return resultado;
  }

  return (
    // <Row gutter={12} className='w-100' style={{ marginTop: '10px', alignItems: 'center', display: 'flex', flexDirection: 'column' }} align='middle'>
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <label style={{ fontWeight: "600" }}>Fechas de Curso</label>
      </Col>

      {/* <Col lg={24} className='antd-date select-date'> */}
      <Col span={24}>
        <div className="cotizacion__start-end">
          {/* Inicio de clases */}
          <div>
            <label>Inicio de Clases</label>
            <Form.Item
              name="startEvent"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              {course?.offer?.type_course !== "package" && (
                <DatePicker
                  className="quote__border"
                  locale={locale}
                  style={{ width: "15rem" }}
                  placeholder="Añadir fecha"
                  disabledDate={disabledDate}
                  onChange={onChange}
                  format="dddd, MMMM DD, YYYY"
                />
              )}
              {course?.offer?.type_course === "package" && (
                <Select onChange={onChange}>
                  {course?.offer?.start_dates?.map((date) => (
                    <Option key={date.date} value={date.date}>
                      {handleDate(date.date)}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </div>

          {/* Duración del curso */}
          <div>
            <label>Duración del Curso</label>
            <div className="package__semanas">
              <Form.Item
                name="weeks"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Select
                  size="small"
                  addonAfter="weeks"
                  onChange={(nWeeks) => {
                    setFormConfig({
                      ...formConfig,
                      weeks: nWeeks,
                    });
                  }}
                >
                  {service.getOptionsWeeks()?.map((week, index) => (
                    <Option key={index} value={week.value}>
                      {week.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="weeks">semanas</div>
            </div>
          </div>
        </div>
      </Col>

      {service.getEndString() && (
        <Col span={24} className="end-of-course">
          <p>
            Fin del curso:{" "}
            {convertirFecha(moment(service.getEndString(), "DD MMMM YYYY"))}
          </p>
        </Col>
      )}

      {service.getIsHighSeason() && (
        <Col>
          <b>High Season</b>
        </Col>
      )}
    </Row>
  );
}

export { SelectStartAndDuration };
