import React from 'react'
// import { QuoteCalculatorService } from '../../services/QuoteCalculatorService'
import { useFindOfferPackage } from '../../bussines/hooks/quoter/useFindOffer'
import { Form, notification } from 'antd'
import { formatQuoterForm } from '../../pages/quote-course-package/utils/formatQuoterForm'
import { usePostQuote } from '../../bussines/hooks/quoter/usePostQuote'
import { useAuth } from '../auth/auth-context-provider'
import { useGetBaseQuote } from '../../bussines/hooks/quote/useGetBaseQuote'
import { useGetMyExchangeValues } from '../../bussines/hooks/currencies/useGetMyExcahngeValues'
// import { HighEducationQuoterService } from '../../services/HighEducationQuoterService'
import LottieCargando from '../../lotties/LottieCargando'
import { PackageCalculatorService } from '../../services/PackageCalculatorService'

const SearchContext = React.createContext()

function PackageCourseProvider (props) {
  const [form] = Form.useForm()
  const {
    user,
    userLocalCurrency,
    setUserLocalCurrency,
    userCountryLocation
  } = useAuth()

  const [formConfig, setFormConfig] = React.useState({})
  const [prices, setPrices] = React.useState()
  const [favorite, setFavorite] = React.useState(false)
  const [faileForm, setFaileForm] = React.useState(false)
  const [opinionsData, setOpinionsData] = React.useState()
  const [changeCurrency, setChangeCurrency] = React.useState(false)

  const { isLoading, data, refetch, error: offerError, isError: isOfferError } = useFindOfferPackage(props?.courseId, props?.campusId)
  const { isLoading: isLoadingBaseQuote, data: baseQuote } = useGetBaseQuote(props?.quoteId)
  const { isLoading: isLoadingPost, isSuccess: isSuccessPost, create, isError: isErrorPost, data: dataQuote, error: errorPost } = usePostQuote()
  const { data: exchangeValues, isLoading: loadingExchangeValues } = useGetMyExchangeValues()

  if (!isLoading && offerError && isOfferError) {
    if (offerError?.response?.status === 401) {
      const exception = new Error()
      exception.name = 'CustomError'

      exception.response = {
        status: offerError?.response?.status,
        data: {
          detail: 'HTTP Error 401 unauthorized'
        }
      }

      throw exception
    }
    return (
      <div className='w-100 byp-centered' style={{ height: '60vh' }}>
        {offerError?.response?.data?.message}
      </div>
    )
  }

  const service = new PackageCalculatorService(data, formConfig, setFormConfig, user, exchangeValues?.exchanges, prices, setPrices)
  // if (data?.offer?.program?.name === 'language' || data?.offer?.program?.name === 'summer_camps') {
  //   service = new QuoteCalculatorService(data, formConfig, setFormConfig, user, exchangeValues?.exchanges, prices, setPrices)
  // } else {
  //   service = new HighEducationQuoterService(data, formConfig, setFormConfig, user, exchangeValues?.exchanges, prices, setPrices)
  // }

  const submit = () => {
    try {
      form.submit()
    } catch (error) {
      console.error('error: ', error)
    }
  }

  const handleFinishForm = (values) => {
    if (!service.userCanApply()) {
      return notification.info({
        message: 'This quote canot be applied due to the course start date being less than 1 or 2 weeks from now'
      })
    }
    values.campus = props?.campusId
    values.year = props?.yearId
    values.favorite = favorite

    const format = formatQuoterForm(data?.offer?.id, values, service, data.offer?.program?.name)
    console.log('🚀 ~ file: quoter-course-context.js ~ line 39 ~ handleFinishForm ~ format', format)
    create(format)
  }

  const handleShare = () => {
    const newCurrency = userLocalCurrency
    let urlActual = window.location.href
    urlActual = urlActual.split('?')[0]
    const values = form.getFieldsValue()
    values.minorCosts = formConfig?.minorCosts
    values.fees = formConfig?.fees
    values.courseHSFees = formConfig?.courseHSFees
    values.lodgingExtras = formConfig?.lodgingExtras
    values.transportMinorCosts = formConfig?.transportMinorCosts
    values.courseCosts = formConfig?.courseCosts
    values.appliedGoods = formConfig?.appliedGoods
    values.lodgingExtras = formConfig?.lodgingExtras
    values.additionals = formConfig?.additionals
    values.currency = newCurrency
    console.log('minorCosts', values)
    const stringValue = JSON.stringify(values)
    navigator.clipboard.writeText(urlActual + '?values=' + stringValue).then(function () {
      console.log('Texto copiado al portapapeles: ' + urlActual + '?values=' + stringValue)
    }).catch(function (error) {
      console.error('Error al copiar al portapapeles: ', error)
    })
  }

  const getLinkToShare = () => {
    const newCurrency = userLocalCurrency
    let urlActual = window.location.href
    urlActual = urlActual.split('?')[0]
    const values = form.getFieldsValue()
    values.minorCosts = formConfig?.minorCosts
    values.fees = formConfig?.fees
    values.courseHSFees = formConfig?.courseHSFees
    values.lodgingExtras = formConfig?.lodgingExtras
    values.transportMinorCosts = formConfig?.transportMinorCosts
    values.courseCosts = formConfig?.courseCosts
    values.appliedGoods = formConfig?.appliedGoods
    values.lodgingExtras = formConfig?.lodgingExtras
    values.currency = newCurrency
    // console.log('minorCosts', values)
    const stringValue = JSON.stringify(values)
    return urlActual + '?values=' + stringValue
  }

  if (isLoading) {
    return <LottieCargando />
  }

  return (
    <SearchContext.Provider
      value={{
        isLoadingOffer: isLoading,
        offerError,
        isOfferError,
        refetch,
        isErrorPost,
        errorPost,
        handleFinishForm,
        isLoadingPost,
        isSuccessPost,
        submit,
        form,
        course: data,
        prices,
        quote: dataQuote,
        service,
        formConfig,
        setFormConfig,
        setFavorite,
        setFaileForm,
        faileForm,
        favorite,
        isLoadingBaseQuote,
        baseQuote,
        exchangeValues,
        loadingExchangeValues,
        handleShare,
        changeCurrency,
        setChangeCurrency,
        opinionsData,
        setOpinionsData,
        userLocalCurrency,
        setUserLocalCurrency,
        userCountryLocation,
        getLinkToShare
      }}
      {...props}
    />
  )
}

function usePackage () {
  const context = React.useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useQuoter must be used within a QuoteCourseProvider')
  }
  return context
}

export { PackageCourseProvider, usePackage }
