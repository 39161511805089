export const API_URL = process.env.REACT_APP_API_URL
export const URL_WEBSITE = process.env.REACT_APP_URL
export const URL_COPY = process.env.REACT_APP_URL

export const API_EXCHANGES_URL = process.env.REACT_APP_EXCHANGES_API_URL
export const API_EXCHANGES_KEY = process.env.REACT_APP_EXCHANGES_API_KEY
export const API_GEOCODE_KEY = process.env.REACT_APP_GEOCODE_API_KEY

export const APPLYBOARD_AGENCY = process.env.REACT_APP_APPLY_BOARD_AGENCY

// export const VERSION = process.env.REACT_APP_VERSION
export const URL_POLICY = API_URL + '/api/policy/register/file'
export const URL_SCHOOL_POLICY = API_URL + '/api/policy/school_termns_and_conditions/'
export const URL_AGENCY_POLICY = API_URL + '/api/policy/agency_termns_and_conditions/'

export const VERSION = process.env.REACT_APP_CRYPT_SALT

// VIMEO variables
export const VIMEO_TOKEN = process.env.REACT_APP_VIMEO_TOKEN
export const VIMEO_API_URL = process.env.REACT_APP_VIMEO_API_URL

// BYP public user data
export const BYP_PUBLIC_USER = process.env.REACT_APP_BYP_PUBLIC_USER
export const BYP_PUBLIC_PASSWORD = process.env.REACT_APP_BYP_PUBLIC_PASSWORD
