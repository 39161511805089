import { Button, Col, DatePicker, Form, Input, Row, notification, Modal } from 'antd'
// import Column from 'antd/lib/table/Column'
// import { FiltersFormUploadPromotions } from './FiltersFormUploadPromotions'
import { SelectBranchesAll } from '../agency/components/SelectBranchesAll'
import { usePostSaveDiscount } from '../../../bussines/hooks/school/discounts/usePostSaveDiscount'
import { useGetDiscounts } from '../../../bussines/hooks/school/discounts/useGetDiscounts'
import { useEffect, useState } from 'react'
import { TableDiscountsAgency } from '../Institution/Tables/TableDiscountsAgency'
import { useDeleteDiscount } from '../../../bussines/hooks/school/discounts/useDeleteDiscount'
import { useUpdateDiscount, useUpdateStatusDiscount } from '../../../bussines/hooks/school/discounts/useUpdateDiscount'
import { showErrorModal } from '../../../utils/errorModal'
import { useFindDiscount } from '../../../bussines/hooks/school/discounts/useFindDiscount'
import utilsState from '../../../bussines/utils/state-control'
import moment from 'moment'

function FormUploadPromotions () {
  const [page, setPage] = useState(1)
  const [discountId, setDiscountId] = useState()
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const [searchFilter, setSearchFilter] = useState({})

  const [form] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal()

  const {
    currentState,
    isLoading: isSaving,
    saveDiscount,
    error: errorSave,
    isError: isErrorSaving
  } = usePostSaveDiscount()

  const {
    data: discounts,
    isLoading,
    refetch
  } = useGetDiscounts({ page, type: 'table', ...searchFilter })

  const {
    deleteDiscount,
    isLoading: isDeleting,
    currentState: deleteState,
    error: errorDelete,
    isError: isErrorDeleting
  } = useDeleteDiscount()

  const {
    updateDiscount,
    isLoading: isUpdating,
    currentState: updateState,
    error: errorUpdate,
    isError: isErrorUpdating
  } = useUpdateDiscount()

  const {
    updateDiscount: updateStatus,
    isLoading: isUpdatingStatus,
    currentState: updateStateStatus,
    error: errorUpdateStatus,
    isError: isErrorUpdatingStatus
  } = useUpdateStatusDiscount()

  const { data: discount, isLoading: isSearching } = useFindDiscount(discountId)

  useEffect(() => {
    if (!isSaving && currentState === utilsState.STATE_SUCCESS) {
      notification.success({
        message: 'Data saved'
      })
      form.resetFields()
      refetch()
    }
  }, [isSaving, currentState])

  useEffect(() => {
    if (!isDeleting && deleteState === utilsState.STATE_SUCCESS) {
      refetch()
    }
  }, [isDeleting, deleteState])

  useEffect(() => {
    if (!isSearching && discount) {
      const startAt = discount.start ?? null
      const endAt = discount.end ?? null

      discount.start = moment(startAt)
      discount.end = moment(endAt)
      discount.sedes = discount.branches

      form.setFieldsValue(discount)
      setIsUpdateMode(true)
    }
  }, [isSearching, discount])

  useEffect(() => {
    if (!isUpdating && updateState === utilsState.STATE_SUCCESS) {
      notification.success({
        message: 'Data updated'
      })
      setIsUpdateMode(false)
      form.resetFields()
      setDiscountId()
      refetch()
    }
  }, [isUpdating, updateState])

  useEffect(() => {
    if (!isUpdatingStatus && updateStateStatus === utilsState.STATE_SUCCESS) {
      setIsUpdateMode(false)
      setDiscountId()
      refetch()
    }
  }, [isUpdatingStatus, updateStateStatus])

  useEffect(() => {
    if (isErrorUpdating && errorUpdate) {
      showErrorModal(errorUpdate.response.data.message)
    }
    if (isErrorSaving && errorSave) {
      showErrorModal(errorSave.response.data.message)
    }
    if (isErrorDeleting && errorDelete) {
      showErrorModal(errorDelete.response.data.message)
    }
    if (isErrorUpdatingStatus && errorUpdateStatus) {
      showErrorModal(errorUpdateStatus.response.data.message)
    }
  }, [
    errorSave,
    isErrorSaving,
    errorUpdate,
    isErrorUpdating,
    errorDelete,
    isErrorDeleting,
    errorUpdateStatus,
    isErrorUpdatingStatus
  ])

  const onFinish = (values) => {
    values.start = values?.start?.toISOString().slice(0, 19).replace('T', ' ')
    values.end = values?.end?.toISOString().slice(0, 19).replace('T', ' ')

    isUpdateMode ? updateDiscount(values, discountId) : saveDiscount(values)
  }

  return (
    <div className='byp-dashboard-content'>
      <Form
        name='basic'
        form={form}
        onFinish={onFinish}
      >
        {contextHolder}
        <Row gutter={20}>
          <Col md={12}>
            <h4 style={{ paddingBottom: '30px' }}>Create your promotions </h4>
            <Form.Item
              label='Promo name'
              name='name'
              labelCol={{ span: 4 }}
              labelAlign='left'
            >
              <Input style={{ width: '50%' }} />
            </Form.Item>
            <Form.Item
              label='Start'
              name='start'
              labelCol={{ span: 4 }}
              labelAlign='left'
            >
              <DatePicker format='MM/DD/YYYY' size='small' style={{ width: '50%' }} />
            </Form.Item>
            <Form.Item
              label='Deadline'
              name='end'
              labelCol={{ span: 4 }}
              labelAlign='left'
            >
              <DatePicker format='MM/DD/YYYY' size='small' style={{ width: '50%' }} />
            </Form.Item>
            <h5 style={{ paddingBottom: '30px', fontWeight: 'bold', color: 'var(--blue-200)' }}>Promotions goes to</h5>
            <SelectBranchesAll
              mode='multiple'
              name='sedes'
              label='Offices'
              showLabel={false}
              form={form}
            />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type='primary'
                className='float-right'
                htmlType='submit'
                loading={isLoading || isSaving || isUpdating || isDeleting}
              >
                {isUpdateMode ? 'Update promotions' : 'Save promotions'}
              </Button>
            </div>
          </Col>
          <Col md={12} style={{ paddingLeft: '12px' }}>
            <TableDiscountsAgency
              data={discounts}
              isLoading={isLoading || isSaving || isUpdating || isDeleting}
              refetch={refetch}
              setDiscountId={setDiscountId}
              deleteDiscount={deleteDiscount}
              updateDiscount={updateStatus}
              isUpdatingStatus={isUpdatingStatus}
              setPage={setPage}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
            />
          </Col>
        </Row>
      </Form>
    </div>
  )
}
export { FormUploadPromotions }
