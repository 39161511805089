import axios from 'axios'

export const shortenUrl = async (longUrl) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/shorten`, { original_url: longUrl })
    return response.data.short_url
  } catch (error) {
    console.error('Error acortando la URL:', error)
    throw error
  }
}
