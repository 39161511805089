import { Form, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetAllBranches } from '../../../bussines/hooks/agency/useGetAllBranches'
const { Option } = Select
function SelectBranchesFormItem ({ form, filters, noStyle, onChange, showOptionAll, showNameSede, isDisabled = false, haveValue, firstOption = false, ...props }) {
  const { isLoading, data: braches } = useGetAllBranches(filters)

  useEffect(() => {
    if (firstOption && braches?.length > 0 && showNameSede) {
      onChange(braches[0].id + '-' + braches[0]?.name)
    } else if (firstOption && braches?.length > 0) {
      onChange(braches[0].id)
    }
  }, [braches])

  const [selectedValues, setSelectedValues] = useState([])

  const handleChange = (values) => {
    let isAll = false
    if (Array.isArray(values)) {
      isAll = values.includes('All')
    }
    if (isAll) {
      if (selectedValues.length === 0 || selectedValues.length < braches.length) {
        setSelectedValues(braches?.map(item => item.id) || [])
      } else {
        setSelectedValues([])
      }
    } else {
      setSelectedValues(values)
    }
  }

  useEffect(() => {
    if (props.name === 'sede' && form && showOptionAll) {
      form.setFieldsValue({ sede: selectedValues })
    } else if (props.name === 'applies_in' && form && showOptionAll) {
      form.setFieldsValue({ applies_in: selectedValues })
    }
  }, [selectedValues, form])

  return (
    <Form.Item {...props} noStyle={noStyle}>
      <Select
        style={{ width: '100%' }} placeholder={props.placeholder ?? ''} loading={isLoading}
        size={props.size}
        onChange={onChange}
        onSelect={handleChange}
        disabled={isDisabled}
        {...props}
      >
        {showOptionAll && <Option value='All'>All Offices</Option>}
        {showNameSede
          ? (
            braches?.map(item => (
              <Option key={item.id + '-' + item.name} value={item.id + '-' + item.name}>{item.name}</Option>
            ))
          )
          : (
            braches?.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))
          )}
      </Select>
    </Form.Item>
  )
}

export { SelectBranchesFormItem }


