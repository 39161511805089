import { useQuery } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'
import removeInvalidValuesFromParams from '../../../utils/removeInvalidValuesFromParams'

function useGetInstitutions (filters = {}, enabled = true) {
  const validFilters = removeInvalidValuesFromParams(filters)
  const filtersQuery = Object.keys(validFilters).map((key) => key + '=' + validFilters[key]).join('&')
  return useQuery(['useGetInstitutions', filtersQuery],
    () => AgencyRepository.institutions(filtersQuery),
    { retry: 2, enabled }
  )
}

export { useGetInstitutions }
