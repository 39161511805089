import { Pagination } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetQuoteActionsDetailsReports } from '../../../../bussines/hooks/agency/analytics/users/useGetQuoteActionsDetails'
import { BasicSpinner } from '../../../../common/components/BasicSpinner'

const SendsQuotesDataContainer = ({ user, time, orderby, studentID }) => {
  const [quotes, setQuotes] = useState([])
  const [page, setPage] = useState(1)
  const { data, isLoading } = useGetQuoteActionsDetailsReports({ type: 'send', ...time, page, user, studentID })
  // const { data, isLoading } = useGetQuoteActionsDetails(user, { type: 'send', ...time, page })

  useEffect(() => {
    if (data && !isLoading) {
      // console.log('🚀 ~ file: SendsQuotesDataContainer.js:10 ~ useEffect ~ data', data)
      setQuotes(data?.data)
    }
  }, [data, isLoading])

  useEffect(() => {
    if (orderby && quotes?.length > 0) {
      setQuotes(
        [...quotes].sort((a, b) => {
          return (typeof a[orderby] === 'number' || !isNaN(a[orderby]))
            ? a[orderby] - b[orderby]
            : a[orderby]?.localeCompare(b[orderby])
        })
      )
    }
  }, [orderby])

  return (
    <>
      {isLoading && <tr><td colSpan={6}><BasicSpinner /></td></tr>}
      {quotes?.map((item, index) => (
        <tr key={index} className='byp-color-white-full'>
          <td>{item.date_of_send}</td>
          <td>{item.program}</td>
          <td>{item.school}</td>
          <td><Link to={`/quote-preview/${item.quote_uuid}`}>{item.course}</Link></td>
          <td>{item.student_id}</td>
          <td style={{ overflowWrap: 'break-word' }}>{item.client_email}</td>
        </tr>
      ))}
      <tr>
        <td colSpan={6} style={{ textAlign: 'end', paddingTop: '0.5rem' }}>
          <Pagination
            className='byp-w-100'
            defaultCurrent={1}
            current={data?.meta?.current_page ?? 1}
            // size='small'
            pageSize={data?.meta?.per_page ?? 20}
            total={data?.meta?.total ?? 0}
            onChange={setPage}
            hideOnSinglePage
            showQuickJumper={false}
            showSizeChanger={false}
          />
        </td>
      </tr>
    </>
  )
}

export { SendsQuotesDataContainer }
