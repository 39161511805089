import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import header from '../../common/assets/images/figma/video-preview.png'
import { Col, Image, Row } from 'antd'
import { logo } from '../../utils/fallbackImage'
import { LeftSideImages } from './components/LeftSideImages'
import { OpinionForm } from './components/OpinionForm'
import { useLocation, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import SessionService from '../../services/SessionService'
import ModalRegisterUser from '../../common/components/ModalRegisterUser'
import { saveInStorage } from '../../utils/localStorageUtils'

const URL_OPINION_PAGE = '/opinion/:campusSlug/:courseId'
const URL_OPINION_CAMPUS_PAGE = '/opinion/:campusSlug'

const OpinionPage = () => {
  const { courseId, campusSlug } = useParams()
  const [gallery, setGallery] = useState([])
  const [showModal, setShowModal] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (SessionService.isPublicSession()) {
      const pathName = location.pathname
      saveInStorage('redirectOnLoginSucces', pathName)
      setShowModal(true)
    }
  }, [])

  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme layoutStyle={{ background: 'var(--background)' }}>
        <div
          style={{
            // background: 'var(--background)',
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Row gutter={12} className='byp-container'>
            <Col xs={24} style={{ marginBottom: '1.5rem' }}>
              <Image
                width='100%'
                height={200}
                src={header}
                // src='error
                style={{ objectFit: 'cover', borderRadius: '1rem' }}
                fallback={logo}
              />
            </Col>
            <Col xs={0} md={6}>
              <LeftSideImages images={gallery} />
            </Col>
            <Col xs={24} md={18} style={{ paddingLeft: '4%', paddingRight: '4%' }}>
              <OpinionForm
                courseId={courseId}
                campusSlug={campusSlug}
                setGallery={setGallery}
                setShowRegisterModal={setShowModal}
              />
            </Col>
          </Row>
        </div>
      </MainTheme>
      <ModalRegisterUser isModalOpen={showModal} setIsModalOpen={setShowModal} />
    </PageProtected>
  )
}

export { OpinionPage, URL_OPINION_PAGE, URL_OPINION_CAMPUS_PAGE }
