import { Button } from 'antd'
import CreateIcon from '@material-ui/icons/Create'
import { Link } from 'react-router-dom'
import { IconEditHome } from '../../../../common/assets/svg/icons'

export const BlogFooter = () => {
  return (
    <div className='blog__footer'>
      <h1 style={{ fontWeight: '600', marginBottom: '15px', textAlign: 'center' }}>¡Arma tu propia cotización!</h1>
      <Link to='/home' className='blog__footer-button'>
        <Button
          style={{
            fontSize: '14px',
            fontWeight: '600',
            color: '#FFF',
            backgroundColor: '#FF395C',
            borderColor: '#FF395C',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '17.5px',
            gap: '10px',
            '&:hover': {
              backgroundColor: '#FF627E !important'
            }
          }}
        >
          {/* <CreateIcon style={{ fontSize: '13px', color: '#FFF' }} /> */}
          <IconEditHome style={{fontsize: '13px'}}/>
          Fácil y rápido
        </Button>
      </Link>
    </div>
  )
}
