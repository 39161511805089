import { Statistic, Typography } from "antd";
import { useQuoter } from "../../providers/quoter/quoter-course-context";

const { Text } = Typography;
export function discountsApplied(discounts) {
  if (Array.isArray(discounts)) {
    return discounts.length > 0;
  }
  return discounts > 0;
}
export function StatisticWithDiscounts({
  discounts,
  amount,
  subtotal,
  isHighSeasion,
  currency,
  active = false,
}) {
  const { service } = useQuoter();
  if (
    service.getCourseProgram()?.name !== "language" &&
    active &&
    (amount === 0 || amount === "0.00")
  ) {
    return <Text className="text-discount">INCLUDED</Text>;
  }

  if (!active && (amount === 0 || amount === "0.00")) return null;

  return (
    <>
      <Statistic
        prefix="$"
        // prefix=''
        precision={2}
        value={`${amount}`}
        // suffix={`${currency}`}
      />
     
      {isHighSeasion?.start && <b>Temporada alta</b>}
      {discountsApplied(discounts) > 0 && (
        <Statistic
          prefix="$"
          className="price-after text-discount"
          precision={2}
          value={`- ${subtotal}`}
          // suffix={`${currency}`}
        />
      )}
    </>
  );
}
