import { Form, Input, Select, Upload } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import { useGetPages } from '../../../../bussines/hooks/page/useGetPages'
import { getBase64, headersForUpload } from '../../../../utils/uploadImageUtils'
import { API_URL } from '../../../../config'
import { URL_TABLE_BLOGS_PAGE } from '../../blogs/PageTableBlogs'
import debounce from 'lodash/debounce'

const { Option } = Select

function EditBlog ({ setBlogs, setTitleBlog, setImage, image, setIconBlogId, blogIds, setSubTitleBlog }) {
  const [filters, setFilters] = React.useState({ type: 'blog', status: 'Published' })
  const { data: dataBlogs } = useGetPages(filters)

  const handleChangeBlogs = (value) => {
    if (value) {
      const blogs = dataBlogs?.data?.filter((blog) => value.includes(blog.id))
      setBlogs(blogs)
    }
  }

  const handleChange = (event) => {
    if (event.file.status === 'done') {
      getBase64(event.file.originFileObj, (imageUrl) => {
        setImage(imageUrl)
        setIconBlogId(event.file.response.id)
      })
    } else if (event.file.status === 'error') {
      console.log('🚀 ~ file error:', event.file.response)
    }
  }

  const debouncedSearch = React.useMemo(() => {
    return debounce((key, value) => {
      if (value) {
        setFilters({
          ...filters,
          search: value
        })
      } else {
        setFilters({ type: 'blog', status: 'Published' })
      }
    }, 800)
  })

  useEffect(() => {
    if (blogIds) handleChangeBlogs(blogIds)
  }, [blogIds])

  return (
    <div className='edit-home__section'>
      <h4>Sección blogs</h4>
      <span>Icon</span>

      <label className='edit-home__section-icon byp-mb-1' htmlFor='icon__blog'>
        {image ? <img src={image} /> : <RecordVoiceOverIcon style={{ fontSize: '20px' }} />}
      </label>

      <div style={{ display: 'none' }}>
        <Upload
          name='image'
          listType='picture-card'
          className='upload-page__img'
          showUploadList={false}
          action={`${API_URL}/pages/images`}
          id='icon__blog'
          onChange={event => handleChange(event)}
          headers={headersForUpload()}
        >
          <img src={image ?? ''} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
        </Upload>
      </div>

      <span>Título</span>
      <Form.Item
        className='edit-home__form-item'
        name='title_blog'
      >
        <Input onChange={e => setTitleBlog(e.target.value)} />
      </Form.Item>

      <span>Subtítulo</span>
      <Form.Item
        className='edit-home__form-item'
        name='subtitle_blog'
      >
        <Input onChange={e => setSubTitleBlog(e.target.value)} />
      </Form.Item>

      <span>Blogs a mostrar</span>
      <Form.Item
        className='edit-home__form-item input-margin-0'
        name='blogs'
      >
        <Select
          mode='multiple'
          onChange={handleChangeBlogs}
          optionFilterProp='children'
          className='edit-programs-blog__select'
          onSearch={(e) => {
            debouncedSearch('search ', e)
          }}
        >
          {dataBlogs?.data?.map((blog) => (
            <Option key={blog.id} value={blog.id}>{blog.title}</Option>
          ))}
        </Select>
      </Form.Item>

      <Link to={URL_TABLE_BLOGS_PAGE}>
        <a className='edit-home__link'>Dar de alta un blog</a>
      </Link>
    </div>
  )
}

export default EditBlog
