import React from 'react'
import { Collapse, Modal, Typography } from 'antd'
import '../common/assets/css/modal-error.less'
const { Text } = Typography
const { Panel } = Collapse

const defaultMessage = 'Ocurrió un error al tratar de contactar al servidor'
  + ', por favor, trata de nuevo en unos minutos.'
  + ' Si el error continua contactanos a admin@quoteyourprogram.com'
const defaultTitle = 'Algo salió mal'
const config = (
  errorDetails,
  message = defaultMessage,
  title = defaultTitle
) => ({
  title,
  content: (
    <>
      <Text className='w-100'>
        {message}
      </Text>
      {errorDetails && (
        <Collapse bordered={false}className='modal-error-details'>
          <Panel header='Error details' key='details'>
            <Text>{errorDetails}</Text>
          </Panel>
        </Collapse>
      )}
    </>
  ),
  icon: '',
  className: 'byp-modal-error'
})

// TODO: check the error code
const showErrorModal = (errorDetails, message = defaultMessage, title = defaultTitle) => {
  Modal.error(config(errorDetails, message, title))
}
export {
  config,
  showErrorModal,
  defaultMessage
}
