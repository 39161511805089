import { Col, Image, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { BlockVideo } from '../../blogs/components/BlockVideo'
import { BlockText } from './BlockText'
import { BlockTextP } from './BlockTextP'

const BlockColumn = ({ item }) => {
  const textRef = useRef(null)
  const [imageSize, setImageSize] = useState('100%')
  const [stylesImage, setStylesImage] = useState(false)

  useEffect(() => {
    if (textRef.current && item) {
      const textContainerHeight = textRef.current.clientHeight
      const maxHeight = 200
      setStylesImage(true)
      if (textContainerHeight >= maxHeight) setImageSize(`${textContainerHeight}px`)
      if (maxHeight > textContainerHeight) setImageSize(`${maxHeight}px`)
    }
  }, [textRef, item])

  if (item.hidded) {
    return null
  }

  const getBlock = (block) => {
    switch (block?.type) {
      case 'title': return <BlockText key={block.key} item={block} />
      case 'text': return <BlockTextP key={block.key} item={block} textRef={textRef} />
      case 'col': return null
      case 'video': return <BlockVideo item={block} />
      case 'image': return <Image src={block.url} style={{ margin: 'auto' }} height={imageSize} className={stylesImage && 'fit-image'} />
      default: return null
    }
  }

  const handleRef = (type) => {
    if (type === 'text') {
      return textRef
    }
    return null
  }

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: '1rem', alignItems: 'flex-start' }}>
      <Col xs={24} md={12} ref={handleRef(item.blockA?.type)} style={item.blockA?.type === 'image' && { textAlign: 'center' }} className={item?.blockA?.type === 'image' && 'container_img-100'}>
        {getBlock(item.blockA)}
      </Col>
      <Col xs={24} md={12} ref={handleRef(item.blockB?.type)} style={item.blockB?.type === 'image' && { textAlign: 'center' }} className={item?.blockB?.type === 'image' && 'container_img-100'}>
        {getBlock(item.blockB)}
      </Col>
    </Row>
  )
}

export { BlockColumn }
