import { Link } from 'react-router-dom'
import { DesktopIcon } from '../icons/DesktopIcon'
import { getItem } from './components/get-menu-item'
import { IconAnalyticsSharp } from '../../assets/svg/icons'
import { URL_GENERAL_ANALYSIS_PAGE } from '../../../pages/dashboards/agency/analitics/general/GeneralAnalysisPage'
import { URL_USER_ANALYSIS_PAGE } from '../../../pages/dashboards/agency/analitics/user/UserAnalysisPage'
import { UsersIcon } from '../icons/UsersIcon'
import { URL_CREATE_USERS_PAGE } from '../../../pages/dashboards/users/CrudUserPage'

function SuperAdminMenuItems () {
  return [
    getItem(
      <Link to='/dashboard' state={{ menuItemSelected: 1 }}>Desktop</Link>,
      '1',
      <DesktopIcon />
    ),
    getItem(
      'Analytics',
      'analitic',
      <IconAnalyticsSharp />,
      [
        getItem(
          <Link
            to={URL_GENERAL_ANALYSIS_PAGE}
            state={{ menuItemSelected: 'general-analysis', openSub: 'analitic' }}
          >General analysis
          </Link>,
          'general-analysis'
        ),
        getItem(
          <Link
            to={URL_USER_ANALYSIS_PAGE}
            state={{ menuItemSelected: 'user-analysis', openSub: 'analitic' }}
          >User analysis
          </Link>,
          'user-analysis'
        )
      ]
    ),
    getItem(
      <Link to={URL_CREATE_USERS_PAGE} state={{ menuItemSelected: 'users' }}>Users</Link>,
      'users', <UsersIcon />
    )
  ]
}

export default SuperAdminMenuItems
