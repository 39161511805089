import React, { useEffect, useState } from 'react'
import { ValidateEnterpriseProfile } from '../../common/components/ValidateEnterpriseProfile'
import { MainTheme } from '../../common/theme/MainTheme'
import { FilterCategory } from './components/FilterCategory'
import { ListSearchQuoteResult } from './components/ListSearchQuoteResult'
import { SearchProvider } from '../../providers/search/search-context-provider'
// import { HideActiveSearch } from './components/HideActiveSearch'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { useLocation } from 'react-router-dom'
import { useGetConfiguration } from '../../bussines/hooks/big-home/useGetPackage'

const URL_QUOTER_PAGE = '/home'

const QuoterPage = () => {
  const [programsSorted, setProgramsSorted] = useState(null)
  const [subType, setSubType] = useState()
  const [newState, setNewState] = useState()
  const [status, setStatus] = useState(false)
  const { state } = useLocation()
  const { data: programs } = useGetConfiguration()

  useEffect(() => {
    if (programs && programs?.configuration && programs?.configuration?.length > 0) {
      setProgramsSorted(JSON.parse(programs?.configuration[0]?.value))
    }
  }, [programs])

  useEffect(() => {
    if (state) {
      setNewState(state)
    }
  }, [state])

  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme activeCarousel showMainTheme={false}>
        <SearchProvider>
          <FilterCategory
            subType={subType}
            status={status}
            setStatus={setStatus}
            state={newState}
            setState={setNewState}
            programsSorted={programsSorted}
          />
          <ListSearchQuoteResult
            setSubType={setSubType}
            status={status}
            state={newState}
            setState={setNewState}
          />
        </SearchProvider>
      </MainTheme>
    </ValidateEnterpriseProfile>
  )
}
export { QuoterPage, URL_QUOTER_PAGE }
