import React from "react";
import { Col, Layout, Row } from "antd";
// import { AppLogoNameHeader } from '../components/AppLogoNameHeader'
import "./../assets/css/main-theme.less";
import { MainThemeCarrousel } from "./partials/MainThemeCarrousel";
import { MainThemeFooter } from "./partials/MainThemeFooter";
import { UserMenuSwitcher } from "./partials/UserMenuSwitcher";
import { Header } from "antd/lib/layout/layout";
import { AppLogoNameHeader } from "../components/AppLogoNameHeader";
import { MainThemeMenu } from "./partials/MainThemeMenu";
// import { LogoSchool } from '../assets/images/icons/LogoSchool'

const { Footer, Content, Sider } = Layout;

const PublicThemeNone = ({
  children,
  activeCarousel = false,
  showMenu = false,
}) => {
  return (
    <div style={{ backgroundColor: "#F9F9F9" }}>
      <Header className="header-theme logo header-pc">
        <Row
          justify="space-around"
          align="middle"
          style={{ padding: "0 20px" }}
        >
          <Col xs={24} md={24} lg={6}>
            <AppLogoNameHeader className="h-logo" />
          </Col>
          <Col xs={24} md={24} lg={18} align="right">
            <MainThemeMenu />
          </Col>
        </Row>
      </Header>
      {/** TABLET **/}
      <Header className="header-theme logo header-tablet">
        <Row justify="space-between" align="top" style={{ padding: '6px 0' }}>
          <Col
            xs={12}
            sm={6}
            md={5}
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            <AppLogoNameHeader className="logo-small" changeImage />
          </Col>
          <Col className="header-bar">
            <MainThemeMenu />
          </Col>
        </Row>
      </Header>

      <Layout className="layout public-them-layout">
        <Layout>
          <Row style={{ width: "100%" }}>
            {showMenu && (
              <Col md={4} lg={4} style={{ zIndex: 999, textAlign: "center" }}>
                <Sider
                  width="100%"
                  className=""
                  breakpoint="lg"
                  collapsedWidth="0"
                  onBreakpoint={(broken) => {}}
                  onCollapse={(collapsed, type) => {}}
                >
                  <UserMenuSwitcher />
                </Sider>
              </Col>
            )}
            <Col
              xs={24}
              md={24}
              lg={showMenu ? 20 : 24}
              className="main-menu-container"
            >
              <Content>
                {activeCarousel && <MainThemeCarrousel />}
                <Content className="site-layout-content">{children}</Content>
              </Content>
            </Col>
          </Row>
        </Layout>
        {!showMenu && (
          <Footer className="site-layout-footer">
            <MainThemeFooter />
          </Footer>
        )}
      </Layout>
    </div>
  );
};

export { PublicThemeNone };
