import { Image, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { URL_BLOG_PAGE } from '../BlogPage'
import { useNavigate } from 'react-router-dom'
import stripHtml from '../../../../utils/stripHtml'
const { Paragraph } = Typography

export function FeaturedCard({ item, isFeatured = false }) {
  const [cover, setCover] = useState()
  const [textBlock, setTextBlock] = useState()
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(URL_BLOG_PAGE.replace(':slug', item?.slug))
  }

  useEffect(() => {
    if (item && item.content) {
      const cover = item?.files?.find((file) => file.type === 'image' && file.pivot?.position === 'Cover')
      setCover(cover?.src)
      const texts = item.content
        .filter(content => content.type === 'text')
        .map(content => stripHtml(content.body))
        .join(' ')
        .split(' ')
        .filter(word => word.trim().length > 0)
      let combinedText = ''
      let wordCount = 0

      for (let word of texts) {
        combinedText += `${word} `
        wordCount++
        if (wordCount >= 20) break
      }
      setTextBlock(combinedText.trim())
    }
  }, [item])


  return (
    <div className='blog-card' onClick={handleClick} style={{ cursor: 'pointer' }}>
      <div className='blog-card__header'>
        <Image
          className='image-height-desktop image-height-mobile'
          width='100%'
          height='500px'
          preview={false}
          src={cover}
        />
        {isFeatured && <span>Artículo Destacado</span>}
      </div>
      <div className='byp-mt-1 blog-card__body'>
        <h4>{item?.title}</h4>
        <div className='extract'>
          <Paragraph ellipsis={{ rows: 3 }}>
            {textBlock}
          </Paragraph>
        </div>

        <div >
          <a>Leer Más</a>
          <a>
            <ArrowRightAltIcon />
          </a>
        </div>
      </div>
    </div>
  )
}
