import { useQuery } from 'react-query'
import CampusRespository from '../../repositories/CampusRespository'

function useGetCampusWithOffers (filters = {}, enabled = true) {
  const params = { whereHasOffers: true, ...filters }
  return useQuery(
    ['useGetCampusWithOffers', params],
    () => CampusRespository.campus(params),
    {
      retry: 1,
      enabled
    }
  )
}

export { useGetCampusWithOffers }
