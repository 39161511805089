import { Col } from 'antd'
import { toDate } from 'date-fns'
import format from 'date-fns/format'

function getStartLabel (start, program) {
  if (program === 'academic_years' || program === 'boarding_schools') {
    const date1 = new Date(start)
    const date = toDate(date1)
    date.setUTCDate(date.getDate())
    const date2 = new Date(date)
    return format(date2, 'MM/yyyy')
  }
  return start
}
const WithLoadignDates = ({ lodging, courseStart, courseEnd, program = 'language', t }) => (
  <>
    {(program === 'language' || program === 'summer_camps') && (
      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={12} className='quote-overview__date'>
        <div className='quote-overview__date--border-left'>
          <p className='byp-blue-100' style={{ margin: 0 }}>'Arrival'</p>
          <h6 className='byp-m-0 byp-fw-bold'>{lodging.start}</h6>
        </div>
        <div className='quote-overview__date--border-right '>
          <p className='byp-blue-100' style={{ margin: 0 }}>'Departure'</p>
          <h6 className='byp-m-0 byp-fw-bold'>{lodging.end}</h6>
        </div>
      </Col>
    )}
    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={program !== 'graduate' ? 12 : 24} className='quote-overview__date '>
      <div className='quote-overview__date--border-left '>
        <p className='byp-blue-100' style={{ margin: 0 }}>
          {program !== 'language' && 'Intake'}
          {(program === 'language' || program === 'summer_camps') && 'Start date'}
        </p>
        <h6 className='byp-m-0 byp-fw-bold'>
          {getStartLabel(courseStart, program)}
        </h6>
      </div>
      <div className='quote-overview__date--border-right'>
        <p className='byp-blue-100' style={{ margin: 0 }}>
          {program !== 'language' && 'Program lenght'}
          {(program === 'language' || program === 'summer_camps') && 'End date'}
        </p>
        <h6 className='byp-m-0 byp-fw-bold'>{courseEnd}</h6>
      </div>
    </Col>
  </>
)

const WithOutLodging = ({ courseStart, courseEnd, program = 'language', t }) => (
  <>
    <Col span={12}>
      <div className='quote-overview__date--border-left'>
        <p className='byp-blue-100' style={{ margin: 0 }}>
          {(program !== 'language' && program !== 'summer_camps') && "Intake"}
          {(program === 'language' || program === 'summer_camps') && 'Arrival date'}
        </p>
        <h6 className='byp-m-0 byp-fw-bold'>
          {getStartLabel(courseStart, program)}
        </h6>
      </div>
    </Col>
    <Col span={12}>
      <div className='quote-overview__date--border-right'>
        <p className='byp-blue-100' style={{ margin: 0 }}>
          {(program !== 'language' && program !== 'summer_camps') && 'Program lenght'}
          {(program === 'language' || program === 'summer_camps') && 'Departure date'}
        </p>
        <h6 className='byp-m-0 byp-fw-bold'>{courseEnd}</h6>
      </div>
    </Col>
  </>
)

export {
  WithLoadignDates,
  WithOutLodging
}
