import React from 'react'
import { logoEdit } from '../../../../utils/fallbackImage'
import { Form, Select } from 'antd'

const { Option } = Select

function EditBanner ({ image, setPosition, setRepeat, setSize }) {
  return (
    <div className='edit-home__section'>
      <h4>Banner Principla</h4>
      <span>Elegir Imagen</span>
      <label htmlFor='image01__page'>
        <div className='edit-home__img'>
          <img src={image ?? logoEdit} alt='edit-image' />
        </div>
      </label>

      <Form.Item
        label='Posición'
        className='edit-home__form-item byp-mt-1'
        name='position'
      >
        <Select onChange={setPosition} defaultValue='center center'>
          <Option value='center center'>Centro Centro</Option>
          <Option value='left top'>Arriba Izquierda</Option>
          <Option value='left center'>Izquierda Centro</Option>
          <Option value='left bottom'>Izquierda Abajo</Option>
          <Option value='right top'>Derecha Arriba</Option>
          <Option value='right center'>Derecha Centro</Option>
          <Option value='right bottom'>Derecha Abajo</Option>
          <Option value='center top'>Centro Arriba</Option>
          <Option value='center bottom'>Centro Abajo</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label='Repetir'
        className='edit-home__form-item'
        name='repeat'
      >
        <Select onChange={setRepeat} defaultValue='no-repeat'>
          <Option value='no-repeat'>No-repetir</Option>
          <Option value='repeat'>Repetir</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label='Tamaño'
        className='edit-home__form-item'
        name='size'
      >
        <Select onChange={setSize} defaultValue='cover'>
          <Option value='cover'>Abarcar</Option>
          <Option value='contain'>Contener</Option>
          <Option value='initial'>Inicial</Option>
          <Option value='auto'>Automático</Option>
        </Select>
      </Form.Item>
    </div>
  )
}

export default EditBanner
