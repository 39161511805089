import { Button, Modal } from 'antd'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { URL_REGISTER_PAGE_BETA } from '../../..'
import { useCreateUser } from '../../../../../bussines/hooks/agency/useCreateUser'

function ButtonAcceptTermsAndConditions ({ successRegister, setIsVerified }) {
  const navigate = useNavigate()

  const { state: locationState } = useLocation()
  const [modal, contextHolder] = Modal.useModal()
  const { register, currentState, state, isLoading, errorMessage, isVerified } = useCreateUser()

  useEffect(() => {
    if (!isLoading && currentState === state.STATE_ERROR) {
      modal.error({
        title: '',
        content: <>{errorMessage}</>
      })
    }
    if (!isLoading && currentState === state.STATE_SUCCESS) {
      successRegister && successRegister(true)
      if (successRegister && isVerified) {
        successRegister && setIsVerified(true)
        const route = process.env.REACT_APP_URL
        window.location.href = route
      }
    }
  }, [currentState, isLoading])

  const handleRegister = () => {
    register(locationState)
  }

  const handleBack = () => {
    navigate(URL_REGISTER_PAGE_BETA)
  }

  if (currentState === state.STATE_SUCCESS) {
    return null
  }

  return (
    <div>
      {contextHolder}

      <Button
        disabled={isLoading}
        loading={isLoading}
        onClick={handleBack}
        size='large'
        className='byp-btn-back'
      >
        {' '}
        Volver
      </Button>
      <Button
        disabled={isLoading}
        loading={isLoading}
        onClick={handleRegister}
        size='large'
        type='primary'
      >
        {' '}
        Confirmar
      </Button>
    </div>
  )
}

export { ButtonAcceptTermsAndConditions }
