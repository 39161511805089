/**
 * formatQuoterForm
 * @param {Number} courseId
 * @param {Object} values
 * @param {QuoteCalculatorService} service
 */

function formatQuoterForm (courseId, values, service, program = 'language') {
  // console.log("🚀 ~ file: formatQuoterForm.js:9 ~ formatQuoterForm ~ values", values)
  if (program === 'graduate' || program === 'academic_years' || program === 'boarding_schools') {
    return formatHEQuoterForm(courseId, values, service)
  }
  return {
    course: courseId,
    campus: values.campus,
    year: service.getPricesYear()?.id,
    student_id: values.student_id,

    startCourse: service.getStartCourseFormated(),
    endCourse: service.getEndCourseFormated(),
    weeks: values.weeks,
    hs_suplement: service.getHighSeasonFeesAmount(),
    course_discounts: service.getDiscountsAppliedInCourse(),
    minor_costs: service.minorCostValues(),

    hs_costs: service.getHighSeasonFeesCosts(),

    goodIds: service.getGoodsIds(),

    tuition: service.getCourseAmount(),
    tuition_discount: service.getCourseDiscountAmount(),
    tuition_discounts: service.getTuitionDiscountsValues(),

    materials: values.materials,
    material: {
      id: values.material,
      cost: service.getAmountMaterials()?.toFixed(2),
      discounts: service.getMaterialDiscountValues(),
      duration: service.durationMaterial()
    },

    medical_insurance: {
      id: values.medical,
      cost: service.getAmountMedical()?.toFixed(2),
      currency: service.getAgencyMedicalCurrency(),
      start: service.getMedicalInsuranceDates().start,
      end: service.getMedicalInsuranceDates().end,
      duration: service.getMedicalInsuranceDates().duration
    },

    favorite_name: values.favorite_name,
    favorite: values.favorite,
    assign_myself: values.assign_myself,
    assign_others: values.assign_others,

    inscription: {
      id: service.getTuitionFee()?.id,
      cost: service.getAmountInscription(),
      discount: service.getTuitionFeeDiscountsAmount()
    },

    lodging: {
      id: values.lodging,
      start: values.lodgingStart,
      weeks: service.getAmountsLodging().ls_weeks,
      hs_weeks: service.getAmountsLodging().hs_weeks,
      end: service.getLodgingEnd(),
      cost: service.getAmountsLodging().ls_cost,

      hs_suplement: service.getAmountsLodging().hs_cost,
      acomodation_fee_cost: service.getLodgingAcommodationFee(),
      acomodation_fee_discounts: service.getAcommodationFeeDiscountsValues(),

      hs_fees: service.LodgingPSFeesSelected(),

      extras: service.getLodgingSelectedExtraCosts(),

      discount: service.getLodgingDiscountsAmount(),
      discounts: service.getLodgingDiscountsValues(),

      extra_nights_start: service.getLodgingEnd() ?? null,
      extra_nights_end: service.getLodgingExEndFormated() ?? null,
      extra_nights_duration: service.getLodgingExAmounts().ls_days ?? null,
      extra_nights_cost: service.getLodgingExAmounts().ls_cost ?? null,
      extra_nights_hs_duration: service.getLodgingExAmounts().hs_days ?? null,
      extra_nights_hs_cost: service.getLodgingExAmounts().hs_cost ?? null
    },

    transport: {
      id: values.transport,
      ways: values.transport_way,
      cost: service.getAmountTransport(),
      hs_suplement: service.getTransportAmountHighSeason(),
      discount: service.getTransportDiscountsAmount(),
      discounts: service.getTransportDiscountsValues(),
      minor_costs: service.transportMinorCostValues()
    },

    divisa: service.getCurrencyId(),
    total: service.getSubTotalQuoteAmount(),
    discount: service.getTotalDiscounts(),

    totals: service.getTotalsAmount(),

    expiration: values?.expiration?.format('DD-MM-YYYY') ?? '',
    name: values?.client_name ?? '',
    message: values?.message ?? '',
    client_email: values?.client_email ?? null,
    student_nationality: values?.student_nationality ?? 0,
    student_location: values?.student_location ?? 0,

    extras: service.getFeesDiscountValues(),

    health_insurance: {
      id: values.health_insurance,
      cost: service.getAmountHealthInsurance(),
      discount: service.getInsuranceDiscountAmount(),
      discounts: service.getInsuranceDiscountValues(),
      duration: service.durationInsurance()
    },

    agency_services: service.getQuoteAgencyServices(),

    additional_services: service.getArrayOfServices(values.services_agency)
  }
}

function formatHEQuoterForm (courseId, values, service) {
  return {
    course: courseId,
    intake: service.getStartString(), // Intake
    campus: values.campus,
    year: service.getPricesYear()?.id,
    student_id: values.student_id,
    grade: values?.grade,

    startCourse: service.getStartCourse(),
    endCourse: service.getEndCourse(),
    weeks: values.length,
    duration: service.courseDuration(),
    hs_suplement: service.getHighSeasonFeesAmount(),
    goodIds: service.getGoodsIds(),

    tuition: service.getCourseAmount(),

    costs: service.getCourseAmounts(),
    course_discounts: service.getDiscountsAppliedInCourse(),

    tuition_discount: service.getCourseDiscountAmount(),
    tuition_discounts: service.getTuitionDiscountsValues(),

    materials: values.materials,
    material: {
      id: values.material,
      cost: service.getAmountMaterials(),
      discounts: service.getMaterialDiscountValues(),
      duration: service.courseDuration()
    },

    medical_insurance: {
      id: values.medical,
      cost: service.getAmountMedical()?.toFixed(2),
      currency: service.getAgencyMedicalCurrency(),
      start: service.getMedicalInsuranceDates().start,
      end: service.getMedicalInsuranceDates().end,
      duration: service.getMedicalInsuranceDates().duration
    },

    favorite_name: values.favorite_name,
    favorite: values.favorite,
    assign_myself: values.assign_myself,
    assign_others: values.assign_others,

    inscription: {
      id: service.getTuitionFee()?.id,
      cost: service.getAmountInscription(),
      discount: service.getTuitionFeeDiscountsAmount()
    },

    lodging: {
      id: values.lodging,
      start: values.lodgingStart,
      duration: service.getAmountsLodging().duration,
      weeks: service.getAmountsLodging().ls_weeks,
      hs_weeks: service.getAmountsLodging().hs_weeks,
      end: service.getLodgingEnd(),
      cost: service.getAmountsLodging().ls_cost,

      hs_suplement: service.getAmountsLodging().hs_cost,
      acomodation_fee_cost: service.getLodgingAcommodationFee(),
      acomodation_fee_discounts: service.getAcommodationFeeDiscountsValues(),

      hs_fees: service.LodgingPSFeesSelected(),

      extras: service.getLodgingSelectedExtraCosts(),

      discount: service.getLodgingDiscountsAmount(),
      discounts: service.getLodgingDiscountsValues(),
      // discount: service.getLodgingDiscountsAmount(),
      // discounts: service.getLodgingDiscountsValues(),

      extra_nights_start: service.getLodgingEnd() ?? null,
      extra_nights_end: service.getLodgingExEndFormated() ?? null,
      extra_nights_duration: service.getLodgingExAmounts().ls_days ?? null,
      extra_nights_cost: service.getLodgingExAmounts().ls_cost ?? null,
      extra_nights_hs_duration: service.getLodgingExAmounts().hs_days ?? null,
      extra_nights_hs_cost: service.getLodgingExAmounts().hs_cost ?? null
    },

    transport: {
      id: values.transport,
      ways: values.transport_way,
      cost: service.getAmountTransport(),
      hs_suplement: service.getTransportAmountHighSeason(),
      discount: service.getTransportDiscountsAmount(),
      discounts: service.getTransportDiscountsValues(),
      minor_costs: service.transportMinorCostValues()
    },

    divisa: service.getCurrencyId(),
    total: service.getSubTotalQuoteAmount(),
    discount: service.getTotalDiscounts(),

    totals: service.getTotalsAmount(),

    expiration: values?.expiration?.format('DD-MM-YYYY') ?? '',
    name: values?.client_name ?? '',
    message: values?.message ?? '',
    client_email: values?.client_email ?? null,
    student_nationality: values?.student_nationality ?? 0,
    student_location: values?.student_location ?? 0,

    extras: service.getFeesDiscountValues(),

    health_insurance: {
      id: values.health_insurance,
      cost: service.getAmountHealthInsurance(),
      discount: service.getInsuranceDiscountAmount(),
      discounts: service.getInsuranceDiscountValues(),
      duration: service.courseDuration()
    },

    agency_services: service.getQuoteAgencyServices(),

    additional_services: service.getArrayOfServices(values.services_agency)
  }
}

export { formatQuoterForm, formatHEQuoterForm }
