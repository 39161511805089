import { API_GEOCODE_KEY } from '../config'

// https://geocode.xyz/api
const getCountryName = async () => {
  return new Promise(async (resolve, reject) => {
    if ('geolocation' in navigator) {
      try {
        const position = await new Promise((innerResolve, innerReject) => {
          navigator.geolocation.getCurrentPosition(
            innerResolve,
            innerReject,
            { timeout: 5000 } // Adjust timeout as needed
          )
        })

        const lat = position.coords.latitude
        const lon = position.coords.longitude

        try {
          const response = await fetch(`https://geocode.xyz/${lat},${lon}?json=1&auth=${API_GEOCODE_KEY}`)
          const countriesData = await response.json()
          console.log('conutries api response', countriesData)
          if (countriesData && countriesData.prov !== 'Throttled! See geocode.xyz/pricing') {
            const countryCode = countriesData.prov
            const response = await fetch(`https://restcountries.com/v3.1/alpha/${countryCode}`)
            const country = await response.json()

            if (country && country.length > 0 && country[0]) {
              resolve(Object.keys(country[0].currencies)[0])
            }
          } else {
            reject(new Error('No se pudo obtener información de países.'))
          }
        } catch (error) {
          reject(error)
        }
      } catch (error) {
        // geolocation permission is denied or timed out
        const defaultCurrency = 'MXN' // Replace with your desired default currency
        resolve(defaultCurrency)
      }
    } else {
      // geolocation is not supported
      const defaultCurrency = 'MXN' // Replace with your desired default currency
      resolve(defaultCurrency)
    }
  })
}

/**
 * Return the name of the user located country
 * @returns string country name
 */
const getCountry = async () => {
  return new Promise(async (resolve, reject) => {
    if ('geolocation' in navigator) {
      try {
        const position = await new Promise((innerResolve, innerReject) => {
          navigator.geolocation.getCurrentPosition(
            innerResolve,
            innerReject,
            { timeout: 5000 }
          )
        })

        const lat = position.coords.latitude
        const lon = position.coords.longitude

        try {
          const response = await fetch(`https://geocode.xyz/${lat},${lon}?json=1&auth=${API_GEOCODE_KEY}`)
          const countriesData = await response.json()

          if (countriesData && countriesData.country !== 'Throttled! See geocode.xyz/pricing') {
            resolve(countriesData.country)
          } else {
            reject(new Error('No se pudo obtener información de países.'))
          }
        } catch (error) {
          reject(error)
        }
      } catch (error) {
        // geolocation permission is denied or timed out
        const defaultCurrency = 'Mexico' // Replace with your desired default currency
        resolve(defaultCurrency)
      }
    } else {
      // geolocation is not supported
      const defaultCurrency = 'Mexico' // Replace with your desired default currency
      resolve(defaultCurrency)
    }
  })
}

/**
 * Return the data {name, state} of the user located country
 * @returns { Object } { country, state, postal, timezone }
 */
const getCountryData = async () => {
  return new Promise(async (resolve, reject) => {
    if ('geolocation' in navigator) {
      try {
        const position = await new Promise((innerResolve, innerReject) => {
          navigator.geolocation.getCurrentPosition(
            innerResolve,
            innerReject,
            { timeout: 5000 }
          )
        })

        const lat = position.coords.latitude
        const lon = position.coords.longitude

        try {
          const response = await fetch(`https://geocode.xyz/${lat},${lon}?json=1&auth=${API_GEOCODE_KEY}`)
          const countriesData = await response.json()

          if (countriesData && countriesData.country !== 'Throttled! See geocode.xyz/pricing') {
            resolve({
              country: countriesData.country,
              city: countriesData.city,
              prov: countriesData.prov,
              state: countriesData.state,
              postal: countriesData.postal,
              timezone: countriesData.timezone
            })
          } else {
            reject(new Error('No se pudo obtener información de países.'))
          }
        } catch (error) {
          reject(error)
        }
      } catch (error) {
        // geolocation permission is denied or timed out
        resolve({
          status: 'Permission denied or timed out',
          country: null,
          city: null,
          prov: null,
          state: null,
          postal: null,
          timezone: null
        })
      }
    } else {
      // geolocation is not supported
      resolve({
        status: 'Geolocation is not supported',
        country: null,
        city: null,
        prov: null,
        state: null,
        postal: null,
        timezone: null
      })
    }
  })
}

const handlePrice = (price, changePrice, type = 'number') => {
  let newPrice = price
  if (type !== 'percentage') {
    if (typeof newPrice === 'string') {
      newPrice = parseFloat(price.replace(/,/g, ''))
    }
    const res = newPrice * changePrice
    return res.toLocaleString('en-US')
  }
  if (isNaN(newPrice)) {
    console.log('🚀 ~ file: getCountryName.js:98 ~ handlePrice ~ price, changePrice:', price, changePrice)
  }
  return newPrice
}

export { getCountryName, handlePrice, getCountry, getCountryData }
