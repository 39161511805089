
import { Button, Col, Image, Rate, Row } from 'antd'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { IconHeartFilled } from '../../../../src/common/assets/svg/icons'
// import { ThemeCarrouselSmall } from '../../../common/theme/partials/MainThemeCarrousel'
import { image } from '../../../utils/fallbackImage'
import { Link } from 'react-router-dom'
import { ThemeCarrouselSmall } from '../../../common/theme/partials/MainThemeCarrousel'
import React, { useEffect, useState } from 'react'
import { ModalAddToCollection } from '../../../common/components/ModalAddToCollection'
import { useAuth } from '../../../providers/auth/auth-context-provider'

const namesMonths = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic'
]

function CardWithImage({ noHeart, item, label, collections, refetchCollections }) {
  const [gallery, setGallery] = useState()

  const handleUrl = () => {
    let url = ''
    if (item.course?.type === 'package') {
      url = `/package-course/${item.course?.id}/${item.campus?.id}`
    }
    if (item.course?.type === 'short') {
      url = `/short-term-course/${item.course?.id}/${item.campus?.id}`
    }
    if (item.course?.type === 'course') {
      url = `/quote-course/${item.course?.id}/${item.campus?.id}`
    }
    if (item?.uuid) {
      return url + '?quote=' + item.uuid
    } else {
      return url
    }
  }

  const dateFormat = (dateCourse) => {
    if (dateCourse) {
      const date = new Date(dateCourse)
      const day = date.getDate()
      const month = namesMonths[date.getMonth()]

      return `${day} ${month}`
    }
    return ''
  }
  const { isPublicUser } = useAuth()

  const getLenght = (start, end) => {
    if (start && end) {
      const lenghtDate = new Date(end) - new Date(start)
      const differenceWeeks = Math.ceil(lenghtDate / (1000 * 60 * 60 * 24 * 7))
      return differenceWeeks
    }
  }

  const handleTime = (course) => {
    const start = dateFormat(course?.start_at)
    const end = dateFormat(course?.end_at)
    if (course?.properties?.min_weeks && course?.properties?.max_weeks) {
      if (course?.properties?.min_weeks !== course?.properties?.max_weeks) {
        return `${course?.properties?.min_weeks} o ${course?.properties?.max_weeks} semanas - ${start} - ${end}`
      } else {
        return `${course?.properties?.min_weeks} semanas - ${start} - ${end}`
      }
    }

    if (item.min_weeks) return item.min_weeks + ' semanas'

    return `${getLenght(course?.start_at, course?.end_at)} semanas - ${start} - ${end}`
  }

  // Color favorite button
  const [isIconClicked, setIsIconClicked] = useState(false)
  const [buttonColor, setButtonColor] = useState('#F9F9F9')
  const handleClick = () => {
    setIsIconClicked(!isIconClicked)
    setButtonColor(isIconClicked ? '#F9F9F9' : '#FF395C')
  }

  useEffect(() => {
    if (item) {
      const { course, campus } = item
      let nGallery = null
      if (course?.files?.length > 0) {
        nGallery = course.files
      } else if (campus?.images?.length > 0) {
        nGallery = course.files
      }
      setGallery(nGallery)
    }
  }, [item])

  return (
    <div className='box-shadow-cards card-package' style={{ padding: '0' }}>
      <div className="image-container" style={{ padding: '0' }}>
        {gallery?.length >= 1 && (
          <ThemeCarrouselSmall style={{ padding: '0' }} slides={gallery} urlProperty='src' inRandomOrder />
        )}
        {!gallery && (
          <Image
            width={'100%'}
            height={'200px'}
            src={item?.randomImage?.profile_photo_url ?? item?.campus?.profilePhotoUrl}
            fallback={image}
          />
        )}
      </div>

      {/** ¿QUE ES ESTO? --> Label de categoria para CURSOS
        label &&
          <div style={{ backgroundColor: '#FFFFFF', position: 'absolute', top: '40px', height: '32px', borderTopRightRadius: '12px', borderBottomRightRadius: '12px', padding: '0 15px', display: 'flex', alignItems: 'center', fontWeight: '500' }}>
            {item?.course?.category?.label}
          </div>
       **/}

      {/** FAVORITE BUTTON **/}
      <div className='float-button-favorite'>
        <ModalAddToCollection
          itemId={item.id}
          button={{
            style: { backgroundColor: 'transparent', width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0', borderRadius: '6.5px', borderColor: 'transparent' },
            text: '',
            className: '',
            icon: isIconClicked ?
              <IconHeartFilled style={{ width: '2rem', color: '#ff395c' }} /> :
              <FavoriteBorderIcon style={{ width: '2rem', color: '#ff395c' }} />
          }}
          isPublicUser={isPublicUser}
          collectionItems={collections}
          loadItems={false}
          refetchCollections={refetchCollections}
        />
      </div>
      <Link to={handleUrl()} style={{ color: '#797979', padding: '0px' }}>
        <Col style={{ padding: '15px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
          <Link to={handleUrl()} style={{ color: '#797979' }}>
            <p style={{ fontWeight: '600', lineHeight: '18px', fontSize: '18px', color: '#373737' }}>
              {item?.course?.label}
            </p>
          </Link>
          <p className='description__card'>
            {item?.course?.description}
          </p>
          {noHeart ?? (
            <p style={{ fontWeight: '700', fontSize: '12px', lineHeight: '18px' }}>
              {handleTime(item?.course)}
            </p>
          )}

          {/** STARS, OPINIONS, ARROW **/}
          <Row style={{ justifyContent: 'space-between', alignContent: 'center' }}>
            <Row>
              <Rate defaultValue={item?.rate ?? 5} disabled className='byp-gray-rate' allowHalf />
              <p style={{ fontSize: '12px', lineHeight: '15px', marginBottom: '0', display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
                {item?.rate_count ?? 0} Opiniones
              </p>
            </Row>
            <Col style={{ fontSize: '28px', lineHeight: '15px', marginBottom: '0', display: 'flex', alignItems: 'center', color: '#797979', justifyContent: 'center' }}>
              <ArrowRightAltIcon />
            </Col>
          </Row>
        </Col>
      </Link>
    </div>

  )
}

export { CardWithImage }
