import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useAllLanguages } from '../../../bussines/hooks/quoter/useAllLanguages'

const { Option } = Select

function SelectLanguageFormItem ({ name, label, ...rest }) {
  const { isLoading: isLoadingLanguage, data: languages } = useAllLanguages()
  const [allLangs, setAllLangs] = useState([])

  // Ordering first en, fr, de
  useEffect(() => {
    if (!isLoadingLanguage && languages) {
      const en = languages?.find((lang) => lang.code === 'en')
      const fr = languages?.find((lang) => lang.code === 'fr')
      const german = languages?.find((lang) => lang.code === 'de')
      const it = languages?.find((lang) => lang.code === 'it')
      const restLang = languages?.filter((lang) => {
        return (lang.code !== 'en' && lang.code !== 'fr' && lang.code !== 'de' && lang.code !== 'it')
      })
      setAllLangs([en, fr, german, it, ...restLang])
    }
  }, [languages, isLoadingLanguage])

  return (
    <Form.Item
      name={name}
      label={label}
      {...rest}
    >
      <Select
        placeholder='Idiomas'
        loading={isLoadingLanguage}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showSearch
        className='gray-background'
        {...rest}
      >
        {allLangs?.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
      </Select>
    </Form.Item>
  )
}

export { SelectLanguageFormItem }
