import { Form, Select } from 'antd'
import { useEffect } from 'react'
import { useGetInstitutions } from '../../../bussines/hooks/agency/useGetInstitutions'

const { Option } = Select

/**
 * Return Select for Main Campus
 */
function SelectInstitutions ({ name, type, showAll = false, onChange, selectProps = {}, ...props }) {
  const { data } = useGetInstitutions({})

  const handleChange = (val) => {
    if (onChange) {
      onChange(val)
    }
    console.log('data...', data)
  }
  return (
    <Form.Item
      name={name}
      {...props}
    >
      <Select
        filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
        onChange={handleChange}
        {...props}
        {...selectProps}
      >
        {showAll && <Option key={0} value={null}>All institutions </Option>}
        {data?.map((institution) => (
          <Option key={institution.id} value={institution.id}>{institution.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

/**
 * Return Select for Main Campus
 */
function SelectInstitutionsAdvance ({ name, type, showAll = false, isAdvance = false, countries = [], onChange, filters = {}, getMainCampusId = true, ...props }) {
  const { data, isLoading } = useGetInstitutions(isAdvance ? { countries, isadvance: true, ...filters } : { ...filters })

  useEffect(() => {
    if (!isLoading && data) {
      // console.log('🚀 ~ file: SelectInstitutions.js:34 ~ useEffect ~ data:', data, countries)
    }
  }, [isLoading])

  const handleOnchange = (e, option) => {
    if (onChange && e) {
      if (getMainCampusId) {
        onChange(e, option)
      } else {
        let nValue = e
        const id = e?.split('@')[0]
        const name = e?.split('@')[1]
        const item = data.find(i => i.id === Number(id))
        const instituionId = item?.institution_id
        if (instituionId) {
          nValue = instituionId + '@' + name
          console.log('🚀 ~ handleOnchange ~ nValue:', nValue)
        }
        onChange(nValue, option)
      }
    } else if (onChange) {
      onChange(e, option)
    }
  }

  return (
    <Form.Item
      name={name}
      {...props}
    >
      <Select {...props} onChange={handleOnchange} loading={isLoading} allowClear>
        {showAll && <Option key={0} value={null}>All institutions </Option>}
        {data?.map((institution) => (
          <Option key={institution.id + '@' + institution.name} value={institution.id + '@' + institution.name}>{institution.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}
/**
 * Return Select for Institutions
 */
function SelectSchools ({ name, type, showAll = false, firstOption = false, haveOptionAll = false, isReports, filters, selectProps = {}, ...props }) {
  const { data } = useGetInstitutions(filters)

  useEffect(() => {
    if (firstOption && data?.length > 0) {
      const { onChange } = props
      onChange(data[0].institution_id)
    }
  }, [data])

  return (
    <Form.Item
      name={name}
      {...props}
    >
      <Select
        filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
        {...props}
        {...selectProps}
      >
        {isReports && <Option value={null}>All the schools</Option>}
        {showAll && <Option key={0} value={null}>All institutions</Option>}
        {haveOptionAll && (
          <Option value='all'>All</Option>
        )}
        {data?.map((institution) => (
          <Option key={institution.id} value={institution.institution_id}>{institution.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

/**
 * Return Select for institutions
 */
function SelectInstitutionsWithOffers ({ name, showAll = false, onChange, onClear, filters = {}, customData, ...props }) {
  const { data, isLoading } = useGetInstitutions(filters, !customData)

  const handleOnchange = (e, option) => {
    if (onChange && e) {
      onChange(e, option)
    }
  }

  return (
    <Form.Item
      name={name}
      {...props}
    >
      <Select
        {...props}
        className='styled-select'
        onChange={handleOnchange}
        onClear={onClear}
        loading={isLoading}
        allowClear
        filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
      >
        {showAll && <Option key={0} value={null}>All institutions </Option>}
        {data?.map((campus) => (
          <Option key={campus.institution_id} value={campus.institution_id}>{campus.name}</Option>
        ))}
        {customData?.map((institution) => (
          <Option key={institution.id} value={institution.id}>{institution.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectInstitutions, SelectSchools, SelectInstitutionsAdvance, SelectInstitutionsWithOffers }
