import React from 'react'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import logo from './../../common/assets/images/logo.png'
import { MainTheme } from '../../common/theme/MainTheme'

const URL_DASHBOARD_PAGE = '/dashboard'

function DashboardPage() {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_SUPER_ADMIN,
        userRoles.TYPE_SUPER_EDITOR,
        userRoles.TYPE_AGENCY,
        userRoles.TYPE_AGENCY_OFFICE_MANAGER,
        userRoles.TYPE_AGENCY_MANAGER,
        userRoles.TYPE_AGENCY_COMMERCIAL_MANAGER,
        userRoles.TYPE_AGENCY_EDITOR,
        userRoles.TYPE_INSTITUTION,
        userRoles.TYPE_EG_ADMIN
      ]}
    >
      <MainTheme 
        showMenu
      >
        <div className='byp-dashboard-content byp-align-centered'>
          <img src={logo} className='dashboard-main-logo byp-mt-1' />
          <h4 className='byp-title'>Welcome to QYP the portal to quote and make your quotes</h4>

          <p className='dashboard-text'>
            We are extremely happy and equally excited to welcome you to our beta version. We are hoping that you will enjoy working with our plataform
            and we are excited to hear about your experience.
          </p>
          <p className='dashboard-text-blue'>
            As a beta tester, you'll be able to use features that haven't been released to the public.
            These features could become available in the future.
            <b> We ask that you don't publicize or share the features you're testing until they're publicly available.</b>
          </p>
          <p className='dashboard-text'>
            <b>Once again, a very warm welcome to QYP!</b>
          </p>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { DashboardPage, URL_DASHBOARD_PAGE }
