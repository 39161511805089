import { useQuery } from 'react-query'
import SchoolRepository from '../../repositories/SchoolRepository'

function useGetAllCampus (filters = {}, enabled = true) {
  const keys = Object.keys(filters)
  const validKeys = keys.filter(key => !!filters[key])
  const filtersQuery = validKeys.map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetAllCampus', filtersQuery],
    () => SchoolRepository.getCampus(filtersQuery), {
      retry: 1, enabled
    })
}

export { useGetAllCampus }
