import { Button, Col, DatePicker, Form, Input, Row, Select, Switch, Typography, notification } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
// import { SelectCurrencyFormItem } from '../../../common/components/helper-form/SelectCurrencyFormItem'
// import { SelectProgramsFormItem } from '../../../common/components/helper-form/SelectProgramsFormItem'
import { SelectLanguageFormItem } from '../../../common/components/helper-form/SelectLanguageFormItem'
import { SelectGenderFormItem } from '../../../common/components/helper-form/SelectGenderFormItem'
import { SelectReligionFormItem } from '../../../common/components/helper-form/SelecReligionFormItem'
import { SelectSportsFormItem } from '../../../common/components/helper-form/SelectSportsFormItem'
import { SelectExtracurricularFormItem } from '../../../common/components/helper-form/SelectExtracurricularFormItem'
import { useEffect, useState } from 'react'
import { AddAndRemoveListButtons } from '../Institution/components/AddAndRemoveListButtons'
import { SelectBranchesAll } from '../agency/components/SelectBranchesAll'
import { usePostCourses } from '../../../bussines/hooks/school/usePostCourses'
import { YearsFormList } from '../Institution/components/YearsFormList'
import { TablePackages } from '../agency/Tables/TablePackages'
import { useGetPackages } from '../../../bussines/hooks/packages/useGetPackages'
import { formatYearsList, formatYearsListToMoment, momentToDateformat } from '../../../utils/formatMoment'
import { useFindPackage } from '../../../bussines/hooks/packages/useFindPackage'
import moment from 'moment'
import { usePostUpdateCourse } from '../../../bussines/hooks/school/course/usePostUpdateCourse'
import { useDeleteService } from '../../../bussines/hooks/school/useDeleteService'
import { useGetInstitutionByProgram } from '../../../bussines/hooks/agency/analytics/program/useGetInstitutionsByProgram'
import { Option } from 'antd/lib/mentions'
import { SelectProgramsFormItem } from '../../../common/components/helper-form/SelectProgramsFormItem'
import { useGetCampusByInstitutionAndProgram } from '../../../bussines/hooks/agency/analytics/program/useGetCampusByInstitutionAndProgram'
import { useLocation } from 'react-router-dom'
import { SelectCampusByProgramFormItem } from '../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { useGetAllBranches } from '../../../bussines/hooks/agency/useGetAllBranches'
import { UploadSchoolMediaForm } from '../Institution/Schools/UploadSchoolMediaForm'
import { API_URL } from '../../../config'
import { showErrorModal } from '../../../utils/errorModal'
import { SelectCurrencyFormItem } from '../../../common/components/helper-form/SelectCurrencyFormItem'
import { BasicFormListItem } from '../../../common/components/BasicFormListItem'
import { SelectTagsFormItem } from '../blogs/components/SelectTagsFormItem'

const { Title, Paragraph } = Typography

const testValues = {
  // name: 'OPEN Package ED A',
  // service_categories_id: 4,
  // divisa_id: 7,
  // description: 'Package ED A',
  // language_id: 70,
  // service_terms: null,
  // comments: 'vc cvcbf',
  // min_age: 7,
  // max_age: 13,
  // min_weeks: 8,
  // max_weeks: 15,
  // institution_id: 54,
  // campus_id: 444,
  // spots: 41,
  // gender: 'Only boys',
  // school_link: 'vcbcvcb.com',
  // religion: 'Secular',
  // offices: [
  //   4,
  //   5,
  //   6,
  //   7,
  //   11,
  //   12,
  //   16,
  //   17,
  //   18,
  //   19,
  //   20,
  //   21,
  //   54,
  //   55
  // ],
  // program: 'boarding_schools',
  // unlimited: true
  requirements: [{}],
  characteristics: [{}]
}

function FormPackages () {
  const { user: _user } = useAuth()
  const location = useLocation()
  const { packageID } = location.state ?? { packageID: undefined }
  const [lenght, setLenght] = useState('')
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const [confirmLenght, setConfirmLenght] = useState(false)
  const [haveSpecificStartDates, setHaveSpecificStartDates] = useState(false)
  const [haveUnlimited, setHaveUnlimited] = useState(false)
  const [packageId, setPackageId] = useState(packageID)
  const [paginationData, setPaginationData] = useState({})
  const [filters, setFilters] = useState({ page: 1 })
  const [haveSports, setHaveSports] = useState(false)
  const [haveExtra, setHaveExtra] = useState(false)
  const [haveActivities, setHaveActivities] = useState(false)
  const [packageItem, setPackageItem] = useState(null)
  const { saveCourses, isLoading: isSaving, state, currentState, error } = usePostCourses()
  const { updateCourse, isLoading: isUpdating, error: updateError, currentState: updateState } = usePostUpdateCourse()
  const { deleteService, isLoading: isDeleting, currentState: deleteState } = useDeleteService()
  const { data: packages, isLoading: isLoadingPackages, isError, error: getPackagesError, refetch } = useGetPackages(filters)
  const { data: packageItemData, isLoading: searchingPackage, isError: packageNotFound, error: findPackageError, refetch: refetchPackage } = useFindPackage(packageId)

  const [form] = Form.useForm()
  const sports = Form.useWatch('includeSports', form)
  const extracurriculars = Form.useWatch('includeExtracurriculars', form)
  const activities = Form.useWatch('includeActivities', form)

  const [program, setProgram] = useState()
  const [institution, setInstitution] = useState()
  const [campus, setCampus] = useState()
  const [institutions, setInstitutions] = useState([])
  const [campusData, setCampusData] = useState([])
  const [type, setType] = useState(null)
  const [subType, setSubType] = useState(null)
  const [isAgency, setIsAgency] = useState(false)
  const [idBranch, setIdBranch] = useState()

  const { data, isLoading } = useGetInstitutionByProgram(program)
  const { data: campusDataApi } = useGetCampusByInstitutionAndProgram(program, institution)
  const { data: dataBranches } = useGetAllBranches({})

  const handleUniqueArr = (array) => {
    return array?.filter((item, index) => {
      return array.findIndex(obj => obj.id === item.id) === index
    })
  }

  useEffect(() => {
    if (packageItemData) {
      setPackageItem(packageItemData)
    }
  }, [packageItemData])

  const handleLenght = (date, dateString, type) => {
    console.log(date, dateString, type)
    if (type === 'start') {
      setStart(date)
    } else if (type === 'end') {
      setEnd(date)
    }
  }

  const disabledDateStart = (current) => {
    return (current < moment().startOf('day'))
  }

  const disabledDateEnd = (current) => {
    return (current < moment().startOf('day') || current < start)
  }

  const handleProgram = (val) => {
    const typeArray = ['diploma', 'advanced_diploma', 'certificate', 'bachelor', 'postgraduate', 'master']
    const subTypeArray = ['diploma', 'advanced_diploma', 'certificate']
    if (typeArray.includes(val)) {
      if (subTypeArray.includes(val)) {
        setType('diploma')
        setSubType(val)
      } else {
        setType(val)
      }
      setProgram('graduate')
    } else {
      setProgram(val)
    }
  }

  useEffect(() => {
    setCampusData(handleUniqueArr(campusDataApi))
  }, [campusDataApi])

  useEffect(() => {
    setInstitutions(handleUniqueArr(data))
  }, [data])

  useEffect(() => {
    setHaveExtra(extracurriculars)
    setHaveSports(sports)
    setHaveActivities(activities)
  }, [extracurriculars, sports, activities])

  const handleOnFinish = (values) => {
    values.service_type = 'package'
    values.start_at = momentToDateformat(values.start_at)
    values.end_at = momentToDateformat(values.end_at)
    values.years = formatYearsList(values.years)
    values.religion = form.getFieldValue('religion') ?? 'Secular'
    values.gender = form.getFieldValue('gender') ?? 'Mixed'
    values.comments = form.getFieldValue('comments')
    values.program = program
    values.offices = [idBranch]
    values.start_dates = values.start_dates?.map((item) => {
      return { date: momentToDateformat(item.date) }
    })

    const files = values.files?.filter(img => img.response?.id).map(img => img.response.id)
    values.files = files

    if (!values?.start_dates) {
      notification.error({
        message: 'Faltan start dates'
      })
      return
    }

    if (type && packageId) {
      values.type_program = type
    }

    if (type && !packageId) {
      values.type = type
    }

    if (subType) {
      values.sub_type = subType
    }
    // const istest = true
    // if (istest) return null
    packageId ? updateCourse(packageId, values) : saveCourses(values)
  }

  useEffect(() => {
    if (!searchingPackage && packageItem) {
      form.resetFields()
      const formatedYears = formatYearsListToMoment(packageItem.years)
      packageItem.start_at = moment(packageItem.start_at)
      packageItem.end_at = moment(packageItem.end_at)
      packageItem.start_dates = packageItem.start_dates?.map((item) => {
        return { date: moment(item.start_date) }
      })

      const newFiles = packageItem.files?.map(img => ({
        uid: img.id,
        name: img.name ?? img.url,
        status: 'done',
        url: img.profile_photo_url,
        response: {
          id: img.id
        }
      }))

      const requirements = packageItem.extras?.filter(p => p.type === 'requirement')
      const characteristics = packageItem.extras?.filter(p => p.type === 'characteristic')
      const skills = packageItem.tags?.filter(p => p.type === 'skill')
      const lodging = packageItem.extras?.filter(p => p.type === 'lodging')
      const lodgingDescription = packageItem.extras?.filter(p => p.type === 'lodging_description')

      const newValues = {
        ...packageItem,
        name: packageItem.label,
        ...packageItem.properties,
        years: formatedYears,
        unlimited: !!packageItem.properties?.spots,
        offices: packageItem.properties?.offices ?? [],
        religion: packageItem.properties.religion,
        gender: packageItem.properties.gender,
        comments: packageItem.comments,
        files: newFiles ?? [],
        divisa: packageItem.divisa_id,
        url_vimeo: packageItem.properties.url_vimeo,
        requirements,
        characteristics,
        skills: skills.map(s => s.name),
        lodging: lodging && lodging[0]?.value,
        lodging_description: lodgingDescription && lodgingDescription[0]?.value
      }
      if (packageItem?.properties?.sub_type) {
        newValues.program = packageItem?.properties?.sub_type
      } else if (packageItem?.properties?.type) {
        newValues.program = packageItem?.properties?.type
      } else {
        newValues.program = packageItem.category?.name
      }
      setHaveSpecificStartDates(newValues.start_dates?.length > 0)
      setConfirmLenght(newValues.start_dates?.length > 0 || newValues.max_weeks)
      setStart(newValues.start_at)
      setEnd(newValues.end_at)
      if (packageItem?.properties?.sports?.length > 0) {
        setHaveSports(true)
        newValues.includeSports = true
      }
      if (packageItem?.properties?.extracurriculars?.length > 0) {
        setHaveExtra(true)
        newValues.includeExtracurriculars = true
      }

      if (packageItem?.properties?.activities?.length > 0) {
        setHaveActivities(true)
        newValues.includeActivities = true
      }

      if (packageItem.properties.spots > 0) {
        setHaveUnlimited(false)
      } else {
        setHaveUnlimited(true)
      }
      setProgram(newValues.program)
      setInstitution(newValues.institution_id)
      setCampus(newValues.campus_id)
      newValues.officesall = newValues.offices.length === dataBranches.length
      form.setFieldsValue(newValues)
    } else if (!searchingPackage && packageNotFound) {
      notification.error({
        message: findPackageError.response?.data?.message
      })
      setPackageId()
    }
  }, [searchingPackage, packageItem, updateState])

  useEffect(() => {
    if (!isSaving && currentState === state.STATE_SUCCESS) {
      notification.success({
        message: 'Data saved'
      })
      refetch()
      form.resetFields()
    } else if (!isSaving && currentState === state.STATE_ERROR) {
      notification.error({
        message: error?.response?.data?.message ?? 'Error :c'
      })
    }
  }, [isSaving])

  useEffect(() => {
    if (!isUpdating && updateState === state.STATE_SUCCESS) {
      notification.success({
        message: 'Data updated'
      })
      refetch()
      form.resetFields()
      setPackageId(undefined)
      setPackageItem(null)
    } else if (!isUpdating && updateState === state.STATE_ERROR) {
      showErrorModal(updateError?.response?.data?.message)
    }
  }, [isUpdating])

  useEffect(() => {
    if (!isDeleting && deleteState === state.STATE_SUCCESS) {
      refetch()
      notification.success({
        message: 'Package deleted'
      })
    } else if (!isDeleting && deleteState === state.STATE_ERROR) {
      showErrorModal()
    }
  }, [isDeleting])

  useEffect(() => {
    if (start && end) {
      const lenghtDate = end - start
      const differenceWeeks = Math.ceil(lenghtDate / (1000 * 60 * 60 * 24 * 7))
      setLenght(differenceWeeks + ' weeks')
    }
  }, [start, end])

  useEffect(() => {
    if (!isLoadingPackages && isError) {
      notification.error({
        message: getPackagesError.response?.data?.message
      })
    }
    if (!isLoadingPackages && packages) {
      setPaginationData({
        page: packages.meta?.current_page,
        total: packages.meta?.total,
        pageSize: packages.meta?.per_page,
        size: packages.meta?.last_page,
        current: packages.meta?.current_page,
        onChange: (val) => setFilters({ ...filters, page: val })
      })
    }
  }, [isLoadingPackages])

  useEffect(() => {
    if (dataBranches && dataBranches?.length > 0) {
      setIdBranch(dataBranches[0].id)
    }
  }, [dataBranches])

  useEffect(() => {
    handleProgram(program)
  }, [program])

  useEffect(() => {
    if (_user.type === 'Institution') {
      setIsAgency(false)
      setProgram('short_term')
    } else if (_user.type === 'Agency') {
      setIsAgency(true)
    }
  }, [_user])

  return (
    <Form form={form} onFinish={handleOnFinish} initialValues={testValues} requiredMark={false} className='packages-form'>
      <div className='byp-dashboard-content'>
        <Row gutter={20}>
          <Col md={12}>
            <h4 style={{ paddingBottom: '30px' }}>Create your packages </h4>

            <SelectCurrencyFormItem
              name='divisa'
              label='Currency'
              labelCol={{ span: 5 }}
              labelAlign='left'
              size='small'
              className='br-05'
            />

            <p style={{ fontSize: '1.25rem' }}><b>  Name and description </b></p>
            <Form.Item
              label='Package name'
              labelCol={{ span: 5 }}
              labelAlign='left'
              name='name'
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Description'
              labelCol={{ span: 5 }}
              labelAlign='left'
              name='description'
            >
              <TextArea
                maxLength={600}
                showCount
              />
            </Form.Item>

            {/* Pictures and video */}
            <Row style={{ marginBottom: '1rem' }}>
              <Col xs={24} md={18} xxl={12}>
                <Title level={4} style={{ marginBottom: '0' }}>Pictures and video</Title>
                <Paragraph style={{ marginBottom: '1rem' }}>We invite you to upload al least 5 pictures and 1 video</Paragraph>

                <UploadSchoolMediaForm
                  name='files'
                  uploadName='document'
                  action={`${API_URL}/files`}
                  form={form}
                  maxCount={10}
                />
              </Col>
            </Row>
            <Form.Item
              label='URL vimeo'
              labelCol={{ span: 5 }}
              labelAlign='left'
              name='url_vimeo'
            >
              <Input placeholder='https://www.vimeo.com/isujgud' />
            </Form.Item>
            {/* Package program */}
            <p style={{ fontSize: '1.25rem', marginTop: '.5rem' }}><b>This package applies to</b></p>
            <Row gutter={12}>
              {isAgency &&
                <Col span={12}>
                  <SelectProgramsFormItem
                    formItemProps={{
                      labelCol: { span: 10 },
                      labelAlign: 'left',
                      label: 'Program type',
                      name: 'program'
                    }}
                    // placeholder=''
                    // style={{ width: '100%' }}
                    size='small'
                    value={program}
                    firstOption
                    onChange={(val) => {
                      setProgram(val)
                      setInstitution()
                      setCampus()
                    }}
                    setProgramName={setProgram}
                  />
                </Col>}
              <Col span={12}>
                <SelectLanguageFormItem
                  placeholder=''
                  label='Language'
                  name='language_id'
                  showSearch
                  size='small'
                  labelCol={{ span: 10 }}
                  labelAlign='left'
                />
              </Col>
              <Col span={24}>
                {program === 'short_term' && (
                  <Form.Item name='short_course_type' label='Short term courses type'>
                    <Select>
                      <Option value='Summer camp'>Summer camp</Option>
                      <Option value='Winter camp'>Winter camp</Option>
                      <Option value='Higher Education'>Higher Education</Option>
                      <Option value='Work and Study'>Work and Study</Option>
                      <Option value='Languages'>Languages</Option>
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>
            {/* Package age range */}
            <p style={{ fontSize: '.9rem' }}><b>Age range:</b></p>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label='Minimum'
                  name='min_age'
                  labelCol={{ span: 10 }}
                  labelAlign='left'
                >
                  <Input
                    size='small'
                    type='number'
                    min='5'
                    max='55'
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Maximum'
                  name='max_age'
                >
                  <Input
                    size='small'
                    type='number'
                    min='5'
                    max='55'
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Gender */}
            <SelectGenderFormItem
              labelAlign='left'
              labelCol={{ span: 5 }}
              size='small'
              name='gender'
              label='Gender'
              changeNameMix
            // defaultValue='mix'
            />
            {/* Religion */}
            <SelectReligionFormItem
              labelAlign='left'
              labelCol={{ span: 5 }} size='small' name='religion' label='Religion'
            // defaultValue='Secular'
            />

            <p style={{ fontSize: '1.25rem' }}><b>Accommodation and description</b></p>
            <Row align='middle' gutter={[16, 16]} className='byp-mb-1'>
              <Col span={7}>
                Acommodation name:
              </Col>
              <Col span={17}>
                <Form.Item
                  name='lodging'
                  className='byp-m-0'
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row align='middle' gutter={[16, 16]} className='byp-mb-1'>
              <Col span={7}>
                Description:
              </Col>
              <Col span={17}>
                <Form.Item
                  name='lodging_description'
                  className='byp-m-0'
                >
                  <TextArea maxLength={600} showCount />
                </Form.Item>
              </Col>
            </Row>

            {/* Program Specialty */}
            <p style={{ fontSize: '1.25rem' }}><b>Program Specialty </b></p>
            <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
              <Col span={7} style={{ fontSize: '.9rem' }}>
                <b>Sports programs </b>
              </Col>
              <Form.Item name='includeSports'>
                <Switch checked={haveSports} className='br-1' />
              </Form.Item>
            </Row>
            {sports && (
              <SelectSportsFormItem maxTagCount='responsive' size='small' name='sports' mode='multiple' style={{ width: '100%' }} />
            )}

            <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
              <Col span={7} style={{ fontSize: '.9rem' }}>
                <b>Curricular program </b>
              </Col>

              <Form.Item name='includeExtracurriculars'>
                <Switch checked={haveExtra} />
              </Form.Item>
            </Row>
            {extracurriculars && (
              <SelectExtracurricularFormItem maxTagCount='responsive' size='small' name='extracurriculars' mode='multiple' style={{ width: '100%' }} />
            )}

            <Row>
              <Col span={7} style={{ fontSize: '0.9rem' }}>
                <b>Actividades del programa</b>
              </Col>

              <Form.Item name='includeActivities'>
                <Switch checked={haveActivities} />
              </Form.Item>
            </Row>
            {activities && (
              <SelectTagsFormItem name='activities' size='small' filters={{ type: 'activities' }} />
            )}

            {isAgency && (
              <>
                {/* <SelectBranchesAll
                  mode='multiple'
                  name='offices'
                  label='Offices'
                  form={form}
                /> */}

                <Form.Item
                  name='institution_id' label='Institution' labelCol={{ span: 5 }} labelAlign='left' size='small'
                >
                  <Select
                    placeholder=''
                    style={{ width: '100%' }}
                    value={institution}
                    loading={isLoading}
                    onChange={(val) => {
                      setInstitution(val)
                      setCampus('undefined')
                    }}
                    showSearch
                    filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
                  >
                    {institutions?.map(institution => (
                      <Option key={institution.id} value={institution.id}>{institution.name}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name='campus_id' label='School' labelCol={{ span: 5 }} labelAlign='left' size='small'
                >
                  <Select
                    placeholder=''
                    style={{ width: '100%' }}
                    size='small'
                    value={campus}
                    loading={isLoading}
                    onChange={(val) => {
                      setCampus(val)
                    }}
                  >
                    {campusData?.map(campus => (
                      <Option key={campus.id} value={campus.id}>{campus.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            {!isAgency &&
              <SelectCampusByProgramFormItem
                program={program}
                label='School'
                labelCol={{ span: 4 }}
                labelAlign='left'
                size='small'
                name='campus_id'
                maxTagCount='responsive'
                form={form}
              />}

            <p style={{ fontSize: '1.25rem' }}><b>Time and availability</b></p>
            <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
              <p style={{ fontSize: '.9rem' }}><b>Please confirm if you package had a specific lenght or start dates</b></p>
              <Form.Item>
                <Switch onChange={setConfirmLenght} checked={confirmLenght} />
              </Form.Item>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  label='Start'
                  labelAlign='left'
                  labelCol={{ span: 10 }}
                  name='start_at'
                >
                  <DatePicker
                    format='MM/DD/YYYY' size='small'
                    onChange={
                      (e, value) => {
                        handleLenght(e, value, 'start')
                      }
                    }
                    disabledDate={disabledDateStart}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='End'
                  labelAlign='left'
                  labelCol={{ span: 10 }}
                  name='end_at'
                >
                  <DatePicker
                    format='MM/DD/YYYY' size='small' onChange={(e, value) => handleLenght(e, value, 'end')}
                    disabledDate={disabledDateEnd}
                  />
                </Form.Item>
              </Col>
            </Row>

            {!confirmLenght && (
              <>
                <Form.Item
                  label='Lenght'
                  labelAlign='left'
                  labelCol={{ span: 5 }}
                >
                  <Input size='small' value={lenght} disabled style={{ color: '#000' }} />
                </Form.Item>
                <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
                  <Col span={7} style={{ fontSize: '.9rem' }}>
                    <b>Specific start date</b>
                  </Col>

                  <Form.Item>
                    <Switch onChange={setHaveSpecificStartDates} checked={haveSpecificStartDates} />
                  </Form.Item>
                </Row>
                {
                  haveSpecificStartDates && (
                    <div>
                      <Form.List
                        name='start_dates'
                      // initialValue={[{}]}
                      >
                        {(fields, { add, remove }) => (
                          <div className={fields.length === 0 ? '' : 'white-background'}>
                            {fields.map(({ key, name, ...restField }, index) => (
                              <Row key={key} gutter={10}>
                                <Col>
                                  <Form.Item
                                    label='Start'
                                    name={[name, 'date']}
                                  >
                                    <DatePicker format='MMM DD' size='small' />
                                  </Form.Item>
                                </Col>
                                <Col style={{ display: 'flex', gap: '20px' }}>
                                  <AddAndRemoveListButtons
                                    fields={fields}
                                    index={index}
                                    add={add}
                                    remove={remove}
                                    name={name}
                                  />
                                </Col>
                              </Row>
                            ))}
                            {
                              fields.length === 0 && (
                                <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()}>
                                  Add
                                </Button>
                              )
                            }
                          </div>
                        )}
                      </Form.List>
                    </div>
                  )
                }
              </>
            )}
            {confirmLenght && (
              <>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label='Minimum '
                      labelAlign='left'
                      name='min_weeks'
                      labelCol={{ span: 10 }}
                    >
                      <Input size='small' type='number' min='1' />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label='Maximum '
                      labelAlign='left'
                      name='max_weeks'
                      labelCol={{ span: 10 }}
                    >
                      <Input size='small' type='number' min='1' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
                  <Col span={7} style={{ fontSize: '.9rem' }}>
                    <b>Specific start date</b>
                  </Col>

                  <Form.Item>
                    <Switch onChange={setHaveSpecificStartDates} checked={haveSpecificStartDates} />
                  </Form.Item>
                </Row>
                {
                  haveSpecificStartDates && (
                    <div>
                      <Form.List
                        name='start_dates'
                      // initialValue={[{}]}
                      >
                        {(fields, { add, remove }) => (
                          <div className={fields.length === 0 ? '' : 'white-background'}>
                            {fields.map(({ key, name, ...restField }, index) => (
                              <Row key={key} gutter={10}>
                                <Col>
                                  <Form.Item
                                    label='Start'
                                    name={[name, 'date']}
                                  >
                                    <DatePicker format='MMM DD' size='small' />
                                  </Form.Item>
                                </Col>
                                <Col style={{ display: 'flex', gap: '20px' }}>
                                  <AddAndRemoveListButtons
                                    fields={fields}
                                    index={index}
                                    add={add}
                                    remove={remove}
                                    name={name}
                                  />
                                </Col>
                              </Row>
                            ))}
                            {
                              fields.length === 0 && (
                                <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()}>
                                  Add
                                </Button>
                              )
                            }
                          </div>
                        )}
                      </Form.List>
                    </div>
                  )
                }
              </>
            )}

            <p style={{ fontSize: '1.25rem' }}><b>    Available spots </b></p>
            <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
              <Col span={7} style={{ fontSize: '.9rem' }}>
                <b>Unlimited</b>
              </Col>
              <Form.Item name='unlimited'>
                <Switch onChange={setHaveUnlimited} checked={haveUnlimited} />
              </Form.Item>
            </Row>
            {
              !haveUnlimited && (
                <Form.Item
                  label='Spots'
                  labelAlign='left'
                  labelCol={{ span: 5 }}
                  name='spots'
                >
                  <Input />
                </Form.Item>
              )
            }

            {/* Requisitos y Caracteristicas del paquete */}
            <h5 className='byp-title'>Requisitos</h5>
            <BasicFormListItem name='requirements' />
            <h5 className='byp-title'>Características del Paquete</h5>
            <BasicFormListItem name='characteristics' />
            <h5 className='byp-title'>Habilidades</h5>
            <SelectTagsFormItem name='skills' filters={{ type: 'skill' }} />

            {/* <p style={{ fontSize: '1.25rem' }}><b>Additional comments</b></p>
            <Form.Item
              name='comments'
              label='Comments'
              labelAlign='left'
              labelCol={{ span: 5 }}
            >
              <TextArea
                maxLength={600}
                showCount
              />
            </Form.Item> */}
            <p style={{ fontSize: '1.25rem', lineHeight: '0px' }}><b>Publication dates</b></p>
            <p style={{ lineHeight: '3px', fontSize: '13px' }}>(dates on which prices are publicated)</p>
            <YearsFormList
              rules={[{ required: true, message: 'This field is required' }]}
              name='years'
            />

            <Form.Item
              name='school_link'
              label='School link'
              labelAlign='left'
              labelCol={{ span: 5 }}
            >
              <Input
                addonBefore='http://'
              />
            </Form.Item>

            <div style={{ width: '100%', justifyContent: 'flex-end', display: 'flex', marginTop: '1rem' }}>
              <Button type='primary' htmlType='submit' loading={isSaving || isUpdating}>
                {packageId ? 'Update package' : 'Save package'}
              </Button>
            </div>
          </Col>
          <Col md={12}>
            <TablePackages
              packages={packages?.data ?? []}
              setId={setPackageId}
              refetch={refetchPackage}
              isLoading={isLoadingPackages}
              isDeleting={isDeleting || searchingPackage}
              deletePackage={deleteService}
              setFilters={setFilters}
              paginationData={paginationData}
            />
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export { FormPackages }
