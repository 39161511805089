import React, { useEffect, useState } from 'react'
import { Col, Row, Divider, Button } from 'antd'
import TotalQuotes from './TotalQuotes'
import MostPopularPrograms from './MostPopularPrograms'
import MostPopularCities from './MostPopularCities'
import AgencyUsers from './AgencyUsers'
import TopUsers from '../components/TopUsers'
import MostPopularSchools from '../components/MostPopularSchools'
import MostPopularDatesAndTimes from './MostPopularDatesAndTimes'
import TimeFilters from '../TimeFilters'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import { useDownloadGeneralAnalysis } from '../../../../../../src/bussines/hooks/agency/analytics/general/useDownloadGeneralAnalysis'
import { IconExcel } from '../../../../../common/assets/svg/icons'

let readOnly = true
function GenearlAnalysisInfo() {
  const { user: _user } = useAuth()

  const [time, setTime] = useState({})
  const [nameOffice, setNameOffice] = useState()
  const [idOffice, setIdOffice] = useState()
  const {
    downloadHistory,
    data: responseFile,
    isLoading: isDowloading
  } = useDownloadGeneralAnalysis()

  useEffect(() => {
    const roles = _user?.role_names ?? []

    if (roles?.includes('agency-admin') || roles?.includes('agency-editor')) {
      readOnly = false
    }

    if (roles?.includes('agency-office-manager')) {
      setNameOffice(_user?.sedes[0])
      setIdOffice(_user?.sede)
      readOnly = true
    }
  }, [_user])

  useEffect(() => {
    if (responseFile) {
      const url = window.URL.createObjectURL(new Blob([responseFile]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'GeneralAnalysis.xlsx')
      document.body.appendChild(link)
      link.click()
    }
  }, [responseFile])

  const handleDownload = () => {
    downloadHistory(time)
  }

  return (
    <>
      <Row className='byp-dashboard-content'>
        <Col span={24}>
          <Row>
            <Col span={16}>
              <h3 className='byp-title'>General analysis</h3>
            </Col>
            <Col span={8}>
              <Row justify='end'>
                <Button
                  style={{
                    backgroundColor: '#CDDFFF',
                    border: 'none',
                    color: '#2C2F66',
                    fontWeight: 'bold'
                  }}
                  onClick={handleDownload}
                  loading={isDowloading}
                >
                  <IconExcel />
                  <span
                    style={{ marginLeft: '7px' }}
                    className='align-icon-btn'
                  >
                    Download Excel
                  </span>
                </Button>
              </Row>
            </Col>
          </Row>

          <Row gutter={16} className='byp-mb-1' style={{ paddingTop: '20px' }}>
            <Col span={16}>
              <Button className='byp-bg-blue-200 byp-color-white'>
                {nameOffice ?? 'All offices'}
              </Button>
            </Col>

            <Col
            className='time-filter'
            >
              <TimeFilters setTime={setTime} />
            </Col>
          </Row>

          <Row gutter={16} style={{ justifyContent: 'center' }}>
            <Col className='two-thirds-responsive'>
              <h4 className='byp-title-4 byp-mb-1 byp-black'>Total quotes</h4>
              <TotalQuotes time={time} idOffice={idOffice} />

              <Divider
                style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <h4 className='byp-title-4 byp-mb-1 byp-black'>Most popular programs</h4>
                
              </div>
              <MostPopularPrograms time={time} office={idOffice} />

              <Divider
                style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }}
              />

              <h4 className='byp-title-4 byp-mb-1 byp-black'>
                Most popular cities
              </h4>
              <MostPopularCities time={time} office={idOffice} />

              <Divider
                style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }}
              />

              {_user.type !== 'Education group' && (
                <>
                  <h4 className='byp-title-4 byp-mb-1 byp-black'>
                    Most popular schools
                  </h4>

                  <MostPopularSchools time={time} office={idOffice} />
                  <Divider
                    style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }}
                  />
                </>
              )}

              <h4 className='byp-title-4 byp-mb-1 byp-black'>
                Most popular dates and times
              </h4>
              <MostPopularDatesAndTimes time={time} office={idOffice} />

              <Divider
                style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }}
              />
            </Col>
            {_user.type !== 'Education group' && _user.type !== 'Super Admin' && (
              <Col className='one-third-responsive'>
                <AgencyUsers readOnly={readOnly} office={idOffice} />
              </Col>
            )}
          </Row>

          <Col span={24}>
            {_user.type !== 'Education group' && (
              <>
                <h4 className='byp-title-4 quote-overview--color-blue byp-mb-1'>
                  Top users
                </h4>
                <TopUsers time={time} office={idOffice} />
              </>
            )}
          </Col>
        </Col>
      </Row>
    </>
  )
}

export { GenearlAnalysisInfo }
