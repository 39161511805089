import { useState } from "react";
import { useMutation } from "react-query";
import AgencyRepository from "../../../repositories/AgencyRepository";
import state from "../../../utils/state-control";

function useActiveInstitution() {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL);
  const mutation = useMutation(AgencyRepository.activeInstitution, {
    onSuccess: (d) => {
      console.log(d);
      setCurrentState(state.STATE_SUCCESS);
    },
    onError: (e) => {
      console.log(e);
      setCurrentState(state.STATE_ERROR);
    },
    throwOnError: true,
  });

  /**

   * @param data
   */
  const activeInstitution = (data) => {
    mutation.mutate(data);
    console.log(data);
  };

  return { ...mutation, state, currentState, activeInstitution };
}

export { useActiveInstitution };
