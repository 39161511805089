import { Col, notification, Progress, Row } from 'antd'
import { useEffect } from 'react'
import { useGetpopularDates } from '../../../../../../bussines/hooks/agency/analytics/general/useGetpopularDates'
import { BasicSpinner } from '../../../../../../common/components/BasicSpinner'

const ProgramPopularTimeRanges = ({ filters }) => {
  const { data, isLoading, error } = useGetpopularDates(filters)

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        // console.log('🚀 ~ file: MostPopularDatesAndTimes.js:11 ~ useEffect ~ data', data)
      } else if (error) {
        notification.error({
          message: 'Something went wrong'
        })
        // console.log('🚀 ~ file: MostPopularDatesAndTimes.js:13 ~ useEffect ~ error', error)
      }
    }
  }, [isLoading, data, error])

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={[8, 8]}>
      {data?.map((item, index) => (
        <Col
          xs={24}
          sm={12}
          xl={6}
          key={item.year + item.month + item.name + item.weeks + '_' + index}
        >
          <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem' }}>
            <p className='margin-0 byp-color-gray-100 byp-text-sm'>{item.quotes_count} - Quotes created</p>
            <Row>
              <Col span={15} className='byp-centered'>
                <h5 className='byp-title- quote-overview--color-blue'>
                  {item.month}
                </h5>
              </Col>
              <Col span={9}>
                <Progress
                  type='circle'
                  percent={item.quotes_percentage}
                  className='ant-progress-inner-small'
                  // trailColor='#2D2F66'
                  strokeColor='#2D2F66'
                />
              </Col>
            </Row>
            <div className='d-flex align-center' style={{ justifyContent: 'space-between' }}>
              <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>
                {!item.duration && (item.weeks + ' Semanas')}
                {item.duration}
              </p>
              <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>{item.label}</p>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export default ProgramPopularTimeRanges
