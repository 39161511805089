import React from 'react'
import { PlusOutlined } from '@ant-design/icons'

const CardEdit = ({ editModal }) => {
  return (
    <div className='card-edit' onClick={editModal}>
      <div className='card-edit__icon'>
        <PlusOutlined />
      </div>
    </div>
  )
}

export default CardEdit
