import { Button, Col, DatePicker, Form, Input, InputNumber, Rate, Row, Spin, Typography, notification } from 'antd'
import '../../../common/assets/css/rate-theme.less'
import { useSaveOpinion } from '../../../bussines/hooks/opinion/useSaveOpinion'
import { useEffect, useState } from 'react'
import stateControl from '../../../bussines/utils/state-control'
import { useFindOpinion } from '../../../bussines/hooks/opinion/useFindOpinion'
import { format } from 'date-fns'
import moment from 'moment'
import { OpinionSavedModal } from './OpinionSavedModal'
import SessionService from '../../../services/SessionService'
import { showErrorModal } from '../../../utils/errorModal'

const { TextArea } = Input

const disabledDates = (current) => {
  return current >= moment().endOf('day')
}

const OpinionForm = ({ courseId, campusSlug, setGallery, setShowRegisterModal }) => {
  const [showModal, setShowModal] = useState(false)
  const [form] = Form.useForm()
  const { saveOpinion, isLoading: isSaving, data, currentState, error } = useSaveOpinion()
  const { isLoading: isSearching, data: opinionData, refetch, isError, error: searchError } = useFindOpinion(campusSlug, courseId, {})

  useEffect(() => {
    if (!isSaving && data && currentState === stateControl.STATE_SUCCESS) {
      refetch()
      setShowModal(true)
    }
    if (!isSaving && error && currentState === stateControl.STATE_ERROR) {
      showErrorModal(null, error?.response?.data?.message)
    }
  }, [isSaving, currentState])

  useEffect(() => {
    if (!isSearching && opinionData) {
      // const momentDate = moment(opinionData.comment?.opinion_date)
      // const values = {
      //   campus_rate: opinionData.rates?.campus ?? 5,
      //   suport_rate: opinionData.rates?.suport ?? 5,
      //   activities_rate: opinionData.rates?.activities ?? 5,
      //   city_rate: opinionData.rates?.city ?? 5,
      //   support_rate: opinionData.rates?.support ?? 5,
      //   title: opinionData.comment?.title,
      //   body: opinionData.comment?.comment,
      //   date: momentDate,
      //   course_name: opinionData.comment?.course_name,
      //   rate: opinionData.rates?.general ?? 5,
      // }
      const values = {
        rate: 5,
        campus_rate: 5,
        suport_rate: 5,
        activities_rate: 5,
        city_rate: 5,
        support_rate: 5
      }
      if (opinionData.hasAccommodation) {
        values.accommodation_rate = 5
        // values.accommodation_rate = opinionData.rates?.accommodation ?? 5
      }
      form.setFieldsValue(values)
      if (opinionData.campus?.images?.length > 4) {
        setGallery(opinionData.campus?.images.slice(3))
      } else {
        setGallery(opinionData.campus?.images)
      }
    } else if (!isSearching && isError) {
      notification.error({
        message: searchError?.response?.data?.message ?? 'Something went wrong'
      })
    }
  }, [isSearching])

  const handleFinish = (values) => {
    if (SessionService.isPublicSession()) {
      if (setShowRegisterModal) {
        setShowRegisterModal(true)
      }
    } else {
      values.campus = opinionData?.campus?.id ?? campusSlug
      values.course = courseId
      const date = new Date(values.date)
      values.date = format(date, 'yyyy-LL-dd')
      saveOpinion(values)
    }
  }

  const handleCurrentYear = () => {
    const today = new Date()
    form.setFieldValue('date', moment(today))
  }

  return (
    <Form
      onFinish={handleFinish}
      labelCol={{ span: 8 }}
      labelAlign='left'
      wrapperCol={12}
      layout='horizontal'
      form={form}
      requiredMark={false}
      initialValues={{
        rate: 4.5,
        campus: 4.5,
        support: 4.5,
        accommodation: 4.5,
        activities: 4.5,
        city: 4.5
      }}
    >
      <Row gutter={12}>
        <Col xs={24}> {/* Header */}
          <h1 className='opinion-title'>¿Estudiaste en {opinionData?.campus?.name}?</h1> {isSearching && <Spin />}
          <Typography.Paragraph>Compartenos tu opinión para que otros estudiantes conozcan tu experiencia</Typography.Paragraph>
          <h5 className='opinion-subtitle'>¿Qué curso estudiaste?</h5>
          <Form.Item name='course_name'>
            <TextArea autoSize='1' className='form-item__textarea' maxLength={100} showCount placeholder='Estudie General English...' />
          </Form.Item>

          {/* <h5 className='opinion-subtitle'>Calificación general</h5>
          <Form.Item name='rate'>
            <Rate allowHalf className='byp-red-rate' allowClear={false} />
          </Form.Item> */}
        </Col>
        <Col xs={24}> {/* Fechaa */}
          <h5 className='opinion-subtitle'>¿Cuándo estudiaste?</h5>
          <Form.Item
            name='date' rules={[{
              required: true,
              message: 'Este dato es requerido'
            }]}
          >
            <DatePicker
              disabledDate={disabledDates}
              format='YYYY'
              picker='year'
              placeholder='(año)'
              onChange={(val) => {
                // setEndDate(val)
                // handleChangeDate(val, endName)
              }}
              renderExtraFooter={() => (
                <Button
                  title='Current year'
                  type='link'
                  style={{
                    color: 'var(--blue-100)',
                    width: '100%'
                  }}
                  onClick={handleCurrentYear}
                >
                  Current
                </Button>
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24}> {/* Rates */}
          {/* <h5 className='opinion-subtitle'>Califica las características</h5> */}
          {opinionData?.course?.properties.modality !== 'Online' &&
            <Form.Item name='campus_rate' label='Ubicación de la escuela' style={{ fontWeight: 'bold' }}>
              <Rate allowClear={false} allowHalf className='byp-red-rate' />
            </Form.Item>}
          {/* {isSearching && <Spin />} */}
          <Form.Item name='support_rate' label='Apoyo por parte de la escuela' style={{ fontWeight: 'bold' }}>
            <Rate allowClear={false} allowHalf className='byp-red-rate' />
          </Form.Item>
          {/* {isSearching && <Spin />} */}
          {/* {opinionData?.hasAccommodation && (
            <Form.Item name='accommodation_rate' label='Hospedaje' style={{ fontWeight: 'bold' }}>
              <Rate allowClear={false} allowHalf className='byp-red-rate' />
            </Form.Item>
          )} */}
          {/* {isSearching && <Spin />} */}
          <Form.Item name='activities_rate' label='Actividades y excursiones' style={{ fontWeight: 'bold' }}>
            <Rate allowClear={false} allowHalf className='byp-red-rate' />
          </Form.Item>
          {/* {isSearching && <Spin />} */}
          <Form.Item name='city_rate' label='País y ciudad' style={{ fontWeight: 'bold' }}>
            <Rate allowClear={false} allowHalf className='byp-red-rate' />
          </Form.Item>
          {/* {isSearching && <Spin />} */}
        </Col>
        <Col xs={24}> {/* opinion title */}
          <h5 className='opinion-subtitle'>Título de tu opinión</h5>
          <Form.Item
            name='title' rules={[{
              required: true,
              message: 'Este dato es requerido'
            }]}
          >
            <TextArea placeholder='La mejor experiencia de estudios...' className='form-item__textarea' maxLength={100} showCount rows={1} />
          </Form.Item>
        </Col>
        <Col xs={24}> {/* opinion commment */}
          <h5 className='opinion-subtitle'>Comparte tus comentarios y recomendaciones</h5>
          <Form.Item
            name='body' rules={[{
              required: true,
              message: 'Este dato es requerido'
            }]}
          >
            <TextArea rows={4} placeholder='Lo que más me gustó fue que...' className='form-item__textarea' maxLength={300} showCount />
          </Form.Item>
        </Col>
        <Col xs={24}> {/* opinion commment */}
          <h5 className='opinion-subtitle'>Código de referido</h5>
          <span style={{ fontSize: '0.75rem' }} className='byp-mb-1'>(Opcional)</span>
          <Form.Item
            name='code'
          >
            <InputNumber style={{ width: '100%' }} placeholder='0000' className='form-item__input-number' />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Button htmlType='submit' className='byp-btn-red-50 byp-mt-1' block loading={isSaving}>
            Enviar Opinión
          </Button>
        </Col>
      </Row>
      <OpinionSavedModal setIsModalOpen={setShowModal} isModalOpen={showModal} />
    </Form>
  )
}

export { OpinionForm }
