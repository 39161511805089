import { Checkbox, Col, Form, Row, Spin } from 'antd'
import { useGetAllCampus } from '../../../bussines/hooks/school/useGetAllCampus'

const CheckCampusFormItem = ({ filters, onChange, name, customData, loading, formItemProps, ...props }) => {
  const { data, isLoading } = useGetAllCampus(filters, !customData)

  return (
    <Form.Item name={name} style={{ marginBottom: 0 }} {...formItemProps} >
      <Checkbox.Group
        style={{ width: '100%' }}
        onChange={onChange}
        {...props}
      >
        <Row>
          {(loading || isLoading) && <Spin />}
          {data?.campus?.map(option => (
            <Col span={24} key={option.id + '-campus-graduate'}>
              <Checkbox className='school-search__checkbox' value={option.id}>{option.name}</Checkbox>
            </Col>
          ))}
          {(!loading && !isLoading) && customData?.map(option => (
            <Col span={24} key={option.id + '-campus-graduate'}>
              <Checkbox className='school-search__checkbox' value={option.id}>{option.name}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  )
}

export { CheckCampusFormItem }
