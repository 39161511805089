import React from 'react'
import { Col, Row, Typography } from 'antd'
import PropTypes from 'prop-types'

const { Title } = Typography

const TitleSectionQuote = ({ label, icon = null, extra = null, extra2 = null }) => {
  return (
    <Row className='d-flex align-center'>
      <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 17, order: 0, left: '1.875rem' }} lg={{ span: 18, order: 0, left: '1.875rem' }} style={{ left: '1.875rem' }}>
        <Title className='title-section byp-m-0-important quote-title-responsive' style={{ fontSize: '22px', fontStyle: 'normal', fontWeight: '700', display: 'flex', alignItems: 'center' }}>
          {icon ?? ''}
          <p style={{ color: '#2D2F66', paddingLeft: '1rem', margin: '0', marginRight: '5vw' }}>{label}&nbsp;</p>
        </Title>
      </Col>
      {/* <Col span={4} className='byp-centered'>{extra}</Col> */}
      <Col xs={{ span: 24, order: 0, textAlign: 'left' }} sm={{ span: 24, order: 0, textAlign: 'left' }} md={{ span: 7, order: 0, right: '1.875rem', textAlign: 'right' }} lg={{ span: 6, order: 0, right: '1.875rem', textAlign: 'right' }} xl={{ span: 6, order: 0, right: '1.875rem', textAlign: 'right' }} xxl={{ span: 6, order: 0, right: '1.875rem', textAlign: 'right' }} style={{ textAlign: 'end' }}>{extra2}</Col>
    </Row>
  )
}

TitleSectionQuote.propTypes = {
  label: PropTypes.string.isRequired
}

export { TitleSectionQuote }
