import { Card, Col, Image, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { InfoQuoteCampusCarrousel } from './InfoQuoteCampusCarrousel'
import { image } from '../../../utils/fallbackImage'
import { FaSchoolFlag, FaBookOpen } from 'react-icons/fa6'
import { institutionTypes } from '../../../languageConfiguration/infoCourseTranslate'
import generoImage from '../../../common/assets/images/genero.png'
import graduationImage from '../../../common/assets/images/graduation.png'

const InfoQuoteCampus = ({ course, campus, extras, tags }) => {
  const [, setSkills] = useState([])
  const [locations, setLocations] = useState([])
  const [included, setIncluded] = useState([])
  const [requirements, setRequirements] = useState([])
  const [, setTargets] = useState([])
  const [description, setDescription] = useState('')

  useEffect(() => {
    const requirements = extras?.filter((extra) => extra.type === 'requirement')
    const requirementsEs = requirements?.map((extra) => {
      const nameTranslated = extra?.translationables?.find(translation => translation.to === 'es')
      return {
        ...extra,
        value: nameTranslated?.translation ?? extra.value
      }
    })
    setRequirements(requirementsEs)

    const targets = extras?.filter((extra) => extra.type === 'target')
    const targetsEs = targets?.map((extra) => {
      const nameTranslated = extra?.translationables?.find(translation => translation.to === 'es')
      return {
        ...extra,
        value: nameTranslated?.translation ?? extra.value
      }
    })
    setTargets(targetsEs)

    // const description = extras?.find((extra) => extra.type === 'description')
    const translations = campus?.translationables?.filter(translation => translation.to === 'es')
    const description = translations?.find((t) => t.attribute === 'description')
    setDescription(description?.translation)
    console.log('🚀 ~ useEffect ~ translations:', description)
  }, [extras])

  useEffect(() => {
    const skills = tags?.filter((extra) => extra.type === 'skill')
    const skillsEs = skills?.map((extra) => {
      const nameTranslated = extra?.translationables?.find(translation => translation.to === 'es')
      return {
        ...extra,
        name: nameTranslated?.translation ?? extra.name
      }
    })
    setSkills(skillsEs)

    const included = tags?.filter((extra) => extra.type === 'included')
    const includedEs = included?.map((extra) => {
      const nameTranslated = extra?.translationables?.find(translation => translation.to === 'es')
      return {
        ...extra,
        name: nameTranslated?.translation ?? extra.name
      }
    })
    setIncluded(includedEs)

    const locations = tags?.filter((extra) => extra.type === 'places_of_interest')
    const locationsEs = locations?.map((extra) => {
      const nameTranslated = extra?.translationables?.find(translation => translation.to === 'es')
      return {
        ...extra,
        name: nameTranslated?.translation ?? extra.name
      }
    })
    setLocations(locationsEs)
  }, [tags])
  return (
    <Card style={{ height: '100%' }}>
      <Row>
        <Col span={24} className='info-quote__campus byp-mb-1'>
          <Image
            src={course?.offer?.campus?.profilePhotoUrl ?? image}
            style={{ width: '3.75rem', aspectRatio: '1' }}
            fallback={image}
          />
          {/* <img src={course?.offer?.campus?.profilePhotoUrl ?? image} alt={course?.offer?.campus?.name} style={{ width: '3.75rem', aspectRatio: '1' }} /> */}
          <h1>{course?.offer?.campus?.name}</h1>
        </Col>
      </Row>
      {/* <p className='quote-byp__mb'><span>Curso: </span>{course?.offer?.name}</p> */}

      {description && (
        <>
          <h3>Descripción</h3>
          <p className='quote-byp__mb quote-byp__description'>
            {description}
          </p>
        </>
      )}
      {!description && (
        <>
          <h3>Descripción</h3>
          <p className='quote-byp__mb quote-byp__description'>
            {course?.offer?.campus?.description}
          </p>
        </>
      )}

      {(course?.offer?.campus?.images && course?.offer?.campus?.images?.length > 0) && (
        <div className='images__carrousel'>
          <InfoQuoteCampusCarrousel slides={course?.offer?.campus?.images ?? []} className='carrousel_school-quote' />
        </div>
      )}

      {(course?.offer?.program?.name === 'academic_years' || course?.offer?.program?.name === 'boarding_schools') && (
        <>
          {course?.offer?.campus?.sports?.length > 0 && (
            <>
              <h3>Deportes que ofrece</h3>
              <Row gutter={[10, 10]} className='quote-byp__mb'>
                {course?.offer?.campus?.sports?.map((sport) => (
                  <Col key={sport}>
                    <div className='quote-byp__habilidades'>{sport}</div>
                  </Col>
                ))}
              </Row>
            </>
          )}

          {course?.offer?.campus?.extracurriculars?.length > 0 && (
            <>
              <h3>Clases extracurriculares</h3>
              <Row gutter={[10, 10]} className='quote-byp__mb'>
                {course?.offer?.campus?.extracurriculars?.map((extracurricular) => (
                  <Col key={extracurricular}>
                    <div className='quote-byp__habilidades'>{extracurricular}</div>
                  </Col>
                ))}
              </Row>
            </>
          )}

          {locations?.length > 0 && (
            <>
              <h3>Lugares de Interés</h3>
              <Row gutter={[10, 10]} className='quote-byp__mb'>
                {locations?.map((include) => (
                  <Col key={include.id}>
                    <div className='quote-byp__habilidades'>{include.name}</div>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </>
      )}

      {(course?.offer?.program?.name !== 'academic_years' && course?.offer?.program?.name !== 'boarding_schools') && (
        <>
          {included?.length > 0 && (
            <>
              <h3>Extras que ofrece</h3>
              <Row gutter={[10, 10]} className='quote-byp__mb'>
                {included?.map((include) => (
                  <Col key={include.id}>
                    <div className='quote-byp__habilidades'>{include.name}</div>
                  </Col>
                ))}
              </Row>
            </>
          )}

          {locations?.length > 0 && (
            <>
              <h3>Lugares de Interés</h3>
              <Row gutter={[10, 10]} className='quote-byp__mb'>
                {locations?.map((include) => (
                  <Col key={include.id}>
                    <div className='quote-byp__habilidades'>{include.name}</div>
                  </Col>
                ))}
              </Row>
            </>
          )}

          {requirements?.length > 0 && (
            <>
              <h3>Requisitos</h3>
              <ul className='quote-byp__mb quote-byp__list'>
                {requirements?.map((requirement) => (
                  <li key={requirement.id}>{requirement.value}</li>
                ))}
              </ul>
            </>
          )}
        </>
      )}

      <div className='d-flex'>
        {campus?.type && course?.offer?.program?.name !== 'language' && (
          <div className='quote-byp__icon-card byp-centered byp-mt-1'>
            <FaSchoolFlag />
            <span>Tipo de Escuela</span>
            <Typography.Title level={5}>{institutionTypes.es[campus.type] ?? campus.type}</Typography.Title>
          </div>
        )}

        {(course?.offer?.program?.name === 'academic_years' || course?.offer?.program?.name === 'boarding_schools') && (
          <>
            {campus?.gender && (
              <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                <img src={generoImage} style={{ width: '2.5rem' }} />
                <span>Género</span>
                <Typography.Title level={5}>{institutionTypes.es[campus.gender] ?? campus.gender}</Typography.Title>
              </div>
            )}
            {campus?.religion && (
              <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                <FaBookOpen />
                <span>Religión</span>
                <Typography.Title level={5}>{institutionTypes.es[campus.religion] ?? campus.religion}</Typography.Title>
              </div>
            )}
            {(campus?.grades && campus?.grades?.length > 0) && (
              <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                <img src={graduationImage} style={{ width: '2.5rem' }} />
                <span>Grados</span>
                <Typography.Title level={5}>{campus?.grades?.join('-')}</Typography.Title>
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  )
}

export { InfoQuoteCampus }
