import React, { useEffect, useState } from 'react'
import { Card, Col, Collapse, Divider, notification, Rate, Row } from 'antd'
import { QuoteOverviewCosts } from './QuoteOverviewCosts'
import { QuoteOverviewTotals } from './QuoteOverviewTotals'
import '../../../common/assets/css/main-theme.less'
import { usePostSendQuoteEmail } from '../../../bussines/hooks/quote/usePostSendQuoteEmail'
import { useFetchUser } from '../../../bussines/hooks/user/useFetchUser'
import { QuoteOverviewAgencyCosts } from './QuoteOverviewAgencyCosts'
import { QuoteOverviewMedicalAgencyCosts } from './QuoteOverviewMedicalAgencyCost'
import { useGetAgenExchangeValues } from '../../../bussines/hooks/currencies/useGetAgenExchangeValues'
import PlaceIcon from '@material-ui/icons/Place'
import { IconOpiniones } from '../../../common/assets/svg/icons'

const { Panel } = Collapse

export const QuoteOverview = ({
  course, discounts, campus, lodging, cost, agent,
  quoteId, campusCosts, agency, assistCard, logo, intake, arrivalDate, t
}
) => {
  const { isLoading, data: response, error } = usePostSendQuoteEmail()
  const { data: userData } = useFetchUser()
  const { data: exchangeValues, isLoading: loadingExchangeValues } = useGetAgenExchangeValues(agent?.profile?.user_id)

  const [exchangeVal, setExchangeVal] = useState()
  const [costsInsuranceAgency, setCostsInsuranceAgency] = useState([])
  const [costsAdditionalServices, setCostsAdditionalServices] = useState([])

  useEffect(() => {
    if (!isLoading && response?.send) {
      notification.success({
        placement: 'top',
        message: 'Email send'
      })
    } else if (!isLoading && error) {
      notification.error({
        placement: 'top',
        message: error?.response?.data?.message ?? 'something went wrong, please, try again'
      })
    }
  }, [isLoading, response, error])

  useEffect(() => {
    if (!loadingExchangeValues && exchangeValues) {
      const found = exchangeValues.find((value) => value?.code === campus?.divisa)
      const valueFound = found?.exchanges_values?.find((values) => values.divisa_id === agency?.divisa_id)
      setExchangeVal(valueFound?.value)
    }
  }, [loadingExchangeValues, exchangeValues])

  const getExchangeVal = (currency) => {
    const found = exchangeValues?.find((value) => value?.code === currency)
    const valueFound = found?.exchanges_values?.find((values) => values.divisa_id === agency?.divisa_id)
    return valueFound?.value
  }

  useEffect(() => {
    const resMedical = campusCosts?.filter(cost => {
      return cost?.isAgency === 'Agency' && cost?.type === 'insurance'
    })
    setCostsInsuranceAgency(resMedical)

    const divisasAdditional = {}
    campusCosts?.forEach(cost => {
      if (cost.isAgency === 'Agency' && cost.type === 'service') {
        if (divisasAdditional[cost.divisaAgency] === undefined) divisasAdditional[cost.divisaAgency] = []
        divisasAdditional[cost.divisaAgency].push(cost)
      }
    })
    setCostsAdditionalServices(divisasAdditional)
  }, [])

  return (
    <Card className='quote_paypal' style={{ height: '100%' }}>
      <Row justify='center'>
        <Col span={24}>
          <picture className='quote__overview-image'>
            <img src='https://images.unsplash.com/photo-1696229951930-df940c1e4255?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1933&q=80' />
          </picture>
        </Col>
      </Row>

      <Row justify='space-between' style={{ marginBottom: '1.25rem' }}>
        <Col className='quote__overview-info'>
          <h2>{course?.name}</h2>
          <p><span>Escuela:</span> {campus?.name}</p>
          <p style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}><PlaceIcon style={{ width: '1rem' }} /> {campus?.address}</p>
        </Col>
        <Col>
          <Row align='middle' gutter={[16, 16]}>
            <Col style={{ height: '100%' }}>
              <div className='quote__overview-rate'>
                {course?.rate ?? 5}
              </div>
            </Col>

            <Col>
              <Rate defaultValue={course?.rate ?? 5} disabled className='byp-gray-rate' allowHalf />
              <p className='quote__overview-opinions'><IconOpiniones width='15' height='15' /> {course?.rate_count ?? 0} Opiniones</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Collapse
        style={{ marginBottom: '1.25rem' }}
        defaultActiveKey={['0', '2', 'agency_insurance_', 'additional_services']}
        expandIconPosition='right'
        className='course-prices quote__overview-collapse'
      >
        <Panel
          header='Desglose'
          className='byp-p-0'
          key='0'
        >
          <QuoteOverviewCosts
            data={campusCosts}
            divisa={campus.divisa}
            type={agent.type}
            agentDivisa={agent.sede?.divisa}
            exchangeVal={exchangeVal}
            isLoading={loadingExchangeValues}
          />

          {
            agency?.services.length > 0 && (
              <QuoteOverviewAgencyCosts
                data={agency?.services}
                divisa={agency?.divisa}
                userData={userData}
                agentData={agent}
                exchangeVal={exchangeVal}
                getExchangeVal={getExchangeVal}
              />
            )
          }
          {
            Object.entries(costsAdditionalServices)?.length > 0 && Object.entries(costsAdditionalServices).map(([key, costAdditional]) => (
              <div key={key}>
                <QuoteOverviewMedicalAgencyCosts
                  data={costAdditional}
                  userData={userData}
                  agentData={agent}
                  exchangeVal={exchangeVal}
                  getExchangeVal={getExchangeVal}
                />
              </div>
            ))
          }
          {
            costsInsuranceAgency?.length > 0 && (
              <QuoteOverviewMedicalAgencyCosts
                data={costsInsuranceAgency}
                userData={userData}
                agentData={agent}
                exchangeVal={exchangeVal}
                getExchangeVal={getExchangeVal}
              />
            )
          }
        </Panel>
      </Collapse>

      <Row>
        <Col span={24}>
          <QuoteOverviewTotals
            totals={cost.totals}
            divisa={campus.divisa}
            agentDivisa={agent.sede?.divisa}
            agent={agent}
            exchangeVal={exchangeVal}
            course={course}
            className='quote-overview-totals'
            soloTotals
          />
        </Col>
      </Row>
      <Divider style={{ margin: '1rem 0' }} />
    </Card>
  )
}
