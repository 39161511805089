import { Col, notification, Row } from 'antd'
import React, { useEffect } from 'react'
import { useGetpopularDatesByUser } from '../../../../../bussines/hooks/agency/analytics/general/useGetpopularDates'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'

const PopularRangeTime = ({ time, user, office, campus }) => {
  const { data, isLoading, error } = useGetpopularDatesByUser({ user, office, limit: 4, campus, ...time })

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        // console.log('🚀 ~ file: MostPopularDatesAndTimes.js:11 ~ useEffect ~ data', data)
      } else if (error) {
        notification.error({
          message: 'Something went wrong'
        })
        console.log('🚀 ~ file: MostPopularDatesAndTimes.js:13 ~ useEffect ~ error', error)
      }
    }
  }, [isLoading, data, error])

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={[8, 8]}>
      {data?.map((item) => (
        <Col
          xs={24}
          sm={12}
          xl={6}
          key={item.year + item.month + item.name + item.weeks}
        >
          <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem' }}>
            <p className='margin-0 byp-color-gray-100 byp-text-sm'>{item.quotes_count} - Quotes created</p>
            <h5 className='byp-title- quote-overview--color-blue'>{item.month}</h5>
            <div className='d-flex align-center' style={{ justifyContent: 'space-between' }}>
              <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>
                {!item.duration && (item.weeks + ' Semanas')}
                {item.duration}
              </p>
              <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>{item.label}</p>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export default PopularRangeTime
