import { AutoComplete, Button, Col, Form, Row } from 'antd'
import EditIcon from '@material-ui/icons/Edit'
import { useEffect, useMemo, useState } from 'react'
import { useGetCountriesWithOffers } from '../../../bussines/hooks/places/useGetCountriesWithOffers'
import debounce from 'lodash/debounce'
// import { useGetLanguages } from '../../../bussines/hooks/quoter/useGetLanguages'
// import { useGetAllNationalities } from '../../../bussines/hooks/quoter/useGetAllNationalities'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { URL_QUOTER_PAGE } from '../../quoter'
import dayjs from 'dayjs'
import { useGetDates } from '../../../bussines/hooks/quoter/useGetSearchData'
// import { languagesEs } from '../../../bussines/utils/LanguagesES'

function ChooseCourse ({ program }) {
  const navigate = useNavigate()
  const [searchFilter, setSearchFilter] = useState()
  const [options, setOptions] = useState([])
  const [disabled, setDisabled] = useState(true)
  // const [weeks, setWeeks] = useState(2)
  const [availableMonthsAndYears, setAvailableMonthsAndYears] = useState([])
  const [stateSearch, setStateSearch] = useState()
  const { data, isLoading } = useGetCountriesWithOffers(searchFilter, stateSearch)
  // const { data: languages } = useGetLanguages(stateSearch)
  const { data: dates } = useGetDates(stateSearch)

  const location = useLocation()
  const receivedData = location.state

  // const debounceFilter = useMemo(() => {
  //   return debounce((key, value) => {
  //     setSearchFilter(value)
  //   }, 500)
  // })

  const handleEnter = (e) => {
    if (e.code === 'Enter') {
      if (!stateSearch?.idLocation) {
        if (options) {
          setStateSearch(currentValues => {
            currentValues.location = options[0].name
            currentValues.type = options[0].type
            currentValues.idLocation = options[0].id
            return currentValues
          })
          navigate(URL_QUOTER_PAGE, { state: { ...stateSearch, program } })
        }
      } else {
        navigate(URL_QUOTER_PAGE, { state: { ...stateSearch, program } })
      }
    }
  }

  useEffect(() => {
    if (data && !isLoading) {
      const countries = data.countries?.map((country) => ({
        id: country.id,
        value: country?.translations?.translation ?? country.name,
        iso: country.iso3,
        type: 'country'
      }))
      const cities = data.cities?.map((city) => ({
        id: city.id,
        value: city?.translations?.translation ?? city.name,
        iso: city.country?.iso3,
        type: 'city'
      }))

      // Combina países y ciudades y asigna claves únicas a cada elemento
      // const combinedOptions = [...countries, ...cities].map((option) => ({
      //   ...option,
      //   key: `${option.type}_${option.id}`
      // }))
      const combinedOptions = [...countries].map((option) => ({
        ...option,
        key: `${option.type}_${option.id}`
      }))

      setOptions(combinedOptions)
    }
  }, [isLoading, data])

  useEffect(() => {
    if (receivedData) {
      setStateSearch(currentValues => {
        currentValues.location = receivedData.name
        currentValues.type = receivedData.type
        currentValues.idLocation = receivedData.id
        return currentValues
      })
    }
  }, [receivedData])

  useEffect(() => {
    if (program) {
      const values = { program }
      if (program === 'language') {
        // values.is_legal = 'yes'
      }
      setStateSearch((currentValues) => {
        return { ...currentValues, ...values }
      })
    }
  }, [program])

  // const [selectedValue, setSelectedValue] = useState('')
  // const [optionsLanguages, setOptionsLanguages] = useState([])
  // const handleInputChange = (value) => {
  //   setSelectedValue(value)
  //   setStateSearch((currentValues) => {
  //     return { ...currentValues, searchLanguage: value }
  //   })
  //   const filteredOptions = languages?.filter((language) => {
  //     const txt = languagesEs[language.name] ?? language.name
  //     return txt.toUpperCase().includes(value.toUpperCase())
  //   })
  //   setOptionsLanguages(filteredOptions)
  // }

  useEffect(() => {
    setStateSearch(currentValues => {
      return { ...currentValues, searchLocation: searchFilter }
    })
  }, [searchFilter])

  // const handleAge = (value) => {
  //   setStateSearch((currentValues) => {
  //     return { ...currentValues, is_legal: value?.target?.value }
  //   })
  // }

  useEffect(() => {
    if (dates) {
      const data = dates?.data?.map(date => {
        const dateObject = dayjs(date.start_date)
        return {
          year: dateObject.year(),
          month: dateObject.month()
        }
      })
      setAvailableMonthsAndYears(data)
    }
  }, [dates])

  return (
    <>
      {/* <Form.Item>
        <AutoComplete
          className='school-search__item'
          size='large'
          value={selectedValue}
          onChange={handleInputChange}
          onSelect={(value, values) => {
            if (value) {
              setDisabled(false)
            } else {
              setDisabled(true)
            }
            setStateSearch(currentValues => {
              currentValues.language = value
              currentValues.idLanguage = values.id
              return currentValues
            })
          }}
          placeholder='¿Qué idioma te interesa?'
          filterOption={false}
          defaultOpen={false}
        >
          {optionsLanguages?.map((language) => (
            <AutoComplete.Option key={language.name} value={languagesEs[language.name] ?? language.name} id={language.id}>
              {languagesEs[language.name] ?? language.name}
            </AutoComplete.Option>
          ))}
        </AutoComplete>
      </Form.Item> */}

      {/* <Row align='middle' className='byp-mb-1' gutter={[16, 16]}>
        <Col>¿Eres mayor de 16 años?</Col>
        <Col>
          <Form.Item
            className='school-search__radio'
          >
            <Radio.Group
              name='radiogroup'
              onChange={handleAge}
              defaultValue='yes'
            >
              <Radio value='yes'>Sí</Radio>
              <Radio value='no'>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row> */}

      <Form.Item>
        <AutoComplete
          className='school-search__item'
          size='large'
          options={options}
          placeholder='¿Qué quieres estudiar?'
          filterOption={
            (inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onChange={(value) => {
            // debounceFilter('searchPlace', value)
            if (!value) {
              setStateSearch(currentValues => {
                delete currentValues.location
                delete currentValues.type
                delete currentValues.idLocation
                return currentValues
              })
            }
          }}
          value={receivedData?.name}
          onSelect={(value, place) => {
            if (value) {
              setDisabled(false)
            } else {
              setDisabled(true)
            }
            setStateSearch(currentValues => {
              currentValues.location = value
              currentValues.type = place.type
              currentValues.idLocation = place.id
              return currentValues
            })
          }}
          onInputKeyDown={handleEnter}
          notFoundContent='Sin resultados'
          // onPressEnter={() => console.log('asdsadsa')}
        />
      </Form.Item>

      {/* <InputNumber
        className='school-search__item byp-mb-1'
        size='large'
        onChange={e => {
          setWeeks(e)
          setStateSearch((currentValues) => {
            return { ...currentValues, weeks: e }
          })
        }}
        placeholder='¿Cuánto tiempo te gustaría irte a estudiar?'
        value={weeks}
        addonAfter='semanas'
        defaultValue={100}
      /> */}

      <Row justify='end'>
        <Col xs={24}>
          <Link to={URL_QUOTER_PAGE} state={{ ...stateSearch, program }} className='program__btn-float'>
            <Button
              className='program_filter_button'
              style={{
                width: '134px',
                height: '34px',
                fontSize: '12px',
                fontWeight: '600',
                color: '#FFF',
                backgroundColor: '#FF395C',
                borderColor: '#FF395C',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '16px 6px',
                gap: '6px',
                '&:hover': {
                  backgroundColor: '#FF627E !important'
                }
              }}
            > <EditIcon style={{ fontSize: '13px' }} />
              Ver opciones
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  )
}

export { ChooseCourse }
