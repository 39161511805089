import { Button, Row, Table, notification } from 'antd'
import { useGetExchangeValuesByCurrency } from '../../../bussines/hooks/currencies/useGetExchangeValuesByCurrency'
// import { useGetMyExchangeValues } from '../../../bussines/hooks/currencies/useGetMyExcahngeValues'
import { useEffect, useState } from 'react'
import stateControl from '../../../bussines/utils/state-control'
import { SelectCurrencyFormItem } from '../../../common/components/helper-form/SelectCurrencyFormItem'

function QuoteDivisa ({ divisa, lastCurrency, setExtraCoin, extraCoin }) {
  const { getExchangeValuesByCurrency, isLoading, data: newdata, currentState, error } = useGetExchangeValuesByCurrency()
  // const { data } = useGetMyExchangeValues()
  const [NewDivisa, setNewDivisa] = useState(false)

  useEffect(() => {
    if (!isLoading && currentState === stateControl.STATE_ERROR) {
      notification.error({
        message: error?.response?.data?.message ?? 'Something went wrong, please try again'
      })
    }
    if (!isLoading && currentState === stateControl.STATE_SUCCESS && newdata) {
      const data = newdata[0].data
      const total = lastCurrency
      const totalformated = total
      setExtraCoin({
        divisa: data.target_code,
        conversionRate: data.conversion_rate,
        conversionValue: (parseFloat(totalformated) * data.conversion_rate).toFixed(2)
      })
      // const message = 'New currency: ' + data.query.to + '  Exchange value: ' + data.result + ' * ' + totalformated + ' = ' + (parseFloat(totalformated) * data.result).toFixed(2)
      // console.log('🚀 ~ file: QuoteDivisa.js:29 ~ useEffect ~ message:', message, total)
      // notification.info({
      //   message
      // })
    }
  }, [isLoading, newdata])

  function handleClick (value) {
    setNewDivisa(true)
    getExchangeValuesByCurrency(value, [divisa])
  }

  function clicBottom () {
    setNewDivisa(false)
  }

  const columns = [
    {
      title: 'Divisa',
      render () {
        if (NewDivisa && extraCoin) {
          return (
            <Row style={{ columnGap: '20px', alignItems: 'center' }}>
              <b>{extraCoin.divisa}</b>
              <Button
                className='quote-divisa__btn'
                onClick={clicBottom}
              >
                Cambiar Divisa
              </Button>
            </Row>

          )
        } else {
          return (
            <Row>
              <SelectCurrencyFormItem
                name='currency'
                size='small'
                setDivisa={handleClick}
                sizeWidth='100%'
                style={{ width: '100%' }}
              />
            </Row>

          )
        }
      }
    },
    {
      title: 'Total',
      render () {
        if (NewDivisa && extraCoin) {
          return (
            <b>${Number(extraCoin.conversionValue).toLocaleString('en')}</b>
          )
        } else {
          return (
            <b>$-</b>
          )
        }
      }
    }
  ]

  const dataSource = [
    {
      key: '1'
    }
  ]

  return (
    <Table
      columns={columns} dataSource={dataSource} pagination={false}
      className='quote-overview-totals quote-overview-totals__divisa equal'
    />
  )
}

export { QuoteDivisa }
