import { Button, Card, Col, DatePicker, Form, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import 'moment/locale/es-mx'
// import locale from 'antd/es/date-picker/locale/es_ES'
import { AddAndRemoveListButtons } from './AddAndRemoveListButtons'
import { SelectStartAndEndDatesFormItem } from '../../../../common/components/helper-form/SelectStartAndEndDatesFormItem'
import React from 'react'
import moment from 'moment'

function YearsFormList ({ name, form, ...props }) {
  const thisList = Form.useWatch(name, form)

  const disabledStartDates = (current) => {
    let disabled = current < moment().startOf('year')
    return disabled
  }

  return (
    <Form.List
      name={name}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <React.Fragment key={key}>
              <Card key={key} className='byp-mb-1'>
                <Row gutter={12}>
                  <Col md={12} xl={10}>
                    <Form.Item label='Year' name={[name, 'year']} rules={[{ required: true, message: 'This field is required' }]}>
                      <DatePicker picker='year' format='YYYY' size='small' disabledDate={disabledStartDates}/>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <h6 className='byp-title'>Publication dates</h6>
                    <SelectStartAndEndDatesFormItem
                      startName={[name, 'start']}
                      endName={[name, 'end']}
                      handleChangeDate={(vals) => console.log(vals)}
                    />
                  </Col>
                  <Col span={24}>
                    <h6 className='byp-title byp-mt-1'>Start Dates</h6>
                    <SelectStartAndEndDatesFormItem
                      startName={[name, 'prices_start']}
                      endName={[name, 'prices_end']}
                      handleChangeDate={(vals) => console.log(vals)}
                    />
                  </Col>
                </Row>
              </Card>
              <Row className='w-100'>
                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                  notDelete={thisList?.length <= 1}
                />
              </Row>
            </React.Fragment>
          ))}
          {
            fields.length === 0 && (
              add()
            )
          }
        </>
      )}
    </Form.List>
  )
}

export { YearsFormList }
