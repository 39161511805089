import { useMutation } from 'react-query'
import state from '../../utils/state-control'
import { useState } from 'react'
import CollectionRepository from '../../repositories/CollectionRepository'

function useAddItemToCollection () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(CollectionRepository.addItem, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const addItem = (collectionId, itemData) => {
    mutation.mutate({ id: collectionId, data: itemData })
  }

  return { ...mutation, state, currentState, addItem }
}

export { useAddItemToCollection }
