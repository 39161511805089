import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useGetDiplomaSubtypes } from '../../../bussines/hooks/program/useGetDiplomaSubtypes'

const { Option } = Select
const labels = [
  {
    key: 'diploma',
    value: 'diploma',
    label: 'Diploma'
  },
  {
    key: 'advanced_diploma',
    value: 'advanced_diploma',
    label: 'Advanced Diploma'
  },
  {
    key: 'certificate',
    value: 'certificate',
    label: 'Certificate'
  }
]
// Return institutions types that has offers
const SelectDiplomaSubtypes = ({ name, filters = {}, onChange, selectProps = {}, ...formItemProps }) => {
  const [enabledFilters, setEnabledFilters] = useState(true)
  const [options, setOptions] = useState([])
  const { data } = useGetDiplomaSubtypes(filters, enabledFilters)

  useEffect(() => {
    if (data) {
      const nOptions = labels.filter((item) => data.find((dataItem) => dataItem === item.value))
      setOptions(nOptions)
    }
  }, [data])

  const handleOnChange = (val) => {
    if (val) {
      setEnabledFilters(false)
    } else {
      setEnabledFilters(true)
    }
    if (onChange) onChange(val)
  }

  return (
    <Form.Item
      name={name}
      {...formItemProps}
    >
      <Select
        showSearch
        onChange={handleOnChange}
        {...selectProps}
      >
        {options?.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectDiplomaSubtypes }
