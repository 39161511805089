import React from 'react'
import { IconCamping } from '../../../quote-preview/assets/imgs/Icons'
import { Form, Input, Select, Upload } from 'antd'
import { Link } from 'react-router-dom'
import { getBase64, headersForUpload } from '../../../../utils/uploadImageUtils'
import { API_URL } from '../../../../config'
import { useGetPackage } from '../../../../bussines/hooks/big-home/useGetPackage'
import { URL_PACKAGES_PAGE } from '../../packages/PackagesPage'

const { Option } = Select

function EditPackage ({ image, setImage, setTitlePackage, setIconPackageId, setSubTitlePackage, isLoading }) {
  const { data } = useGetPackage()

  const handleChange = (event) => {
    if (event.file.status === 'done') {
      getBase64(event.file.originFileObj, (imageUrl) => {
        setImage(imageUrl)
        // console.log('🚀 ~ file: FormEditBlog.js:44 ~ getBase64 ~ imageUrl:', imageUrl)
        setIconPackageId(event.file.response.id)
        // console.log('🚀 ~ file: FormEditBlog.js:46 ~ getBase64 ~ event.file.response:', event.file.response)
      })
    } else if (event.file.status === 'error') {
      console.log('🚀 ~ file error:', event.file.response)
    }
  }

  return (
    <div className='edit-home__section'>
      <h4>Sección paquetes</h4>
      <span>Icon</span>

      <label className='edit-home__section-icon byp-mb-1' htmlFor='icon__package'>
        {image ? <img src={image} /> : <IconCamping />}
      </label>

      <div style={{ display: 'none' }}>
        <Upload
          name='image'
          listType='picture-card'
          className='upload-page__img'
          showUploadList={false}
          action={`${API_URL}/pages/images`}
          id='icon__package'
          onChange={event => handleChange(event)}
          headers={headersForUpload()}
        >
          <img src={image ?? ''} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
        </Upload>
      </div>

      <span>Título</span>
      <Form.Item
        className='edit-home__form-item'
        name='title_package'
      >
        <Input onChange={e => setTitlePackage(e.target.value)} />
      </Form.Item>

      <span>Subtítulo</span>
      <Form.Item
        className='edit-home__form-item'
        name='subtitle_package'
      >
        <Input onChange={e => setSubTitlePackage(e.target.value)} />
      </Form.Item>

      <span>Paquetes a mostrar</span>
      <Form.Item
        className='edit-home__form-item input-margin-0'
        name='packages'
      >
        <Select
          mode='multiple'
          optionFilterProp='children'
        >
          {data?.data?.map(item => <Option key={item.id} value={item.course.id}>{item.course.label}</Option>)}
        </Select>
      </Form.Item>

      <Link to={URL_PACKAGES_PAGE}>
        <a className='edit-home__link'>Dar de alta un paquete</a>
      </Link>
    </div>
  )
}

export default EditPackage
