import { Button, Card, Col, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
const { Paragraph } = Typography
const InfoQuoteAccomodations = ({ course, accommodations }) => {
  const [list, setList] = useState([])
  // const [extraDescription, setExtraDescriptions] = useState([])

  useEffect(() => {
    if (accommodations?.length) {
      const fullList = accommodations
      setList(fullList.slice(0, 3))
    }
  }, [accommodations])

  const handleExtras = (id, type) => {
    const accommodation = accommodations.find(item => item.id === id)
    return accommodation?.extras?.filter(item => item?.type === type)
  }

  const handleExtraDescription = (extras) => {
    const extraDescription = extras?.find(item => item?.type === 'description')
    return extraDescription?.translationables?.find(t => t.to === 'es')?.translation ?? extraDescription?.value
  }

  const handleLoadMore = () => {
    const currentLength = list.length
    const fullList = accommodations
    setList(fullList.slice(0, currentLength + 3))
  }

  return (
    <Card style={{ height: '100%' }}>
      <h1>{course?.offer?.name}</h1>
      <p className='quote-byp__mb'>
        <span>Escuela: </span>
        <span className='upper fw-4'>{course?.offer?.campus?.name}</span>
      </p>
      <div style={{ height: '550px', overflowY: 'scroll' }}>

        {list?.map((accommodation) => (
          <Card key={accommodation.id} style={{ marginBottom: '1rem', backgroundColor: '#F8F8F8', boxShadow: '-3px 5px 13px 0px #0000001F' }}>
            <h5>{accommodation?.translationables?.find(translation => translation.to === 'es')?.translation ?? accommodation?.name}</h5>
            <Row gutter={[10, 10]} className='quote-byp__mb'>
              {accommodation?.tags?.map((include) => (
                <Col key={include.id}>
                  <div className='quote-byp__habilidades'>
                    {include.translations?.find(t => t.to === 'es')?.translation ?? include.name}
                  </div>
                </Col>
              ))}
            </Row>
            <h5 className='fw-7 fs-14'>Descripción</h5>
            <Paragraph ellipsis={{ rows: 3 }} className='quote-byp__mb quote-byp__description'>
              {handleExtraDescription(accommodation.extras) ?? accommodation?.description}
            </Paragraph>
            <h5 className='fw-7 fs-14'>Requisitos</h5>
            <ol className='quote-byp__mb quote-byp__description info-quote_list'>
              {handleExtras(accommodation.id, 'requirement')?.map(item => (
                <li key={item.id}>
                  {item.translationables?.find(t => t.to === 'es')?.translation ?? item.value}
                </li>
              ))}
            </ol>
            <h5 className='fw-7 fs-14'>Características del hospedaje</h5>
            <ol>
              {handleExtras(accommodation.id, 'characteristics')?.map(item => (
                <li key={item.id}>
                  {item.translationables?.find(t => t.to === 'es')?.translation ?? item.value}
                </li>
              ))}
            </ol>
          </Card>
        ))}

        {list.length < accommodations?.length && (
          <Button
            className='byp-btn-red-50'
            onClick={handleLoadMore}
          >
            Cargar más
          </Button>
        )}
      </div>
      {/* <h3>Requisitos</h3>
      <ul className='quote-byp__mb quote-byp__list'>
        {requirements?.map((requirement) => (
          <li key={requirement.id}>{requirement.value}</li>
        ))}
      </ul> */}
    </Card>
  )
}

export { InfoQuoteAccomodations }
