import { format } from 'date-fns'
import moment from 'moment'

const momentToDateformat = (momentObject, dateFormat = 'yyyy-LL-dd') => {
  const toDate = new Date(momentObject)
  return format(toDate, dateFormat)
}

const formatYearsList = (years) => {
  const formatedYeaars = years?.map(year => {
    const yearDate = new Date(year.year)
    const startDate = new Date(year.start)
    const endDate = new Date(year.end)
    const pricesStartDate = new Date(year.prices_start)
    const pricesEndDate = new Date(year.prices_end)
    return {
      id: year.id ?? null,
      year: format(yearDate, 'Y'),
      start: format(startDate, 'yyyy-LL-dd'),
      end: format(endDate, 'yyyy-LL-dd'),
      prices_start: format(pricesStartDate, 'yyyy-LL-dd'),
      prices_end: format(pricesEndDate, 'yyyy-LL-dd')
    }
  })
  return formatedYeaars
}

const formatYearsListToMoment = (years) => {
  const formatedYears = years?.map(yrs => ({
    id: yrs.id,
    year: moment(yrs.year),
    start: moment(yrs.start),
    end: moment(yrs.end),
    prices_start: moment(yrs.prices_start),
    prices_end: moment(yrs.prices_end)
  }))
  if (formatedYears?.length) {
    return formatedYears
  }
  return [{}]
}

const stringDateToMoment = (date) => {
  if (!date) return null
  const nDate = new Date(date)
  return moment(nDate)
}

export { momentToDateformat, formatYearsList, formatYearsListToMoment, stringDateToMoment }
