import { Button, Col, Form, Row } from 'antd'
import React from 'react'
import { IconPusOutlined } from '../../../../common/assets/svg/icons'
import ContainerImage from './ContainerImage'

export const UploadImage = ({ image, setImage, setImageUrl, imageUrl, form, setText, nameForm = 'publicity', haveTitle = true }) => {
  return (
    <Form.List
      name={nameForm}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row gutter={12} key={key}>
              <Col span={24}>
                <ContainerImage
                  remove={remove}
                  name={name}
                  setImage={setImage}
                  setImageUrl={setImageUrl}
                  image={image}
                  imageUrl={imageUrl}
                  setText={setText}
                  form={form}
                  haveTitle={haveTitle}
                  fieldName={nameForm}
                />
              </Col>
            </Row>
          ))}
          {fields?.length === 0 && (
            <Button
              onClick={() => add()}
              style={{
                display: 'flex',
                width: '100%',
                backgroundColor: '#1A171E',
                color: 'white',
                fontWeight: '400',
                fontSize: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1.5rem 0',
                gap: '1rem'
              }}
            >
              <IconPusOutlined color='white' />
              Subir más
            </Button>
          )}
        </>
      )}
    </Form.List>
  )
}
