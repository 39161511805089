import React, { useEffect, useState } from 'react'
import { Col, Divider, Row } from 'antd'
import { ButtonCategorySelector } from './ButtonsCategorySelector'
import { useGetMyServiceCategories } from '../../../bussines/hooks/school/useGetMyServiceCategories'
import { useSearch } from '../../../providers/search/search-context-provider'
// import LottieCargando from '../../../lotties/LottieCargando'
// import { AdvanceSearchModal } from './AdvancedSearchModal'
import { useLocation } from 'react-router-dom'
import SearchForm from './forms-search/SearchForm'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { saveInStorage } from '../../../utils/localStorageUtils'
import { getProgram, getProgramSubType, getProgramType } from '../../../utils/programUtils'
import moment from 'moment'
import AcademiYearsSearchForm from './forms-search/AcademicYearsSearchForm'
function getCollection (programs) {
  const menuItems = programs?.map((program) => (
    {
      label: program.label,
      key: program.name,
      form: <p>form</p>
    }
  ))
  return menuItems ?? []
}

const FilterCategory = ({ subType, staticPage = false, params, onlyMenu, status, setStatus, onChange, state, programsSorted, setState, program, onlyProgram = false }) => {
  const [collection, setCollection] = useState([])
  const [formSelected, changeFormSelect] = React.useState(program)
  const [, setIsActive] = useState(false)
  const [selected, setSelected] = useState(program)

  const { changeSearch, totalResults, search, enableSearch, setEnableSearch, setProgram, form } = useSearch()
  const { isLoading, data } = useGetMyServiceCategories()

  const {
    userLocalCurrency,
    // userCountryLocation,
    // setUserLocalCurrency,
    localCurrencyKey,
    isPublicUser
  } = useAuth()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const keysQueryParams = queryParams.keys()


  const handleChangeProgram = (program) => {
    changeFormSelect(program)
    setSelected(program)
    if (search && (search?.idCountry || search?.search)) {
      if (search?.country_type === 'country') {
        changeSearch('LANG_FORM', {
          all: true,
          program: getProgram(program),
          type: getProgramType(program),
          subtype: getProgramSubType(program)
        })
      } else {
        changeSearch('LANG_FORM', {
          all: true,
          program: getProgram(program),
          type: getProgramType(program),
          subtype: getProgramSubType(program)
        })
      }
    } else {
      changeSearch('LANG_FORM', {
        all: true,
        program: getProgram(program),
        type: getProgramType(program),
        subtype: getProgramSubType(program)
      })
    }
    if (setProgram) setProgram(program)
    if (setState) setState()
    if (onChange) onChange(program)
    form.resetFields()
  }

  const sortCollections = (data) => {
    const orden = [
      'language',
      'academic_years',
      'boarding_schools',
      'graduate',
      'short_term'
    ]
    let items = orden.map(orden => {
      return data.find(x => x.key === orden)
    })
    items = items.filter(item => item)

    setCollection(items)
  }

  useEffect(() => {
    if (location?.state?.program) {
      handleChangeProgram(location?.state?.program)
      setSelected(location?.state?.program)
    }
    const getCurrency = async () => {
      if (setStatus) setStatus(!status)
    }
    getCurrency()
  }, [])

  useEffect(() => {
    if (data && !isLoading) {
      const items = getCollection(data)
      const firstItem = items[0] ?? null
      if (!queryParams.has('program') && !state) {
        if (firstItem?.key !== 'graduate') {
          changeFormSelect(firstItem?.key ? firstItem?.key : null)
        } else {
          setSelected('diploma')
          changeFormSelect(subType)
        }
      }
      sortCollections(items)
      // Here makes the search at the home page by default
      if (!state && !search?.program && !onlyMenu) {
        if (!enableSearch) {
          setEnableSearch(true)
        }
        changeSearch('LANG_FORM', {
          ...search,
          program: firstItem?.key
        })
      }
    }
  }, [isLoading, data])

  useEffect(() => {
    if (data && !isLoading) {
      const items = getCollection(data)
      const firstItem = items[0] ?? null
      if (firstItem?.key === 'graduate') {
        setSelected(subType)
        changeFormSelect(subType)
      }
    }
  }, [subType])

  useEffect(() => {
    const getCurrency = async () => {
      if (userLocalCurrency) {
        if (setStatus) setStatus(!status)
        saveInStorage(localCurrencyKey, userLocalCurrency)
      }
    }
    getCurrency()
  }, [userLocalCurrency])

  // Update de navigator
  useEffect(() => {
    if (search?.program && !onlyProgram) {
      // console.log('🚀 ~ useEffect ~ search:', search, keysQueryParams)
      const queryString = Object.keys(search).map((key) => key + '=' + search[key]).join('&')
      window.history.pushState(search, '', location.pathname + '?' + queryString)
    }
  }, [search])

  useEffect(() => {
    if (queryParams.has('program') && !enableSearch && !onlyProgram) {
      const newValues = {}
      queryParams.forEach((value, key) => {
        newValues[key] = value
      })
      // console.log('🚀 ~ queryParams.forEach ~ newValues:', newValues)
      // TODO: Check how to handle the graduate program with the diploma type
      const program = newValues.program
      if (newValues.type === 'diploma') {
        changeFormSelect('graduate_diploma')
        setSelected('graduate_diploma')
        if (setProgram) setProgram('graduate_diploma')
        if (onChange) onChange('graduate_diploma')
      } else if (newValues.type) {
        changeFormSelect(newValues.type)
        setSelected(newValues.type)
        if (setProgram) setProgram(newValues.type)
        if (onChange) onChange(newValues.type)
      } else if (program) {
        changeFormSelect(program)
        setSelected(program)
        if (setProgram) setProgram(program)
        if (onChange) onChange(program)
      } else {
        // changeFormSelect('language')
        // setSelected('language')
        // if (setProgram) setProgram('language')
        // if (onChange) onChange('language')
        // console.log('🚀 ~ useEffect ~ DEAFULT:')
      }

      const nSearch = {
        ...newValues
      }
      if (!enableSearch) {
        setEnableSearch(true)
      }
      changeSearch('LANG_FORM', nSearch)
      // update form
      const formValues = {}
      formValues.city = newValues.arrayCities?.split(',').map(city => city + '-city')
      formValues.lang = newValues.arrayLang?.split(',').map(id => Number(id))
      formValues.is_legal = newValues.is_legal
      if (newValues.dateAll) {
        const splits = newValues.dateAll.split('/')
        const d = splits[0]
        const m = splits[1]
        const y = splits[2]
        formValues.date = moment(m + '/' + d + '/' + y)
      }
      if (newValues.place_type === 'campus') {
        formValues.campus = newValues.place
        formValues.school = Number(newValues.institution)
      }
      if (newValues.place_type === 'country') {
        formValues.country = newValues.place?.split(',').map(id => Number(id))
      } else if (newValues.country) {
        formValues.country = newValues.country?.split(',').map(id => Number(id))
      }
      if (newValues.weeks) formValues.weeks = Number(newValues.weeks)
      if (newValues.cost) formValues.cost = Number(newValues.cost)
      formValues.study_area = newValues.study_area
      formValues.materials = newValues.materials
      formValues.seasons = newValues.seasons
      formValues.type_school = newValues.arrayTypeSchool?.split(',').map(type => type)
      // To check
      if (newValues.place_type === 'institution') {
        formValues.school = Number(newValues.place)
      }
      form.setFieldsValue(formValues)
    }
  }, [keysQueryParams])

  useEffect(() => {
    if (state) {
      const newValues = {}
      if (state?.program) {
        const program = state?.program
        changeFormSelect(program)
        setSelected(program)
        if (getProgram(program)) {
          newValues.program = getProgram(program)
        }
        if (getProgramType(program)) {
          newValues.type = getProgramType(program)
        }
        if (getProgramSubType(program)) {
          newValues.subtype = getProgramSubType(program)
        }
      }

      if (state?.idLanguage && state?.language) {
        newValues.lang = state?.idLanguage
      } else if (state?.searchLanguage) {
        newValues.lang_search = state?.searchLanguage
      }

      if (state?.date) {
        newValues.dateAll = state?.date
      }

      if (state?.idLocation && state?.type) {
        newValues.place = state?.idLocation
        newValues.place_type = state?.type
      }
      if (state?.place && state?.place_type && !state?.arrayCities) {
        newValues.place = state?.place
        newValues.place_type = state?.place_type
      }

      if (state?.search) {
        newValues.search = state?.search
      }
      if (state?.age) {
        newValues.age = state?.age
      }
      if (state?.sports) {
        newValues.sports = state?.sports
      }
      if (state?.extras) {
        newValues.extras = state?.extras
      }
      if (state?.weeks) {
        newValues.weeks = state?.weeks
      } else if (state?.program === 'language') {
        newValues.weeks = 2
      }
      if (state?.arrayCities) {
        newValues.arrayCities = state?.arrayCities
      }
      if (state?.sub_type) newValues.sub_type = state?.sub_type
      if (state?.study_area) newValues.study_area = state?.study_area
      if (state?.is_legal && state?.program === 'language') newValues.is_legal = state?.is_legal

      const nSearch = {
        ...search,
        ...newValues
      }
      // console.log('🚀 ~ file: FilterCategory.js:225 ~ useEffect ~ nSearch:', nSearch, '______state____', state)
      if (!enableSearch) {
        setEnableSearch(true)
      }
      changeSearch('LANG_FORM', nSearch)
    }
  }, [state])

  if (onlyMenu) {
    return (
      <ButtonCategorySelector
        collection={collection}
        onChange={handleChangeProgram}
        selected={selected}
        program={program}
        setIsActive={setIsActive}
        programsSorted={programsSorted}
        onlyProgram={onlyProgram}
        setGhost
      />
    )
  }

  return (
    <>
      <div>
        <Divider style={{ backgroundColor: '#A8A8A8', width: '140%', marginLeft: '-20%' }} />
        <div className='home-btns-responsive ' style={{ margin: '0 2%', padding: '0 8px' }}>
          <Col span={24} className='byp-mb-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1.2rem' }}>
            <h4 style={{ fontWeight: 700, color: '#1A171E', fontSize: '20px' }}>Elige tu programa de interés.</h4>
          </Col>
          <Row gutter={20} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Col xs={24} lg={21} className='btns__programs'>
              <ButtonCategorySelector
                collection={collection}
                onChange={handleChangeProgram}
                selected={selected}
                setIsActive={setIsActive}
                program={formSelected}
                programsSorted={programsSorted}
              />
            </Col>
          </Row>
        </div>
        <Divider style={{ backgroundColor: '#A8A8A8', width: '140%', marginLeft: '-20%' }} />
        <div className='search-form-container'>
          {!staticPage &&
            <Row>
              <Col span={24}>
                {['academic_years', 'boarding_schools'].includes(formSelected) && (
                  <AcademiYearsSearchForm program={formSelected} state={state} />
                )}
                {!['academic_years', 'boarding_schools'].includes(formSelected) && (
                  <SearchForm program={formSelected} state={state} />
                )}
              </Col>
            </Row>}

        </div>
      </div>

      {/* <AdvanceSearchModal isActive={isActive} setIsActive={setIsActive} collection={collection} staticPage={staticPage} params={params} /> */}
      <div className='home-results-header'>
        <h6 style={{ color: 'var(--gray-100)' }}>
          Elige entre más de {totalResults} resultados. Precios sujetos a cambios sin previo aviso.
        </h6>
      </div>
    </>
  )
}

export { FilterCategory }
