import api from '../../services/api'

class EducationGroupRepository {
  async find () {
    const response = await api.get('/education-group/profile')
    console.log('🚀 ~ file: EducationGroupRepository.js:8 ~ EducationGroupRepository ~ find ~ response.data:', response.data)
    return response.data
  }

  async getInfoStep () {
    const response = await api.get('/education-group/steps')
    return response.data
  }

  async postDeleteLogo () {
    const response = await api.post('/education-group/delete/logo')
    return response.data
  }

  async postSteps ({ step, data }) {
    const response = await api.post(`/education-group/steps/${step}`, data)
    return response.data
  }

  async update (data) {
    const response = await api.put('/education-group/profile', data)
    return response.data
  }

  async activateIstitution (data) {
    const response = await api.post('/education-group/institution/activate', data)
    return response.data
  }
}

export default new EducationGroupRepository()
