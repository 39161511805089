import React, { useEffect, useState } from 'react'
import { Col, Image, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'
import icon from '../../../common/assets/images/icon.png'
import { ThemeCarrouselSmall } from '../../../common/theme/partials/MainThemeCarrousel'
import '../../../common/assets/css/card-paid-quote.less'
import { URL_QUOTE_SUMMARY_PAGE } from '../../quote-summary'
import { URL_APLICACION_PAGE } from '../../aplicacion'
import { programs } from '../../../bussines/utils/programTranslations'
import { formatStartDate } from '../../../utils/formatQuoteStartDate'
const { Title } = Typography

function CardPaidQuote ({ item }) {
  const [campus, setCampus] = useState()
  const [course, setCourse] = useState()
  const handleUrl = () => {
    return URL_QUOTE_SUMMARY_PAGE.replace(':id', item.quote.uuid)
  }
  const handleFormatoUrl = () => {
    return URL_APLICACION_PAGE.replace(':id', item.quote.uuid).replace(':applicationId', item.id)
  }
  const handleWhatsAppClick = () => {
    const mensaje = `Hola, me gustaría recibir más información sobre el curso ${course?.name} en ${campus?.city.name}, ${campus?.country.name}, en el que me acabo de registrar. Gracias.`
    const urlWhatsApp = `https://api.whatsapp.com/send/?phone=5213310922610&text=${mensaje}&type=phone_number&app_absent=0`
    window.location.href = urlWhatsApp
  }

  useEffect(() => {
    if (item) {
      setCampus(item.quote?.provider?.campus)
      setCourse(item.quote?.course)
    }
  }, [item])

  return (
    <div className='box-shadow-cards card-paid-quote'>
      <Row className='w-100'>
        <Col span={24} className='ant-image-h-100 ant-image-w-100' style={{ position: 'relative', borderTopLeftRadius: '20px' }}>
          {item.quote.provider.campus.gallery?.length >= 1 && (
            <ThemeCarrouselSmall slides={item.quote.provider.campus.gallery} urlProperty='src' inRandomOrder />
          )}
          {!item.quote.provider.campus.gallery?.length >= 1 && (
            <Image
              src={campus?.profilePhotoUrl || icon}
              style={{
                objectFit: 'cover',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                maxWidth: '100%',
                minHeight: '100%'
              }}
            />
          )}
          <span className='program-label'>
            {programs[item.quote.course.program] ?? item.quote.course.program} - {item.quote.provider.campus.country.name}, {item.quote.provider.campus.city.name}
          </span>
        </Col>
        <Col span={24} className='px-1'>
          <Link to={handleUrl()}>
            <Title className='course-title' style={{ fontSize: '20px' }}>{course?.name}</Title>
          </Link>
          <Link to={handleUrl()}>
            <p className='course-duration' style={{ fontSize: '13px', fontWeight: 'normal' }}>
              Curso de {item.quote.course.weeks ?? item.quote.course.course_length} semanas
            </p>
          </Link>
          <div className='p-1 bg-white' style={{ borderRadius: '16px' }}>
            Inicio de clases <br />
            <b>
              {item.quote?.intake ? (item.quote?.intake + ' ' + item.quote?.year?.year) : formatStartDate(item.quote?.course?.start)}
              {/* {item.quote.arrival_date} */}
            </b>
          </div>
          {/* <br /> */}
          <p style={{ fontSize: '12px', marginTop: '5px', marginBlockEnd: '5px' }}>Para continuar</p>

          {!item.form && (
            <Link to={handleFormatoUrl()} style={{ textDecoration: 'none', color: 'inherit' }}>
              <button className='quote-apply_now' style={{ padding: '5px', marginBottom: '10px' }}>
                Rellena tu formato de aplicación
              </button>
            </Link>
          )}

          <Link style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleWhatsAppClick} to='#'>
            <button className='quote-apply_now' style={{ padding: '10px', marginBottom: '10px', background: '#373737' }}>
              Contacta tu asesor
            </button>
          </Link>

          {/* <Link to={handleUrl()}>
            <Paragraph ellipsis={{ rows: 4 }} style={{ height: '5rem', overflow: 'auto' }}>
              {course?.description}
            </Paragraph>
          </Link> */}
          {/* {item?.payments?.map((payment) => {
            return (
              <React.Fragment key={payment.id}>
                <Paragraph>
                  {payment.amount} {payment.currency}
                </Paragraph>
                {payment.metadata?.receipt_url && (
                  <a href={payment.metadata?.receipt_url} rel='noreferrer'>
                    Receipt
                  </a>
                )}
              </React.Fragment>
            )
          })} */}
        </Col>
      </Row>
    </div>
  )
}

export { CardPaidQuote }
