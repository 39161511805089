import { Button, Col, Divider, Form, Row, notification } from 'antd'
import { UploadPageHeaderFormItem } from './UploadPageHeaderFormItem'
import EditBanner from './EditBanner'
import ButtonsProgram from './ButtonsProgram'
import { EditIcon, IconCamping } from '../../../quote-preview/assets/imgs/Icons'
import { HomeCarrousel } from '../../../../common/components/HomeCarrousel'
import { CardPlaces } from '../../../../common/components/CardPlaces'
import PlaceIcon from '@material-ui/icons/Place'
import { CardWithImage } from '../../../home/components/CardWithImage'

import { ImAirplane } from 'react-icons/im'
import avion from '../../../../common/assets/images/avion.png'
// import { LanguageCard } from '../../../home/components/languageCard'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import EditCitiesAndCountries from './EditCitiesAndCountries'
import EditPackage from './EditPackage'
import EditBlog from './EditBlog'
import { useEffect, useState } from 'react'
import { CardBlogSimple } from '../../blog/components/CardBlogSimple'
import { usePostBigHome } from '../../../../bussines/hooks/big-home/useSaveBigHome'
import { useGetBigHomePage, useGetPackage } from '../../../../bussines/hooks/big-home/useGetPackage'
import { BlogFooter } from '../../blogs/components/BlogFooter'

export const FormEditHome = () => {
  const [form] = Form.useForm()

  const idsPackages = Form.useWatch('packages', form)

  const { saveBigHome, isLoading: isSaving, data: saveResponse, error: saveError } = usePostBigHome()
  const { data } = useGetPackage(idsPackages ?? [], 'show')
  const { data: page, isFetching: isLoadingBigHomePage } = useGetBigHomePage()

  const [slides, setSlides] = useState(4)
  const [slidesBlog, setSlidesBlog] = useState(3)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const [programs, setPrograms] = useState(null)

  // banner section
  const [image, setImage] = useState(null)
  const [imageId, setImageId] = useState(null)
  const [position, setPosition] = useState('center center')
  const [repeat, setRepeat] = useState('no-repeat')
  const [size, setSize] = useState('cover')

  // cities section
  const [cities, setCities] = useState([])
  const [iconCity, setIconCity] = useState(null)
  const [citiesIds, setCitiesIds] = useState(null)
  const [iconCityId, setIconCityId] = useState(null)
  const [titleCity, setTitleCity] = useState('Lo mejor que puedes estudiar, por ciudad')
  const [subTitleCity, setSubTitleCity] = useState('Lo mejor de lo mejor')

  // package section
  const [iconPackage, setIconPackage] = useState(null)
  const [iconPackageId, setIconPackageId] = useState(null)
  const [titlePackage, setTitlePackage] = useState()
  const [subTitlePackage, setSubTitlePackage] = useState()

  // blog section
  const [blogs, setBlogs] = useState([])
  const [blogIds, setBlogIds] = useState(null)
  const [iconBlog, setIconBlog] = useState(null)
  const [iconBlogId, setIconBlogId] = useState(null)
  const [titleBlog, setTitleBlog] = useState('Blog')
  const [subTitleBlog, setSubTitleBlog] = useState('Conoce más del mundo')

  const onFinish = (values) => {
    if (imageId) values.cover = imageId
    if (iconCityId) values.iconCity = iconCityId
    if (iconPackageId) values.iconPackage = iconPackageId
    if (iconBlogId) values.iconBlog = iconBlogId
    if (programs) values.programs = programs
    const data = handleCountryCity(values.cities)
    values.cities = data.cities
    values.countries = data.countries

    values.style = {
      position,
      repeat,
      size
    }

    saveBigHome(values)
  }

  const handleCountryCity = (cities) => {
    const values = {
      countries: [],
      cities: []
    }

    cities?.forEach(item => {
      const value = item.split('-')
      if (value[1] === 'city') {
        values.cities.push(value[0])
      } else if (value[1] === 'country') {
        values.countries.push(value[0])
      }
    })

    return values
  }

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useEffect(() => {
    if (page && !isLoadingBigHomePage) {
      const data = page.page
      if (data) {
        let dataPosition, dataRepeat, dataSize
        data?.files?.forEach(item => {
          if (item.pivot.position === 'Header' && item.pivot.type === 'big-home') {
            setImage(item.src)
            setImageId(item.id)
            const styles = JSON.parse(item.properties)
            if (styles?.style?.position) setPosition(styles?.style?.position)
            if (styles?.style?.position) dataPosition = styles?.style?.position
            if (styles?.style?.repeat) setRepeat(styles?.style?.repeat)
            if (styles?.style?.repeat) dataRepeat = styles?.style?.repeat
            if (styles?.style?.size) setSize(styles?.style?.size)
            if (styles?.style?.size) dataSize = styles?.style?.size
          }
          if (item.pivot.position === 'Header' && item.pivot.type === 'icon-city') {
            setIconCity(item.src)
            setIconCityId(item.id)
          }
          if (item.pivot.position === 'Header' && item.pivot.type === 'icon-package') {
            setIconPackage(item.src)
            setIconPackageId(item.id)
          }
          if (item.pivot.position === 'Header' && item.pivot.type === 'icon-blog') {
            setIconBlog(item.src)
            setIconBlogId(item.id)
          }
        })
        const cities = getBlock(data, 'city')
        const countries = getBlock(data, 'country')
        const idsCities = getIds(cities?.cities, 'city')
        const idsCountries = getIds(countries?.countries, 'country')
        setCitiesIds([...idsCities, ...idsCountries])
        if (cities?.title) setTitleCity(cities?.title)

        const packageData = getBlock(data, 'package')
        const idsPackage = getIds(packageData?.courses, 'package')
        if (packageData?.title) setTitlePackage(packageData?.title ?? '¡Ya viene el verano!')
        if (packageData?.subtitle) setSubTitlePackage(packageData?.subtitle ?? 'Elige entre nuestro mejores campamentos')

        const blogs = getBlock(data, 'blog')
        const idsBlogs = getIds(blogs?.blogs, 'blog')
        if (blogs?.title) setTitleBlog(blogs?.title)
        if (idsBlogs) setBlogIds(idsBlogs)

        if (data?.configurations && data?.configurations?.length > 0) setPrograms(JSON.parse(data?.configurations[0]?.value))

        form.setFieldsValue({
          title_package: packageData?.title,
          subtitle_package: packageData?.subtitle,
          title_city: cities?.title,
          subtitle_city: cities?.subtitle,
          title_blog: blogs?.title,
          subtitle_blog: blogs?.subtitle,
          packages: idsPackage,
          blogs: idsBlogs,
          cities: citiesIds,
          position: dataPosition,
          repeat: dataRepeat,
          size: dataSize
        })
      }
    }
  }, [page, isLoadingBigHomePage])

  useEffect(() => {
    if (!isSaving && saveResponse) {
      // refetch()
      notification.success({
        message: 'Data saved'
      })
    }
    if (!isSaving && saveError) {
      notification.error({
        message: saveError?.response?.data?.message
      })
    }
  }, [isSaving, saveResponse])

  useEffect(() => {
    if (citiesIds) {
      form.setFieldsValue({
        cities: citiesIds
      })
    }
  }, [citiesIds])

  const getBlock = (data, type) => {
    return data?.blocks?.find(item => item.type === type)
  }

  const getIds = (data, type) => {
    if (type === 'city' || type === 'country') {
      return data?.map(item => item.id + '-' + type)
    }
    return data?.map(item => item.id)
  }

  useEffect(() => {
    form.setFieldsValue({
      title_city: titleCity,
      subtitle_city: subTitleCity,
      title_package: titlePackage,
      subtitle_package: subTitlePackage,
      title_blog: titleBlog,
      subtitle_blog: subTitleBlog
    })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (windowSize.width <= 800) {
      setSlides(1)
      setSlidesBlog(1)
    } else {
      setSlides(4)
      setSlidesBlog(3)
    }
  }, [windowSize])

  return (
    <Form
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={[16, 16]} justify='center'>
        <Col md={16} lg={20}>
          <Row justify='center'>
            <Col md={23}>
              <UploadPageHeaderFormItem
                setImage={setImage}
                image={image}
                setImageId={setImageId}
                position={position}
                repeat={repeat}
                size={size}
              />
              <Divider />

              <Row className='btns__programs byp-mt-1 byp-mb-1'>
                <ButtonsProgram setPrograms={setPrograms} sortPrograms={programs} />
              </Row>

              <div className='edit-home__divider'>
                <Divider />
                <p>Editar Ciudades <EditIcon /></p>
                <Divider />
              </div>

              <h4 style={{ color: '#373737' }} className='title-with-icon'>
                {iconCity ? <img src={iconCity} /> : <PlaceIcon />}
                {titleCity}
              </h4>
              <p style={{ color: '#797979' }}>{subTitleCity}</p>
              <Row>
                <Col span={24} style={{ width: '5rem' }}>
                  <HomeCarrousel slides={cities ?? []} Slide={CardPlaces} className='big-home__package' slidesPerRow={slides} />
                </Col>
              </Row>

              <div className='edit-home__divider'>
                <Divider />
                <p>Editar Paquetes <EditIcon /></p>
                <Divider />
              </div>

              <h4 style={{ color: '#373737' }} className='title-with-icon'>
                {iconPackage ? <img src={iconPackage} /> : <IconCamping />}
                {titlePackage}
              </h4>
              <p style={{ color: '#797979' }}>{subTitlePackage}</p>
              <Row>
                <Col span={24} style={{ width: '5rem' }}>
                  <HomeCarrousel slides={data?.data ?? []} Slide={CardWithImage} className='big-home__package' slidesPerRow={slides} />
                </Col>
              </Row>

              <div className='edit-home__divider'>
                <Divider />
                <p>Editar Banner Publicidad <EditIcon /></p>
                <Divider />
              </div>

              <div className='big-home__info'>
                <img src={avion} alt='Avion' />
                <Row className='big-home__info-text'>
                  <Col sm={2} lg={1} className='byp-centered'>
                    <ImAirplane className='big-home__imairplane' />
                  </Col>
                  <Col>
                    <h4 style={{ color: '#FFF' }}>Descubre el mundo, y aprende en el proceso</h4>
                  </Col>
                </Row>
                <Row className='big-home__info-text'>
                  <Col sm={2} lg={1} />
                  <Col>
                    <p style={{ color: '#FFF' }}>
                      1,925 diferentes cursos
                    </p>
                  </Col>
                </Row>
              </div>

              <div className='edit-home__divider'>
                <Divider />
                <p>Editar Blog <EditIcon /></p>
                <Divider />
              </div>

              <h4 style={{ color: '#373737' }} className='title-with-icon'>
                {iconBlog ? <img src={iconBlog} /> : <RecordVoiceOverIcon style={{ fontSize: '20px' }} />}
                {titleBlog}
              </h4>
              <p style={{ color: '#797979' }}>{subTitleBlog}</p>
              <Row style={{ padding: '0 2rem' }}>
                <Col span={23} style={{ width: '5rem' }}>
                  <HomeCarrousel slides={blogs ?? []} Slide={CardBlogSimple} className='big-home__package edit_carousel' slidesPerRow={slidesBlog} />
                </Col>
              </Row>

              <div className='edit-home__divider'>
                <Divider />
                <p>Editar Llamada a la Acción <EditIcon /></p>
                <Divider />
              </div>

              {/** LINK A HOME CHIQUITO **/}
              <BlogFooter />
            </Col>
          </Row>
        </Col>

        <Col md={8} lg={4} style={{ backgroundColor: '#373737' }}>
          <EditBanner
            image={image}
            setPosition={setPosition}
            setRepeat={setRepeat}
            setSize={setSize}
          />

          <EditCitiesAndCountries
            image={iconCity}
            setImage={setIconCity}
            setTitleCity={setTitleCity}
            setSubTitleCity={setSubTitleCity}
            setIconCityId={setIconCityId}
            setCities={setCities}
            citiesIds={citiesIds}
          />

          <EditPackage
            image={iconPackage}
            setImage={setIconPackage}
            setTitlePackage={setTitlePackage}
            setSubTitlePackage={setSubTitlePackage}
            setIconPackageId={setIconPackageId}
            isLoading={isLoadingBigHomePage}
          />

          <EditBlog
            setBlogs={setBlogs}
            setTitleBlog={setTitleBlog}
            setSubTitleBlog={setSubTitleBlog}
            image={iconBlog}
            setImage={setIconBlog}
            setIconBlogId={setIconBlogId}
            blogIds={blogIds}
          />

          <div className='edit-home__section'>
            <Row justify='end' className='byp-mt-1'>
              <Col>
                <Button
                  className='edit-home__apply'
                  size='middle'
                  htmlType='submit'
                  loading={isSaving}
                >Aplicar
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
