import { useEffect, useState } from 'react'
import { Avatar, Button, Card, Col, Form, Row, Typography, notification } from 'antd'
// import { BiCheck } from 'react-icons/bi'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useAuth } from '../../../providers/auth/auth-context-provider'
// import { GeneralAccountFields } from './GeneralAccountFields'
import { ProfileAccountFields } from './ProfileAccountFields'
import { SecurityAccountFields } from './SecurityAccountFields'
import { useUpdateUserProfile } from '../../../bussines/hooks/user/useUpdateUserProfile'
import stateControl from '../../../bussines/utils/state-control'
// import { DocumentsAccountFields } from './DocumentsAccountFields'
import { BYP_PUBLIC_USER } from '../../../config'
import { BasicSpinner } from '../../../common/components/BasicSpinner'
import { useNavigate } from 'react-router-dom'
import { momentToDateformat, stringDateToMoment } from '../../../utils/formatMoment'
// import { MyTravelsList } from '../../quotes/components/MyTravelsList'
const { Title, Text } = Typography
const AccountForm = () => {
  const navigate = useNavigate()
  const { user, refetch, isValidatingUser } = useAuth()
  const [, setConfirmedData] = useState([{ name: 'Pasaporte' }, { name: 'Email' }, { name: 'Telefono' }])
  const [ iso3, setIso3] = useState()
  const [form] = Form.useForm()
  // const [selected, setSelected] = useState('General')

  const { nextStep, isLoading: isSaving, currentState, error } = useUpdateUserProfile()

  const handleOnFinish = (values) => {
    console.log('🚀 ~ file: AccountForm.js:17 ~ handleOnFinish ~ values:', values)
    values.date_of_birth = momentToDateformat(values.date_of_birth)
    
    // Hace update del usuario
    nextStep(null, values)
  }

  useEffect(() => {
    if (user) {
      if (user?.email === BYP_PUBLIC_USER) {
        navigate('/login')
      }
      const contacts = user.emergency_contacts?.map(contact => ({
        id: contact.id,
        full_name: contact.name,
        kinship: contact.role,
        cel: contact.tel,
        email: contact.email
      }))
      let documents = [{
        name: 'Pasaporte',
        type: 'passport'
      }]
      if (user.documents?.length) {
        documents = user.documents?.map((doc) => ({
          name: doc.description,
          type: doc.type,
          file: {
            id: doc.id,
            name: doc.name,
            url: doc.src,
            status: doc.status
          },
          status: doc.status
        }))
      }
      form.setFieldsValue({
        name: user.name,
        last_name: user.last_name,
        email: user.email,
        phone: user.profile?.phone,
        school: user.profile?.school_of_study,
        languages: JSON.parse(user.profile?.languages ?? '[]'),
        allergies: JSON.parse(user.profile?.allergies ?? '[]'),
        not_eat: JSON.parse(user.profile?.diet ?? '[]'),
        pets: JSON.parse(user.profile?.accept_pets ?? '[]'),
        profile_photo_url: user.profile_photo_url,
        profile_photo_path: user.profile_photo_url,
        country: user.profile?.country_id,
        iso3: user.profile?.country?.iso3,
        nationality: user.profile?.nationality_id,
        city: user.profile?.city_id,
        old_password: undefined,
        documents,
        contacts: user.emergency_contacts ? contacts : [{}],

        date_of_birth: stringDateToMoment(user.profile.date_of_birth),
        address: user.profile.address,
        colony: user.profile.colony,
        zip_code: user.profile.zip_code,
        cel: user.profile.phone,
        education_level: user.profile.education_level,
        medical_situation: user.profile.medical_situation
      })

      setIso3(user?.profile?.country?.iso3);

      if (user.confirmedData) {
        setConfirmedData(user.confirmedData)
      }
    }
  }, [user, isValidatingUser])

  useEffect(() => {
    if (!isSaving && currentState === stateControl.STATE_SUCCESS) {
      refetch()
      notification.success({
        message: 'You profile has been updated succesfully'
      })
    } else if (!isSaving && currentState === stateControl.STATE_ERROR) {
      notification.error({
        message: error.response?.data?.message ?? 'Something went wrong, please try later'
      })
    }
  }, [isSaving, currentState])

  if (user?.email === BYP_PUBLIC_USER) {
    return <BasicSpinner />
  }

  const HelpButton = () => {
    window.open('https://api.whatsapp.com/send/?phone=5213310922610&text&type=phone_number&app_absent=0', '_blank')
  }

  return (
    <Form
      className='w-100 application-form'
      layout='vertical'
      form={form}
      onFinish={handleOnFinish}
    >
      <Row gutter={[16, 16]} style={{ marginRight: '50px', marginLeft: '50px' }}>
        <Col span={24}>
          <div className='d-flex justify-between align-center byp-mb-1'>
            <div className='d-flex align-center' style={{ gap: '0.5rem' }}>
              <Button
                className='btn__back br-06 fs-12'
                onClick={() => window.history.back()}
              >
                <ArrowLeftOutlined style={{ width: '1rem' }} /> Volver
              </Button>
            </div>
          </div>
          <div className='d-flex justify-between align-center w-100'>
            <div className='d-flex align-center' style={{ gap: '0.5rem' }}>
              <Title level={2}>Mi cuenta</Title>
            </div>
            <div className='d-flex align-center' style={{ gap: '0.5rem' }}>
              <Button
                className='byp-btn-red-50 br-06 fs-12'
                size='large'
                style={{ fontWeight: 'normal' }}
                onClick={HelpButton}
              >
                ¿Necesitas ayuda?
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginRight: '50px', marginLeft: '50px' }}>
        <Col xs={24} md={6} lg={5} xxl={4}>
          <Card className='byp-bg-gray box-shadow-cards byp-mb-1 card-user-account'>
            <Row gutter={12}>
              <Col span={10}>
                <Avatar src={user?.profile_photo_url} style={{ width: '100%', height: 'auto' }} />
              </Col>
              <Col span={14} style={{ display: 'inline-grid', alignContent: 'center' }} className='text-black-50'>
                <Text className='fw-5 fs-14 text-black-50' style={{ marginBottom: '0' }}>Tu cuenta</Text>
                <Title level={4} className='text-black-50' style={{ marginTop: '0', marginBottom: '0' }} ellipsis>{user?.name}</Title>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={18} lg={19} xxl={20}>
          <ProfileAccountFields isSaving={isSaving} currentIso={iso3}/>
          <br />
          <br />
          <SecurityAccountFields isSaving={isSaving} />
          {/* <Segmented
            size='large'
            className='byp-account-segmented transparent byp-mb-1'
            options={['General', 'Editar perfil', 'Seguridad']}
            onChange={(value) => {
              setSelected(value)
            }}
          />
          <Col xs={selected === 'General' ? 24 : 0} sm={selected === 'General' ? 20 : 0}>
            <GeneralAccountFields isSaving={isSaving} />
          </Col>
          <Col xs={selected === 'Editar perfil' ? 24 : 0} sm={selected === 'Editar perfil' ? 20 : 0}>
            <ProfileAccountFields isSaving={isSaving} />
          </Col>
          <Col xs={selected === 'Seguridad' ? 24 : 0} sm={selected === 'Seguridad' ? 20 : 0}>
            <SecurityAccountFields isSaving={isSaving} />
          </Col>
          <Col xs={selected === 'Documentos' ? 24 : 0} sm={selected === 'Documentos' ? 20 : 0}>
            <DocumentsAccountFields isSaving={isSaving} />
          </Col>
          <Col xs={selected === 'Viajes' ? 24 : 0} sm={selected === 'Viajes' ? 20 : 0}>
            <MyTravelsList />
          </Col> */}
        </Col>
      </Row>
    </Form>
  )
}

export { AccountForm }
