import { MinusCircleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, Input, Row } from 'antd'
import { useState } from 'react'
import FloatLabel from '../../../../../../common/components/helper-form/FloatLabel'
import { SelectCountryAndCityFormItem } from '../../../../../../common/components/helper-form/SelectCountryAndCityFormItem'
import { SelectPhoneCodeFormItem } from '../../../../../../common/components/helper-form/SelectPhoneCodeFormItem'
import MapAddressFormItem from '../../../../../../common/components/MapAddressFormItem'

export function OfficesContainer ({ name, form }) {
  const arrayOffices = Form.useWatch('offices', form) || [{}]
  const [index, setIndex] = useState(0)
  const addressChange = (address, coords, politicalInfo) => {
    if (address || coords) {
      Object.assign(arrayOffices[index], { address })
      Object.assign(arrayOffices[index], { coords })
      // Object.assign(arrayOffices[index], { country: politicalInfo.country })
      Object.assign(arrayOffices[index], { country_code: politicalInfo.country_code })
      // Object.assign(arrayOffices[index], { city: politicalInfo.city })
      form.setFieldsValue({ offices: arrayOffices })
    }
  }

  const changeIndex = (index) => {
    setIndex(index)
  }

  // const { Title } = Typography

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div key={key} style={{ minWidth: '400px' }}>
              <Row gutter={6}>
                <Col span={23}>
                  <MapAddressFormItem
                    name={[name, 'address']}
                    address={arrayOffices[name]?.address || ''}
                    coords={arrayOffices[name]?.coords || []}
                    setCoords={console.log}
                    addressChange={addressChange}
                    onClick={() => changeIndex(name)}
                  />
                </Col>
                <Col span={1}>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Col>
                <Col span={24}>
                  <SelectCountryAndCityFormItem
                    restField={restField}
                    name={name}
                    office={arrayOffices[name]}
                  />
                </Col>
                <Col span={24}>
                  <FloatLabel
                    label='ZIP code'
                    value={arrayOffices[name]?.cp}
                  >
                    <Form.Item {...restField} name={[name, 'cp']}>
                      <Input />
                    </Form.Item>
                  </FloatLabel>
                </Col>
                {/* <Col span={12}>
                    <SelectCurrencyFormItem
                      value={arrayOffices[name]?.divisa_id}
                      name={[name, 'divisa_id']}
                    />
                  </Col> */}
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Row>
                    Phone
                  </Row>
                  <Row gutter={12}>
                    <Col span={12}>
                      <SelectPhoneCodeFormItem
                        {...restField}
                        showSearch
                        name={[name, 'phone_code']}
                      />
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'tel']}
                        rules={[{
                          pattern: /^([0-9])*$/,
                          message: 'Phone must be a number.'
                        }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    WhatsApp
                  </Row>
                  <Row gutter={12}>
                    <Col span={12}>
                      <SelectPhoneCodeFormItem
                        {...restField}
                        showSearch
                        name={[name, 'phone_code']}
                      />
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'whatsapp']}
                        rules={[{
                          pattern: /^([0-9])*$/,
                          message: 'Phone must be a number.'
                        }]}
                      >
                        <Input />
                      </Form.Item>

                    </Col>
                  </Row>

                </Col>
              </Row>

              <div style={{ color: 'black', fontWeight: '100', fontSize: '15px' }}>
                Website
              </div>
              <Form.Item {...restField} name={[name, 'website']}>
                <Input />
              </Form.Item>

              <Divider />
            </div>

          ))}
          <Form.Item>
            <Row>
              <Button
                style={{ display: 'flex', alignItems: 'center' }}
                className='byp-btn-blue-100-outline'
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add more
              </Button>
              <Button
                className='byp-btn-gray-100-outline'
                style={{ display: 'flex', alignItems: 'center' }}
                icon={<MinusOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}
