import { useState } from 'react'
import TablesPagesRepository from '../../repositories/TablesPagesRepository'
import { useMutation, useQuery } from 'react-query'
import stateControl from '../../utils/state-control'

function useGetDataCountryCities (stateSearch, statusInput) {
  return useQuery(['useGetDataCountryCities', stateSearch, statusInput],
    () => TablesPagesRepository.getTableCountriesCities(stateSearch, statusInput), {
      retry: 1
    }
  )
}

function useGetPage (data) {
  return useQuery(['useGetPage', data],
    () => TablesPagesRepository.getGetDataPage(data), {
      retry: 1
    }
  )
}

function useGetDataPrograms () {
  return useQuery('useGetDataPrograms',
    () => TablesPagesRepository.getTablePrograms(), {
      retry: 1
    }
  )
}

function usePostSavePageCountry () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(TablesPagesRepository.postSavePageCountry, {
    onSuccess: d => { setCurrentState(stateControl.STATE_SUCCESS) },
    onError: (e) => { setCurrentState(stateControl.STATE_ERROR) }
  })

  const savePageCountry = ({ body, name, images, city_id, code, status, ...restData }) => {
    mutation.mutate({ body, name, images, city_id, code, status, ...restData })
  }

  return { ...mutation, savePageCountry, currentState, stateControl }
}

function usePostSavePageProgram () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(TablesPagesRepository.postSavePageProgram, {
    onSuccess: d => { setCurrentState(stateControl.STATE_SUCCESS) },
    onError: (e) => { setCurrentState(stateControl.STATE_ERROR) }
  })

  const savePageProgram = ({ body, name, images, city_id, code, status, program, programType }) => {
    mutation.mutate({ body, name, images, city_id, code, status, program, type_program: programType })
  }

  return { ...mutation, savePageProgram, currentState, stateControl }
}

function usePutSavePage () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(TablesPagesRepository.putSavePage, {
    onSuccess: d => { setCurrentState(stateControl.STATE_SUCCESS) },
    onError: (e) => { setCurrentState(stateControl.STATE_ERROR) }
  })

  const updatePage = ({ body, name, images, city_id, code, status, page, program, ...restData }) => {
    mutation.mutate({ body, name, images, city_id, code, status, page_id: page, program, ...restData })
  }

  return { ...mutation, updatePage, currentState, stateControl }
}

export { useGetDataCountryCities, useGetDataPrograms, usePostSavePageCountry, useGetPage, usePutSavePage, usePostSavePageProgram }
