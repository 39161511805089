import { Button, Col, Form, Input, Row, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { AppLogoNameHeader } from '../../common/components/AppLogoNameHeader'
import { useForgotPassword } from '../../bussines/hooks/user/useForgotPassword'
import stateControl from '../../bussines/utils/state-control'
import SessionService from '../../services/SessionService'
import { useNavigate } from 'react-router-dom'
import { URL_QUOTER_PAGE } from '../quoter'
import { showErrorModal } from '../../utils/errorModal'
import RandomImage from '../../utils/RandomImage'


const URL_FORGOT_PASSWORD_PAGE = '/forgot-password'

const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const [random, setRandom] = useState(1)
  const { isLoading, onSubmit, currentState, data, error } = useForgotPassword()

  const onFinish = (values) => {
    onSubmit(values.username)
  }

  useEffect(() => {
    setRandom(Math.floor(Math.random() * 10) + 1)
  }, [])

  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: ForgotPassword.js:21 ~ useEffect ~ data', data)
      notification.success({
        message: 'We have emailed your password reset link!'
      })
    }
    if (!isLoading && error) {
      console.log('🚀 ~ file: ForgotPassword.js:21 ~ useEffect ~ data', error.response.data)
      let message = error.response.data.status
      if (Array.isArray(message)) {
        message = message[0]
      }
      showErrorModal(message)
    }
  }, [isLoading, currentState, data, error])

  React.useEffect(() => {
    if (SessionService.existSession() && !SessionService.isPublicSession()) {
      navigate(URL_QUOTER_PAGE)
    }
  }, [isLoading])

  return (
    <div>
      <div style={{ overflow: 'hidden', height: '100vh' }}>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <RandomImage />
          </Col>
          <div className='form-container'>
            <div className='img-logo-container'>
              <AppLogoNameHeader
                style={{
                  width: '100%',
                  objectFit: 'contain',
                  height: '100%',
                  paddingBottom: '18px'
                }}
              />
            </div>
            <Form
              name='forgot_password'
              className='login-form'
              style={{ width: '50%' }}
              onFinish={onFinish}
            >
              <h4 style={{ fontWeight: 400, fontFamily: 'Poppins, sans-serif', fontSize: '22px' }}>Recupera tu contraseña</h4>
              {
                currentState === stateControl.STATE_SUCCESS && (
                  <p style={{ fontFamily: 'Poppins, sans-serif' }}>
                    We have emailed your password reset link!
                  </p>
                )
              }
              {
                currentState === stateControl.STATE_INITIAL && (
                  <p style={{ fontFamily: 'Poppins, sans-serif', marginTop: 15, fontSize: '12px', lineHeight: '18px' }}>
                    Ingresa el correo asociado con tu cuenta en Book Your Program
                  </p>
                )
              }
              <Form.Item
                name='username'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Username!'
                  }
                ]}
                style={{ marginBottom: 15 }}

              >
                <Input
                  size='large'
                  className='input_email'
                  //prefix={<UserOutlined className='site-form-item-icon' />}
                  placeholder='Email'
                />
              </Form.Item>
              <Button
                loading={isLoading}
                className='byp-btn-red-50_forgot '
                block
                htmlType='submit'
              >
                Continuar
              </Button>
              {
                currentState === stateControl.STATE_SUCCESS && (
                  <Button
                    loading={isLoading}
                    type='link'
                    htmlType='submit'
                    style={{ color: '#FF395C', textDecoration: 'underline' }}
                  >
                    Reenviar
                  </Button>
                )
              }

              <br />
              <h5 className='byp-title  byp-mt-1' style={{ fontWeight: 600, fontFamily: 'Poppins, sans-serif', fontSize: '14px', lineHeight: '21px' }}>¿Cambiaste de correo?</h5>
              <p style={{ fontWeight: 400, fontFamily: 'Poppins, sans-serif', fontSize: '12px', lineHeight: '18px' }}>
                Si ya no tienes el correo con que te diste de alta, o la haz olvidado.
                Ponte en contacto con <a style={{ color: '#FF395C', textDecoration: 'underline' }} href="#">Servicio al cliente</a> para ayudarte a recuperar al acceso a tu cuenta.
              </p>

            </Form>
          </div>
        </Row>
      </div>
    </div>
  )
}

export { ForgotPasswordPage, URL_FORGOT_PASSWORD_PAGE }
