import React from 'react'
import { Col, Row } from 'antd'
import '../../../common/assets/css/register.css'
import RandomImage from '../../../utils/RandomImage'

// Función para calcular numero aleatorio
// function getRandomNumber (min, max) {
//   return Math.floor(Math.random() * (max - min + 1)) + min
// }

function StepBaseTemplate ({ children, align, justify, footer }) {
  // Genereamos random del 1 al 9 ya que esas son las imagenes
  // const randomNumber = getRandomNumber(1, 9)

  return (
    <div style={{ overflow: 'hidden', height: '100vh' }}>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          <div className='img-container-register'>
            <RandomImage />
          </div>
        </Col>

        <Col md={12} className='form-container'>
          <div
            style={{
              maxHeight: '100vh',
              width: '100%'
            }}
          >
            <div className='step-body'>{children}</div>
          </div>

        </Col>
      </Row>
    </div>
  )
}

export { StepBaseTemplate }
