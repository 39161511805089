import { Form, Select } from 'antd'
import { useGetAllInsurance } from '../../../bussines/hooks/agency/insurances/useGetAllInsurance'
const { Option } = Select

export function SelectInsurancesFormItem ({ name, type, noStyle, showAll, ...props }) {
  const { data: insurances } = useGetAllInsurance()

  return (
    <Form.Item
      name={name}
      {...props}
      noStyle={noStyle}
    >
      <Select {...props} defaultActiveFirstOption>
        {showAll && <Option value={null}>All Roles</Option>}
        {insurances?.data?.map((item) => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}
