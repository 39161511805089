import { LaptopOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { useEffect, useState } from 'react'
import { MarketingMaterialIcon } from '../icons/MarketingMaterialIcon'
import { URL_PACKAGES_PAGE } from '../../../pages/dashboards/packages/PackagesPage'
import { URL_PACKAGES_PRICES_PAGE } from '../../../pages/dashboards/packages/PackagesPricesPage'
import { URL_PACKAGES_PROMOTIONS_PAGE } from '../../../pages/dashboards/packages/UploadPromotionsPage'
import { URL_PACKAGES_PROMOTIONS_RULES_PAGE } from '../../../pages/dashboards/packagesPromotions/PromotionsRulesPage'
import { URL_TABLE_PROGRAMS_PAGE } from '../../../pages/dashboards/tables-pages/TableProgramsPage'
import { URL_TABLE_COUNTRY_CITIES_PAGE } from '../../../pages/dashboards/tables-pages/TableCountryCitiesPage'
import { IconsPages } from '../../../pages/quote-preview/assets/imgs/Icons'
import { URL_TABLE_BLOGS_PAGE } from '../../../pages/dashboards/blogs/PageTableBlogs'
import { URL_PUBLICITY_PAGE } from '../../../pages/dashboards/publicity'
import sale from '../../assets/images/discount.png'
import { URL_PUBLICITY_COURSE_PAGE } from '../../../pages/dashboards/publicityCourse'
import { URL_EDIT_HOME_PAGE } from '../../../pages/dashboards/edit-page/EditHomePage'
import { DesktopIcon } from '../icons/DesktopIcon'
import { IconEdit, IconListCheck } from '../../assets/svg/icons'
import { URL_INSTITUTIONS_FOR_AGENCY } from '../../../pages/agency/InstitutionsForAgencyPage'
import { URL_SERVICES_STATUS_PAGE } from '../../../pages/dashboards/agency/service-status/ServiceStatusPage'
import { URL_ACTIVATE_PACKAGE_AGENCY_PAGE } from '../../../pages/dashboards/agency/ActivationPackage/ActivatePackagePage'
import { URL_CREATE_INSURANCE_PAGE } from '../../../pages/dashboards/agency/insurances/CreateInsurancePage'
import { URL_CREATE_INSURANCE_PRICES_PAGE } from '../../../pages/dashboards/agency/insurancePrices/InsurancePricesPage'

function getItem (label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  }
}

function AgencyMenuItems () {
  const {
    // company,
    user
  } = useAuth()
  const [AgencyProfileAccess, setAgencyProfileAccess] = useState(true)
  const [IsAgencyAdmin, setIsAgencyAdmin] = useState(false)

  useEffect(() => {
    const roles = user?.role_names ?? []
    if (roles.includes('agency-office-manager')) {
      setAgencyProfileAccess(false)
    }
    if (user?.role_name === 'agency-admin') {
      setIsAgencyAdmin(true)
    }
  }, [user])

  const items = [
    getItem(
      <Link to='/dashboard' state={{ menuItemSelected: 1 }}>Desktop</Link>,
      '1',
      <LaptopOutlined />
    ),
    getItem(
      <Link to={URL_EDIT_HOME_PAGE} state={{ menuItemSelected: 2 }}>Home</Link>,
      '2',
      <DesktopIcon />
    ),
    getItem(
      <Link
        to={URL_INSTITUTIONS_FOR_AGENCY}
        state={{ menuItemSelected: 'schools' }}
      >Escuelas
      </Link>,
      'schools',
      <IconEdit />
    ),
    getItem(
      <Link to={URL_TABLE_BLOGS_PAGE} state={{ menuItemSelected: 'blogs' }}>Carrusel para blog</Link>,
      'blogs', <LaptopOutlined />
    ),
    getItem(
      'Publicidad',
      'publicity',
      <img src={sale} alt='publicity icon' width={12} />,
      [
        getItem(
          <Link
            to={URL_PUBLICITY_PAGE}
            state={{ menuItemSelected: 'upload-publicity', openSub: 'publicity' }}
          >Inicio
          </Link>
          ,
          'upload-publicity'
        ),
        getItem(
          <Link
            to={URL_PUBLICITY_COURSE_PAGE}
            state={{ menuItemSelected: 'course-publicity', openSub: 'publicity' }}
          >Curso
          </Link>,
          'course-publicity'
        )
      ]
    ),
    getItem(
      'Packages',
      'packages',
      <MarketingMaterialIcon />,
      [
        getItem(
          <Link
            to={URL_PACKAGES_PAGE}
            state={{ menuItemSelected: 'upload-packages', openSub: 'packages' }}
          >Upload packages
          </Link>
          ,
          'upload-packages'
        ),
        getItem(
          <Link
            to={URL_PACKAGES_PRICES_PAGE}
            state={{ menuItemSelected: 'prices-packages', openSub: 'packages' }}
          >Upload prices
          </Link>,
          'prices-packages'
        ),
        getItem(
          'Promotions packages',
          'promotions-packages',
          undefined,
          [
            getItem(
              <Link
                to={URL_PACKAGES_PROMOTIONS_PAGE}
                state={{ menuItemSelected: 'upload-promotions', openSub: 'promotions-packages', openSub2: 'packages' }}
              >
                Upload promotions
              </Link>,
              'upload-promotions'
            ),
            getItem(
              <Link
                to={URL_PACKAGES_PROMOTIONS_RULES_PAGE}
                state={{ menuItemSelected: 'promotions-rules', openSub: 'promotions-packages', openSub2: 'packages' }}
              >
                Promotions rules
              </Link>,
              'promotions-rules'
            )
          ]
        )
      ]
    ),
    getItem(
      <Link to={URL_TABLE_COUNTRY_CITIES_PAGE} state={{ menuItemSelected: 'paises' }}>Ubicaciones</Link>,
      'paises',
      <IconsPages color='var(--blue-200)' />
    ),
    getItem(
      'Programs',
      'programs',
      <IconsPages />,
      [
        getItem(
          <Link
            to={URL_TABLE_PROGRAMS_PAGE}
            state={{ menuItemSelected: 'programas-todos', openSub: 'programas', type: 'Agency' }}
          >Todos
          </Link>,
          'programas-todos'
        ),
        getItem(
          <Link
            to='/'
            state={{ menuItemSelected: 'programas-activar', openSub: 'programas', type: 'Institution' }}
          >Activar
          </Link>,
          'programas-activar'
        )
      ]
    ),
    getItem(
      'Custom services',
      'customs-quotes',
      <IconListCheck />,
      [
        getItem(
          'Medical Insurance',
          'medical', undefined,
          [
            getItem(
              <Link
                to={URL_CREATE_INSURANCE_PAGE}
                state={{ menuItemSelected: 'medical_form', openSub: 'customs-quotes', openSub2: 'medical' }}
              >Upload medical insurance
              </Link>,
              'medical_form'
            ),
            getItem(
              <Link
                to={URL_CREATE_INSURANCE_PRICES_PAGE}
                state={{ menuItemSelected: 'medical_prices', openSub: 'customs-quotes', openSub2: 'medical' }}
              >Prices medical insurance
              </Link>,
              'medical_prices'
            )
          ]
        )
      ]
    ),
    getItem(
      'Published services',
      'published-services',
      <IconListCheck />,
      [
        getItem(
          <Link
            to={URL_SERVICES_STATUS_PAGE}
            state={{ menuItemSelected: 'services-status', openSub: 'published-services' }}
          >Insurance and services
          </Link>,
          'services-status'
        ),
        getItem(
          <Link
            to={URL_ACTIVATE_PACKAGE_AGENCY_PAGE}
            state={{ menuItemSelected: 'packages-status', openSub: 'published-services' }}
          >Packages
          </Link>,
          'packages-status'
        )
      ]
    )
  ]

  if (!AgencyProfileAccess) {
    items.splice(4, 4)
  }

  if (!IsAgencyAdmin) {
    items.splice(2, 2)
    items.splice(6, 1)
  }

  return items
}

export { AgencyMenuItems }
