import logo from './../assets/images/logo-beta.png'
import secondlogo from './../assets/images/second_logo.jpg'
import book from './../assets/images/book.png'
import { useNavigate } from 'react-router-dom'

const AppLogoNameHeader = ({ changeImage = false, img, style, otherlogo, ...props }) => {
  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate('/')
  }
  if (img) {
    return (
      <img
        style={style ?? { cursor: 'pointer' }}
        onClick={handleOnClick}
        {...props}
        src={img} alt='byp'
      />
    )
  }
  if (otherlogo) {
    return (
      <img
        style={style ?? { cursor: 'pointer' }}
        onClick={handleOnClick}
        {...props}
        src={secondlogo} alt='byp'
      />
    )
  }

  return (
    <img
      style={style ?? { cursor: 'pointer' }}
      onClick={handleOnClick}
      {...props}
      src={changeImage ? book : logo} alt='byp'
    />
  )
}

export { AppLogoNameHeader }
