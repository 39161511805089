import React, { useEffect, useState } from "react";
import { Button, Col, Image, Rate, Row, Typography, notification } from "antd";
import { Link } from "react-router-dom";
import icon from "../../../common/assets/images/icon.png";
import { IconOpiniones } from "../../../common/assets/svg/icons";
import { RiShareFill, RiDeleteBin5Fill } from "react-icons/ri";
import { URL_OPINION_PAGE } from "../../opinion/OpinionPage";
import { ThemeCarrouselSmall } from "../../../common/theme/partials/MainThemeCarrousel";
import { handlePrice } from "../../../utils/getCountryName";
import ConfirmModal from "../../../common/components/ConfirmModal";
import { useRemoveItemFromCollection } from "../../../bussines/hooks/collections/useRemoveItemFromCollection";
import { ShareLinkButton } from "./ShareLinkButton";
import { URL_WEBSITE } from "../../../config";
import { useGetCurrencyToCurrency } from "../../../bussines/hooks/currencies/useGetCurrencyToCurrency";
import { useAuth } from "../../../providers/auth/auth-context-provider";
import InfoCostPopUp from "../../quoter/components/InfoCostPopUp";

const { Paragraph } = Typography;

function CardFavoriteItem({ item, status, onDelete }) {
  const { userLocalCurrency } = useAuth;
  const { data: newdata, getCurrencyToCurrency } = useGetCurrencyToCurrency();
  const {
    removeItem,
    isLoading: isRemovingItem,
    currentState: itemState,
    state,
  } = useRemoveItemFromCollection();

  const LongText = ({ content, limit }) => {
    const [showAll, setShowAll] = useState(false);

    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);

    if (content?.length <= limit) {
      // there is nothing more to show
      return <div className="card-content">{content}</div>;
    }
    if (showAll) {
      // We show the extended text and a link to reduce it
      return (
        <div className="card-content">
          {content}
          <a style={{ color: "blue" }} onClick={showLess}>
            {" "}
            Read less
          </a>
        </div>
      );
    }
    if (!content) {
      return <a />;
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = content?.substring(0, limit) + "...";
    return (
      <div className="card-content">
        {toShow}
        <a style={{ color: "blue" }} onClick={showMore}>
          Read more
        </a>
      </div>
    );
  };

  const handleUrl = () => {
    let url = "";
    if (item.course?.type === "package") {
      url = `/package-course/${item.course?.id}/${item.campus?.id}`;
    }
    if (item.course?.type === "short") {
      url = `/short-term-course/${item.course?.id}/${item.campus?.id}`;
    }
    if (item.course?.type === "course") {
      url = `/quote-course/${item.course?.id}/${item.campus?.id}`;
    }
    if (item?.uuid) {
      return url + "?quote=" + item.uuid;
    } else {
      return url;
    }
  };

  const handleUrltoCopy = () => {
    const url = handleUrl();
    return URL_WEBSITE + url;
  };

  useEffect(() => {
    const getCurrency = async () => {
      if (item.currency) {
        getCurrencyToCurrency(userLocalCurrency, item.currency);
      } else if (item?.quote_costs && item?.quote_costs?.divisa) {
        getCurrencyToCurrency(userLocalCurrency, item?.quote_costs?.divisa);
      }
    };
    getCurrency();
  }, [status, userLocalCurrency]);

  useEffect(() => {
    if (!isRemovingItem && itemState === state.STATE_SUCCESS) {
      notification.success({
        message: "Favorito eliminado correctamente",
      });
      if (onDelete) onDelete();
    }
  }, [isRemovingItem, itemState]);

  useEffect(() => {
    if (newdata) {
      // console.log('🚀 ~ file: CardFavoriteItem.js:109 ~ useEffect ~ newdata:', newdata)
    }
  }, [newdata]);

  return (
    <>
      <div className="card-course" style={{ paddingBottom: "30px" }}>
        <Row
          style={{
            backgroundColor: "#F4F4F4",
            border: "1px solid var(--gray-100)",
            borderRadius: "22px",
          }}
        >
          {/* Image */}
          <Col
            xs={24}
            md={4}
            className="card-course-column"
            style={{ position: "relative" }}
            key={item.id || item.course?.id || item.campus?.id}
          >
            {item.images?.length >= 1 ? (
              <ThemeCarrouselSmall
                slides={item.images}
                urlProperty="src"
                inRandomOrder
              />
            ) : (
              <img
                className="card-course__image"
                alt={item?.label}
                src={item?.campus?.profilePhotoUrl || icon}
              />
            )}
          </Col>

          {/* Info - card body*/}
          <Col xs={24} md={20} className="card__body-container">
            <Row gutter={[8, 0]}>
              <Col xs={24}>
                <Row gutter={[0, 16]} justify="space-between">
                  {/* Location */}
                  {/* <Col span={20} className="card__location-container">
                    {item.campus?.name}
                    {" " + item.city?.name + "-" + item.country?.name}
                  </Col> */}

                  <Col
                    xs={{ span: 19 }}
                    md={{ span: 20 }}
                    className="card__location-container"
                  >
                    {item.campus?.name}
                    {" " + item.city?.name + "-" + item.country?.name} fdsfgfdgd
                    fgfd
                  </Col>

                  {/* Button actions container */}
                  <Col
                  xs={{ span: 4 }}
                  md={{ span: 4 }}
                  style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
                  >
                    <Col>
                        <ConfirmModal
                          onOk={removeItem}
                          isLoading={isRemovingItem}
                          id={item.id}
                          icon={<RiDeleteBin5Fill />}
                          // btnClass="byp-favorite-icon-button"
                          btnClass="card__add-to-favorites-btn"
                          btnSize="middle"
                        />
                      </Col>

                      <Col>
                        <ShareLinkButton
                          url={handleUrltoCopy()}
                          buttonProps={{
                            text: "",
                            icon: <RiShareFill />,
                            // className: "byp-favorite-icon-button",
                            className:"card__add-to-favorites-btn"

                          }}
                        />
                      </Col>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Course name */}
            <Col xs={24}>
              <Link to={handleUrl()} className="card__title">
                {item?.course?.label}
              </Link>
            </Col>

            {/* Link to course */}
            <Col>
              <Link to={handleUrl()} className="card__link-btn">
                Curso de {item.course_length} semanas
              </Link>
            </Col>

            {/* Course description */}
            <Col>
              <Link to={handleUrl()}>
                <Paragraph ellipsis={{ rows: 4 }}>
                  <LongText content={item.course?.description} limit={160} />
                </Paragraph>
              </Link>
            </Col>

            <Row
              className="only-desktop"
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              {/* Opinion container - desktop */}
              <Col>
                <Row>
                  <Col
                    style={{
                      padding: "10px",
                      borderRadius: "4px",
                      backgroundColor: "#E5E5E5",
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                      fontStyle: "Poppins",
                      width: "46px",
                      height: "44px",
                      textJustify: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {item?.rate ? item?.rate?.toFixed(1) : 5}
                  </Col>
                  <Col>
                    <Col>
                      <Rate
                        value={item?.rate ?? 5}
                        disabled
                        className="byp-gray-rate"
                      />
                    </Col>
                    <Row>
                      <IconOpiniones width="15" height="15" />{" "}
                      {item.rate_count ?? 0} Opiniones
                    </Row>
                  </Col>
                </Row>
              </Col>

              {/* Cost - desktop*/}
              <Col>
                <Button
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#FF395C",
                    color: "white",
                    fontSize: "12px",
                    padding: "1px 8px",
                    borderRadius: "6px",
                    fontWeight: "700",
                    justifyContent: "center",
                  }}
                >
                  TIENE DESCUENTO
                </Button>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#000000",
                    marginBottom: "0px",
                  }}
                >
                  {newdata?.data ? (
                    <>
                      {item?.quote_costs &&
                        typeof item?.quote_costs === "object" && (
                          <span>
                            {item?.quote_costs?.divisa} $
                            {item?.quote_costs?.total}
                          </span>
                        )}
                      {!item?.quote_costs && (
                        <span>
                          {userLocalCurrency} $
                          {handlePrice(item.cost, newdata.data?.value)}
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {item?.quote_costs && (
                        <span>
                          {item?.quote_costs?.divisa} $
                          {item?.quote_costs?.total}
                        </span>
                      )}
                      {!item?.quote_costs && (
                        <span>
                          {item.currency} ${item.cost}
                        </span>
                      )}
                    </>
                  )}
                </p>
              </Col>
            </Row>

            {/* Opinion container - mobile  */}
            <Col xs={24} md={12} className="only-mobile">
              <Row justify="space-between">
                <Col xs={12} md={24}>
                  <Row>
                    {/* Rating */}
                    <Col xs={24}>
                      <Rate
                        value={item?.rate ?? 5}
                        disabled
                        className="byp-gray-rate byp-rate-sm"
                      />
                    </Col>

                    {/* Opinions counter */}
                    <Col xs={24} className="card__opinions-container">
                      <IconOpiniones width="14" height="14" />
                      <b>{item.rate_count ?? 0} Opiniones</b>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Link
                    className="card__link-opinion"
                    to={URL_OPINION_PAGE.replace(
                      ":campusSlug",
                      item?.campus?.slug
                    ).replace(":courseId", item?.course?.id)}
                  >
                    Deja tu opinión
                  </Link>
                </Col>
              </Row>
            </Col>

            {/* Cost - mobile */}
            <Col
              className="only-mobile flex"
              xs={24}
              md={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                gap: "5px",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "27px",
                  color: "#000000",
                  marginBottom: "0px",
                }}
              >
                {newdata?.data ? (
                  <>
                    {item?.quote_costs &&
                      typeof item?.quote_costs === "object" && (
                        <span>
                          {item?.quote_costs?.divisa} $
                          {item?.quote_costs?.total}
                        </span>
                      )}
                    {/* {!item?.quote_costs && (
                        <span>
                          {userLocalCurrency} $
                          {handlePrice(item.cost, newdata.data?.value)}
                        </span>
                      )} */}

                    {!item?.quote_costs && (
                      <InfoCostPopUp
                        cost={item.cost}
                        currency={item.currency}
                        tuition={item.tuition}
                      >
                        {userLocalCurrency} $
                        {handlePrice(item.cost, newdata.data?.value)}
                      </InfoCostPopUp>
                    )}
                  </>
                ) : (
                  <>
                    {item?.quote_costs && (
                      <InfoCostPopUp
                        cost={item.cost}
                        currency={item.currency}
                        tuition={item.tuition}
                      >
                        {item?.quote_costs?.divisa} ${item?.quote_costs?.total}
                      </InfoCostPopUp>
                    )}
                    
                    {!item?.quote_costs && (
                      <InfoCostPopUp
                        cost={item.cost}
                        currency={item.currency}
                        tuition={item.tuition}
                      >
                        {item.currency} ${item.cost}
                      </InfoCostPopUp>
                    )}
                  </>
                )}
              </p>
            </Col>

            {/* <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                gap: "5px",
              }}
            >
              {percentageDiscount > 0 && (
                <Button className="offer-discount-amount">
                  - {percentageDiscount} %*
                </Button>
              )}

              {hasDiscounts && exchangeValue && (
                <div className="price-container">
                  <span className="price-before">
                    {userLocalCurrency} $
                    {handlePrice(item.cost, newdata?.data?.value)}
                  </span>

                  <InfoCostPopUp
                    cost={item.cost}
                    costDetails={costs}
                    discounts={costs.discounts}
                    currency={item.currency}
                    tuition={item.tuition}
                  >
                    {userLocalCurrency} $
                    {handlePrice(handleCost(), newdata?.data?.value)}
                  </InfoCostPopUp>
                </div>
              )}
              {hasDiscounts && !exchangeValue && (
                <div className="price-container">
                  <span className="price-before">
                    {item.currency} ${item.cost}
                  </span>
                  <InfoCostPopUp
                    cost={item.cost}
                    costDetails={costs}
                    discounts={costs.discounts}
                    currency={item.currency}
                    tuition={item.tuition}
                  >
                    {item.currency} ${handleCost()}
                  </InfoCostPopUp>
                </div>
              )}
              {!hasDiscounts && (
                <>
                  {exchangeValue && (
                    <div className="price-container">
                      <InfoCostPopUp
                          cost={item.cost}
                          costDetails={costs}
                          discounts={costs.discounts}
                          currency={item.currency}
                          tuition={item.tuition}
                        >
                          {userLocalCurrency} $
                          {handlePrice(handleCost(), newdata?.data?.value)}
                        </InfoCostPopUp>
                    </div>
                  )}
                  {!exchangeValue && (
                    <>
                      <p className="price">
                        {item.currency} ${item.cost}
                      </p>
                    </>
                  )}
                </>
              )}
            </Col> */}

            <Row
              className="only-desktop"
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <Col>
                <Link
                  style={{
                    color: "#373737",
                    fontWeight: "400",
                    fontSize: "10px",
                    textDecorationLine: "underline",
                  }}
                  to={URL_OPINION_PAGE.replace(
                    ":campusSlug",
                    item?.campus?.slug
                  ).replace(":courseId", item?.course?.id)}
                >
                  Deja tu opinión
                </Link>
              </Col>
              <Col
                style={{ color: "black", fontWeight: "400", fontSize: "10px" }}
              >
                Precio promedio por semana, impuestos y cargos incluidos
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export { CardFavoriteItem };
