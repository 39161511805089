import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/auth/auth-context-provider'
import { useQuote } from '../../bussines/hooks/quote/useQuote'
import SessionService from '../../services/SessionService'
import { URL_LOGIN_PAGE } from '../login'
import LottieCargando from '../../lotties/LottieCargando'
import Quote from './Components/Quote'

const QuoteSummaryContent = ({ quoteUuid }) => {
  const navigate = useNavigate()
  const { isPublicUser } = useAuth()
  const { isLoading, data: quote } = useQuote(quoteUuid, !isPublicUser)

  useEffect(() => {
    if (SessionService.isPublicSession()) {
      navigate(URL_LOGIN_PAGE)
    }
  }, [])

  if (isLoading || !quote) {
    return <LottieCargando />
  }

  return (
    <Quote quote={quote} quoteUuid={quoteUuid} />
  )
}

export default QuoteSummaryContent
