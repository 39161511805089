import React from 'react'
import { usePackage } from '../../../providers/package/package-course-context'
import { Col, Row } from 'antd'

function IncludesSection() {
  const {
    service
  } = usePackage()

  return (
    <>
      {
        service.getIncludes()?.map(item => (
          <Row gutter={12} className='w-100'>
            <Col xs={24} className='incluyeConceptos' style={{ padding: '5px' }}>{item.name}</Col>
            <hr className='byp-hr' style={{ margin: '0' }} />
          </Row>
        ))
      }
    </>
  )
}

export default IncludesSection
