import { Button, Col, Form, Input, Modal, Typography } from 'antd'
import { useEffect, useState } from 'react'
import stateControl from '../../bussines/utils/state-control'
const { TextArea } = Input

export const ModalCommentForm = ({ onFinish, isLoading, status }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleOnFinish = (values) => {
    if (onFinish) onFinish(values)
  }

  useEffect(() => {
    if (!isLoading && status === stateControl.STATE_SUCCESS) {
      form.resetFields()
      handleCancel()
    }
  }, [isLoading, status])

  return (
    <>
      <div className='byp-mt-1 blog__add-comment' onClick={showModal} style={{ cursor: 'pointer' }}>
        Deja tu comentario +
      </div>
      <Modal
        style={{ padding: '0 10px' }} onCancel={handleCancel} visible={isModalOpen}
        footer={<></>}
        // footer={
        //   <Row style={{ justifyContent: 'space-between' }}>
        //     <Button
        //       onClick={handleCancel}
        //       style={{ borderColor: '#5D5D5D', color: '#5D5D5D', fontWeight: '500', borderWidth: '2px', backgroundColor: '#F4F4F4' }}
        //     >Cancelar
        //     </Button>
        //     <Button
        //       onClick={() => handleOnFinish(form.getFieldsValue())}
        //       // loading={isLoading}
        //       style={{ borderColor: '#5D5D5D', color: '#f4f4f4', fontWeight: '500', backgroundColor: '#5D5D5D' }}
        //     >Guardar
        //     </Button>
        //   </Row>
        // }
      >
        <Form onFinish={handleOnFinish} form={form} onFinishFailed={() => console.log('FAILEDD')}>
          <Col xs={24}> {/* opinion title */}
            <Typography.Text>Título</Typography.Text>
            <Form.Item
              name='title' rules={[{
                required: true,
                message: 'Este dato es requerido'
              }]}
            >
              <TextArea placeholder='La mejor experiencia de estudios...' maxLength={100} showCount rows={1} />
            </Form.Item>
          </Col>
          <Col xs={24}> {/* opinion commment */}
            <Typography.Text>Comparte tus comentarios y recomendaciones</Typography.Text>
            <Form.Item
              name='comment' rules={[{
                required: true,
                message: 'Este dato es requerido'
              }]}
            >
              <TextArea rows={4} placeholder='Lo que más me gustó fue que...' maxLength={300} showCount />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Button htmlType='submit' className='byp-btn-red-50 byp-mt-1' block loading={isLoading}>
              Enviar Opinión
            </Button>
          </Col>
        </Form>
      </Modal>
    </>
  )
}
