import api from '../../services/api'

class FeeRepository {
  async list (category, filtersQuery) {
    const response = await api.get(`/fees/${category}/list?` + filtersQuery)
    return response.data
  }

  async create ({ type, data }) {
    const response = await api.post(`/fees/${type}`, data)
    return response.data
  }

  async savePrices ({ id, data }) {
    const response = await api.post(`/fees/${id}/prices/save`, data)
    return response.data
  }

  async publishFee ({ id, data }) {
    const response = await api.post(`/fees/${id}/publish`, data)
    return response.data
  }

  async find (id) {
    const response = await api.get(`/fees/${id}`)
    return response.data
  }

  async details (id, params) {
    const response = await api.get(`/fees/${id}`, { params })
    return response.data
  }

  async getCosts (id) {
    const response = await api.get(`/fees/${id}/prices`)
    return response.data
  }

  async feesStatus (filtersQuery) {
    const response = await api.get('/fees-details?' + filtersQuery)
    console.log('🚀 ~ file: FeeRepository.js:31 ~ FeeRepository ~ feesStatus ~ response', response.data)
    return response.data
  }
}

export default new FeeRepository()
