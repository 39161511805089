import { useMutation } from 'react-query'
import PageRepository from '../../repositories/PageRepository'
import { useState } from 'react'
import stateControl from '../../utils/state-control'

function useSaveBlog () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(PageRepository.saveBlog, {
    onSuccess: d => {
      setCurrentState(stateControl.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(stateControl.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const save = (data) => {
    mutation.mutate(data)
  }

  return { ...mutation, stateControl, currentState, save }
}

function usePublishedPage () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(PageRepository.publishedPage, {
    onSuccess: d => {
      setCurrentState(stateControl.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(stateControl.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const save = (slug) => {
    console.log('🚀 ~ file: useSaveBlog.js:46 ~ save ~ slug:', slug)
    mutation.mutate(slug)
  }

  return { ...mutation, stateControl, currentState, save }
}

export { useSaveBlog, usePublishedPage }
