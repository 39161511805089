import { Card, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
// import { LocationIcon } from '../../quote-preview/assets/imgs/Icons'

const InfoQuoteCourse = ({ course, skills, requirements, characteristics, sports, extracurriculars, activities }) => {
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)

  useEffect(() => {
    if (course) {
      const item = course?.offer
      if (item?.translations && item?.translations?.length > 0) {
        const label = item?.translations?.find(item => item?.to === 'es' && item?.attribute === 'label')
        setTitle(label?.translation)
        const description = item?.translations?.find(item => item?.to === 'es' && item?.attribute === 'description')
        setDescription(description?.translation)
      } else {
        setTitle(item?.name)
        setDescription(item?.description)
      }
    }
  }, [course])
  return (
    <Card style={{ height: '100%' }}>
      <h1>{title}</h1>
      <br />
      {/* <p className='quote-byp__mb'>
        <span>Escuela: </span>
        <br />
        {course?.offer?.campus?.name}
        <br />
        <LocationIcon /> {course?.offer?.campus?.address}
      </p> */}

      {skills?.length > 0 && <h3>Habilidades que obtendrás</h3>}
      <Row gutter={[10, 10]} className='quote-byp__mb'>
        {skills?.map((skill) => (
          <Col key={skill.id}>
            <div className='quote-byp__habilidades'>{skill.name}</div>
          </Col>
        ))}
      </Row>

      {requirements?.length > 0 && <h3>Requisitos</h3>}
      <ul className='quote-byp__mb quote-byp__list'>
        {requirements?.map((requirement) => (
          <li key={requirement.id}>{requirement.value}</li>
        ))}
      </ul>

      {requirements?.length <= 0 && (
        <>
          <h3>Requisitos</h3>
          Ninguno
          <br /><br />
        </>
      )}

      {description && <h3>Descripción</h3>}
      <p className='quote-byp__mb quote-byp__description'>{description}</p>

      {characteristics?.length > 0 && <h3>Caracteristicas de curso</h3>}
      <ul className='quote-byp__mb quote-byp__list'>
        {characteristics?.map((characteristic) => (
          <li key={characteristic.id}>{characteristic.value}</li>
        ))}
      </ul>

      {sports?.length > 0 && <h3>Deportes</h3>}
      <Row gutter={[10, 10]} className='quote-byp__mb'>
        {sports?.map((sport) => (
          <Col key={sport}>
            <div className='quote-byp__habilidades'>{sport}</div>
          </Col>
        ))}
      </Row>
      {extracurriculars?.length > 0 && <h3>Extracurriculares</h3>}
      <Row gutter={[10, 10]} className='quote-byp__mb'>
        {extracurriculars?.map((extracurricular) => (
          <Col key={extracurricular}>
            <div className='quote-byp__habilidades'>{extracurricular}</div>
          </Col>
        ))}
      </Row>
      {activities?.length > 0 && <h3>Actividades</h3>}
      <Row gutter={[10, 10]} className='quote-byp__mb'>
        {activities?.map((activity) => (
          <Col key={activity}>
            <div className='quote-byp__habilidades'>{activity}</div>
          </Col>
        ))}
      </Row>
    </Card>
  )
}

export { InfoQuoteCourse }
