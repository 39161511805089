import { useQuery } from 'react-query'
import { NationalityRepository } from '../../repositories/NationalityRepository'

function useGetCountriesWithOffers (search, filters = {}, enabled = true) {
  return useQuery(['useGetCountriesWithOffers', search, filters],
    () => NationalityRepository.countriesAndCitiesWithOffers(search, filters), {
      retry: 2, enabled
    })
}

export { useGetCountriesWithOffers }
