import { Form, Radio } from 'antd'
import React, { useState } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'

export default function DatesLodging () {
  const [isActiveLodging, setIsActiveLodging] = useState('no')
  const { formConfig, setFormConfig, service } = useQuoter()
  // const onChange = (e) => {
  //   setIsActiveLodging(e.target.value)
  // }

  const handleChangeSwitch = (e) => {
    const included = e.target.value === 'si'
    setFormConfig({
      ...formConfig,
      lodging: included
    })

    setIsActiveLodging(e.target.value)
  }
  return (
    <>
      {service.hasLodging() && (
        <div className='quotation__accomodation-question'>
          <label style={{ fontWeight: '700' }}>¿Deseas añadir Hospedaje?</label>
          <Form.Item
            name='radioLodging'
            rules={[{
              required: true,
              message: 'This field is required'
            }]}
            style={{ margin: '0' }}
            initialValue={isActiveLodging}
          >

            <Radio.Group onChange={handleChangeSwitch} style={{ marginLeft: '0.5rem' }}>
              <Radio value='si' className='radio_btn-quote'>Si</Radio>
              <Radio value='no' className='radio_btn-quote'>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      )}
    </>
  )
}
