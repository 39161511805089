import api from '../../services/api'

class SearchDataRepository {
  async getInstitutionsAndCampus (program, filters) {
    const response = await api.get('get/institutions-campus/' + program, {
      params: {
        ...filters
      }
    })
    return response.data
  }

  async getDates (filters) {
    const response = await api.get('get/dates', {
      params: {
        ...filters
      }
    })
    return response.data
  }

  async getCosts (program, filters) {
    const response = await api.get('get/prices/' + program, {
      params: {
        ...filters
      }
    })
    return response.data
  }

  async getStartdatesAndHolidays (params) {
    const response = await api.get('get/start/holidays', {
      params
    })
    return response.data
  }
}

export default new SearchDataRepository()
