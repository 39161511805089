import api from '../../services/api'

class ProgramRepository {
  async programs (params) {
    const response = await api.get('/programs', { params })
    return response.data
  }

  async find (program) {
    const response = await api.get(`/programs/${program}`)
    return response.data
  }

  async diplomaSubtypes (filters) {
    const response = await api.get('get/diploma/subtypes', { params: filters })
    return response.data
  }
}

export default new ProgramRepository()
