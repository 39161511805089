import React from 'react'
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { MainTheme } from '../../common/theme/MainTheme'
import { Button, Col, Row, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import MyCourses from './components/MyCourses'

const URL_USER_COURSES = '/courses'

const MyCoursesPage = () => {
  const HelpButton = () => {
    window.open('https://api.whatsapp.com/send/?phone=5213310922610&text&type=phone_number&app_absent=0', '_blank')
  }
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme>
        <Row gutter={[16, 16]} style={{ marginRight: '50px', marginLeft: '50px' }} className='my-courses'>
          <Col span={24}>
            <div className='d-flex justify-between align-center byp-mb-1'>
              <div className='d-flex align-center' style={{ gap: '0.5rem' }}>
                <Button
                  className='btn__back br-06 fs-12'
                  onClick={() => window.history.back()}
                >
                  <ArrowLeftOutlined style={{ width: '1rem' }} />
                  <span className='component_none'>Volver</span>
                </Button>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <Typography.Title level={2} className='my-courses-title'>Mis cursos</Typography.Title>
          </Col>
          <Col span={12} className='justify-end'>
            <Button
              className='byp-btn-red-50 br-06 fs-12'
              size='large'
              style={{ fontWeight: 'normal' }}
              onClick={HelpButton}
            >
              ¿Necesitas ayuda?
            </Button>
          </Col>
        </Row>
        <MyCourses />
      </MainTheme>
    </PageProtected>
  )
}

export { MyCoursesPage, URL_USER_COURSES }
