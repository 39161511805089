import { Col, Layout, Row, notification, Space, Button } from 'antd'
import { PersonalizedSearcher } from '../../home/components/PersonalizedSearcher'
import { CardWithImage } from '../../home/components/CardWithImage'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import FarCastle from '../../../common/assets/images/far-castle.png'
import BlueCastle from '../../../common/assets/images/blue-castle.png'
import AlleyFestival from '../../../common/assets/images/alley-festival.png'
import { useGetCountryPage } from '../../../bussines/hooks/page/useGetCountryPage'
import React, { useEffect, useState } from 'react'
import PlaceIcon from '@material-ui/icons/Place'
// import SearchIcon from '@material-ui/icons/Search'
import EditIcon from '@material-ui/icons/Edit'
import { IconCamping } from '../../quote-preview/assets/imgs/Icons'
import { HomeCarrousel } from '../../../common/components/HomeCarrousel'
import { CardBlogSimple } from '../../dashboards/blog/components/CardBlogSimple'
// import { useGetOfferRecomendations } from '../../../bussines/hooks/offer/useGetOfferRecomendations'
import { BlogFooter } from '../../dashboards/blogs/components/BlogFooter'
import { Link } from 'react-router-dom'
import { ProgramButtons } from '../../../common/components/ProgramButtons'

function CountryContent ({ code, city, isPreview, search }) {
  const [image01, setImage01] = useState()
  const [image02, setImage02] = useState()
  const [image03, setImage03] = useState()
  const [blogs, setBlogs] = useState({})
  const [offers, setOffers] = useState({})
  const [courses, setCourses] = useState([])
  const [dataOffers, setDataOffers] = useState([])
  const [orderCourses, setOrderCourses] = useState({})
  const [orderOffers, setOrderOffers] = useState([])

  // const { data: offersData, isLoading: isLoadingRecommendations } = useGetOfferRecomendations({ courses, pagelocation: true, city, code, search })
  const { isLoading, data: countryPage, isError, error } = useGetCountryPage(city ?? code, city ? { city } : search ? { search } : {})

  useEffect(() => {
    if (!isLoading && countryPage) {
      const images = countryPage.images?.reverse() ?? []
      setImage01(images[0] ?? undefined)
      setImage02(images[1] ?? undefined)
      setImage03(images[2] ?? undefined)
      const blocks = countryPage.blocks ?? []
      const blogsBlock = blocks.find((block) => block.type === 'blog')
      setBlogs(blogsBlock ?? {})
      const courseBlock = blocks.find((block) => block.type === 'course')
      setCourses(courseBlock?.courses?.map(item => item.id))
      courseBlock?.courses?.forEach(item => {
        setOrderCourses(prevState => {
          return {
            ...prevState,
            [item.id]: item?.pivot?.order
          }
        })
      })

      setOffers(courseBlock ?? {})
    }
    if (!isLoading && isError && error?.response?.status !== 404) {
      notification.error({
        message: error?.response?.data?.message ?? 'Algo salió, por favor intentelo más tarde'
      })
    }
  }, [isLoading])

  // useEffect(() => {
  //   if (!isLoadingRecommendations && offersData) {
  //     const uniqueCourseIds = new Set()
  //     const resultadosUnicos = offersData?.data?.filter(item => {
  //       if (item.course?.id && !uniqueCourseIds.has(item.course.id)) {
  //         uniqueCourseIds.add(item.course.id)
  //         return true
  //       }
  //       return false
  //     })
  //     setDataOffers(resultadosUnicos)
  //   }
  // }, [isLoadingRecommendations, offersData])

  useEffect(() => {
    if (orderCourses && dataOffers) {
      const newArray = dataOffers?.map(item => {
        item.pivot = {}
        item.pivot.order = orderCourses[item?.course?.id]
        return item
      })
      setOrderOffers(newArray)
      console.log('🚀 ~ useEffect ~ newArray:', newArray)
    }
  }, [orderCourses, dataOffers])

  return (
    <div className='bg-bScreen'>
      <div className='layout-maxWidth'>
        <Space direction='vertical' className='space-content' size={[0, 50]}>
          {/** HERO + Search**/}
          <Layout style={{ background: 'transparent' }} className='search_mobile_hidden'>
            <PersonalizedSearcher />
          </Layout>

          {/** MENSAJE ERROR 404 **/}
          <Layout style={{ background: 'transparent', marginTop: '-20px' }}>
            {!isLoading && ((error?.response?.status === 404 || countryPage?.pageNotExists || countryPage?.status !== 'Published') && !isPreview) && (
              <Row>
                <Col span={24}>
                  <h3 className='byp__location-title'>Aún no tenemos (país-ciudad)</h3>
                  <p className='byp__location-description'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla elit augue, gravida a consectetur vel, fermentum eu ligula. Maecenas pharetra semper nisl in pellentesque. Phasellus eleifend aliquam vehicula. Nunc cursus ullamcorper ligula, ut maximus ante faucibus ut. Nam varius sapien augue, a pellentesque dolor cursus vel. Vivamus sed dignissim enim. Sed euismod vulputate augue vel dignissim. Mauris libero eros, dapibus vel ultricies et, tempus a tortor. Morbi porta tempor convallis. Fusce id eros enim. Mauris vel mauris quis massa hendrerit viverra. In porta nulla non ipsum aliquet molestie. Donec laoreet lectus arcu, nec blandit felis ullamcorper eget. Etiam fermentum nisl nunc, eu hendrerit enim blandit a. Suspendisse ultricies neque eu luctus convallis.
                  </p>
                </Col>
              </Row>
            )}
          </Layout>

          {/** CONTENIDO PAIS **/}
          <Layout style={{}} className='content_mobile layout-home'>
            {!isLoading && ((countryPage && !countryPage?.pageNotExists && countryPage?.status === 'Published') || (isPreview)) && (
              <>
                <Row className='byp-mt-1 byp-mb-1' gutter={[20, 20]} justify='space-between' align='top'>
                  {/** TITULO Y BUSCADOR **/}
                  <Col xs={{ order: 2, span: 24 }} md={{ order: 1, span: 14 }}>
                    <Row gutter={[10, 10]}>
                      <Col xs={{ order: 1, span: 24 }} md={{ order: 1, span: 24 }} style={{ marginBottom: '-20px' }}>
                        <h1 className='byp__location-title'>{countryPage?.title}</h1>
                      </Col>
                      <Col xs={{ order: 3, span: 24 }} md={{ order: 2, span: 24 }}>
                        <div className='byp__location-description' dangerouslySetInnerHTML={{ __html: countryPage?.body }} />
                        {/* <p style={{ fontSize: '18px', wordBreak: 'break-all' }}>
                          {countryPage?.body}
                        </p> */}
                      </Col>
                      {/** BUSCADOR **/}
                      <Col xs={{ order: 2, span: 24 }} md={{ order: 3, span: 24 }}>
                        <div className='title-with-icon'>
                          <RecordVoiceOverIcon className='icon_hidden_mobile' />
                          <h4 style={{ color: '#373737' }} className='title-with-icon'>Conoce que puedes estudiar</h4>
                        </div>
                        <p style={{ color: '#797979' }} className='subtitle__big-home'>Dale clic al botón para descubrir las propuestas</p>
                        {/** Este BOTON debe filtrar los resultados por Pais **/}
                        <Link to='/home'>
                          <Button
                            className='program_filter_button'
                            style={{
                              fontSize: '14px',
                              fontWeight: '600',
                              color: '#FFF',
                              backgroundColor: '#FF395C',
                              borderColor: '#FF395C',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '16px 6px',
                              gap: '6px',
                              '&:hover': {
                                backgroundColor: '#FF627E !important'
                              }
                            }}
                          > <EditIcon style={{ fontSize: '13px' }} />
                            Ver opciones
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                  {/** IMAGENES **/}
                  <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 10 }}>
                    {(image01 || image02 || image03) && (
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div className='images__locations--big'>
                            {image01?.src && <img src={image01?.src ?? BlueCastle} style={{ width: '100%', height: 'auto' }} />}
                          </div>
                        </Col>
                        <Col span={24} className='hidden-foto-celular'>
                          <div className='images__locations--small'>
                            {image02?.src && <img src={image02?.src ?? FarCastle} style={{ width: '100%', height: 'auto' }} />}
                          </div>
                        </Col>
                        <Col span={24} className='hidden-foto-celular'>
                          <div className='images__locations--small'>
                            {image03?.src && <img src={image03?.src ?? AlleyFestival} style={{ width: '100%', height: 'auto' }} />}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Layout>

          {/** CURSOS RECOMENDADOS**/}
          <Layout style={{}} className='layout-home'>
            <h4 style={{ color: '#373737', marginTop: '25px' }} className='country-content__title-block'>
              <IconCamping color='#373737' width='20px' height='20px' />{((!countryPage?.pageNotExists && countryPage?.status === 'Published') || isPreview) ? offers?.title : 'Cursos Recomendados'}
            </h4>
            <p className='country-content__subtitle-block'>{((!countryPage?.pageNotExists && countryPage?.status === 'Published') || isPreview) ? offers?.subtitle : 'Descubre el mundo estudiando'}</p>
            <HomeCarrousel slides={countryPage?.courses_recommendation ?? []} Slide={CardWithImage} className='carrousel_height big-home__carousel-responsive' label />
            <div className='big-home__cards-responsive-x snaps-inline'>
              {countryPage?.courses_recommendation?.map(item => (
                <React.Fragment key={item.id}>
                  <CardWithImage item={item} />
                </React.Fragment>
              ))}
            </div>
          </Layout>

          {/** CIUDADES **/}
          <Layout style={{}} className='layout-home'>
            <div>
              <h4 style={{ color: '#373737' }} className='country-content__title-block'>
                <PlaceIcon color='#373737' width='20px' height='20px' />
                ¿Buscas otro País?
              </h4>
              <p style={{ color: '#797979' }} className='country-content__subtitle-block'>Lo mejor de lo mejor</p>
            </div>
            <div>
              {/** <HomeCarrousel slides={cities ?? []} Slide={CardPlaces} className='big-home__package' slidesPerRow={slides} />**/}
            </div>
          </Layout>

          {/* *PROGRAMAS RECOMENDADOS **/}
          <Layout style={{ background: 'transparent' }}>
            <h4 style={{ color: '#373737', margin: '20px 0' }}>{((!countryPage?.pageNotExists && countryPage?.status === 'Published') || isPreview) ? 'En ' + countryPage?.parent?.name + ' puedes estudiar:' : '¿Que programa te gustaría estudiar?'}</h4>
            <Row style={{ margin: '10px 0' }}>
              <ProgramButtons programs={countryPage?.programs} countryPage={countryPage} city={city} />
            </Row>
          </Layout>

          {/* *ARTICULOS RECOMENDADOS - BLOG**/}
          <Layout style={{}} className='layout-home'>
            {
              ((!countryPage?.pageNotExists && countryPage?.status === 'Published') || isPreview) && (
                <>
                  <h4 style={{ color: '#373737' }} className='country-content__title-block'><RecordVoiceOverIcon style={{ fontSize: '20px' }} />{blogs?.title}</h4>
                  <p className='country-content__subtitle-block'>{blogs?.subtitle}</p>
                  <HomeCarrousel slides={blogs?.blogs ?? []} Slide={CardBlogSimple} className='big-home__package big-home__carousel-responsive big-home__blog-language' slidesPerRow={3} />
                  <div className='big-home__cards-responsive big-home__blog-language'>
                    {blogs?.blogs?.map((item, index) => (
                      <React.Fragment key={index}>
                        <CardBlogSimple item={item} />
                      </React.Fragment>
                    ))}
                  </div>
                </>
              )
            }
          </Layout>

          {/** LINK A HOME CHIQUITO **/}
          <Layout style={{}} className='layout-home'>
            <BlogFooter />
          </Layout>

        </Space>
      </div>
    </div>
  )
}
export { CountryContent }
