import { Button, Col, Form, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { UploadImage } from '../publicity/components/UploadImage'
import { usePostPublicity } from '../../../bussines/hooks/publicity/useSavePublicity'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import moment from 'moment'

function PublicityCourseInfo () {
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)

  const [text, setText] = useState(null)
  const [image2, setImage2] = useState(null)
  const [imageUrl2, setImageUrl2] = useState(null)

  const [form] = Form.useForm()
  const { savePublicity, isLoading } = usePostPublicity()

  const { user: _user } = useAuth()

  useEffect(() => {
    // vertical
    if (_user && _user?.publicityVerticalUpdate && _user?.publicityVerticalUpdate?.length > 0) {
      const currentValues = form.getFieldsValue(['publicityVertical'])
      const fieldName = 'publicityVertical'
      if (_user?.publicityVerticalUpdate[0]?.link) currentValues[fieldName][0].link = _user?.publicityVerticalUpdate[0].link
      if (_user?.publicityVerticalUpdate[0].id) currentValues[fieldName][0].id = _user?.publicityVerticalUpdate[0].id

      if (_user?.publicityVerticalUpdate[0]?.expirate_at) {
        const date = _user?.publicityVerticalUpdate[0]?.expirate_at?.slice(0, 10)
        currentValues[fieldName][0].expired_date = moment(date, 'YYYY-MM-DD')
        const time = _user?.publicityVerticalUpdate[0]?.expirate_at?.slice(11, 16)
        currentValues[fieldName][0].expired_time = moment(time, 'HH:mm')
      }

      if (_user?.publicityVerticalUpdate[0]?.src) setImage(_user?.publicityVerticalUpdate[0].src)
      if (_user?.publicityVerticalUpdate[0]?.name) setImageUrl(_user?.publicityVerticalUpdate[0].name)
    }

    // horizontal
    if (_user && _user?.publicityHorizontalUpdate && _user?.publicityHorizontalUpdate?.length > 0) {
      const currentValues = form.getFieldsValue(['publicityHorizontal'])
      const fieldName = 'publicityHorizontal'
      if (_user?.publicityHorizontalUpdate[0]?.title) currentValues[fieldName][0].title = _user?.publicityHorizontalUpdate[0].title
      if (_user?.publicityHorizontalUpdate[0]?.link) currentValues[fieldName][0].link = _user?.publicityHorizontalUpdate[0].link
      if (_user?.publicityHorizontalUpdate[0].id) currentValues[fieldName][0].id = _user?.publicityHorizontalUpdate[0].id

      if (_user?.publicityHorizontalUpdate[0]?.expirate_at) {
        const date = _user?.publicityHorizontalUpdate[0]?.expirate_at?.slice(0, 10)
        currentValues[fieldName][0].expired_date = moment(date, 'YYYY-MM-DD')
        const time = _user?.publicityHorizontalUpdate[0]?.expirate_at?.slice(11, 16)
        currentValues[fieldName][0].expired_time = moment(time, 'HH:mm')
      }

      if (_user?.publicityHorizontalUpdate[0]?.title) setText(_user?.publicityHorizontalUpdate[0].title)
      if (_user?.publicityHorizontalUpdate[0]?.src) setImage2(_user?.publicityHorizontalUpdate[0].src)
      if (_user?.publicityHorizontalUpdate[0]?.name) setImageUrl2(_user?.publicityHorizontalUpdate[0].name)
    }
  }, [_user])

  const onFinish = (values) => {
    values.publicityVertical[0].time = values?.publicityVertical[0]?.expired_time?.format('HH:mm')
    values.publicityHorizontal[0].time = values?.publicityHorizontal[0]?.expired_time?.format('HH:mm')
    savePublicity(values)
  }

  return (
    <Row className='publicity'>
      <Col span={24}>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            publicityVertical: [{}],
            publicityHorizontal: [{}]
          }}
        >
          <Row gutter={[16, 16]}>
            <Col md={12} className='publicity__section'>
              <h4>Banner Promocional</h4>

              <h6>Banner vertical</h6>
              <h5>Añade publicidad al inicio</h5>
              <span>(Más de 1 image, aparecerá como un carrusel que cambia automáticamente)</span>
              <span style={{ marginBottom: '1rem' }}>4 de 8 añadidas</span>
              <UploadImage
                setImage={setImage}
                setImageUrl={setImageUrl}
                image={image}
                imageUrl={imageUrl}
                haveTitle={false}
                nameForm='publicityVertical'
                form={form}
              />

              <h6 style={{ marginTop: '1.75rem' }}>Banner horizontal</h6>
              <h5>Añade publicidad al inicio</h5>
              <span>(Más de 1 image, aparecerá como un carrusel que cambia automáticamente)</span>
              <span style={{ marginBottom: '1rem' }}>4 de 8 añadidas</span>
              <UploadImage
                setImage={setImage2}
                setImageUrl={setImageUrl2}
                image={image2}
                imageUrl={imageUrl2}
                setText={setText}
                nameForm='publicityHorizontal'
                form={form}
              />

              <Row justify='end'>
                <Col>
                  <Button
                    htmlType='submit'
                    loading={isLoading}
                    className='publicity__submit'
                  >
                    Guardar
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col md={12} className='publicity__section'>
              <h5>Vista Previa</h5>
              <div className='publicity__grid'>
                <div className='publicity-info__image-static-1' />
                <div className='publicity-info__image-vertical'>
                  {image && (
                    <img src={image} alt='publicity' />
                  )}
                </div>
                <div className='publicity-info__image-horizontal'>
                  {image2 && (
                    <>
                      <img src={image2} alt='publicity' />
                      <div className='publicity-info__info'>
                        <p>{text ?? 'Descubre el mundo, y aprende en el proceso'}</p>
                        <p>1,925 diferentes cursos</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default PublicityCourseInfo
