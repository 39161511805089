import { useMutation } from 'react-query'
import state from '../../utils/state-control'
import { useState } from 'react'
import CollectionRepository from '../../repositories/CollectionRepository'

function useSaveCollection () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(CollectionRepository.create, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const saveCollection = (data) => {
    mutation.mutate(data)
  }

  return { ...mutation, state, currentState, saveCollection }
}

export { useSaveCollection }
