import { Image } from 'antd'
import { image } from '../../../utils/fallbackImage'
import img1 from '../../../common/assets/images/figma/Rectangle 44.png'
import img2 from '../../../common/assets/images/figma/Rectangle 45.png'
import img3 from '../../../common/assets/images/figma/Rectangle 37.png'
import img4 from '../../../common/assets/images/figma/Rectangle 43.png'

const LeftSideImages = ({ images = [] }) => {
  const imgs = [img1, img2, img3, img4]
  return (
    <div>
      {images.length >= 4 && images.map((img, index) => (
        <div key={'imaaage_' + index} style={{ marginBottom: '1.5rem' }}>
          <Image
            width='100%'
            height={200}
            src={img.src}
            style={{ objectFit: 'cover', borderRadius: '1rem' }}
            fallback={imgs[index] ?? image}
            // onError={() => notification.warning({ message: 'La imágen ' + img.src + ' no se encontró en nuestros servidores' })}
          />
        </div>
      ))}
      {images.length < 4 && imgs.map((img, index) => (
        <div key={'imaaage_' + index} style={{ marginBottom: '1.5rem' }}>
          <Image
            width='100%'
            height={200}
            src={img}
            // src='error
            style={{ objectFit: 'cover', borderRadius: '1rem' }}
            fallback={image}
          />
        </div>
      ))}
    </div>
  )
}

export { LeftSideImages }
