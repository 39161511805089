import { Button, Card, Col, Form, Row, Typography } from "antd";
import React, { useEffect } from "react";

function ButtonPaypal({ isLoading, isTest = false }) {
  const form = Form.useFormInstance();
  const phone = Form.useWatch("phone", form);
  useEffect(() => {
    if (phone?.length === 10) {
      console.log("the phone is valid");
    }
  }, [phone]);

  return (
    <Card className="quote_paypal mobile-margin_card txt-center">
      <div className="quote_paypal__desktop-card">
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              Empieza tu proceso de inscripción <b className="fw-7">GRATIS</b>
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Button
              className="btn__paypal byp-btn-red-50 br-05 fw-5"
              style={{ fontSize: "1rem" }}
              htmlType="submit"
              // disabled={!isTest}
              loading={isLoading}
            >
              Inicia Ahora
            </Button>
            <div className="info-quote__terminos byp-mt-1">
              <p className="terminos-mobile info-quote__terminos-span fw-4 text-black">
                Al continuar tu proceso de admisión das por hecho que has
                aceptado los
                <span className="info-quote__terminos-span">
                  {" "}
                  términos y condiciones{" "}
                </span>
                así como las{" "}
                <span className="info-quote__terminos-span">
                  {" "}
                  políticas de privacidad
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div className="quote_paypal__mobile-card">
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Typography.Title className="title" level={3}>
              Dar pago inicial de apartado
            </Typography.Title>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={24}>
                <Typography className="subtitle">
                  Aparta tu lugar con
                </Typography>
              </Col>
              <Col span={24}>
                <Typography className="description">
                  Pago inicial no reembolsable en caso de cancelación. Este pago
                  es abonable al costo total del programa
                </Typography>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Typography.Title level={1} className="line-through">
              $250 USD
            </Typography.Title>
          </Col>

          <Col span={24}>
            <Typography className="subtitle">
              Empieza tu proceso de inscripción gratis hoy mismo
            </Typography>
          </Col>

          <Col span={24}>
            <Button
              className="btn__paypal br-05 fw-5"
              style={{ fontSize: "1rem" }}
              htmlType="submit"
              loading={isLoading}
            >
              Inicia Ahora
            </Button>
          </Col>
        </Row>
      </div>
    </Card>
  );
}

export default ButtonPaypal;
