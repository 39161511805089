import { Button, Checkbox, Col, Form, Row, Select, Slider, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetOfferFiltersByProgram } from '../../../../bussines/hooks/offer/useGetOfferFiltersByProgram'
import { useGetCurrencyToCurrency } from '../../../../bussines/hooks/currencies/useGetCurrencyToCurrency'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { useSearch } from '../../../../providers/search/search-context-provider'
import { useDebounce } from '../../../../utils/useDebounce'
import { useLocation } from 'react-router-dom'
import { validateProgramName } from '../../../../utils/programUtils'
import { formatStringDate } from '../../../../utils/formatStringDate'
import { isAfter, isSameMonth } from 'date-fns'

const { Option } = Select

function BoardingAndAcademicFilters ({ program, type, handleMigas, clearMigas, state, setShowMovilFilters }) {
  const { search, changeSearch, form, width, breakpoint } = useSearch()
  const [searchFilter, setSearchFilter] = useState({ program })
  const { country, type_course: durationType, intake, activities: selectedActivities, religion, gender, grade, institution } = search
  const [showCities, setShowCities] = useState(true)

  const location = useLocation()

  const [cityOptions, setCityOptions] = useState([])
  const [institutionTypes, setInstitutionTypes] = useState([])
  const [institutions, setInstitutions] = useState([])
  const [intakeOptions, setIntakeOptions] = useState([])
  const [durationTypes, setDurationTypes] = useState([])
  const [activities, setActivities] = useState([])
  const [religions, setReligions] = useState([])
  const [genders, setGenders] = useState([])

  const [minCosts, setMinCosts] = useState(0)
  const [maxCosts, setMaxCosts] = useState(0)
  const [cost, setCost] = useState(0)
  const [, setId] = useState()
  const [isFirst, setIsFirst] = useState(true)

  const selectedCountries = Form.useWatch('country', form)
  const selectedCountiesData = Form.useWatch('city', form)

  const debouncedCost = useDebounce(cost, 1600)

  const { data: filterData, isLoading: loadingFilters } = useGetOfferFiltersByProgram({
    program,
    type_program: type,
    country,
    duration_type: durationType,
    activities: selectedActivities,
    intake,
    religion,
    gender,
    city: search?.arrayCities,
    type_school: search?.arrayTypeSchool,
    grade,
    institution
  })
  const { data: newdata, getCurrencyToCurrency } = useGetCurrencyToCurrency()
  const {
    userLocalCurrency
  } = useAuth()

  const filterOption = (inputValue, option) => {
    return option?.children?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) >= 0
  }

  const handleSearch = (key, values) => {
    const isMovil = width <= breakpoint
    const nValues = {
      ...search,
      ...searchFilter
    }
    if (key === 'school') {
      // setInstitutionId(values ?? undefined)
      nValues.place = values
      nValues.institution = values
      nValues.place_type = 'institution'
    } else if (key === 'place') {
      if (values) {
        const place = values.split('-')
        setId(place[0])
        if (place[1] === 'country') {
          nValues.place = place[0]
          nValues.place_type = place[1]
          nValues.idCountry = place[0]
          nValues.country_type = place[1]
          nValues.arrayCities = null
          nValues.page = 1
        } else {
          nValues.place = place[0]
          nValues.place_type = place[1]
          nValues.page = 1
          changeSearch('LANG_FORM', {
            ...search,
            place: place[0],
            place_type: place[1],
            page: 1
          })
        }
      } else {
        delete search.place
        delete search.place_type
        delete nValues.place
        delete nValues.place_type
        setId()
      }
    } else {
      nValues[key] = values
    }
    if (isMovil) {
      setSearchFilter(nValues)
    } else {
      changeSearch('LANG_FORM', nValues)
    }
  }

  const handleOnSubmitSearch = () => {
    const countFilter = Object.keys(searchFilter).length
    if (isNaN(searchFilter?.sede)) {
      delete searchFilter.sede
    }
    if (isNaN(searchFilter?.lang)) {
      delete searchFilter.lang
    }
    const programData = validateProgramName(program)
    if (program) {
      const nFilter = {
        ...searchFilter,
        ...programData
      }
      setSearchFilter(nFilter)
    }
    if (countFilter) {
      changeSearch('LANG_FORM', {
        ...search,
        ...searchFilter,
        ...programData
      })
      setShowMovilFilters(false)
    }
  }

  const handleArrayCities = (values) => {
    const array = values.map(item => {
      return Number(item.split('-')[0])
    })
    handleSearch('arrayCities', array)
    let cities
    if (array.length > 0) {
      cities = cityOptions.filter(city => array.includes(city.id))
    } else {
      cities = cityOptions
    }
    handleInfo(cities, 'cities')
  }

  const tipFormatter = value => {
    // Multiplicar el valor por 2
    const formattedValue = value * newdata?.data?.value
    return `${formattedValue}`
  }

  const cleanSearch = () => {
    form.resetFields()
    clearMigas()
    const newSearch = {
      program: search.program
    }
    // if (search.country) {
    //   newSearch.country = search.country
    // }
    // if (search.grade) {
    //   newSearch.grade = search.grade
    // }

    setCost(maxCosts)

    setId()
    setShowCities(true)

    const queryString = Object.keys(newSearch).map((key) => key + '=' + newSearch[key]).join('&')
    window.history.pushState(newSearch, '', location.pathname + '?' + queryString)

    changeSearch('LANG_FORM', {
      ...newSearch
    })
  }

  const formatMoney = (cost) => {
    const newCost = cost * newdata?.data?.value
    return newCost.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    })
  }

  useEffect(() => {
    getCurrencyToCurrency(userLocalCurrency, 'USD')
  }, [])

  useEffect(() => {
    if (!loadingFilters && filterData) {
      const cities = filterData.cities
      const institutionTypes = filterData.types
      const intakes = filterData.intakes

      const today = new Date()
      const validIntakes = intakes.filter(intake => {
        const date = formatStringDate(intake, 'Y/LL/dd')
        const instakeData = new Date(date)
        return isSameMonth(today, instakeData) || isAfter(instakeData, today)
      })
      setIntakeOptions(validIntakes)

      setInstitutions(filterData.institutions ?? [])
      setInstitutionTypes(institutionTypes)
      setDurationTypes(filterData.durations ?? [])
      setActivities([...filterData.sports, ...filterData.extras])
      setReligions(filterData.religions)
      setGenders(filterData.genders)

      if (cities.length <= 5) {
        setCityOptions(cities)
      } else { // TOP 5 cities
        const sorted = cities.sort(function (a, b) {
          if (a.offers <= b.offers) {
            return 1
          }
          return -1
        })
        // Save all the cities in another place?
        const topCities = sorted.slice(0, 5)
        setCityOptions(topCities)
      }

      const costs = filterData.costs_usd ?? [0, 0]
      setMinCosts(costs[0])
      setMaxCosts(costs[1])
      setCost(search.cost ?? costs[1])

      if (state?.location && isFirst) {
        if (cities?.length > 0 && state.type === 'city') {
          const foundCity = cities?.find(item => item.value.toLowerCase().includes(state?.location?.toLowerCase()))
          if (foundCity) {
            setIsFirst(false)
            form.setFieldsValue({
              city: [foundCity?.id + '-city']
            })
          }
        } else if (state.type === 'country') {
          //
        }
      }
    }
  }, [loadingFilters, filterData])

  useEffect(() => {
    if (selectedCountries) {
      if (!showCities && selectedCountries.length) {
        setShowCities(true)
      }
    }
  }, [selectedCountries])

  useEffect(() => {
    if (debouncedCost > 0 && debouncedCost < maxCosts) {
      handleSearch('cost', debouncedCost)
      handleInfo(formatMoney(debouncedCost), 'cost')
    }
  }, [debouncedCost])

  useEffect(() => {
    const selectedCities = selectedCountiesData?.map(item => {
      return Number(item.split('-')[0])
    })
    if (!loadingFilters && filterData) {
      const sorted = filterData.cities?.sort(function (a, b) {
        if (!selectedCities?.includes(Number(b.id)) && !selectedCities?.includes(Number(a.id))) {
          if (a.offers <= b.offers) {
            return 1
          }
          return -1
        }
        if (!selectedCities?.includes(Number(a.id)) && selectedCities?.includes(Number(b.id))) {
          return 1
        }
        return -1
      })
      const topCities = sorted.slice(0, 5)
      setCityOptions(topCities)
    }
  }, [selectedCountiesData])

  const handleInfo = (e, type, option) => {
    if (type === 'place') {
      handleMigas(option.children, type)
    } else {
      handleMigas(e, type)
    }
  }

  const IsLoadingIndicator = () => {
    if (loadingFilters) {
      return (
        <div>
          <Spin />
        </div>
      )
    }
    return null
  }

  return (
    <Form form={form}>
      <Row>
        <Col span={24} className='byp-mb-1'>
          <Row justify='end'>
            <Col>
              <Button onClick={cleanSearch} type='ghost' className='btn-clean-filters'>Limpiar Filtros</Button>
            </Col>
          </Row>
        </Col>
        <Col span={24} className='byp-mb-1'>
          <h4>Busqueda Avanzada</h4>
        </Col>

        {/* Cities */}
        {showCities && cityOptions.length > 0 && (
          <>
            <Col span={24}>
              <h6 className='school-search__title'>Ciudades</h6>
            </Col>
            <Col span={24} className='byp-mb-1 cities-container'>
              <IsLoadingIndicator />
              <Form.Item name='city'>
                <Checkbox.Group
                  style={{ width: '100%' }}
                  onChange={(e, option) => {
                    handleArrayCities(e)
                  }}
                  disabled={loadingFilters}
                >
                  <Row>
                    {cityOptions?.map(option => (
                      <Col span={24} key={option.id + '-city'}>
                        <Checkbox className='school-search__checkbox' value={option.id + '-city'}>
                          {option.country_name && option.country_name + ' - '} {option.name} {option.offers}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </>
        )}
        {/* Institution */}
        <Col span={24}>
          <h6 className='school-search__title'>Institución</h6>
        </Col>
        <Col span={24} className='byp-mb-1'>
          <IsLoadingIndicator />
          <Form.Item name='institution' noStyle>
            <Select
              onChange={e => {
                handleSearch('school', e)
                // handleInfo(e, 'institution')
              }}
              className='school-search__item'
              showSearch
              allowClear
              filterOption={filterOption}
              size='large'
            >
              <Option key='institution_all' value={null}>Todas</Option>
              {institutions?.map(institution => (
                <Option key={'institution_' + institution.id} value={institution.id}>{institution.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Duration */}
        <Col span={24}>
          <h6 className='school-search__title'>Duración del programa</h6>
        </Col>
        <Col span={24} className='byp-mb-1 cities-container'>
          <IsLoadingIndicator />
          <Form.Item name='duration_type' noStyle>
            <Select
              onChange={(value) => handleSearch('type_course', value)}
              className='school-search__item'
              showSearch
              allowClear
              filterOption={filterOption}
              size='large'
            >
              {durationTypes?.map(duration => (
                <Option key={duration} value={duration}>{duration}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Course Start */}
        <Col span={24}>
          <h6 className='school-search__title'>¿Cuándo deseas comenzar tu programa?</h6>
        </Col>
        <Col span={24} className='byp-mb-1 cities-container'>
          <IsLoadingIndicator />
          <Form.Item name='intake' noStyle>
            <Select
              onChange={(value) => handleSearch('intake', value)}
              className='school-search__item'
              showSearch
              allowClear
              filterOption={filterOption}
              size='large'
            >
              {intakeOptions?.map(intake => (
                <Option key={intake} value={intake}>{formatStringDate(intake, 'LLLL yyyy')}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Hobbies And Sports */}
        <Col span={24}>
          <h6 className='school-search__title'>Hobbies y deportes que te interesan</h6>
        </Col>
        <Col span={24} className='byp-mb-1 cities-container'>
          <IsLoadingIndicator />
          <Form.Item name='activities' noStyle>
            <Select
              onChange={(value) => handleSearch('activities', value)}
              className='school-search__item'
              showSearch
              allowClear
              filterOption={filterOption}
              mode='multiple'
              size='large'
            >
              {activities?.map(activity => (
                <Option key={activity} value={activity} data-type={activity}>{activity}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Religion */}
        <Col span={24}>
          <h6 className='school-search__title'>Religión</h6>
        </Col>
        <Col span={24} className='byp-mb-1 cities-container'>
          <IsLoadingIndicator />
          <Form.Item name='religion' noStyle>
            <Select
              onChange={(value) => handleSearch('religion', value)}
              className='school-search__item'
              showSearch
              allowClear
              filterOption={filterOption}
              size='large'
            >
              {religions?.map(religion => (
                <Option key={religion} value={religion}>{religion}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Type of institution */}
        {program === 'academic_years' && (
          <>
            <Col span={24}>
              <h6 className='school-search__title'>Tipo de institución</h6>
            </Col>
            <Col span={24} className='byp-mb-1'>
              <IsLoadingIndicator />
              <Form.Item name='type_school' noStyle>
                <Select
                  onChange={e => {
                    handleSearch('arrayTypeSchool', e)
                    handleInfo(e, 'type_school')
                  }}
                  className='school-search__item'
                  showSearch
                  allowClear
                  filterOption={filterOption}
                  size='large'
                >
                  {institutionTypes?.map(type => (
                    <Option key={type} value={type}>{type}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}

        {/* Gender */}
        <Col span={24}>
          <h6 className='school-search__title'>Género</h6>
        </Col>
        <Col span={24} className='byp-mb-1 cities-container'>
          <IsLoadingIndicator />
          <Form.Item name='gender' noStyle>
            <Select
              onChange={(value) => handleSearch('gender', value)}
              className='school-search__item'
              showSearch
              allowClear
              filterOption={filterOption}
              size='large'
            >
              {genders?.map(gender => (
                <Option key={gender} value={gender}>{gender}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {maxCosts > minCosts && (
          <Col span={24}>
            <h6 className='school-search__title'>Precio aproximado del curso</h6>
            <p className='school-search__cost'>{userLocalCurrency} {formatMoney(cost)}+</p>
            <Slider
              className='school-search__slider'
              value={cost}
              min={minCosts}
              max={maxCosts}
              tipFormatter={tipFormatter}
              onChange={e => setCost(e)}
              disabled={loadingFilters}
            />
          </Col>
        )}

        {(width <= breakpoint) && (
          <Col span={24}>
            <Button
              onClick={handleOnSubmitSearch}
              block
              size='large'
              className='byp-btn-red-50'
            >Buscar
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  )
}

export default BoardingAndAcademicFilters
