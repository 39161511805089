import { Button, Checkbox, Col, DatePicker, Form, Row, Select, Slider, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetOfferFiltersByProgram } from '../../../../bussines/hooks/offer/useGetOfferFiltersByProgram'
import { useGetCurrencyToCurrency } from '../../../../bussines/hooks/currencies/useGetCurrencyToCurrency'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { useSearch } from '../../../../providers/search/search-context-provider'
import { useDebounce } from '../../../../utils/useDebounce'
import { SelectInstitutionsWithOffers } from '../../../../common/components/helper-form/SelectInstitutions'
import { CheckCampusFormItem } from '../../../../common/components/helper-form/CheckCampusFormItem'
import { useLocation } from 'react-router-dom'
import { CheckLanguagesFormItem } from '../../../../common/components/helper-form/CheckLanguagesFormItem'
import { validateProgramName } from '../../../../utils/programUtils'

const { Option } = Select

function GraduateFilters ({ program, type, handleMigas, clearMigas, state, setShowMovilFilters }) {
  const { search, changeSearch, form, width, breakpoint } = useSearch()
  const [searchFilter, setSearchFilter] = useState({ program })
  const { place_type: placeType, place, search: textSearch, country } = search
  const [showCities, setShowCities] = useState(true)

  const location = useLocation()
  // const [optionCountries, setOptionsCountries] = useState([])

  const [languageOptions, setLanguageOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [institutionTypes, setInstitutionTypes] = useState([])
  const [areaOptions, setAreaOptions] = useState([])
  const [campusOptions, setCampusOptions] = useState([])
  const [schoolsOptions, setSchoolsOptions] = useState([])
  const [seasonsOptions, setSeasonsOptions] = useState([])

  const [minCosts, setMinCosts] = useState(0)
  const [maxCosts, setMaxCosts] = useState(0)
  const [cost, setCost] = useState(0)
  const [, setId] = useState()
  // const [optionCities, setOptionsCities] = useState([])
  const [isFirst, setIsFirst] = useState(true)

  const institutionId = Form.useWatch('school', form)
  // TODO: Replace this with the country value in the search?
  const selectedCountries = Form.useWatch('country', form)

  const debouncedCost = useDebounce(cost, 1600)

  const { data: filterData, isLoading: loadingFilters } = useGetOfferFiltersByProgram({
    program,
    type_program: type,
    search: textSearch,
    country,
    institution: institutionId,
    study_area: search?.study_area,
    city: search?.arrayCities,
    campus: search?.campus,
    type_school: search?.arrayTypeSchool,
    language: search.arrayLang,
    // year: search.year
    seasons: search.seasons
  })
  const { data: newdata, getCurrencyToCurrency } = useGetCurrencyToCurrency()
  const {
    userLocalCurrency
  } = useAuth()

  const filterOption = (inputValue, option) => {
    return option.props['data-type']?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1
  }

  const handleSearch = (key, values) => {
    const isMovil = width <= breakpoint
    const nValues = {
      ...search,
      ...searchFilter
    }
    if (key === 'school') {
      // setInstitutionId(values ?? undefined)
      nValues.place = values
      nValues.institution = values
      nValues.place_type = 'institution'
    } else if (key === 'place') {
      if (values) {
        const place = values.split('-')
        setId(place[0])
        if (place[1] === 'country') {
          nValues.place = place[0]
          nValues.place_type = place[1]
          nValues.idCountry = place[0]
          nValues.country_type = place[1]
          nValues.arrayCities = null
          nValues.page = 1
        } else {
          nValues.place = place[0]
          nValues.place_type = place[1]
          nValues.page = 1
          changeSearch('LANG_FORM', {
            ...search,
            place: place[0],
            place_type: place[1],
            page: 1
          })
        }
      } else {
        delete search.place
        delete search.place_type
        delete nValues.place
        delete nValues.place_type
        setId()
      }
    } else {
      nValues[key] = values
    }
    if (isMovil) {
      setSearchFilter(nValues)
    } else {
      changeSearch('LANG_FORM', nValues)
    }
  }

  const handleOnSubmitSearch = () => {
    const countFilter = Object.keys(searchFilter).length
    if (isNaN(searchFilter?.sede)) {
      delete searchFilter.sede
    }
    if (isNaN(searchFilter?.lang)) {
      delete searchFilter.lang
    }
    const programData = validateProgramName(program)
    if (program) {
      const nFilter = {
        ...searchFilter,
        ...programData
      }
      setSearchFilter(nFilter)
    }
    if (countFilter) {
      changeSearch('LANG_FORM', {
        ...search,
        ...searchFilter,
        ...programData
      })
      setShowMovilFilters(false)
    }
  }

  const handleArrayCities = (values) => {
    const array = values.map(item => {
      return Number(item.split('-')[0])
    })
    handleSearch('arrayCities', array)
    let cities
    if (array.length > 0) {
      cities = cityOptions.filter(city => array.includes(city.id))
    } else {
      cities = cityOptions
    }
    handleInfo(cities, 'cities')
  }

  const tipFormatter = value => {
    // Multiplicar el valor por 2
    const formattedValue = value * newdata?.data?.value
    return `${formattedValue}`
  }

  const cleanSearch = () => {
    form.resetFields()
    clearMigas()
    const newSearch = {}
    Object.assign(newSearch, search)

    delete newSearch.idLanguage
    delete newSearch.lang
    delete newSearch.dateAll
    delete newSearch.place
    delete newSearch.place_type
    delete newSearch.place_name
    delete newSearch.search
    delete newSearch.rate
    delete newSearch.age
    delete newSearch.school
    delete newSearch.is_legal
    delete newSearch.arrayLang
    delete newSearch.arrayCities
    delete newSearch.school_id
    delete newSearch.school_type
    delete newSearch.idCountry
    delete newSearch.country_type
    delete newSearch.arrayTypeSchool

    setCost(maxCosts)

    setId()
    // setInstitutionId()
    setShowCities(true)

    const queryString = Object.keys(newSearch).map((key) => key + '=' + newSearch[key]).join('&')
    window.history.pushState(newSearch, '', location.pathname + '?' + queryString)

    changeSearch('LANG_FORM', {
      ...newSearch
    })
  }

  const handleArrayLanguages = (values) => {
    handleSearch('arrayLang', values)
  }

  const handleOnSchoolChange = (value) => {
    handleSearch('school', value)
  }

  const formatMoney = (cost) => {
    const newCost = cost * newdata?.data?.value
    return newCost.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    })
  }

  useEffect(() => {
    getCurrencyToCurrency(userLocalCurrency, 'USD')
  }, [])

  useEffect(() => {
    if (!loadingFilters && filterData) {
      const cities = Object.values(filterData.cities)
      const institutions = Object.values(filterData.institutions)
      const campus = Object.values(filterData.campus)
      const institutionTypes = filterData.institutionTypes
      const studyAreas = filterData.studyAreas
      const seasons = filterData.seasons
      const languages = filterData.languages

      setLanguageOptions(languages)
      setInstitutionTypes(Object.keys(institutionTypes ?? {}))
      setAreaOptions(Object.keys(studyAreas ?? {}))
      setSeasonsOptions(Object.keys(seasons ?? {}))
      setCampusOptions(campus)
      const sortedInstitutions = institutions?.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      setSchoolsOptions(sortedInstitutions)

      if (cities.length <= 5) {
        setCityOptions(cities)
      } else { // TOP 5 cities
        const sorted = cities.sort(function (a, b) {
          if (a.offers <= b.offers) {
            return 1
          }
          return -1
        })
        // Save all the cities in another place?
        const topCities = sorted.slice(0, 5)
        setCityOptions(topCities)
      }

      const costs = filterData.costs_usd ?? [0, 0]
      setMinCosts(costs[0])
      setMaxCosts(costs[1])
      setCost(search.cost ?? costs[1])

      if (state?.location && isFirst) {
        if (cities?.length > 0 && state.type === 'city') {
          const foundCity = cities?.find(item => item.value.toLowerCase().includes(state?.location?.toLowerCase()))
          if (foundCity) {
            setIsFirst(false)
            form.setFieldsValue({
              city: [foundCity?.id + '-city']
            })
          }
        } else if (state.type === 'country') {
          // const countries = countryData?.find(item => item.value.toLowerCase().includes(state?.location?.toLowerCase()))
          // if (countries) {
          //   form.setFieldsValue({
          //     country: [countries?.id]
          //   })
          //   setIsFirst(false)
          // }
        }
      }
    }
  }, [loadingFilters, filterData])

  useEffect(() => {
    if (selectedCountries) {
      if (!showCities && selectedCountries.length) {
        setShowCities(true)
      }
    }
  }, [selectedCountries])

  useEffect(() => {
    if (debouncedCost > 0 && debouncedCost < maxCosts) {
      handleSearch('cost', debouncedCost)
      handleInfo(formatMoney(debouncedCost), 'cost')
    }
  }, [debouncedCost])

  const handleInfo = (e, type, option) => {
    if (type === 'place') {
      handleMigas(option.children, type)
    } else {
      handleMigas(e, type)
    }
  }

  const IsLoadingIndicator = () => {
    if (loadingFilters) {
      return (
        <div>
          <Spin />
        </div>
      )
    }
    return null
  }

  return (
    <Form form={form}>
      <Row>
        <Col span={24} className='byp-mb-1'>
          <Row justify='end'>
            <Col>
              <Button onClick={cleanSearch} type='ghost' className='btn-clean-filters'>Limpiar Filtros</Button>
            </Col>
          </Row>
        </Col>
        <Col span={24} className='byp-mb-1'>
          <h4>Busqueda Avanzada</h4>
        </Col>

        {/* area */}
        <Col span={24}>
          <h6 className='school-search__title'>Area de Estudios de Interés:</h6>
        </Col>
        <Col span={24}>
          <Form.Item name='study_area'>
            <Select
              className='school-search__item'
              showSearch
              filterOption={filterOption}
              size='large'
              onChange={(e, option) => {
                handleSearch('study_area', e)
                handleInfo(option?.children, 'study_area')
              }}
              loading={loadingFilters}
              disabled={loadingFilters}
            >
              <Option value={null} data-type='todos'>Todos</Option>
              {areaOptions?.includes('Business') && (
                <Option key='b' value='Business'>Negocios</Option>
              )}
              {areaOptions?.includes('Arts') && (
                <Option key='a' value='Arts'>Artes</Option>
              )}
              {areaOptions?.includes('Sciences') && (
                <Option key='s' value='Sciences'>Ciencias(STEM)</Option>
              )}
              {areaOptions?.includes('Humanities') && (
                <Option key='h' value='Humanities'>Humanidades</Option>
              )}
              {areaOptions?.includes('Health') && (
                <Option key='e' value='Health & Community Studies'>Estudios sanitarios y comunitarios</Option>
              )}
              {areaOptions?.includes('Other') && (
                <Option key='o' value='Other'>Otros</Option>
              )}
            </Select>
          </Form.Item>
        </Col>

        {/* Cities */}
        {showCities && cityOptions.length > 0 && (
          <>
            <Col span={24}>
              <h6 className='school-search__title'>Ciudades</h6>
            </Col>
            <Col span={24} className='byp-mb-1 cities-container'>
              <IsLoadingIndicator />
              <Form.Item name='city'>
                <Checkbox.Group
                  style={{ width: '100%' }}
                  onChange={(e, option) => {
                    handleArrayCities(e)
                  }}
                  disabled={loadingFilters}
                >
                  <Row>
                    {/* {optionCities?.map(option => (
                      <Col span={24} key={option.id + '-' + option.type}>
                        <Checkbox className='school-search__checkbox' value={option.id + '-' + option.type}>
                          {option.value} {option.offers}
                        </Checkbox>
                      </Col>
                    ))} */}
                    {cityOptions?.map(option => (
                      <Col span={24} key={option.id + '-city'}>
                        <Checkbox className='school-search__checkbox' value={option.id + '-city'}>
                          {option.country_name && option.country_name + ' - '} {option.name} {option.offers}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </>
        )}

        {/* Languages */}
        <Col span={24}>
          {languageOptions.length > 0 && (
            <h6 className='school-search__title'>¿En que idioma te gustaría estudiar tu programa?</h6>
          )}
        </Col>
        <Col span={24} className='byp-mb-1'>
          <IsLoadingIndicator />
          <CheckLanguagesFormItem
            name='lang'
            filters={{ program, type, countries: selectedCountries, offersSearch: textSearch }}
            onChange={handleArrayLanguages}
            customData={languageOptions}
            disabled={loadingFilters}
          />
        </Col>

        {/* Type of institution */}
        <Col span={24}>
          <h6 className='school-search__title'>Tipo de institución</h6>
        </Col>
        <Col span={24} className='byp-mb-1'>
          <IsLoadingIndicator />
          <Form.Item noStyle name='type_school'>
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={e => {
                handleSearch('arrayTypeSchool', e)
                handleInfo(e, 'type_school')
              }}
              disabled={loadingFilters}
            >
              <Row>
                {institutionTypes?.includes('Public University') && (
                  <Col span={24}>
                    <Checkbox className='school-search__checkbox' value='Public University'>Universidad pública</Checkbox>
                  </Col>
                )}
                {institutionTypes?.includes('Private University') && (
                  <Col span={24}>
                    <Checkbox className='school-search__checkbox' value='Private University'>Universidad privada</Checkbox>
                  </Col>
                )}
                {institutionTypes?.includes('Public College') && (
                  <Col span={24}>
                    <Checkbox className='school-search__checkbox' value='Public College'>Colegio público</Checkbox>
                  </Col>
                )}
                {institutionTypes?.includes('Private College') && (
                  <Col span={24}>
                    <Checkbox className='school-search__checkbox' value='Private College'>Colegio privado</Checkbox>
                  </Col>
                )}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>

        {/* schools */}
        <Col span={24}>
          <h6 className='school-search__title'>Institución Educativa</h6>
        </Col>
        <Col span={24} className='byp-mb-1'>
          <SelectInstitutionsWithOffers
            name='school'
            customData={schoolsOptions}
            showSearch
            placeholder='Escuela'
            size='large'
            className='school-search__item'
            filters={{
              program,
              type,
              whereHasOffers: true,
              country: placeType === 'country' ? place : undefined,
              offersSearch: textSearch
            }}
            onChange={(e, option) => {
              setShowCities(!e) // Si e es true (institución seleccionada), oculta las ciudades
              handleOnSchoolChange(e)
              handleInfo(option?.children, 'school')
            }}
            onClear={() => {
              setShowCities(true)
              handleSearch('school', null)
              console.log('🚀 ~ GraduateFilters ~ onClear:')
            }}
            disabled={loadingFilters}
          />
        </Col>

        {/* Campus */}
        {institutionId && (
          <>
            <Col span={24}>
              <h6 className='school-search__title'>Campus</h6>
            </Col>
            <CheckCampusFormItem
              filters={{
                program: 'graduate',
                whereHasOffers: true,
                institution: institutionId
              }}
              onChange={(val) => {
                const isMovil = width <= breakpoint
                if (isMovil) {
                  setSearchFilter({
                    ...searchFilter,
                    place: val,
                    place_type: 'campus'
                  })
                } else {
                  changeSearch('LANG_FORM', {
                    ...search,
                    place: val,
                    place_type: 'campus',
                    campus: val
                    // page: 1
                  })
                }
              }}
              customData={campusOptions}
              loading={loadingFilters}
              name='campus'
              disabled={loadingFilters}
            />
          </>
        )}

        {/* dates */}
        <Col span={24}>
          <h6>¿Cuándo deseas comenzar tu programa?</h6>
        </Col>
        <Col span={24} className='byp-mb-1'>
          <Row gutter={[16, 16]}>
            <Col span={19}>
              Inicio de clases
              <Form.Item name='seasons'>
                <Select
                  className='school-search__item'
                  showSearch
                  allowClear
                  filterOption={filterOption}
                  size='large'
                  onChange={(e, option) => {
                    handleSearch('seasons', e)
                    handleInfo(option?.children, 'seasons')
                  }}
                  disabled={loadingFilters}
                >
                  {seasonsOptions?.includes('Spring') && <Option value='Spring'>Primavera</Option>}
                  {seasonsOptions?.includes('Summer') && <Option value='Summer'>Verano</Option>}
                  {seasonsOptions?.includes('Fall') && <Option value='Fall'>Otoño</Option>}
                  {seasonsOptions?.includes('Winter') && <Option value='Winter'>Invierno</Option>}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              Año
              <Form.Item name='year'>
                <DatePicker
                  picker='year'
                  size='large'
                  onChange={(e, year) => {
                    handleSearch('year', year)
                    handleInfo(year, 'year')
                  }}
                  disabled={loadingFilters}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        {maxCosts > minCosts && (
          <Col span={24}>
            <h6 className='school-search__title'>Precio aproximado del curso</h6>
            <p className='school-search__cost'>{userLocalCurrency} {formatMoney(cost)}+</p>
            <Slider
              className='school-search__slider'
              value={cost}
              min={minCosts}
              max={maxCosts}
              tipFormatter={tipFormatter}
              onChange={e => setCost(e)}
              disabled={loadingFilters}
            />
          </Col>
        )}

        {(width <= breakpoint) && (
          <Col span={24}>
            <Button
              onClick={handleOnSubmitSearch}
              block
              size='large'
              className='byp-btn-red-50'
            >Buscar
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  )
}

export default GraduateFilters
