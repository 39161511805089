import { Modal } from 'antd'
import React from 'react'
import { RegisterForm } from '../../pages/register/components/forms/RegisterForm'

export default function ModalRegisterUser ({ isModalOpen, setIsModalOpen }) {
  return (
    <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} closable={false} footer='' maskClosable={false}>
      <RegisterForm isOnModal />
    </Modal>
  )
}
