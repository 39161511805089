import { useQuery } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'

/**
 * useGetCoursesByFilter
 * @param {Object} filter
 * @returns {UseQueryResult}
 */
function useGetCoursesByFilter (filter, enabled = true) {
  for (const clave in filter) {
    if (filter[clave] === null || filter[clave] === undefined || filter[clave] === '') {
      delete filter[clave]
    }
  }
  return useQuery(['useGetCoursesByFilter', filter],
    () => CourseRepository.getCoursesByFilter(filter), {
      retry: 2, enabled
    })
}

function useGetCoursesByFilterAdvanceSearch (filter) {
  return useQuery(['useGetCoursesByFilterAdvanceSearch', filter],
    () => CourseRepository.getCoursesByFilterAdvanceSearch(filter), {
      retry: 2,
      enabled: !!filter.program
    })
}

/**
 * useGetCoursesLocationsByFilter
 * @param {Object} filter
 * @returns {UseQueryResult}
 */
function useGetCoursesLocationsByFilter (filter, enabled = true) {
  for (const clave in filter) {
    if (filter[clave] === null || filter[clave] === undefined) {
      delete filter[clave]
    }
  }
  // const queryString = Object.keys(filter).map((key) => key + '=' + filter[key]).join('&')
  return useQuery(['useGetCoursesLocationsByFilter', filter],
    () => CourseRepository.getCoursesLocationsByFilter(filter), {
      retry: 1, enabled
    })
}

function useGetCoursesLocationsByFilterAdvanceSearch (filter) {
  return useQuery(['useGetCoursesLocationsByFilterAdvanceSearch', filter],
    () => CourseRepository.getCoursesLocationsByFilterAdvanceSearch(filter), {
      retry: 1,
      enabled: !!filter.program
    })
}

export { useGetCoursesByFilter, useGetCoursesLocationsByFilter, useGetCoursesByFilterAdvanceSearch, useGetCoursesLocationsByFilterAdvanceSearch }
