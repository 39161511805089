import { useQuery } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'

function useGetPackage (ids, type, filters = {}, enabled = true) {
  return useQuery(['useGetPackage', ids, type, filters],
    () => AgencyRepository.getPackage(ids, type, filters), {
      retry: 2, enabled
    })
}

function useGetBigHomePage () {
  return useQuery(['useGetBigHomePage'],
    () => AgencyRepository.getPageBigHome(), {
      retry: 2
    })
}

function useGetConfiguration () {
  return useQuery(['useGetConfiguration'],
    () => AgencyRepository.getConfiguration(), {
      retry: 2
    })
}

export { useGetPackage, useGetBigHomePage, useGetConfiguration }
