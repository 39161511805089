import { Checkbox, Col, Form, Row } from 'antd'
import { languagesEs } from '../../../bussines/utils/LanguagesES'
import { useGetOfferLanguages } from '../../../bussines/hooks/offer/useGetOfferLanguages'

const CheckLanguagesFormItem = ({ name, onChange, filters, customData, formItemProps = {}, ...props }) => {
  const { data } = useGetOfferLanguages(filters, !customData)
  return (
    <Form.Item noStyle name={name} {...formItemProps}>
      <Checkbox.Group style={{ width: '100%' }} onChange={onChange} {...props}>
        <Row>
          {data?.map((item) => (
            <Col span={24} key={item.id}>
              <Checkbox className='school-search__checkbox' value={item.id}>{languagesEs[item.name] ?? item.name}</Checkbox>
            </Col>
          ))}
          {customData?.map((item) => (
            <Col span={24} key={item.id}>
              <Checkbox className='school-search__checkbox' value={item.id}>{languagesEs[item.name] ?? item.name} {item.offers}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  )
}

export { CheckLanguagesFormItem }
