import { Form, Modal, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
// import { URL_QUOTE_PREVIEW } from '../../quote-preview'
import { showErrorModal } from '../../../utils/errorModal'
import { URL_QUOTE_APPLY_PREVIEW } from '../../quote-preview-apply'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { cutFromStorage, saveInStorage } from '../../../utils/localStorageUtils'
import { URL_REGISTER_PAGE_BETA } from '../../register'

function QuoterFormConfigured ({ children }) {
  const {
    service,
    form, handleFinishForm, course, errorPost,
    isSuccessPost, isErrorPost, quote, favorite,
    setFormConfig, formConfig, baseQuote, isPublicUser, setFaileForm
  } = useQuoter()
  const navigate = useNavigate()
  const location = useLocation()
  const [, contextHolder] = Modal.useModal()
  const [initialized] = useState(false)

  const queryParams = new URLSearchParams(location.search)
  const valueQueryParam = queryParams.get('values')

  useEffect(() => {
    if (isSuccessPost && !favorite) {
      if (isPublicUser) {
        saveInStorage('redirectOnLoginSucces', URL_QUOTE_APPLY_PREVIEW.replace(':id', quote.uuid))
        navigate(URL_REGISTER_PAGE_BETA)
      } else {
        navigate(URL_QUOTE_APPLY_PREVIEW.replace(':id', quote.uuid))
      }
    }
    if (isErrorPost) {
      const errMsg = errorPost?.response?.data?.message
      showErrorModal(errMsg)
    }
  }, [isSuccessPost, isErrorPost])

  useEffect(() => {
    if (course) {
      const { offer } = course
      const loadInitialData = async () => {
        if (!formConfig.fees) {
          setFormConfig({
            ...formConfig,
            fees: []
          })
        }

        const newFees = formConfig.fees ?? []
        const courseCosts = formConfig.courseCosts ?? []
        const formValues = {}
        const formConfigValues = {}

        const searchedWeeks = cutFromStorage('search_course_weeks')
        const searchedStartCourse = cutFromStorage('search_course_start')
        if (!formConfig.weeks && searchedWeeks) {
          formConfigValues.weeks = searchedWeeks
          formValues.weeks = searchedWeeks
        }
        if (!formConfig.start && searchedStartCourse) {
          formConfigValues.start = searchedStartCourse
          formValues.startEvent = moment(searchedStartCourse)
        }

        if (!initialized) {
          // setInitialized(true)
          // const availableIntakes = service.getCourseIntakes()
          // const first = availableIntakes[0] ?? {}
          // const intakeV = first.id + '&' + first.season + '&' + first.intake
          // const values = intakeV.split('&')
          if (offer.program?.name === 'graduate' && !formConfig.intake) {
            // formValues.intake = intakeV
            // formValues.length = offer.length
            // formValues.duration_type = offer.time

            // formConfigValues.intake = values[2] + ''
            // formConfigValues.duration = offer.length
            // formConfigValues.duration_type = offer.time

            // const dates = service.getDates(values[2] + '')
            // service?.searchPrices(dates[0])
            // service?.searchPricesById(values[0], values[2])
          } else if ((offer.program?.name === 'academic_years' || offer.program?.name === 'boarding_schools') && !formConfig.intake) {
            // const intakes = service.getCourseIntake() ?? [null]
            // formValues.intake_date = intakes[0]
            // formValues.length = offer.length

            // formConfigValues.intake = intakes[0]
          }
        }

        try {
          const alreadyActivePrices = formConfig.fees ?? []
          const hs = service?.getPrices()?.extras
          if (hs) {
            for (const item of hs) {
              const extraAlreadyIncluded = alreadyActivePrices.find((costItem) => costItem.id === item.id)
              if (item.mandatory && !extraAlreadyIncluded) {
                newFees.push({
                  id: item.id,
                  cost: item.cost,
                  frequency: item.frequency
                })
                await service.isChecked(item.id)
              }
            }
          }

          const basePrices = service?.getPrices()?.base_prices
          if (basePrices) {
            for (const item of basePrices) {
              const alreadyIncluded = alreadyActivePrices.find((costItem) => costItem.id === item.id)
              if (!alreadyIncluded) {
                if (item.mandatory && item.name !== 'Registration Fee') {
                  newFees.push({ id: item.id, cost: item.cost, frequency: item.frequency })
                  await service.isChecked(item.id)
                }
              }
            }
          }
          formConfigValues.fees = newFees

          const medical = service?.getArrayHealtInsurance()
          if (medical) {
            for (const item of medical) {
              if (item.mandatory) {
                formValues.health_insurance = item.id
                formConfigValues.insurance = true
                formConfigValues.healthInsuranceId = item.id
              }
            }
          }

          const courseCost = service?.courseCosts()
          const alreadyActiveCosts = formConfig.courseCosts ?? []
          if (courseCost) {
            for (const item of courseCost) {
              if (item.mandatory) {
                const alreadyIncluded = alreadyActiveCosts.find((costItem) => costItem.id === item.id)
                if (alreadyIncluded) {
                  // console.log('🚀 ~ file: FieldPayTuition.js:81 ~ loadInitialData ~ alreadyIncluded:', alreadyIncluded)
                } else {
                  courseCosts.push({ id: item.id, cost: item.cost ?? null, frequency: item.frequency, name: item.name })
                  await service.isCostChecked(item.id)
                }
              }
            }
          }

          const materials = service.getSchoolMaterial() ?? []
          const foundMandatory = materials?.find((material) => material.mandatory)

          formConfigValues.courseCosts = courseCosts

          if (foundMandatory) {
            formValues.materials = true
            formValues.material = foundMandatory?.id
            formConfigValues.materials = true
            formConfigValues.material = foundMandatory?.id
            formConfigValues.mandatoryMaterial = true
          }

          form.setFieldsValue(formValues)
          const nconfig = {
            ...formConfig,
            ...formConfigValues
          }
          setFormConfig(nconfig)
        } catch (error) {
          console.error('Error loading initial data:', error)
        }
      }

      if (!baseQuote && !valueQueryParam) {
        loadInitialData()
      } else if (valueQueryParam) {
        // console.log('🚀 ~ useEffect ~ valueQueryParam:', valueQueryParam)
        const decodedValues = JSON.parse(valueQueryParam)
        if (decodedValues) {
          const baseQuote = decodedValues
          form.setFieldsValue({
            intake_date: baseQuote.intake_date,
            intake: baseQuote.intake,
            startCourse: baseQuote.startCourse,
            startEvent: moment(baseQuote.startEvent),
            weeks: baseQuote.weeks,
            radioLodging: baseQuote.radioLodging,
            materials: baseQuote.materials,
            material: baseQuote.material,
            lodging: baseQuote.lodging,
            lodgingStart: moment(baseQuote.lodgingStart) ?? null,
            lodgingWeeks: baseQuote.lodgingWeeks,
            lodgingExtraNights: baseQuote.lodgingExtraNights,
            extra_nigth: !!baseQuote?.lodgingExtraNights,
            transport: baseQuote.transport,
            transport_way: baseQuote.transport_way,
            health_insurance: baseQuote.health_insurance
          })

          setFormConfig({
            ...formConfig,
            courseCosts: baseQuote.courseCosts,
            courseStart: moment(baseQuote.startEvent),
            weeks: baseQuote.weeks,
            intake_date: baseQuote.intake_date,
            intake: baseQuote.intake,
            startCourse: baseQuote.startCourse,
            materials: baseQuote.materials,
            material: baseQuote.material,
            lodging: baseQuote.radioLodging === 'si',
            lodgingId: baseQuote.lodging,
            lodgingStart: moment(baseQuote.lodgingStart) ?? null,
            lodgingWeeks: baseQuote.lodgingWeeks,
            lodgingExtraNights: baseQuote.lodgingExtraNights,
            extra_nigth: !!baseQuote?.lodgingExtraNights,
            transport: !!baseQuote?.transport,
            transportId: baseQuote.transport,
            transportWaySelected: baseQuote.transport_way,
            insurance: !!baseQuote?.health_insurance,
            healthInsuranceId: baseQuote.health_insurance,
            minorCosts: baseQuote.minorCosts,
            fees: baseQuote.fees,
            courseHSFees: baseQuote.courseHSFees,
            lodgingExtras: baseQuote.lodgingExtras,
            transportMinorCosts: baseQuote.transportMinorCosts,
            appliedGoods: baseQuote.appliedGoods
          })
          // if (baseQuote?.currency && baseQuote?.currency !== 'null' && baseQuote?.currency !== 'undefined') {
          //   localStorage.setItem('___BYP_KEY_CURRENCY___', baseQuote?.currency)
          // }
        }
      }
    }
  }, [course, valueQueryParam])

  return (
    <Form
      className={favorite ? 'errors-dont quoter-layout-content' : 'quoter-layout-content'}
      form={form}
      layout='horizontal'
      name='basic'
      onFinish={handleFinishForm}
      autoComplete='off'
      scrollToFirstError
      requiredMark={false}
      initialValues={{
        // weeks: service.getMinWeeks()
      }}
      onFinishFailed={
        () => {
          if (!favorite) {
            notification.info({
              message: 'Por favor de llenar los campos mandatorios',
              placement: 'top'
            })
          } else {
            setFaileForm(true)
          }
        }
      }
    >
      {contextHolder}
      {children}
    </Form>
  )
}

export { QuoterFormConfigured }
