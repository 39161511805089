import { Button, Table } from 'antd'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { EditIcon } from '../../../../common/theme/icons/EditIcon'
import { PackageTableFilter } from '../Tables/Filters/PackageTableFilter'
import { useEffect, useState } from 'react'
import LottieCargando from '../../../../lotties/LottieCargando'
import { useGetOfficesPackage } from '../../../../bussines/hooks/packages/useGetPackages'

const { Column } = Table

const TablePackages = ({ packages, setId, isLoading, isDeleting, deletePackage, setFilters, filters, paginationData, refetch }) => {
  const [offices, setOffices] = useState([])
  const [programs, setPrograms] = useState([])
  const { data } = useGetOfficesPackage()

  useEffect(() => {
    const dataArray = []
    data?.data?.forEach(item => {
      item.branches.forEach(branch => {
        dataArray.push(branch)
      })
    })
    setOffices(dataArray)

    const dataPrograms = []
    data?.data?.forEach(item => {
      dataPrograms.push(item.category)
    })

    const uniqueData = dataPrograms.filter((item, index, array) => {
      return index === array.findIndex(obj => obj.id === item.id)
    })

    setPrograms(uniqueData)
  }, [data])

  return (
    <>
      <PackageTableFilter searchFilter={filters} setSearchFilter={setFilters} offices={offices} programs={programs} />
      {isLoading && <LottieCargando />}
      {!isLoading && (
        <Table
          loading={isLoading}
          dataSource={packages ?? []}
          className='byp-table-dashboard'
          title={() => 'Added packages'}
          rowKey='id'
          style={{ paddingLeft: '12px' }}
          pagination={paginationData}
        >
          <Column
            title='Name' dataIndex='name' key='name'
            render={(_, record) => (
              <a>{record.label}</a>
            )}
          />
          <Column
            title='Program' dataIndex='program' key='program'
            render={(_, record) => (
              <a>{record.category?.label}</a>
            )}
          />
          <Column
            title='Office'
            dataIndex='offices'
            key='offices'
            responsive={['lg']}
            render={(_, record) => (
              <div style={{ maxHeight: '6rem', overflow: 'auto' }}>
                <ul>
                  {record.offices?.map((item) => (
                    <li key={item.id}>{item.name}</li>
                  ))}
                </ul>
              </div>
            )}
          />

          <Column
            title='Actions'
            key='action'
            render={(_, record) => (
              <>
                <ConfirmModal onOk={deletePackage} isLoading={isLoading || isDeleting} id={record.id} />
                <Button
                  className='byp-btn-edit'
                  loading={isLoading || isDeleting}
                  size='small'
                  onClick={
                    () => {
                      if (refetch) refetch()
                      setId(record.id)
                    }
                  }
                  icon={<EditIcon />}
                />
              </>
            )}
          />
        </Table>
      )}
    </>
  )
}

export { TablePackages }
