import React, { useEffect, useState } from 'react'
import { Diploma, IconAppleSchool, IconPosgrados, IconShortCourse, IconsMaestrias } from '../../../../common/assets/svg/icons'
import { LiaLanguageSolid } from 'react-icons/lia'
import { FaSchoolFlag } from 'react-icons/fa6'
import SchoolIcon from '@material-ui/icons/School'

const programs = [
  {
    label: 'Idiomas',
    icon: <LiaLanguageSolid />,
    key: 'language'
  },
  {
    label: 'Diplomas y Certificados',
    icon: <Diploma width='12px' height='12px' color='#d9d9d9' />,
    key: 'graduate_diploma'
  },
  {
    label: 'Años academicos',
    icon: <IconAppleSchool />,
    key: 'academic_years'
  },
  {
    label: 'Boardings',
    icon: <FaSchoolFlag />,
    key: 'boarding_schools'
  },
  {
    label: 'Lincenciatura',
    icon: <SchoolIcon style={{ width: '12px' }} />,
    key: 'bachelor'
  },
  {
    label: 'Posgrados',
    icon: <IconPosgrados />,
    key: 'postgraduate'
  },
  {
    label: 'Maestrías',
    icon: <IconsMaestrias />,
    key: 'master'
  },
  {
    label: 'Cursos Cortos',
    icon: <IconShortCourse />,
    key: 'short_term'
  }
]

function ButtonsProgram ({ setPrograms, sortPrograms }) {
  const [programList, setProgramList] = useState(programs)

  useEffect(() => {
    if (sortPrograms) {
      const sortedPrograms = programs.sort((a, b) => {
        const aIndex = sortPrograms.indexOf(a.key)
        const bIndex = sortPrograms.indexOf(b.key)

        if (aIndex === -1) return 1
        if (bIndex === -1) return -1

        return aIndex - bIndex
      })
      setProgramList(sortedPrograms)
    }
  }, [sortPrograms])

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e, dropIndex) => {
    e.preventDefault()
    const dragIndex = e.dataTransfer.getData('text/plain')
    const newProgramList = [...programList]
    const [draggedProgram] = newProgramList.splice(dragIndex, 1)
    newProgramList.splice(dropIndex, 0, draggedProgram)
    setProgramList(newProgramList)
    setPrograms(newProgramList.map(program => program.key))
  }
  return (
    <>
      {programList.map((item, index) => (
        <div
          key={index}
          className='button__program'
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e, index)}
        >
          {item.icon} {item.label}
        </div>
      ))}
    </>
  )
}

export default ButtonsProgram
