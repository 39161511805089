import { Form, Input, Modal } from 'antd'
import { useState } from 'react'
import { Map } from './Map'
import { Flecha } from '../assets/images/icons/Flecha'

const MapAddressFormItem = ({ name, onClick, ...props }) => {
  const [visible, setVisible] = useState(false)
  const { address, setCoords, addressChange, coords, label } = props

  const showModal = () => {
    setVisible(true)
  }

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const containerStyle = {
    width: '100%',
    height: '20rem',
    borderRadius: '0.5rem'
  }

  return (
    <>
      <Form.Item
        name={name}
        onClick={onClick}
        label={label}
      >
        <Input className='school_address' onClick={showModal} readOnly placeholder='Write the school address' addonAfter={<Flecha />} />
      </Form.Item>
      <Modal
        title=''
        visible={visible}
        onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        className='byp-map-modal'
        okText='Accept'
        cancelText='Cancelar'
        cancelButtonProps={{
          className: 'byp-bg-gray'
        }}
      >
        <Map address={address} coords={coords} setCoords={setCoords} addressChange={addressChange} containerStyle={containerStyle} />
      </Modal>
    </>
  )
}

export default MapAddressFormItem
