import api from '../../services/api'

class LodgingRepository {
  async findLodging (id) {
    if (id === undefined) {
      return null
    }
    const response = await api.get(`/fees/${id}`)
    return response.data
  }

  async getLodgings (filtersQuery) {
    const response = await api.get('/fees/lodging/list?' + filtersQuery)
    console.log('🚀 ~ file: LodgingRepository.js ~ line 14 ~ LodgingRepository ~ getLodgings ~ response', response)
    return response.data
  }

  async updateFee ({ id, data }) {
    if (id === undefined || data === undefined) {
      return
    }
    const response = await api.put(`/fees/${id}/update`, data)
    return response.data
  }

  async deleteFee ({ id }) {
    const response = await api.delete(`/fees/${id}/delete`)
    return response.data
  }

  async getFeesByCategoryAndSede (type, sede, category, year) {
    if (category === undefined || sede === undefined || year === undefined) {
      return
    }
    const response = await api.get(`/fees/${sede}/${category}/${type}/list?year=` + year)
    return response.data
  }

  async savePrices ({ type, data }) {
    const response = await api.post(`/fees/${type}/prices`, data)
    return response.data
  }
}

export default new LodgingRepository()
