import { useQuery } from 'react-query'
import OpinionRepository from '../../repositories/OpinionRepository'

function useGetOpinions (campus, course, filters = {}) {
  const params = { campus, course, ...filters }
  if (!course) delete params.course
  return useQuery(
    ['useGetOpinions', params],
    () => OpinionRepository.opinionsList(params),
    {
      retry: 1,
      enabled: !!campus
    }
  )
}

export { useGetOpinions }
