import { addDays, addWeeks, differenceInDays, format, parseISO } from 'date-fns'
import { calculateServiceCost, sumArrayCost } from '../bussines/logic/quoter/calculateServiceCost'
import { calculateHighSeasonCosts, getApplicableHighSeson, getHighSeasonCost, isHighSeason, isInHighSeason } from '../bussines/logic/quoter/isHighSeason'
import { getAllHighSeasonBetweenDates } from '../bussines/logic/quoter/getAllSeason'
import { CalculateLodgingCost, CalculateLodgingExtraNightCost } from '../bussines/logic/quoter/CalculateLodgingCost'
import { calculateTotalDiscount, getApplicableDiscounts, isApplicable } from '../bussines/logic/quoter/DiscountsLogic'
import { calculateCost, calculateCostByDays, calculateHsCost } from '../pages/quote-course/utils/calculateCost'
import { calculateAccomodationHGS } from '../bussines/logic/quoter/calculateAccommodationHighSeason'
import { isSameOrAfter } from '../utils/isSameOrAfter'
import { isSameOrBefore } from '../utils/isSameOrBefore'
import { notification } from 'antd'
import { formatStringDate } from '../utils/formatStringDate'
import moment from 'moment'

const dayNumber = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7
}

class QuoteCalculatorService {
  constructor(courseItem, config, setFormConfig, user, exchangeValues, prices, setPrices) {
    this.course = courseItem
    this.config = config
    this.setFormConfig = setFormConfig
    this.user = user
    this.exchanges = exchangeValues
    this.prices = prices
    this.setPrices = setPrices
  }

  searchPrices(date) {
    let nDate = new Date(date)
    nDate = nDate.toISOString()
    nDate = new Date(nDate)
    const allPrices = this.course?.offer?.prices ?? []
    let isBetweenT3LastDays = false
    const found = allPrices.find((prices) => {
      let startDate = new Date(parseISO(prices.year?.prices_start ?? null))
      startDate = startDate.toISOString()
      startDate = new Date(startDate)
      const endDate = new Date(prices.year?.prices_end ?? null)
      if (isSameOrAfter(nDate, startDate) && isSameOrBefore(nDate, endDate)) {
        const diffInDays = differenceInDays(endDate, nDate)
        isBetweenT3LastDays = diffInDays >= 3
        return true
      }
      return false
    })

    if (isBetweenT3LastDays) {
      const foundAnother = allPrices.find((prices) => {
        const foundYear = Number(found?.year?.year)
        const thisYear = Number(prices?.year?.year)
        if (foundYear >= thisYear) return false
        let startDate = new Date(parseISO(prices.year?.prices_start ?? null))
        startDate = startDate.toISOString()
        startDate = new Date(startDate)
        const endDate = new Date(prices.year?.prices_end ?? null)
        if (isSameOrAfter(nDate, startDate) && isSameOrBefore(nDate, endDate)) {
          return true
        }
        return false
      })
      if (foundAnother) {
        if (foundAnother?.year?.year !== this.getPrices()?.year?.year) {
          this.setFormConfig({
            ...this.config,
            courseHSFees: [],
            appliedGoods: [],
            transportMinorCosts: [],
            lodgingExtras: [],
            PeakSeasonFees: [],
            fees: []
          })
        }
        return this.setPrices(foundAnother)
      }
    }

    if (found) {
      if (found?.year?.year !== this.getPrices()?.year?.year) {
        this.setFormConfig({
          ...this.config,
          courseHSFees: [],
          appliedGoods: [],
          transportMinorCosts: [],
          lodgingExtras: [],
          PeakSeasonFees: [],
          fees: []
        })
      }
      this.setPrices(found)
    } else {
      notification.info({
        message: 'Prices for this date not found'
      })
    }
  }

  getPrices () {
    return this.prices ?? this.course?.offer?.prices[0]
  }

  getPricesYear () {
    return this.getPrices()?.year
  }

  getCourseProgram () {
    return this.course.offer.program
  }

  getTotalWeeks () {
    return this.config.weeks ?? 0
  }

  getLodgingWeeks () {
    return this.config.lodgingWeeks ?? 0
  }

  getBasesPrices () {
    return this.getPrices()?.base_prices
  }

  getSubTotalQuoteAmount () {
    let cost = 0
    cost += this.subTotalCourse() + this.subTotalLodging() + this.subTotalAgencyCost()
    cost += (
      this.subTotalCourseExtra() +
      this.getAmountHealthInsurance() +
      this.getSubtotalTrasnport()
    )
    if (this.getCurrency() === this.getAgencyCurrency()) {
      cost += this.calculateAgencyMiscellaneousTotal()
    }

    // Add Transpot minor costs
    this.config.transportMinorCosts?.forEach((item) => {
      const addCost = this.config.transportWaySelected === 'One Way' ? item.cost : (item.cost * 2)
      cost += addCost
    })
    if (isNaN(cost)) {
      return 0
    }
    return cost ?? 0
  }

  getTotalQuoteAmount () {
    return (
      this.getSubTotalQuoteAmount() -
      this.getTotalDiscounts()
    ) ?? this.getSubTotalQuoteAmount()
  }

  getSubTotalCampusAmount () {
    let cost = 0
    cost += this.subTotalCourse() + this.subTotalLodging() + this.subTotalAgencyCost()
    cost += this.subTotalCourseExtra() + this.getAmountHealthInsurance() + this.getSubtotalTrasnport()
    this.transportMinorCostValues()?.forEach((item) => {
      cost += item.cost
    })
    if (isNaN(cost)) {
      return 0
    }
    return cost ?? 0
  }

  getCampusDiscountAmount () {
    const totalDiscounts = (
      this.getCourseDiscountAmount() +
      this.getMaterialDiscountsAmount() +
      this.getTuitionFeeDiscountsAmount() +
      this.getLodgingDiscountsAmount() +
      this.getTransportDiscountsAmount()
      // this.getExtraDiscountsAmount(),
      // this.getFeesDiscountAmount()
    ) ?? 0

    console.log('totalDiscounts', totalDiscounts)
    return totalDiscounts
  }

  getTotalCampusAmount () {
    const cost = this.getSubTotalCampusAmount() - this.getCampusDiscountAmount()
    if (isNaN(cost)) {
      return this.getSubTotalCampusAmount()
    }
    return cost ?? 0
  }

  getTotalsAmount () {
    const costs = {
      campus: this.getTotalCampusAmount(),
      campus_subtotal: this.getSubTotalCampusAmount(),
      campus_currency: this.getCurrency(),

      agency: this.calculateAgencyMiscellaneousTotal(),
      agency_subtotal: this.calculateAgencyMiscellaneousTotal(),
      agency_currency: this.getAgencyCurrency(),

      medical: this.getAmountMedical(),
      medical_subtotal: this.getAmountMedical(),
      medical_currency: this.getAgencyMedicalCurrency()
    }
    if (this.getAgencyCurrency() === this.getCurrency()) {
      costs.total = costs.campus + costs.agency
    } else {
      // When converting to campus currency is a divition operation
      const agencyCosts = (costs.agency / this.getExchangeValue(costs.campus_currency)) ?? 0
      costs.total_in_campus_currency = isNaN(agencyCosts) ? costs.campus : (costs.campus + agencyCosts)
      costs.subtotaltotal_in_campus_currency = isNaN(agencyCosts) ? costs.campus_subtotal : (costs.campus_subtotal + agencyCosts)
      // When converting to agency currency is a product operation
      if (this.getAgencyCurrency()) {
        const campusCosts = (costs.campus * this.getExchangeValue(costs.campus_currency)) ?? 0
        costs.total_in_agency_currency = campusCosts + costs.agency
      }
    }
    return costs
  }

  getCurrency () {
    return this.course.offer?.campus?.divisa?.code
  }

  getCurrencyId () {
    return this.getPrices()?.divisa?.id
  }

  getTotalWeeksAmount () {
    return (this.getTotalWeeks() * this.getCourseAmountPerWeek()) ?? 0
  }

  getAmountInscription () {
    const found = this.getTuitionFee()
    return found?.cost ?? 0
  }

  getStudentNationality () {
    return {
      country: this.config?.student_nationality,
      subregion: this.config?.student_subregion,
      region: this.config?.student_region
    }
  }

  /**
   * ------------------------------------ Course Funtions ------------------------------------
   */
  courseCosts () {
    return this.getPrices()?.costs || []
  }

  getMinWeeks () {
    const standards = this.getPrices()?.costs?.filter((item) => {
      return item.type === 'standard'
    })
    let min = 0
    standards?.map((standard) => {
      if (!min) min = standard.min
      if (standard.min < min) min = standard.min
      return min
    })
    return min
  }

  getMaxWeeks () {
    const standards = this.getPrices()?.costs?.filter((item) => {
      return item.type === 'standard'
    })
    let max = 0
    standards?.map((standard) => {
      if (standard.max > max) max = standard.max
      return max
    })
    return max
  }

  getNameCourse () {
    return this.course?.offer?.label ?? this.course?.offer?.name
  }

  getDayStartCourse () {
    if (!this.course.offer?.start_day) return null
    return dayNumber[this.course.offer?.start_day]
  }

  getCourseAmountPerWeek () {
    const standards = this.getPrices()?.costs?.filter((item) => {
      return item.type === 'standard'
    })

    const cost = standards?.find((costItem) => {
      if (this.getTotalWeeks() >= costItem.min && this.getTotalWeeks() <= costItem.max) {
        return costItem
      }
      return false
    })

    return cost?.cost
  }

  getCourseAmount () {
    const weeks = this.getTotalWeeks() ?? 0
    const costWeekly = this.getCourseAmountPerWeek() ?? 0
    const cost = costWeekly * weeks
    return cost ?? 0
  }

  isMinorCostChecked(id) {
    const found = this.config?.minorCosts?.find((el) => el.id === id)
    return found
  }

  minorCostValues () {
    const values = []
    this.getPrices()?.minor_costs?.forEach((item) => {
      if (this.isMinorCostChecked(item.id)) {
        values.push({
          id: item.id,
          cost: this.calculateCost(item.cost, item.frequency),
          duration: this.getDurationLabel (item.frequency)
        })
      }
    })
    return values
  }

  // Make the sum of minor costs and its discounts
  getMinorCostsAmount () {
    let total = 0
    this.getPrices()?.minor_costs?.forEach((item) => {
      if (this.isMinorCostChecked(item.id)) {
        total += this.calculateCost(item.cost, item.frequency)
      }
    })
    return total
  }

  /**
   * ------------------------------------ Lodgings Funtions ------------------------------------
   */
  hasLodging () {
    // return !!this.getPrices()?.lodgings
    const lodgings = this.getPrices()?.lodgings || []
    return lodgings.length > 0
  }

  includeLodging () {
    return this.config.lodging
  }

  includeMedical () {
    return this.config.medical
  }

  includeService () {
    return this.config.serviceAgency
  }

  includeExtraNight () {
    const lodgingId = this.config.lodgingId
    const hasExtraNightCost = this.getPrices()?.lodgings.find(lodging => lodging.id === lodgingId)
    return this.config.lodgingId && Number(hasExtraNightCost?.extra_cost) > 0
  }

  getArrayLodging () {
    return this.getPrices()?.lodgings ?? []
  }

  getMedicalInsurance () {
    return this.getPrices()?.medical_insurance ?? []
  }

  getServiceAgency () {
    return this.getPrices()?.service_agency ?? []
  }

  getSelectedLodging () {
    if (!this.config?.lodging) {
      return 0
    }
    const lodgingCollection = this.getArrayLodging()
    const foundCurrentLodging = lodgingCollection.find((lo) => {
      return lo.id === this.config?.lodgingId
    })

    return foundCurrentLodging
  }

  getSelectedMedical () {
    if (!this.config?.medical) {
      return 0
    }
    const medicalCollection = this.getMedicalInsurance()
    const foundCurrentMedical = medicalCollection.find((lo) => {
      return lo.id === this.config?.medicalId
    })

    return foundCurrentMedical
  }

  getSelectedServices () {
    const additionalsServices = this.getArrayOfServices(this.config?.serviceId)
    const divisasAdditional = {}

    additionalsServices.forEach(cost => {
      if (divisasAdditional[cost.currency] === undefined) divisasAdditional[cost.currency] = []
      divisasAdditional[cost.currency].push(cost)
    })

    return divisasAdditional
  }

  getAmountAdditionalService () {
    if (!this.config?.serviceAgency) {
      return 0
    }
    let amountAdditionalService = 0
    let currency = ''

    this.config?.serviceId?.forEach(service => {
      amountAdditionalService += this.getAmountService(service.service_agency)
      currency = this.getServiceAgencyCurrency(service.service_agency)
    })

    return {
      total: amountAdditionalService,
      currency
    }
  }

  getAmountLodging () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) {
      return 0
    }

    return CalculateLodgingCost(
      foundCurrentLodging,
      this.config.lodgingStart,
      this.getLodgingWeeks(),
      this.getHighSeasons()
    )
  }

  getAmountsLodging () {
    const foundCurrentLodging = this.getSelectedLodging()
    const values = this.getLodgingHighSeasonValues()
    let weeks = 0
    let hsCost = 0
    let daysOff = 0
    let hsTotal = 0
    values.details?.forEach((hs) => {
      weeks += hs.weeks
      // daysOff += hs.daysOff
      daysOff += 0
      hsCost = hs.costs.standard_cost
      hsTotal += (hs.weeks * hs.costs.standard_cost)
      hsTotal += (hs.daysOff * (hs.costs.standard_cost / 7))
    })
    const lowSeasonWeeks = this.getLodgingWeeks() - weeks
    return {
      ls_cost: lowSeasonWeeks * foundCurrentLodging?.cost,
      ls_weeks: lowSeasonWeeks,
      hs_cost: (weeks * hsCost) + (daysOff * (hsCost / 7)),
      hs_total: hsTotal,
      hs_costExt: daysOff * (hsCost / 7),
      hs_weeks: weeks
    }
  }

  // Return the lodging amout + High Season Costs?
  getHighSeasonAmountLodging () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) return 0
    const values = this.getLodgingHighSeasonValues()
    let weeks = 0
    let hsCost = 0
    // let daysOff = 0
    // let hsTotal = 0
    values.details?.forEach((hs) => {
      weeks += hs.weeks
      hsCost = hs.costs.standard_cost
      // daysOff += hs.daysOff
      // hsTotal += (hs.weeks * hs.costs.standard_cost)
      // hsTotal += (hs.daysOff * (hs.costs.standard_cost / 7))
    })
    const lowSeasonWeeks = this.getLodgingWeeks() - weeks
    return (lowSeasonWeeks * foundCurrentLodging.cost) + (weeks * hsCost)
    // return (lowSeasonWeeks * foundCurrentLodging.cost) + (weeks * hsCost) + (daysOff * (hsCost / 7))
    // return (lowSeasonWeeks * foundCurrentLodging.cost) + hsTotal
  }

  getAmountLodgingExtNight () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging || !this.config.lodgingExtraNights || !this.config.extra_nigth || !this.getLodgingEnd()) {
      return 0
    }
    return CalculateLodgingExtraNightCost(
      foundCurrentLodging,
      this.getLodgingEnd(),
      this.config.lodgingExtraNights,
      this.getHighSeasons()
    )
  }

  getLodgingAcommodationFee () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) {
      return 0
    }
    return foundCurrentLodging.accomodation_fee ?? 0
  }

  getAcommodationFeeDiscounts () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) return []
    const discounts = foundCurrentLodging.accomodation_fee_discounts ?? []
    return getApplicableDiscounts(discounts, this.config?.courseStart)
  }

  getAcommodationFeeDiscountsAmount () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) return []
    const discounts = this.getAcommodationFeeDiscounts()
    return calculateTotalDiscount(discounts, foundCurrentLodging.accomodation_fee ?? 0)
  }

  getAcommodationFeeDiscountsValues () {
    const cost = this.getSelectedLodging()?.accomodation_fee ?? 0
    const values = this.getAcommodationFeeDiscounts().map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], cost)?.toFixed(2)
      })
    )
    return values
  }

  AcommodationFeeTotal () {
    const cost = this.getLodgingAcommodationFee()
    if (!cost) return 0
    return cost - this.getAcommodationFeeDiscountsAmount()
  }

  getDayStartLodging () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging?.arrival_day) return null
    return dayNumber[foundCurrentLodging?.arrival_day]
  }

  getDayEndLodging () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging?.departure_day) return null
    return dayNumber[foundCurrentLodging?.departure_day]
  }

  getLodgingExtraCosts () {
    const lodging = this.getSelectedLodging()
    if (!lodging) return []
    return lodging.extra_costs || []
  }

  getLodgingExtraCostsDiscounts (id) {
    const lodging = this.getSelectedLodging()
    if (!lodging) return []
    const selectedExtras = this.config.lodgingExtras || []
    const found = selectedExtras.find((extra) => extra.id === id)
    if (!found) return []
    // const Accfee = lodging.extra_costs?.find((extra) => extra.name === 'Accommodation Fee')
    // if (Accfee?.id === id) {
    //   return this.getLodgingDiscounts()
    // }
    return []
  }

  calculateLodgingExtraSubtotal (id) {
    const selectedExtras = this.config.lodgingExtras || []
    const found = selectedExtras.find((extra) => extra.id === id)
    if (!found) return 0
    const cost = this.calculateLodgingExtraCosts (id)
    const discounts = this.getLodgingExtraCostsDiscounts (id)
    const discountAmout = calculateTotalDiscount(discounts, cost)
    return (cost - discountAmout)
  }

  getLodgingExtraSubtotal (id) {
    const selectedExtras = this.config.lodgingExtras || []
    const found = selectedExtras.find((extra) => extra.id === id)
    if (!found) return 0
    const cost = this.calculateLodgingExtraCosts (id)
    const discounts = this.getLodgingExtraCostsDiscounts (id)
    const discountAmout = calculateTotalDiscount(discounts, cost)
    return discountAmout
  }

  getLodgingSelectedExtraCosts () {
    return this.config.lodgingExtras || []
  }

  calculateLodgingExtraCosts (id) {
    const selectedExtras = this.config.lodgingExtras || []
    const extras = this.getLodgingExtraCosts()
    if (selectedExtras.length < 1 || extras.length < 1) return 0
    const found = selectedExtras.find((extra) => extra.id === id)
    if (!found) return 0
    return this.calculateCostWithLodging(found.cost, found.frequency)
  }

  getLodgingExtraCostsAmount(id) {
    let total = 0
    const selectedExtras = this.config.lodgingExtras || []
    selectedExtras.forEach(extra => {
      const cost = this.calculateCostWithLodging(extra.cost, extra.frequency)
      extra.total = cost
      total += cost
    })
    return total
  }

  isLodgingExtraSelected(id) {
    const found = this.config.lodgingExtras?.find((item) => (item.id === id))
    return !!found
  }

  isLodgingPSFeeSelected(id) {
    const found = this.config.PeakSeasonFees?.find((item) => (item.id === id))
    return !!found
  }

  LodgingPSFeesSelected () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) return []
    const highSesons = this.getLodgingHighSeasonValues()?.details
    const values = this.config.PeakSeasonFees?.map((fee) => {
      const hs = highSesons?.find((item) => item.id === fee.hsId)
      return {
        id: fee.id,
        cost: calculateHsCost(
          fee.cost,
          fee.frequency,
          hs?.weeks ?? 0,
          hs?.daysOff ?? 0
        )
      }
    })
    return values
  }

  /**
   * ------------------------------------ Transports Functions ------------------------------------
   */
  hasTransports () {
    // return !!this.getPrices()?.transports
    const transports = this.getPrices()?.transports || []
    return transports.length > 0
  }

  includeTransport () {
    return this.config?.transport
  }

  getArrayTransport () {
    return this.getPrices()?.transports ?? []
  }

  getTransport () {
    const transportCollection = this.getArrayTransport()
    const foundTransport = transportCollection.find((t) => {
      return t.id === this.config?.transportId
    })
    return foundTransport
  }

  getTransportMinorCosts () {
    const transport = this.getTransport()
    if (!transport) return []
    return transport.minor_costs
  }

  getTransportTotal () {
    let total = 0
    total += this.getSubtotalTrasnport()
    this.transportMinorCostValues()?.forEach((item) => {
      if (this.isTransportMinorCostChecked(item.id)) {
        total += item.cost
      }
    })
    return total
  }

  isTransportMinorCostChecked(id) {
    const found = this.config?.transportMinorCosts?.find((el) => el.id === id)
    return found
  }

  transportMinorCostValues () {
    const values = []
    this.getTransportMinorCosts()?.forEach((item) => {
      if (this.isTransportMinorCostChecked(item.id)) {
        values.push({
          id: item.id,
          cost: this.config.transportWaySelected === 'One Way' ? item.cost : (item.cost * 2),
          duration: this.getDurationLabel (item.frequency)
        })
      }
    })
    return values
  }

  getAmountTransport () {
    const ways = this.config.transportWaySelected
    const foundTransport = this.getTransport()

    if (!foundTransport || !ways || !this.includeTransport()) {
      return 0
    }

    let cost = 0
    ways === 'One Way' ? cost = foundTransport.one_way_cost : cost = foundTransport.two_ways_cost

    return cost
  }

  getWaysTransport () {
    const transport = this.getTransport()
    const ways = []
    const oneWayCost = transport?.one_way_cost ?? null
    const twoWaysCost = transport?.two_ways_cost ?? null
    if (oneWayCost >= 0) ways.push('One Way')
    if (twoWaysCost >= 0) ways.push('Round Way')
    return ways
  }

  /**
   * Insurance
   */
  includeInsurance () {
    return this.config?.insurance
  }

  /**
   * ------------------------------------ High Seasons ------------------------------------
   */

  getHighSeasons () {
    return this.getPrices()?.high_seasons || []
  }

  getLodgingHighSeasons () { // here
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) {
      return []
    }

    const results = getApplicableHighSeson(
      foundCurrentLodging.high_seasons ?? [],
      this.config?.lodgingStart ?? null,
      this.getLodgingWeeks()
    )

    const hs = results.map((result) => {
      const found = foundCurrentLodging.high_seasons.find((item) => item.id === result.id)
      return found
    })
    return hs
  }

  getTransportHighSeasons () {
    const currentTransport = this.getTransport()
    if (!currentTransport) {
      return 0
    }
    return currentTransport.high_seasons ?? []
  }

  getIsHighSeason () {
    return isHighSeason(
      this.getTotalWeeks(),
      this.getCourseStart(),
      this.getHighSeasons()
    )
  }

  getHighSeasonFees () {
    if (!this.getCourseStart() || !this.getHighSeasons()) return []
    const highSeasons = this.getHighSeasons() || []
    const results = getApplicableHighSeson(highSeasons, this.getCourseStart(), this.getTotalWeeks()) ?? []
    return results
  }

  isHighSeasonFeeChecked (id) {
    const found = this.config.courseHSFees?.find((item) => item.id === id)
    return !!found
  }

  getHighSeasonFeesCosts () {
    const hsData = this.getHighSeasonFees()
    const psFees = hsData.map((hs) => {
      const fees = []
      hs.fees?.forEach((fee) => {
        if (this.isHighSeasonFeeChecked(fee.id)) {
          fees.push({
            id: fee.id,
            cost: calculateHsCost(fee.cost, fee.frequency, hs.weeks, hs.daysOff),
            duration: this.getDurationLabel(fee.frequency)
          })
        }
      })
      return {
        hs_id: hs.id,
        hs_fees: fees
      }
    })
    psFees.map((hs) => {
      let total = 0
      hs.hs_fees.forEach(fee => {
        total += fee.cost
      })
      hs.total = total
      return true
    })
    return psFees
  }

  getHighSeasonFeesAmount () {
    const data = this.getHighSeasonFeesCosts()
    let total = 0
    data.forEach(hs => {
      total += hs.total ?? 0
    })
    return total
  }

  getLodgingIsHighSeason () {
    return isHighSeason(
      this.getLodgingWeeks(),
      this.config?.lodgingStart ?? null,
      this.getLodgingHighSeasons() ?? []
    )
  }

  // Check if the extra nights are in high season
  getLodginExgIsHighSeason () {
    const startExtraNights = this.getLodgingEnd()
    const endExtraNights = addDays(startExtraNights, this.config.lodgingExtraNights)
    const values = calculateHighSeasonCosts(startExtraNights, endExtraNights, this.getLodgingHighSeasons() ?? [], 'lodging')
    return values ?? []
  }

  getLodgingExAmounts () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging || !this.config.extra_nigth) {
      return {
        ls_cost: 0, hs_cost: 0, ls_days: 0, hs_days: 0
      }
    }
    const values = this.getLodginExgIsHighSeason()
    let days = 0
    let hsCost = 0
    values.details?.forEach((hs) => {
      days += hs.days
      hsCost = hs.costs.extra_cost
    })
    const lowSeasonDays = this.config.lodgingExtraNights - days
    return {
      ls_cost: (lowSeasonDays * foundCurrentLodging.extra_cost),
      hs_cost: (days * hsCost),
      ls_days: lowSeasonDays,
      hs_days: days
    }
  }

  getLodgingExHighSeasonAmount () {
    const values = this.getLodgingExAmounts()
    return values.ls_cost + values.hs_cost
  }

  getTransportIsHighSeason () {
    let start = new Date(this.config?.courseStart)
    let end = this.getEndCourse()
    if (this.config.includeLodging) {
      end = this.getLodgingEnd()
      start = new Date(this.config?.lodgingStart)
    }
    const resp = {
      end: isInHighSeason(end, this.getTransportHighSeasons()),
      end_cost: getHighSeasonCost(end, this.getTransportHighSeasons()),
      start: isInHighSeason(start, this.getTransportHighSeasons()),
      start_cost: getHighSeasonCost(start, this.getTransportHighSeasons())
    }
    return resp
  }

  getLodgingAmountHighSeason () {
    const isTrue = this.getLodgingIsHighSeason()
    if (!isTrue) {
      return 0
    }

    const result = calculateAccomodationHGS(
      this.config?.lodgingStart ?? null,
      this.getLodgingEnd(),
      this.getLodgingHighSeasons(),
      this.getDayEndLodging()
    )
    const total = result?.standard_cost
    return total
  }

  getLodgingHighSeasonValues () {
    const isTrue = this.getLodgingIsHighSeason()
    if (!isTrue) {
      return []
    }

    const result = calculateAccomodationHGS(
      this.config?.lodgingStart ?? null,
      this.getLodgingEnd(),
      this.getLodgingHighSeasons(),
      this.getDayEndLodging()
    )
    return result
  }

  getLodgingExtraAmountHighSeason () {
    const isTrue = this.getLodgingIsHighSeason()
    const lodgingExtraStart = this.getLodgingEnd()
    if (!isTrue) {
      return 0
    }
    if (!lodgingExtraStart || !this.config?.lodgingExtraNights) {
      return 0
    }
    const start = lodgingExtraStart
    const duration = this.config.lodgingExtraNights
    const end = addDays(new Date(start), duration)
    const result = calculateHighSeasonCosts(
      lodgingExtraStart ?? null,
      end,
      this.getLodgingHighSeasons(),
      'lodging'
    )
    let t = 0
    const hsList = result?.details ?? []
    hsList.forEach(hs => {
      t += hs.costs.extra_cost * hs.days
    })
    return t
  }

  getTransportAmountHighSeason () {
    const isHighSeason = this.getTransportIsHighSeason()
    const waySelected = this.config.transportWaySelected
    if (isHighSeason.end && isHighSeason.start && waySelected) {
      if (waySelected === 'One Way') {
        return isHighSeason.start_cost
      }
      if (waySelected === 'Round Way') {
        return (isHighSeason.end_cost + isHighSeason.start_cost)
      }
    }
    if (isHighSeason.start && waySelected) {
      return isHighSeason.start_cost
    }
    if (isHighSeason.end && waySelected) {
      return isHighSeason.end_cost
    }

    return 0
  }

  getAgencyServiceFee () {
    return this.course?.agency_services?.activities ?? []
  }

  getAgencyMiscellaneous () {
    return this.course?.agency_services ?? []
  }

  getCourseStart () {
    return this.config?.courseStart ?? null
  }

  getCourseIntake () {
    return this.course.offer?.intake_date ?? ''
  }

  // For getting The high seasons that aplies in te course time
  getHighSeason () {
    if (!this.getTotalWeeks()) {
      return []
    }

    if (!this.getCourseStart()) {
      return []
    }

    return getAllHighSeasonBetweenDates(
      this.getTotalWeeks(),
      this.getCourseStart(),
      this.getHighSeasons()
    )
  }

  subTotalLodging () {
    let lodgingCost = this.getAmountLodging()
    if (this.getLodgingHighSeasonValues().weeks > 0) {
      lodgingCost = this.getHighSeasonAmountLodging()
      this.LodgingPSFeesSelected()?.forEach((item) => {
        lodgingCost += item.cost
      })
    }
    // console.log(
    //   'values',
    //   '\n ', this.getAmountLodgingExtNight(),
    //   '\n ', lodgingCost,
    //   '\n ', this.getLodgingExtraCostsAmount(),
    //   '\n ', this.AcommodationFeeTotal()
    // )
    return (
      this.getAmountLodgingExtNight() +
      lodgingCost +
      // this.getLodgingExtraAmountHighSeason() +
      this.getLodgingExtraCostsAmount() +
      // this.getLodgingAcommodationFee()
      this.AcommodationFeeTotal()
    ) ?? 0
  }

  subTotalAgencyCost () {
    return (this.getAmountActivities() ?? 0) +
      (this.getAmountServiceQuote() ?? 0) +
      (this.getAmountVisa() ?? 0)
  }

  subTotalCourse () {
    const st = (
      this.getTotalWeeksAmount() +
      this.getAmountInscription() +
      this.getHighSeasonFeesAmount() +
      this.getMinorCostsAmount() +
      // this.getAmountMaterials() - this.getMaterialDiscountsAmount()
      this.getAmountMaterials()
    ) ?? 0

    if (isNaN(st)) return 0

    return st ?? 0
  }

  subTotalCourseWithDiscount () {
    return (
      this.subTotalCourse() -
      (this.getTuitionFeeDiscountsAmount() + this.getCourseDiscountAmount())
    ) ?? 0
  }

  subTotalCourseExtra () {
    let feesCost = 0
    if (this.config?.fees) {
      this.config?.fees.map((fee) => (
        feesCost = feesCost + this.getFeeSubtotal(fee.id)
      ))
    }
    return feesCost ?? 0
  }

  // getAgencyVisa  () {
  //   return this.course?.agency_services?.agency_miscellaneous?.find((el) => {
  //     if (el.name === 'visa') {
  //       return el
  //     }
  //     return null
  //   })
  // }

  getAgencyQuote () {
    return this.course?.agency_services?.agency_miscellaneous?.find((el) => {
      if (el.name === 'service-quote') {
        return el
      }
      return null
    })
  }

  getAmountVisa () {
    if (!this.config?.visa) {
      return 0
    }

    const costServiceQuote =
      this.course?.agency_services?.agency_miscellaneous?.map((el) => {
        if (el.name === 'visa') {
          return calculateServiceCost(
            el.costs ?? [],
            this.getTotalWeeks(),
            this.getCourseStart(),
            this.getHighSeason()
          )?.total
        }
        return 0
      })

    return sumArrayCost(costServiceQuote)
  }

  getAmountActivities () {
    const foundCostSeason = this.course?.agency_services?.activities?.map((el) => {
      if (this.config?.activitiesId?.find((activityId) => activityId === el.id)) {
        return calculateServiceCost(
          el.costs ?? [],
          this.getTotalWeeks(),
          this.getCourseStart(),
          this.getHighSeason()
        )?.total
      }
      return 0
    })
    return sumArrayCost(foundCostSeason)
  }

  getAmountServiceQuote () {
    if (!this.config['service-quote']) {
      return 0
    }

    const costServiceQuote =
      this.course?.agency_services?.agency_miscellaneous?.map((el) => {
        if (el.name === 'service-quote') {
          return calculateServiceCost(
            el.costs ?? [],
            this.getTotalWeeks(),
            this.getCourseStart(),
            this.getHighSeason()
          )?.total
        }
        return 0
      })

    return sumArrayCost(costServiceQuote)
  }

  getSchoolMaterial () {
    if (this.getPrices()?.materials?.length >= 1) {
      return this.getPrices()?.materials
    }
    return null
  }

  getSelectedMaterial () {
    if (!this.config?.materials) return null
    const found = this.getPrices()?.materials?.find((el) => el.id === this.config?.material)
    return found
  }

  getAmountMaterials () {
    if (!this.config?.materials) return 0
    const found = this.getPrices()?.materials?.find((el) => el.id === this.config?.material)
    let cost = 0
    if (found) {
      if (found.min && found.max) {
        if (this.getTotalWeeks() <= found.max) {
          cost = this.calculateCost(found.cost, found.frequency)
        } else {
          cost = calculateCost(found.cost, found.frequency, found.max)
          const subt = cost
          const extraWeeks = this.getTotalWeeks() - found.max
          const extraCost = extraWeeks * Number(found.extra_cost)
          cost = subt + extraCost
          if (found.cost_max && cost > found.cost_max) {
            return Number(found.cost_max)
          }
        }
      } else {
        cost = this.calculateCost(found?.cost, found.frequency)
      }
    }
    return cost
  }

  getAmountMedical () {
    if (!this.config?.medical) return 0
    const found = this.getPrices()?.medical_insurance?.find((el) => el.id === this.config?.medicalId)
    let cost = 0
    let days = 0
    const start = this.config.medical_start
    const end = this.config.medical_end
    if (start && end) {
      days = differenceInDays(end, start)
    }
    found?.costs?.forEach(medicalCost => {
      cost += calculateCostByDays(medicalCost.cost, medicalCost.frequency, days)
    })

    return cost
  }

  getAmountService(id) {
    if (!this.config?.serviceAgency) return 0
    const found = this.getPrices()?.service_agency?.find((el) => el.id === id)
    let cost = 0

    found?.costs?.forEach(serviceCost => {
      cost += this.calculateCost(serviceCost.cost, serviceCost.frequency)
    })

    return cost
  }

  getTotalMaterials () {
    return this.getAmountMaterials() - this.getMaterialDiscountsAmount()
  }

  /**
   * Dates
   */
  getStartString () {
    if (!this.config?.courseStart) return null
    return formatStringDate(this.config?.courseStart, 'dd LLLL Y')
  }

  getStartCourseFormated () {
    const date = new Date(this.config?.courseStart)
    return format(date, 'dd-LL-Y')
  }

  getEndString () {
    const endDate = this.getEndCourse()
    if (!endDate) return null
    return format(endDate, 'd LLLL Y')
  }

  getEndCourse () {
    if (!this.config?.courseStart || !this.config?.weeks) {
      return null
    }

    const date = new Date(this.config?.courseStart)

    const day = date.getUTCDay() ?? 1
    let daysToFriday = 5 - day
    if (daysToFriday < 0) {
      daysToFriday = daysToFriday + 7
    }
    const endOfFirstWeek = addDays(new Date(this.config?.courseStart), daysToFriday)

    return addWeeks(endOfFirstWeek, (this.config?.weeks - 1))
  }

  getEndCourseFormated () {
    const endDate = this.getEndCourse()
    return format(endDate, 'dd-LL-Y')
  }

  getLodgingEndString () {
    if (!this.getLodgingEnd()) return null
    return format(this.getLodgingEnd(), 'dd LLLL Y')
  }

  getLodgingEnd () {
    if (!this.config?.lodgingStart || !this.config?.lodgingWeeks) {
      return null
    }

    const startMoment = moment(this.config.lodgingStart)
    const endOfFirstWeek = startMoment.clone().endOf('week')
    const totalWeeks = this.config.lodgingWeeks - 1

    const lodgingEnd = endOfFirstWeek.clone().add(totalWeeks, 'weeks')

    if (lodgingEnd.day() !== 6) {
      lodgingEnd.endOf('week')
    }

    return lodgingEnd.toDate()
  }

  getLodgingExEnd () {
    if (!this.config?.lodgingExtraNights) {
      return null
    }
    const start = this.getLodgingEnd()
    const duration = this.config.lodgingExtraNights
    return addDays(new Date(start), duration)
  }

  getLodgingExEndFormated () {
    const end = this.getLodgingExEnd()
    if (!end) {
      return null
    }
    return format(end, 'dd-LL-Y')
  }

  getLodgingExEndString () {
    const end = this.getLodgingExEnd()
    if (!end) {
      return null
    }
    return format(end, 'dd LLLL Y')
  }

  isHoliday(date) {
    let isHoliday = false
    this.getPrices()?.holidays?.map((holiday) => {
      if (holiday.date === date) {
        isHoliday = true
      }
      return false
    })
    return isHoliday
  }

  enableNextDay(date) {
    const nextDay = addDays(new Date(date), 2)
    const datString = format(nextDay, 'Y-LL-dd')
    // TO DO: Check if the new date is also a holiday
    // if (this.isHoliday(datString)) {
    //   return this.enableNextDay(datString)
    // }
    return datString
  }

  isEnabledDate(date) {
    let isEnabled = false
    this.config?.enabledDates?.map((enabledDate) => {
      if (enabledDate === date) {
        isEnabled = true
      }
      return false
    })
    return isEnabled
  }

  // TODO: Validar fechas de salida
  getMedicalInsuranceDates () {
    const dates = {
      start: null,
      end: null,
      duration: 'Single payment'
    }
    if (this.config.medical_start) {
      dates.start = format(this.config.medical_start, 'yyyy-LL-dd')
    }
    if (this.config.medical_end) {
      dates.end = format(this.config.medical_end, 'yyyy-LL-dd')
    }
    if (dates.start && dates.end) {
      dates.duration = 'From ' + dates.start + ' To ' + dates.end
    }
    return dates
  }

  /**
   *
   */

  getSchoolName () {
    return this.course?.offer?.campus?.name
  }

  getDocuments () {
    return this.course?.offer?.campus?.documents || []
  }

  getIncluded () {
    return this.getPrices()?.includes || []
  }

  isAgency () {
    return this.user.type === 'Agency'
  }

  isInstitution () {
    return this.user.type === 'Institution'
  }

  getComments () {
    return this.getPrices()?.comments || null
  }

  getMinAge () {
    return this.getPrices()?.min_age || null
  }

  expires () {
    return this.config.expires || null
  }

  isOnline () {
    return this.course.offer.modality === 'Online'
  }

  /**
   * Return all the posibles fees (Used for the base quote initialization)
   */
  getFees () {
    const bases = this.getPrices()?.base_prices || []
    const extras = this.getPrices()?.extras || []
    const allFees = [...bases, ...extras]
    return allFees
  }

  getTuitionFee () {
    const found = this.getPrices()?.base_prices?.find((el) => {
      if (el.name === 'Registration Fee') {
        return el
      }
      return false
    })
    return found
  }

  getFeeCost(id) {
    const fee = this.config.fees?.find((i) => i.id === id)
    if (!fee) return 0
    const res = this.calculateCost(fee.cost, fee.frequency)
    return res
  }

  isChecked(id) {
    const found = this.config?.fees?.find((el) => {
      if (el.id === id) {
        return true
      }
      return false
    })
    return found
  }

  getIds(array = []) {
    const ids = array.map((item) => { return item.id })
    return ids
  }

  /**
   * Insurance
   */
  getArrayHealtInsurance () {
    return this.getPrices()?.insurances ?? []
  }

  getInsuranceSelected () {
    if (!this.config?.healthInsuranceId) return null
    const insurance =
      this.getPrices()?.insurances?.find(
        (el) => el.id === this.config?.healthInsuranceId
      )
    return insurance
  }

  getAmountHealthInsurance () {
    if (!this.config?.weeks) return 0
    if (!this.config?.healthInsuranceId) return 0
    if (!this.config?.insurance) return 0
    if (!this.getCourseStart()) return 0

    const costHealthInsurance = this.getInsuranceSelected()
    const frequency = costHealthInsurance?.frequency
    if (frequency === 'day') {
      const courseDays = differenceInDays(this.getEndCourse(), new Date(this.getCourseStart()))
      return costHealthInsurance?.cost * (courseDays + 1)
    }
    return this.calculateCost(costHealthInsurance?.cost, costHealthInsurance?.frequency) ?? 0
  }

  getInsuranceDiscounts () {
    const insurance = this.getInsuranceSelected()
    if (!insurance) return []
    const discounts = getApplicableDiscounts (insurance.discounts, this.config?.courseStart)
    return discounts
  }

  getInsuranceDiscountAmount () {
    const weeks = this.getTotalWeeks()
    if (!this.config?.courseStart || !weeks) return 0
    const cost = this.getAmountHealthInsurance()
    const discounts = this.getInsuranceDiscounts()
    return calculateTotalDiscount(discounts, cost)
  }

  getInsuranceDiscountValues () {
    const weeks = this.getTotalWeeks()
    const insurance = this.getInsuranceSelected()
    if (!this.config?.courseStart || !weeks || !insurance) return []
    const cost = this.getAmountHealthInsurance()
    const values = this.getInsuranceDiscounts()?.map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], cost)?.toFixed(2)
      })
    )
    return values
  }

  getSubtotalInsurance () {
    return this.getAmountHealthInsurance() - this.getInsuranceDiscountAmount()
  }

  /**
   * discounts
   */

  // Course Discounts
  getCourseCost () {
    const costs = this.getPrices()?.costs
    const cost = costs?.find((costItem) => {
      if (this.getTotalWeeks() >= costItem.min && this.getTotalWeeks() <= costItem.max) {
        return costItem
      }
      return false
    })
    return cost
  }

  courseHasDiscount () {
    const discounts = this.getCourseDiscounts()
    return discounts.length > 0
  }

  getCourseDiscounts () {
    if (!this.config?.courseStart) return []
    const weeks = this.config.weeks
    const cost = this.getCourseCost()
    const discounts = getApplicableDiscounts(cost?.discounts ?? [], this.config?.courseStart, weeks)
    return discounts
  }

  getDiscountsAppliedInCourse () {
    const weeks = this.getTotalWeeks() ?? 0
    const cost = this.getCourseAmountPerWeek()
    const discs = this.getCourseDiscounts() ?? []
    const data = discs.map((item) => {
      return {
        id: item.id,
        amount: calculateTotalDiscount([item], cost, weeks)?.toFixed(2)
      }
    })
    return data
  }

  getCourseDiscountAmount () {
    if (!this.config?.courseStart) return 0
    const weeks = this.getTotalWeeks() ?? 0
    const cost = this.getCourseAmountPerWeek()
    const discounts = this.getCourseDiscounts()
    const totalDiscount = calculateTotalDiscount(discounts, cost, weeks)
    return totalDiscount
  }

  getCourseAmountWithDiscount () {
    const cost = this.getCourseAmount()
    const totalDiscount = this.getCourseDiscountAmount()
    return cost - totalDiscount
  }

  // Material discounts
  getMaterialDiscounts () {
    if (!this.config.material) return []
    const materials = this.getPrices().materials ?? []
    const m = materials.find((material) => (material.id === this.config.material))
    if (!m) return []
    const discounts = getApplicableDiscounts(m.discounts ?? [], this.config?.courseStart)
    return discounts
  }

  getMaterialDiscountsAmount () {
    if (!this.config.material) return 0
    const materials = this.getPrices().materials ?? []
    const m = materials.find((material) => (material.id === this.config.material))
    if (!m) return 0
    const totalDiscount = calculateTotalDiscount(
      this.getMaterialDiscounts(),
      this.getAmountMaterials()
    )
    return totalDiscount
  }

  getMaterialDiscountValues () {
    const material = this.getSelectedMaterial()
    if (!material) return []
    const values = this.getMaterialDiscounts().map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], material.cost, this.getTotalWeeks() ?? 0)?.toFixed(2)
      })
    )
    return values
  }

  // Tuition Discount
  getTotalDiscounts () {
    if (!this.config.weeks) return 0
    return (
      this.getTuitionFeeDiscountsAmount() +
      this.getCourseDiscountAmount() +
      this.getLodgingDiscountsAmount() +
      this.getInsuranceDiscountAmount() +
      this.getMaterialDiscountsAmount() +
      this.getTransportDiscountsAmount()
    )
  }

  getTuitionFeeDiscounts () {
    const fee = this.getTuitionFee()
    if (!this.config?.courseStart || !fee) return []
    const studentLocation = this.getStudentNationality()
    return getApplicableDiscounts(fee.discounts ?? [], this.config?.courseStart, undefined, studentLocation)
  }

  getTuitionFeeDiscountsAmount () {
    const fee = this.getTuitionFee()
    if (!fee) return 0
    const discounts = fee?.discounts ?? []
    const totalDiscount = calculateTotalDiscount(discounts, fee.cost)
    return totalDiscount
  }

  getTuitionDiscountsValues () {
    const fee = this.getTuitionFee()
    const values = this.getTuitionFeeDiscounts().map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], fee.cost)?.toFixed(2)
      })
    )
    return values
  }

  // Lodging Discounts
  getLodgingDiscounts () {
    const lodging = this.getSelectedLodging()
    if (!lodging) return []
    const discounts = getApplicableDiscounts(lodging.discounts ?? [], this.config?.courseStart)
    return discounts
  }

  getLodgingDiscountsValues () {
    const values = this.getLodgingDiscounts().map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], this.getHighSeasonAmountLodging())?.toFixed(2)
      })
    )
    return values
  }

  getLodgingDiscountsAmount () {
    const lodging = this.getSelectedLodging()
    if (!this.config.lodgingStart || !lodging) return 0
    const totalDiscount = calculateTotalDiscount(this.getLodgingDiscounts(), this.getHighSeasonAmountLodging())
    const lodgingCost = (this.getAmountsLodging()?.ls_cost + this.getAmountsLodging()?.hs_cost)
    return lodgingCost > 0 ? totalDiscount : 0
  }

  // Transport Discounts
  getTransportDiscounts () {
    const trasport = this.getTransport()
    if (!this.config.transport || !trasport) return []
    return getApplicableDiscounts(trasport.discounts ?? [], this.config?.courseStart)
  }

  getTransportDiscountsAmount () {
    const trasport = this.getTransport()
    if (!trasport || this.getAmountTransport() <= 0) return 0
    const totalDiscount = calculateTotalDiscount(this.getTransportDiscounts(), this.getAmountTransport())
    return totalDiscount
  }

  getTransportDiscountsValues () {
    const values = this.getTransportDiscounts().map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], this.getAmountTransport())?.toFixed(2)
      })
    )
    return values
  }

  getSubtotalTrasnport () {
    let total = 0
    const subt = this.getAmountTransport() + this.getTransportAmountHighSeason()
    // total += subt - this.getTransportDiscountsAmount()
    total += subt
    // this?.getTransportHighSeasons()?.forEach(high => {
    //   total += high.hs_cost
    // })
    if (isNaN(total)) return 0

    return total ?? 0
  }

  // Fees (Extras And General Prices)
  getFeeDiscounts (id) {
    const isSelected = this.config.fees?.find((i) => i.id === id)
    if (!id || id === undefined || !isSelected) return []
    const allFees = [...this.getPrices()?.base_prices, ...this.getPrices()?.extras]
    const fee = allFees?.find((i) => i.id === id)
    if (!fee) return []
    const numericDiscounts = fee.discounts?.filter((d) => !!d.value)
    return getApplicableDiscounts(numericDiscounts ?? [], this.config?.courseStart)
  }

  getFeeDiscontAmount(id) {
    const discounts = this.getFeeDiscounts (id)
    const cost = this.getFeeCost(id)
    return calculateTotalDiscount(discounts, cost)
  }

  getFeeSubtotal (id) {
    return this.getFeeCost(id) - this.getFeeDiscontAmount(id)
  }

  getFeeDiscountValues (id) {
    const values = this.getFeeDiscounts (id).map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], this.getFeeCost(id))?.toFixed(2)
      })
    )
    return values
  }

  // Return the Extra Cost Selectd with its respective discounts
  getFeesDiscountValues () {
    const values = this.config.fees?.map((i) => ({
      id: i.id,
      cost: this.getFeeCost(i.id),
      discounts: this.getFeeDiscountValues (i.id),
      duration: this.getDurationLabel (i.frequency)
    }))
    return values
  }

  // Discounts in Kinds
  getDiscountsInKindGoods () {
    if (!this.config?.courseStart) return []
    const discountsInkind = this.course.offer.discounts_inkind ?? []
    const validDiscs = discountsInkind.filter((discount) => isApplicable(discount, this.config?.courseStart))
    return validDiscs
  }

  getAllGoods () {
    if (!this.config?.courseStart) return []
    const allGoods = []
    // Course Goods
    const goods = this.getPrices()?.goods ?? []
    allGoods.push(...goods)
    // Base prices Goods
    const basePrices = this.getPrices()?.base_prices ?? []
    const basePricesChecked = this.config.fees ?? []
    const allChecked = [...basePricesChecked, { id: this.getTuitionFee()?.id }]
    basePrices?.forEach(element => {
      const bGoodss = element.goods ?? []
      const found = allChecked.find((item) => item?.id === element.id)
      if (found) {
        allGoods.push(...bGoodss)
      }
    })
    return allGoods
  }

  getValidGoods () {
    if (!this.config?.courseStart) return []
    let goods = this.getAllGoods() ?? []
    if (this.config?.student_nationality) {
      goods = goods?.filter(good => !good?.discount?.exceptions?.includes(this.config?.student_nationality))
      this.validAppliedGoods()
    }
    const validGoods = goods.filter((good) => {
      const applicable = isApplicable(good.discount, this.config?.courseStart)
      const ranges = good.ranges ?? []
      if (ranges.length > 0) {
        const foundRange = good.ranges.find((range) => {
          const weeks = this.getTotalWeeks()
          return weeks >= range.from && weeks <= range.to
        })
        if (!foundRange) return false
      }
      return applicable
    })
    return validGoods
  }

  validAppliedGoods () {
    let goods = this.getPrices()?.goods ?? []
    if (this.config?.student_nationality) {
      goods = goods?.filter(good => good?.discount?.exceptions?.includes(this.config?.student_nationality))
      goods = goods?.map(good => good.id)
      if (this.config.appliedGoods?.length > 0) {
        this.config.appliedGoods = this.config?.appliedGoods?.filter(good => !goods?.includes(good))
      }
    }
  }

  getGoodsIds () {
    return this.config.appliedGoods ?? []
  }

  isGoodChecked(id) {
    return !!this.config.appliedGoods?.find((item) => item === id)
  }

  /**
   * Agency Services
   */
  getAgencyService(id) {
    return this.course.agency_services.filter((service) => service.id === id)
  }

  serviceIsChecked(id) {
    const found = this.config?.agencyServices?.find((el) => (el.id === id))
    return found
  }

  getAgencyServiceCost(id) {
    const found = this.course.agency_services.find((service) => service.id === id)
    const costs = found.costs
    const cost = costs[0] ?? null
    return cost?.cost ?? 0
  }

  getAgencyServiceCurrency(id) {
    // // This return the Agency Service Currency
    const found = this.course.agency_services.find((service) => service.id === id)
    return found?.divisa?.code
    // return this.course.agency_currency?.code
  }

  getAgencyCurrency () {
    // console.log('USER', this.user?.divisa?.code)
    // return this.course.agency_currency?.code
    return this.user?.divisa?.code
  }

  getAgencyMedicalCurrency () {
    const medical = this.getSelectedMedical()
    return medical?.currency?.code
  }

  getServiceAgencyCurrency(id) {
    const service = this.getPrices()?.service_agency?.find((el) => el.id === id)
    return service?.currency?.code
  }

  getAgencyServiceSelectedCost(id) {
    const mis = this.config?.agencyServices?.find((i) => i.id === id)
    if (mis) {
      return mis?.cost ?? 0
    }
    return 0
  }

  // Return the agency services amount in agency currency
  calculateAgencyMiscellaneousTotal () {
    let total = 0
    this.config?.agencyServices?.map((misc) => {
      let miscTotal = 0
      misc.currency = this.getAgencyServiceCurrency(misc.id)
      if (misc.currency === this.getAgencyCurrency()) {
        miscTotal = misc.cost ?? 0
      } else {
        miscTotal = this.calculateExchangeValue(misc.cost, this.getExchangeValue(misc.currency))
      }
      misc.total = miscTotal
      total += miscTotal
      return total
    })
    return total
  }

  getQuoteAgencyServices () {
    return this.config?.agencyServices || []
  }

  // Exchanges
  getExchangeValue(currency) {
    if (!this.exchanges) return 1
    if (currency === this.getAgencyCurrency()) return 1
    const found = this.exchanges.find((value) => value?.code === currency)
    return found?.exchange_value
  }

  calculateExchangeValue(cost, exchangeVal) {
    if (!cost || !exchangeVal) console.log('Somenting is wrong ', cost, exchangeVal)
    return +(cost * exchangeVal)?.toFixed(2) ?? 'exchange value not found'
  }

  // Functions
  calculateCost(cost, frequency) {
    if (frequency === 'day') {
      const courseDays = differenceInDays(this.getEndCourse(), new Date(this.getCourseStart()))
      if (isNaN(courseDays)) {
        // console.log('QuoteCalculatorService ln 1840: courseDays IsNaN')
        return 0
      }
      return (courseDays + 1) * cost
    }
    const weeks = this.getTotalWeeks()
    return calculateCost(cost, frequency, weeks)
  }

  calculateCostWithLodging(cost, frequency) {
    const weeks = this.getLodgingWeeks()
    return calculateCost(cost, frequency, weeks)
  }

  getDurationLabel(frequency) {
    if (frequency === 'unic') return 'Single payment'
    if (frequency === 'day') {
      const courseDays = differenceInDays(this.getEndCourse(), new Date(this.getCourseStart()))
      return '' + (courseDays + 1) + ' Days'
    }
    if (frequency === 'weeks' || frequency === 'week') {
      return (this.getTotalWeeks() + ' Semanas')
    }
    if (frequency === 'month' || frequency === 'months' || frequency === 'monthly') {
      const months = Math.ceil(this.getTotalWeeks() / 4)
      return (months + ' Months')
      // return (this.getTotalWeeks() + ' Semanas')
    }
    return 'Single payment.'
  }

  // Duration label for materials
  durationMaterial () {
    if (!this.config?.materials) return null
    const found = this.getPrices()?.materials?.find((el) => el.id === this.config?.material)
    return this.getDurationLabel(found?.frequency)
  }

  durationInsurance () {
    const insurance = this.getInsuranceSelected()
    if (!insurance) return null
    return this.getDurationLabel (insurance.frequency)
  }

  getArrayOfServices(services) {
    const groupServices = []
    services?.forEach(service => {
      groupServices.push({
        id: service.service_agency,
        cost: this.getAmountService(service.service_agency),
        currency: this.getServiceAgencyCurrency(service.service_agency)
      })
    })
    return groupServices
  }

  userCanApply () {
    const now = new Date()
    const startCourse = this.getCourseStart()
    if (!startCourse) return false
    const startDate = new Date(startCourse)
    const daysOfDifference = differenceInDays(startDate, now)
    if (this.hasLodging && this.getSelectedLodging && daysOfDifference > 14) return true
    if (!this.config?.lodging && daysOfDifference > 7) return true
    return false
  }

  showAdditionalsPanel () {
    return true
  }
}

export { QuoteCalculatorService }
