import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd'
import { useSearch } from '../../../providers/search/search-context-provider'
import { useGetLanguages } from '../../../bussines/hooks/quoter/useGetLanguages'
import { useGetInstitutionsAndCampus } from '../../../bussines/hooks/quoter/useGetSearchData'
import { useGetExtras, useGetSports } from '../../../bussines/hooks/quoter/useGetInputs'
import { useGetGrades } from '../../../bussines/hooks/quoter/useGetGrades'
import { SelectStudyAreasByProgramFormItem } from '../../../common/components/helper-form/SelectStudyAreasByProgramFormItem'
import { SelectDiplomaSubtypes } from '../../../common/components/helper-form/SelectDiplomaSubtypes'
import { languagesEs } from '../../../bussines/utils/LanguagesES'

const { Option } = Select
const { RangePicker } = DatePicker

const dateFormat = 'MM/YYYY'
function SchoolSearcher ({ state, clearMigas, handleMigas }) {
  const [optionsData, setOptionsData] = useState([])
  const [optionCountries, setOptionsCountries] = useState([])
  const [optionCities, setOptionsCities] = useState([])
  const { search, changeSearch } = useSearch()
  const [searchlang, setSearchlang] = useState(false)
  const [searchCity, setSearchCity] = useState()
  const [id, setId] = useState()
  const [type, setType] = useState()
  const [isGreater, setIsGreater] = useState(false)
  const [idLanguage, setIdLanguage] = useState()
  const [isStart, setIsStart] = useState(true)

  const { program } = search
  const [form] = Form.useForm()
  const { data, isLoading } = useGetInstitutionsAndCampus(program, { search: searchCity, id, type, idLanguage })
  const { isLoading: isLoadingLanguage, data: languages } = useGetLanguages({ idLocation: id, type })
  const { data: sports } = useGetSports({ program })
  const { data: extras } = useGetExtras({ program })
  const { data: grades } = useGetGrades({ program })

  useEffect(() => {
    if (data && !isLoading) {
      let institutions = (data.institutions || []).map((institution) => ({
        id: institution.id,
        value: institution.name,
        type: 'institution'
      }))
      institutions = institutions.filter(item => item.value)

      let campus = (data.campus || []).map((item) => ({
        id: item.id,
        value: item.name,
        type: 'campus'
      }))
      campus = campus.filter(item => item.value)

      let cities = (data.cities || []).map((item) => ({
        id: item.id,
        value: item.name,
        type: 'city'
      }))
      cities = cities.filter(item => item.value)

      let countries = (data.countries || []).map((item) => ({
        id: item.id,
        value: item.name,
        type: 'country'
      }))
      countries = countries.filter(item => item.value)

      setOptionsData([
        ...institutions, ...campus
      ])

      setOptionsCountries([
        ...countries
      ])
      setOptionsCities([
        ...cities
      ])
    }
  }, [isLoading, data])

  const handleSearch = (key, values) => {
    if (key === 'school') {
      const school = values.split('-')
      changeSearch('LANG_FORM', {
        ...search,
        school_id: school[0],
        school_type: school[1]
      })
    } else if (key === 'place') {
      const place = values.split('-')
      setId(place[0])
      setType(place[1])
      if (place[1] === 'country') {
        changeSearch('LANG_FORM', {
          ...search,
          // place_id: place[0],
          place: place[0],
          place_type: place[1],
          idCountry: place[0],
          country_type: place[1]
        })
      } else {
        changeSearch('LANG_FORM', {
          ...search,
          // place_id: place[0],
          place: place[0],
          place_type: place[1]
        })
      }
    } else {
      changeSearch('LANG_FORM', {
        ...search,
        [key]: values
      })
    }
  }

  const filterOption = (inputValue, option) => {
    return option.props['data-type'].toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1
  }

  const disabledDate = (current) => {
    return current && current < moment().startOf('day')
  }

  // useEffect(() => {
  //   form.resetFields()
  // }, [program])

  const cleanSearch = () => {
    form.resetFields()
    clearMigas()
    const newSearch = {}
    Object.assign(newSearch, search)

    delete newSearch.idLanguage
    delete newSearch.lang
    delete newSearch.dateAll
    delete newSearch.place
    delete newSearch.place_type
    delete newSearch.search
    delete newSearch.rate
    delete newSearch.age
    delete newSearch.school

    setId()
    setType()

    changeSearch('LANG_FORM', {
      ...newSearch
    })
  }

  useEffect(() => {
    if (state) {
      form.resetFields()
      if (state?.searchLocation) {
        setSearchCity(state?.searchLocation)
      }
      if (state?.idLanguage && state?.language) {
        setSearchlang(state?.language)
      }
      form.setFieldsValue({
        lang: state?.idLanguage,
        date: state?.date && moment(state?.date, 'MM/YYYY')
      })
      if (state?.idLocation && state?.type) {
        setSearchCity(state?.location)
        setId(state?.idLocation)
        setType(state?.type)
        if (state?.type === 'city') {
          form.setFieldsValue({
            city: state?.idLocation + '-' + state?.type
          })
        } else {
          form.setFieldsValue({
            place: state?.idLocation + '-' + state?.type
          })
        }
      }
      if (state?.place && state?.place_type) {
        setId(state?.place)
        setType(state?.place_type)
        if (state?.place_type === 'city') {
          form.setFieldsValue({
            city: state?.place + '-' + state?.place_type
          })
        } else {
          form.setFieldsValue({
            place: state?.place + '-' + state?.place_type
          })
        }
      }
      if (state?.age) {
        form.setFieldsValue({
          age: state?.age
        })
      }
      if (state?.sports) {
        form.setFieldsValue({
          sports: state?.sports
        })
      }
      if (state?.extras) {
        form.setFieldsValue({
          extras: state?.extras
        })
      }
      if (state?.modality) {
        form.setFieldsValue({
          modality: state?.modality
        })
      }
      if (state?.grade) {
        form.setFieldsValue({
          grade: state?.grade
        })
      }
      if (state?.sub_type) {
        form.setFieldsValue({
          sub_type: state?.sub_type
        })
      }
      if (state?.study_area) {
        form.setFieldsValue({
          study_area: state?.study_area
        })
      }
      if (state?.is_legal) {
        form.setFieldsValue({
          is_legal: state?.is_legal
        })
      }
    }
  }, [state])

  useEffect(() => {
    if (search) {
      if (search?.place && search?.place_type) {
        setId(search?.place)
        setType(search?.place_type)
        if (search?.place_type === 'country') {
          form.setFieldsValue({
            place: search?.place + '-' + search?.place_type
          })
        }
      }
      setSearchCity()
    }
  }, [search])

  const [isFirst, setIsFirst] = useState(true)
  useEffect(() => {
    if (state && data && state?.searchLocation && isFirst && search?.dateAll) {
      if (data?.cities?.length > 0) {
        const cities = data.cities?.find(item => item.name.toLowerCase().includes(state?.searchLocation?.toLowerCase()))
        if (cities) {
          form.setFieldsValue({
            city: cities?.id + '-city'
          })
          setIsFirst(false)
          // handleSearch('place', cities?.id + '-city')
          delete state.searchLocation
        }
      } else if (data?.countries?.length > 0) {
        const countries = data.countries?.find(item => item.name.toLowerCase().includes(state?.searchLocation?.toLowerCase()))
        if (countries) {
          form.setFieldsValue({
            place: countries?.id + '-country'
          })
          setIsFirst(false)
          // handleSearch('place', countries?.id + '-country')
          delete state.searchLocation
        }
      }
      // if (setEnableSearch) setEnableSearch(true)
      // console.log("🚀 ~ file: SchoolSearcher.js:279 ~ useEffect ~ setEnableSearch:")
    } else if (!state) {
      // if (setEnableSearch) setEnableSearch(true)
    }
  }, [state, data])

  const disabledDateLanguage = (current) => {
    const today = moment()
    if (current && current < today) {
      return true
    }

    if (isStart) {
      setIsStart(current => !current)
      return !(current.day() === 1)
    } else {
      setIsStart(current => !current)
      return !(current.day() === 5)
    }
  }

  const handleRangeDate = (dateMoment) => {
    if (dateMoment[1]) {
      handleSearch('dateAll', dateMoment[0].format('MM/YYYY'))
    }

    setIsStart(current => !current)
  }

  const handleInfo = (e, type, option) => {
    if (type === 'place') {
      handleMigas(option.children, type)
    } else {
      handleMigas(e, type)
    }
  }

  return (
    <Form form={form} style={{ marginBottom: '1rem' }}>
      <Row className='school-search__inner school-searhc__row-gap'>
        <Col span={24}>
          <Row justify='space-between'>
            <Col>
              <h4>Filtrar por</h4>
            </Col>
            <Col>
              <Button onClick={cleanSearch}>Limpiar</Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Form.Item name='place'>
            <Select
              className='school-search__item'
              showSearch
              filterOption={filterOption}
              placeholder='País'
              size='large'
              onSearch={e => setSearchCity(e)}
              onChange={(e, option) => {
                handleSearch('place', e)
                handleInfo(e, 'place', option?.children)
              }}
            >
              {optionCountries?.map(option => (
                <Option key={option.id + '-' + option.type} value={option.id + '-' + option.type} data-type={option.value}>{option.value}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name='city'>
            <Select
              className='school-search__item'
              showSearch
              filterOption={filterOption}
              placeholder='Ciudad'
              size='large'
              onSearch={e => setSearchCity(e)}
              onChange={(e, option) => {
                handleSearch('place', e)
                handleInfo(option?.children, 'city')
              }}
            >
              {optionCities?.map(option => (
                <Option key={option.id + '-' + option.type} value={option.id + '-' + option.type} data-type={option.value}>{option.value}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item noStyle name='lang'>
            <Select
              showSearch
              className='school-search__item'
              filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
              onSearch={(search) => {
                setSearchlang(!!search)
              }}
              placeholder='Idiomas'
              allowClear
              size='large'
              loading={isLoadingLanguage}
              onChange={(e, option) => {
                handleInfo(option?.children, 'lang')
                if (e) {
                  handleSearch('lang', Number(e))
                  setIdLanguage(Number(e))
                } else {
                  handleSearch('lang', '')
                  setIdLanguage()
                }
              }}
            >
              {searchlang && languages?.map((item) => <Option key={item?.id} value={item?.id}>{languagesEs[item?.name] ?? item?.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>

        {program !== 'language' && (
          <Col span={24}>
            <Form.Item noStyle name='date'>
              <DatePicker
                className='school-search__item'
                placeholder='Cualquier fecha'
                size='large'
                picker='month'
                format={dateFormat}
                disabledDate={disabledDate}
                onChange={(date, dateString) => {
                  handleSearch('dateAll', dateString)
                  handleInfo('dateAll', dateString)
                }}
              />
            </Form.Item>
          </Col>)}

        {program === 'language' && (
          <Col span={24}>
            <Form.Item noStyle name='range-date'>
              <RangePicker className='school-search__item' disabledDate={disabledDateLanguage} onCalendarChange={handleRangeDate} />
            </Form.Item>
          </Col>
        )}

        {(program === 'academic_years' || program === 'boarding_schools') && (
          <Col span={24}>
            <Form.Item name='sports'>
              <Select
                className='school-search__item'
                size='large'
                mode='multiple'
                placeholder='Deportes'
                onChange={(e, option) => {
                  handleSearch('sports', e)
                  handleInfo(e, 'sports')
                }}
              >
                {sports?.sports?.sort()?.map((item) => <Option key={`${item}`}>{item} </Option>)}

              </Select>
            </Form.Item>
          </Col>)}

        {(program === 'academic_years' || program === 'boarding_schools') && (
          <Col span={24}>
            <Form.Item name='extras'>
              <Select
                className='school-search__item'
                size='large'
                mode='multiple'
                placeholder='Extracurriculars'
                onChange={e => {
                  handleSearch('extras', e)
                  handleInfo(e, 'extras')
                }}
              >
                {extras?.extracurriculars?.sort()?.map((item) =>
                  <Option key={`${item}`}>{item} </Option>)}

              </Select>
            </Form.Item>
          </Col>)}

        {(program === 'academic_years' || program === 'boarding_schools') && (
          <Col span={24}>
            <Form.Item name='modality'>
              <Select
                className='school-search__item'
                size='large'
                placeholder='Modalidad'
                onChange={(e, option) => {
                  handleSearch('modality', e)
                  handleInfo(option?.children, 'modality')
                }}
              >
                <Option key={1} value='Presential'>Presential</Option>
                <Option key={2} value='Online'>Online</Option>
                <Option key={3} value='Hybrid'>Hybrid</Option>
              </Select>
            </Form.Item>
          </Col>)}

        {(program === 'academic_years' || program === 'boarding_schools') && (
          <Col span={24}>
            <Form.Item name='grade'>
              <Select
                className='school-search__item'
                size='large'
                placeholder='¿En qué grado de estudios estás actualmente?'
                onChange={(e, option) => {
                  handleSearch('grade', e)
                  handleInfo(option?.children, 'grade')
                }}
              >
                {grades?.map(grade => <Option key={grade}>{grade}</Option>)}
              </Select>
            </Form.Item>
          </Col>)}

        {(program === 'graduate') && (
          <Col span={24}>
            <SelectDiplomaSubtypes
              name='sub_type'
              onChange={e => handleSearch('sub_type', e)}
              // onChange={(val) => {
              //   setSearchFilter((currentValues) => {
              //     return { ...currentValues, sub_type: val }
              //   })
              // }}
              selectProps={{
                className: 'school-search__item',
                size: 'large',
                defaultValue: 'diploma'
              }}
            // filters={searchFilter}
            />
          </Col>
        )}

        {(program === 'graduate') && (
          <Col span={24}>
            <SelectStudyAreasByProgramFormItem
              name='study_area'
              className='school-search__item'
              size='large'
              onChange={e => handleSearch('study_area', e)}
              // onChange={(val) => {
              //   setSearchFilter((currentValues) => {
              //     return { ...currentValues, study_area: val }
              //   })
              // }}
              placeholder='Área de estudios'
            // filters={searchFilter}
            />
          </Col>
        )}

        {(program === 'language' && (
          <Col span={24}>
            <Row align='middle' gutter={[16, 16]}>
              <Col>¿Eres mayor de 16 años?</Col>
              <Col>
                <Form.Item
                  className='school-search__radio'
                  name='is_legal'
                >
                  <Radio.Group
                    name='radiogroup'
                    onChange={e => {
                      handleSearch('is_legal', e.target.value)
                      if (e.target.value === 'no') {
                        setIsGreater(false)
                      } else {
                        setIsGreater(true)
                      }
                    }}
                    defaultValue='no'
                  >
                    <Radio value='yes'>Sí</Radio>
                    <Radio value='no'>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        ))}

        {(!isGreater || program !== 'language') && (
          <Col span={24}>
            <Form.Item name='age'>
              <Input
                type='number'
                placeholder='Edad'
                size='large'
                onChange={e => {
                  handleSearch('age', e.target.value)
                  handleInfo(e.target.value, 'age')
                }}
                className='school-search__item'
              />
            </Form.Item>
          </Col>)}

        <Col span={24}>
          <Form.Item name='school'>
            <Select
              className='school-search__item'
              showSearch
              filterOption={filterOption}
              placeholder='Escuela'
              size='large'
              onChange={(e, option) => {
                handleSearch('school', e)
                handleInfo(option?.children, 'school')
              }}
            >
              {optionsData?.map(option => (
                <Option key={option.id + '-' + option.type} value={option.id + '-' + option.type} data-type={option.value}>{option.value}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

      </Row>
    </Form>
  )
}

export { SchoolSearcher }
