import { Col, Row } from 'antd'
import { AppLogoNameHeader } from '../../components/AppLogoNameHeader'
import React from 'react'
import { Link } from 'react-router-dom'
import { URL_ABOUT_TERMS_AND_CONDITIONS_PAGE } from '../../../pages/about/TermsAndConditionsPage'
import { URL_ABOUT_POLICY_PAGE } from '../../../pages/about/PolicyPage'
import PublicIcon from '@material-ui/icons/Public'
import { useAuth } from '../../../providers/auth/auth-context-provider'

const MainThemeFooter = () => {
  const { user: _user, userLocalCurrency, setUserLocalCurrency, userCountryLocation } = useAuth()

  return (
    <Row className='byp-footer'>
      <Col>

        <Col xs={24} md={21} style={{ color: 'var(--gray-100)', alignItems: 'center', fontSize: '12px', fontWeight: '400', lineHeight: '18px', letterSpacing: '0em', marginBottom:'15px' }}>
          <PublicIcon style={{ fontSize: '15px' }} /> {userCountryLocation} ({userLocalCurrency}) ${userLocalCurrency}
        </Col>
        <Row style={{ alignItems: 'center' }} gutter={12}>
          <Col xs={24} md={3}>
            <AppLogoNameHeader otherlogo style={{ width: '2.625rem', heigh: 'auto' }} />
          </Col>
          <Col xs={24} md={21} style={{ fontSize: '12px', fontWeight: '400', lineHeight: '18px', letterSpacing: '0em' }}>
            Todos los derechos reservados BOOK YOUR PROGRAM 2023
          </Col>
        </Row>
      </Col>
      <Col style={{ alignItems: 'center', display: 'flex' }}>
        <Row>
          <a href='#'><span className='align-icon-txt'>Contáctanos</span></a>
          <Link
            to={URL_ABOUT_TERMS_AND_CONDITIONS_PAGE}
            state={{}}
          >
            <span className='align-icon-txt'>Términos y condiciones</span>
          </Link>
          <Link
            to='#'
            state={{}}
          >
            <span className='align-icon-txt'>FAQ's</span>
          </Link>
          <Link
            to={URL_ABOUT_POLICY_PAGE}
            state={{}}
          >
            <span className='align-icon-txt'>Política de privacidad</span>
          </Link>
        </Row>
      </Col>
    </Row>
  )
}

export { MainThemeFooter }
