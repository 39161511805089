import { Col, DatePicker, Form, InputNumber, Row } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import moment from 'moment'
import { format, subDays } from 'date-fns'

import 'moment/locale/es-mx'
import locale from 'antd/es/date-picker/locale/es_ES'
import { useEffect, useState } from 'react'

function SelectStartAndDuration () {
  const { service, formConfig, setFormConfig, form, course } = useQuoter()

  const disabledDate = (current) => {
    if (current < moment().add(14, 'days')) {
      return true
    }
    // filter dated base on some many diferentes criteria :c
    let filteredDates
    const day = moment(current).isoWeekday()
    const datString = format(moment(current).toDate(), 'Y-LL-dd')
    const isHoliday = service.isHoliday(datString)
    const startDay = course?.offer?.start_day
    const startDates = course?.offer?.start_dates

    if (startDay === 'none') {
      const today = new Date()
      filteredDates = startDates?.filter(d => new Date(d.date) >= today)
      const specificDatesList = filteredDates?.map(dateObj => dateObj?.date)
      const isSpecific = specificDatesList?.some(date => moment(date).isSame(current, 'day'))
      return !isSpecific
    }

    let disabled
    disabled = day > service.getDayStartCourse() || day < service.getDayStartCourse()
    if (!disabled && isHoliday) {
      const nextDay = service.enableNextDay(datString)
      addEnabledDate(nextDay)
      return true
    }
    disabled = (current && disabled) || current < moment().endOf('day')

    const program = course?.offer?.program?.name
    if (startDates && (program === 'language' || program === 'summer_camps') && startDates?.length > 0) {
      const today = new Date()
      filteredDates = startDates?.filter(d => new Date(d.date) >= today)
    }

    if (filteredDates && (program === 'language' || program === 'summer_camps') && filteredDates?.length > 0) {
      const specificDates = filteredDates?.map(dateObj => dateObj?.date)
      const isSpecificDate = specificDates?.some(date => moment(date).isSame(current, 'day'))
      if (isSpecificDate) {
        return false
      }
      if (day === 1) {
        disabled = true
      }
    }

    if (startDay && (program === 'language') && !(current < moment().endOf('day'))) {
      moment.locale('en')
      const dayName = moment(current).locale('en').format('dddd')
      if (dayName.toLowerCase() === startDay.toLowerCase()) {
        return false
      }
    }

    return (disabled || isHoliday) && !service.isEnabledDate(datString)
  }

  const addEnabledDate = (date) => {
    const enabledDates = formConfig?.enabledDates ?? []
    const found = enabledDates.find((d) => d === date)
    if (found) {
      // console.log('La fecha ' + date + '  ya se había agregado')
      return true
    }
    enabledDates.push(date)
    setFormConfig({
      ...formConfig,
      enabledDates
    })
  }

  const onChange = (date) => {
    // TO DO: Check if there is a way of go to the date without select the date
    const lodgingDate = moment.utc(date).subtract(1, 'days')
    form.setFieldsValue({ lodgingStart: moment(lodgingDate) })
    setFormConfig({
      ...formConfig,
      courseStart: date,
      lodgingStart: moment(lodgingDate)
    })
    service?.searchPrices(date)
    setStartCourse(date)
  }

  const changeWeeks = (nWeeks) => {
    const courseCosts = formConfig.courseHSFees ?? []
    const hs = service.getHighSeasonFees()
    if (hs) {
      for (const item of hs) {
        for (const fee of item.fees) {
          const alreadyIncluded = courseCosts.find((item) => item.id === fee.id)
          if (fee.mandatory && !alreadyIncluded) {
            courseCosts.push({
              id: fee.id,
              cost: undefined,
              frequency: undefined
            })
          }
        }
      }
      setFormConfig(prevFormConfig => ({
        ...prevFormConfig,
        courseHSFees: courseCosts,
        weeks: nWeeks
      }))
    } else {
      setFormConfig({
        ...formConfig,
        weeks: nWeeks
      })
    }
  }

  const [startCourse, setStartCourse] = useState(null)
  useEffect(() => {
    if (formConfig?.courseStart !== startCourse) {
      const program = course?.offer?.program?.name
      const startDates = course?.offer?.start_dates
      if (startDates && (program === 'language' || program === 'summer_camps') && startDates?.length > 0) {
        form.setFieldsValue({
          startEvent: moment(startDates[0]?.date)
        })
        setStartCourse(startDates[0]?.date)
        onChange(startDates[0]?.date)
      }
    }
  }, [course, service])

  function obtenerNombreMes (fecha) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return meses[fecha.getMonth()]
  }

  function convertirFecha (fechaStr) {
    const fecha = new Date(fechaStr)

    const nombreMes = obtenerNombreMes(fecha)

    const dia = fecha.getDate()
    const año = fecha.getFullYear()

    const resultado = `${dia} ${nombreMes} ${año}`

    return resultado
  }

  return (
    // <Row gutter={12} style={{ marginTop: '10px' }} align='middle'>
    <div style={{ marginTop: '10px', display: "flex", flexDirection: "column", gap: 0 }}>
      <label style={{ fontWeight: '600', padding: '0 6px 12px' }}>Fechas de Curso</label>
      <Col className='antd-date'>
        <div className='cotizacion__start-end'>
          <Col>
            <label>Inicio de Clases</label>
            <Form.Item
              name='startEvent' rules={[{
                required: true,
                message: 'This field is required'
              }]}
            >
              <DatePicker
                locale={locale}
                style={{ marginBottom: '10px' }}
                placeholder='Añadir fecha'
                disabledDate={disabledDate}
                onChange={onChange}
                format='dddd, MMMM DD, YYYY'
              />
            </Form.Item>
          </Col>
          <Col>
            <label>Duración del curso</label>
            <Form.Item
              name='weeks'
              rules={[{
                required: true,
                message: 'This field is required'
              }]}
            >
              <InputNumber
                placeholder='1'
                style={{ marginBottom: '10px' }}
                onChange={changeWeeks}
                className='school-search__item quotation__inputnumber'
                min={service.getMinWeeks()}
                max={service.getMaxWeeks()}
                addonAfter='Semanas'
              />
            </Form.Item>
            {/* <DatePicker
              locale={locale}
              style={{ maxWidth: '8rem' }}
              value={service.getEndString() && moment(service.getEndString(), 'DD MMMM YYYY')}
              placeholder='Fecha final'
              disabled
              onChange={onChange}
              format='MMMM DD, YYYY'
              className='quote__border'
            /> */}
          </Col>
        </div>
      </Col>
      <Col span={24}>
        {service?.getEndString() && (
          // <label>Fin del curso: {moment(service.getEndString(), 'DD MMMM YYYY').format('DD MMMM YYYY')}</label>
          <p id='EndString' style={{ marginBottom: '0', marginTop: '.5rem', fontSize: '0.75rem' }}>
            Fin del curso: {convertirFecha(moment(service.getEndString(), 'DD MMMM YYYY'))}
          </p>
        )}
        {/* <Form.Item
          name='weeks'
          rules={[{
            required: true,
            message: 'This field is required'
          }]}
        >
          <InputNumber
            style={{ width: '9rem' }}
            placeholder='1'
            onChange={(nWeeks) => {
              setFormConfig({
                ...formConfig,
                weeks: nWeeks
              })
            }}
            min={service.getMinWeeks()}
            max={service.getMaxWeeks()}
            addonAfter='Semanas'
          />
        </Form.Item> */}
      </Col>
      <Col>
        {service.getIsHighSeason() && <b id='IsHighSeason'>High Season</b>}
      </Col>
    </div>
  )
}

export { SelectStartAndDuration }
