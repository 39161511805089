import React, { useEffect, useState } from 'react'
import { Alert, Button, Divider, Form, Input } from 'antd'
import { useLoginUser } from '../../../bussines/hooks/user/useLoginUser'
import { LoadingForm } from '../../../common/components/LoadingForm'
import { useNavigate } from 'react-router-dom'
// import { URL_REGISTER_PAGE } from '../../register'
import { URL_QUOTER_PAGE } from '../../quoter'
import {
  // GoogleLogin,
  // GoogleOAuthProvider,
  useGoogleLogin
} from '@react-oauth/google'
import axios from 'axios'
import { FcGoogle } from 'react-icons/fc'
import { removeFromStorage, retrieveFromStorage } from '../../../utils/localStorageUtils'
import SessionService from '../../../services/SessionService'

const LoginForm = ({ isForAB = false }) => {
  const navigate = useNavigate()
  const { isLoading, onSubmit, currentState, state } = useLoginUser()
  const [user, setUser] = useState([])
  const [profile, setProfile] = useState([])

  const handleRedirection = () => {
    if (SessionService.isPublicSession()) return
    const redirect = retrieveFromStorage('redirectOnLoginSucces')
    if (redirect) {
      removeFromStorage('redirectOnLoginSucces')
      navigate(redirect)
    } else {
      navigate(URL_QUOTER_PAGE)
    }
  }

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  })

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          setProfile(res.data)
        })
        .catch((err) => console.log(err))
    }
  }, [user])

  useEffect(() => {
    if (profile.length !== 0 && user) {
      onSubmit(profile.email, null, false, {
        id: profile.id,
        name: profile.family_name,
        lastName: profile.given_name,
        picture: profile.picture,
        token: profile.access_token
      })
    }
  }, [profile])

  // TODO: Fix When we come from the register page, the first attepms to login always fail
  useEffect(() => {
    if (!isLoading && currentState === state.LOGIN_SUCCESS) {
      handleRedirection()
    } else if (!SessionService.isPublicSession() && currentState === state.LOGIN_INITIAL) {
      handleRedirection()
    }
  }, [currentState])

  if (isLoading) {
    return <LoadingForm message='Login in...' />
  }

  const onFinish = (values) => {
    onSubmit(values.username, values.password, values.remember ?? '')
  }

  return (
    <Form
      name='normal_login'
      className='login-form'
      // initialValues={{
      //   password: process.env.REACT_APP_PASSWORD || '',
      //   remember: true
      // }}
      onFinish={onFinish}
    >

      <Button onClick={() => login()} className='google-bottom'>
        <FcGoogle /> Acceder con google
      </Button>

      <Divider style={{ borderColor: '#BDBDBD', margin: '32px 0' }}>O también</Divider>

      {currentState === state.LOGIN_ERROR && (
        <Form.Item>
          <Alert
            message='El correo o la contraseña son incorrectas'
            type='error'
          />
        </Form.Item>
      )}
      <Form.Item
        name='username'
        rules={[
          {
            required: true,
            message: 'Ingresa tu correo electrónico'
          }
        ]}
        style={{
          marginBottom: 24
        }}
        className='input-transparent'
      >
        <Input
          size='large'
          // prefix={<UserOutlined className='site-form-item-icon' />}
          placeholder='Email'
          className='input-transparent'
        />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            message: 'Ingresa tu contraseña'
          }
        ]}
        style={{
          marginBottom: 17
        }}
      >
        <Input.Password
          size='large'
          // prefix={<LockOutlined className='site-form-item-icon' />}
          type='password'
          placeholder='Contraseña'
          string=''
          className='input-transparent'
        />
      </Form.Item>
      <Form.Item>
        {/* <Form.Item name='remember' valuePropName='checked' noStyle>
          <Checkbox>Keep me signed in</Checkbox>
        </Form.Item> */}

        <a className='byp-color-red' href='/forgot-password'>
          ¿Olvidaste tu contraseña?
        </a>
      </Form.Item>

      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          size='large'
          type='primary'
          htmlType='submit'
          className='login-form-button'
          style={{ fontSize: '12px', height: '38px' }}
        >
          Continuar
        </Button>
      </Form.Item>

      <div className='form-items-container'>
        <a href='/register/$2y$10$DwiblQ' className='byp-color-red'>
          <span style={{ color: 'black' }}>¿No tienes una cuenta? </span> Regístrate aquí
        </a>
      </div>

    </Form>
  )
}

export { LoginForm }
