import { Col, DatePicker, Form, InputNumber, Row } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import moment from 'moment'

import 'moment/locale/es-mx'
import locale from 'antd/es/date-picker/locale/es_ES'

function SelectLodgingStartAndDuration({ program }) {
  const { service, formConfig, setFormConfig } = useQuoter()
  const form = Form.useFormInstance()
  const startEventSelected = Form.useWatch('startEvent', form)

  const disabledDate = (current) => {
    // If enable all dates variable, only disable past dates
    const enableAll = formConfig.enableAllDates ?? false
    if (enableAll) {
      const getDayStartLodgingH = service.getDayStartLodging()
      const dayH = moment(current).isoWeekday()
      const disabledH = dayH !== getDayStartLodgingH
      return disabledH || (current < moment().endOf('day'))
    }

    // Disable custom dates
    const startDate = moment(startEventSelected)
    const day = moment(current).isoWeekday()
    const getDayStartLodging = service.getDayStartLodging()
    const disabled = day !== getDayStartLodging
    return disabled || (current.diff(startDate, 'days') >= 0 || current.diff(startDate, 'days') < -7)
  }

  const handleOnChangeDuration = (nWeeks) => {
    setFormConfig({
      ...formConfig,
      lodgingWeeks: nWeeks
    })
    // lodgingExtraStart: service.getLodgingEnd()
  }
  const isHighSeason = !!document.getElementById('IsHighSeason')
  const EndString = !!document.getElementById('EndString')

  return (
    <>
      {
        service.includeLodging() && (
          // <Row gutter={12} style={{ marginTop: '10px' }} align='middle'>
            <div style={{ marginTop: '10px', display: "flex", flexDirection: "column", gap: 0 }}>
            <label style={{ fontWeight: '600', padding: '0 6px 12px' }}>Fechas de Hospedaje</label>
            <Col className='antd-date'>
              <div className='cotizacion__start-end'>
                <Col>
                  <label>Llegada al Hospedaje</label>
                  <Form.Item
                    name='lodgingStart'
                    rules={[{
                      required: true,
                      message: 'Field required'
                    }]}
                  // label='Inicio'
                  >
                    <DatePicker
                      locale={locale}
                      disabledDate={program !== 'graduate' ? disabledDate : null}
                      onChange={(start) => {
                        setFormConfig({
                          ...formConfig,
                          lodgingStart: start
                        })
                      }}
                      format='dddd, MMMM DD, YYYY'
                      style={{ marginBottom: '10px' }}
                      className='quote__border'
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <label>Duración del Hospedaje</label>
                  <Form.Item
                    // label='Duración'
                    name='lodgingWeeks'
                    rules={[{
                      required: true,
                      message: 'Field required'
                    }]}
                  >
                    <InputNumber
                      placeholder='1'
                      className='school-search__item quotation__inputnumber'
                      style={{ marginBottom: '10px' }}
                      // className='quote__border'
                      min={0}
                      max={formConfig?.weeks}
                      onChange={handleOnChangeDuration}
                      addonAfter='Semanas'
                    />
                  </Form.Item>
                </Col>
              </div>
            </Col>
            {/* <Col xs={8} md={9}>
              <Form.Item
                name='lodgingStart'
                rules={[{
                  required: true,
                  message: 'Field required'
                }]}
                label='Inicio'
              >
                <DatePicker
                  locale={locale}
                  disabledDate={program !== 'graduate' ? disabledDate : null}
                  onChange={(start) => {
                    setFormConfig({
                      ...formConfig,
                      lodgingStart: start
                    })
                  }}
                />
              </Form.Item>
              <p style={{ marginBottom: '0', marginTop: '.5rem' }}><i>End: {service.getLodgingEndString()}</i></p>
            </Col>
            <Col xs={8} md={9}>
              <Form.Item
                label='Duración'
                name='lodgingWeeks'
                rules={[{
                  required: true,
                  message: 'Field required'
                }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder='1'
                  min={0}
                  onChange={handleOnChangeDuration}
                />
              </Form.Item>
            </Col>
            <Col xs={8} md={6}>
              <Tag style={{ padding: '.44rem' }}>Semanas</Tag>
            </Col> */}
            <Col span={24}>
              {service.getLodgingEndString()
                ? (<p style={{ marginBottom: '0', marginTop: '.5rem', fontSize: '0.75rem' }}>Fin del Hospedaje: {service.getLodgingEndString()}</p>)
                : (EndString && (
                  <p style={{ marginBottom: '0', marginTop: '.5rem', fontSize: '0.75rem' }}>
                    <br />
                  </p>
                  )
                  )}
              {isHighSeason && (<>&nbsp;</>)}
            </Col>
          </div>
        )
      }
    </>
  )
}

export { SelectLodgingStartAndDuration }
