import { Form, Select, Statistic, Switch, Tooltip } from 'antd'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { MinorCostsSection } from './MinorCostsSection'
import { RowQuoter } from './RowQuoter'
import { calculateHsCost } from '../utils/calculateCost'
import { getDiscountSymbol, handlePrice } from '../../../utils/getDiscountSymbol'
import { useEffect } from 'react'
// import { formatStringDate } from '../../../utils/formatStringDate'
import { IconPromocion } from '../../../common/assets/svg/icons'

const { Option } = Select

function FieldCourse() {
  const { course, service, setFormConfig, formConfig, isLoadingOffer, prices } = useQuoter()

  const handleChange = (value, id, cost, frequency) => {
    let courseHSFees = []
    if (!formConfig.courseHSFees) {
      setFormConfig({
        ...formConfig,
        courseHSFees: []
      })
    }
    if (value) {
      courseHSFees = formConfig.courseHSFees ?? []
      courseHSFees?.push({
        id,
        cost,
        frequency
      })
    } else {
      formConfig.courseHSFees?.map((fee) => (
        fee.id !== id ? courseHSFees.push(fee) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      courseHSFees
    })
  }

  useEffect(() => {
    if (course && !isLoadingOffer) {
      const loadInitialData = async () => {
        if (!formConfig.courseHSFees) {
          setFormConfig({
            ...formConfig,
            courseHSFees: []
          })
        }
        const courseCosts = formConfig.courseHSFees ?? []
        try {
          const hs = service.getHighSeasonFees()
          if (hs) {
            for (const item of hs) {
              for (const fee of item.fees) {
                if (fee.mandatory) {
                  courseCosts.push({
                    id: fee.id,
                    cost: undefined,
                    frequency: undefined
                  })
                }
              }
            }
            setFormConfig(prevFormConfig => ({
              ...prevFormConfig,
              courseHSFees: courseCosts
            }))
          }
        } catch (error) {
          console.error('Error loading initial data:', error)
        }
      }
      loadInitialData()
    }
  }, [formConfig.weeks, prices])

  return (
    <>
      <RowQuoter
        colA={
          <>
            <Form.Item
              label={
                <span>
                  {service.getDiscountsInKindGoods().length > 0 ||
                    (service.courseHasDiscount() && service.getCourseDiscounts().length > 0) ||
                    service.getHighSeasonFees().some((hs) => hs.fees.length > 0) ? (
                    <Tooltip title={
                      <>
                        {service.getDiscountsInKindGoods()?.map(d => (
                          <div key={d.id}>
                            <p className='info-cost__discount w-100'>
                              {d.translations?.find(t => t.to === 'es')?.translation ?? d.name}
                            </p>
                            <p className='info-cost__discount w-100'>Descuento valor monetario {handlePrice(d.value, service.getCourseAmount())} {service.getCurrency()} en Colegiatura</p>
                          </div>
                        ))}
                        {service.courseHasDiscount() && service.getCourseDiscounts()?.map((d, index) => (
                          <div key={d.id}>
                            <p className='info-cost__discount w-100'>
                              {d.translations?.find(t => t.to === 'es')?.translation ?? d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())}  en Colegiatura
                            </p>
                            <p className='info-cost__discount w-100'>Descuento valor monetario {handlePrice(d.value, service.getCourseAmount())} {service.getCurrency()} en Colegiatura</p>
                          </div>
                        ))}
                      </>
                    } placement="bottomLeft" color='white'>
                      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <IconPromocion style={{ verticalAlign: 'middle' }} />
                        {" "}Course
                      </span>
                    </Tooltip>
                  ) : (
                    <span style={{ verticalAlign: 'middle' }}>
                      Curso
                    </span>
                  )}
                </span>
              } style={{ marginBottom: '2px' }}>
              <Select defaultValue={course?.offer?.id} name='course' size='small'
                dropdownMatchSelectWidth={false}
                >
                <Option key={course?.offer?.id} value={course?.offer?.id}>
                  <div style={{ whiteSpace: 'normal', display: 'block' }}>
                    {course?.offer?.translations?.find(t => t.to === 'es')?.translation ?? course?.offer?.name}
                  </div>
                </Option>
              </Select>
            </Form.Item>
          </>
        }
        colB={
          <>
            <Statistic
              prefix='$'
              precision={2}
              value={`${service.getCourseAmount()}`}
              // suffix={`${service.getCurrency()}`}
            />
            {service.getDiscountsInKindGoods().length > 0 && (
              service.getDiscountsInKindGoods()?.map((d, index) => (
                <Statistic
                  key={index}
                  prefix='$'
                  className='price-after text-discount'
                  precision={2}
                  value={`${d.type === 'amount' ? '-' + d.value : '-' + handlePrice(d.value, service.getCourseAmount())}`}
                  // suffix={`${service.getCurrency()}`}
                />
              ))
            )}
            {service.courseHasDiscount() && service.getCourseDiscounts()?.map((d, index) => (
              <Statistic
                key={index}
                prefix='$'
                className='price-after text-discount'
                precision={2}
                value={`${d.type === 'amount' ? '-' + d.value : '-' + handlePrice(d.value, service.getCourseAmount()) + ' '}`}
                // suffix={`${service.getCurrency()}`}
              />
            ))}
            {/*  service.getHighSeasonFees().map((hs) => (
              hs.fees.map((fee) => (
                <StatisticWithDiscounts
                  key={fee.id}
                  discounts={[]}
                  amount={
                    service.isHighSeasonFeeChecked(fee.id)
                      ? calculateHsCost(fee.cost, fee.frequency, hs.weeks, hs.daysOff)
                      : 0
                  }
                  currency={`${service.getCurrency()}`}
                  subtotal={
                    service.isHighSeasonFeeChecked(fee.id)
                      ? calculateHsCost(fee.cost, fee.frequency, hs.weeks, hs.daysOff)
                      : 0
                  }
                />
              ))
            )) */}
          </>
        }
        colC={
          <>
            {service.getHighSeasonFees().map((hs) => (
              hs.fees.map((fee) => (
                <Switch
                  key={fee.id}
                  onChange={(value) => { handleChange(value, fee.id) }}
                  className='byp-ml-1'
                  checked={service.isHighSeasonFeeChecked(fee.id)}
                />
              ))
            ))}
          </>
        }
      />
      {/* Peak Seasons */}
      {service.getHighSeasonFees().map((hs) => (
        hs.fees.map((fee) => (
          <RowQuoter
            key={fee.id}
            colA={
              <>
                {fee.translations?.find(t => t.to === 'es')?.translation ?? fee.name + ' ' + hs.weeks + ' Semanas'} {hs.daysOff > 0 && (' más ' + hs.daysOff + ' días')}
              </>
            }
            colB={
              <>
                <StatisticWithDiscounts
                  key={fee.id}
                  discounts={[]}
                  amount={calculateHsCost(fee.cost, fee.frequency, hs.weeks, hs.daysOff)}
                  currency={`${service.getCurrency()}`}
                  subtotal={calculateHsCost(fee.cost, fee.frequency, hs.weeks, hs.daysOff)}
                />
              </>
            }
          />
        ))
      ))}
      <MinorCostsSection />
    </>
  )
}

export { FieldCourse }
