import { Form, Select, Statistic, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { StatisticWithDiscountsPackage } from "../../../common/components/StatisticWithDiscountsPackage";
import { RowQuoter } from "../../quote-course/components/RowQuoter";
// import { format } from 'date-fns'
import {
  getDiscountSymbol,
  handlePrice,
} from "../../../utils/getDiscountSymbol";
import { usePackage } from "../../../providers/package/package-course-context";
import { discountsApplied } from "../../../common/components/StatisticWithDiscounts";
// import { formatStringDate } from '../../../utils/formatStringDate'

const { Option } = Select;

function OtherSection() {
  const [selected, setSelected] = useState();
  const { service, formConfig, setFormConfig } = usePackage();

  const handleChange = (value, id, cost, frequency) => {
    let fees = [];
    if (!formConfig.fees) {
      setFormConfig({
        ...formConfig,
        fees: [],
      });
    }
    if (value) {
      fees = formConfig.fees ?? [];
      fees?.push({ id, cost, frequency });
    } else {
      formConfig.fees?.map((fee) => (fee.id !== id ? fees.push(fee) : null));
    }
    setFormConfig({
      ...formConfig,
      fees,
    });
  };

  const handleChangeSwitch = (included) => {
    setFormConfig({
      ...formConfig,
      insurance: included,
    });
  };

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      healthInsuranceId: selected,
    });
  }, [selected]);

  return (
    <div>
      {service.getArrayHealtInsurance()?.length > 0 && (
        <RowQuoter
          colA={
            <div  style={{ width: "100%"}}>
              <p className="w-100">Añadir seguro</p>
              {service.includeInsurance() && (
                <div style={{ width: "100%"}}>
                  <Form.Item
                    style={{ width: "100%"}}
                    label="Insurance name"
                    name="health_insurance"
                    className="byp-mb-0"
                    rules={[
                      {
                        required: true,
                        message: "Field required",
                      },
                    ]}
                  >
                    <Select
                      dropdownMatchSelectWidth={false}
                      allowClear
                      placeholder="Insurance name"
                      onChange={setSelected}
                      size="small"
                    >
                      {service.getArrayHealtInsurance().map((itm) => {
                        return (
                          <Option key={itm.id} value={itm.id}>
                            {itm.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {/* {
                      // Insurance description
                      <p>{service.getInsuranceSelected()?.description}</p>
                    } */}

                  {service.getInsuranceDiscounts().length > 0 &&
                    service.getInsuranceDiscounts()?.map((d) => (
                      <p key={d.id} className="byp-fw-bold">
                        {d.name}{" "}
                        {getDiscountSymbol(
                          d.value,
                          d.type,
                          service.getCurrency()
                        )}{" "}
                        en la Cuota de inscripción.
                      </p>
                    ))}
                </div>
              )}
            </div>
          }
          colB={
            <Statistic
              prefix=""
              precision={2}
              value={`${service.getAmountHealthInsurance()?.toFixed(2)}`}
            />
          }
          colC={
            <Switch
              onChange={handleChangeSwitch}
              checked={service.includeInsurance()}
            />
          }
        />
      )}
      {service.getInsuranceDiscounts().length > 0 &&
        service.getInsuranceDiscounts()?.map((d, index) => (
          <RowQuoter
            key={index}
            colA={
              <p key={d.id} className="byp-fw-bold">
                {d.name}{" "}
                {getDiscountSymbol(d.value, d.type, service.getCurrency())} en
                Seguros.
              </p>
            }
            colB={
              <Statistic
                prefix=""
                className="price-after"
                precision={2}
                value={`${d.type === "amount" ? d.value : handlePrice(d.value, service.getAmountInscription()?.toFixed(2))}`}
                // suffix={`${service.getCurrency()}`}
              />
            }
          />
        ))}
      {service.getPrices()?.extras?.map((item) => {
        return item.name !== "Registration Fee" ? (
          <RowQuoter
            key={item.id}
            colA={
              <>
                <p>{item.name}</p>
                <p className="description-cost">{item.description}</p>
              </>
            }
            colB={
              <StatisticWithDiscountsPackage
                discounts={service.getFeeDiscounts(item.id)}
                amount={service.getFeeCost(item.id)?.toFixed(2)}
                currency={service.getCurrency()}
                subtotal={service.getFeeSubtotal(item.id)}
                active={service.isChecked(item.id)}
              />
            }
            colC={
              <Switch
                onChange={(value) => {
                  handleChange(value, item.id, item.cost, item.frequency);
                }}
                className="byp-ml-1"
                checked={service.isChecked(item.id)}
                // checkedChildren='YES'
                // unCheckedChildren='NO'
              />
            }
          />
        ) : null;
      })}
    </div>
  );
}

export { OtherSection };
