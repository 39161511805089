import { Checkbox, Col, Form, Row, Select } from 'antd'
import { useState, useEffect } from 'react'
import { useGetAllBranches } from '../../../../bussines/hooks/agency/useGetAllBranches'
const { Option } = Select

function SelectBranchesAll ({ name, onChange, single, allowClear, form, rules, officesOfUser, isAgencyOffice, filters, isReports, isDisabled, ...props }) {
  const [checkAllBranch, setCheckAllBranch] = useState(false)
  const _name = name ?? 'branches'
  const { isLoading, data } = useGetAllBranches(filters)

  const onCheckAllChangeBranch = (e) => {
    setCheckAllBranch(e?.target?.checked)
  }

  useEffect(() => {
    if (data && !isAgencyOffice) {
      const ids = data.map(c => (c.id))
      checkAllBranch ? form.setFieldsValue({ [_name]: ids }) : form.setFieldsValue({ [_name]: [] })
    }
  }, [checkAllBranch, data])

  return (
    <>
      <Row>
        {props.label && (
          <Col style={{ paddingTop: '12px' }} xs={24} md={isReports ? 24 : 5} className={isReports ? 'title-multi-select' : ''}>
            {props.label + ':'}
          </Col>
        )}

        <Col xs={24} md={isReports ? 24 : props.label ? 19 : 24} className={isReports ? 'multi-select' : ''}>
          <Row className='byp-dashboard-header byp-mb-1'>

            <Col xs={24} md={6} className='byp-justify-centered'>
              <Form.Item className='byp-m-0-important prefix' name={name + 'all'} valuePropName='checked'
              >
                <Checkbox onChange={onCheckAllChangeBranch} disabled={isAgencyOffice || isDisabled}>
                  {(props.label && !isReports) && 'All ' + name}
                  {!props.label && 'All '}
                  {isReports && 'All'}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={18} className='byp-justify-centered'>
              <Form.Item
                name={_name}
                rules={rules}
                className='byp-m-0-important'
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder=''
                  loading={isLoading}
                  mode='multiple'
                  maxTagCount='responsive'
                  className='byp-m-0-important'
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                  onChange={onChange}
                  disabled={isAgencyOffice || isDisabled}
                  bordered={false}
                >
                  {data?.map(item => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}

                </Select>

              </Form.Item>
            </Col>

          </Row>
        </Col>
      </Row>

    </>
  )
}

export { SelectBranchesAll }
