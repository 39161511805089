import { Card, Col, Image, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { image } from '../../../utils/fallbackImage'

import {
  CollegeIcon,
  IdiomaIcon,
  IntakeIcon,
  // LocationIcon,
  ModalityIcon
} from '../../quote-preview/assets/imgs/Icons'
import { FaClock } from 'react-icons/fa6'
// import { IoIosSchool } from 'react-icons/io'
// import { FaCloud, FaRegSnowflake } from 'react-icons/fa'
// import { LuDroplets } from 'react-icons/lu'
import { IoCalendarSharp } from 'react-icons/io5'
import { courseLanguage, modalityCourse, programIntakes, programLenght, studyArea, studyMethod } from '../../../languageConfiguration/infoCourseTranslate'
// import { GiTeacher } from 'react-icons/gi'

const { Title } = Typography

const InfoQuoteCourse = ({ course, skills, requirements, extraDescription, characteristics }) => {
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)

  const handleTextTranslate = (item, value) => {
    if (item?.translationables && item?.translationables?.length > 0) {
      const itemTranslate = item?.translationables?.find(i => i?.to === 'es')
      return itemTranslate?.translation
    } else {
      return value
    }
  }

  const handleTranslateStudy = (studies) => {
    const newStudy = []
    studies?.forEach(item => {
      if (studyArea.es[item]) {
        newStudy.push(studyArea.es[item])
      } else {
        newStudy.push(item)
      }
    })
    return newStudy?.join(', ')
  }

  const handleTranslateIntake = (intakes) => {
    const newIntakes = []
    intakes?.forEach(item => {
      if (programIntakes.es[item]) {
        newIntakes.push(programIntakes.es[item])
      } else {
        newIntakes.push(item)
      }
    })
    return newIntakes?.join(', ')
  }

  useEffect(() => {
    if (course) {
      const item = course?.offer
      if (item?.translations && item?.translations?.length > 0) {
        const label = item?.translations?.find(item => item?.to === 'es' && item?.attribute === 'label')
        setTitle(label?.translation)
        // ** This shows the first description
        // const description = item?.translations?.find(item => item?.to === 'es' && item?.attribute === 'description')
        // setDescription(description?.translation)
        // ** This show the extra description
        const description = extraDescription?.translationables?.find(item => item?.to === 'es')
        setDescription(description?.translation)
      } else {
        setTitle(item?.name)
        setDescription(item?.description)
      }
    }
  }, [course])

  return (
    <Card style={{ height: '100%' }}>
      <Row>
        <Col xs={24} md={19} lg={19} className='info-quote__campus byp-mb-1 '>
          <h1>{title}</h1>
        </Col>
        <Col span={5} className='school-logo'>
          <Image
            src={course?.offer?.campus?.profilePhotoUrl ?? image}
            style={{ width: '3.75rem', aspectRatio: '1' }}
            fallback={image}
          />
        </Col>
      </Row>
      <p className='quote-byp__mb'>
        <span>Escuela: </span>
        {course?.offer?.campus?.name}
        {/* <LocationIcon /> {course?.offer?.campus?.address} */}
      </p>
      <br />

      {(course?.offer?.program?.name !== 'academic_years' && course?.offer?.program?.name !== 'boarding_schools') && (
        <>
          {skills?.length > 0 && <h3>Habilidades que obtendrás</h3>}
          <Row gutter={[10, 10]} className='quote-byp__mb'>
            {skills?.map((skill) => (
              <Col key={skill.id}>
                <div className='quote-byp__habilidades'>{handleTextTranslate(skill, skill?.name)}</div>
              </Col>
            ))}
          </Row>

          {requirements?.length > 0 && <h3>Requisitos</h3>}
          <ul className='quote-byp__mb quote-byp__list'>
            {requirements?.map((requirement) => (
              <li key={requirement.id}>{handleTextTranslate(requirement, requirement?.value)}</li>
            ))}
          </ul>

          {requirements?.length <= 0 && (
            <>
              <h3>Requisitos</h3>
              Ninguno
              <br /><br />
            </>
          )}

          {description && <h3>Descripción</h3>}
          <p className='quote-byp__mb quote-byp__description'>{description}</p>

          {characteristics?.length > 0 && <h3>Caracteristicas de curso</h3>}
          <ul className='quote-byp__mb quote-byp__list' style={{ marginBottom: '1rem' }}>
            {characteristics?.map((characteristic) => (
              <li key={characteristic.id}>{handleTextTranslate(characteristic, characteristic?.value)}</li>
            ))}
          </ul>
          {/* {targets?.length <= 0 && (
            <>
              <h3>¿Para quién es este curso?</h3>
              Para todos
              <br />
            </>
          )} */}

          {/* Icons */}
          <Row gutter={[16, 24]}>
            {course.offer.study_method && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <FaClock />
                  <span>Método de Estudio</span>
                  <Title level={5} style={{ textTransform: 'capitalize' }}>
                    {studyMethod.es[course.offer.study_method] ? studyMethod.es[course.offer.study_method] : course.offer.study_method}
                  </Title>
                </div>
              </Col>
            )}
            {(course.offer?.study_area && course.offer?.study_area?.length > 0) && Array.isArray(course.offer?.study_area) && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <CollegeIcon />
                  <span>Área de Estudio</span>
                  <Title level={5} ellipsis={{ rows: 2 }} style={{ textAlign: 'center' }}>{handleTranslateStudy(course.offer?.study_area)}</Title>
                </div>
              </Col>
            )}
            {course.offer.intake && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <IntakeIcon />
                  <span>Temporada de inicio</span>
                  {Array.isArray(course.offer.intake) && (
                    <Title level={5} ellipsis={{ rows: 5 }} style={{ textAlign: 'center' }}>{handleTranslateIntake(course.offer.intake)}</Title>
                  )}
                  {!Array.isArray(course.offer.intake) && (
                    <Title level={5} ellipsis={{ rows: 5 }} style={{ textAlign: 'center' }}>{programIntakes.es[course.offer.intake] ? programIntakes.es[course.offer.intake] : course.offer.intake}</Title>
                  )}
                </div>
              </Col>
            )}
            {course.offer.length && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <IoCalendarSharp />
                  <span>Duración</span>
                  <Title level={5}>{course.offer.length} {programLenght.es[course.offer.time] ? programLenght.es[course.offer.time] : course.offer.time}</Title>
                </div>
              </Col>
            )}
            {course.offer.modality && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <ModalityIcon />
                  <span>Modalidad</span>
                  <Title level={5}>{modalityCourse.es[course.offer.modality] ? modalityCourse.es[course.offer.modality] : course.offer.modality}</Title>
                </div>
              </Col>
            )}
            {(course.offer.language && course.offer.language?.length > 0) && Array.isArray(course.offer.language) && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <IdiomaIcon />
                  <span>Idioma</span>
                  <Title level={5}>{course.offer.language.map((lang) => { if (courseLanguage.es[lang.name]) { return courseLanguage.es[lang.name] } return lang.name }).join(', ')}</Title>
                </div>
              </Col>
            )}

          </Row>
        </>
      )}

      {(course?.offer?.program?.name === 'academic_years' || course?.offer?.program?.name === 'boarding_schools') && (
        <>
          {description && <h3>Descripción</h3>}
          <p className='quote-byp__mb quote-byp__description'>{description}</p>

          {skills?.length > 0 && <h3>Habilidades que obtendrás</h3>}
          <Row gutter={[10, 10]} className='quote-byp__mb'>
            {skills?.map((skill) => (
              <Col key={skill.id}>
                <div className='quote-byp__habilidades'>{handleTextTranslate(skill, skill?.name)}</div>
              </Col>
            ))}
          </Row>

          {requirements?.length > 0 && <h3>Requisitos</h3>}
          <ul className='quote-byp__mb quote-byp__list'>
            {requirements?.map((requirement) => (
              <li key={requirement.id}>{handleTextTranslate(requirement, requirement?.value)}</li>
            ))}
          </ul>

          {requirements?.length <= 0 && (
            <>
              <h3>Requisitos</h3>
              Ninguno
              <br /><br />
            </>
          )}

          {/* Icons */}
          <Row gutter={[16, 24]}>
            {course.offer.study_method && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <FaClock />
                  <span>Método de Estudio</span>
                  <Title level={5} style={{ textTransform: 'capitalize' }}>
                    {studyMethod.es[course.offer.study_method] ? studyMethod.es[course.offer.study_method] : course.offer.study_method}
                  </Title>
                </div>
              </Col>
            )}
            {(course.offer?.study_area && course.offer?.study_area?.length > 0) && Array.isArray(course.offer?.study_area) && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <CollegeIcon />
                  <span>Área de Estudio</span>
                  <Title level={5} ellipsis={{ rows: 2 }} style={{ textAlign: 'center' }}>{handleTranslateStudy(course.offer?.study_area)}</Title>
                </div>
              </Col>
            )}
            {course.offer.intake && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <IntakeIcon />
                  <span>Intake</span>
                  {Array.isArray(course.offer.intake) && (
                    <Title level={5} ellipsis={{ rows: 5 }} style={{ textAlign: 'center' }}>{handleTranslateIntake(course.offer.intake)}</Title>
                  )}
                  {!Array.isArray(course.offer.intake) && (
                    <Title level={5} ellipsis={{ rows: 5 }} style={{ textAlign: 'center' }}>{programIntakes.es[course.offer.intake] ? programIntakes.es[course.offer.intake] : course.offer.intake}</Title>
                  )}
                </div>
              </Col>
            )}
            {course.offer.length && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <IoCalendarSharp />
                  <span>Duración</span>
                  <Title level={5}>{course.offer.length} {programLenght.es[course.offer.time] ? programLenght.es[course.offer.time] : course.offer.time}</Title>
                </div>
              </Col>
            )}
            {course.offer.modality && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <ModalityIcon />
                  <span>Modalidad</span>
                  <Title level={5}>{modalityCourse.es[course.offer.modality] ? modalityCourse.es[course.offer.modality] : course.offer.modality}</Title>
                </div>
              </Col>
            )}
            {(course.offer.language && course.offer.language?.length > 0) && Array.isArray(course.offer.language) && (
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className='quote-byp__icon-card byp-centered byp-mt-1'>
                  <IdiomaIcon />
                  <span>Idioma</span>
                  <Title level={5}>{course.offer.language.map((lang) => { if (courseLanguage.es[lang.name]) { return courseLanguage.es[lang.name] } return lang.name }).join(', ')}</Title>
                </div>
              </Col>
            )}

          </Row>
        </>
      )}
    </Card>
  )
}

export { InfoQuoteCourse }
