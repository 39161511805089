import { Button, Card, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { AirPlaneIcon, EditIcon, TeamIcon } from '../../quote-preview/assets/imgs/Icons'
import { ArrowRightOutlined } from '@ant-design/icons'
import { CourseOpinions } from './CourseOpinions'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { useGetOpinions } from '../../../bussines/hooks/opinion/useGetOpinions'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { HomeCarrousel } from '../../../common/components/HomeCarrousel'
import { CardCourse } from '../../dashboards/blog/components/CardCourse'
import { useGetOfferRecomendations } from '../../../bussines/hooks/offer/useGetOfferRecomendations'
import { showErrorModal } from '../../../utils/errorModal'
import { Link } from 'react-router-dom'
// import { URL_OPINION_PAGE } from '../../opinion/OpinionPage'
// import { URL_REGISTER_PAGE_BETA } from '../../register'
// import { saveInStorage } from '../../../utils/localStorageUtils'
import { IconSchool } from '../../../common/assets/svg/icons'

export const FooterQuote = ({ setHasComments, hasComments }) => {
  const { course, setOpinionsData } = useQuoter()
  const [filters, setFilters] = useState({ limit: 6 })
  const [recomendationFilters] = useState({
    distinctCourse: true,
    program: course?.offer?.program?.name
  })
  const [image, setImage] = useState()
  const [text, setText] = useState()
  const [link, setLink] = useState()
  const { isLoading, data, error, isError } = useGetOpinions(course?.offer?.campus?.id, undefined, filters)
  const { data: offers } = useGetOfferRecomendations(recomendationFilters)
  const { user: _user } = useAuth()
  // const navigate = useNavigate()1

  useEffect(() => {
    if (!isLoading && data) {
      setOpinionsData(data)
    } else if (!isLoading && isError) {
      showErrorModal(error?.response?.data?.message)
    }
  }, [isLoading])

  useEffect(() => {
    if (data) {
      if (data?.opinions?.length > 0) setHasComments(true)
    }
  }, [data])

  function addHttpsIfNotExist (url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    return url
  }

  // const handleOpinionRedirection = () => {
  //   const opinionPage = URL_OPINION_PAGE.replace(':campusSlug', course?.offer?.campus?.slug).replace(':courseId', course?.offer?.id)
  //   saveInStorage('redirectOnLoginSucces', opinionPage)
  //   navigate(URL_REGISTER_PAGE_BETA)
  // }

  useEffect(() => {
    if (_user && _user?.publicityHorizontal && _user?.publicityHorizontal?.length > 0) {
      if (_user?.publicityHorizontal[0]?.title) setText(_user?.publicityHorizontal[0]?.title)
      if (_user?.publicityHorizontal[0]?.src) setImage(_user?.publicityHorizontal[0]?.src)
      if (_user?.publicityHorizontal[0]?.link) setLink(addHttpsIfNotExist(_user?.publicityHorizontal[0]?.link))
    }
  }, [_user])

  return (
    <>
      <div style={{ background: 'transparent', paddingLeft: '2.5rem', paddingRight: '2.5rem' }}>

        <h4 style={{ color: '#373737', marginTop: '10px' }} className='title-with-icon'><IconSchool />Nuestra recomendación de cursos</h4>
        <p style={{ color: '#797979' }}>Descubre el mundo estudiando</p>
      </div>
      <HomeCarrousel slides={offers?.data ?? []} Slide={CardCourse} className='quotation__footer-carrousel big-home__package edit_carousel edit_page-country' slidesPerRow={4} />
      {offers?.data && (
        <div className='quotation__footer-carrousel-responsive'>
          <div>
            {offers?.data?.map(item => (
              <CardCourse item={item} key={item.id} />
            ))}
          </div>
        </div>
      )}
      <Card className='quote-byp__new_search show_mobile hide_pc'>
        <h2 className='byp-m-0'>Empezar de nuevo</h2>
        <Button><EditIcon /> <Link style={{ color: '#797979' }} to='/home'>Nueva Búsqueda</Link></Button>
      </Card>

      {hasComments && (
        <>
          <h2 className='opiniones__title' style={{ paddingTop: '30px' }}><TeamIcon /> Opiniones del curso</h2>
          <p className='opiniones__description byp-mb-1'>No te vayas sin saber que opinan</p>
          <CourseOpinions
            opinions={data?.opinions ?? []}
            rates={data?.rates ?? []}
            filters={filters}
            setFilters={setFilters}
            isLoading={isLoading}
          />
        </>
      )}
      <Row gutter={[38, 38]} className='byp-mb-1'>
        <></>
      </Row>

      {image && (
        <a href={link} target='_blank' rel='noreferrer'>
          <div className='travel__footer' style={{ backgroundImage: `url(${image})` }}>
            <AirPlaneIcon />
            <div>
              <h4>{text ?? 'Discover the world, and learn in the process'}</h4>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p className='byp-m-0'>1,925 different course</p>
                <ArrowRightOutlined />
              </div>
            </div>
          </div>
        </a>
      )}
    </>
  )
}
