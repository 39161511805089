const adminRoles = ['agency-admin', 'institution-admin', 'education-group-admin']

const superAdminRoles = ['super-admin', 'super-editor']
const institutionRoles = ['institution-admin', 'institution-manager', 'institution-marketer']
const educationGroupRoles = ['education-group-admin']
const agencyRoles = ['agency-admin', 'agency-manager', 'agency-consultant', 'agency-editor', 'agency-office-manager']

const bypUser = 'byp-client'
const userRole = 'user'
// const bypGeneralUser = 'byp-client'

const allRoles = [
  ...superAdminRoles,
  ...agencyRoles,
  ...institutionRoles,
  ...educationGroupRoles,
  bypUser,
  userRole
]

const userAndRoles = [
  {
    name: 'Super Admin',
    roles: superAdminRoles
  },
  {
    name: 'Agency',
    roles: ['agency-admin', 'agency-manager', 'agency-consultant', 'agency-editor', 'agency-office-manager']
  },
  {
    name: 'Institution',
    roles: institutionRoles
  },
  {
    name: 'Education group',
    roles: educationGroupRoles
  }
]

export {
  userAndRoles,
  allRoles,
  adminRoles,
  superAdminRoles,
  agencyRoles,
  institutionRoles,
  educationGroupRoles,
  bypUser
}
