import React from 'react'
import { useQuote } from '../../bussines/hooks/quote/useQuote'
import { useParams } from 'react-router-dom'
import { Quote } from './components/Quote'
import LottieCargando from '../../lotties/LottieCargando'
import { ErrorBoundary } from '../../providers/validation/ErrorBoundary'
// import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'

const URL_QUOTE_PREVIEW = '/quote-preview/:id'

const QuotePreview = () => {
  const { id } = useParams()
  const { isLoading, data: quote } = useQuote(id)

  if (isLoading) {
    return <LottieCargando />
  }

  return (
    <ErrorBoundary>
      <Quote quote={quote} />
    </ErrorBoundary>
  )
}

export { QuotePreview, URL_QUOTE_PREVIEW }
