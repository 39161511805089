import { Button, Modal, Row, notification } from 'antd'
import { CollectionCard } from './CollectionCard'
import { HeartOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { IconDocumentAdd } from '../assets/svg/icons'
import { ModalCreateCollection } from './ModalCreateCollection'
import { useGetCollections } from '../../bussines/hooks/collections/useGetCollections'
import { useAddItemToCollection } from '../../bussines/hooks/collections/useAddItemToCollection'
import { useNavigate } from 'react-router-dom'
import { saveInStorage } from '../../utils/localStorageUtils'
import { URL_REGISTER_PAGE_BETA } from '../../pages/register'

const buttonProps = {
  style: {},
  text: 'Añadir a favoritos',
  className: 'quote-byp__btn-add',
  icon: <HeartOutlined />
}

const ModalAddToCollection = ({ itemId, onSave, isPublicUser, button = buttonProps, collectionItems, loadItems = true, refetchCollections, ...props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [collectionSelected, setCollectionSelected] = useState()
  const { data: collections, isLoading, refetch } = useGetCollections(undefined, loadItems)
  const { addItem, isLoading: isSavingItem, currentState: itemState, state } = useAddItemToCollection()
  const navigate = useNavigate()

  const showModal = () => {
    if (isPublicUser) {
      const pathName = '/favorites'
      saveInStorage('redirectOnLoginSucces', pathName)
      const actionOnLoginSucces = {
        name: 'save-in-favorites',
        item: itemId
      }
      saveInStorage('actionOnLoginSucces', JSON.stringify(actionOnLoginSucces))
      navigate(URL_REGISTER_PAGE_BETA)
      return
    }
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleSave = () => {
    if (onSave) {
      onSave(collectionSelected)
      setIsModalOpen(false)
    } else {
      addItem(collectionSelected, { item_id: itemId })
    }
  }

  useEffect(() => {
    if (!isLoading && collections && loadItems) {
      const all = collections.data?.find((collection) => collection.name === 'Todos')
      setCollectionSelected(all?.id)
    } else if (!loadItems) {
      const all = collectionItems?.data?.find((collection) => collection.name === 'Todos')
      setCollectionSelected(all?.id)
    }
  }, [isLoading])

  useEffect(() => {
    if (!isSavingItem && itemState === state.STATE_SUCCESS) {
      notification.success({
        message: 'Favorito agregado a tu collección'
      })
      setIsModalOpen(false)
      if (loadItems) {
        refetch()
      }
      if (refetchCollections) {
        refetchCollections()
      }
    }
  }, [isSavingItem])

  return (
    <>
      <Button className={button.className} style={button.style} onClick={showModal} title='Add To Collection'>
        {button.icon} {button.text}
      </Button>
      <Modal
        title='Añadir a favoritos'
        onOk={handleOk}
        onCancel={handleCancel}
        visible={isModalOpen}
        footer={
          <>
            <Row style={{ justifyContent: 'center', marginBottom: '15px' }}>
              <Button
                style={{ border: '2px solid #5D5D5D', fontWeight: '500', alignItems: 'center', display: 'flex', gap: '4px', width: '100%', justifyContent: 'center' }}
                onClick={handleSave}
                loading={isSavingItem}
              >
                <IconDocumentAdd color='#5D5D5D' width='13px' height='13px' />
                Guardar
              </Button>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <ModalCreateCollection onFinish={() => refetch()} />
            </Row>
          </>
        }
        className='byp-modal'
      >
        <h5>Colecciones</h5>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: '15px' }}>
          {collections?.data?.map((collection) => (
            <CollectionCard
              key={collection.id}
              collection={collection}
              onClick={() => {
                setCollectionSelected(collection.id)
              }}
              className={collectionSelected === collection.id ? 'byp-card-active' : ''}
            />
          ))}
        </div>
      </Modal>
    </>
  )
}

export { ModalAddToCollection }
