import { useQuery } from 'react-query'
import { NationalityRepository } from '../../repositories/NationalityRepository'

function useGetCitiesWithOffers (filters = {}) {
  return useQuery(['useGetCitiesWithOffers', filters],
    () => NationalityRepository.citiesWithOffers(filters), {
      retry: 2
    })
}

function useGetCitiesAndCountries (filters = {}) {
  return useQuery(['useGetCitiesAndCountries', filters],
    () => NationalityRepository.getCitiesnAndCountries(filters), {
      retry: 2
    })
}

export { useGetCitiesWithOffers, useGetCitiesAndCountries }
