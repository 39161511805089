import { AutoComplete, Button, Col, DatePicker, Form, Row, Upload } from 'antd'
import SearchIcon from '@material-ui/icons/Search'
import { getBase64, headersForUpload } from '../../../../utils/uploadImageUtils'
// import { RiImageAddLine } from 'react-icons/ri'
import { logoEdit } from '../../../../utils/fallbackImage'
import { API_URL } from '../../../../config'
import { EditIcon } from '../../../quote-preview/assets/imgs/Icons'

const dateFormat = 'MM/YYYY'
export const UploadPageHeaderFormItem = ({ image, setImage, setImageId, position, repeat, size }) => {
  const handleChange = (event) => {
    if (event.file.status === 'done') {
      getBase64(event.file.originFileObj, (imageUrl) => {
        setImage(imageUrl)
        // console.log('🚀 ~ file: FormEditBlog.js:44 ~ getBase64 ~ imageUrl:', imageUrl)
        setImageId(event.file.response.id)
        // console.log('🚀 ~ file: FormEditBlog.js:46 ~ getBase64 ~ event.file.response:', event.file.response)
      })
    } else if (event.file.status === 'error') {
      console.log('🚀 ~ file error:', event.file.response)
    }
  }
  // className='edit-blog__image'
  return (
    <div>
      <label className='icon-edit-blog-title-image-page edit-home__header' htmlFor='image01__page'>
        <div
          style={{
            alignItems: 'flex-end',
            paddingBottom: '1.688rem',
            justifyContent: 'center',
            height: '19rem',
            backgroundImage: `url(${image ?? logoEdit})`,
            backgroundPosition: `${position}`,
            backgroundRepeat: `${repeat}`,
            backgroundSize: `${size}`
          }}
        >
          {/* <img src={image ?? logoEdit} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> */}
        </div>
        <div className='edit-home__icon'>
          <EditIcon />
        </div>
        <div className='edit-blog__searcher' style={{ position: 'absolute', bottom: '0', width: '100%' }}>
          <Row
            style={{
              alignItems: 'flex-end',
              justifyContent: 'center',
              margin: '9px 0',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '20px'
            }}
          >
            <Col md={10}>
              <Form.Item
                style={{ marginBottom: '0' }}
              >
                <AutoComplete
                  className='big-home-search__header'
                  size='large'
                  options={[]}
                  placeholder='¿Dónde quieres estudiar?'
                  allowClear
                  filterOption={
                    (inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={(value) => {
                    console.log('searching ', value)
                  }}
                  onSelect={(value, place) => {
                    console.log('selected ', value)
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={10} style={{ backgroundColor: 'white' }}>
              <Form.Item style={{ marginBottom: '0' }}>
                <DatePicker
                  className='big__datepicker'
                  picker='month'
                  placeholder='Cuándo'
                  format={dateFormat}
                  size='large'
                  // onChange={handleDate}
                  // disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col style={{
              backgroundColor: 'white',
              width: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderTopRightRadius: '12px',
              borderBottomRightRadius: '12px',
              height: '48px'
            }}
            >
              <Button
                style={{ width: '50px', height: '36px', padding: '5px 12px' }}
                className='byp-btn-red-50 big__btn-search'
                onClick={() => console.log('Hello!')}
              >
                <SearchIcon style={{ color: 'white' }} />
              </Button>
            </Col>
          </Row>
        </div>
      </label>

      <div style={{ display: 'none' }}>
        <Upload
          name='image'
          listType='picture-card'
          className='upload-page__img'
          showUploadList={false}
          action={`${API_URL}/pages/images`}
          id='image01__page'
          onChange={event => handleChange(event)}
          headers={headersForUpload()}
        >
          <img src={image ?? logoEdit} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
        </Upload>
      </div>
    </div>
  )
}
