import { Col, Image } from 'antd'
import ExampleImage from '../../common/assets/images/figma/Rectangle 37.png'

function CollectionCard({ collection, onClick, imagePreview = true, ...props }) {
  return (
    <div onClick={onClick} {...props} style={{cursor:'pointer' }}>
      <Col >
        <div style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <span style={{ fontSize: '12px' }}>Guardado: {collection.items_count}</span>

          <Image
            src={collection.image ?? ExampleImage}
            fallback={ExampleImage}
            style={{
              maxWidth: '12rem',
              height: '12rem',
              objectFit: 'cover',
              borderRadius: '12px',
              border: '1.42px solid #797979'
            }}
            preview={imagePreview}
          />
          <h5 style={{ textAlign: 'center', wordBreak: 'break-all', marginBottom: '25px' }}>{collection?.name}</h5>
        </div>
      </Col>
    </div>

  )
}

export { CollectionCard }
