import React from 'react'
import { PageProtected } from '../../../common/components/PageProtected'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { MainTheme } from '../../../common/theme/MainTheme'
import { FormEditHome } from './components/FormEditHome'

const URL_EDIT_HOME_PAGE = '/edit/home-page'

function EditHomePage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
    >
      <MainTheme
        showMenu
        className='site-layout__padding'
      >
        <div className='byp-align-centered' style={{ padding: 0 }}>
          <FormEditHome />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { EditHomePage, URL_EDIT_HOME_PAGE }
