import { Form } from 'antd'
import React from 'react'

const SearchContext = React.createContext()

const searchInit = {}
const breakpoint = 768

function SearchProvider (props) {
  const [type, setType] = React.useState('')
  const [width, setWidth] = React.useState(window.innerWidth)
  const [search, setSearch] = React.useState(searchInit)
  const [totalResults, setTotalResults] = React.useState()
  const [enableSearch, setEnableSearch] = React.useState(false)
  const [form] = Form.useForm()
  // form.resetFields()
  // form.getFieldValue('')

  /**
   * changeSearch
   * @param {String}type
   * @param {Object} search
   */
  const changeSearch = (type, search) => {
    setSearch(search)
    setType(type)
  }

  return (
    <SearchContext.Provider
      value={
        {
          type,
          search,
          changeSearch,
          isSearch: Object.keys(search).length === 0,
          totalResults,
          setTotalResults,
          enableSearch,
          setEnableSearch,
          form,
          width,
          setWidth,
          breakpoint
        }
      }
      {...props}
    />
  )
}

function useSearch () {
  const context = React.useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider')
  }
  return context
}

export { SearchProvider, useSearch }
