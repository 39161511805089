import { Form, Modal, notification } from 'antd'
import React, { useEffect } from 'react'
import { usePackage } from '../../../providers/package/package-course-context'
// import { URL_QUOTE_PREVIEW } from '../../quote-preview'
import { showErrorModal } from '../../../utils/errorModal'
import { URL_QUOTE_PREVIEW } from '../../quote-preview'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

function PackageFormConfigured({ children }) {
  const {
    form, handleFinishForm, errorPost,
    isSuccessPost, isErrorPost, quote, favorite,
    setFormConfig, formConfig, course, setFaileForm, faileForm
  } = usePackage()
  const [modal, contextHolder] = Modal.useModal()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const valueQueryParam = queryParams.get('values')
  const decodedValues = JSON.parse(valueQueryParam)

  useEffect(() => {
    if (decodedValues) {
      const baseQuote = decodedValues
      console.log('🚀 ~ file: QuoterForm.js:35 ~ useEffect ~ baseQuote:', baseQuote)
      form.setFieldsValue({
        startEvent: moment(baseQuote.startEvent),
        weeks: baseQuote.weeks,
        radioLodging: baseQuote.radioLodging,
        materials: baseQuote.materials,
        material: baseQuote.material,
        lodging: baseQuote.lodging,
        lodgingStart: moment(baseQuote.lodgingStart) ?? null,
        lodgingWeeks: baseQuote.lodgingWeeks,
        lodgingExtraNights: baseQuote.lodgingExtraNights,
        extra_nigth: !!baseQuote?.lodgingExtraNights,
        transport: baseQuote.transport,
        transport_way: baseQuote.transport_way,
        health_insurance: baseQuote.health_insurance,
        additionals: baseQuote.additionals
      })

      setFormConfig({
        ...formConfig,
        courseStart: moment(baseQuote.startEvent),
        weeks: baseQuote.weeks,
        materials: baseQuote.materials,
        material: baseQuote.material,
        lodging: baseQuote.radioLodging === 'si',
        lodgingId: baseQuote.lodging,
        lodgingStart: moment(baseQuote.lodgingStart) ?? null,
        lodgingWeeks: baseQuote.lodgingWeeks,
        lodgingExtraNights: baseQuote.lodgingExtraNights,
        extra_nigth: !!baseQuote?.lodgingExtraNights,
        transport: !!baseQuote?.transport,
        transportId: baseQuote.transport,
        transportWaySelected: baseQuote.transport_way,
        insurance: !!baseQuote?.health_insurance,
        healthInsuranceId: baseQuote.health_insurance,
        minorCosts: baseQuote.minorCosts,
        fees: baseQuote.fees,
        courseHSFees: baseQuote.courseHSFees,
        lodgingExtras: baseQuote.lodgingExtras,
        transportMinorCosts: baseQuote.transportMinorCosts,
        additionals: baseQuote.additionals
      })

      if (baseQuote?.currency && baseQuote?.currency !== 'null' && baseQuote?.currency !== 'undefined') {
        localStorage.setItem('___BYP_KEY_CURRENCY___', baseQuote?.currency)
      }
    }
  }, [course, valueQueryParam])

  useEffect(() => {
    if (isSuccessPost && !favorite) {
      window.open(URL_QUOTE_PREVIEW.replace(':id', quote.uuid))
    }
    if (isErrorPost) {
      const errMsg = errorPost?.response?.data?.message
      showErrorModal(errMsg)
    }
  }, [isSuccessPost, isErrorPost])

  return (
    <Form
    // className={`site-layout-content ${!faileForm ? 'errors-dont' : ''} marginPkg`}
    className={`packages-layout-content ${!faileForm ? 'errors-dont' : ''}`}
    form={form}
      layout='horizontal'
      name='basic'
      onFinish={handleFinishForm}
      autoComplete='off'
      scrollToFirstError
      requiredMark={false}
      initialValues={{
        // weeks: service.getMinWeeks()
      }}
      onFinishFailed={
        () => {
          if (!favorite) {
            notification.info({
              message: 'Por favor de llenar los campos mandatorios',
              placement: 'top'
            })
          } else {
            setFaileForm(true)
          }
        }
      }
    >
      {contextHolder}
      {children}
    </Form>
  )
}

export { PackageFormConfigured }
