import { Button, Col, DatePicker, Form, Input, Row, Typography } from 'antd'
import { SelectCountriesFormItem } from '../../../common/components/helper-form/SelectCountriesFormItem'
import { UploadLogo } from '../../../common/components/UploadLogo'
import { usePostDeleteLogo } from '../../../bussines/hooks/user/usePostDeleteLogo'
import { API_URL } from '../../../config'
import { useEffect, useState } from 'react'
import { SelectCityByIsoFormItem } from '../../../common/components/helper-form/SelectCityByIsoFormItem'
// import { SelectLanguageFormItem } from '../../../common/components/helper-form/SelectLanguageFormItem'

// const required = { required: true, message: 'Este dato es requerido' }
const { Title } = Typography

const ProfileAccountFields = ({ isSaving, currentIso }) => {
  const form = Form.useFormInstance()
  const profilePhotoUrl = Form.useWatch('profile_photo_url', form)
  const country = Form.useWatch('country', form)
  const [newProfilePhoto, setNewProfilePhoto] = useState(false)
  const [iso3, setIso3] = useState()

    useEffect(() => {
        setIso3(currentIso)
    }, [currentIso])

  return (
    <Row gutter={[16, 16]}>
      <Col xs={8} sm={5} md={3} lg={2}>
        <UploadLogo
          action={`${API_URL}/user/images`}
          usePostDeleteLogo={usePostDeleteLogo}
          profileUrl={profilePhotoUrl}
          title='Subir foto'
          form={form}
          showButtons={false}
          showDeleteButton={false}
          fileFieldName='image'
          onUploaded={(response) => {
            console.log('🚀 ~ file: ProfileAccountFields.js:25 ~ ProfileAccountFields ~ response:', response)
            setNewProfilePhoto(true)
            form.setFieldsValue({ profile_photo_path: response?.path })
          }}
        />
        {newProfilePhoto && (
          <Form.Item name='profile_photo_path' noStyle>
            <Input type='hidden' />
          </Form.Item>
        )}
      </Col>
      <Col span={24}>
        <Title level={4}>Información General</Title>
      </Col>
      <Col span={12}>
        <Form.Item label='Nombre' name='name'>
          <Input className='aplication__input' size='large' />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label='Apellido(s)' name='last_name'>
          <Input className='aplication__input' size='large' />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label='Email' name='email'>
          <Input className='aplication__input' size='large' />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label='Teléfono Celular' name='phone'>
          <Input className='aplication__input' size='large' />
        </Form.Item>
      </Col>
      <Col span={24}>
        <SelectCountriesFormItem
          name='nationality'
          size='large'
          label='País de origen'
          className='byp-select transparent br-1'
          showSearch
        />
      </Col>
      <Col xs={24} md={12}>
        <SelectCountriesFormItem
          name='country'
          size='large'
          label='País'
          className='byp-select transparent br-1'
          showSearch
          setCountry={(country) => {
            setIso3(country?.iso3)
            form.setFieldsValue({
              city: null
            })
          }}
        />
      </Col>
      {country && (
        <Col xs={24} md={12}>
          <SelectCityByIsoFormItem
            name='city'
            size='large'
            label='Ciudad'
            className='byp-select transparent br-1'
            iso={iso3}
            withCountry
          />
        </Col>
      )}

      <Col span={24}>
        <label className='label-aplication'>Dirección completa</label>
        <Form.Item
          name='address'
          // rules={[required]}
        >
          <Input className='aplication__input' placeholder='Avenida 8 de Julio 879' />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          name='colony'
          // rules={[required]}
        >
          <Input className='aplication__input' placeholder='Colonia' />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          name='zip_code'
          // rules={[required]}
        >
          <Input className='aplication__input' placeholder='Código Postal' />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name='date_of_birth'
          label='Fecha de Nacimiento'
          // rules={[required]}
          className='byp-mb-0'
        >
          <DatePicker className='aplication__input-date' format='DD [de] MMMM [de] YYYY' />
        </Form.Item>
      </Col>
      {/* <Col span={24}>
        <Form.Item label='Donde estudiaste' name='school'>
          <Input className='aplication__input' size='large' />
        </Form.Item>
      </Col>
      <Col span={24}>
        <SelectLanguageFormItem
          label='Idiomas que hablas'
          name='languages'
          mode='multiple'
          className='byp-select transparent br-1'
        />
      </Col>
      <Col span={24}>
        <Form.Item
          name='address'
          label='Dirección completa'
          rules={[required]}
        >
          <Input className='aplication__input' />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name='colony'
          label='Colonia'
          rules={[required]}
        >
          <Input className='aplication__input' placeholder='Colonia' />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name='zip_code'
          label='Código Postal'
          rules={[required]}
        >
          <Input className='aplication__input' placeholder='Código Postal' />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name='medical_situation'
          label='Situación médica que debamos estar enterados, o comentarios adicionales'
          rules={[required]}
        >
          <Input className='aplication__input' />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name='allergies'
          label='Me da alergia'
        >
          <Select
            className='school-search__item aplication__input-multiselect transparent byp-p-0'
            mode='tags'
            notFoundContent=''
            size='large'
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name='not_eat'
          label='No puedo comer'
        >
          <Select
            className='school-search__item aplication__input-multiselect transparent byp-p-0'
            mode='tags'
            notFoundContent=''
            size='large'
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name='pets'
          label='Tipo de mascotas'
        >
          <Select
            className='school-search__item aplication__input-multiselect transparent byp-p-0'
            mode='tags'
            notFoundContent=''
            size='large'
          />
        </Form.Item>
      </Col> */}
      <Col>
        <Button htmlType='submit' className='byp-btn-gray-200-outline br-06' loading={isSaving}>
          Guardar
        </Button>
      </Col>
    </Row>
  )
}

export { ProfileAccountFields }
