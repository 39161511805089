import { Button, Col, Form, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { AppLogoNameHeader } from '../../common/components/AppLogoNameHeader'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useResetPassword } from '../../bussines/hooks/user/useResetPassword'
import SessionService from '../../services/SessionService'
import { URL_QUOTER_PAGE } from '../quoter'
import { showErrorModal } from '../../utils/errorModal'
import RandomImage from '../../utils/RandomImage'

const URL_RESET_PASSWORD_PAGE = '/reset-password'

const ResetPasswordPage = () => {
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const token = params.get('token')
  const email = params.get('email')
  const [random, setRandom] = useState(1)
  const { isLoading, onSubmit, currentState, data, error } = useResetPassword()

  const onFinish = (values) => {
    values.token = token
    onSubmit(values)
  }

  useEffect(() => {
    setRandom(Math.floor(Math.random() * 10) + 1)
  }, [])

  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: Reset.js:21 ~ useEffect ~ data', data)
    }
    if (!isLoading && error) {
      showErrorModal(error.response.data?.message)
    }
  }, [isLoading, currentState, data, error])

  React.useEffect(() => {
    if (SessionService.existSession() && !SessionService.isPublicSession()) {
      // if (searchParams.get('redirect')) {
      //   navigate(searchParams.get('redirect'))
      // } else {
      // }
      navigate(URL_QUOTER_PAGE)
    }
  }, [isLoading])

  return (
    <div>
      <div style={{ overflow: 'hidden', height: '100vh' }}>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <RandomImage />
          </Col>


          <div className='form-container'>
            <div className='img-logo-container'>
              <AppLogoNameHeader
                style={{
                  width: '100%',
                  objectFit: 'contain',
                  height: '100%',
                  paddingBottom: '18px'
                }}
              />
            </div>
            <Form
              name='forgot_password'
              className='login-form'
              style={{ width: '50%' }}
              onFinish={onFinish}
            >
              <h4 style={{ fontWeight: 400, fontFamily: 'Poppins, sans-serif', fontSize: '22px' }}>Crear una nueva contraseña</h4>
              <p style={{ fontFamily: 'Poppins, sans-serif', marginTop: 15, fontSize: '12px', lineHeight: '18px' }}>Te pediremos esta contraseña siempre que inicies sesión</p>
              {/* <Form.Item
              name='email'
              initialValue={email}
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!'
                }
              ]}
              style={{ marginBottom: 0 }} 

            >
              <Input
                size='large'
                value={email}
                prefix={<UserOutlined className='site-form-item-icon' />}
                placeholder='Email'
              />
            </Form.Item> */}

              <Form.Item
                name='password'
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Ingresa tu contraseña'
                  },
                  {
                    pattern:
                      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%\-_=+<>])([a-zA-Z0-9!#$%\-_=+<>]+){8,}$/,
                    message:
                      'La contraseña debe incluír por lo menos 8 caracteres e incluir al menos: 1 número, una mayúscula, una minúscula, y un caracter especial.'
                  }
                ]}
                style={{ marginBottom: 10 }}


              >
                <Input.Password
                  size='large'
                  className='input_email'
                  placeholder='Nueva contraseña'
                />
              </Form.Item>

              <Form.Item
                name='confirmation'
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Por favor cofirma tu contraseña!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('Las contraseñas no conciden!')
                      )
                    }
                  })
                ]}
                style={{ marginBottom: 15 }}

              >
                <Input.Password
                  size='large'
                  className='input_email'
                  placeholder='Confirmar Contraseña'
                />
              </Form.Item>
              <Button
                loading={isLoading}
                className='byp-btn-red-50_forgot '
                block
                htmlType='submit'
                style={{ height: 40 }}

              >
                Continuar
              </Button>
            </Form>
          </div>
        </Row>
      </div>
    </div>
  )
}

export { ResetPasswordPage, URL_RESET_PASSWORD_PAGE }
