import { userRoles } from '../../bussines/settings/validate-user-access'
import { ValidateEnterpriseProfile } from '../../common/components/ValidateEnterpriseProfile'
import { MainTheme } from '../../common/theme/MainTheme'
import { SearchProvider } from '../../providers/search/search-context-provider'
import { FavoriteContent } from './components/favoriteContent'

const URL_FAVORITES = '/favorites'

function FavoritePage () {
  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme activeCarousel showMainTheme={false}>
        <SearchProvider>
          <FavoriteContent />
        </SearchProvider>
      </MainTheme>
    </ValidateEnterpriseProfile>
  )
}

export { FavoritePage, URL_FAVORITES }
