import { addDays, addWeeks, differenceInDays, differenceInWeeks } from 'date-fns'
import { isSameOrAfter } from '../../../utils/isSameOrAfter'
import { isSameOrBefore } from '../../../utils/isSameOrBefore'
import { formatStringDate } from '../../../utils/formatStringDate'
import { enUS } from 'date-fns/locale'

const dayNumber = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7
}

// Calculate the difference in weeks of the date 1 + first friday to date 2
function difInWeeks (date1, date2) {
  const startDay = formatStringDate(date1, 'EEEE', enUS)
  const day = dayNumber[startDay]
  let daysToFriday = 5 - day
  if (daysToFriday < 0) {
    daysToFriday = daysToFriday + 7
  }
  const endOfFirstWeek = addDays(new Date(date1), daysToFriday)
  const difInWeekss = differenceInWeeks(endOfFirstWeek, new Date(date2))
  return difInWeekss
}

function isInHighSeason (date, highSeasons) {
  if (!date || highSeasons.length === 0 || !highSeasons) {
    return false
  }
  for (const highSeason of highSeasons) {
    const hsEnd = addDays(new Date(highSeason.hs_end), 1)
    const hsStart = addDays(new Date(highSeason.hs_start), 1)
    // console.log(
    //   'Dates for Transport HS',
    //   '\n date: ', date,
    //   '\n hsEnd: ', hsEnd,
    //   '\n hsStart: ', hsStart
    // )
    if (isSameOrAfter(hsEnd, new Date(date)) &&
    isSameOrBefore(hsStart, new Date(date))
    ) {
      return true
    }
  }
  return false
}

function calculateHSCosts (highSeason, weeks) {
  const standard = highSeason.hs_cost ?? 0
  const extra = highSeason.hs_extra_cost ?? 0
  const minor = highSeason.hs_minor_cost ?? 0
  const costs = {
    standard_cost: standard * weeks,
    extra_cost: extra * weeks,
    minor_cost: minor * weeks
  }
  return costs
}

function getHSCosts (highSeason) {
  const costs = {
    standard_cost: highSeason.hs_cost ?? 0,
    extra_cost: highSeason.hs_extra_cost ?? 0,
    minor_cost: highSeason.hs_minor_cost ?? 0
  }
  return costs
}

function calculateHSFeesCosts (highSeason, weeks) {
  const fees = highSeason.fees ?? []
  let sumCost = 0
  for (const fee of fees) {
    sumCost += fee.cost ?? 0
  }
  return sumCost * weeks
}

// If there are more than 1 week of high season, retur true
function isHighSeason (weeks, startDate, highSeasons) {
  if (!weeks || !startDate || highSeasons.length === 0) {
    return false
  }

  let realWeeks = 0
  const endDate = addWeeks(new Date(startDate), weeks)
  for (const highSeason of highSeasons) {
    // The high season dates are all inside the course dates
    if (isSameOrAfter(new Date(highSeason.hs_start), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      realWeeks += differenceInWeeks(new Date(highSeason.hs_end), new Date(highSeason.hs_start))
      continue
    }
    // The high seasons dates start with or after the course and still continue after the course ends
    if (isSameOrAfter(new Date(highSeason.hs_start), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_start), new Date(endDate))
    ) {
      realWeeks += differenceInWeeks(new Date(endDate), new Date(highSeason.hs_start))
      continue
    }
    // The High Season is still valid and ends within the course dates
    if (isSameOrAfter(new Date(highSeason.hs_end), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      realWeeks += difInWeeks(highSeason.hs_end, startDate)
      continue
    }
    // The High Season is still valid and continue throug the course dates
    if (isSameOrBefore(new Date(highSeason.hs_start), new Date(startDate)) &&
      isSameOrAfter(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      realWeeks += weeks
    }
  }
  return realWeeks > 0
}

/**
 * Return the applicable highseason with its fees
 * @param {*} highSeasons
 * @param {*} startDate
 * @param {*} weeks
 * @returns
 */
function getApplicableHighSeson (highSeasons, startDate, weeks) {
  if (!weeks || !startDate || highSeasons.length === 0) {
    return []
  }

  let nWeeks = 0
  const results = []
  const endDate = addWeeks(new Date(startDate), weeks)
  for (const highSeason of highSeasons) {
    // The high season dates are all inside the course dates
    if (isSameOrAfter(new Date(highSeason.hs_start), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      nWeeks = differenceInWeeks(new Date(highSeason.hs_end), new Date(highSeason.hs_start))
      const nDays = differenceInDays(new Date(highSeason.hs_end), new Date(highSeason.hs_start))
      results.push({
        id: highSeason.id,
        weeks: nWeeks,
        daysOff: nDays % 7,
        fees: highSeason.fees || []
      })
      continue
    }
    // The high seasons dates start with or after the course and still continue after the course ends
    if (isSameOrAfter(new Date(highSeason.hs_start), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_start), new Date(endDate))
    ) {
      nWeeks = differenceInWeeks(new Date(endDate), new Date(highSeason.hs_start))
      const nDays = differenceInDays(new Date(endDate), new Date(highSeason.hs_start))
      results.push({
        id: highSeason.id,
        weeks: nWeeks,
        daysOff: nDays % 7,
        fees: highSeason.fees || []
      })
      continue
    }
    // The High Season is still valid and ends within the course dates
    if (isSameOrAfter(new Date(highSeason.hs_end), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      nWeeks = difInWeeks(highSeason.hs_end, startDate)
      const nDays = differenceInDays(new Date(highSeason.hs_end), new Date(startDate))
      results.push({
        id: highSeason.id,
        weeks: nWeeks,
        daysOff: nDays % 7,
        fees: highSeason.fees || []
      })
      continue
    }
    // The High Season is still valid and continue throug the course dates
    if (
      // isSameOrAfter(new Date(highSeason.hs_end), new Date(startDate)) &&
      // isSameOrAfter(new Date(highSeason.hs_end), new Date(endDate))
      isSameOrBefore(new Date(highSeason.hs_start), new Date(startDate)) &&
      isSameOrAfter(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      nWeeks = weeks
      results.push({
        id: highSeason.id,
        weeks: nWeeks,
        daysOff: 0,
        fees: highSeason.fees || []
      })
    }
  }
  // console.log('🚀 ~ file: isHighSeason.js ~ line 107 ~ getApplicableHighSeson ~ realWeeks', realWeeks)
  return results
}
/**
 * @param {*} date
 * @param {*} highSeasons
 * @returns the cost of the firs high_Season aplicable
 */
function getHighSeasonCost (date, highSeasons) {
  if (!date || highSeasons.length === 0 || !highSeasons) {
    return 0
  }
  for (const highSeason of highSeasons) {
    const hsEnd = addDays(new Date(highSeason.hs_end), 1)
    const hsStart = addDays(new Date(highSeason.hs_start), 1)
    if (isSameOrAfter(hsEnd, new Date(date)) &&
    isSameOrBefore(hsStart, new Date(date))
    ) {
      return highSeason.hs_cost ?? 0
    }
  }
  return 0
}

// Calculate the HighSeasons Cost (Not HS fees)
function calculateHighSeasonCosts (startDate, endDate, highSeasons, type = 'course') {
  let HSCost = 0
  const Costs = {
    standard_cost: 0,
    extra_cost: 0,
    minor_cost: 0,
    weeks: 0
  }
  const CostsByHs = []
  for (const highSeason of highSeasons) {
    if (isSameOrAfter(new Date(highSeason.hs_start), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      // const nWeeks = differenceInWeeks(new Date(highSeason.hs_end), new Date(highSeason.hs_start))
      const nWeeks = difInWeeks(highSeason.hs_end, highSeason.hs_start)
      const nDays = differenceInDays(new Date(highSeason.hs_end), new Date(highSeason.hs_start))
      // console.log(
      //   'first option',
      //   '\n HS id: ', highSeason.id,
      //   '\n nWeeks', nWeeks,
      //   '\n nDays', nDays,
      //   '\n nDaysOff', nDays % 7
      // )
      if (type === 'course') {
        HSCost += calculateHSFeesCosts(highSeason, nWeeks)
      } else {
        const hsCosts = calculateHSCosts(highSeason, nWeeks)
        CostsByHs.push({
          id: highSeason.id,
          costs: getHSCosts(highSeason),
          weeks: nWeeks,
          daysOff: nDays % 7,
          start: formatStringDate(highSeason.hs_start, 'yyyy-LL-dd'),
          start_formated: highSeason.hs_start_formated,
          end: formatStringDate(highSeason.hs_end, 'yyyy-LL-dd'),
          end_formated: highSeason.hs_end_formated,
          fees: highSeason.fees,
          days: differenceInDays(new Date(highSeason.hs_end), new Date(highSeason.hs_start))
        })
        Costs.standard_cost += hsCosts?.standard_cost
        Costs.extra_cost += hsCosts?.extra_cost
        Costs.minor_cost += hsCosts?.minor_cost
        Costs.weeks += nWeeks
      }
      continue
    }

    if (isSameOrAfter(new Date(highSeason.hs_start), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_start), new Date(endDate))
    ) {
      // const nWeeks = differenceInWeeks(new Date(endDate), new Date(highSeason.hs_start))
      const nWeeks = difInWeeks(endDate, highSeason.hs_start)
      const nDays = differenceInDays(new Date(endDate), new Date(highSeason.hs_start))
      // console.log(
      //   'Second option',
      //   '\n nWeeks', nWeeks,
      //   '\n nDays', nDays,
      //   '\n nDaysOff', nDays % 7
      // )
      if (type === 'course') {
        HSCost += calculateHSFeesCosts(highSeason, nWeeks)
      } else {
        const hsCosts = calculateHSCosts(highSeason, nWeeks)
        Costs.standard_cost += hsCosts?.standard_cost
        Costs.extra_cost += hsCosts?.extra_cost
        Costs.minor_cost += hsCosts?.minor_cost
        Costs.weeks += nWeeks
        CostsByHs.push({
          id: highSeason.id,
          costs: getHSCosts(highSeason),
          weeks: nWeeks,
          daysOff: nDays % 7,
          start: formatStringDate(highSeason.hs_start, 'yyyy-LL-dd'),
          end: formatStringDate(highSeason.hs_end, 'yyyy-LL-dd'),
          start_formated: highSeason.hs_start_formated,
          end_formated: highSeason.hs_end_formated,
          fees: highSeason.fees,
          days: differenceInDays(new Date(endDate), new Date(highSeason.hs_start))
        })
      }
      continue
    }

    if (isSameOrAfter(new Date(highSeason.hs_end), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      // const nWeeks = differenceInWeeks(new Date(highSeason.hs_end), new Date(startDate))
      const nWeeks = difInWeeks(highSeason.hs_end, startDate)
      const nDays = differenceInDays(new Date(highSeason.hs_end), new Date(startDate))
      // console.log(
      //   'Third option',
      //   '\n HS id: ', highSeason.id,
      //   '\n nWeeks', nWeeks,
      //   '\n nDays', nDays,
      //   '\n nDaysOff', nDays % 8
      // )
      if (type === 'course') {
        HSCost += calculateHSFeesCosts(highSeason, nWeeks)
      } else {
        const hsCosts = calculateHSCosts(highSeason, nWeeks)
        Costs.standard_cost += hsCosts?.standard_cost
        Costs.extra_cost += hsCosts?.extra_cost
        Costs.minor_cost += hsCosts?.minor_cost
        Costs.weeks += nWeeks
        CostsByHs.push({
          id: highSeason.id,
          costs: getHSCosts(highSeason),
          weeks: nWeeks,
          daysOff: nDays % 7,
          start: formatStringDate(highSeason.hs_start, 'yyyy-LL-dd'),
          end: formatStringDate(highSeason.hs_end, 'yyyy-LL-dd'),
          start_formated: highSeason.hs_start_formated,
          end_formated: highSeason.hs_end_formated,
          fees: highSeason.fees,
          days: differenceInDays(new Date(highSeason.hs_end), new Date(startDate))
        })
      }
      continue
    }
    // The High Season is still valid and continue throug the course dates
    if (
      isSameOrBefore(new Date(highSeason.hs_start), new Date(startDate)) &&
      isSameOrAfter(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      // const nWeeks = differenceInWeeks(new Date(highSeason.hs_end), new Date(startDate))
      const nWeeks = difInWeeks(endDate, startDate)
      const nDays = differenceInDays(new Date(endDate), new Date(startDate))
      console.log(
        'Fourt option',
        '\n HS id: ', highSeason.id,
        '\n nWeeks', nWeeks,
        '\n nDays', nDays,
        '\n nDaysOff', nDays % 7,
        '\n start: ', new Date(highSeason.hs_start),
        '\n endDate: ', new Date(highSeason.hs_end)
      )
      if (type === 'course') {
        HSCost += calculateHSFeesCosts(highSeason, nWeeks)
      } else {
        const hsCosts = calculateHSCosts(highSeason, nWeeks)
        Costs.standard_cost += hsCosts?.standard_cost
        Costs.extra_cost += hsCosts?.extra_cost
        Costs.minor_cost += hsCosts?.minor_cost
        Costs.weeks += nWeeks
        CostsByHs.push({
          id: highSeason.id,
          start: formatStringDate(highSeason.hs_start, 'yyyy-LL-dd'),
          end: formatStringDate(highSeason.hs_end, 'yyyy-LL-dd'),
          start_formated: highSeason.hs_start_formated,
          end_formated: highSeason.hs_end_formated,
          costs: getHSCosts(highSeason),
          weeks: nWeeks,
          daysOff: nDays % 7,
          fees: highSeason.fees,
          days: differenceInDays(new Date(endDate), new Date(startDate))
        })
      }
    }
  }
  Costs.details = CostsByHs
  if (type === 'course') {
    return HSCost
  } else {
    return Costs
  }
}

export {
  isHighSeason,
  calculateHighSeasonCosts,
  isInHighSeason,
  getHighSeasonCost,
  getApplicableHighSeson
}
