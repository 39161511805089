import { programIntakes } from '../languageConfiguration/infoCourseTranslate'
import { formatStringDate } from './formatStringDate'

const intakes = ['Spring', 'Summer', 'Fall', 'Winter']
// MOVE THIS INTO A UTILS METHOD
function obtenerNombreDiaSemana (fecha) {
  const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
  return diasSemana[fecha.getDay()]
}

function obtenerNombreMes (fecha) {
  const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  return meses[fecha.getMonth()]
}

function formatStartDate (fechaStr, year = '') {
  if (intakes.includes(fechaStr)) {
    const intakeF = programIntakes.es[fechaStr] ?? fechaStr
    return intakeF + ' ' + year
  }
  const fecha = new Date(fechaStr)

  const nombreDiaSemana = obtenerNombreDiaSemana(fecha)
  const nombreMes = obtenerNombreMes(fecha)

  const dia = fecha.getDate()
  const año = fecha.getFullYear()

  if (isNaN(dia) || isNaN(año) || isNaN(nombreMes)) {
    return fechaStr
  }

  const resultado = `${nombreDiaSemana} ${dia} de ${nombreMes}, ${año}`
  return resultado
}

function formatIntake (fechaStr, year = '') {
  if (intakes.includes(fechaStr)) { // Ej. Winter
    const intakeF = programIntakes.es[fechaStr] ?? fechaStr
    return intakeF + ' ' + year
  } else if (fechaStr.split(' ').length === 2) { // Ej. Winter 2025
    const splits = fechaStr?.split(' ')
    const intakeWithoutYear = splits[0]
    const intakeF = programIntakes.es[intakeWithoutYear] ?? intakeWithoutYear
    return intakeF + ' ' + splits[1]
  }
  const fecha = new Date(fechaStr)

  const nombreMes = obtenerNombreMes(fecha)
  const dia = fecha.getDate()
  const año = fecha.getFullYear()

  if (isNaN(dia) || isNaN(año) || isNaN(nombreMes)) {
    return formatStringDate(fechaStr, 'LLLL yyyy')
  }

  const resultado = `${dia} de ${nombreMes}, ${año}`
  return resultado
}

export { formatStartDate, formatIntake }
