import api from '../../services/api'

class AdminRepository {
  async allCompanies (params) {
    const response = await api.get('/admin/accounts', { params })
    return response.data
  }

  async validateEmail (data) {
    const response = await api.put('/admin/company/validate-email', data)
    return response.data
  }

  async aproveCompany (data) {
    const response = await api.put('/admin/company/aprove', data)
    return response.data
  }

  async sendResetPasswordEmail (data) {
    const response = await api.post('/forgot-password', data)
    return response.data
  }

  async resetPassword (data) {
    const response = await api.post('/admin/reset-password', data)
    return response.data
  }

  async allRoles (params) {
    const response = await api.get('/admin/roles', { params })
    return response.data
  }

  async allUsers (params) {
    const response = await api.get('/admin/users', { params })
    return response.data
  }

  async allCompaniesNames (params) {
    const response = await api.get('/admin/accounts/names', { params })
    return response.data
  }

  async getInfoOffice (type, id, params) {
    const response = await api.get(`/admin/${type}/${id}`, { params })
    return response.data
  }

  async convertCampusToInstitution (id) {
    const response = await api.put(`/admin/convert_campus_to_institution/${id}`)
    return response.data
  }
}

export default new AdminRepository()
