import { useQuery } from 'react-query'
import InputsRepository from '../../repositories/InputsRepository'

function useGetSports (filters) {
  return useQuery(['useGetSports', filters], () => InputsRepository.getSports(filters), {
    retry: 2
  })
}

function useGetExtras (filters) {
  return useQuery(['useGetExtras', filters], () => InputsRepository.getExtras(filters), {
    retry: 2
  })
}

export { useGetSports, useGetExtras }
