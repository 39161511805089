import { Form, Select, Statistic, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { FieldHighSeason } from './FieldHighSeason'
import { RowQuoter } from './RowQuoter'
import { SelectExtraNightStartAndDuration } from './SelectExtraNightStartAndDuration'
// import { SelectLodgingStartAndDuration } from './SelectLodgingStartAndDuration'
import { StatisticWithDiscounts, discountsApplied } from '../../../common/components/StatisticWithDiscounts'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
// import { formatStringDate } from '../../../utils/formatStringDate'

const { Option } = Select

function LodgingSection () {
  const { service, formConfig, setFormConfig } = useQuoter()
  const [selected, setSelected] = useState(formConfig.lodgingId)

  const handleChangeExSwitch = (included) => {
    setFormConfig({
      ...formConfig,
      extra_nigth: included
    })
  }

  const handleAddextra = (value, { id, cost, frequency }) => {
    let lodgingExtras = formConfig.lodgingExtras || []
    const nExtras = []
    if (value) {
      lodgingExtras.push({ id, cost, frequency })
    } else {
      formConfig.lodgingExtras?.map((extra) => (
        extra.id !== id ? nExtras.push(extra) : null
      ))
      lodgingExtras = nExtras
    }
    setFormConfig({
      ...formConfig,
      lodgingExtras
    })
  }

  useEffect(() => {
    const lodgings = service.getArrayLodging() || []
    const found = lodgings.find((item) => item.id === selected)
    const extras = found?.extra_costs || []
    const mandatoryExtras = extras.filter((extra) => extra.mandatory)
    const lodgingExtras = mandatoryExtras.map((extra) => ({
      id: extra.id,
      cost: extra.cost,
      frequency: extra.frequency
    }))
    setFormConfig({
      ...formConfig,
      lodgingId: selected,
      lodgingExtras
    })
  }, [selected])

  useEffect(() => {
    if (formConfig.lodgingId) {
      // console.log(formConfig.lodgingId)
      setSelected(formConfig.lodgingId)
    }
  }, [formConfig.lodgingId])

  return (
    <div>
      {service.includeLodging() && (
        <RowQuoter
          colA={
            <p className='w-100 byp-mb-0'>
              Alojamiento
            </p>
          }
        />
      )}
      {service.includeLodging() && (
        <RowQuoter // Accomodation search
          colA={
            <>
              <p className='byp-mb-0'>Búsqueda de alojamiento</p>
              {
                // Discounts
                service.getAcommodationFeeDiscounts().length > 0 && (
                  service.getAcommodationFeeDiscounts()?.map((d) => (
                    <p key={d.id} className='byp-fw-bold text-discount'>
                      {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Alojamiento
                    </p>
                  ))
                )
              }
            </>
          }
          colB={
            selected && (
              <StatisticWithDiscounts
                discounts={service.getAcommodationFeeDiscounts()}
                amount={service.getLodgingAcommodationFee()}
                currency={service.getCurrency()}
                // subtotal={service.AcommodationFeeTotal()}
                subtotal={service.getAcommodationFeeDiscountsAmount()}
                active={formConfig.lodging && selected}
              />
            )
          }
        />
      )}
      {service.includeLodging() && (
        <RowQuoter // Select Accomodation
          hr={service.getAmountsLodging().hs_cost <= 0}
          colA={
            <>
              <Form.Item
                label='Alojamiento'
                name='lodging'
                rules={[{
                  required: true,
                  message: 'Field required'
                }]}
                className='byp-mb-0'
              >
                <Select
                  style={{ width: '95%' }}
                  allowClear
                  placeholder='No seleccionado'
                  onChange={setSelected}
                  size='small'
                  className='quote__border'
                  dropdownMatchSelectWidth={false}

                >
                  {service.getArrayLodging().map((itm) => {
                    return <Option key={itm.id} value={itm.id}>{itm.translations?.find(t => t.to === 'es')?.translation ?? itm.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </>
          }
          colB={
            service.includeLodging() && (
              <>
                {// Low Season Cost
                  service.getAmountsLodging().ls_cost > 0 && (
                    <Statistic
                      prefix='$'
                      precision={2}
                      value={`${service.getAmountsLodging().ls_cost}`}
                      // suffix={`${service.getCurrency()}`}
                    />
                  )
                }
              </>
            )
          }
        />
      )}
      {/* {(service.getCourseProgram()?.name !== 'academic_years') && (
        <SelectLodgingStartAndDuration program={service.getCourseProgram()?.name} />
      )} */}
      {service.getLodgingHighSeasonValues()?.details?.map(// Peak Seasons
        (hs) => (
          <RowQuoter
            key={'hs_label' + hs.id}
            colA={
              <p>
                Coste de temporada alta
                Desde {hs.start_formated ?? hs.start} a {hs.end_formated ?? hs.end}, {hs.weeks} semanas
                {/* {hs.daysOff > 0 && (
                  ' plus ' + hs.daysOff + ' days'
                )} */}
              </p>
            }
            colB={// High Season Costs
              service.getAmountsLodging().hs_cost > 0 && (
                <Statistic
                  prefix='$'
                  precision={2}
                  value={`${service.getAmountsLodging().hs_cost}`}
                  // value={`${(hs.costs.standard_cost * hs.weeks) + (hs.daysOff * (hs.costs.standard_cost / 7))}`}
                  // value={`${(hs.costs.standard_cost * hs.weeks)}`}
                  // suffix={`${service.getCurrency()}`}
                />
              )
            }
          />
        )
      )}
      {// Discounts
        service.getLodgingDiscounts().length > 0 && service.getHighSeasonAmountLodging() > 0 && (
          service.getLodgingDiscounts()?.map((d) => (
            <RowQuoter
              key={'row_lodging_section_a' + d.name}
              colA={
                <p key={d.id} className='byp-fw-bold text-discount'>
                  {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Alojamiento
                </p>
              }
              colB={
                <Statistic
                  prefix='$'
                  className='price-after text-discount'
                  precision={2}
                  // value={`${service.getHighSeasonAmountLodging()?.toFixed(2) - service.getLodgingDiscountsAmount()}`}
                  value={`${'-' + service.getLodgingDiscountsAmount()}`}
                  // suffix={`${service.getCurrency()}`}
                />
              }
            />
          ))
        )
      }
      {service.getLodgingIsHighSeason() && <FieldHighSeason forLodging />}
      {// Lodging Extra Costs
        service.getLodgingExtraCosts().length > 0 && (
          service.getLodgingExtraCosts().map((extra) => (
            <RowQuoter
              key={extra.id}
              colA={
                <>
                  <p>{extra.translations?.find(t => t.to === 'es')?.translation ?? extra.name}:</p>
                  {service.getLodgingExtraCostsDiscounts(extra.id)?.map((d) => (
                    <p key={'accom_fee_disc_' + d.id} className='byp-fw-bold'>
                      {d.translations?.find(t => t.to === 'es')?.translation ?? d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Alojamiento temporada alta.
                    </p>
                  ))}
                </>
              }
              colB={
                <StatisticWithDiscounts
                  discounts={service.getLodgingExtraCostsDiscounts(extra.id)}
                  amount={service.calculateLodgingExtraCosts(extra.id)?.toFixed(2)}
                  currency={service.getCurrency()}
                  subtotal={service.getLodgingExtraSubtotal(extra.id)?.toFixed(2)}
                  active={service.isLodgingExtraSelected(extra.id)}
                />

              }
              colC={
                <>
                  {!extra.mandatory && (
                    <Switch
                      onChange={(val) => handleAddextra(val, extra)}
                      checked={service.isLodgingExtraSelected(extra.id)}
                    />
                  )}
                </>
              }
            />
          ))
        )
      }
      {
        service.includeExtraNight() &&
          <RowQuoter
            colA={
              <p className='w-100'>
                Añadir noche extra:
              </p>
            }
            colC={
              <Switch onChange={handleChangeExSwitch} checked={formConfig.extra_nigth} />
              // <>
              //   {!active2
              //     ? (
              //       <Button className='cotizacion_btn-add' onClick={() => handleChangeExSwitch(true)}><span className='icon-mas'>+</span> Añadir</Button>
              //       )
              //     : (
              //       <Button className='cotizacion_btn-deleted' onClick={() => handleChangeExSwitch(false)}><DeletedIcon /> Eliminar</Button>
              //       )}
              // </>
            }
          />
          }
      {formConfig.extra_nigth && (
        <SelectExtraNightStartAndDuration />
      )}
    </div>
  )
}

export { LodgingSection }
