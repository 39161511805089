import { Button, Card, Col, Layout, Row, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import { AirPlaneIcon, TeamIcon } from "../../quote-preview/assets/imgs/Icons";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CourseOpinions } from "./CourseOpinions";
import { useGetOpinions } from "../../../bussines/hooks/opinion/useGetOpinions";
import { usePackage } from "../../../providers/package/package-course-context";
// import { CardCourse } from '../../dashboards/blog/components/CardCourse'
import { HomeCarrousel } from "../../../common/components/HomeCarrousel";
import { useAuth } from "../../../providers/auth/auth-context-provider";
// import { useGetOfferRecomendations } from '../../../bussines/hooks/offer/useGetOfferRecomendations'
import { showErrorModal } from "../../../utils/errorModal";
import {
  useGetRandomBlogs,
  useGetRandomCourses,
} from "../../../bussines/hooks/page/useGetRandomCourses";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import { CardBlogSimple } from "../../dashboards/blog/components/CardBlogSimple";
import { PencilIcon } from "../../quote-preview/assets/imgs/Icons";
import { URL_OPINION_PAGE } from "../../opinion/OpinionPage";
import { saveInStorage } from "../../../utils/localStorageUtils";
import { Link, useNavigate } from "react-router-dom";
import { URL_REGISTER_PAGE_BETA } from "../../register";
import { EditIcon } from "../../../common/theme/icons/EditIcon";
import { IconSchool } from "../../../common/assets/svg/icons";
import { CardCourse } from "../../dashboards/blog/components/CardCourse";
import { CardPlacesWithText } from "../../../common/components/CardPlaces";

export const FooterQuote = () => {
  const navigate = useNavigate();

  const { course, setOpinionsData, isPublicUser } = usePackage();
  const [filters, setFilters] = useState({ limit: 6 });
  // const [recomendationFilters] = useState({
  //   distinctCourse: true,
  //   program: course?.offer?.program?.name
  // })
  const [image, setImage] = useState();
  const [text, setText] = useState();
  const [link, setLink] = useState();
  const { isLoading, data, error, isError } = useGetOpinions(
    course?.offer?.campus?.id,
    course?.offer?.id,
    filters
  );
  // const [slides, setSlidesBlog] = useState(3)
  const [isMobile, setIsMobile] = useState(false);
  const [slides, setSlides] = useState(1);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  // const { data: offers } = useGetOfferRecomendations(recomendationFilters)
  const {
    isLoading: isLoadingBlogs,
    data: blogs,
    isErrorBlogs,
    error: errorBlogs,
  } = useGetRandomBlogs(course?.offer?.program?.name);

  const {
    isLoading: isLoadingCourses,
    data: courses,
    isError: isErrorCourses,
    error: errorCourses,
  } = useGetRandomCourses(course?.offer?.program?.name);

  console.log("courses", courses);

  const { user: _user } = useAuth();

  useEffect(() => {
    if (!isLoading && data) {
      setOpinionsData(data);
    } else if (!isLoading && isError) {
      showErrorModal(error?.response?.data?.message);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoadingBlogs && blogs) {
      console.log(
        "🚀 ~ file: CountryContent.js:20 ~ useEffect ~ Blogs:",
        blogs
      );
    }
    if (!isLoadingBlogs && isErrorBlogs) {
      notification.error({
        message: errorBlogs?.response?.data?.message ?? "Not Found",
      });
    }
  }, [isLoadingBlogs]);

  function addHttpsIfNotExist(url) {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "https://" + url;
    }
    return url;
  }
  const handleOpinionRedirection = () => {
    const opinionPage = URL_OPINION_PAGE.replace(
      ":campusSlug",
      course?.offer?.campus?.slug
    ).replace(":courseId", course?.offer?.id);
    saveInStorage("redirectOnLoginSucces", opinionPage);
    navigate(URL_REGISTER_PAGE_BETA);
  };

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.width <= 800) {
      // setSlides(1)
      setSlides(1);
      setIsMobile(true);
    } else {
      // setSlides(4)
      setSlides(3);
      setIsMobile(false);
    }
  }, [windowSize]);

  useEffect(() => {
    if (
      _user &&
      _user?.publicityHorizontal &&
      _user?.publicityHorizontal?.length > 0
    ) {
      if (_user?.publicityHorizontal[0]?.title)
        setText(_user?.publicityHorizontal[0]?.title);
      if (_user?.publicityHorizontal[0]?.src)
        setImage(_user?.publicityHorizontal[0]?.src);
      if (_user?.publicityHorizontal[0]?.link)
        setLink(addHttpsIfNotExist(_user?.publicityHorizontal[0]?.link));
    }
  }, [_user]);

  return (
    <>
      {/** BLOG **/}
      <div className="only-desktop">
        <div>
          <h4 style={{ color: "#373737" }} className="title-with-icon">
            <RecordVoiceOverIcon style={{ fontSize: "20px" }} />
            Blog
          </h4>
          <p style={{ color: "#797979" }} className="subtitle__big-home">
            Conoce más del mundo
          </p>
        </div>
        <div>
          <HomeCarrousel
            slides={blogs?.blogs ?? []}
            Slide={CardBlogSimple}
            className="big-home__package carrousel-blog"
            slidesPerRow={slides}
          />
        </div>
      </div>

      {/* COURSES RECOMENDATIONS*/}
      <div style={{marginBottom: "1rem"}} className="only-mobile">
        <div>
          <h4 style={{ color: "#373737" }} className="title-with-icon">
          <IconSchool color="#373737" width="20px" height="20px" /> Nuestra
          recomendación de cursos
          </h4>
          <p style={{ color: "#797979" }} className="subtitle__big-home">
            Descubre el mundo estudiando
          </p>
        </div>
        <div>
        <HomeCarrousel
              slides={courses?.courses ?? []}
              Slide={CardCourse}
              className="big-home__package carrousel-blog"
              slidesPerRow={slides}
            />
        </div>
      </div>
     
      <Card className="quote-byp__new_search show_mobile hide_pc">
        <h2 className="byp-m-0">Empezar de nuevo </h2>
        <Button>
          <EditIcon />{" "}
          <Link style={{ color: "#797979" }} to="/home">
            Nueva Búsqueda
          </Link>
        </Button>
      </Card>
      <Col className="flex-byp__group">
        <Space>
          {isPublicUser && (
            <Button
              className="quote-byp__btn-opinion hide_pc show_mobile"
              style={{ margin: "1rem auto", width: "100%" }}
              onClick={handleOpinionRedirection}
            >
              <PencilIcon />
              Deja tu Opinión
            </Button>
          )}
          {!isPublicUser && (
            <Button
              className="quote-byp__btn-opinion hide_pc show_mobile"
              style={{ margin: "1rem auto", width: "100%" }}
            >
              <PencilIcon />
              <Link
                to={URL_OPINION_PAGE.replace(
                  ":campusSlug",
                  course?.offer?.campus?.slug
                ).replace(":courseId", course?.offer?.id)}
                target="_blank"
                state={{
                  campus: course?.offer?.campus,
                  course: {
                    id: course?.offer?.id,
                    name: course?.offer?.name,
                  },
                }}
              >
                Deja tu Opinión
              </Link>
            </Button>
          )}
        </Space>
      </Col>

      {data?.opinions?.length > 0 && (
        <>
          <h2 className="opiniones__title" style={{ paddingTop: "30px" }}>
            <TeamIcon /> Opiniones del curso
          </h2>
          <p className="opiniones__description byp-mb-1">
            No te vayas sin saber que opinan
          </p>
          <CourseOpinions
            opinions={data?.opinions ?? []}
            rates={data?.rates ?? []}
            filters={filters}
            setFilters={setFilters}
            isLoading={isLoading}
          />
        </>
      )}

      <Row gutter={[38, 38]} className="byp-mb-1">
        <></>
      </Row>

      {image && (
        <a href={link} target="_blank" rel="noreferrer">
          <div
            className="travel__footer"
            style={{ backgroundImage: `url(${image})` }}
          >
            <AirPlaneIcon />
            <div>
              <h4>{text ?? "Discover the world, and learn in the process"}</h4>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="byp-m-0">1,925 different course</p>
                <ArrowRightOutlined />
              </div>
            </div>
          </div>
        </a>
      )}
    </>
  );
};
