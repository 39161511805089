import { Button, Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { URL_QUOTER_PAGE } from "../../quoter";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  MainThemeCarrouselQyp,
  ThemeCarrousel,
} from "../../../common/theme/partials/MainThemeCarrousel";
import { QuoteGalleryTemplateA } from "../../quote-preview/components/QuoteGalleryTemplateA";
import { useQuoter } from "../../../providers/quoter/quoter-course-context";

const HeaderQuote = () => {
  const navigate = useNavigate();
  const { course } = useQuoter();

  return (
    <div>
      <Row gutter={[0, 16]}>
        <Col xs={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <Button
                className="quote-byp__btn"
                onClick={() => navigate(URL_QUOTER_PAGE)}
              >
                <ArrowLeftOutlined />
              </Button>
            </Col>
            <Col>
              {/* <CreateAndAddToCollection buttonProps={buttonProps} /> */}
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <div
            className="image-quoter"
            style={{ borderRadius: "13px", overflow: "hidden" }}
          >
            {course?.offer.template === "1" && (
              <ThemeCarrousel
                slides={course?.offer.sliders}
                urlProperty="profile_photo_url"
              />
            )}
            {course?.offer.template === "2" && (
              <QuoteGalleryTemplateA
                gallery={course?.offer.gallery}
                videoUrl={course?.offer.video?.url}
              />
            )}
            {!(course?.offer.template === "2") &&
              !(course?.offer.template === "1") && (
                <div className="carrousel-gallery">
                  <MainThemeCarrouselQyp textProperty="text" />
                </div>
              )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export { HeaderQuote };
