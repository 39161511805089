import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetCampusByProgram } from '../../../bussines/hooks/school/useGetCampusByProgram';

const { Option } = Select;

function SelectCampusByProgramFormItem({ form, mode, program, showAll, ...props }) {
  const { isLoading, data } = useGetCampusByProgram(program);
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (values) => {
    if (values.includes("All")) {
      if (selectedValues.length === 0 || selectedValues.length < data.length) {
        setSelectedValues(data?.map(item => item.id) || []);
      } else {
        setSelectedValues([]);
      }
    } else {
      setSelectedValues(values);
    }
  };

  useEffect(() => {
    console.log(selectedValues);
    form.setFieldsValue({ sedes: selectedValues });
  }, [selectedValues, form]);

  return (
    <Form.Item name="sedes" {...props}>
      <Select
        {...props}
        style={{ width: '100%' }}
        placeholder={props.placeholder ?? ''}
        loading={isLoading}
        mode={mode}
        value={selectedValues}
        onChange={handleChange}
        onSelect={handleChange}
      >
        {showAll && (
          <Option key={0} value={"All"}>
            All campus
          </Option>
        )}
        {data?.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export { SelectCampusByProgramFormItem };
