import { Button, Col, Form, Input, Modal, Row, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import FarCastle from '../../../../common/assets/images/far-castle.png'
import BlueCastle from '../../../../common/assets/images/blue-castle.png'
import AlleyFestival from '../../../../common/assets/images/alley-festival.png'
import { useGetPage, usePostSavePageCountry, usePutSavePage } from '../../../../bussines/hooks/tables-page/useGetDataTablePage'
import { API_URL } from '../../../../config'
import { getBase64, headersForUpload } from '../../../../utils/uploadImageUtils'
import { EditIcon, MonitoringIcon } from '../../../quote-preview/assets/imgs/Icons'
// import EditProgramsBlog from './EditProgramsBlog'
import { IconSchool } from '../../../../common/assets/svg/icons'
import { HomeCarrousel } from '../../../../common/components/HomeCarrousel'
import { CardCourse } from '../../blog/components/CardCourse'
import { CardBlogSimple } from '../../blog/components/CardBlogSimple'
import { useNavigate } from 'react-router-dom'
import { URL_CITY_PREVIEW_PAGE, URL_COUNTRY_PREVIEW_PAGE } from '../../../programs/CountryPage'
import { showErrorModal } from '../../../../utils/errorModal'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'draft-js/dist/Draft.css'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../../../../common/assets/css/text-editor.less'
// import { useGetOfferRecomendations } from '../../../../bussines/hooks/offer/useGetOfferRecomendations'
import ModalAddBlog from './ModalAddBlog'
import ModalAddCourse from './ModalAddCourse'

function EditPageCountryInfo ({ code, city }) {
  const navigation = useNavigate()
  const [statusPage, setStatusPage] = useState('Pending')
  const [pageId, setPageId] = useState(null)
  const [idSercices, setIdServices] = useState([])
  const [idSercicesOrder, setIdServicesOrder] = useState([])
  const [blogs, setBlogs] = useState([])
  const [courses, setCourses] = useState([])
  const [isPreview, setIsPreview] = useState(false)
  const [modal, contextHolder] = Modal.useModal()
  const [values, setValues] = useState()

  const [form] = Form.useForm()

  const [recomendationFilters, setRecomendationFilters] = useState({
    country: code,
    city,
    distinctCourse: true,
    courses: idSercices
  })
  // const { data: offers } = useGetOfferRecomendations(recomendationFilters)

  const { savePageCountry, isLoading: isLoadingSave, error, data } = usePostSavePageCountry()
  const { updatePage, isLoading: isLoadingUpdate, error: errorUpdate, data: dataUpdate } = usePutSavePage()
  const { data: pageData, refetch, isLoading: isSearching } = useGetPage({ code, city_id: city })

  const [description, setDescription] = useState('')
  const [image01, setImage01] = useState(null)
  const [image02, setImage02] = useState(null)
  const [image03, setImage03] = useState(null)
  const [image01Url, setImage01Url] = useState(null)
  const [image02Url, setImage02Url] = useState(null)
  const [image03Url, setImage03Url] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalBlogOpen, setIsModalBlogOpen] = useState(false)
  const [isModalCoursesOpen, setIsModalCoursesOpen] = useState(false)
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [titleProgram] = useState('Nuestra recomendación de cursos')
  const [subtitleProgram] = useState('Descubre el mundo estudiando')
  const [titleBlog] = useState('Blog')
  const [subtitleBlog] = useState('Conoce más del mundo')
  const [idItem, setIdItem] = useState()
  const [idItemCourse, setIdItemCourse] = useState()
  const [blog, setBlog] = useState()
  const [course, setCourse] = useState()
  const [blogsIds, setBlogsIds] = useState([])
  const [blogsIdsOrder, setBlogsIdsOrder] = useState([])
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [slides, setSlides] = useState(4)
  const [slidesBlog, setSlidesBlog] = useState(3)
  const onFinish = (values) => {
    const images = []
    if (image01Url) {
      images.push(image01Url)
    }
    if (image02Url) {
      images.push(image02Url)
    }
    if (image03Url) {
      images.push(image03Url)
    }

    if (!pageId) {
      savePageCountry({
        body: description,
        name: values.title,
        city_id: city,
        code,
        status: statusPage,
        images,
        blocks: [
          {
            title: titleProgram,
            subtitle: subtitleProgram,
            items: idSercicesOrder ?? [],
            type: 'course'
          },
          {
            title: titleBlog,
            subtitle: subtitleBlog,
            items: blogsIdsOrder ?? [],
            type: 'blog'
          }
        ],
        institution: values.institution,
        campus: values.campus,
        program: values.program,
        city: values.city
      })
    } else if (pageId) {
      updatePage({
        body: description,
        name: values.title,
        city_id: city,
        code,
        status: values.status ?? statusPage,
        page: pageId,
        images,
        blocks: [
          {
            title: titleProgram,
            subtitle: subtitleProgram,
            items: idSercicesOrder ?? [],
            type: 'course'
          },
          {
            title: titleBlog,
            subtitle: subtitleBlog,
            items: blogsIdsOrder ?? [],
            type: 'blog'
          }
        ],
        institution: values.institution,
        campus: values.campus,
        program: values.program,
        city: values.city
      })
    }
  }

  useEffect(() => {
    if (!isLoadingSave && data) {
      modal.success({
        title: 'Succes',
        content: 'Data saved succesfully'
      })
      if (isPreview) {
        console.log('It should be navigating')
        if (city) {
          const url = URL_CITY_PREVIEW_PAGE.replace(':code', code)
          navigation(url.replace(':city', city))
        } else {
          navigation(URL_COUNTRY_PREVIEW_PAGE.replace(':code', code))
        }
      } else {
        refetch()
      }
    }
    if (!isLoadingSave && error) {
      showErrorModal(error.response?.data?.message)
    }
  }, [isLoadingSave, data, error])

  useEffect(() => {
    if (!isLoadingUpdate && dataUpdate) {
      if (isPreview) {
        console.log('It should be navigating')
        if (city) {
          const url = URL_CITY_PREVIEW_PAGE.replace(':code', code)
          navigation(url.replace(':city', city))
        } else {
          navigation(URL_COUNTRY_PREVIEW_PAGE.replace(':code', code))
        }
      } else {
        refetch()
        modal.success({
          title: 'Succes',
          content: 'Data saved succesfully'
        })
      }
    }
    if (!isLoadingUpdate && errorUpdate) {
      showErrorModal(errorUpdate.response?.data?.message)
    }
  }, [isLoadingUpdate, dataUpdate, errorUpdate])

  useEffect(() => {
    if (!isSearching && pageData) {
      const blocks = pageData.page?.blocks ?? []
      const blogsBlock = blocks.find((block) => block.type === 'blog')
      setBlogs(blogsBlock?.blogs ?? [])
      const idsBlogs = blogsBlock?.blogs?.map(item => item.id)
      setBlogsIds(idsBlogs ?? [])
      setBlogsIdsOrder(blogsBlock?.blogs?.map(item => ({ id: item.id, order: item?.pivot?.order })))
      const courseBlock = blocks.find((block) => block.type === 'course')
      setCourses(courseBlock?.courses ?? [])
      setIdServices(courseBlock?.courses?.map((blog) => blog.id) ?? [])
      setIdServicesOrder(courseBlock?.courses?.map((blog) => ({ id: blog.id, order: blog?.pivot?.order })) ?? [])
      const institutions = pageData.page?.institutions?.map((institutionsItem) => institutionsItem.id)
      const campus = pageData.page?.campus?.map((campusItem) => campusItem.id)
      // const program = pageData.page?.programs?.map((programItem) => programItem.name)
      const cities = pageData.page?.cities?.map((cityItem) => cityItem.id)
      const data = {
        title: pageData?.page?.title ? pageData?.page?.title : pageData?.name,
        description: pageData?.page?.body ? pageData?.page?.body : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at tellus et massa rutrum hendrerit. Quisque ut venenatis dolor, interdum facilisis massa. Quisque eros ipsum, varius eget turpis id, elementum tincidunt ipsum. Integer vehicula pellentesque sagittis. Praesent dictum viverra nibh et suscipit. Fusce commodo aliquam dui eget placerat. Nam a ligula id lacus sagittis pellentesque. Pellentesque quis facilisis turpis, vel tincidunt elit. Morbi nec lectus at leo volutpat ultrices. Praesent vehicula diam at lorem egestas pharetra. In congue dolor sed diam hendrerit suscipit. Curabitur vitae ipsum iaculis dolor eleifend dapibus.',
        blogs_title: blogsBlock?.title,
        blogs_subtitle: blogsBlock?.subtitle,
        // blogs: blogsBlock?.blogs?.map((blog) => blog.id),
        courses_title: courseBlock?.title,
        courses_subtitle: courseBlock?.subtitle,
        // courses: courseBlock?.courses?.map((blog) => blog.id),
        institution: institutions,
        campus,
        // program,
        city: cities
      }
      if (pageData?.page?.body) {
        const html = pageData?.page?.body ?? ''
        const contentBlock = htmlToDraft(html)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        setEditorState(() => EditorState.createWithContent(contentState))
        setDescription(pageData?.page?.body)
      }
      form.setFieldsValue(data)
      if (pageData?.page?.images) {
        const images = pageData?.page?.images.reverse()
        if (images[0]) {
          setImage01(images[0].profile_photo_url)
          setImage01Url(images[0].url)
        }
        if (images[1]) {
          setImage02(images[1].profile_photo_url)
          setImage02Url(images[1].url)
        }
        if (images[2]) {
          setImage03(images[2].profile_photo_url)
          setImage03Url(images[2].url)
        }
      }
      setPageId(pageData?.page?.id)
    } else if (!isSearching && !pageData) {
      form.setFieldsValue({
        title: 'Lorem Ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at tellus et massa rutrum hendrerit. Quisque ut venenatis dolor, interdum facilisis massa. Quisque eros ipsum, varius eget turpis id, elementum tincidunt ipsum. Integer vehicula pellentesque sagittis. Praesent dictum viverra nibh et suscipit. Fusce commodo aliquam dui eget placerat. Nam a ligula id lacus sagittis pellentesque. Pellentesque quis facilisis turpis, vel tincidunt elit. Morbi nec lectus at leo volutpat ultrices. Praesent vehicula diam at lorem egestas pharetra. In congue dolor sed diam hendrerit suscipit. Curabitur vitae ipsum iaculis dolor eleifend dapibus.'
      })
      setPageId(null)
      setImage01(null)
      setImage01Url(null)
      setImage02(null)
      setImage02Url(null)
      setImage03(null)
      setImage03Url(null)
    }
  }, [pageData, isSearching])
  //  Move this to teh EditProgramBlog component
  useEffect(() => {
    setRecomendationFilters({
      ...recomendationFilters,
      courses: idSercices
    })
  }, [idSercices])

  const handleChange = (event, index) => {
    if (event.file.status === 'done') {
      getBase64(event.file.originFileObj, (imageUrl) => {
        if (index === 1) {
          setImage01(imageUrl)
          setImage01Url(event.file.response.url)
        }
        if (index === 2) {
          setImage02(imageUrl)
          setImage02Url(event.file.response.url)
        }
        if (index === 3) {
          setImage03(imageUrl)
          setImage03Url(event.file.response.url)
        }
      })
    } else if (event.file.status === 'error') {
      console.log('🚀 ~ file error:', event.file.response)
    }
  }

  const showModal = () => {
    const e = form.getFieldsValue()
    setValues(e)
    if (!e?.description || !image01Url || !image02Url || !image03Url || notExistBlock(e, 'course') || notExistBlock(e, 'blog')) {
      setIsModalOpen(true)
    } else {
      e.status = 'Published'
      onFinish(e)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleOk = () => {
    onFinish(values)
    setIsModalOpen(false)
  }

  const notExistBlock = (values, type) => {
    if (type === 'course') {
      // titleProgram && subtitleProgram &&
      if (values?.courses?.length > 0) {
        return false
      }
    }
    if (type === 'blog') {
      // titleBlog && subtitleBlog &&
      if (values?.blogs?.length > 0) {
        return false
      }
    }
    return true
  }

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  const showModalBlog = () => {
    setIsModalBlogOpen(true)
  }
  const handleCancelBlog = () => {
    setIsModalBlogOpen(false)
  }
  const handleRemoveBlog = (id) => {
    setBlogs(currentValue => {
      return currentValue?.filter((item) => item.id !== id)
    })
    setBlogsIds(currentValue => {
      return currentValue?.filter((item) => item !== id)
    })
    setBlogsIdsOrder(currentValue => {
      return currentValue?.filter((item) => item.id !== id)
    })
  }
  const handleEditBlog = (id) => {
    setIdItem(id)
    setIsModalBlogOpen(true)
  }
  const handleOkBlog = () => {
    if (blog) {
      if (idItem) {
        setBlogs(currentValue => {
          const index = currentValue.findIndex(blog => blog.id === idItem)
          const newBlogs = [...currentValue]
          newBlogs.splice(index, 0, blog)
          return newBlogs
        })
        setBlogsIds(currentValue => {
          const index = currentValue.findIndex(blog => blog.id === idItem)
          const newBlogsIds = [...currentValue]
          newBlogsIds.splice(index, 0, blog.id)
          return newBlogsIds
        })
        setBlogsIdsOrder(currentValue => {
          const index = currentValue.findIndex(blog => blog.id === idItem)
          const newBlogsIds = [...currentValue]
          newBlogsIds.splice(index, 0, { id: blog.id, order: index })
          return newBlogsIds
        })
        handleRemoveBlog(idItem)
      } else {
        setBlogs(currentValue => {
          return [...currentValue, blog]
        })
        setBlogsIds(currentValue => {
          return [...currentValue, blog.id]
        })
        setBlogsIdsOrder(currentValue => {
          return [...currentValue, { id: blog.id, order: blogsIdsOrder?.length + 1 }]
        })
      }
    }
    setIdItem()
    setIsModalBlogOpen(false)
    form.resetFields(['cities-blogs'])
    form.resetFields(['blogs'])
  }
  const handleCancelCourses = () => {
    setIsModalCoursesOpen(false)
  }
  const showModalCourses = () => {
    setIsModalCoursesOpen(true)
  }
  const handleEditCourse = (id) => {
    setIdItemCourse(id)
    setIsModalCoursesOpen(true)
  }
  const handleRemoveCourse = (id) => {
    setCourses(currentValue => {
      return currentValue?.filter((item) => item.id !== id)
    })
    setIdServices(currentValue => {
      return currentValue?.filter((item) => item !== id)
    })
    setIdServicesOrder(currentValue => {
      return currentValue?.filter((item) => item.id !== id)
    })
  }
  const handleOkCourses = () => {
    if (course) {
      if (idItemCourse) {
        setCourses(currentValue => {
          const index = currentValue.findIndex(item => item.id === idItemCourse)
          const newCourses = [...currentValue]
          newCourses.splice(index, 0, course)
          return newCourses
        })
        setIdServices(currentValue => {
          const index = currentValue.findIndex(item => item.id === idItemCourse)
          const newCoursesIds = [...currentValue]
          newCoursesIds.splice(index, 0, course.id)
          return newCoursesIds
        })
        setIdServicesOrder(currentValue => {
          const index = currentValue.findIndex(item => item.id === idItemCourse)
          const newCoursesIds = [...currentValue]
          newCoursesIds.splice(index, 0, { id: course.id, order: index })
          return newCoursesIds
        })
        handleRemoveCourse(idItemCourse)
      } else {
        setCourses(currentValue => {
          return [...currentValue, course]
        })
        setIdServices(currentValue => {
          return [...currentValue, course.id]
        })
        setIdServicesOrder(currentValue => {
          return [...currentValue, { id: course.id, order: idSercices?.length + 1 }]
        })
      }
    }
    setIdItemCourse()
    setIsModalCoursesOpen(false)
    form.resetFields(['program'])
    form.resetFields(['campus'])
    form.resetFields(['courses'])
    form.resetFields(['city'])
    form.resetFields(['school'])
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    if (windowSize.width <= 800) {
      setSlides(1)
      setSlidesBlog(1)
    } else {
      setSlides(4)
      setSlidesBlog(3)
    }
  }, [windowSize])

  return (
    <Form
      onFinish={onFinish}
      form={form}
      style={{ paddingTop: '1rem', width: '100%', paddingLeft: '1.5rem' }}
    >
      {contextHolder}
      <Row gutter={[0, 16]}>
        <Col span={24} style={{ padding: '1.5rem', paddingBottom: '0' }}>
          <Row justify='space-between' style={{ paddingRight: '1.5rem' }}>
            <Col><h3 className='byp-title'>Editar lugar</h3></Col>
            <Col className='edit__buttons'>
              <Button
                onClick={() => setStatusPage('Pending')}
                htmlType='submit'
                loading={isLoadingSave || isLoadingUpdate}
              >
                Guardar
              </Button>
              <Button
                onClick={() => {
                  setStatusPage('Pending')
                  setIsPreview(true)
                }} htmlType='submit' loading={isLoadingSave || isLoadingUpdate}
                className='align-icon'
              >
                <MonitoringIcon /> Vista Previa
              </Button>
              <Button
                onClick={() => {
                  setStatusPage('Published')
                  showModal()
                }}
                loading={isLoadingSave || isLoadingUpdate}
              >
                Publicar
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={24}>
          <Row gutter={[0, 8]}>
            <Col span={24} style={{ padding: '0 1.5rem' }}>
              <Row>
                <Col className='edit-page__info'>Subir información y fotos</Col>
              </Row>
            </Col>
            {/* Content */}
            <Col span={24}>
              <Row justify='center'>
                <Col span={23} className='edit-page__form'>
                  <Row gutter={[16, 16]}>
                    <Col md={12}>
                      <Form.Item
                        name='title'
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <Input style={{ fontWeight: 'bold', fontSize: '1.5em', background: 'var(--gray)' }} className='edit-programs-blog__input' />
                      </Form.Item>

                      <label>Contenido:</label>
                      <Form.Item name='description' style={{ margin: '0' }}>
                        {/* <TextArea rows={16} maxLength={1000} showCount className='textarea-bg-gray edit-programs-blog__textarea' /> */}
                        <div style={{ borderRadius: '0.25rem', backgroundColor: '#F4F4F4', border: '1px solid #d9d9d9', minHeight: '25rem' }}>
                          <Editor
                            editorState={editorState}
                            onEditorStateChange={(state) => {
                              // setInitialice(false)
                              setEditorState(state)
                              const htmlData = draftToHtml(convertToRaw(state.getCurrentContent()))
                              console.log('data', htmlData)
                              setDescription(htmlData)
                            }}
                            onContentStateChange={(values) => {
                              // console.log('🚀 ~ file: BlockTextEditableV2.js:23 ~ BlockTextEditableV2 ~ values:', values)
                            }}
                            placeholder='Comienza a escribir'
                            toolbar={{
                              options: ['textAlign', 'inline', 'list'],
                              inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['unordered', 'ordered']
                              }
                            }}
                            wrapperClassName='byp-editor-wrapper'
                            editorClassName='byp-editor'
                            toolbarClassName='byp-editor-toolbar'
                          />
                        </div>
                      </Form.Item>
                      {/* <div style={{ textAlign: 'end' }}>
                        <p style={{ fontSize: '0.688rem', marginTop: '1rem' }}>máximo 1,000 aracteres</p>
                      </div> */}
                    </Col>

                    <Col md={12}>
                      <label className='icon-edit__page images__locations--big' htmlFor='image01__page'>
                        {image01 ? <img src={image01} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> : <img src={BlueCastle} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />}
                        <div className='icon'>
                          <EditIcon />
                        </div>
                      </label>
                      <div style={{ display: 'none' }}>
                        <Upload
                          name='image'
                          listType='picture-card'
                          className='upload-page__img'
                          showUploadList={false}
                          action={`${API_URL}/pages/images`}
                          id='image01__page'
                          onChange={event => handleChange(event, 1)}
                          headers={headersForUpload()}
                        >
                          {image01 ? <img src={image01} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> : <img src={BlueCastle} alt='avatar' style={{ width: '100%' }} />}
                        </Upload>
                      </div>

                      <Row gutter={8}>
                        <Col span={12}>
                          <label className='icon-edit__page images__locations--small' htmlFor='image02__page'>
                            {image02 ? <img src={image02} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> : <img src={FarCastle} style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />}
                            <div className='icon'>
                              <EditIcon />
                            </div>
                          </label>
                          <div style={{ display: 'none' }}>
                            <Upload
                              name='image'
                              listType='picture-card'
                              className='upload-page__img'
                              showUploadList={false}
                              action={`${API_URL}/pages/images`}
                              id='image02__page'
                              onChange={event => handleChange(event, 2)}
                              headers={headersForUpload()}
                            >
                              {image02 ? <img src={image02} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> : <img src={FarCastle} style={{ width: '50%', height: 'auto', borderRadius: '12px' }} />}
                            </Upload>
                          </div>
                        </Col>

                        <Col span={12}>
                          <label className='icon-edit__page images__locations--small' htmlFor='image03__page'>
                            {image03 ? <img src={image03} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> : <img src={AlleyFestival} style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />}
                            <div className='icon'>
                              <EditIcon />
                            </div>
                          </label>
                          <div style={{ display: 'none' }}>
                            <Upload
                              name='image'
                              listType='picture-card'
                              className='upload-page__img'
                              showUploadList={false}
                              action={`${API_URL}/pages/images`}
                              id='image03__page'
                              onChange={event => handleChange(event, 3)}
                              headers={headersForUpload()}
                            >
                              {image03 ? <img src={image03} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> : <img src={FarCastle} style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />}
                            </Upload>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* Courses and blogs */}
            <Col span={24}>
              <Row justify='center'>
                <Col span={23}>
                  <h4 style={{ color: '#373737', marginTop: '10px' }} className='title-with-icon'>
                    <IconSchool color='#373737' width='20px' height='20px' />{titleProgram}
                  </h4>
                  <p style={{ color: '#797979' }}>{subtitleProgram}</p>
                </Col>
              </Row>
              <Row style={{ padding: '2rem' }}>
                <Col span={23}>
                  <HomeCarrousel slides={courses ?? []} Slide={CardCourse} className='big-home__package edit_carousel edit_page-country' slidesPerRow={slides} isEditing editSection={8} editModal={showModalCourses} editItem={handleEditCourse} removeItem={handleRemoveCourse} />
                </Col>
              </Row>
            </Col>

            <Col span={24} className='byp-mb-1'>
              <Row justify='center'>
                <Col span={23}>
                  <h4 style={{ color: '#373737' }} className='title-with-icon'>{titleBlog}</h4>
                  <p style={{ color: '#797979' }}>{subtitleBlog}</p>
                </Col>
              </Row>
              <Row style={{ padding: '0 2rem' }}>
                <Col span={23}>
                  <HomeCarrousel slides={blogs ?? []} Slide={CardBlogSimple} className='big-home__package edit_carousel' slidesPerRow={slidesBlog} editSection={3} editModal={showModalBlog} removeItem={handleRemoveBlog} editItem={handleEditBlog} isEditing />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* <EditProgramsBlog
          titleProgram={titleProgram}
          setTitleProgram={setTitleProgram}
          subtitleProgram={subtitleProgram}
          setSubtitleProgram={setSubtitleProgram}
          titleBlog={titleBlog}
          setTitleBlog={setTitleBlog}
          subtitleBlog={subtitleBlog}
          setSubtitleBlog={setSubtitleBlog}
          setIdServices={setIdServices}
          setBlogs={setBlogs}
          setCourses={setCourses}
          country={code}
          city={city}
          setRecomendationFilters={setRecomendationFilters}
          recomendationFilters={recomendationFilters}
        /> */}
      </Row>

      <ModalAddBlog
        isModalOpen={isModalBlogOpen}
        handleCancel={handleCancelBlog}
        handleOk={handleOkBlog}
        code={code}
        city={city}
        setBlog={setBlog}
        blog={blog}
        blogsIds={blogsIds}
        setBlogs={setBlogs}
        editBlogs
      />

      <ModalAddCourse
        isModalOpen={isModalCoursesOpen}
        handleCancel={handleCancelCourses}
        handleOk={handleOkCourses}
        country={code}
        city={city}
        setCourse={setCourse}
        idSercices={idSercices}
      />

      <Modal title='' visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText='Publicar' cancelText='Cancelar'>
        <h5>¿Seguro que quieres publicar?</h5>
        {
          (!values?.description || !image01Url || !image02Url || !image03Url || notExistBlock(values, 'course') || notExistBlock(values, 'blog')) && (
            <>
              <p>Hace falta:</p>
              <ul>
                {!values?.description && <li>Descripcion</li>}
                {(values?.page?.images?.length < 3 || !image01Url || !image02Url || !image03Url) && <li>Fotos</li>}
                {notExistBlock(values, 'course') && <li>Programas</li>}
                {notExistBlock(values, 'blog') && <li>Blog</li>}
              </ul>
              <p>avisandote que es lo que te hace falta y si quieres publicar asi sin algunos datos</p>
            </>
          )
        }
      </Modal>
    </Form>
  )
}

export { EditPageCountryInfo }
