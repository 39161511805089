import { Col, Form, Input, Radio, Row } from 'antd'
import React from 'react'

function ApplicationTransportSection ({ form, transport, disabled }) {
  const haveTransport = Form.useWatch('haveTransport', form)

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <h2 className='subtitle-aplication byp-m-0'>Recepción de Aeropuerto</h2>
      </Col>
      <Col span={24} className='byp-mb-1'>
        <Row align='middle' gutter={[8, 8]}>
          <Col>
            <label className='label-aplication'>¿Necesitas transport?</label>
          </Col>
          <Col>
            <Form.Item className='byp-m-0' name='haveTransport'>
              <Radio.Group name='radiogroup' defaultValue='no' disabled={!!transport || disabled}>
                <Radio value='yes' className='radio_btn-quote'>Sí</Radio>
                <Radio value='no' className='radio_btn-quote'>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
      {haveTransport === 'yes' && transport && (
        <>
          <Col xs={24} md={12}>
            <label className='label-aplication'>Transporte</label>
            <Form.Item
              name='transport'
            >
              <Input className='aplication__input' disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <label className='label-aplication'>Viaje</label>
            <Form.Item
              name='travel'
            >
              <Input className='aplication__input' disabled />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  )
}

export default ApplicationTransportSection
