import React from 'react'
import { Col, Form, Input, Row, Typography } from 'antd'
// import { SelectCurrencyFormItem } from '../../../../../common/components/helper-form/SelectCurrencyFormItem'
// import { SelectAllCitiesFormItem } from '../../../../../common/components/helper-form/SelectAllCitiesFormItem'
import { API_URL } from '../../../../../config'
import { UploadLogo } from '../../../../../common/components/UploadLogo'
import { usePostDeleteLogo } from '../../../../../bussines/hooks/user/usePostDeleteLogo'
// import { SelectBranchesFormItem } from '../../../../../common/components/helper-form/SelectBranchesFormItem'
// import { SelectCampusFormItem } from '../../../../../common/components/helper-form/SelectCampusFormItem'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import { useStepByStep } from '../../../../../providers/stepbystep/step-service-provider'
// import ENTERPRISE_TYPES from '../../../../../bussines/utils/enterprise-types-control'
import FloatLabel from '../../../../../common/components/helper-form/FloatLabel'
import { SelectPhoneCodeFormItem } from '../../../../../common/components/helper-form/SelectPhoneCodeFormItem'

const { TextArea } = Input
const { Title, Text } = Typography

function UserProfileFieldsContainer () {
  const { user } = useAuth()
  const { form } = useStepByStep()
  const profilePhotoUrl = form.getFieldValue('profile_photo_url')
  const name = Form.useWatch('name', form)
  // const dob = Form.useWatch('date_of_birth', form)
  return (
    <>
      <p style={{ marginTop: '-20px', marginBottom: '40px' }}>
        Let's start, insert your data so that you have the opportunity to offer Additional costs to your customers,
        as well as customize quotes and searchses.
      </p>
      <Title style={{ marginBottom: '40px' }} level={5}><b>Basic information </b></Title >
      <UploadLogo
        action={`${API_URL}/user/upload/logo`}
        usePostDeleteLogo={usePostDeleteLogo}
        profileUrl={profilePhotoUrl}
        title='Add picture'
        form={form}
      />
      <FloatLabel label='Full name' value={name} >
        <Form.Item
          name='name'
          rules={[{ required: true, message: 'The name is required' }]} style={{ marginTop: '40px', marginBottom: '40px' }}
        >
          <Input />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label='Bio (about you)' value={Form.useWatch('biography', form)}>
        <Form.Item name='biography'>
          <TextArea rows={4} />
        </Form.Item>
      </FloatLabel>

      <Row gutter={16}>
        <Col span={12}>
          <Text level={5}>Your position in the company:</Text>
          <Form.Item
            name='position'
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input placeholder='' />
          </Form.Item>
        </Col>

      </Row>

      {/* Contact information */}
      <Title style={{ marginBottom: '20px' }} level={5}>Contact information</Title>

      <Row gutter={306}>
        <Col>
          Phone
        </Col>
        <Col>
          Whatsapp
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={4}>
          <SelectPhoneCodeFormItem name='phone_code' />
        </Col>
        <Col span={8}>
          <Form.Item
            name='phone'
            rules={[{
              // pattern: /[0-9]{12}/,
              pattern: /[0-9]/
              // message: 'El numero debe ser de 12 digitos'
            }]}
          >
            <Input maxLength={15} />
          </Form.Item>
        </Col>

        <Col span={4}>
          <SelectPhoneCodeFormItem name='whatsapp_code' />
        </Col>
        <Col span={8}>
          <Form.Item
            name='whatsapp'
            rules={[{
              // pattern: /[0-9]{12}/,
              pattern: /[0-9]/
              // message: 'El numero debe ser de 12 digitos'
            }]}
          >
            <Input maxLength={15} />
          </Form.Item>
        </Col>
      </Row>
      {/* End contact information */}

      {/* Extra info */}
      <Title level={5}>Schedule calls</Title>
      <FloatLabel label='Calendly' value={Form.useWatch('link', form)}>
        <Form.Item name='link'>
          <Input />
        </Form.Item>
      </FloatLabel>
      {/* End extra info */}
    </>
  )
}

export { UserProfileFieldsContainer }
