import { useQuery } from 'react-query'
import UserRepository from '../../../../repositories/UserRepository'

function useGetCampusByInstitutionAndProgram (program, institution, type, isHome = false) {
  return useQuery(
    ['useGetCampusByInstitutionAndProgram', program, institution, type, isHome],
    () => UserRepository.getCampusByInstitutionAndProgram(program, institution, type, isHome),
    {
      retry: 1,
      enabled: !!program && !!institution
    }
  )
}

export { useGetCampusByInstitutionAndProgram }
