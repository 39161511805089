import { Col, Row, Typography, Card } from 'antd'
import DefaultImage from '../../common/assets/images/bg-hero.png'
import { Link } from 'react-router-dom'
import { URL_COUNTRY_PAGE } from '../../pages/programs/CountryPage'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { useEffect, useState } from 'react'
import { URL_QUOTER_PAGE } from '../../pages/quoter'

/**
 * Card for cities and countries on home page
 * @param {Object} item The city/country data
 * @param {boolean} redirecToHomeOffers Contros if the link to redirect on click is to the home offers or the city/country blog
 * @returns Ant Link Component with a Card
 */
function CardPlaces ({ item, redirecToHomeOffers = false }) {
  const [placeImage, setPlaceImage] = useState(DefaultImage)
  const [link, setLink] = useState(URL_COUNTRY_PAGE.replace(':code', item?.iso2 ?? item?.country?.iso2 + '/' + item.id))

  useEffect(() => {
    const images = item?.page?.images ?? []
    const image = images[images?.length - 1] ?? undefined
    if (image) {
      setPlaceImage(image.src)
    }
  }, [item])

  useEffect(() => {
    if (redirecToHomeOffers) {
      const params = {
        program: 'language',
        place: [item?.id],
        place_type: item.country_id ? 'city' : 'country'
      }
      if (item.country_id) {
        params.city = [item?.id]
        params.country = [item?.country_id]
      } else {
        params.country = [item?.id]
      }

      const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&')
      setLink(URL_QUOTER_PAGE + '?' + queryString)
    }
  }, [redirecToHomeOffers])

  return (
    <Link to={link}>
      <Card
        hoverable
        style={{
          width: '100%',
          padding: 0,
          marginRight: 16,
          backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(20, 20, 20, 0.55) 100%), url(' + placeImage + ')',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: 260,
          borderRadius: 20,
          border: 'none'
        }}
        type='inner'
      >
        <Typography.Title level={3} className='card__country-title' style={{ paddingLeft: '.75vw' }}>
          {(item?.page?.title ?? item?.name)?.toUpperCase()}{item?.country?.name && ','} <br />
          {item?.country?.name?.toUpperCase()}
        </Typography.Title>
      </Card>
    </Link>
  )
}

function CardPlacesWithText ({ item }) {
  const [placeImage, setPlaceImage] = useState(DefaultImage)

  useEffect(() => {
    const images = item?.page?.images ?? []
    const image = images[0] ?? undefined
    if (image) {
      setPlaceImage(image.src)
    }
  }, [item])

  return (
    <div className='card__country--text' style={{ borderRadius: '1rem', overflow: 'hidden' }}>
      <div className='card__country-container'>
        <img className='card__country-image' src={placeImage} preview={false} alt='' />
      </div>

      <div style={{ padding: '0.75rem' }}>
        <Typography.Title level={3}>
          {item?.name}
        </Typography.Title>
        <div className='byp-mb-1 description__card-3' style={{ height: '20px' }}>
          {item?.page?.body}
          {/* <Text ellipsis={{ rows: 3, expandable: false }}>
          </Text> */}
          {/* <Text>
            {item?.page?.body}
          </Text> */}
        </div>

        <Row>
          <Col span={18}>
            <Typography.Text style={{ marginBottom: '1rem' }}>
              {item?.course_count} different courses
            </Typography.Text>
          </Col>
          <Col span={6} style={{ display: 'flex' }}>
            {/* <Link to={URL_COUNTRY_PAGE.replace(':code', item?.iso2 ?? item?.country?.iso2 + '/' + item.id)} style={{ width: '100%', textAlign: 'end', color: '#797979' }}>
              <ArrowRightAltIcon />
            </Link> */}
            <Link
              to={URL_QUOTER_PAGE}
              state={{ place: item.id, place_type: 'country' }}
            >
              <ArrowRightAltIcon />
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { CardPlaces, CardPlacesWithText }
