import api from '../../services/api'

class SchoolRepository {
  async register (args) {
    const response = await api.post('/register', args)
    return response.data
  }

  async getInfoStep () {
    const response = await api.get('/school/steps')
    return response.data
  }

  async postSteps ({ step, data }) {
    const response = await api.post(`/school/steps/${step}`, data)
    return response.data
  }

  async getCampus (queryString) {
    const response = await api.get(`/school/campus?${queryString}`)
    return response.data
  }

  async getCampusForUploadPrices (campusId, programId, queryString) {
    if (campusId === undefined || programId === undefined) return
    const response = await api.get(`/campus/${campusId}/program/${programId}/upload-prices?${queryString}`)
    return response.data
  }

  async getCategoryServices (params) {
    const response = await api.get('/get/service/categories', { params })
    return response.data
  }

  async getMyCategoryServices () {
    const response = await api.get('/school/service/categories')
    return response.data
  }

  async postDeleteLogo () {
    const response = await api.post('/school/delete/logo')
    console.log(response)
    return response.data
  }

  async postDeleteCampusLogo () {
    const response = await api.post('/campus/delete/logo')
    console.log(response)
    return response.data
  }

  async find () {
    const response = await api.get('/school/profile')
    return response.data
  }

  /**
   * Return the services (courses) of a category
   * Ej. Language, Postgrados
   */
  async getServicesByCategory (category) {
    const response = await api.get(`/school/services/${category}/list`)
    return response.data
  }

  /**
   * Return the services (courses) of a category and from a Campus/branch
   * Ej. Language From Campus Z
   */
  async getServicesByCategoryAndSede (category, sede, year, subType) {
    if (category === undefined || sede === undefined || year === undefined) {
      return
    }
    let typeQuery = ''
    if (subType) typeQuery = '&type_program=' + subType
    const response = await api.get(`/services/school/${sede}/${category}/list?year=` + year + typeQuery)
    console.log('🚀 ~esponse', response.data)
    return response.data
  }

  async getCourses (queryString) {
    const response = await api.get(`/school/courses/list?${queryString}`)
    return response.data
  }

  /**
   * Save School Data Methods
   */
  async savePrices ({ data }) {
    const response = await api.post('/school/prices', data)
    return response.data
  }

  /**
   * Save School Data Methods
   */
  async duplicatePrices ({ data }) {
    const response = await api.post('/school/prices/duplicate', data)
    return response.data
  }

  async saveCourses ({ data }) {
    const response = await api.post('/school/courses', data)
    return response.data
  }

  async update (data) {
    const response = await api.put('/school/profile', data)
    return response.data
  }

  /**
   * Documents
   */
  async saveDocuments ({ data }) {
    const response = await api.post('/documents', data)
    return response.data
  }

  async findDocument (id) {
    const response = await api.get(`/documents/${id}`)
    return response.data
  }

  async getDocuments () {
    const response = await api.get('/documents')
    console.log('🚀 ~ file: SchoolRepository.js ~ line 119 ~ SchoolRepository ~ getDocuments ~ response', response.data)
    return response.data
  }

  async deleteDocument ({ id }) {
    const response = await api.delete(`/documents/${id}/delete`)
    return response.data
  }
}

export default new SchoolRepository()
