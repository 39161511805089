import { Statistic, Switch, Typography } from 'antd'
import React, { useEffect } from 'react'
import { RowQuoter } from '../../quote-course/components/RowQuoter'
import { usePackage } from '../../../providers/package/package-course-context'

const { Text } = Typography

function Additionals () {
  const { service, setFormConfig, formConfig, course, isLoadingOffer, prices } = usePackage()

  const handleChange = (value, item) => {
    let agencyServices = []
    if (!formConfig.agencyServices) {
      setFormConfig({
        ...formConfig,
        additionals: []
      })
    }
    if (value) {
      agencyServices = formConfig.additionals ?? []
      agencyServices?.push(item)
    } else {
      formConfig.additionals?.map((service) => (
        service.id !== item.id ? agencyServices.push(service) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      additionals: agencyServices
    })
  }

  useEffect(() => {
    if (course && !isLoadingOffer) {
      const loadInitialData = async () => {
        if (!formConfig.additionals) {
          setFormConfig({
            ...formConfig,
            additionals: []
          })
        }

        const additionalsCosts = formConfig.additionals ?? []
        try {
          const addttions = service?.getAdditions()
          if (addttions) {
            for (const item of addttions) {
              if (item.mandatory) {
                additionalsCosts.push({ ...item })
              }
            }
          }
          setFormConfig(prevFormConfig => ({
            ...prevFormConfig,
            additionals: additionalsCosts
          }))
        } catch (error) {
          console.error('Error loading initial data:', error)
        }
      }
      loadInitialData()
    }
  }, [course, isLoadingOffer, prices])

  return (
    <>
      {service.getAdditions()?.length > 0 && (
        service.getAdditions()?.map((d) => (
          <RowQuoter
            key={d.id}
            colA={
              <>
                <div key={d.id}>
                  <Text  className='conceptMonetario' >{d.concept}</Text>
                </div>
              </>
            }
            colB={
              <>
                <div key={d.id}>
                  {service?.getAdditional(d.id)
                    ? (
                      <Statistic
                        prefix=''
                        precision={2}
                        // value={d.cost}
                        // suffix={`${service.getCurrency()}`}
                        value={`${service.getAdditional(d.id)?.cost}`}
                        // suffix={`${service.getCurrency()}`}
                      />
                      )
                    : (
                      <>-</>
                      )}
                </div>
              </>
            }
            colC={
              <>
                {!d.mandatory && (
                  <Switch
                    // onChange={() => service.addAdditionalsActives(d.id)}
                    onChange={(value) => { handleChange(value, d) }}
                    checked={service.includeAdditions(d.id)}
                    // checkedChildren='YES'
                    // unCheckedChildren='NO'
                  />
                )}
              </>
            }
          />
        )
        ))}
    </>
  )
}

export { Additionals }
