import { useQuery } from 'react-query'
import OfferRespository from '../../repositories/OfferRespository'

function useGetOffers (filters = {}, enabled = true) {
  return useQuery(['useGetOffers', filters],
    () => OfferRespository.offers(filters), {
      retry: 2,
      enabled
    })
}

export { useGetOffers }
