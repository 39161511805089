import api from '../../services/api'

class DiscountRepository {
  async find (id) {
    if (id === undefined) {
      return null
    }
    const response = await api.get(`/discounts/${id}`)
    console.log('🚀 ~ file: DiscountRepository.js ~ line 10 ~ DiscountRepository ~ find ~ response.data', response.data)
    return response.data
  }

  async create ({ data }) {
    const response = await api.post('/discounts/create', data)
    return response.data
  }

  async update ({ data, id }) {
    const response = await api.put(`/discounts/${id}/update`, data)
    return response.data
  }

  async updateStatus ({ data, id }) {
    const response = await api.put(`/discounts/${id}/update-status`, data)
    return response.data
  }

  async activate ({ data }) {
    const response = await api.post('/discount/activate', data)
    return response.data
  }

  async delete ({ id }) {
    const response = await api.delete(`/discounts/${id}/delete`)
    return response.data
  }

  async getDiscounts (filtersQuery) {
    const response = await api.get('/discounts?' + filtersQuery)
    return response.data
  }

  async getDiscountsByProgram (program) {
    const response = await api.get(`/discounts?program=${program}`)
    return response.data
  }

  async getBlockPrices (block, discount, year) {
    if (block === undefined || discount === undefined || year === undefined) {
      return null
    }
    const response = await api.get(`/discounts/${discount}/${block}/prices?year=${year}`)
    return response.data
  }

  async getBlockApplied (discount) {
    const response = await api.get(`/discounts/${discount}/applied`)
    return response.data
  }

  async saveValues ({ data }) {
    const response = await api.post('/discounts/values', data)
    console.log('🚀 ~ file: DiscountRepository.js ~ line 48 ~ DiscountRepository ~ saveValues ~ response.data', response.data)
    return response.data
  }

  async getPackageValues (discount, year, filters) {
    const response = await api.get(`/discounts/${discount}/package_prices`, { params: { ...filters, year } })
    return response.data
  }

  async savePackageValues ({ discountId, data }) {
    const response = await api.post(`/discounts/${discountId}/package_prices`, data)
    return response.data
  }

  async years (id) {
    const response = await api.get(`/discounts/${id}/years`)
    return response.data
  }
}

export default new DiscountRepository()
