import { useQuery } from 'react-query'
import PageRepository from '../../repositories/PageRepository'

function useGetPageComments (slug, filters = {}) {
  return useQuery(
    ['useGetPageComments', slug, filters],
    () => PageRepository.comments(slug, filters),
    {
      retry: 1,
      enabled: !!slug
    }
  )
}

export { useGetPageComments }
