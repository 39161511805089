import React from 'react'
import { PageProtected } from '../../../common/components/PageProtected'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { MainTheme } from '../../../common/theme/MainTheme'
import { EditPageCountryInfo } from './components/EditPageCountryInfo'
import { useParams } from 'react-router-dom'

const URL_EDIT_PAGE_COUNTRY_PAGE = '/edit/country-page/:code'
const URL_EDIT_PAGE_CITY_PAGE = '/edit/country-page/:code/:city'

function EditPageCountryPage () {
  const { code, city } = useParams()

  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
    >
      <MainTheme
        showMenu
        // className='site-layout__padding'
      >
        <div className='byp-align-centered' style={{ padding: 0 }}>
          <EditPageCountryInfo code={code} city={city} />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { EditPageCountryPage, URL_EDIT_PAGE_COUNTRY_PAGE, URL_EDIT_PAGE_CITY_PAGE }
