import { Form, Select } from 'antd'
import React from 'react'
import { useGetCoursesByProgram } from '../../../bussines/hooks/school/course/useGetCoursesByProgram'
// import { usePutCourseExpired } from '../../../bussines/hooks/school/course/usePutCourseExpired'
const { Option } = Select
function SelectServiceByCategory ({ onChange, mode, program, showAll, ...props }) {
  const { isLoading, data } = useGetCoursesByProgram(program)
  // usePutCourseExpired();
  return (
    <Form.Item {...props}>
      <Select
        {...props}
        showSearch
        style={{ width: '100%' }}
        placeholder={props.placeholder ?? ''}
        loading={isLoading}
        mode={mode}
        className='byp-m-0'
        onChange={onChange}
      >
        {showAll && <Option key={0} value={null}>All</Option>}
        {data?.map(item => (
          <Option key={item.id} value={item.id}>{item.label}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectServiceByCategory }
