import { Col, Form, Input, Row, Select } from 'antd'
import ENTERPRISE_TYPES from '../../../../bussines/utils/enterprise-types-control'
const { Option } = Select

function InputSenderAgencyTeam ({ form, type }) {
  return (
    <Form name='sendEmail' form={form} scrollToFirstError layout='inline'>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
          Email
          <Form.Item
            name='email'
            rules={[
              {
                type: 'email',
                message: 'No es un correo válido!'
              }
            ]}
          >
            <Input placeholder='email' />
          </Form.Item>
        </Col>
        <Col span={12}>
          Rol
          <Form.Item
            name='role'
            rules={[{ required: true, message: 'El rol es requerido' }]}
            style={{ width: '100%' }}
          >
            {type === ENTERPRISE_TYPES.AGENCY
              ? (
                <Select>
                  <Option value='Administrador'>Administrador</Option>
                  <Option value='Editor'>Editor</Option>
                  <Option value='Gerente Comercial'>Gerente Comercial</Option>
                  <Option value='Consultor'>Consultor</Option>
                </Select>
                )
              : (
                <Select>
                  <Option value='Administrador'>Administrador</Option>
                  <Option value='Editor'>Editor</Option>
                  <Option value='Marketer'>Marketer</Option>
                </Select>
                )
              }
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export { InputSenderAgencyTeam }
