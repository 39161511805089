import { useQuery } from 'react-query'
import OfferRespository from '../../repositories/OfferRespository'

function useGetOfferLanguages (filters = {}, enabled = true) {
  return useQuery(['useGetOfferLanguages', filters],
    () => OfferRespository.offerAvailableLanguages(filters), {
      retry: 2,
      enabled
    })
}

export { useGetOfferLanguages }
