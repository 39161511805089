import React, { useEffect, useState, useContext } from 'react'
import { Avatar, Button, Col, Image, Input, Layout, Modal, PageHeader, Row, Statistic, notification } from 'antd'
import { QuoterForm } from './components/QuoterForm'
import { useQuoter } from '../../providers/quoter/quoter-course-context'
import '../../common/assets/css/switch-theme.less'
import { QuoterHighEducationForm } from './components/QuoterHighEducationForm'
import { ProgramIntake } from './components/ProgramIntake'
import { StarFilled, ShareAltOutlined, LinkOutlined } from '@ant-design/icons'
import { SelectCurrencyFormItem } from '../../common/components/helper-form/SelectCurrencyFormItem'
import { useAuth } from '../../providers/auth/auth-context-provider'
import { useGetCurrencyToCurrency } from '../../bussines/hooks/currencies/useGetCurrencyToCurrency'
import { handlePrice } from '../../utils/getCountryName'
// import moment from 'moment'
import { QuoteDivisa } from './components/QuoteDivisa'
// import UrlShortener from './components/UrlShortener'
import DiscountText from './components/DiscountText'
import { image } from '../../utils/fallbackImage'
import { useGetOfferExtras } from '../../bussines/hooks/extras/useGetOfferExtras'
import LogoFacebook from '../../common/assets/images/facebook_logo.png'
import LogoWhatsapp from '../../common/assets/images/whatsapp_logo.png'
import { institutionTypes } from '../../languageConfiguration/infoCourseTranslate'
import { UrlContext } from './context/UrlContext'

const { Content } = Layout

export default function FormContent ({ setHasPermision, setImage, setLink }) {
  const { shortUrl, setShortUrl } = useContext(UrlContext)
  const [originalUrl] = useState(`${window.location.href}`)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const { handleShare, copyToClipboard } = useQuoter()
  const {
    course, service, offerError, isOfferError, isLoadingOffer, submit, isLoadingPost,
    changeCurrency, setChangeCurrency, opinionsData, prices, getLinkToShare, setFavorite
  } = useQuoter()
  const [extraCoin, setExtraCoin] = useState()
  const [showLangQuoter, setShowLangQuoter] = useState()
  const [GeneralOpinion, setGeneralOpinion] = useState()
  const {
    user:
    _user,
    isPublicUser,
    // userCountryLocation
    userLocalCurrency,
    setUserLocalCurrency
  } = useAuth()
  const {
    data: newdata,
    getCurrencyToCurrency,
    isLoading
  } = useGetCurrencyToCurrency()
  const [offerId, setOfferId] = useState()
  const { data } = useGetOfferExtras(offerId)
  const [isChange, setIsChange] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [description, setDescription] = useState('')

  const handleShareClick = async () => {
    setIsButtonEnabled(false)
    await handleShare()
    setShowModal(true)
    setIsButtonEnabled(true)
  }

  useEffect(() => {
    setIsButtonEnabled(!isLoadingPost)
  }, [isLoadingPost])

  useEffect(() => {
    if (prices) {
      setOfferId(prices.offer_id)
    } else {
      const p = service?.getPrices()
      setOfferId(p.offer_id)
    }
  }, [prices])

  // const handleApply = () => {
  //   if (isPublicUser) {
  //     // Do something
  //   }
  //   submit()
  // }

  const handleClick = (value) => {
    setUserLocalCurrency(value)
    setIsChange(false)
  }

  function addHttpsIfNotExist (url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    return url
  }

  useEffect(() => {
    if (offerError && isOfferError) {
      console.log('🚀 ~ file: index.js ~ line 94 ~ useEffect ~ offerError', offerError)
    }
  }, [offerError, isOfferError])

  useEffect(() => {
    const program = course?.offer?.program?.name
    if (program === 'language' || program === 'summer_camps') {
      setShowLangQuoter('yes')
    } else {
      setShowLangQuoter('no')
    }

    if (!course?.is_active) {
      setHasPermision(false)
    }
  }, [course])

  useEffect(() => {
    if (_user && _user?.publicityVertical && _user?.publicityVertical?.length > 0) {
      if (_user?.publicityVertical[0]?.src) setImage(_user?.publicityVertical[0]?.src)
      if (_user?.publicityVertical[0]?.link) setLink(addHttpsIfNotExist(_user?.publicityVertical[0]?.link))
    }
  }, [_user])

  useEffect(() => {
    const getCurrency = async () => {
      setChangeCurrency(!changeCurrency)
    }
    getCurrency()
  }, [])

  useEffect(() => {
    const getCurrency = async () => {
      if (userLocalCurrency) {
        setChangeCurrency(!changeCurrency)
      }
    }
    getCurrency()
  }, [userLocalCurrency])

  useEffect(() => {
    if (opinionsData) {
      const general = opinionsData?.rates?.find((rate) => rate.type === 'general')
      setGeneralOpinion(general)
    }
  }, [opinionsData])

  useEffect(() => {
    getCurrencyToCurrency(userLocalCurrency, service.getCurrency())
  }, [changeCurrency])

  useEffect(() => {
    if (!isLoading && newdata) {
      if (!newdata.data) {
        notification.error({
          message: 'Exchange Value Not found'
        })
      }
    }
  }, [isLoading, newdata])

  useEffect(() => {
    const extras = data?.campus?.extras
    const description = extras?.find((extra) => extra.type === 'description')
    setDescription(description)
  }, [data])

  const [title, setTitle] = useState(null)

  useEffect(() => {
    if (course) {
      const item = course?.offer
      if (item?.translations && item?.translations?.length > 0) {
        const label = item?.translations?.find(item => item?.to === 'es' && item?.attribute === 'label')
        setTitle(label?.translation)
      } else {
        setTitle(item?.name)
      }
    }
  }, [course])

  const handleShareFacebook = () => {
    const urlACompartir = getLinkToShare()
    const facebookCompartirUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(urlACompartir)

    // window.open(facebookCompartirUrl, '_blank', 'width=600,height=400')
    return facebookCompartirUrl
  }

  const handleShareWhatsapp = () => {
    let urlACompartir = getLinkToShare()
    urlACompartir = urlACompartir.replace(/{/g, '%7B').replace(/}/g, '%7D').replace(/"/g, '%22').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\[/g, '%5B').replace(/\]/g, '%5D')
    const mensaje = '¡Echa un vistazo a este enlace: ' + urlACompartir
    const whatsappCompartirUrl = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(mensaje)

    window.open(whatsappCompartirUrl, '_blank')
  }

  if (offerError && isOfferError) {
    return 'ups'
  }

  return (
    <PageHeader
      className='antd-card content-quoter byp-mb-1'
      ghost={false}
      title={
        <Row>
          <Col>
            <h3 className='byp-m-0 cotizacion__title'>Personaliza tu cotización</h3>
          </Col>
        </Row>
      }
      extra={
        GeneralOpinion?.total_opinions > 0 && (
          <small key='3' className='byp-m-0 cotizacion__rate'>
            <span><StarFilled />{GeneralOpinion?.rate?.toFixed(1)}</span> ({GeneralOpinion?.total_opinions} calificaciones) {GeneralOpinion?.total_opinions} estudiantes
          </small>
        )
      }

    >
      {/* <p className='cotizacion__description'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dignissim orci venenatis urna vulputate eleifend. Phasellus ut lorem nisi. Nunc porttitor eget lorem pharetra elementum.
      </p> */}
      <Row justify='space-between' align='middle'>
        <Row align='middle' className='byp-mb-1'>
          <Col>
            <Avatar
              className='cotizacion__avatar'
              src={course?.offer?.campus?.profilePhotoUrl}
              icon={<img src={course?.offer?.campus?.parent?.profile_photo_url} alt='' />}
            />
          </Col>
          <Col className='byp-justify-centered'>
            <h4 className='byp-m-0 quote-course--title__responsive cotizacion__name-campus'>{course?.offer?.campus?.name}</h4>
          </Col>
        </Row>

        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <small className='quotation__address' key='3'>{course?.offer?.campus?.address}</small>
          {isChange && (
            <div style={{ width: '200px' }}>
              <SelectCurrencyFormItem
                name='currency'
                size='medium'
                setDivisa={handleClick}
                sizeWidth='100%'
                style={{ width: '100%', margin: '0' }}
              />
            </div>
          )}
        </Col>
      </Row>

      {/* <h3 className='byp-title byp-text-md'>{course?.offer?.campus?.group_name}</h3> */}
      <h3 className='cotizacion__name-offer quotation__title'>{title}</h3>
      {course?.offer?.program?.name === 'graduate' && (
        <Row className='high-info-quote'>
          <Col span={12}>
            {course?.offer?.code && (<span>Code: <b>{course?.offer?.code}</b></span>)}
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <p style={{ marginBottom: '.4rem' }}>Duración: <b>{service?.courseDuration()}</b></p>
            <p>Tipo de institución: <b>{institutionTypes.es[course?.offer?.campus.type] ?? course?.offer?.campus.type}</b></p>
          </Col>
        </Row>
      )}
      {course?.offer?.program?.name === 'graduate' && (
        <Row className='high-info-quote-tablet'>
          <Col span={12}>
            {course?.offer?.code && (<span>Code: <b>{course?.offer?.code}</b></span>)}
            <ProgramIntake />
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <p>Tipo de institución: <b>{institutionTypes.es[course?.offer?.campus.type] ?? course?.offer?.campus.type}</b></p>
            <p style={{ marginBottom: '.4rem' }}>Duración del programa: <b>{service?.courseDuration()}</b></p>
          </Col>
        </Row>
      )}
      {(course?.offer?.program?.name === 'academic_years' || course?.offer?.program?.name === 'boarding_schools') && (
        <Row>
          <Col span={12}>
            <p>Grades: <span className='byp-fw-bold'>{course?.offer?.campus?.grades?.join('-')}</span></p>
            <p>Course type: <span className='byp-fw-bold'>{course?.offer?.type}</span></p>
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <p>Duración: <span className='byp-fw-bold'>{course?.offer?.length} {course?.offer?.time}</span></p>
            <p>
              Type of Institution: <span className='byp-fw-bold'>{institutionTypes.es[course?.offer?.campus.type] ?? course?.offer?.campus.type}</span>
            </p>
          </Col>
        </Row>
      )}
      <Content style={{ paddingBottom: '1rem' }}>
        {!isLoadingOffer && showLangQuoter === 'yes' && <QuoterForm />}
        {!isLoadingOffer && showLangQuoter === 'no' && <QuoterHighEducationForm />}
        <Row gutter={[16, 16]} style={{ marginBottom: '1.25rem' }}>
          <Col md={16}>
            <DiscountText />
            {/* La promoción es valida para inscripciones, hasta el día {handleFormat(prices?.year?.end)}. */}
            <p className='byp-m-0 notice__quote'>
              <span>AVISO:</span> Precios sujetos a cambios sin previo aviso. Aplican restricciones. El hospedaje está sujeto a disponibilidad de lugares. Es
              responsabilidad del estudiante contar con los permisos de entrada al país destion. De acuerdo al país de
              origen se aplican restricciones de Visado, más información <a href='#'>aquí.</a><br />
              Precios en [{userLocalCurrency}] son como referencia sujetos al T.C. a la venta del día que se liquide el programa
            </p>
          </Col>
          <Col xs={24} md={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='cotizacion__total'>
              {userLocalCurrency && (
                <div className='cotizacion-currency'>
                  <span>Aprox.</span>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%' }}>
                    {service.getTotalDiscounts() === 0 &&
                      <Statistic
                        className='cotizacion__total-currency'
                        prefix='$'
                        precision={2}
                        value={newdata?.data?.value ? `${handlePrice(service.getSubTotalQuoteAmount(), newdata.data?.value)}` : `${service.getSubTotalQuoteAmount()}`}
                        suffix={newdata?.data?.value ? `${userLocalCurrency}` : `${service.getCurrency()}`}
                      />}
                    {
                      service.getTotalDiscounts() > 0 && (
                        <Statistic
                          prefix='$'
                          className='price-after cotizacion__total-currency'
                          precision={2}
                          value={newdata?.data?.value ? `${handlePrice(service.getTotalQuoteAmount(), newdata.data?.value)}` : `${service.getTotalQuoteAmount()}`}
                          suffix={newdata?.data?.value ? `${userLocalCurrency}` : `${service.getCurrency()}`}
                        />
                      )
                    }
                  </div>
                </div>
              )}
            </div>
            <div className='cotizacion__total'>
              <Statistic
                className={service.getTotalDiscounts() > 0 ? 'price-before cotizacion_total-discount' : null}
                prefix='$'
                precision={2}
                value={`${service.getSubTotalQuoteAmount()}`}
                // suffix={`${service.getCurrency()}`}
              />
              {
                service.getTotalDiscounts() > 0 && (
                  <Statistic
                    prefix='$'
                    className='price-after cotizacion_total'
                    precision={2}
                    value={`${service.getTotalQuoteAmount()}`}
                    // suffix={`${service.getCurrency()}`}
                  />
                )
              }
            </div>
            {/* <p className='byp-m-0' style={{ alignSelf: 'flex-end' }}>Costo Total</p> */}
          </Col>
        </Row>
        <Row justify='space-between' gutter={12}>
          <Col style={{ display: 'flex' }} className='quotation__footer-btns' xs={24} md={12}>
            <Button
              // onClick={handleShare}
              // onClick={() => setShowModal(true)}
              onClick={handleShareClick}
              loading={isLoadingPost}
              className='quote__btn-quote'
              style={{ marginRight: '1rem' }}
              disabled={!isButtonEnabled}
            ><ShareAltOutlined /> <span className='quotation__header-text'>Compartir</span>
            </Button>
            {/* <CreateAndAddToCollection className='quotation__header-text' /> */}
          </Col>
          <Col xs={24} md={6} xxl={4} className='quotation__marging'>
            <Button
              onClick={(event) => {
                setFavorite(false)
                submit(event)
              }}
              loading={isLoadingPost}
              className='quote-apply_now'
            >
              Aplicar Ahora
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <p className='byp-mt-1 byp-mb-1' style={{ fontWeight: '700' }}>¿Quieres verlo en otro divisa?</p>
            <QuoteDivisa
              divisa={service.getCurrency()}
              lastCurrency={service.getTotalQuoteAmount()}
              setExtraCoin={setExtraCoin}
              extraCoin={extraCoin}
            />
          </Col>
        </Row>
      </Content>
      <Modal title='Compartir' visible={showModal} footer='' onCancel={() => setShowModal(false)}>
        <Row>
          <Col span={24} className='info-quote__campus byp-mb-1'>
            <Image
              src={course?.offer?.campus?.profilePhotoUrl ?? image}
              style={{ width: '2.5rem', aspectRatio: '1' }}
              fallback={image}
            />
            {/* <img src={course?.offer?.campus?.profilePhotoUrl ?? image} alt={course?.offer?.campus?.name} style={{ width: '3.75rem', aspectRatio: '1' }} /> */}
            <h4>{course?.offer?.campus?.name}</h4>
          </Col>
          <Col span={24}>
            <p className='quote-byp__mb quote-byp__description'>
              {description?.value ?? course?.offer?.campus?.description}
            </p>
          </Col>
          <Col span={24} style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '20px' }}>
            <UrlContext.Provider value={{ shortUrl, setShortUrl, originalUrl }}>
              <LinkOutlined style={{ fontSize: '20px' }} />
              <Input
                id='myInput'
                value={shortUrl}
                suffix={
                  <Button onClick={() => copyToClipboard(shortUrl)} style={{ color: 'var(--blue-100)', border: 'none', marginRight: '-10px' }}>COPY</Button>
              }
              />
            </UrlContext.Provider>
          </Col>
          <Col span={24} className='byp-mt-2'>
            <h5 className='text-align-center'>Compartir</h5>
            <Row gutter={[16, 16]} align='middle' justify='center'>
              <Col>
                <a className='d-flex justify-center align-center flex-column' href={handleShareFacebook()} target='_blank' rel='noreferrer'>
                  <Image
                    src={LogoFacebook}
                    style={{ width: '2.5rem', aspectRatio: '1' }}
                    fallback={LogoFacebook}
                    preview={false}
                  />
                  <p>Facebook</p>
                </a>
              </Col>
              <Col>
                <div className='d-flex justify-center align-center flex-column' onClick={handleShareWhatsapp}>
                  <Image
                    src={LogoWhatsapp}
                    style={{ width: '2.5rem', aspectRatio: '1' }}
                    fallback={LogoWhatsapp}
                    preview={false}
                  />
                  <p>WhatsApp</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </PageHeader>
  )
}
