import { useEffect, useState, useRef } from 'react'
import { useGetEmbedVideo } from '../../bussines/hooks/vimeo/useGetEmbedVideo'
import { Modal } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import axios from 'axios'

const VimeoEmbed = ({ videoID }) => {
  const [videoId, setVideoId] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [image, setImage] = useState(null)
  const { data: response } = useGetEmbedVideo(videoId)
  const iframeRef = useRef(null); 

  useEffect(() => {
    if (response) console.log('embed response', response)
  }, [response])

  useEffect(() => {
    if (videoID) {
      console.log('🚀 ~ useEffect ~ videoID:', videoID)
      const parts = videoID.split('/')
      const size = parts.length
      const id = parts[size - 1] ?? undefined
      const urlVimeo = `https://vimeo.com/${id}`
      setVideoId(urlVimeo)
    }
  }, [videoID])

  const getJsonOfVideo = async (id) => {
    const response = await axios.get('https://vimeo.com/api/v2/video/' + id + '.json')
    if (response && response?.data && response?.data?.length > 0) {
      setImage(response?.data[0]?.thumbnail_large)
      return response?.data[0]?.thumbnail_large
    }
  }

  const getVimeoThumbnailUrl = (url) => {
    if (url) {
      const videoID = url.match(/\/(\d+)/)[1]
      getJsonOfVideo(videoID)
      return getJsonOfVideo(videoID)
    }
  }

  useEffect(() => {
    if (videoID) getVimeoThumbnailUrl(videoID)
  }, [videoID])

  const stopVideo = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current.querySelector('iframe');
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ method: 'pause' }, '*')
      }
    }
  }

  return (
    <div className=''>
      {response && (
        <>
          {videoID && (
            <div className='vimeo-thumbnail'>
              {image && <img src={image} alt='video' />}
              <div className='vimeo-thumbnail__play' onClick={() => setIsModalOpen(true)}>
                <CaretRightOutlined />
              </div>
            </div>
          )}
        </>
      )}
      {!response && (
        <div style={{ width: '100%', height: '100%', background: 'white' }}>
          <></>
        </div>
      )}
     <Modal
        title=''
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          stopVideo(); 
        }}
        okButtonProps={{ style: { display: 'none' } }}
        width='100%'
        cancelText='Close'
        centered={false}
        className='modal-video'
      >
        <div
          dangerouslySetInnerHTML={{ __html: response?.html }}
          className='vimeo-form'
          ref={iframeRef} 
        />
      </Modal>
    </div>
  )
}

export { VimeoEmbed }
