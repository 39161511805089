import { Button, Col } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

function DeleteButton ({ remove, name, notDelete, labelDelete }) {
  return (
    !notDelete && (
      <>
        <Button
          className={labelDelete ? 'byp-btn-list-function-del' : 'byp-btn-list-function byp-bg-gray'}
          onClick={() => remove(name)}
          size='small'
          icon={<MinusOutlined />}
          style={labelDelete && { width: '100%' }}
        >
          {labelDelete}
        </Button>
      </>
    )
  )
}

function AddAndRemoveListButtons ({ fields, index, add, remove, name, notDelete, labelAdd, labelDelete, withOutDelete }) {
  if (fields.length > 1 ) {
    return (
      <>
        <Col style={{ display: 'inline-flex', gap: '10px' }} className='byp-mb-1'>
          <Button
            className={labelAdd ? 'byp-btn-list-function-add' : 'byp-btn-list-function-more byp-bg-blue-50'}
            onClick={() => add(name, index+1)}
            size='small'
            icon={<PlusOutlined />}
            style={labelAdd && { width: '100%' }}
          >
             {labelAdd}
          </Button>
          {withOutDelete ? '' : <DeleteButton remove={remove} name={name} notDelete={notDelete} labelDelete={labelDelete} />}
          </Col>
      </>
         
    )
  } else {
    if (index > 1) {
      return (
        <>
          <Col style={{ display: 'inline-flex', gap: '10px' }} className='byp-mb-1'>
            <Button
              className={labelAdd ? 'byp-btn-list-function-add' : 'byp-btn-list-function-more byp-bg-blue-50'}
              onClick={() => add()}
              size='small'
              icon={<PlusOutlined />}
              style={labelAdd && { width: '100%' }}
            >
              {labelAdd}
            </Button>
            {withOutDelete ? '' : <DeleteButton remove={remove} name={name} notDelete={notDelete} labelDelete={labelDelete} />}
          </Col>
        </>
      )
    } else {
      return (
        <Col style={{ display: 'inline-flex', gap: '10px' }} className='byp-mb-1'>
          <Button
            className={labelAdd ? 'byp-btn-list-function-add' : 'byp-btn-list-function-more byp-bg-blue-50'}
            onClick={() => add()}
            size='small'
            icon={<PlusOutlined />}
            style={labelAdd && { width: '100%' }}
          >
            {labelAdd}
          </Button>
          <DeleteButton remove={remove} name={name} notDelete={notDelete} labelDelete={labelDelete} />
        </Col>
      )
    }
  }
}

export { AddAndRemoveListButtons }
