import { Button, Col, List, Rate, Row, Spin } from 'antd'
import { CardOpinions } from './CardOpinions'
import { useEffect, useState } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { URL_OPINION_PAGE } from '../../opinion/OpinionPage'
import { saveInStorage } from '../../../utils/localStorageUtils'
import { Link, useNavigate } from 'react-router-dom'
import { URL_REGISTER_PAGE_BETA } from '../../register'
import { PencilIcon } from '../../quote-preview/assets/imgs/Icons'

//cursos
const rateTypeLabel = (type) => {
  switch (type) {
    /* case 'accommodation':
      return 'Hospedaje' */
    case 'activities':
      return 'Actividades y excursiones'
    case 'campus':
      return 'Ubicación de la escuela'
    case 'city':
      return 'País y ciudad'
    case 'support':
      return 'Apoyo por parte de la escuela'
    default:
      return ''
  }
}

function CourseOpinions({ opinions, rates, filters, setFilters, isLoading }) {
  const [generalRate, setGeneralRate] = useState()
  const [featureRates, setFeatureRates] = useState([])
  const [opinionItems, setOpinionItems] = useState([])
  const [avgRate, setAvgRate] = useState()
  const [page, setPage] = useState(1)
  const { course, setOpinionsData, isPublicUser } = useQuoter()
  const navigate = useNavigate()

  useEffect(() => {
    if (rates && !isLoading) {
      const features = rates?.filter((rate) => rate.type !== 'general')
      const general = rates?.find((rate) => rate.type === 'general')
      setGeneralRate(general)
      setFeatureRates(features)
      let rateAvg = 0
      features.forEach(feature => {
        rateAvg += feature.rate
      })
      setAvgRate(rateAvg / features.length)
    }
  }, [rates])

  useEffect(() => {
    if (!isLoading && opinions) setOpinionItems(opinions)
  }, [isLoading])

  const handleOpinionRedirection = () => {
    const opinionPage = URL_OPINION_PAGE.replace(':campusSlug', course?.offer?.campus?.slug).replace(':courseId', course?.offer?.id)
    saveInStorage('redirectOnLoginSucces', opinionPage)
    navigate(URL_REGISTER_PAGE_BETA)
  }

  return (
    <div id='offer-opinions'>
      <Row gutter={50}>
        <Col xs={24} md={24} lg={8} style={{ display: 'flex', flexDirection: 'column', rowGap: '15px' }}>
          <Row style={{ backgroundColor: 'white', borderRadius: '12px', padding: '0 12px' }}>
            <Col xs={8} md={4} lg={9} xl={7} xxl={3} style={{ fontSize: '60px', lineHeight: '90px', fontWeight: '700', color: 'black' }}>
              {avgRate?.toFixed(1)}
            </Col>
            <Col xs={16} md={20} lg={15} xl={17} xxl={21} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Col>
                <Rate allowHalf value={avgRate?.toFixed(1)} disabled className='byp-gray-rate' />
              </Col>
              <Col style={{ fontSize: '10px', lineHeight: '15px' }}>
                {generalRate?.total_opinions} opiniones
              </Col>
            </Col>
          </Row>
          <Col style={{ backgroundColor: 'white', borderRadius: '12px', padding: '12px', display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
            <Row style={{ fontSize: '16px', fontWeight: '600' }}>
              Calificación de características
            </Row>
            {featureRates && featureRates?.map((rate) => (
              <Row key={rate.type} style={{ justifyContent: 'space-between' }}>
                <Col xs={12}>
                  {rateTypeLabel(rate.type)}
                </Col>
                <Col >
                  <Rate allowHalf value={rate.rate} disabled className='byp-gray-rate' />
                </Col>
              </Row>
            ))}
          </Col>

          {!isPublicUser && (
            <Button className='quote-byp__btn-opinion quote-byp__btn-opinion2 show_pc hide_mobile' style={{ width: '37%' }}>
              <Link
                to={URL_OPINION_PAGE.replace(':campusSlug', course?.offer?.campus?.slug).replace(':courseId', course?.offer?.id)}
                state={{
                  campus: course?.offer?.campus,
                  course: {
                    id: course?.offer?.id,
                    name: course?.offer?.name
                  }
                }}
              >Dar tu Opinión
              </Link>
            </Button>
          )}

          {!isPublicUser && (
            <Button className='quote-byp__btn-opinion hide_pc show_mobile' style={{ margin: '1rem auto', width: '100%' }}>
              <Link
                to={URL_OPINION_PAGE.replace(':campusSlug', course?.offer?.campus?.slug).replace(':courseId', course?.offer?.id)}
                state={{
                  campus: course?.offer?.campus,
                  course: {
                    id: course?.offer?.id,
                    name: course?.offer?.name
                  }
                }}
              >Dar tu Opinión
              </Link>
            </Button>
          )}

        </Col>

        <Col xs={24} md={24} lg={16} style={{ display: 'flex', rowGap: '15px', flexDirection: 'column', alignItems: 'center' }}>
          <List
            style={{ width: '100%' }}
            itemLayout='vertical'
            pagination={{
              onChange: page => {
                setPage(page)
              },
              total: opinionItems?.length,
              pageSize: 10,
              hideOnSinglePage: true,
              current: page
            }}
            dataSource={opinionItems || []}
            renderItem={(item, key) => <CardOpinions key={key} item={item} />}
          />
          {isLoading && <Spin />}
          {filters.limit === opinionItems.length && (
            <Button
              style={{ borderColor: '#797979', margin: '10px 0' }}
              onClick={() => {
                const limit = filters.limit ?? 6
                setFilters({
                  ...filters,
                  limit: limit + 6
                })
              }}
            >
              Leer Más
            </Button>
          )}

        </Col>
      </Row>
    </div>
  )
}

export { CourseOpinions }
