import { useEffect, useState } from 'react'
import { ButtonCategorySelector } from '../../pages/quoter/components/ButtonsCategorySelector'
// import { URL_PROGRAM_PAGE } from '../../pages/programs/programPage'
import { useNavigate } from 'react-router-dom'
import { useGetConfiguration } from '../../bussines/hooks/big-home/useGetPackage'
import { URL_QUOTER_PAGE } from '../../pages/quoter'

const ProgramButtons = ({ programs, countryPage, city }) => {
  const [programsSorted, setProgramsSorted] = useState(null)
  const [collection, setCollection] = useState([])
  const [selected, setSelected] = useState('language')
  const { data } = useGetConfiguration()

  useEffect(() => {
    if (data && data?.configuration && data?.configuration?.length > 0) {
      setProgramsSorted(JSON.parse(data?.configuration[0]?.value))
    }
  }, [programs])

  const navigate = useNavigate()

  const handleChangeProgram = (program) => {
    const dataToPass = {}
    if (countryPage) {
      if (city) dataToPass.type = 'city'
      if (!city) dataToPass.type = 'country'
      dataToPass.name = countryPage?.parent?.clean_name
      dataToPass.id = countryPage?.parent?.id
    }
    navigate(URL_QUOTER_PAGE, {
      state: {
        program,
        place_type: dataToPass.type,
        place: dataToPass.id
      }
    })
    setSelected(program)
  }

  useEffect(() => {
    if (programs) {
      const collections = programs?.map((program) => ({
        key: program.name,
        name: program.name,
        label: program.label
      }))
      // setCollection(collections?.filter(item => item.key === 'language'))
      setCollection(collections)
    }
  }, [programs])
  return (
    <div className='buttons__program snaps-inline btns__programs'>
      <ButtonCategorySelector collection={collection} onChange={handleChangeProgram} selected={selected} programsSorted={programsSorted} />
    </div>
  )
}

export { ProgramButtons }
