import { Col, Form, Row, Switch, Typography } from 'antd'
import React from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'

const { Text } = Typography
function ItemService({ item }) {
  const { service, formConfig, setFormConfig } = useQuoter()

  const handleChange = (value, id, cost, name) => {
    let agencyServices = []
    if (!formConfig.agencyServices) {
      setFormConfig({
        ...formConfig,
        agencyServices: []
      })
    }
    if (value) {
      agencyServices = formConfig.agencyServices ?? []
      agencyServices?.push({ id, cost: cost?.cost, name })
    } else {
      formConfig.agencyServices?.map((service) => (
        service.id !== id ? agencyServices.push(service) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      agencyServices
    })
  }

  return (
    <Row key={item.id} gutter={12} className='w-100'>
      <Col xs={24} md={18} lg={18} style={{ padding: '5px' }}>
        <Text>{item.label}</Text>
      </Col>
      <Col xs={24} md={6} lg={4} className='byp-border-left byp-border-right' style={{ textAlign: 'end', padding: '5px' }}>
        <Text>
          $ {service.getAgencyServiceSelectedCost(item.id)?.toFixed(2)} <span> </span>
          {service.getAgencyServiceCurrency(item.id)}
        </Text>
        {!service.getExchangeValue(service.getAgencyServiceCurrency(item.id)) && 'Valor de cambio no encontrado'}
        {
          service.getExchangeValue(service.getAgencyServiceCurrency(item.id)) && (
            service.calculateExchangeValue(
              service.getAgencyServiceSelectedCost(item.id)?.toFixed(2),
              service.getExchangeValue(service.getAgencyServiceCurrency(item.id))
            )
          )
        }
        {' '} {service.getAgencyCurrency()}
      </Col>
      <Col xs={24} md={6} lg={2} className='byp-border-left byp-border-right' style={{ textAlign: 'end', padding: '5px' }}>
        <Form.Item noStyle name={['services', item.name]}>
          <Switch
            onChange={(value) => { handleChange(value, item.id, item.costs[0] ?? null, item.name) }}
            className='byp-ml-1'
            checked={service.serviceIsChecked(item.id)}
          />
        </Form.Item>
      </Col>
      <hr className='byp-hr' />
    </Row>
  )
}

function AgencyCostSections() {
  const { service } = useQuoter()
  return (
    <div>
      {
        service.getAgencyMiscellaneous()?.map((item) => {
          return <ItemService key={item.id} item={item} />
        })
      }
    </div>
  )
}

export { AgencyCostSections }
