import React, { useEffect, useState } from "react";
import { Button, Col, Rate, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import icon from "../../../common/assets/images/icon.png";
import {
  IconHeartFilled,
  IconOpiniones,
} from "../../../common/assets/svg/icons";
import { URL_OPINION_PAGE } from "../../opinion/OpinionPage";
import { ThemeCarrouselSmall } from "../../../common/theme/partials/MainThemeCarrousel";
import { handlePrice } from "../../../utils/getCountryName";
import { ModalAddToCollection } from "../../../common/components/ModalAddToCollection";
import { useAuth } from "../../../providers/auth/auth-context-provider";
import { useGetCurrencyToCurrency } from "../../../bussines/hooks/currencies/useGetCurrencyToCurrency";
import InfoCostPopUp from "./InfoCostPopUp";
import { calculateCost } from "../../quote-course/utils/calculateCost";
import "../../../common/assets/css/cardOfferTheme.less";
import { isApplicable } from "../../../bussines/logic/quoter/DiscountsLogic";
import { programLenght } from "../../../languageConfiguration/infoCourseTranslate";

const { Paragraph } = Typography;

function CardItemCourse({
  item,
  status,
  program,
  collections,
  refetchCollections,
}) {
  const { data: newdata, getCurrencyToCurrency } = useGetCurrencyToCurrency();
  const { isPublicUser, userLocalCurrency } = useAuth();
  const [exchangeValue, setExchangeValue] = useState();
  const [hasDiscounts, setHasDiscounts] = useState(false);
  const [percentageDiscount, setPercentageDiscount] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [costs, setCosts] = useState({
    course: null,
    courseAmount: 0,
    tuition: null,
    tuitionAmount: 0,
    weeks: 2,
    discounts: [],
  });

  // TODO: Replace this with the Paragraph ellipsis
  const LongText = ({ content, limit }) => {
    const [showAll, setShowAll] = useState(false);

    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);

    if (content?.length <= limit) {
      // there is nothing more to show
      return <div className="card-content">{content}</div>;
    }
    if (showAll) {
      // We show the extended text and a link to reduce it
      return (
        <div className="card-content">
          {content}
          <a style={{ color: "blue" }} onClick={showLess}>
            {" "}
            Leer menos
          </a>
        </div>
      );
    }
    if (!content) {
      return <a />;
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = content?.substring(0, limit) + "...";
    return (
      <div className="card-content">
        {toShow}
        <a style={{ color: "blue" }} onClick={showMore}>
          Leer más
        </a>
      </div>
    );
  };

  const handleUrl = () => {
    let url = "";
    if (item.course?.type === "package") {
      url = `/package-course/${item.course?.id}/${item.campus?.id}`;
    }
    if (item.course?.type === "short") {
      url = `/short-term-course/${item.course?.id}/${item.campus?.id}`;
    }
    if (item.course?.type === "course") {
      url = `/quote-course/${item.course?.id}/${item.campus?.id}`;
    }
    if (item?.uuid) {
      return url + "?quote=" + item.uuid;
    } else {
      return url;
    }
  };

  const getLenght = (start, end) => {
    if (start && end) {
      const lenghtDate = new Date(end) - new Date(start);
      const differenceWeeks = Math.ceil(lenghtDate / (1000 * 60 * 60 * 24 * 7));
      return differenceWeeks;
    }
  };

  const handleTime = (item) => {
    if (item?.course?.type === "short" || item?.course?.type === "package") {
      if (item?.course?.properties?.min_weeks) {
        return `Curso de ${item?.course?.properties?.min_weeks} semanas`;
      } else {
        return `Curso de ${getLenght(item?.course?.start_at, item?.course?.end_at)} semanas`;
      }
    }

    if (item?.program?.name === "language") {
      return "Curso de " + item?.min_weeks + " semanas";
    }

    if (item?.program?.name !== "language") {
      return `Curso de ${item?.course?.properties?.length} ${programLenght.es[item?.course?.properties?.time] ?? item?.course?.properties?.time}`;
    }
  };

  const getTuitionPercentageDiscounts = (discounts) => {
    const today = new Date();
    const percentages = discounts.filter(
      (discount) => discount.pivot?.type === "percentage"
    );
    // const weeks = 2
    // // const validPercentages = getApplicableDiscounts(percentages, today, weeks)

    // const discountsWithRanges = percentages.filter(discount => discount.ranges?.length)
    // const discountsWithOutRanges = percentages.filter(discount => !discount.ranges?.length)
    // const validPercentages = [...discountsWithOutRanges, discountsWithRanges[0] ?? []]

    const validPercentages = [percentages[0] ?? []];

    if (!validPercentages.length) return false;
    let sum = 0;
    let min = null;
    validPercentages.forEach((discount) => {
      if (isApplicable(discount, today)) {
        const value = discount.pivot.value ?? 0;
        sum += value;
        if (!min && discount.pivot.value) min = value;
        if (discount.pivot.value < min) min = value;
      }
    });
    return Math.floor(sum);
    // return Math.floor(min)
  };

  const handleCost = () => {
    const costs = item.costs ?? [];
    const cost = costs[0];
    if (!cost) {
      return 0;
    }
    const calculatedCost = calculateCost(
      cost.cost,
      cost.frequency,
      item.min_weeks ?? 1
    );
    const discountedPercentage = getTuitionPercentageDiscounts(
      item.course_discounts ?? []
    );
    const discAmount = calculatedCost * (discountedPercentage / 100);

    const regex = /,/g;
    let offerCost;
    if (typeof item?.cost === "string") {
      offerCost = Number("" + item?.cost?.replace(regex, ""));
    } else {
      offerCost = item?.cost;
      console.log("🚀 ~  ~ offerCost:", typeof item?.cost, offerCost);
    }
    const result = offerCost - discAmount;
    return result;
  };

  const hasPercentageDiscounts = (discounts = []) => {
    const percentages = discounts.filter(
      (discount) => discount.pivot?.type === "percentage"
    );
    return percentages.length > 0;
  };

  useEffect(() => {
    getCurrencyToCurrency(userLocalCurrency, item.currency);
  }, [status, userLocalCurrency]);

  useEffect(() => {
    if (newdata?.data?.value && newdata?.data?.value !== 0) {
      setExchangeValue(newdata?.data?.value);
    }
  }, [newdata]);

  useEffect(() => {
    if (item) {
      // const hasDiscount = item.has_discount
      const hasCourseDiscounts = hasPercentageDiscounts(
        item.course_discounts ?? []
      );
      // const hasTuitionDiscounts = hasPercentageDiscounts(item.tuition?.discounts ?? [])
      setHasDiscounts(hasCourseDiscounts);
      setPercentageDiscount(
        getTuitionPercentageDiscounts(item.course_discounts ?? [])
      );

      // Choose language
      // const [title, setTitle] = useState('')
      // const [description, setDescription] = useState('')
      if (item?.translations && item?.translations?.length > 0) {
        const label = item?.translations?.find(
          (item) => item?.to === "es" && item?.attribute === "label"
        );
        setTitle(label?.translation);
        const description = item?.translations?.find(
          (item) => item?.to === "es" && item?.attribute === "description"
        );
        setDescription(description?.translation);
      } else {
        setTitle(item?.course?.label ?? "");
        setDescription(item?.course?.description ?? "");
      }

      const program = item.program;
      if (program?.name === "language") {
        const courseWeeklyCost = item.costs?.find((costItem) => {
          const min = costItem.rules?.find(
            (rule) => rule.name === "min_frequency"
          )?.pivot?.value;
          const max = costItem.rules?.find(
            (rule) => rule.name === "max_frequency"
          )?.pivot?.value;
          if (Number(item.min_weeks) >= min && Number(item.min_weeks) <= max) {
            return costItem;
          }
          return false;
        });

        setCosts({
          course: courseWeeklyCost,
          courseAmount: courseWeeklyCost?.cost * Number(item.min_weeks),
          tuition: item.tuition?.cost,
          weeks: Number(item.min_weeks),
          discounts: item.course_discounts,
        });
      } else {
        const mandatoryCosts = item.costs?.filter((cost) => cost.mandatory);
        setCosts({
          courseCosts: mandatoryCosts,
          tuition: item.tuition?.cost,
          weeks: Number(item.min_weeks),
          courseLength: item.course.properties.length,
          courseLengthType: item.course.properties.time,
        });
      }
    }
  }, [item]);
  return (
    <div style={{ paddingBottom: "30px" }} className="card-course">
      <Row
        style={{
          backgroundColor: "#F4F4F4",
          border: "1px solid var(--gray-100)",
          borderRadius: "22px",
        }}
      >
        {/* Image */}
        <Col
          xs={24}
          md={7}
          className="card-course-column"
          style={{ position: "relative" }}
          key={item.id || item.course?.id || item.campus?.id}
        >
          {item.images?.length >= 1 ? (
            <ThemeCarrouselSmall
              slides={item.images}
              urlProperty="src"
              inRandomOrder
            />
          ) : (
            <img
              alt={item?.label}
              src={item?.campus?.profilePhotoUrl || icon}
            />
          )}
          {!program && (
            <div className="card__label">
              <b>{item?.program?.label}</b>
            </div>
          )}
        </Col>

        {/* Info - card body*/}
        <Col xs={24} md={17} className="card__body-container">
          {/* <Row gutter={[0, 16]}> */}
          <Row gutter={[8, 0]}>
            <Col xs={24}>
              <Row gutter={[0, 16]} justify="space-between">
                {/* Location */}
                <Col span={20} className="card__location-container">
                  {item.campus?.name}
                  {" " + item.city?.name + "-" + item.country?.name}
                </Col>

                {/* Add to favorites */}
                <Col>
                  <ModalAddToCollection
                    itemId={item.id}
                    button={{
                      text: "",
                      className: "card__add-to-favorites-btn",
                      icon: <IconHeartFilled width="22px" height="20px" />,
                    }}
                    isPublicUser={isPublicUser}
                    collectionItems={collections}
                    loadItems={false}
                    refetchCollections={refetchCollections}
                  />
                </Col>
              </Row>
            </Col>

            {/* Course name */}
            <Col xs={24}>
              <Link to={handleUrl()} className="card__title">
                {title}
              </Link>
            </Col>

            {/* Link to course */}
            <Col>
              <Link to={handleUrl()} className="card__link-btn">
                {handleTime(item)}
              </Link>
            </Col>

            {/* See in map button */}
            {/* TODO: Pending for Sam */}
            {/* <Col>
              <Link className='card__link-btn--red'>
              Ver en el mapa
              </Link>
            </Col> */}

            {/* Course description */}
            <Col xs={24}>
              <Paragraph
                ellipsis={{ rows: 4 }}
                style={{ lineHeight: "normal", marginBottom: "4px" }}
              >
                <LongText content={description} limit={160} />
              </Paragraph>
            </Col>

            {/* Opinion container */}
            <Col xs={24} md={12}>
              <Row justify="space-between">
                <Col xs={12} md={24}>
                  <Row>
                    {/* Rating */}
                    <Col xs={24}>
                      <Rate
                        value={item?.rate ?? 5}
                        disabled
                        className="byp-gray-rate byp-rate-sm"
                      />
                    </Col>

                    {/* Opinions counter */}
                    <Col xs={24} className="card__opinions-container">
                      <IconOpiniones width="14" height="14" />
                      <b>{item.rate_count ?? 0} Opiniones</b>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Link
                    className="card__link-opinion"
                    to={URL_OPINION_PAGE.replace(
                      ":campusSlug",
                      item?.campus?.slug
                    ).replace(":courseId", item?.course?.id)}
                  >
                    Deja tu opinión
                  </Link>
                </Col>
              </Row>
            </Col>

            {/* ========================== */}

            {/* Cost */}
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                gap: "5px",
              }}
            >
              {percentageDiscount > 0 && (
                <Button className="offer-discount-amount">
                  - {percentageDiscount} %*
                </Button>
              )}

              {hasDiscounts && exchangeValue && (
                <div className="price-container">
                  <span className="price-before">
                    {userLocalCurrency} $
                    {handlePrice(item.cost, newdata?.data?.value)}
                  </span>

                  <InfoCostPopUp
                    cost={item.cost}
                    costDetails={costs}
                    discounts={costs.discounts}
                    currency={item.currency}
                    tuition={item.tuition}
                  >
                    {userLocalCurrency} $
                    {handlePrice(handleCost(), newdata?.data?.value)}
                  </InfoCostPopUp>
                </div>
              )}
              {hasDiscounts && !exchangeValue && (
                <div className="price-container">
                  <span className="price-before">
                    {item.currency} ${item.cost}
                  </span>
                  <InfoCostPopUp
                    cost={item.cost}
                    costDetails={costs}
                    discounts={costs.discounts}
                    currency={item.currency}
                    tuition={item.tuition}
                  >
                    {item.currency} ${handleCost()}
                  </InfoCostPopUp>
                </div>
              )}
              {!hasDiscounts && (
                <>
                  {exchangeValue && (
                    <div className="price-container">
                      <InfoCostPopUp
                          cost={item.cost}
                          costDetails={costs}
                          discounts={costs.discounts}
                          currency={item.currency}
                          tuition={item.tuition}
                        >
                          {userLocalCurrency} $
                          {handlePrice(handleCost(), newdata?.data?.value)}
                        </InfoCostPopUp>
                    </div>
                  )}
                  {!exchangeValue && (
                    <>
                      <p className="price">
                        {item.currency} ${item.cost}
                      </p>
                    </>
                  )}
                </>
              )}
            </Col>

            {/* ========================== */}

            {/* Cost */}
            {/* TODO: Add class styles */}
            {/* <Col xs={24} md={12}>
              <Col xs={24}>
                <Row gutter={[0, 0]}>
                  <Col xs={24}>
                    {percentageDiscount > 0 && (
                      <Button className="offer-discount-amount">
                        - {percentageDiscount} %*
                      </Button>
                    )}
                  </Col>

                  {hasDiscounts && exchangeValue && (
                    <Col xs={12} style={{ background: "pink" }}>
                      <div className="price-container">
                        <span className="price-before">
                          {userLocalCurrency} $
                          {handlePrice(item.cost, newdata?.data?.value)}
                        </span>

                        <InfoCostPopUp
                          cost={item.cost}
                          costDetails={costs}
                          discounts={costs.discounts}
                          currency={item.currency}
                          tuition={item.tuition}
                        >
                          {userLocalCurrency} $
                          {handlePrice(handleCost(), newdata?.data?.value)}
                        </InfoCostPopUp>
                      </div>
                    </Col>
                  )}

                  {hasDiscounts && !exchangeValue && (
                    <Col xs={12} style={{ background: "gren" }}>
                      <div className="price-container">
                        <span className="price-before">
                          {item.currency} ${item.cost}
                        </span>

                        <InfoCostPopUp
                          cost={item.cost}
                          costDetails={costs}
                          discounts={costs.discounts}
                          currency={item.currency}
                          tuition={item.tuition}
                        >
                          {item.currency} ${handleCost()}
                        </InfoCostPopUp>
                      </div>
                    </Col>
                  )}

                  <Col xs={24} style={{ background: "orange" }}>
                    {!hasDiscounts && (
                      <>
                        {exchangeValue && (
                          <div className="price-container">
                            <InfoCostPopUp
                              cost={item.cost}
                              costDetails={costs}
                              discounts={costs.discounts}
                              currency={item.currency}
                              tuition={item.tuition}
                            >
                              {userLocalCurrency} $
                              {handlePrice(handleCost(), newdata?.data?.value)}
                            </InfoCostPopUp>
                          </div>
                        )}
                        {!exchangeValue && (
                          <>
                            <p className="price">
                              {item.currency} ${item.cost}
                              si
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export { CardItemCourse };
