import React from 'react'
import logoWhatsDesktop from '../assets/images/whatsapp-icon2.png'
import logoWhatsMobile from '../assets/images/whatsapp-icon.png'

const WhatsAppButton = () => {
  const openWhatsApp = () => {
    window.open('https://api.whatsapp.com/send/?phone=5213327707549&text&type=phone_number&app_absent=0', '_blank');
  }

  return (
    <div id='whatsapp-button' onClick={openWhatsApp}>
      <picture>
        <source media='(min-width: 768px)' srcSet={logoWhatsDesktop} />
        <source media='(max-width: 568px)' srcSet={logoWhatsMobile} />
        <img src={logoWhatsDesktop} alt='WhatsApp' className='button-whats' />
      </picture>
    </div>
  )
}

export default WhatsAppButton
