import React, { useEffect, useMemo, useState } from 'react'
import { Col, Form, Row, Select, Typography } from 'antd'
import { useGetCountriesWithOffers } from '../../../bussines/hooks/places/useGetCountriesWithOffers'
import debounce from 'lodash/debounce'
import { useGetCitiesWithOffers } from '../../../bussines/hooks/places/useGetCitiesWithOffers'
import { useGetCampusWithOffers } from '../../../bussines/hooks/places/useGetCampusWithOffers'
import { useGetPrograms } from '../../../bussines/hooks/program/useGetPrograms'

const { Option } = Select

function SelectPlacesWithOffers ({ onChange, span = 24, className = '', countryProps, cityProps, campusProps, programProps, initialData }) {
  const [searchFilter, setSearchFilter] = useState()
  const [countryFilters] = useState({ onlyCountries: true })
  const [cityFilters, setCityFilters] = useState({ countries: [] })
  const [campusFilters, setCampusFilters] = useState({ cities: [] })
  const [programFilters, setProgramFilters] = useState({ campus: [] })
  const [dataCities, setDataCities] = useState()
  const { isLoading, data: countries } = useGetCountriesWithOffers(searchFilter, countryFilters)
  const { isLoading: isSearchinCities, data: citiesData } = useGetCitiesWithOffers(cityFilters)
  const { isLoading: isSearchinCampus, data: campusData } = useGetCampusWithOffers(campusFilters)
  const { isLoading: isSearchinPrograms, data: programsData } = useGetPrograms(programFilters)

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      if (key === 'country') {
        setSearchFilter(value)
      }
      if (key === 'city') {
        setCityFilters({
          ...cityFilters,
          search: value
        })
      }
      if (key === 'campus') {
        setCampusFilters({
          ...campusFilters,
          search: value
        })
      }
      console.log('sending the request :x', key, value)
    }, 500)
  })

  useEffect(() => {
    if (initialData?.countries) {
      setCityFilters({
        ...cityFilters,
        countries: initialData.countries ?? []
      })
    }
    if (initialData?.cities) {
      setCampusFilters({
        ...campusFilters,
        cities: initialData.cities ?? []
      })
    }
  }, [initialData])

  useEffect(() => {
    if (citiesData) {
      if (citiesData?.cities) {
        setDataCities(citiesData?.cities)
      } else {
        setDataCities(citiesData)
      }
    }
  }, [citiesData])

  return (
    <Row gutter={16}>
      <Col span={span} className={className}>
        {countryProps?.label && <Typography.Title level={3} className='w-100'>{countryProps?.label}</Typography.Title>}
        <Form.Item
          name={countryProps?.name ?? 'countries'}
        >
          <Select
            className='w-100 edit-blog__form-item'
            loading={isLoading}
            onSearch={(value) => {
              debounceFilter('country', value)
            }}
            onChange={(values) => {
              setCityFilters({
                ...cityFilters,
                countries: values
              })
              if (onChange) onChange(values, 'countries')
            }}
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            {...countryProps}
          >
            {countries?.map((country) => (
              <Option
                key={country.id}
                value={country.id}
              >{country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={span} className={className}>
        {cityProps?.label && <Typography.Title level={3} className='w-100'>{cityProps?.label}</Typography.Title>}
        <Form.Item
          name={cityProps.name ?? 'cities'}
        >
          <Select
            className='w-100 edit-blog__form-item'
            onChange={(values) => {
              setCampusFilters({
                ...campusFilters,
                cities: values
              })
              if (onChange) onChange(values, 'cities')
            }}
            onSearch={(value) => {
              debounceFilter('city', value)
            }}
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            loading={isSearchinCities}
            {...cityProps}
          >
            {dataCities && dataCities?.map((city) => (
              <Option
                key={city.id}
                value={city.id}
              >{city.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={span} className={className}>
        {campusProps?.label && <Typography.Title level={3} className='w-100'>{campusProps?.label}</Typography.Title>}
        <Form.Item
          name={campusProps?.name ?? 'campus'}
        >
          <Select
            className='w-100 edit-blog__form-item'
            onChange={(values) => {
              setProgramFilters({
                ...programFilters,
                campus: values
              })
              if (onChange) onChange(values, 'campus')
            }}
            loading={isSearchinCampus}
            {...campusProps}
          >
            {campusData?.map((campus) => (
              <Option
                key={campus.id}
                value={campus.id}
              >{campus.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={span} className={className}>
        {programProps?.label && <Typography.Title level={3} className='w-100'>{programProps?.label}</Typography.Title>}
        <Form.Item
          name={programProps?.name ?? 'programs'}
        >
          <Select
            className='w-100 edit-blog__form-item'
            onChange={(values) => {
              if (onChange) onChange(values, 'programs')
            }}
            loading={isSearchinPrograms}
            {...programProps}
          >
            {programsData?.map((program) => (
              <Option
                key={program.id}
                value={program.id}
              >{program.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  )
}

export { SelectPlacesWithOffers }
