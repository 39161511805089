import { Form, Select } from 'antd'
const { Option } = Select
const SelectGenderFormItem = ({ name, label, ...rest }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      {...rest}
    >
      <Select
        className='w-100'
        {...rest}
      >
        <Option key='mix'>Mix</Option>
        <Option key='Only boys'>Only boys</Option>
        <Option key='Only girls'>Only girls</Option>
      </Select>
    </Form.Item>
  )
}

export { SelectGenderFormItem }
