import { Button, Col, Form, Input, Modal, notification, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useGetAllInsurance } from '../../../../bussines/hooks/agency/insurances/useGetAllInsurance'
import { usePostInsurances } from '../../../../bussines/hooks/agency/insurances/usePostInsurances'
import { useFindLodging } from '../../../../bussines/hooks/school/lodging/useFindLodging'
import stateControl from '../../../../bussines/utils/state-control'
import { SelectCampusAll } from '../components/SelectCampusAll'
import { SelectBranchesAll } from '../components/SelectBranchesAll'
import { SelectInstitutionsAll } from '../components/SelectInstitutionsAll'
import { SelectCurrencyFormItem } from '../../../../common/components/helper-form/SelectCurrencyFormItem'
import { SelectSchoolProgramsFormItem } from '../../../../common/components/helper-form/SelectSchoolProgramsFormItem'
import { TableInsurance } from './TableInsurances'
import moment from 'moment'
import { useDeleteFee } from '../../../../bussines/hooks/fee/useDeleteFee'
import { showErrorModal } from '../../../../utils/errorModal'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { useGetAllBranches } from '../../../../bussines/hooks/agency/useGetAllBranches'
import { useGetAllCampus } from '../../../../bussines/hooks/school/useGetAllCampus'
import { YearsFormListAgency } from '../../Institution/components/YearsFormListAgency'
import { formatStringDate } from '../../../../utils/formatStringDate'

const { TextArea } = Input

let readOnly = true

const required = { required: true, message: 'This element is required' }

const FormInsurance = () => {
  const { user: _user } = useAuth()
  const [form] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal()

  const [insuranceId, setInsuranceId] = useState()
  const [isUpdateMode, setIsUpdateMode] = useState()
  const [program, setProgram] = useState()

  const { createInsurance, isLoading, data, currentState, error: errorSaving } = usePostInsurances()
  const { deleteFee, data: response, isLoading: isDeleting } = useDeleteFee()
  const { data: insuranceDataList, isLoading: loadingList, refetch, isFetching } = useGetAllInsurance({})
  const { data: insurance, isLoading: isSearching } = useFindLodging(insuranceId)
  const { isLoading: isLoadingBranches, data: branches } = useGetAllBranches()
  const { isLoading: isLoadingCampus, data: campus } = useGetAllCampus(program && { program })

  const onFinish = (values) => {
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }

    if (isUpdateMode) {
      values.id = insurance.id
    }
    const years = values.years?.map(yearItem => {
      return {
        id: yearItem.id ?? null,
        year: formatStringDate(yearItem.year, 'Y'),
        start: formatStringDate(yearItem.start, 'yyyy-LL-dd'),
        end: formatStringDate(yearItem.end, 'yyyy-LL-dd')
      }
    })

    values.years = years
    createInsurance(values)
  }

  useEffect(() => {
    if (!isLoading && data && currentState === stateControl.STATE_SUCCESS) {
      // console.log('🚀 ~ file: FormInsurance.js:18 ~ useEffect ~ data', data)
      const createMessage = 'Insurance created succesfully'
      const updateMessage = 'Insurance updated succesfully'
      notification.success({
        message: isUpdateMode ? updateMessage : createMessage
      })
      refetch()
      form.resetFields()
      if (isUpdateMode) {
        setIsUpdateMode(false)
        setInsuranceId()
      }
    }
  }, [isLoading, data, currentState])

  useEffect(() => {
    if (!isSearching && insurance) {
      console.log('🚀 ~ file: FormInsurance.js:38 ~ useEffect ~ insurance', insurance)
      setIsUpdateMode(true)
      const years = []
      insurance.years?.forEach(yrs => {
        if (yrs.id) {
          years.push({
            id: yrs.id,
            year: moment(yrs.year),
            start: moment(yrs.start),
            end: moment(yrs.end),
            prices_start: moment(yrs.prices_start),
            prices_end: moment(yrs.prices_end)
          })
        }
      })

      years?.length ? insurance.years = years : insurance.years = [{}]
      form.setFieldsValue({
        ...insurance,
        currency: insurance.currency?.id,
        offices: insurance.offices?.map((item) => item.id),
        schools: insurance.schools?.map((item) => item.id),
        institutions: insurance.institutions?.map((item) => item.id),
        officesall: (insurance.offices.length === branches.length),
        institutionsall: (campus.institutions.length === insurance.institutions.length),
        schoolsall: (campus.campus.length === insurance.schools.length)
      })
    }
  }, [insurance, isSearching])

  useEffect(() => {
    if (!isDeleting && response) {
      console.log('🚀 ~ file: FormInsurance.js:79 ~ useEffect ~ response', response)
      refetch()
      notification.info({
        message: 'Medical insurance deleted'
      })
    }
  }, [response, isDeleting])

  useEffect(() => {
    if (!isLoading && errorSaving?.response.data) {
      showErrorModal(errorSaving.response.data.message)
    }
  }, [errorSaving, modal, isLoading])

  useEffect(() => {
    const roles = _user?.role_names ?? []
    if (roles.includes('agency-admin') || roles.includes('agency-editor')) {
      readOnly = false
    }
  }, [_user])

  return (
    <Row gutter={24} className='byp-dashboard-content'>
      {contextHolder}
      <Col xs={24} md={12}>
        <h3 className='byp-title'>Create your medical insurance service</h3>
        <Form
          form={form}
          requiredMark={false}
          labelCol={{
            span: 7
          }}
          labelAlign='left'
          labelWrap
          onFinish={onFinish}
          initialValues={{
            years: [{}]
          }}
        >
          <SelectCurrencyFormItem
            name='currency' label='Currency'
            rules={[required]}
          />
          <h5 className='byp-title'>Name and description </h5>
          <Form.Item name='name' label='Insurance name' rules={[required]}>
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item name='description' label='Description'>
            <TextArea maxLength={600} showCount />
          </Form.Item>

          <h5 className='byp-title'>This applies to </h5>

          <SelectSchoolProgramsFormItem
            rules={[required]}
            name='category'
            label='Program type'
            setProgramName={setProgram}
          />

          <SelectBranchesAll
            rules={[required]}
            mode='multiple'
            name='offices'
            label='Offices'
            form={form}
          />

          <SelectInstitutionsAll
            rules={[required]}
            program={program}
            name='institutions'
            label='Institution'
            mode='multiple'
            form={form}
          />

          <SelectCampusAll
            rules={[required]}
            name='schools'
            label='Schools'
            mode='multiple'
            form={form}
            program={program}
          />

          <h5 className='byp-title mt-4 mb-4'>Time and availability <span className='byp-title'>(dates on which prices are valid)</span></h5>

          <Row gutter={12}>
            <YearsFormListAgency name='years' form={form} />
          </Row>

          <h5 className='byp-title'>Additional comments </h5>
          <Form.Item name='comments' label='Comments'>
            <TextArea maxLength={600} showCount />
          </Form.Item>

          <Row>
            <Col span={24}>
              <Button
                className='byp-btn-blue-200 float-right float-right byp-mt-1'
                htmlType='submit'
                loading={isLoading}
              >
                {isUpdateMode ? 'Update' : 'Save insurance'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col xs={24} md={12}>
        <TableInsurance
          insurances={insuranceDataList?.data}
          setId={setInsuranceId}
          isLoading={loadingList || isFetching || isDeleting}
          deleteFee={deleteFee}
          canDelete={!readOnly}
        />
      </Col>
    </Row>
  )
}

export { FormInsurance }
