import React, { useState } from "react";
import { Col, Layout, Row } from "antd";
import { AppLogoNameHeader } from "../components/AppLogoNameHeader";
import "./../assets/css/main-theme.less";
import "./../assets/css/responsive.less";
import { MainThemeMenu } from "./partials/MainThemeMenu";
import { MainThemeFooter } from "./partials/MainThemeFooter";
import { UserMenuSwitcher } from "./partials/UserMenuSwitcher";
import { ErrorBoundary } from "../../providers/validation/ErrorBoundary";
import { MainFooterMenu } from "./partials/MainFooterMenu";

const { Header, Footer, Content, Sider } = Layout;
const MainTheme = ({
  children,
  activeCarousel = false,
  showMenu = false,
  showMainTheme = true,
  layoutStyle = {},
  className = "",
  showMenuFooter = false,
}) => {
  const [isActive] = useState(false);

  return (
    <ErrorBoundary>
      <Layout className="">
        {/** DESKTOP **/}
        <Header className="header-theme logo header-pc">
          <Row
            justify="space-around"
            align="middle"
            style={{ padding: "0 20px" }}
          >
            <Col xs={24} md={24} lg={6}>
              <AppLogoNameHeader className="h-logo" />
            </Col>
            <Col xs={24} md={24} lg={18} align="right">
              <MainThemeMenu />
            </Col>
          </Row>
        </Header>

        {/* MOBILE */}
        <Header className="header-theme logo header-tablet">
          <Row
            justify="space-between"
            align="middle"
            style={{ padding: "6px 0", display: "flex", alignItems: "center" }}
          >
            <Col
              xs={12}
              sm={6}
              md={5}
              style={{ display: "flex", alignItems: "center" }}
            >
              <AppLogoNameHeader className="logo-small" changeImage />
            </Col>
            <Col
              className="header-bar"
              align="center"
              style={{ display: "flex", alignItems: "center" }}
            >
              <MainThemeMenu />
            </Col>
          </Row>
        </Header>

        {/** CONTENIDO **/}
        <Layout
          className={`app ${isActive ? "no-scroll" : ""}`}
          style={{ minHeight: "75vh", background: "var(--background)" }}
        >
          <Row style={{ width: "100%" }}>
            {showMenu && (
              <Col md={4} lg={4} style={{ zIndex: 999, textAlign: "center" }}>
                <Sider
                  width="100%"
                  className=""
                  breakpoint="lg"
                  collapsedWidth="0"
                  onBreakpoint={(broken) => {}}
                  onCollapse={(collapsed, type) => {}}
                >
                  <UserMenuSwitcher />
                </Sider>
              </Col>
            )}
            <Col
              xs={24}
              md={24}
              lg={showMenu ? 20 : 24}
              className="main-menu-container"
            >
              <Content>
                {/* {activeCarousel && <MainThemeCarrouselWithImages />} */}
                <Content
                  className={className + " site-layout-content"}
                  style={layoutStyle}
                >
                  {children}
                </Content>
              </Content>
            </Col>
          </Row>
        </Layout>
        {!showMenu && (
          <Footer
            className={`app ${isActive ? "no-show-footer site-layout-footer" : "site-layout-footer"} ${showMenuFooter ? "byp-mb-3" : ""}`}
          >
            <MainThemeFooter />
          </Footer>
        )}
      </Layout>
      {/* showMenuFooter && (
          <Footer>
            <MainFooterMenu />
          </Footer>
        ) */}

      {/* <PhoneTheme showMainTheme={showMainTheme} /> */}
    </ErrorBoundary>
  );
};

export { MainTheme };
