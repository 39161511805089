import api from '../../services/api'

class CollectionRepository {
  async collections (params) {
    const response = await api.get('/collections', { params })
    return response.data
  }

  async create (data) {
    const response = await api.post('/collections', data)
    return response.data
  }

  async find (id, params) {
    const response = await api.get(`/collections/${id}`, { params })
    return response.data
  }

  async addItem ({ id, data }) {
    const response = await api.post(`/collections/${id}/item`, data)
    return response.data
  }

  async removeItem ({ id }) {
    const response = await api.delete(`/collections/items/${id}`)
    return response.data
  }
}

export default new CollectionRepository()
