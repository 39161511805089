import React from 'react'
import { Col, Row, Typography } from 'antd'
const { Paragraph, Title } = Typography
function TextTermsAndConditions ({ type = 'Institution' }) {
  return (
    <Row className='terms_and_conditions' gutter={[24, 24]}>
      <Col xs={24} lg={12}>
        <Title level={5}>Responsabilidades:</Title>

        <Paragraph>Programas Interculturales de México S.C. (EduLynks) es una empresa constituida legalmente con el RFC PIM-020812-EL3 con domicilio en Av. Vallarta 3075-1 Col. Vallarta Pte., Guadalajara, Jalisco CP.44110 y cuenta con las facultades de actuar como intermediario entre el cliente y la institución educativa en el extranjero, y no funge como prestador del servicio, siendo el prestador final del servicio la institución educativa extranjera, misma que emite la factura al cliente.</Paragraph>
        <Paragraph>EduLynks se compromete a solicitar los servicios de acuerdo a los requerimientos de los clientes, sin embargo, declina toda responsabilidad para casos fortuitos o de fuerza mayor en los que el prestador no cumpla con el servicio solicitado.</Paragraph>
        <Paragraph>La Procuraduría Federal del Consumidor es competente en la vía administrativa para resolver cualquier controversia que se suscite sobre la interpretación o cumplimento del presente. Sin perjuicio de lo anterior, las partes se someten a la jurisdicción de los tribunales competentes en la ciudad de Guadalajara, Jalisco, México, renunciando expresamente a cualquier otra jurisdicción que pudiera corresponderles, por razón de sus domicilios presentes o futuros o por cualquier otra razón.</Paragraph>

        <Title level={5}>Inscripción</Title>

        <Paragraph>EduLynks se encarga de realizar el proceso de inscripción ante la institución educativa que el cliente ha seleccionado. Al iniciar el trámite de inscripción el cliente da por aceptado los términos y condiciones de este contrato.</Paragraph>

        <Title level={5}>Cancelación y cambios de fechas de inicio</Title>

        <Paragraph> 1.- En caso de cancelar el paquete, la cuota inicial de servicio en pesos no es reembolsable bajo ninguna circunstancia; $20,000 MXN para programas de Años Académicos, Boardings, Carreras, Licenciaturas, Bachelors, Degrees, Undergraduate o equivalente; $10,000 MXN para programas de Idiomas, Campamentos, Estudia y Trabaja, Au Pair, Veranos, Diplomas, Certificados, Maestrías, Postgraduate.</Paragraph>
        <Paragraph> Anticipos o liquidaciones de boletos de avión no son reembolsables en ninguna circunstancia. Cualquier cancelación del programa, se penalizará con la couta inicial sumado a cualquier penalización estipulada por los términos y condiciones del programa/escuela contratado.</Paragraph>
        <Paragraph>
          a. Cualquier reembolso que sea por parte de la escuela, se estará llevando a cabo en los próximos 60 días de la solicitud de la devolución.
          b. Las cancelaciones por motivos de negación de visa solamente se penalizarán con la cuota inicial de servicio y cuota de inscripción de la institución contratada.
          c. Cancelaciones una vez comenzado el programa no aplica ningún reembolso bajo ninguna circunstancia.
        </Paragraph>
        <Paragraph>2.- En caso de cancelación, EduLynks deberá recibir una carta/mail con los motivos de la cancelación del programa, en caso de ser negación de visa es necesario presentar el comprobante de la negación de la visa, así como en caso de accidente o enfermedad una carta del médico explicando los motivos por los que no podrá realizar el viaje.</Paragraph>
        <Paragraph>3.- En Años Académicos con familia voluntaria en Estados Unidos, Francia, Alemania, Italia y Brasil; puede darse el caso en el que llegada una fecha detemrinada, el programa indique que no se ha encontrado ubicación con familia, por lo que se reembolsará como se indica en el punto 1, incisos b y c.</Paragraph>
        <Paragraph>4.- En caso de que se desee posponer el viaje por motivos extraordinarios el pago inicial no es reembol- sable, sin embargo, es abonable para retomar el programa en un periodo máximo a un año, después de ese tiempo si no se estipula una fecha de inicio, se dará automáticamente de baja al estudiante y se considerará como cancelado perdiendo así la cuota inicial.</Paragraph>
        <Paragraph>5.- En caso de posponer el programa contratado, EduLynks se deslinda de cambios realizados por la institución contratada, cambios del programa o cierres de escuela.</Paragraph>
        <Paragraph>6.- EduLynks se deslinda de cualquier responsabilidad sobre cancelaciones y reembolsos gestionados a través de empresas de financiamiento externas, así como de los tiempos que éstas tramitan el reembolso.</Paragraph>
        <Paragraph>7.- Cambios de fechas de inicio pueden generar costos extra y penalizaciones y se determinarán de acuerdo a las políticas de cada institución educativa contratada.</Paragraph>
        <Paragraph>8.- EduLynks se deslinda de cualquier responsabilidad sobre cambios de fechas por parte del programa o cancelaciones y cambios de cursos previos al inicio del programa.</Paragraph>

        <Title level={5}>Pagos del programa y depósito de Garantías</Title>

        <Paragraph>1.- El cliente bajo protesta de decir la verdad, declara que los recursos monetarios con los cuales se están contratando los servicios de EduLynks, tienen su procedencia de actividades lícitas y se sujetan a lo que se establece dentro de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita respecto de las Actividades Vulnera- bles a las que en dicha legislación se hace mención.</Paragraph>
        <Paragraph>2.- El pago inicial realizado se utiliza para el pago de inscripción al programa y para el pago de la cuota de servicio de EduLynks. La cantidad restante será abonada al pago final del programa. En caso de diplomados, certifica- dos, posgrados, años académicos y boardings, se abona al segundo semestre del programa. En carreras de abonan 10,000 al pago del primer semestre y la diferencia restante al pago del segundo semestre.</Paragraph>
        <Paragraph>3.- El costo total del programa deberá estar liquidado a más tardar 4 semanas previo al inicio del curso y en caso de aplicar a un visado deberá estar cubierto a más tardar 12 semanas antes de la fecha de inicio o en las fechas estipuladas por el programa contratado.</Paragraph>
        <Paragraph>4.- EduLynks puede cancelar el curso en caso de no haber recibido el pago total del programa, previo a los 21 días de la fecha de salida o de inicio del curso y su cancelación será sujeta a los términos y condiciones de la escuela contratada.</Paragraph>
        <Paragraph>5.- El costo total del paquete se liquida en pesos mexicanos al tipo de cambio a la venta del día que se realiza la liquidación. Su asesor educativo les otorgará la cantidad a pagar en pesos del día que desean liquidar.</Paragraph>
        <Paragraph>6.- La liquidación de un programa tiene que ser antes de las 13:30 horas (hora del centro), con el fin de poder asegurar el mismo tipo de cambio al hacer la transferencia internacional.</Paragraph>
        <Paragraph>7.- El costo total del paquete no se puede recibir en otra divisa diferente a pesos mexicanos y debe ser depositado a las cuentas de EduLynks y/o la plataforma de pagos internacionales indicado por su asesor educativo. Para estudiantes no mexica- nos, o que realicen el pago desde el extranjero, se indicará las opciones y moneda de pago al contra- tar su programa.</Paragraph>
        <Paragraph>8.- En caso de realizar el pago directo a la institución contratada, es responsabilidad del cliente enterar a EduLynks con la copia de la transferencia internacional.</Paragraph>
        <Paragraph>9.- No se acepta efectivo en liquidaciones en las oficinas.</Paragraph>
        <Paragraph>10.- El tipo de cambio estipulado en los planes a pagos es solo como referencia ya que se tomará el definitivo al momento de liquidar el programa.</Paragraph>
        <Paragraph>11.- Pagos con tarjeta de crédito o débito se genera una comisión del 4.5%. En caso de ser a mensualidades, la comisión varía dependiendo del número de mensualidades establecidas.</Paragraph>
        <Paragraph>12.- Pagos con cheque se tomará al tipo de cambio del día que sea efectivo el cheque, al momento de que entra el pago puede ser necesa- rio hacer un ajuste de tipos de cambio.</Paragraph>
        <Paragraph>13.- La cuota de servicio de EduLynks incluye trámitación de inscripción curso/programa contrat- ado, una transferencia internacional de liquidación, asesoría y seguimiento hasta el regreso del estudiante a México. Transferencias adicionales tienen costo.</Paragraph>
        <Paragraph>14.- EduLynks otorga comprobantes de servicio por el pago del programa contratado, la factura la entrega la escuela a nombre del cliente.</Paragraph>
        <Paragraph>15.- EduLynks se obliga a entregar nota o comprobante de pago por todos y cada uno de los pagos hechos por el cliente.</Paragraph>
        <Paragraph>16.- EduLynks facturará solamente la cuota de servicio.</Paragraph>
        <Paragraph>17.- Los depósitos de garantía se reembolsarán a partir de la tercer semana de término del curso y serán sujetos a revisión de no dejar adeudos en el programa.</Paragraph>
        <Paragraph>18.- Cuando los precios de los servicios varíen por razones ajenas a EduLynks el cliente se obliga a hacerse cargo de la diferencia.</Paragraph>

        <Title level={5}>Tramitación de visa y permisos para salir del país</Title>

        <Paragraph>1.- Es obligación del cliente contar con su pasaporte vigente a tiempo para la tramitación de la visa. El pasaporte debe tener una vigencia mayor a 6 meses después de la fecha de término del programa.</Paragraph>
        <Paragraph>2.- Es responsabilidad del cliente la tramitación del visado correspondiente al programa de estudios.</Paragraph>
        <Paragraph>3. En caso de que la visa sea negada por concep- to de no entregar los documentos completos, mentir u omitir información en la aplicación o dar información errónea, el cliente no aplicará a rembolso alguno del programa.</Paragraph>
        <Paragraph>4.- EduLynks se deslinda de total responsabilidad sobre la respuesta que emita la embajada respec- to a la solicitud de visa.</Paragraph>
        <Paragraph>5.- EduLynks se deslinda de responsabilidad en caso de negación, atrasos por huelgas y factores externos a la empresa en su resolución de visas.</Paragraph>
        <Paragraph>6.- En caso de que la visa no se obtenga antes del inicio del programa, la fecha de inicio podrá cambiarse, sin embargo, cualquier costo adicional por concepto de cambio de fechas, cambio de vuelo, pérdida de días de clases u hospedaje, es responsabilidad del cliente.</Paragraph>
        <Paragraph>7.- EduLynks se deslinda de cualquier responsabi- lidad sobre compra de boletos de avión previos a la obtención de la visa, así como requisitos en vuelos o custodias gestionadas por las aerolíneas contratadas.</Paragraph>
        <Paragraph>8.- El cliente se obliga a revisar los permisos o requisitos para que los menores de edad puedan salir del país, así como hacer el trámite correspon- diente ante migración.</Paragraph>

        <Title level={5}>Seguro de gastos médicos y salud en el extranjero</Title>

        <Paragraph>1.- Todos los participantes se obligan a contar con un seguro de gastos médicos que tenga cobertura en el extranjero de acuerdo a los requisitos estipulados por la institución educativa contratada.</Paragraph>
        <Paragraph>2.- Es obligación del cliente conocer los términos y condiciones del seguro contratado, así como sus coberturas y procedimientos de reembolso.</Paragraph>
        <Paragraph>3.- Ningún seguro médico cubre enfermedades pre-existentes, deportes extremos, ni factores estéticos.</Paragraph>
        <Paragraph>4.- Es obligación del cliente hacer un chequeo general de salud previo al viaje.</Paragraph>
        <Paragraph>5.- EduLynks se deslinda del trámite de obtención de reembolsos por parte de la aseguradora contratada para el viaje; este trámite se deberá hacer directo con la empresa de asistencia de viajes contratada.</Paragraph>
        <Paragraph>6.- Es obligación del cliente enterar a EduLynks a través de la forma de aplicación, cualquier alergia o enfermedad pre-existente con el objeto de tomar las medidas necesarias de seguridad del estudiante o aceptación del programa. En caso de que EduLynks no esté enterado de dichas enfermedades podrá deslindarse de la no aceptación del programa y se exime de cualquier reembolso.</Paragraph>
        <Paragraph>7.- El seguro de gastos médicos internacional caduca una vez terminado el viaje de estudios, si el cliente desea extender su estancia en el país extranjero, es responsabilidad del mismo, contar con una cobertura adicional.</Paragraph>
        <Paragraph>8.- No habrá reducción en el costo para aquellos participantes que inicien o finalicen el programa fuera de las fechas contratadas.</Paragraph>

      </Col>
      <Col xs={24} lg={12}>
        <Title level={5}>Curso, seguridad y comportamiento durante el programa</Title>

        <Paragraph>1.- Todos los participantes aceptarán llevar a cabo las reglas generales de respeto y conducta estipuladas por el programa contratado, hacia sus compañeros, profesores y staff.</Paragraph>
        <Paragraph>2.- Es obligación del cliente, informarse y cumplir con los permisos migratorios del país donde estará radicando en el extranjero.</Paragraph>
        <Paragraph>3.- EduLynks se obliga a informar al cliente sobre la negación de lugares por falta de cupo en los programas establecidos.</Paragraph>
        <Paragraph>4.- EduLynks se deslinda de cualquier responsabilidad de expulsión del programa por faltas injustificadas, faltas de respeto, uso de estupefacientes o faltas al reglamen- to del programa.</Paragraph>
        <Paragraph>5.- El programa académico se reserva el derecho de expulsar a cualquier participante que interfiera en el bienestar de otros o que no cumpla el reglamento y deberá regresar a México (con previa notificación a los padres). Toda expulsión del estudiante no tendrá reemb- olso bajo ninguna circunstancia.</Paragraph>
        <Paragraph>6.- Para los estudiantes menores de edad está prohibido tomar bebidas alcohólicas, hacer uso de sustancias tóxicas, tener relaciones sexuales, fumar, manejar y tener actitudes de violencia o falta de respecto frente a sus compañeros, familia anfitriona y staff.</Paragraph>
        <Paragraph>7.- En caso de que un estudiante haga mal uso de instalaciones y cause danos deberá repararlos con su respectivo costo.</Paragraph>
        <Paragraph>8.- Los estudiantes se obligan a cumplir con las normas de seguridad expuestas en el programa, horarios, toques de queda, entre otros.</Paragraph>
        <Paragraph>9.- Cualquier comportamiento de robo a terceros será motivo de expulsión al programa sin derecho a reembol- so y será sujeto a la corte del país donde esté estudian- do y a cumplir las sanciones o multas dictadas.</Paragraph>
        <Paragraph>10.- El cliente deberá obtener un boleto de avión redondo (llegada-salida) con las fechas estipuladas por el programa contratado. En caso de tener otras fechas de viaje EduLynks no garantiza la colocación de hospedaje durante esas noches extras.</Paragraph>
        <Paragraph>11.- EduLynks se deslinda de cualquier responsabilidad sobre problemas climáticos, problemas de gobierno, huelgas por parte de la institución/programa contratado.</Paragraph>
        <Paragraph>12.- El cliente acepa los términos y condiciones específicos establecidos por el programa Estudia y Trabaja, programa Au-Pair y los programas de la institución específica contratada.</Paragraph>
        <Paragraph>13.- En el caso del programa Au-Pair, el cliente recibe una compensación económica basada en lo que indica el Departamento de Estado Norteameri- cano, de un mínimo semanal de 196.75 USD que está basado en el salario mínimo federal, con un 40% de deducción por alojamiento y alimentos a cambio de sus servicios al cuidado de niños(as) Familias huéspedes y Au- Pairs están en libertad de acordar una compensación económica mayor a la establecida legalmente como mínima.</Paragraph>
        <Paragraph>14.- En el caso de años académicos e boardings es obligación del cliente verificar que esté estudiando en el grado que le corresponde en el extranjero y enterar a EduLynks durante los primeros 15 días cualquier cambio de grado o materias.</Paragraph>
        <Paragraph>15.- En el caso de años académicos e boardings, es obligación del cliente realizar el trámite de revalidación ante la Secretaría de Educación Pública o escuela en la que se encuentre inscrito en México.</Paragraph>
        <Paragraph>16.- En caso de años académicos e boardings, si el estudiante reprueba alguna materia en el extran- jero no se podrá garantizar la aprobación del año de estudios en México.</Paragraph>
        <Paragraph>17.- En el caso de años académicos e boardings, es responsabilidad del estudiante enterar a EduLynks de las materias que deberá cursar en el extranjero y estar consciente que hay materias que podrán ser diferentes a las que se cursan en México.</Paragraph>
        <Paragraph>18.- EduLynks se deslinda de cualquier alteración del programa por causas de fuerza mayor como huelgas, golpes de estado, epidemias, siniestros ecológicos ajenos a la empresa.</Paragraph>
        <Paragraph>19.- La seguridad del estudiante es responsabili- dad del programa o institución educativa contrata- da. EduLynks funge como un intermediario entre el cliente y la institución.</Paragraph>
        <Paragraph>20.- Es obligación del cliente, contar con los requisitos para aplicar al programa seleccionado y presentar la documentación en tiempo y forma. De no hacerlo, EduLynks se deslinda de negaciones en la aplicación.</Paragraph>
        <Paragraph>21.- Para el caso de carreras y maestrías, si la universidad rechaza la aplicación del estudiante, éste pierde el costo de inscripción a dicha universi- dad.</Paragraph>
        <Paragraph>22.- Es obligación del cliente, adquirir y pagar cualquier traducción y/o certificación oficial que se le requiera.</Paragraph>
        <Paragraph>23.- Es obligación del estudiante aplicar a permisos de trabajo e internships. EduLynks no garantiza la obtención de trabajo, prácticas profesionales ni promueve trabajos ilegales en el extranjero.</Paragraph>
        <Paragraph>24.- El estudiante deberá cumplir además con los requisitos de la escuela seleccionada, así como sus políticas internas.</Paragraph>

        <Title level={5}>Hospedaje durante el programa</Title>

        <Paragraph>1.- Es obligación del estudiante avisar a EduLynks sobre cualquier tipo de alergias, enfermedades y características especiales con 30 días de anticipación a la fecha de inicio del viaje para la mejor obtención de un hospedaje.</Paragraph>
        <Paragraph>2.- Todo cambio de hospedaje deberá ser debida- mente justificado y notificado por escrito.</Paragraph>
        <Paragraph>3.- Todo cambio de hospedaje se realizará en un periodo de 20 días hábiles, salvo casos de emergencia donde esté en peligro la seguridad del estudiante.</Paragraph>
        <Paragraph>4.- Es obligación del cliente pagar su hospedaje en tiempo y forma para poder garantizar su estancia.</Paragraph>
        <Paragraph>5.- El estudiante acepta que se apegará a las reglas de su familia anfitriona o residencia, así como guardar las medidas de respeto y cuidar las instalaciones de alojamiento, con el fin de poder garantizar su seguridad durante el programa.</Paragraph>
        <Paragraph>6.- Es responsabilidad del estudiante cubrir cualquier deuda causada durante su estancia por concepto de llamadas por teléfono a su país, maltrato de las instalaciones, u otros.</Paragraph>
        <Paragraph>7.- Estudiantes menores de edad deberán cumplir con los horarios de llegada, así como con las actividades programa y horas límite para dormir.</Paragraph>

        <Title level={5}>Transportación aérea y recepción de aeropuerto</Title>

        <Paragraph>1.- EduLynks queda eximido de la responsabilidad que conlleve la pérdida de equipaje y/o cambios en horarios determinados por la línea aérea en la cual el cliente adquirió su boleto de avión.</Paragraph>
        <Paragraph>2.- Para que EduLynks pueda confirmar la llegada del estudiante y la recepción de aeropuerto, el cliente debe proporcionar los datos completos del itinerario 4 semanas previas al viaje. En caso de cambios notifica- dos por la aerolínea en el último momento, el cliente deberá comunicarse a los teléfonos de emergencia del programa, proporcionados durante su entrega de documentos para solicitar una modificación en la recepción de aeropuerto. La recepción de aeropuerto es un servicio individual y cualquier persona adicional implica un incremento en el costo de dicho servicio.</Paragraph>

        <Title level={5}>Viajes y excursiones durante el programa (únicamente aplica para Años académicos e boardings)</Title>

        <Paragraph>1.- Cualquier viaje o excursión no organizada por el programa deberá estar sujeta a aprobación de los padres o tutores del estudiante, por la seguridad del menor de edad.</Paragraph>
        <Paragraph>2.- El estudiante deberá cubrir cualquier gasto adicional por viajes y excursiones.</Paragraph>
        <Paragraph>3.- Los papás del estudiante en México, deberán enterar por escrito carta o correo electrónico, la aprobación del viaje.</Paragraph>

        <Title level={5}>Avisos de privacidad y uso de datos personales</Title>

        <Paragraph>1.- EduLynks cuenta con el aviso de privacidad estipulado por la ley: http://www.EduLynks.com/a- viso-de-privacidad/</Paragraph>
        <Paragraph>2.- EduLynks se reserva el uso de fotos y videos tomados durante el programa para uso exclusivo de promoción de éstos.</Paragraph>
        <Paragraph>3.- EduLynks no divulgará información personal de estudiantes que aplican al programa sin previa autorización de los padres, de los participantes o del estudiante.</Paragraph>
        <Paragraph>4.-Material como fotos en el campamento, testimonios y/o videos, EduLynks se reserva el derecho a su uso exclusivo para la promoción de sus programas.</Paragraph>
      </Col>
    </Row>
  )
}
export { TextTermsAndConditions }
