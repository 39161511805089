import { Col, Switch, Row, Button, Divider, Form, Select, Spin, Pagination, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetAllServiceCategories } from '../../../src/bussines/hooks/school/useGetAllServiceCategories'
import { useGetCampusByInstitutionAndProgram } from '../../../src/bussines/hooks/agency/analytics/program/useGetCampusByInstitutionAndProgram'
import { useGetAgencyInstitutions } from '../../../src/bussines/hooks/agency/agency_institutions/useGetAgencyInstitutions'
import { useGetAgencyInstitutionsCountries } from '../../../src/bussines/hooks/agency/agency_institutions/useGetInstitutionsCountries'
import { usePostInactiveInstitution } from '../../../src/bussines/hooks/agency/agency_institutions/usePostInactiveInstitution'
import { useActiveInstitution } from '../../../src/bussines/hooks/agency/agency_institutions/useActiveInstitution'
import { usePostInactiveHeartInstitution } from '../../../src/bussines/hooks/agency/agency_institutions/usePostInactiveHeartInstitution'
import { useActiveHeartInstitution } from '../../../src/bussines/hooks/agency/agency_institutions/useActiveHeartInstitution'
import stateControl from '../../bussines/utils/state-control'
import { IconHeartFilled } from '../../common/assets/svg/icons'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../providers/auth/auth-context-provider'

function IconHeart ({ active, institutionId, disabled, refetch }) {
  const [liked, setLiked] = useState(active)
  const { postInactiveHeartInstitution } = usePostInactiveHeartInstitution()
  const { activeHeartInstitution } = useActiveHeartInstitution()

  const handleLike = () => {
    setLiked(!liked)
    if (liked) {
      activeHeartInstitution({ institution_id: institutionId })
      console.log(
        `El corazón de la institución ${institutionId} está activado`
      )
    } else {
      postInactiveHeartInstitution({ institution_id: institutionId })

      console.log(
        `El corazón de la institución ${institutionId} está desactivado`
      )
    }
    refetch()
  }

  if (liked) {
    return (
      <Button
        id={institutionId}
        onClick={handleLike}
        size='small'
        className='heart-btn heart-btn-red'
      >
        <IconHeartFilled color='red' width='10' height='10' />
      </Button>
    )
  } else {
    return (
      <Button
        id={institutionId}
        onClick={handleLike}
        size='small'
        className='heart-btn heart-btn-inactive'
      >
        <IconHeartFilled color='#DCDCDC' width='10' height='10' />
      </Button>
    )
  }
}

function InstitutionsSearcher ({ onSearch, setPage }) {
  const institution = 'all'
  const type = 'schools'
  const { data: programs } = useGetAllServiceCategories()

  const [selectedProgram, setSelectedProgram] = useState()
  const [searchCountry, setSearchCountry] = useState()

  const {
    data: countries
  } = useGetAgencyInstitutionsCountries(searchCountry)

  const handleProgramChange = (value) => {
    setSelectedProgram(value)
    console.log(value)
  }

  const { data: campus } = useGetCampusByInstitutionAndProgram(selectedProgram, institution, type)
  const { Option } = Select
  const [form] = Form.useForm()

  const clearFilters = () => {
    setPage(1)
    form.resetFields()
    onSearch('')
  }

  const handleSearch = (values) => {
    setPage(1)
    onSearch(values)
  }

  return (
    <Form form={form} onFinish={handleSearch}>
      <Row gutter={24}>
        <Col sm={24} style={{ color: 'var(--blue-100)', marginTop: '3px' }}>
          <small>
            <b>Search by</b>
          </small>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item label='Program' name='program'>
            <Select placeholder='Program' onChange={handleProgramChange}>
              {programs?.map((item) => {
                return (
                  <Option key={item.id} value={item.name}>
                    {'Program '} {`${item.label}`}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item label='School' name='school'>
            <Select placeholder='School' showSearch>
              {campus?.map((item) => {
                return (
                  <Option key={item.id} value={item.name}>
                    {`${item.name}`}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={5}>
          <Form.Item label='Status' name='status'>
            <Select placeholder='Status'>
              <Option key='Active' value='Active'>
                Active
              </Option>
              <Option key='Inactive' value='Inactive'>
                Inactive
              </Option>
              <Option key='Favorites' value='Favorites'>
                Favorites
              </Option>
              {/* <Option key='NotFavorites' value='NotFavorites'>
                Not Favorites
              </Option> */}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={5.5}>
          <Form.Item label='Country' name='country'>
            <Select placeholder='Country' showSearch onSearch={(value) => setSearchCountry(value)}>
              {countries?.map((item) => {
                return (
                  <Option key={item.countryid} value={item.countryname}>
                    {`${item.countryname}`}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col style={{ gap: '10px', display: 'flex', marginBottom: '30px' }}>
          <Button
            style={{
              backgroundColor: 'lightgray',
              color: 'black',
              fontWeight: 'bold'
            }}
            onClick={clearFilters}
          >
            Clear
          </Button>
          <Button
            style={{
              backgroundColor: 'var(--blue-100)',
              color: 'white',
              fontWeight: 'bold'
            }}
            htmlType='submit'
          >
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

function InstitutionsView ({
  name,
  active,
  heart,
  urlphoto,
  institution_id,
  refetch
}) {
  const { postInactiveInstitution, isLoading: isDesactivating } = usePostInactiveInstitution()

  const { activeInstitution, isLoading, currentState } = useActiveInstitution()
  const { user: _user } = useAuth()
  const [modal, context] = Modal.useModal()
  const [hasPermision, setHasPermision] = useState(false)

  useEffect(() => {
    if (!isLoading && currentState === stateControl.STATE_SUCCESS) {
      refetch()
    }
  }, [isLoading, currentState])

  function handleSwitchChange (checked, institutionId) {
    if (!hasPermision) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    if (checked) {
      activeInstitution({ institution_id: institutionId })
      document.getElementById(institution_id).style.display = 'block'
    } else {
      postInactiveInstitution({ institution_id: institutionId })
      document.getElementById(institution_id).style.display = 'none'
    }
    refetch()
  }

  useEffect(() => {
    const role = _user?.roles
    if (role?.includes('Agency Administrator') || role?.includes('Agency Editor')) {
      setHasPermision(true)
    }
  }, [_user])

  return (
    <>
      {context}
      <Row style={{ backgroundColor: 'white' }}>
        <Col
          span={8}
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center'
          }}
        >
          <img
            style={{ maxWidth: '80%', height: '110px', margin: '2vh 2vw' }}
            src={urlphoto}
            alt='byp'
          />
        </Col>
        <Divider
          type='vertical'
          style={{
            background: '#CDDFFF',
            padding: '7vh 0',
            margin: '2.5vh 0',
            marginLeft: '1vw'
          }}
        />
        <Col span={14}>
          <p
            style={{
              fontSize: '1vw',
              fontWeight: '700',
              color: 'var(--blue-200)',
              margin: '20px 1.5vw',
              width: '100%',
              maxWidth: '220px'
            }}
          >
            {name}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              margin: '20px 30px'
            }}
          >
            <IconHeart
              active={heart}
              institutionId={institution_id}
              disabled={active}
              refetch={refetch}
            />

            <Switch
              id={institution_id + 's'}
              defaultChecked={active}
              checked={active}
              style={{
                alignItems: 'center',
                display: 'flex',
                width: 'auto'
              }}
              loading={isLoading || isDesactivating}
              checkedChildren='ACTIVE'
              unCheckedChildren='INACTIVE'
              onChange={(checked) => handleSwitchChange(checked, institution_id)}
            />
            {(isLoading || isDesactivating) && <Spin />}
          </div>
        </Col>
      </Row>
    </>
  )
}

const limit = 9
function InstitutionsComponents () {
  const [searchFilters, setSearchFilters] = useState({})
  const [dataInstitutions, setDataInstitutions] = useState([])
  const [page, setPage] = useState(1)
  const location = useLocation()
  const {
    data: Instituciones,
    isLoading,
    refetch
  } = useGetAgencyInstitutions({ ...searchFilters, page, limit }, location.pathname)
  const handleSearch = (values) => {
    setSearchFilters(values)
  }

  useEffect(() => {
    setDataInstitutions(Instituciones?.data)
  }, [Instituciones])

  return (
    <div style={{ padding: '0 5vw' }}>
      <h6 style={{ color: 'var(--blue-100)' }}>
        Select the institutions you want to see and select your favorites
      </h6>
      <p style={{ fontStyle: 'italic' }}>
        <small>Favorite schools will appear as first options at Home</small>
      </p>
      <InstitutionsSearcher onSearch={handleSearch} setPage={setPage} />
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin size='large' />
        </div>
      )}
      {!isLoading && (
        <Row gutter={[16, 16]}>
          {dataInstitutions &&
            dataInstitutions?.map((institution) => (
              <Col key={institution.id} xs={24} md={8} lg={6}>
                <InstitutionsView
                  name={institution.name}
                  active={institution.active}
                  heart={institution.heart}
                  urlphoto={institution.profile_photo_url}
                  institution_id={institution.id}
                  refetch={refetch}
                />
              </Col>
            ))}
        </Row>

      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingTop: '30px'
        }}
      >
        <Pagination
          defaultCurrent={1}
          current={Instituciones?.meta?.current_page ?? 1}
          // size='small'
          pageSize={Instituciones?.meta?.per_page ?? 20}
          total={Instituciones?.meta?.total ?? 0}
          onChange={setPage}
          hideOnSinglePage
          showQuickJumper={false}
          showSizeChanger={false}
        />
      </div>
    </div>
  )
}

export { InstitutionsComponents, InstitutionsSearcher }
