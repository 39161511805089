import { useQuery } from 'react-query'
import SearchDataRepository from '../../repositories/SearchDataRepository'

function useGetInstitutionsAndCampus (program, filters = {}) {
  return useQuery(['useGetInstitutionsAndCampus', program, filters],
    () => SearchDataRepository.getInstitutionsAndCampus(program, filters), {
      retry: 2
    })
}

function useGetDates (filters = {}) {
  return useQuery(['useGetGates', filters],
    () => SearchDataRepository.getDates(filters), {
      retry: 2
    })
}

function useGetCosts (program, filters = {}) {
  return useQuery(['useGetCosts', program, filters],
    () => SearchDataRepository.getCosts(program, filters), {
      retry: 2
    }
  )
}

function useGetStartdateAndHolidays (program, filters = {}) {
  const params = { program, ...filters }
  return useQuery(['useGetStartdateAndHolidays', params],
    () => SearchDataRepository.getStartdatesAndHolidays(params), {
      retry: 2
    }
  )
}

export { useGetInstitutionsAndCampus, useGetDates, useGetCosts, useGetStartdateAndHolidays }
