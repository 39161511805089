import React from 'react'
import { StepBaseTemplate } from './components/StepBaseTemplate'
import { RegisterForm } from './components/forms/RegisterForm'
import { useSearchParams } from 'react-router-dom'

const URL_REGISTER_PAGE = '/register'
const URL_REGISTER_PAGE_BETA = '/register/$2y$10$DwiblQ'

function RegisterPage () {
  const [params] = useSearchParams()
  const email = params.get('email') ?? ''
  const type = params.get('type') ?? ''

  return (
    <StepBaseTemplate justify='space-around' align='middle'>
      <div className='step-margin'>
        <h1 className='register-title'>Crea una cuenta para continuar</h1>
        {/* <p className='register-description'>Felicitaciones! Este es el primer paso para seguir tus sueños, para continuar crea tu cuenta</p> */}
        <RegisterForm email={email} type={type} />
      </div>
    </StepBaseTemplate>
  )
}

export { RegisterPage, URL_REGISTER_PAGE, URL_REGISTER_PAGE_BETA }
