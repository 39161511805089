import { Button, notification } from 'antd'
import { ShareAltOutlined } from '@ant-design/icons'

const buttonPropsTypes = {
  text: 'Compartir',
  icon: <ShareAltOutlined />,
  className: 'quote__btn-quote'
}
const ShareLinkButton = ({ url, buttonProps = buttonPropsTypes }) => {
  const handleShare = () => {
    navigator.clipboard.writeText(url).then(function () {
      notification.info({
        message: 'Enlace copiado al portapapeles',
        placement: 'top'
      })
    }).catch(function (error) {
      console.error('Error al copiar al portapapeles: ', error)
    })
  }
  return (
    <Button
      onClick={handleShare}
      {...buttonProps}
    >
      {buttonProps.text}
    </Button>
  )
}

export { ShareLinkButton }
