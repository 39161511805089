import { useEffect } from 'react'
import { showErrorModal } from '../../../utils/errorModal'

function ExceptionStepController ({ children, usePostMethod }) {
  const { error } = usePostMethod()
  useEffect(() => {
    if (error?.response) {
      showErrorModal(error.response.data.message)
    }
  }, [error])

  return (
    <div> {/* `contextHolder` should always under the context you want to access */}
      {children}
    </div>
  )
}

export { ExceptionStepController }
