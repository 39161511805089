import api from '../../services/api'

class OfferRespository {
  async offers (params) {
    const response = await api.get('/offers', { params })
    return response.data
  }

  async offerFilters (params) {
    const program = params?.program ?? 'language'
    const response = await api.get(`/programs/${program}/filters`, { params })
    return response.data
  }

  async recomendedOffers (params) {
    const response = await api.get('/recomended-offers', { params })
    return response.data
  }

  async find (offer) {
    const response = await api.get(`/offers/${offer}`)
    return response.data
  }

  async extras (offer, params) {
    const response = await api.get(`/offers/${offer}/extras`, { params })
    return response.data
  }

  async offerAvailableLanguages (params) {
    const response = await api.get('/offers/languages', { params })
    return response.data
  }
}

export default new OfferRespository()
