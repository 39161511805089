import { Col, Row } from 'antd'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { EditApplicationIcon } from '../../quote-preview/assets/imgs/Icons'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { ValidateEnterpriseProfile } from '../../../common/components/ValidateEnterpriseProfile'
import { FiUpload } from 'react-icons/fi'
import { URL_APLICACION_PAGE, URL_APLICACION_SHOW_PAGE } from '../../aplicacion'
import RandomImage from '../../../utils/RandomImage'

const URL_CONFIRMATION_PAGE2 = '/confirmacion2'

function ApplyConfirmation() {
  const location = useLocation()
  const { wantAccomodation, quoteId, applicationId, isAdmin } = location.state || {}

  const handleLink = () => {
    if (quoteId && applicationId) {
      let newUrl
      if (isAdmin) newUrl = URL_APLICACION_PAGE?.replace(':id', quoteId)?.replace(':applicationId', applicationId)
      if (!isAdmin) newUrl = URL_APLICACION_SHOW_PAGE?.replace(':id', quoteId)?.replace(':applicationId', applicationId)
      return newUrl
    }

    return '/'
  }

  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <div style={{ overflow: 'hidden', height: '100vh' }}>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <RandomImage />
            {/* <img className='component_none' src={image} alt='RR6' style={{ width: '100%' }} /> */}
          </Col>
          <Col md={12}>
            <Row justify='center'>
              <Col xs={20} md={12} className='confirmation confirmation2'>
                <h2 style={{ marginBottom: '32px' }}>
                  ¡Felicidades estamos comenzando tu proceso de aplicación!
                </h2>
                <p>
                  {wantAccomodation ? 'Un asesor se pondrá en contacto contigo para continuar con la selección del hospedaje.' : ''}
                </p>
                <p>
                  Puedes avanzar en tu proceso subiendo los primeros documentos que necesitaras para tu viaje de estudios:
                </p>
                <Row className='confirmation2'>
                  <Col>
                    <Link to={handleLink()} className='btn__pinkConfirm byp-mb-1' style={{ marginTop: '32px', marginBottom: '20px' }}>
                      <EditApplicationIcon color='#5D5D5D' /> Ver tu formato de aplicación
                    </Link>
                    <Link className='btn-application byp-mb-1' style={{ padding: '0.54rem 1rem' }}>
                      <FiUpload />Sube tus documentos
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ValidateEnterpriseProfile>
  )
}

export { ApplyConfirmation, URL_CONFIRMATION_PAGE2 }
