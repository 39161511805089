import { Statistic, Typography } from 'antd'
import React from 'react'
import { usePackage } from '../../../providers/package/package-course-context'
import { RowQuoter } from '../../quote-course/components/RowQuoter'
import { getDiscountSymbol, handlePrice } from '../../../utils/getDiscountSymbol'
// import { format } from 'date-fns'
// import { formatStringDate } from '../../../utils/formatStringDate'

const { Text } = Typography

function PackageOpenPrice() {
  const { service, prices, formConfig } = usePackage()

  if (!prices && !formConfig?.weeks) {
    return
  }

  return (
    <>
      <RowQuoter
        colA={
          <>
            {service.getCourseCost() && (
              <Text className='conceptMonetario' key={service.getCourseCost().id}>{service.getCourseCost().name}</Text>
            )}
            {/* {service.getDiscountsInKindGoods()?.map( // Discounts pf type Other (In kind)
              d => {
                const discount = d.discount ?? {}
                const endDate = new Date(discount.end)
                return (
                  <p key={d.id} className='byp-fw-bold'>
                    {d.name} discount, until {format(endDate, 'LLL dd yyyy')}
                  </p>
                )
              }
            )} */}
            {
              service.courseHasDiscount() && service.getCourseDiscounts()?.map(d => (
                <p key={d.id} className='byp-fw-bold'>
                  {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Curso.
                </p>
              ))
            }
          </>
        }
        colB={
          <>
            {service.getCourseCost() && (
              <div key={service.getCourseCost().id}>
                <Statistic
                  prefix=''
                  precision={2}
                  value={`${service.getCourseCost().cost}`}
                  // suffix={`${service.getCurrency()}`}
                />
              </div>
            )}
            {service.courseHasDiscount() && (// Discounts
              <Statistic
                prefix=''
                className='price-after'
                precision={2}
                value={`${service.getCourseAmountWithDiscount()}`}
                suffix={`${service.getCurrency()}`}
              />
            )}
            {
              service.courseHasDiscount() && service.getCourseDiscounts()?.map(d => (
                <Statistic
                  prefix=''
                  className='price-after'
                  precision={2}
                  // value={`${d.type === 'amount' ? d.value : d.value + '%'}`}
                  // suffix={`${service.getCurrency()}`}
                  value={`${d.type === 'amount' ? d.value : handlePrice(d.value, service.getAmountInscription()?.toFixed(2))}`}
                  // suffix={`${service.getCurrency()}`}
                />
              ))

            }
          </>
        }
      />

    </>
  )
}

export { PackageOpenPrice }
