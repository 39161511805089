import React, { useEffect, useState } from 'react'
import { useFindPage } from '../../../bussines/hooks/page/useFindPage'
import { Button, Card, Col, Form, Image, Input, Modal, Row, Space, notification } from 'antd'
import { NotFoundPage } from '../../../common/components/NotFoundPage'
import { BlogContainer } from './components/BlogContainer'
import { BlogAds } from './components/BlogAds'
import { HomeCarrousel } from '../../../common/components/HomeCarrousel'
import { CardCourse } from './components/CardCourse'
import { IconSchool } from '../../../common/assets/svg/icons'
import { logoEdit } from '../../../utils/fallbackImage'
import { BlogComments } from './components/BlogComments'
import { ModalCommentForm } from '../../../common/components/ModalCommentForm'
import { usePostSavePageComment } from '../../../bussines/hooks/page/usePostSavePageComment'
import { BlogFooter } from '../blogs/components/BlogFooter'
// import { BlogSkeleton } from './components/BlogSkeleton'
import { BasicSpinner } from '../../../common/components/BasicSpinner'
import { usePublishedPage } from '../../../bussines/hooks/page/useSaveBlog'
import { useLocation, useNavigate } from 'react-router-dom'
import { URL_BLOG_PAGE } from './BlogPage'
import { Helmet } from 'react-helmet'
import { URL_WEBSITE } from '../../../config'
import stripHtml from '../../../utils/stripHtml'

function BlogPageInfo({ blog, isPreview }) {
  const navigation = useNavigate()
  const location = useLocation()
  const { isLoading: isSearching, data: page, error } = useFindPage(blog)
  const { saveComment, isLoading: isSaving, data: response, error: saveError, currentState } = usePostSavePageComment()
  const [cover, setCover] = useState()
  const [requireRefetch, setRequireRefetch] = useState(false)
  const [modalAuthor, setModalAuthor] = useState(false)
  const [isThereError, setError] = useState(false)
  const [author, setAuthor] = useState(null)
  const [appointmentsLink, setAppointmentsLink] = useState()
  const [offersLink, setOffersLink] = useState()
  const { save, isLoading: isSavingBlog, data: saveResponse, error: saveErrorBlog } = usePublishedPage()
  const [pageMeta, setPageMeta] = useState({ title: 'BYP', keywords: 'Book Your Program', description: '', url: location.pathname, image: null })

  useEffect(() => {
    if (!isSearching && page) {
      const cover = page?.files?.find((file) => file.type === 'image' && file.pivot?.position === 'Cover')
      if (cover) setCover(cover.src)
      if (!cover) setCover(logoEdit)
      // links
      setOffersLink(page.blocks?.find(b => b.type === 'link' && b.name === 'offers-link')?.subtitle)
      setAppointmentsLink(page.blocks?.find(b => b.type === 'link' && b.name === 'appointments-link')?.subtitle)

      const tags = page.tags?.map(tag => tag.name).join(', ')
      const firstTextBlock = page.content?.find(content => content.type === 'text')
      let description = page.title
      if (firstTextBlock?.body) {
        description = stripHtml(firstTextBlock?.body)
        const descriptionArray = description.split(' ')
        if (descriptionArray.length > 50) {
          const first50Elements = descriptionArray.slice(0, 50)
          description = first50Elements.join(' ')
        }
      }

      const nMeta = {
        title: page.title,
        keywords: tags,
        description,
        url: URL_WEBSITE + '' + location.pathname,
        image: cover?.src ?? logoEdit
      }
      console.log('🚀 ~ useEffect ~ nMeta:', nMeta)
      setPageMeta(nMeta)
    }
  }, [isSearching, page])

  useEffect(() => {
    if (!isSaving && response) {
      setRequireRefetch(true)
    } else if (!isSaving && saveError) {
      notification.error({
        message: saveError?.response?.data?.message ?? 'Something went wrong'
      })
    }
  }, [isSaving, response])

  // if (!requireRefetch) {
  //   return <BlogSkeleton />
  // }

  const publicarBlog = () => {
    setModalAuthor(false)
    save(blog)
  }

  useEffect(() => {
    if (!isSavingBlog && saveErrorBlog) {
      notification.error({
        message: saveErrorBlog?.response?.data?.message
      })
    }
    if (saveResponse && !isSavingBlog && !saveErrorBlog) {
      navigation(URL_BLOG_PAGE.replace(':slug', blog))
    }
  }, [isSavingBlog, saveResponse])

  function VerifyMistake() {
    if (author && author.length > 0) {
      setError(false)
      publicarBlog()
    } else {
      setError(true)
    }
  }

  function ShowMessage() {
    if (isThereError) {
      return (
        <div style={{ color: 'red' }}>
          *Introduzca un nombre
        </div>
      )
    }
  }
  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const scrollThreshold = 200
      setIsFixed(scrollPosition >= scrollThreshold)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!isSearching && ((error?.response?.status === 404 || page?.status !== 'Published') && !isPreview)) {
    return <NotFoundPage />
  }

  return (
    <Row justify='center'>
      <Helmet>
        <title>{pageMeta.title}</title>
        <meta name='title' content={pageMeta.title} />
        <meta name='description' content={pageMeta.description} />
        <meta name='keywords' content={pageMeta.keywords} />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property='og:type' content='website' />
        <meta property='og:url' content={pageMeta.url} />
        <meta property='og:title' content={pageMeta.title} />
        <meta property='og:description' content={pageMeta.description} />
        <meta property='og:image' content={pageMeta.image} />

        {/* <!-- Twitter --> */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content={pageMeta.url} />
        <meta property='twitter:title' content={pageMeta.title} />
        <meta property='twitter:description' content={pageMeta.description} />
        <meta property='twitter:image' content={pageMeta.image} />
      </Helmet>
      {isPreview && (
        <Col span={22} className='edit__buttons' style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              setModalAuthor(true)
            }}
            loading={isSavingBlog}
          >
            Publicar
          </Button>
        </Col>
      )}
      <Col span={22} className='blog'>
        {!cover && (
          <div className='blog__header-no-image' />
        )}

        {cover && (
          <div className='blog__header'>
            <div>
              <Image
                width='100%'
                preview={false}
                src={cover}
              />
            </div>
            <h1 className='break-word w-100 hide_mobile'>{page?.title}</h1>
          </div>
        )}

        <Row className='byp-mt-1 '>
          {/* Link buttons */}
          {
            (appointmentsLink || offersLink) && (
              <Card span={24} className={`w-100 justify-end flex-byp__group ${isFixed ? 'quotation__container-header' : ''}`} size='small'>
                <Col span={24} className='justify-end flex-byp__group'>
                  <Space>
                    {appointmentsLink && (
                      <Button
                        className='quote-byp__btn-opinion'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={appointmentsLink}
                      >
                        Agenda tu cita
                      </Button>
                    )}
                    {offersLink && (
                      <Button
                        className='quote-apply_now'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={offersLink}
                      >
                        Ver opciones
                      </Button>
                    )}
                  </Space>
                </Col>
              </Card>
            )
          }
          {/* Content */}
          <Col
            style={{ marginTop: '20px' }}
          >
            <p className='blog__body byp-mb-1'>{page?.body}</p>
            {isSearching && <BasicSpinner style={{ height: '40vh' }} />}
            <BlogContainer content={page?.content ?? []} />

            <div className='byp-mt-1 blog__comments'>
              <BlogComments
                blogSlug={blog}
                requireRefetch={requireRefetch} setRequireRefetch={setRequireRefetch}
              />
            </div>

            <ModalCommentForm
              onFinish={(values) => {
                console.log('🚀 ~ file: BlogPageInfo.js:54 ~ BlogPageInfo ~ values:', values)
                saveComment(page?.slug, values)
              }}
              isLoading={isSaving}
              status={currentState}
            />

          </Col>
          {/* Side content */}
          <Col>
            <BlogAds tags={page?.tags} blogSlug={blog} />
          </Col>
        </Row>

        <h4 style={{ color: '#373737' }} className='title-with-icon'><IconSchool />Nuestra recomendación de cursos</h4>
        <p style={{ color: '#797979' }}>Descubre el mundo estudiando</p>
        <HomeCarrousel slides={page?.course_offers ?? []} Slide={CardCourse} className='quotation__footer-carrousel big-home__package edit_carousel edit_page-country' slidesPerRow={4} />
        {page?.course_offers && (
          <div className='quotation__footer-carrousel-responsive'>
            <div>
              {page?.course_offers.map(item => (
                <CardCourse item={item} key={item.id} />
              ))}
            </div>
          </div>
        )}
        <BlogFooter />
      </Col>

      {/* Modals */}
      <Modal visible={modalAuthor} onOk={() => publicarBlog} onCancel={() => setModalAuthor(false)} okText='Publicar' cancelText='Cancelar' footer='' width='400px'>
        <Form.Item name='username' label='Introduzca su nombre'>
          <Input onChange={e => setAuthor(e.target.value)} />
        </Form.Item>
        <ShowMessage />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '10px' }}>
          <Button onClick={() => setModalAuthor(false)} style={{ fontWeight: 'bold' }}>
            Cancel
          </Button>
          <Button
            onClick={VerifyMistake}
            style={{ background: 'var(--blue-100)', color: 'white', fontWeight: 'bold' }}
            loading={false}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </Row>
  )
}

export default BlogPageInfo
