import { Button, Col, Form, Input, Modal, Row, Upload, notification } from 'antd'
import { RiImageAddLine } from 'react-icons/ri'
import { logoEdit } from '../../../../utils/fallbackImage'
import { useFindPage } from '../../../../bussines/hooks/page/useFindPage'
import { useEffect, useState } from 'react'
import { NotFoundPage } from '../../../../common/components/NotFoundPage'
import { getBase64, headersForUpload, isJpgOrPng, isLt2M } from '../../../../utils/uploadImageUtils'
import { API_URL } from '../../../../config'
import { MonitoringIcon } from '../../../quote-preview/assets/imgs/Icons'
import { ButtonAddBlock } from './ButtonAddBlock'
import { BlocksFormList } from './BlocksFormList'
import { SelectTagsFormItem } from './SelectTagsFormItem'
import { SelectPlacesWithOffers } from '../../../../common/components/helper-form/SelectPlacesWithOffers'
// import { SelectCoursesFormItem } from '../../../../common/components/helper-form/SelectCoursesFormItem'
import { useSaveBlog } from '../../../../bussines/hooks/page/useSaveBlog'
import { useNavigate } from 'react-router-dom'
import { URL_TABLE_BLOGS_PAGE } from '../PageTableBlogs'
import { ArrowLeftOutlined, LinkOutlined } from '@ant-design/icons'
import { BasicSpinner } from '../../../../common/components/BasicSpinner'
import { URL_BLOG_PAGE, URL_BLOG_PREVIEW_PAGE } from '../../blog/BlogPage'
import { FeaturedDates } from './FeaturedDates'
import { momentToDateformat } from '../../../../utils/formatMoment'
import moment from 'moment'
import { URL_EDIT_BLOG_PAGE } from '../PageEditBlog'
import ModalAddCourse from '../../edit-page/components/ModalAddCourse'
import { IconSchool } from '../../../../common/assets/svg/icons'
import { CardCourse } from '../../blog/components/CardCourse'
import { HomeCarrousel } from '../../../../common/components/HomeCarrousel'
import { showErrorModal } from '../../../../utils/errorModal'

function removeAccents (text) {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

function beforeUpload (file) {
  const _isJpgOrPng = isJpgOrPng(file)
  if (!_isJpgOrPng) {
    showErrorModal(null, 'Solo puedes subir imágenes con formato jpeg o png!')
  }
  const _isLt2M = isLt2M(file)
  if (!_isLt2M) {
    showErrorModal(null, 'La imagen no debe pesar más de 2MB!')
  }
  return _isJpgOrPng && _isLt2M
}

const FormEditBlog = ({ blog }) => {
  const navigation = useNavigate()
  const [form] = Form.useForm()
  const [slug, setSlug] = useState(blog)
  const { isLoading: isSearching, data: page, error, refetch } = useFindPage(slug)
  const { save, isLoading: isSaving, data: saveResponse, error: saveError } = useSaveBlog()
  const [image01, setImage01] = useState(null)
  const [image01Url, setImage01Url] = useState(null)
  const [initialData, setInitialData] = useState()
  const [isVisible, setIsVisible] = useState(false)
  const [isPreview, setIsPreview] = useState(false)
  const [values, setValues] = useState()
  const [isAprove, setIsAprove] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalAuthor, setModalAuthor] = useState(false)
  const [isThereError, setError] = useState(false)
  const [isModalCoursesOpen, setIsModalCoursesOpen] = useState(false)
  const [course, setCourse] = useState()
  const [slides, setSlides] = useState(4)
  const [courses, setCourses] = useState([])
  const [idSercices, setIdServices] = useState([])
  const [idItemCourse, setIdItemCourse] = useState()
  const [idSercicesOrder, setIdServicesOrder] = useState([])
  const [url, setUrl] = useState()
  const [name, setName] = useState()
  const [showModalCopy, setShowModalCopy] = useState(false)
  const [coursesFilters, setCoursesFilters] = useState({
    campus: [],
    programs: []
  })
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const handleFinish = (values, aprove = false) => {
    setIsAprove(aprove)
    setModalAuthor(false)
    values.id = page?.id
    values.cover = image01Url ?? null
    values.status = aprove ? 'Published' : 'Pending'
    if (isPreview) {
      values.status = page.status
    }
    const date = values.featured_end
    const time = values.featured_end_time
    if (date && time) {
      const dateFormated = momentToDateformat(date)
      const timeFormated = momentToDateformat(time, 'HH:mm')
      values.featured_end_at = dateFormated + ' ' + timeFormated
    }
    // get the url content of the image types
    const contents = values.content?.map((content) => {
      if (['image'].includes(content.type)) {
        const url = content.image?.file?.response?.url
        return {
          ...content,
          body: url
        }
      }
      if (['video'].includes(content.type)) {
        const url = content.body?.target?.value ?? content.body
        return {
          ...content,
          body: url
        }
      }
      return content
    })
    values.content = contents
    values.course_tags = idSercicesOrder
    const readyToSave = true
    if (readyToSave) {
      save(values)
      if (isPreview) {
        const title = removeAccents(values.title)
        const nSlug = title.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '')
        nSlug === slug ? refetch() : setSlug(nSlug)
        const backUrl = process.env.REACT_APP_API_URL + `/blogs/${nSlug}/show`
        setUrl(backUrl)
        // const previewUrl = URL_BLOG_PREVIEW_PAGE.replace(':slug', nSlug)
        // navigation(URL_BLOG_PREVIEW_PAGE.replace(':slug', nSlug))
        // window.open(previewUrl, '_blank')
      }
    }
  }

  const handleShare = () => {
    navigator.clipboard.writeText(url).then(function () {
      notification.info({
        message: 'Enlace copiado al portapapeles',
        placement: 'top'
      })
    }).catch(function (error) {
      console.error('Error al copiar al portapapeles: ', error)
    })
  }

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  const handleChange = (event) => {
    if (event.file.status === 'done') {
      getBase64(event.file.originFileObj, (imageUrl) => {
        setImage01(imageUrl)
        setImage01Url(event.file.response.url)
      })
    } else if (event.file.status === 'error') {
      console.log('🚀 ~ file error:', event.file.response)
    }
  }

  const handleAddBlock = (type) => {
    const blocksList = form.getFieldValue('content')
    switch (type) {
      case 'text':
        blocksList.push({
          key: 'text-' + blocksList.length + 1,
          type: 'text',
          order: blocksList.length,
          style: {},
          hidded: false
        })
        break
      case 'col':
        blocksList.push({
          key: 'col-' + blocksList.length + 1,
          type: 'col',
          order: blocksList.length,
          style: {},
          hidded: false
        })
        break
      case 'title':
        blocksList.push({
          key: 'title-' + blocksList.length + 1,
          type: 'title',
          order: blocksList.length,
          style: {},
          hidded: false
        })
        break
      case 'image':
        blocksList.push({
          key: 'image-' + blocksList.length + 1,
          type: 'image',
          order: blocksList.length,
          style: {},
          hidded: false
        })
        break
      case 'video':
        blocksList.push({
          key: 'video-' + blocksList.length + 1,
          type: 'video',
          order: blocksList.length,
          style: {},
          hidded: false
        })
        break
      default: return null
    }
    form.setFieldsValue({
      content: blocksList
    })
  }

  useEffect(() => {
    if (!isSearching && page) {
      const countries = page?.countries?.map((country) => country.id)
      const cities = page?.cities?.map((city) => city.id)
      const school = page?.campus?.map((campus) => campus.id)
      const program = page?.programs?.map((program) => program.id)
      // const course = page?.courses?.map((course) => course.id)
      setCourses(page?.courses ?? [])
      setIdServices(page?.courses?.map((blog) => blog.id) ?? [])
      setIdServicesOrder(page?.courses?.map((blog) => ({ pageable_id: blog.id, order: blog?.pivot?.order })) ?? [])
      const cover = page?.files?.find((file) => file.type === 'image' && file.pivot?.position === 'Cover')
      const isFt = !!page?.featured_end_at
      const featuredEnd = new Date(page?.featured_end_at)
      const featuredEndTime = new Date(page?.featured_end_at)
      // Separete child content
      const mainContent = page.content?.filter((content) => !content.parent_id)
      const childContent = page.content?.filter((content) => !!content.parent_id)
      const pageContent = mainContent.map((content) => {
        if (content.type !== 'col') {
          content.key = content.type + '-' + content.id
          return content
        }
        content.key = content.type + '-' + content.order + '-' + content.id
        const childA = childContent.find((childContent) => childContent.parent_id === content.id && childContent.order === 1)
        const childB = childContent.find((childContent) => childContent.parent_id === content.id && childContent.order === 2)
        if (childA) {
          childA.column = 'a'
          childA.key = content.type + '-' + content.id + '-child-' + 'a'
        }
        if (childB) {
          childB.column = 'b'
          childB.key = content.type + '-' + content.id + '-child-' + 'b'
        }
        return {
          ...content,
          blockA: childA,
          blockB: childB
        }
      })
      // links
      const offersLink = page.blocks?.find(b => b.type === 'link' && b.name === 'offers-link')?.subtitle
      const appointmentsLink = page.blocks?.find(b => b.type === 'link' && b.name === 'appointments-link')?.subtitle
      const dataLink = page.blocks?.find(b => b.type === 'link' && b.name === 'user-form-link')?.subtitle

      const data = {
        title: page?.title,
        subtitle: page?.subtitle,
        tags: page?.tags?.map((tag) => tag.name),
        country_tags: countries,
        city_tags: cities,
        school_tags: school,
        program_tags: program,
        // course_tags: course,
        content: pageContent?.sort((a, b) => a.order > b.order ? 1 : -1),
        featured_end: isFt ? moment(featuredEnd) : undefined,
        featured_end_time: isFt ? moment(featuredEndTime) : undefined,
        isFeatured: isFt,
        offers_link: offersLink,
        appointments_link: appointmentsLink,
        user_form_link: dataLink
      }
      if (cover) {
        setImage01(cover.src)
      }
      setInitialData({ countries })
      form.setFieldsValue(data)
    } else if (!isSearching && error) {
      notification.error({
        message: error.response?.data?.message ?? 'Something went wrong'
      })
    }
  }, [isSearching, page])

  useEffect(() => {
    if (!isSaving && saveResponse && isAprove) {
      const title = removeAccents(form.getFieldValue('title'))
      const nSlug = title.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '')
      nSlug === slug ? refetch() : setSlug(nSlug)
      const backUrl = process.env.REACT_APP_API_URL + `/blogs/${nSlug}/show`
      setUrl(backUrl)
      // navigation(URL_BLOG_PAGE.replace(':slug', nSlug))
      setShowModalCopy(true)
    } else if (!isSaving && saveResponse) {
      const title = removeAccents(form.getFieldValue('title'))
      const nSlug = title.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '')
      notification.success({
        message: 'Data saved'
      })
      nSlug === slug ? refetch() : navigation(URL_EDIT_BLOG_PAGE?.replace(':slug', nSlug))
    }
    if (!isSaving && saveError) {
      notification.error({
        message: saveError?.response?.data?.message
      })
    }
  }, [isSaving, saveResponse])

  const handleMouseEnter = () => {
    setIsVisible(true)
  }

  const handleMouseLeave = () => {
    setIsVisible(false)
  }

  const showModal = (e) => {
    setValues(e)
    setModalAuthor(true)
    // if ((!image01Url && !image01) || !(e?.content?.length > 0) || !(e?.tags?.length > 0) || !(e?.country_tags?.length > 0) || !(e?.city_tags?.length > 0) || !(e?.school_tags?.length > 0) || !(e?.program_tags?.length > 0) || !(courses?.length > 0)) {
    //   setIsModalOpen(true)
    // } else {
    //   setModalAuthor(true)
    // }
  }

  const Error = () => {
    setError(true)
  }

  const NoError = () => {
    setError(false)
  }

  function VerifyMistake () {
    if (form.getFieldValue('username')) {
      if (form.getFieldValue('username').trim().length === 0) {
        Error()
      } else {
        values.username = form.getFieldValue('username').trim()
        handleFinish(values, true)
        NoError()
      }
    } else {
      Error()
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setModalAuthor(false)
  }

  const continueOK = () => {
    setIsModalOpen(false)
    setModalAuthor(true)
  }

  const handleOk = () => {
    handleFinish(values, true)
    setIsModalOpen(false)
    setModalAuthor(false)
  }

  const handleCancelCourses = () => {
    setIsModalCoursesOpen(false)
  }
  const showModalCourses = () => {
    setIsModalCoursesOpen(true)
  }
  const handleEditCourse = (id) => {
    setIdItemCourse(id)
    setIsModalCoursesOpen(true)
  }
  const handleRemoveCourse = (id) => {
    setCourses(currentValue => {
      return currentValue?.filter((item) => item.id !== id)
    })
    setIdServices(currentValue => {
      return currentValue?.filter((item) => item !== id)
    })
    setIdServicesOrder(currentValue => {
      return currentValue?.filter((item) => item.pageable_id !== id)
    })
    setIdServicesOrder(currentValue => {
      return currentValue?.map((item, index) => ({
        pageable_id: item.pageable_id,
        order: index + 1
      }))
    })
  }
  const handleOkCourses = () => {
    if (course) {
      if (idItemCourse) {
        setCourses(currentValue => {
          const index = currentValue.findIndex(item => item.id === idItemCourse)
          const newCourses = [...currentValue]
          newCourses.splice(index, 0, course)
          return newCourses
        })
        setIdServices(currentValue => {
          const index = currentValue.findIndex(item => item.id === idItemCourse)
          const newCoursesIds = [...currentValue]
          newCoursesIds.splice(index, 0, course.id)
          return newCoursesIds
        })
        setIdServicesOrder(currentValue => {
          const index = currentValue.findIndex(item => item.pageable_id === idItemCourse)
          const newCoursesIds = [...currentValue]
          newCoursesIds.splice(index, 0, { pageable_id: course.id, order: index })
          return newCoursesIds
        })
        handleRemoveCourse(idItemCourse)
      } else {
        setCourses(currentValue => {
          return [...currentValue, course]
        })
        setIdServices(currentValue => {
          return [...currentValue, course.id]
        })
        setIdServicesOrder(currentValue => {
          return [...currentValue, { pageable_id: course.id, order: idSercices?.length + 1 }]
        })
      }
    }
    setIdItemCourse()
    setIsModalCoursesOpen(false)
    form.resetFields(['program'])
    form.resetFields(['campus'])
    form.resetFields(['courses'])
    form.resetFields(['city'])
    form.resetFields(['school'])
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (windowSize.width <= 800) {
      setSlides(1)
    } else {
      setSlides(4)
    }
  }, [windowSize])

  function ShowMessage () {
    if (isThereError) {
      return (
        <div style={{ color: 'red' }}>
          *Introduzca un nombre
        </div>
      )
    }
  }

  if (isSearching) {
    return <BasicSpinner style={{ minHeight: '60vh' }} />
  }

  if (!isSearching && error?.response?.status === 404) {
    return <NotFoundPage />
  }

  return (
    <Form form={form} onFinish={handleFinish} layout='vertical'>
      <Row gutter={[16, 16]}>
        {/* Header */}
        <Col span={24}>
          <div className='d-flex justify-between align-center'>
            <div className='d-flex align-center' style={{ gap: '0.5rem' }}>
              <Button className='btn__back' onClick={() => navigation(URL_TABLE_BLOGS_PAGE)}><ArrowLeftOutlined style={{ width: '1rem' }} /> Volver</Button>
              Editar Artículo
              {/* {page?.status && '[' + page?.status + ']'} */}
            </div>
            <div className='d-flex edit__buttons' style={{ gap: '0.5rem' }}>
              <Button
                onClick={() => {
                  handleFinish(form.getFieldsValue())
                }}
                loading={isSaving}
              >
                Guardar
              </Button>
              {/* <Button
                onClick={() => {
                  setIsPreview(true)
                  handleFinish(form.getFieldsValue())
                }}
                htmlType='submit'
                className='view__preview'
                loading={isSaving}
              >
                <MonitoringIcon /> Vista Previa
              </Button> */}
              <Button
                onClick={() => {
                  setIsPreview(false)
                  showModal(form.getFieldsValue())
                }}
                loading={isSaving}
              >
                Mostrar api
              </Button>
            </div>
          </div>
        </Col>

        {/* Img Header */}
        {/* <Col span={24}>
          <label className='icon-edit-blog-title-image-page' htmlFor='image01__page' style={{ width: '100%', height: 'auto', borderRadius: '12px' }}>
            <div className='edit-blog__image'>
              {image01 ? <img src={image01} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> : <div style={{ width: '100%', height: '65rem' }} />}
            </div>
            <div className='edit-blog__cover-label'>
              <RiImageAddLine size='23' style={{ marginRight: '.6rem' }} />
              {image01 ? '  Actualizar foto de portada' : ' Añadir foto de portada'}
            </div>
          </label>

          <div style={{ display: 'none' }}>
            <Upload
              name='image'
              listType='picture-card'
              className='upload-page__img'
              showUploadList={false}
              action={`${API_URL}/pages/images`}
              id='image01__page'
              onChange={event => handleChange(event)}
              headers={headersForUpload()}
              beforeUpload={beforeUpload}
            >
              <img src={image01 ?? logoEdit} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
            </Upload>
          </div>
        </Col> */}
        <Col span={24}>
          <label>Título</label>
          <Form.Item name='title' className='blog-title-input'>
            <Input placeholder='Titulo' onChange={setName} />
          </Form.Item>
        </Col>

        {/* Nuestra recomendación de cursos */}
        <Col span={24}>
          <Row justify='center'>
            <Col span={23}>
              <h4 style={{ color: '#373737', marginTop: '10px' }} className='title-with-icon'>
                <IconSchool color='#373737' width='20px' height='20px' /> Nuestra recomendación de cursos
              </h4>
              <p style={{ color: '#797979' }}>Descubre el mundo estudiando</p>
            </Col>
          </Row>
          <Row style={{ padding: '2rem' }}>
            <Col span={23}>
              <HomeCarrousel slides={courses ?? []} Slide={CardCourse} className='big-home__package edit_carousel edit_page-country' slidesPerRow={slides} isEditing editSection={8} editModal={showModalCourses} editItem={handleEditCourse} removeItem={handleRemoveCourse} />
            </Col>
          </Row>
        </Col>
        {/* Bloques */}
        {/* <Col span={16}>
          <BlocksFormList name='content' />
          <div
            className={isVisible ? 'dropdown__add-block visible byp-mt-1' : 'dropdown__add-block byp-mt-1'}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <ButtonAddBlock
              onSelect={handleAddBlock}
              isVisible={isVisible}
            />
          </div>
        </Col> */}
        {/* Side menú */}
        <Col span={24}>
          <Form.Item name='appointments_link' label='Agendar cita'>
            <Input placeholder='https://' />
          </Form.Item>
          <Form.Item name='offers_link' label='Ver opciones'>
            <Input placeholder='https://' />
          </Form.Item>
          <Form.Item name='user_form_link' label='Déjanos tus datos'>
            <Input placeholder='https://' />
          </Form.Item>
          {/* <SelectTagsFormItem
            name='tags'
            filters={{ type: null }}
            label='Etiquetas'
            className='edit-blog__form-item'
          />
          <SelectPlacesWithOffers
            onChange={(values, type) => {
              if (type === 'programs') {
                setCoursesFilters({
                  ...coursesFilters,
                  programs: values
                })
              }
              if (type === 'campus') {
                setCoursesFilters({
                  ...coursesFilters,
                  campus: values
                })
              }
            }}
            initialData={initialData}
            countryProps={{
              mode: 'multiple',
              showSearch: true,
              label: 'País',
              name: 'country_tags'
            }}
            cityProps={{
              mode: 'multiple',
              showSearch: true,
              label: 'Ciudad',
              name: 'city_tags'
            }}
            campusProps={{
              mode: 'multiple',
              showSearch: true,
              label: 'Campus',
              name: 'school_tags'
            }}
            programProps={{
              mode: 'multiple',
              showSearch: true,
              label: 'Programas',
              name: 'program_tags'
            }}
            span={24}
          /> */}
          {/* Cursos */}
          {/* <SelectCoursesFormItem
            filters={coursesFilters}
            name='course_tags'
            mode='multiple'
            showSearch
            label='Cursos'
            className='edit-blog__form-item'
          /> */}
          {/* Artculo destaacado */}
          {/* <FeaturedDates label='Articulo destacado' span={12} /> */}
        </Col>
      </Row>

      <ModalAddCourse
        isModalOpen={isModalCoursesOpen}
        handleCancel={handleCancelCourses}
        handleOk={handleOkCourses}
        setCourse={setCourse}
        idSercices={idSercices}
        isEditBlog
      />

      {/* Author Modal */}
      <Modal visible={modalAuthor} onOk={handleOk} onCancel={handleCancel} okText='Publicar' cancelText='Cancelar' footer='' width='400px'>
        <Form.Item name='username' label='Introduzca su nombre'>
          <Input />
        </Form.Item>
        <ShowMessage />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '10px' }}>
          <Button onClick={() => setModalAuthor(false)} style={{ fontWeight: 'bold' }}>
            Cancel
          </Button>
          <Button
            onClick={VerifyMistake}
            style={{ background: 'var(--blue-100)', color: 'white', fontWeight: 'bold' }}
            loading={false}
          >
            Confirm
          </Button>
        </div>
      </Modal>
      {/* Publish Modal */}
      <Modal title='' visible={isModalOpen} onOk={continueOK} onCancel={handleCancel} okText='Continuar' cancelText='Cancelar'>
        <h5>¿Seguro que quieres publicar?</h5>
        {
          ((!image01Url && !image01) || !(values?.content?.length > 0) || !(values?.tags?.length > 0) || !(values?.country_tags?.length > 0) || !(values?.city_tags?.length > 0) || !(values?.school_tags?.length > 0) || !(values?.program_tags?.length > 0) || !(courses?.length > 0)) && (
            <>
              <p>Hace falta:</p>
              <ul>
                {(!image01Url && !image01) && <li>Portada</li>}
                {!(values?.content?.length > 0) && <li>Contenido</li>}
                {!(values?.tags?.length > 0) && <li>Etiquetas</li>}
                {!(values?.country_tags?.length > 0) && <li>País</li>}
                {!(values?.city_tags?.length > 0) && <li>Ciudad</li>}
                {!(values?.school_tags?.length > 0) && <li>Campus</li>}
                {!(values?.program_tags?.length > 0) && <li>Programas</li>}
                {/* {!(values?.course_tags?.length > 0) && <li>Cursos</li>} */}
                {!(courses?.length > 0) && <li>Cursos</li>}
              </ul>
              <p>avisandote que es lo que te hace falta y si quieres publicar asi sin algunos datos</p>
            </>
          )
        }
      </Modal>
      <Modal title='Compartir' visible={showModalCopy} footer='' onCancel={() => setShowModalCopy(false)}>
        <Row>
          <Col span={24} className='info-quote__campus byp-mb-1'>
            <h4>{name}</h4>
          </Col>
          <Col span={24} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <LinkOutlined style={{ fontSize: '20px' }} />
            <Input
              id='myInput'
              value={`${url}`}
              suffix={
                <Button onClick={handleShare} style={{ color: 'var(--blue-100)', border: 'none', marginRight: '-10px' }}>COPY</Button>
              }
            />
          </Col>
        </Row>
      </Modal>
    </Form>
  )
}

export { FormEditBlog }
