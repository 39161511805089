import React, { useEffect, useState } from 'react'
import { BlockText } from './BlockText'
import { Image } from 'antd'
import { BlockVideo } from '../../blogs/components/BlockVideo'
import { BlockColumn } from './BlockColumn'
import { BlockTextP } from './BlockTextP'

export const BlogContainer = ({ content }) => {
  const [mainContent, setMainContent] = useState()
  useEffect(() => {
    if (content) {
      const mainContent = content?.filter((content) => !content.parent_id)
      const childContent = content?.filter((content) => !!content.parent_id)
      const pageContent = mainContent.map((content) => {
        if (content.type !== 'col') {
          return content
        }
        content.key = content.type + '-' + content.order + '-' + content.id
        const childA = childContent.find((childContent) => childContent.parent_id === content.id && childContent.order === 1)
        const childB = childContent.find((childContent) => childContent.parent_id === content.id && childContent.order === 2)
        if (childA) {
          childA.column = 'a'
          childA.key = content.type + '-' + content.id + '-child-' + 'a'
        }
        if (childB) {
          childB.column = 'b'
          childB.key = content.type + '-' + content.id + '-child-' + 'b'
        }
        return {
          ...content,
          blockA: childA,
          blockB: childB
        }
      })
      setMainContent(pageContent)
      console.log('🚀 ~ file: BlogContainer.js:35 ~ useEffect ~ pageContent:', pageContent)
    }
  }, [content])
  const getBlock = (block) => {
    switch (block.type) {
      case 'title': return <BlockText key={block.key} item={block} />
      case 'text': return <BlockTextP key={block.key} item={block} />
      case 'col': return <BlockColumn key={block.key} item={block} />
      case 'video': return <BlockVideo item={block} />
      case 'image': return <Image src={block.url} style={{ marginBottom: '1rem' }} />
      default: return null
    }
  }
  return (
    <div style={{ minHeight: '70vh' }}>
      {mainContent?.map((item) => {
        return getBlock(item)
      })}
    </div>
  )
}
