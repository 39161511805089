import { AutoComplete, Button, Col, Form, Row } from 'antd'
import SearchIcon from '@material-ui/icons/Search'
import Image from '../../../common/assets/images/bg.png'
import { useGetCountriesWithOffers } from '../../../bussines/hooks/places/useGetCountriesWithOffers'
import debounce from 'lodash/debounce'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { URL_CITY_PAGE, URL_COUNTRY_PAGE, URL_SEARCH_PAGE } from '../../programs/CountryPage'
import { URL_QUOTER_PAGE } from '../../quoter'
import { Link } from 'react-router-dom' 
// import moment from 'moment'
import './../../../common/assets/css/main-theme.less' 

// const dateFormat = 'MM/YYYY'
function PersonalizedSearcher ({ headerBigHome, image = null, position = 'center center', repeat = 'no-repeat', size = 'cover', isLoadingPage = false }) {
  const navigate = useNavigate()
  const [searchFilter, setSearchFilter] = useState()
  const [url, setUrl] = useState()
  const [options, setOptions] = useState([])
  const [dateString] = useState(null)
  const [location, setLocation] = useState(null)
  const [srcImage, setSrcImage] = useState(Image)
  const { data, isLoading } = useGetCountriesWithOffers(searchFilter, { hasPage: true })

  const [form] = Form.useForm()

  const selectFirstOption = (e) => {
    if (e.key === 'Enter') {
      if (options && options?.length > 0) {
        setLocation({
          place: options[0].id,
          place_type: options[0].type
        })
        if (options[0].type === 'country') {
          window.open(URL_COUNTRY_PAGE.replace(':code', options[0].iso))
        } else {
          window.open(URL_CITY_PAGE.replace(':code', options[0].iso).replace(':city', options[0].id))
        }
        form.setFieldsValue({
          country: options[0].value
        })
      } else {
        window.open(URL_SEARCH_PAGE.replace(':search', searchFilter), '_blank')
      }
    }
  }

  useEffect(() => {
    if (image) setSrcImage(image)
  }, [image])

  const handleSearch = () => {
    if (dateString) {
      navigate(URL_QUOTER_PAGE, {
        state: {
          ...location,
          date: dateString,
          searchLocation: searchFilter
        }
      })
    } else if (location) {
      // navigate(url)
      window.open(url, '_blank')
    } else if (searchFilter) {
      window.open(URL_SEARCH_PAGE.replace(':search', searchFilter), '_blank')
    }
  }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      setSearchFilter(value)
    }, 100)
  })

  useEffect(() => {
    if (data && !isLoading) {
      const countries = data.countries?.map((country) => ({
        id: country.id,
        // value: country.page?.title ?? country?.translations?.translation ?? country.name,
        value: country.page?.title ?? country.name,
        iso: country.iso3,
        type: 'country'
      }))
      const cities = data.cities?.map((city) => ({
        id: city.id,
        value: city?.page?.title ?? city.name,
        iso: city.country?.iso3,
        type: 'city'
      }))

      // Combina países y ciudades y asigna claves únicas a cada elemento
      const combinedOptions = [...countries, ...cities].map((option) => ({
        ...option,
        key: `${option.type}_${option.id}`
      }))

      setOptions(combinedOptions)
    }
  }, [isLoading, data])

  // const disabledDate = (current) => {
  //   // Deshabilita todas las fechas anteriores a la fecha actual
  //   return current && current < moment().startOf('day')
  // }

  return (
    <Form
      form={form}
    >
      <Row
        style={{
          alignItems: headerBigHome ? 'flex-end' : 'center',
          paddingBottom: headerBigHome ? '1.688rem' : '',
          justifyContent: 'center',
          backgroundImage: isLoadingPage ? '' : `url(${srcImage})`,
          backgroundColor: isLoadingPage ? '#FFFFFF' : '',
          height: headerBigHome ? '19rem' : '9.6875rem',
          backgroundPosition: `${position}`,
          backgroundRepeat: `${repeat}`,
          backgroundSize: `${size}`,
          borderRadius: '20px',
          margin: 'auto'
        }}
        // responsive del buscador
        className='big__form-header'
      >

        <Col className='big__btn-container'>
          <Link to='/home'>
            <Button className='byp-btn-red-50 big__btn-search big__btn-search-responsive' data-test='Busca tu curso en el extranjero'>
              Busca tu curso al extranjero <SearchIcon style={{ color: 'white' }} />
            </Button>
          </Link>
        </Col>
      </Row>
    </Form>
  )
}

export { PersonalizedSearcher }
