import { Button, Col, Image, List, Row, Spin, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { LikeOutlined } from '@ant-design/icons'
import { useGetPageComments } from '../../../../bussines/hooks/page/useGetPageComments'
import { image } from '../../../../utils/fallbackImage'

export function BlogComments ({ blogSlug, setRequireRefetch, requireRefetch }) {
  const [page, setPage] = useState(1)
  const [comments, setComments] = useState([])
  const [filters, setFilters] = useState({
    limit: 6
  })
  const { isLoading: isSearching, data, error, refetch } = useGetPageComments(blogSlug, filters)

  useEffect(() => {
    if (!isSearching && error) {
      notification.error({
        message: error?.response?.data?.message ?? ''
      })
    } else if (!isSearching && data) {
      setComments(data)
    }
  }, [isSearching, data])

  useEffect(() => {
    if (requireRefetch) {
      refetch()
      setRequireRefetch(false)
    }
  }, [requireRefetch])

  return (
    <Row>
      <Col span={comments?.length ? 24 : 0}>
        <List
          style={{ width: '100%' }}
          itemLayout='vertical'
          pagination={{
            onChange: page => {
              setPage(page)
            },
            total: comments?.length,
            pageSize: 10,
            hideOnSinglePage: true,
            current: page
          }}
          dataSource={comments || []}
          renderItem={(item, key) => (
            <div className='blog-comments' key={item.id}>
              <div className='blog-comments__user byp-mb-1'>
                <div className='blog-comments__user-image'>
                  <Image
                    width='100%'
                    src={item.user?.profile_photo_url}
                    fallback={image}
                  />
                </div>

                <div className='blog-comments__user-info'>
                  <p>{item.user?.name} {item.user?.last_name}</p>
                  <div>
                    Esto me gusta <LikeOutlined /> 4
                  </div>
                </div>
              </div>

              <p>
                {item?.comment}
              </p>
            </div>
          )}
        />
        {isSearching && <Spin />}
        <div className='w-100 byp-centered'>
          <Button
            style={{ borderColor: '#797979', margin: '10px 0' }}
            onClick={() => {
              const limit = filters.limit ?? 6
              setFilters({
                ...filters,
                limit: limit + 6
              })
            }}
            disabled={filters.limit > comments?.length}
          >
            Leer Más
          </Button>
        </div>
      </Col>
    </Row>
  )
}
