import React from 'react'
import { Avatar, Col, Dropdown, Menu, Popover, Row } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { Link, useLocation } from 'react-router-dom'
import { IconHeartFilled } from '../../assets/svg/icons'
import { allRoles, superAdminRoles } from '../../../utils/UsersAndRoles'
import { BYP_PUBLIC_USER } from '../../../config'
import { URL_LOGIN_PAGE } from '../../../pages/login'
import { URL_REGISTER_PAGE_BETA } from '../../../pages/register'
import { URL_FAVORITES } from '../../../pages/favorites/favoritePage'
import { URL_USER_ACCOUNT } from '../../../pages/user/MyAccountPage'
import { IoMdNotifications } from 'react-icons/io'
import { GoDotFill } from 'react-icons/go'
import MainThemeNotifications from './MainThemeNotifications'
import { URL_USER_COURSES } from '../../../pages/user/MyCoursesPage'
import useDeviceSize from '../../../utils/useDeviceSize'

const menu = (user, handleCloseSession, pathname, hasUnreadNotifications = false, width) => {
  const role = user.role_name
  const isPublicUser = user.email === BYP_PUBLIC_USER
  const items = [
    { // user name
      name: 'user',
      key: 'user',
      label: <Link to={URL_USER_ACCOUNT} className='byp-user-menu-item'> Mi cuenta </Link>,
      roles: allRoles
    },
    { // user favorites
      name: 'favorites',
      key: 'favorites',
      label: <Link to={URL_FAVORITES} className='byp-user-menu-item'>Mis Favoritos </Link>,
      roles: allRoles
    },
    { // user courses
      name: 'courses',
      key: 'courses',
      label: (
        <Link to={URL_USER_COURSES} className='byp-user-menu-item'>
          Cursos
          {hasUnreadNotifications && (
            <GoDotFill color='#FF395C' style={{ paddingTop: '.3rem' }} />
          )}
        </Link>
      ),
      roles: allRoles
    },
    { // dashboard
      name: 'dashboard',
      key: 'dashboard',
      label: <Link className='byp-user-menu-item' to='/dashboard'>Panel de control</Link>,
      roles: [
        'agency-admin',
        // 'agency-office-manager',
        // 'agency-manager',
        // 'agency-editor',
        // 'institution-admin', 'institution-manager',
        // ...educationGroupRoles,
        ...superAdminRoles
      ]
    },
    { // Home
      name: 'home',
      key: 'home',
      label: <Link to='/' className='byp-user-menu-item'>Inicio</Link>,
      roles: allRoles
    },
    { // Logout DIVIDER
      name: 'logout_divider',
      key: 'logout_divider',
      label: (
        <hr style={{ margin: 0, borderTop: '1px solid var(--full-black)' }} />
      ),
      roles: allRoles
    },
    { // Logout
      name: 'logout',
      key: 'logout',
      label: (
        <a href='/#' onClick={handleCloseSession}>
          Cerrar Sesión
        </a>
      ),
      roles: allRoles
    }
  ]

  let menuItems = []
  if (isPublicUser) {
    menuItems = [
      { // Log In
        name: 'login',
        key: 'login',
        label: <Link to={URL_LOGIN_PAGE} className='byp-user-menu-item'> Log in </Link>,
        roles: allRoles
      },
      {
        name: 'divider',
        key: 'divider',
        type: 'divider',
        roles: allRoles
      },
      {
        name: 'register',
        key: 'register',
        label: <Link to={URL_REGISTER_PAGE_BETA} className='byp-user-menu-item'> Register </Link>,
        roles: allRoles
      }
    ]
  } else {
    menuItems = items.filter((menuItem) => {
      if (menuItem.name === 'dashboard' && pathname.split('/').includes('dashboard')) {
        return false
      }
      if (menuItem.name === 'home' && (pathname === '/' || pathname === '/quote')) {
        return false
      }
      return menuItem.roles.includes(role)
    })
  }

  // responsive logic   
  // if (width > 960) {
  //   menuItems = menuItems.filter((item) => item.key !== 'favorites');
  // }

  return (
    <Menu
      items={menuItems}
      className='byp-user-menu'
    />
  )
}

const MainThemeMenu = ({ isActive }) => {
  const { user, logout, hasUnreadNotifications, notifications } = useAuth()
  const { pathname } = useLocation()
  const [width, height] = useDeviceSize();

  return (
    // <Row gutter={12} justify='end' align='middle' style={{ padding: '6px 0' }}>
    <Row gutter={12} justify='end' align='middle'>
    <Col>
        <Link
          to={URL_FAVORITES}
          state={{}}
        >
          <span
            className='byp-btn-span align-icon'
            style={{
              color: 'var(--gray-100)',
              fontSize: '0.75rem',
              fontWeight: '600',
              lineHeight: '1.375rem',
              display: 'flex',
              paddin: '6px, 15px',
              gap: '0.25rem'
            }}
          >
            <IconHeartFilled color='var(--gray-100)' /> Mis Favoritos
          </span>
        </Link>
      </Col>

      {/* Notifications */}
      <Col justify='space-around' align='middle'>
        <Popover
          content={<MainThemeNotifications notifications={notifications} />}
          placement='bottomLeft'
          trigger='hover'
          className='popover-notifications'
          openClassName='popover-notifications'
          overlayClassName='popover-notifications'
        >
          <div className='byp-centered'>
            <IoMdNotifications size={25} />
            {hasUnreadNotifications && (
              <GoDotFill color='#FF395C' style={{ position: 'absolute', top: '0', right: '6px' }} />
            )}
          </div>
        </Popover>
      </Col>

      {/* User Menu */}
      <Col justify='space-around' align='middle'>
        <Dropdown overlay={menu(user, logout, pathname, hasUnreadNotifications, width)} placement='bottomRight' arrow>
          <div style={{
            border: '1px solid #D4D4D4',
            borderRadius: '12px',
            display: 'flex',
            padding: '0.375rem 0.5rem',
            alignItems: 'center',
            gap: '0.625rem',
            background: '#F9F9F9'
          }}
          >
            <Avatar
              src={
                user?.profile_photo_url
              }
              className='ab-large-avatar'
            />
            <MenuOutlined style={{ width: '40px', height: 'auto', color: '#373737', fontWeight: 'bold', padding: '10px' }} />
          </div>
        </Dropdown>
      </Col>
    </Row>
  )
}

export { MainThemeMenu }
