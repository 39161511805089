import api from '../../services/api'

class PageRepository {
  async pages (params) {
    const response = await api.get('pages', { params })
    return response.data
  }

  async find (slug) {
    const response = await api.get(`pages/${slug}/show`)
    return response.data
  }

  async relatedBlogs (slug) {
    const response = await api.get(`pages/${slug}/related`)
    return response.data
  }

  async comments (slug, params) {
    const response = await api.get(`pages/${slug}/comments`, { params })
    return response.data
  }

  async saveComment ({ slug, data }) {
    const response = await api.post(`pages/${slug}/comment`, data)
    return response.data
  }

  async searchPage (params) {
    const response = await api.get('pages/search', { params })
    return response.data
  }

  async getServices (params) {
    const response = await api.get('pages/services', { params })
    return response.data
  }

  async create (data) {
    const response = await api.post('pages/new', data)
    return response.data
  }

  async saveBlog (data) {
    const response = await api.post('pages/blog', data)
    return response.data
  }

  async publishedPage (slug) {
    const response = await api.put(`pages/${slug}/published`)
    return response.data
  }

  async getServicesById (idSercices) {
    const response = await api.get('pages/services/slider', {
      params: {
        services: idSercices
      }
    })
    return response.data
  }

  async getUsersByPage () {
    const response = await api.get('pages/users')
    return response.data
  }

  async changeStatus ({ page, data }) {
    const response = await api.post(`/pages/${page}/status`, data)
    return response.data
  }

  async deletePage (slug) {
    const response = await api.delete(`/pages/${slug}/delete`)
    return response.data
  }

  async getRandomCourses (params) {
    const response = await api.get('school/courses/random', { params })
    return response.data
  }

  async getRandomBlogs (params) {
    const response = await api.get('get/blogs', { params })
    return response.data
  }

  async getCitiesBlog (params) {
    const response = await api.get('pages/get-countries', { params })
    return response.data
  }

  async getInstitutions (params) {
    const response = await api.get('pages/get-institutions', { params })
    return response.data
  }
}

export default new PageRepository()
