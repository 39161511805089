import { TitleSection } from '../../../../common/components/TitleSection'
import React from 'react'
import { Button, Typography } from 'antd'
import { MailFilled } from '@ant-design/icons'
const { Text } = Typography

function closeWindow () {
  window.open('/', '_self', '')
  window.close()
}
function TextConfirmationRegister () {
  return (
    <div style={{ margin: '0 15%' }} className='vertical-center'>
      <TitleSection label='¡Revisa tu correo!' icon={<MailFilled />} />
      <Text> Te enviaremos un correo electrónico de verificación en un minuto.</Text>
      <div style={{ textAlign: 'right' }}>
        <Button
          size='large'
          type='primary'
          onClick={closeWindow}
        >
          Aceptar
        </Button>
      </div>
    </div>
  )
}

export { TextConfirmationRegister }
