import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Helmet } from 'react-helmet'
import { URL_WEBSITE } from './config'
import { logoEdit } from './utils/fallbackImage'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_ID}>
    <React.StrictMode>
      <Helmet>
        <meta name='title' content='Book your program' />
        <meta name='description' content='The platform that unites the education industry, through a friendly, easy to use, intuitive and integrable platform with the main CRM and websites in the market.' />

        <meta property='og:type' content='website' />
        <meta property='og:url' content={URL_WEBSITE} />
        <meta property='og:title' content='Book your program' />
        <meta property='og:description' content='The platform that unites the education industry, through a friendly, easy to use, intuitive and integrable platform with the main CRM and websites in the market.' />
        <meta property='og:image' content={logoEdit} />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content={URL_WEBSITE} />
        <meta property='twitter:title' content='Book your program' />
        <meta property='twitter:description' content='The platform that unites the education industry, through a friendly, easy to use, intuitive and integrable platform with the main CRM and websites in the market.' />
        <meta property='twitter:image' content={logoEdit} />
      </Helmet>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
