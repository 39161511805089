import api from '../../services/api'

class InputsRepository {
  async getSports (filters) {
    const response = await api.get('/get/sports', {
      params: {
        ...filters
      }
    })
    return response.data
  }

  async getExtras (filters) {
    const response = await api.get('/get/extras', {
      params: {
        ...filters
      }
    })
    return response.data
  }
}

export default new InputsRepository()
