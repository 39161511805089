import axios from 'axios'

const API_BASE_URL = 'https://blog.bookyourprogram.com/wp-json/wp/v2'

export const getBlogs = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/posts`)
    return response.data
  } catch (error) {
    console.error('Error fetching blogs:', error)
  }
}
