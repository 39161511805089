export const languagesEs = {
  Abkhaz: 'Abjasio',
  Afar: 'Afar',
  Afrikaans: 'Afrikáans',
  Akan: 'Akan',
  Albanian: 'Albanés',
  Amharic: 'Amárico',
  Arabic: 'Árabe',
  Aragonese: 'Aragonés',
  Armenian: 'Armenio',
  Assamese: 'Asamés',
  Avaric: 'Avar',
  Avestan: 'Avéstico',
  Aymara: 'Aimara',
  Azerbaijani: 'Azerí',
  Bambara: 'Bambara',
  Bashkir: 'Baskir',
  Basque: 'Vasco',
  Belarusian: 'Bielorruso',
  Bengali: 'Bengalí',
  Bihari: 'Bihari',
  Bislama: 'Bislama',
  Bosnian: 'Bosnio',
  Breton: 'Bretón',
  Bulgarian: 'Búlgaro',
  Burmese: 'Birmano',
  'Catalan; Valencian': 'Catalán; Valenciano',
  Chamorro: 'Chamorro',
  Chechen: 'Checheno',
  'Chichewa; Chewa; Nyanja': 'Chichewa; Chewa; Nyanja',
  Chinese: 'Chino',
  Chuvash: 'Chuvasio',
  Cornish: 'Córnico',
  Corsican: 'Corso',
  Cree: 'Cree',
  Croatian: 'Croata',
  Czech: 'Checo',
  Danish: 'Danés',
  'Divehi; Dhivehi; Maldivian;': 'Divehi; Dhivehi; Maldivo;',
  Dutch: 'Neerlandés',
  English: 'Inglés',
  Esperanto: 'Esperanto',
  Estonian: 'Estonio',
  Ewe: 'Ewe',
  Faroese: 'Feroés',
  Fijian: 'Fiyiano',
  Finnish: 'Finés',
  French: 'Francés',
  'Fula; Fulah; Pulaar; Pular': 'Fula; Fulah; Pulaar; Pular',
  Galician: 'Gallego',
  Georgian: 'Georgiano',
  German: 'Alemán',
  'Greek, Modern': 'Griego, Moderno',
  Guaraní: 'Guaraní',
  Gujarati: 'Gujarati',
  'Haitian; Haitian Creole': 'Haitiano; Criollo haitiano',
  Hausa: 'Hausa',
  'Hebrew (modern)': 'Hebreo (moderno)',
  Herero: 'Herero',
  Hindi: 'Hindi',
  'Hiri Motu': 'Hiri Motu',
  Hungarian: 'Húngaro',
  Interlingua: 'Interlingua',
  Indonesian: 'Indonesio',
  Interlingue: 'Interlingue',
  Irish: 'Irlandés',
  Igbo: 'Igbo',
  Inupiaq: 'Inupiaq',
  Ido: 'Ido',
  Icelandic: 'Islandés',
  Italian: 'Italiano',
  Inuktitut: 'Inuktitut',
  Japanese: 'Japonés',
  Javanese: 'Javanés',
  'Kalaallisut, Greenlandic': 'Kalaallisut, Groenlandés',
  Kannada: 'Kannada',
  Kanuri: 'Kanuri',
  Kashmiri: 'Cachemir',
  Kazakh: 'Kazajo',
  Khmer: 'Jemer',
  'Kikuyu, Gikuyu': 'Kikuyu, Gikuyu',
  Kinyarwanda: 'Kinyarwanda',
  'Kirghiz, Kyrgyz': 'Kirguís',
  Komi: 'Komi',
  Kongo: 'Kongo',
  Korean: 'Coreano',
  Kurdish: 'Kurdo',
  'Kwanyama, Kuanyama': 'Kwanyama, Kuanyama',
  Latin: 'Latín',
  'Luxembourgish, Letzeburgesch': 'Luxemburgués, Letzeburgesch',
  Luganda: 'Luganda',
  'Limburgish, Limburgan, Limburger': 'Limburgués, Limburgan, Limburger',
  Lingala: 'Lingala',
  Lao: 'Lao',
  Lithuanian: 'Lituano',
  'Luba-Katanga': 'Luba-Katanga',
  Latvian: 'Letón',
  Manx: 'Manés',
  Macedonian: 'Macedonio',
  Malagasy: 'Malgache',
  Malay: 'Malayo',
  Malayalam: 'Malayalam',
  Maltese: 'Maltés',
  Māori: 'Maorí',
  'Marathi (Marāṭhī)': 'Maratí (Marathi)',
  Marshallese: 'Marshallese',
  Mongolian: 'Mongol',
  Nauru: 'Nauruano',
  'Navajo, Navaho': 'Navajo, Navaho',
  'Norwegian Bokmål': 'Noruego Bokmål',
  'North Ndebele': 'Ndebele del Norte',
  Nepali: 'Nepalí',
  Ndonga: 'Ndonga',
  'Norwegian Nynorsk': 'Noruego Nynorsk',
  Norwegian: 'Noruego',
  Nuosu: 'Nuosu',
  'South Ndebele': 'Ndebele del Sur',
  Occitan: 'Occitano',
  'Ojibwe, Ojibwa': 'Ojibwe, Ojibwa',
  'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic': 'Antiguo Eslavo Eclesiástico, Eslavo Eclesiástico, Eslavo Eclesiástico, Antiguo Búlgaro, Antiguo Eslavo',
  Oromo: 'Oromo',
  Oriya: 'Oriya',
  'Ossetian, Ossetic': 'Osético',
  'Panjabi, Punjabi': 'Panyabí',
  Pāḷi: 'Pāḷi',
  Persian: 'Persa',
  Polish: 'Polaco',
  'Pashto, Pushto': 'Pastún, Pushto',
  Portuguese: 'Portugués',
  Quechua: 'Quechua',
  Romansh: 'Romanche',
  Kirundi: 'Kirundi',
  'Romanian, Moldavian, Moldovan': 'Rumano, Moldavo, Moldovan',
  Russian: 'Ruso',
  'Sanskrit (Saṁskṛta)': 'Sánscrito (Saṁskṛta)',
  Sardinian: 'Sardo',
  Sindhi: 'Sindhi',
  'Northern Sami': 'Sami del Norte',
  Samoan: 'Samoano',
  Sango: 'Sango',
  Serbian: 'Serbio',
  'Scottish Gaelic; Gaelic': 'Gaélico Escocés; Gaélico',
  Shona: 'Shona',
  'Sinhala, Sinhalese': 'Singalés, Cingalés',
  Slovak: 'Eslovaco',
  Slovene: 'Esloveno',
  Somali: 'Somalí',
  'Southern Sotho': 'Sotho Meridional',
  'Spanish; Castilian': 'Español; Castellano',
  Sundanese: 'Sundanés',
  Swahili: 'Suajili',
  Swati: 'Suazi',
  Swedish: 'Sueco',
  Tamil: 'Tamil',
  Telugu: 'Telugu',
  Tajik: 'Tayiko',
  Thai: 'Tailandés',
  Tigrinya: 'Tigriña',
  'Tibetan Standard, Tibetan, Central': 'Tibetano Estándar, Tibetano, Central',
  Turkmen: 'Turcomano',
  Tagalog: 'Tagalo',
  Tswana: 'Tswana',
  'Tonga (Tonga Islands)': 'Tonga (Islas Tonga)',
  Turkish: 'Turco',
  Tsonga: 'Tsonga',
  Tatar: 'Tártaro',
  Twi: 'Twi',
  Tahitian: 'Tahitiano',
  'Uighur, Uyghur': 'Uigur, Uyghur',
  Ukrainian: 'Ucraniano',
  Urdu: 'Urdu',
  Uzbek: 'Uzbeko',
  Venda: 'Venda',
  Vietnamese: 'Vietnamita',
  Volapük: 'Volapük',
  Walloon: 'Valón',
  Welsh: 'Galés',
  Wolof: 'Wolof',
  'Western Frisian': 'Frisón Occidental',
  Xhosa: 'Xhosa',
  Yiddish: 'Yidis',
  Yoruba: 'Yoruba',
  'Zhuang, Chuang': 'Zhuang, Chuang'
}
