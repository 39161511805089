import { Button, Col, List, Rate, Row, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { CardOpinions } from '../../quote-course/components/CardOpinions'
//paquetes
const rateTypeLabel = (type) => {
  switch (type) {
    /*case 'accommodation':
      return 'Hospedaje'*/
    case 'activities':
      return 'Actividades y excursiones'
    case 'campus':
      return 'Ubicación de la escuela'
    case 'city':
      return 'País y ciudad'
    case 'support':
      return 'Apoyo por parte de la escuela'
    default:
      return ''
  }
}
function CourseOpinions({ opinions, rates, filters, setFilters, isLoading }) {
  const [generalRate, setGeneralRate] = useState()
  const [featureRates, setFeatureRates] = useState([])
  const [opinionItems, setOpinionItems] = useState([])
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (rates && !isLoading) {
      const features = rates?.filter((rate) => rate.type !== 'general')
      console.log('🚀 ~ file: CourseOpinions.js:28 ~ useEffect ~ features:', features)
      const general = rates?.find((rate) => rate.type === 'general')
      setGeneralRate(general)
      setFeatureRates(features)
    }
  }, [rates])

  useEffect(() => {
    if (!isLoading && opinions) setOpinionItems(opinions)
  }, [isLoading])

  return (
    <div id='offer-opinions'>
      <Row gutter={50}>
        <Col xs={24} md={8} style={{ display: 'flex', flexDirection: 'column', rowGap: '15px' }}>
          <Row style={{ backgroundColor: 'white', borderRadius: '12px', padding: '0 12px' }}>
          <Col xs={8} md={6} style={{ fontSize: '60px', lineHeight: '90px', fontWeight: '700', color: 'black' }}>
              {generalRate?.rate?.toFixed(1)}
            </Col>
            <Col xs={16} md={18} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Col>
                <Rate allowHalf value={generalRate?.rate} disabled className='byp-gray-rate' />
              </Col>
              <Col style={{ fontSize: '10px', lineHeight: '15px' }}>
                {generalRate?.total_opinions} opinions
              </Col>
            </Col>
          </Row>
          <Col style={{ backgroundColor: 'white', borderRadius: '12px', padding: '12px', display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
            <Row style={{ fontSize: '16px', fontWeight: '600' }}>
              Calificación de características
            </Row>
            {featureRates && featureRates?.map((rate) => (
              <Row key={rate.type} style={{ justifyContent: 'space-between' }}>
                <Col>
                  {rateTypeLabel(rate.type)}
                </Col>
                <Col>
                  <Rate allowHalf value={rate.rate} disabled className='byp-gray-rate' />
                </Col>
              </Row>
            ))}
          </Col>
        </Col>
        <Col xs={24} md={16} style={{ display: 'flex', rowGap: '15px', flexDirection: 'column', alignItems: 'center' }}>
          <List
            style={{ width: '100%' }}
            itemLayout='vertical'
            pagination={{
              onChange: page => {
                console.log('PAAGEE', page)
                setPage(page)
              },
              total: opinionItems?.length,
              pageSize: 10,
              hideOnSinglePage: true,
              current: page
            }}
            dataSource={opinionItems || []}
            renderItem={(item, key) => <CardOpinions key={key} item={item} />}
          />
          {isLoading && <Spin />}
          <Button
            style={{ borderColor: '#797979', margin: '10px 0' }}
            onClick={() => {
              const limit = filters.limit ?? 6
              setFilters({
                ...filters,
                limit: limit + 6
              })
            }}
          >
            Leer Más
          </Button>

        </Col>
      </Row>
    </div>
  )
}

export { CourseOpinions }
