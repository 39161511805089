import React, { useEffect } from 'react'
import { Col, Collapse, Row, Form } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { LodgingSection } from './LodgingSection'
import { MedicalSection } from './MedicalSection'
import { ServiceSection } from './ServiceSection'
import { OtherSection } from './OtherSection'
import { AgencyCostSections } from './AgencyCostSections'
import { FieldPayTuition } from './FieldPayTuition'
import { FieldSchoolMaterials } from './FieldSchoolMaterials'
import { SelectStartAndDuration } from './SelectStartAndDuration'
import { FieldCourse } from './FieldCourse'
import { TransportSection } from './TransportSection'
import moment from 'moment'
import { CampusPricesSection } from './CampusPricesSection'
import { FieldCourseGoods } from './FieldCourseGoods'
import DatesLodging from './DatesLodging'
import { SelectLodgingStartAndDuration } from './SelectLodgingStartAndDuration'

const { Panel } = Collapse

function QuoterForm () {
  const {
    service,
    isLoadingBaseQuote,
    baseQuote,
    form,
    setFormConfig,
    formConfig,
    isSuccessPost,
    isLoadingPost,
    prices
  } = useQuoter()

  const agencyServices = Form.useWatch('services_agency', form)

  useEffect(() => {
    if (!isLoadingBaseQuote && baseQuote?.id) {
      const lodgingWeeks = baseQuote.lodging_properties?.weeks
      form.setFieldsValue({
        weeks: baseQuote.weeks,
        startEvent: moment(baseQuote.arrival_date),
        student_nationality: baseQuote.student_nationality,
        student_location: baseQuote.student_location,
        expiration: moment(baseQuote.expiration) ?? null,
        materials: baseQuote.materials,
        material: baseQuote.material,
        lodging: baseQuote.lodging,
        radioLodging: baseQuote.lodging ? 'si' : 'no',
        lodgingStart: moment(baseQuote.lodging_start) ?? null,
        lodgingWeeks,
        lodgingExtraStart: moment(baseQuote.lodging_extra_start),
        lodgingExtraNights: baseQuote.lodging_properties?.extra_nights_duration,
        extra_nigth: baseQuote.extra_nigth,
        transport: baseQuote.transport,
        transport_way: baseQuote.transport_ways,
        health_insurance: baseQuote.insurance,
        medical: baseQuote.agency_insurance.length > 0 ? baseQuote.agency_insurance[0].id : null,
        services_agency: baseQuote.additional_services.map((item) => ({ service_agency: item.id })),

        client_name: baseQuote.client_name,
        message: baseQuote.message
      })

      const agencyServices = []
      if (service.isAgency()) {
        baseQuote.agency_services?.map((item) => (
          agencyServices.push({ id: item.id, cost: service.getAgencyServiceCost(item.id) })
        ))
      }

      const includedFees = []
      const offerFees = service.getFees() ?? []
      // console.log('🚀 ~ file: QuoterForm.js:68 ~ useEffect ~ offerFees:', offerFees)
      // console.log('🚀 ~ file: QuoterForm.js:70 ~ useEffect ~ baseQuote.fees:', baseQuote.fees)
      offerFees?.map(({ id, cost, name }) => {
        if (name === 'Registration Fee') return false
        return baseQuote.fees.includes(id) ? includedFees.push({ id, cost }) : null
      })
      // console.log('🚀 ~ file: QuoterForm.js:72 ~ useEffect ~ includedFees:', includedFees)
      const minorCosts = baseQuote.minor_costs ?? []
      const appliedGoods = baseQuote.goods ?? []
      const transportMinorCosts = baseQuote.transport_minor_costs?.map((transportMinorCost) => {
        const values = {
          id: transportMinorCost.id,
          cost: transportMinorCost.cost,
          duration: transportMinorCost.duration
        }
        if (baseQuote.transport_ways === 'Round Way') {
          values.cost = values.cost / 2
        }
        return values
      })
      setFormConfig({
        ...formConfig,
        materials: baseQuote.materials,
        material: baseQuote.material,
        courseStart: moment(baseQuote.arrival_date),
        weeks: baseQuote.weeks,
        lodging: baseQuote.include_lodging,
        lodgingId: baseQuote.lodging,
        lodgingStart: moment(baseQuote.lodging_start) ?? null,
        lodgingWeeks,
        lodgingExtraStart: moment(baseQuote.lodging_extra_start),
        lodgingExtraNights: baseQuote.lodging_properties?.extra_nights_duration,
        extra_nigth: baseQuote.extra_nigth,
        lodgingExtras: baseQuote.lodging_extras,
        transport: baseQuote.include_transport,
        transportId: baseQuote.transport,
        transportWaySelected: baseQuote.transport_ways,
        transportMinorCosts,
        fees: includedFees,
        insurance: baseQuote.include_insurance,
        healthInsuranceId: baseQuote.insurance,
        agencyServices,
        serviceAgency: baseQuote.additional_services?.length > 0,
        medical: baseQuote.agency_insurance?.length > 0,
        minorCosts,
        appliedGoods
      })
    }
  }, [isLoadingBaseQuote, baseQuote])

  useEffect(() => {
    if (!isLoadingPost && isSuccessPost) {
      // notification.info({
      //   message: 'Quote saved',
      //   description: '',
      //   placement: 'top'
      // })
    }
  }, [isSuccessPost, isLoadingPost])

  const handleFormat = (date) => {
    if (date) {
      // Restar 3 días hábiles
      let daysToSubstract = 3
      const fechaMoment = moment(date)
      const dayOfWeek = fechaMoment.day()
      if (dayOfWeek > 5) {
        daysToSubstract = dayOfWeek - 2
      } else if (dayOfWeek <= 3) {
        daysToSubstract = 5
      }
      fechaMoment.subtract(daysToSubstract, 'days')
      return fechaMoment.format('DD/MM/YYYY')
    }
    return ''
  }

  return (
    <>
      <Row align='middle' justify='space-between'>
        <hr className='byp-hr__quote byp-mb-1 byp-mt-1' />
      </Row>

      <Row align='bottom' justify='space-between'>
        <Col xs={24} md={12}>
          <SelectStartAndDuration />
        </Col>
        {service.hasLodging() && (
          <Col xs={24} md={12}>
            <DatesLodging />
            {(service.getCourseProgram()?.name !== 'academic_years') && (
              <SelectLodgingStartAndDuration program={service.getCourseProgram()?.name} />
            )}
          </Col>
        )}
        <hr className='byp-hr__quote byp-mb-1 byp-mt-1' />
      </Row>

      <Collapse bordered={false} defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8']} expandIconPosition='left'>
        <Panel
          header='Costo total'
          key='1'
          className='quote-byp__quote-panel'
          extra={
            <>
              <p className='byp-m-0' style={{ fontWeight: '700', marginRight: '0.5rem' }}>Promoción valida inscribiéndote antes del: {handleFormat(prices?.year?.prices_end)}</p>
            </>
          }
        >
          <Row gutter={12} className='w-100'>
            <Col xs={12} md={14} lg={18}><p className='byp-mt-1'>Concepto</p></Col>
            <Col xs={12} md={6} lg={4} style={{ textAlign: 'end' }}><p className='byp-mt-1'>Total {service.getCurrency()}</p></Col>
            <Col xs={0} md={4} lg={2} className='byp-centered'><p className='byp-m-0' style={{ display: 'none' }}>-</p></Col>
            <hr className='byp-hr' />
          </Row>

          <FieldPayTuition form={form} />
          <FieldCourse />
          {service.getSchoolMaterial() && <FieldSchoolMaterials />}
          <CampusPricesSection />
          <FieldCourseGoods />
        </Panel>
        {(service.hasLodging() && !service.isOnline()) && service.includeLodging() && (
          <Panel
            header='Hospedaje'
            key='2'
            className='quote-byp__quote-panel'
            extra={
              <>
                <p className='byp-m-0' style={{ fontWeight: '700', marginRight: '0.5rem' }}>Promoción valida inscribiéndote antes del: {handleFormat(prices?.year?.prices_end)}</p>
              </>
            }
          >
            <Row gutter={12} className='w-100'>
              <Col xs={12} md={14} lg={18}><p className='byp-mt-1'>Concepto</p></Col>
              <Col xs={12} md={6} lg={4} style={{ textAlign: 'end' }}><p className='byp-mt-1'>Total {service.getCurrency()}</p></Col>
              <Col xs={0} md={4} lg={2} className='byp-centered'><p className='byp-m-0' style={{ display: 'none' }}>-</p></Col>
              <hr className='byp-hr' />
            </Row>

            <LodgingSection />
          </Panel>
        )}
        {(service.hasTransports() && !service.isOnline()) && (
          <Panel
            header='Transporte'
            key='3'
            className='quote-byp__quote-panel'
            extra={
              <>
                <p className='byp-m-0' style={{ fontWeight: '700', marginRight: '0.5rem' }}>Promoción valida inscribiéndote antes del: {handleFormat(prices?.year?.prices_end)}</p>
              </>
            }
          >
            <Row gutter={12} className='w-100'>
              <Col xs={12} md={14} lg={18}><p className='byp-mt-1'>Concepto</p></Col>
              <Col xs={12} md={6} lg={4} style={{ textAlign: 'end' }}><p className='byp-mt-1'>Total {service.getCurrency()}</p></Col>
              <Col xs={0} md={4} lg={2} className='byp-centered'><p className='byp-m-0' style={{ display: 'none' }}>-</p></Col>
              <hr className='byp-hr' />
            </Row>

            <TransportSection />
          </Panel>
        )}
        <Panel
          header='Adicionales'
          key='4'
          className='quote-byp__quote-panel'
          extra={
            <>
              <p className='byp-m-0' style={{ fontWeight: '700', marginRight: '0.5rem' }}>Promoción valida inscribiéndote antes del: {handleFormat(prices?.year?.prices_end)}</p>
            </>
          }
        >
          <Row gutter={12} className='w-100'>
            <Col xs={12} md={14} lg={18}><p className='byp-mt-1'>Concepto</p></Col>
            <Col xs={12} md={6} lg={4} style={{ textAlign: 'end' }}><p className='byp-mt-1'>Total {service.getCurrency()}</p></Col>
            <Col xs={0} md={4} lg={2} className='byp-centered'><p className='byp-m-0' style={{ display: 'none' }}>-</p></Col>
            <hr className='byp-hr' />
          </Row>

          {(service.getArrayHealtInsurance()?.length > 0 || service.getPrices()?.extras?.length > 0) && <OtherSection />}
          {service.isAgency() && <AgencyCostSections />}
          {(service.getServiceAgency()?.length > 0 && service.isAgency()) && <ServiceSection agencyServices={agencyServices} />}
          {(service.getMedicalInsurance()?.length > 0 && service.isAgency()) && <MedicalSection />}
        </Panel>
      </Collapse>
    </>
  )
}

export { QuoterForm }
