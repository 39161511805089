import { Autocomplete, GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api'
import Geocode from 'react-geocode'
import { Input } from 'antd'
import { useEffect, useState } from 'react'
import mapStyles from '../../utils/mapStyles'
import blackMarkerIcon from '../assets/images/pin.png'

const libraries = ['places']

function Map({
  setCoords, addressChange, address = '', coords = {
    lat: 37.772,
    lng: -122.21
  }, draggable = true, showInput = true,
  containerStyle = {
    width: '100%',
    height: '135px',
    borderRadius: '0.5rem'
  }
}) {
  const [autocomplete, setAutocomplete] = useState()
  const [inputState, setInputState] = useState(address)
  const onChangeInput = (e) => {
    setInputState(e.target.value)
  }

  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API)
    Geocode.setLanguage('en')
  }, [])

  const [position, setPosition] = useState({
    lat: coords.lat ?? 37.772,
    lng: coords.lng ?? -122.21
  })

  useEffect(() => {
    if (coords) {
      setPosition({
        lat: coords.lat ?? 37.772,
        lng: coords.lng ?? -122.21
      })
    }
  }, [coords.lat])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries
  })

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete)
  }

  // Get country and city
  const getAddresCompontensFormated = (addrComp) => {
    const addressComponents = {
      city: null,
      country: null
    }
    if (addrComp !== undefined) {
      for (let i = 0; i < addrComp.length; ++i) {
        const typ = addrComp[i].types
        if (typ.includes('locality')) {
          addressComponents.city = addrComp[i].long_name
        } else if (typ.includes('country')) {
          addressComponents.country = addrComp[i].long_name
          addressComponents.country_code = addrComp[i].short_name
        }
        if (addressComponents.city != null && addressComponents.country != null) break
      }
    }
    return addressComponents
  }

  const onCoordsOrPlaceholderChange = (place, coords) => {
    // console.log('🚀 ~ file: Map.js ~ line 62 ~ onCoordsOrPlaceholderChange ~ place', place)
    const formatedDirection = place.formatted_address
    const addressComponents = place.address_components
    const addressComponentsFormated = getAddresCompontensFormated(addressComponents)
    addressChange(formatedDirection, coords, addressComponentsFormated)
    setInputState(formatedDirection)
  }

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace()
      const coordsFormated = {
        lat: place.geometry?.location.lat(),
        lng: place.geometry?.location.lng()
      }
      setPosition(coordsFormated)
      setCoords(coordsFormated)
      onCoordsOrPlaceholderChange(place, coordsFormated)
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  const onDragEnd = data => {
    const coordsFormated = {
      lat: data.latLng?.lat(),
      lng: data.latLng?.lng()
    }
    setPosition(coordsFormated)
    setCoords(coordsFormated)
    Geocode.fromLatLng(coordsFormated.lat, coordsFormated.lng).then(
      (response) => {
        const place = response.results[0]
        onCoordsOrPlaceholderChange(place, coordsFormated)
      },
      (error) => {
        console.error(error)
      }
    )
  }
  if (!isLoaded) return <div>Loading...</div>
  return (
    <GoogleMap
      id='serach-place'
      mapContainerStyle={containerStyle}
      center={position}
      zoom={12}
      options={{
        styles: mapStyles
      }}
    >
      <MarkerF
        id='1'
        key='1'
        position={position}
        draggable={draggable}
        onDragEnd={onDragEnd}
        icon={{
          url: blackMarkerIcon,
          scaledSize: new window.google.maps.Size(30, 30),
        }}
      />
      {showInput && (
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          className='content-quoter p-05'
        >
          <Input
            size='large'
            value={inputState}
            onChange={onChangeInput}
            disabled={!autocomplete}
          />
        </Autocomplete>
      )}
    </GoogleMap>
  )
}

export { Map }
