import { useQuery } from 'react-query'
import OfferRespository from '../../repositories/OfferRespository'

function useGetOfferExtras (offer, filters = {}) {
  return useQuery(['useGetOfferExtras', offer, filters],
    () => OfferRespository.extras(offer, filters), {
      retry: 2,
      enabled: !!offer
    })
}

export { useGetOfferExtras }
