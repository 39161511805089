import React from 'react'
import { Card } from 'antd'
import { Map } from '../../../common/components/Map'

export const QuoteLocation = ({ campus, t }) => {
  return (
    <Card bordered={false} style={{ marginBottom: '1rem' }}>
      <h4 style={{ fontSize: '20px', fontWeight:'500' }}>Aquí estarás</h4>
      <p style={{ fontSize: '1rem' }}>{campus.name} {campus.address}</p>

      <div>
        <Map address={campus.address} coords={{ lat: campus.coords?.lat, lng: campus.coords?.lng }} addressChange={console.log} draggable={false} showInput={false} />
      </div>

    </Card>
  )
}
