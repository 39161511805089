import React, { useEffect, useState } from 'react'
import { notification, List } from 'antd'
import { useGetPaidQuotes } from '../../../bussines/hooks/quote/useGetPaidQuotes'
import { CardPaidQuote } from './CardPaidQuote'

const MyTravelsList = () => {
  const [filters, setFilters] = useState({ page: 1, perPage: 5 })
  const { data: quotes, isLoading, error } = useGetPaidQuotes(filters)
  useEffect(() => {
    if (!isLoading && quotes) {
      console.log('🚀 ~ file: MyTravelsList.js:7 ~ useEffect ~ quotes:', quotes)
    }
    if (!isLoading && error) {
      notification.error({
        message: error?.response?.data?.message ?? 'Something went wrong'
      })
    }
  }, [])
  return (
    <List
      dataSource={quotes?.data ?? []}
      loading={isLoading}
      renderItem={(item, index) => (
        <List.Item>
          <CardPaidQuote item={item} key={item.id} />
        </List.Item>
      )}
      style={{
        width: '100%'
      }}
      pagination={{
        onChange: page => {
          setFilters({
            ...filters,
            page
          })
        },
        total: quotes?.meta?.total,
        pageSize: quotes?.meta?.per_page ?? 10,
        hideOnSinglePage: true,
        current: quotes?.meta?.current_page
      }}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 4
      }}
    />
  )
}

export { MyTravelsList }
