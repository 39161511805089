import { Col, Image } from "antd";
import ExampleImage from "../../common/assets/images/figma/Rectangle 37.png";

function FavoriteCollectionCard({
  collection,
  onClick,
  imagePreview = true,
  isActive = false,
  ...props
}) {
  return (
    <div
      onClick={onClick}
      {...props}
      className={`byp-favorite-card ${isActive && "active"}`}
    >
      <span className="byp-favorite-card__save-qty">
        Guardado: {collection.items_count}
      </span>
      <Image
        src={collection.image ?? ExampleImage}
        fallback={ExampleImage}
        className="byp-favorite-card__image"
        preview={imagePreview}
      />
      <h5 className="byp-favorite-card__title">{collection?.name}</h5>
      <span className="byp-favorite-card__save-qty--mobile">
        Guardado: {collection.items_count}
      </span>
    </div>
  );
}

export { FavoriteCollectionCard };
