import React, { useEffect } from 'react'
import { Col, Collapse, notification, Row, Form } from 'antd'
import { MedicalSection } from './MedicalSection'
import { ServiceSection } from './ServiceSection'
import { OtherSection } from './OtherSection'
// import { ClientInfoSection } from './ClientInfo'
import { AgencyCostSections } from './AgencyCostSections'
import { SelectStartAndDuration } from './SelectStartAndDuration'
import moment from 'moment'
import { usePackage } from '../../../providers/package/package-course-context'
import { PackagePrice } from './PackagePrice'
import { PackageOpenPrice } from './PackageOpenPrice'
import { Additionals } from './Additionals'
import IncludesSection from './IncludesSection'
import { FieldCourseGoods } from './FieldCourseGoods'
import { useGetCurrencyToCurrency } from '../../../bussines/hooks/currencies/useGetCurrencyToCurrency'

const { Panel } = Collapse

function PackageQuoterForm({ isOpen, handleFormatDate }) {
  const {
    service, isLoadingBaseQuote, baseQuote, form, setFormConfig, formConfig,
    isSuccessPost, isLoadingPost, changeCurrency, userLocalCurrency, prices, course
  } = usePackage()

  const agencyServices = Form.useWatch('services_agency', form)

  useEffect(() => {
    if (!isLoadingBaseQuote && baseQuote?.id) {
      form.setFieldsValue({
        weeks: baseQuote.weeks,
        startEvent: moment(baseQuote.arrival_date),
        student_nationality: baseQuote.student_nationality,
        student_location: baseQuote.student_location,
        expiration: moment(baseQuote.expiration) ?? null,
        materials: baseQuote.materials,
        material: baseQuote.material,
        radioLodging: baseQuote.lodging ? 'si' : 'no',
        lodging: baseQuote.lodging,
        lodgingStart: moment(baseQuote.lodging_start) ?? null,
        lodgingWeeks: baseQuote.lodging_properties?.hs_weeks,
        lodgingExtraStart: moment(baseQuote.lodging_extra_start),
        lodgingExtraNights: baseQuote.lodging_properties?.extra_nights_duration,
        extra_nigth: baseQuote.extra_nigth,
        transport: baseQuote.transport,
        transport_way: baseQuote.transport_ways,
        health_insurance: baseQuote.insurance,
        medical: baseQuote.agency_insurance.length > 0 ? baseQuote.agency_insurance[0].id : null,
        services_agency: baseQuote.additional_services.map((item) => ({ service_agency: item.id })),

        client_name: baseQuote.client_name,
        message: baseQuote.message
      })

      const agencyServices = []
      if (service.isAgency()) {
        baseQuote.agency_services?.map((item) => (
          agencyServices.push({ id: item.id, cost: service.getAgencyServiceCost(item.id) })
        ))
      }

      const includedFees = []
      service.getFees()?.map(({ id, cost }) => (
        baseQuote.fees.includes(id) ? includedFees.push({ id, cost }) : null
      ))

      const minorCosts = baseQuote.minor_costs ?? []
      const appliedGoods = baseQuote.goods ?? []

      setFormConfig({
        ...formConfig,
        materials: baseQuote.materials,
        material: baseQuote.material,
        courseStart: moment(baseQuote.arrival_date),
        weeks: baseQuote.weeks,
        lodging: baseQuote.include_lodging,
        lodgingId: baseQuote.lodging,
        lodgingStart: moment(baseQuote.lodging_start) ?? null,
        lodgingWeeks: baseQuote.lodging_properties?.hs_weeks,
        lodgingExtraStart: moment(baseQuote.lodging_extra_start),
        lodgingExtraNights: baseQuote.lodging_properties?.extra_nights_duration,
        extra_nigth: baseQuote.extra_nigth,
        lodgingExtras: baseQuote.lodging_extras,
        transport: baseQuote.include_transport,
        transportId: baseQuote.transport,
        transportWaySelected: baseQuote.transport_ways,
        transportMinorCosts: baseQuote.transport_minor_costs,
        fees: includedFees,
        insurance: baseQuote.include_insurance,
        healthInsuranceId: baseQuote.insurance,
        agencyServices,
        serviceAgency: baseQuote.additional_services?.length > 0,
        medical: baseQuote.agency_insurance?.length > 0,
        minorCosts,
        appliedGoods
      })
    }
  }, [isLoadingBaseQuote, baseQuote])

  useEffect(() => {
    if (!isLoadingPost && isSuccessPost) {
      notification.info({
        message: 'Quote saved',
        description: '',
        placement: 'top'
      })
    }
  }, [isSuccessPost, isLoadingPost])

  // TODO: If this is not used, removed it
  const { data: newdata, getCurrencyToCurrency } = useGetCurrencyToCurrency()
  useEffect(() => {
    const getCurrency = async () => {
      getCurrencyToCurrency(userLocalCurrency, service.getCurrency())
    }
    getCurrency()
  }, [changeCurrency])

  const handleFormat = (date) => {
    if (date) {
      // Restar 3 días hábiles
      let daysToSubstract = 3
      const fechaMoment = moment(date)
      const dayOfWeek = fechaMoment.day()
      if (dayOfWeek > 5) {
        daysToSubstract = dayOfWeek - 2
      } else if (dayOfWeek <= 3) {
        daysToSubstract = 5
      }
      fechaMoment.subtract(daysToSubstract, 'days')
      return fechaMoment.format('DD/MM/YYYY')
    }
    return ''
  }

  return (
    <>
      {(isOpen || course?.offer?.type_course === 'package') && (
        <Row align='middle' justify='space-between'>
          <Col span={24}>
            <SelectStartAndDuration />
          </Col>
          <hr className='byp-hr__quote byp-mb-1 byp-mt-1' />
        </Row>
      )}

      <Collapse className='quote-byp__quote-panel' bordered={false} defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8']} expandIconPosition='left'>
        <Panel
          header='Costo total'
          key='1'
          className='quote-byp__quote-panel'
          extra={
            <>
              <p className='byp-m-0' style={{ fontWeight: '700', marginRight: '0.5rem' }}>Promoción valida inscribiéndote antes del: {handleFormat(prices?.year?.prices_end)}</p>
            </>
          }
        >

          <Row gutter={12} className='w-100'>
            <Col xs={12} md={14} lg={18}><p className='byp-m-0 paquete-concept'>Concepto</p></Col>
            <Col xs={12} md={6} lg={4}><p className='byp-m-0 paquete-concept end'>Total {`${service.getCurrency()}`}</p></Col>
            {/* <Col xs={24} md={4} lg={2} className='byp-centered'><p className='byp-m-0 paquete-concept' style={{ display: 'none' }}>-</p></Col> */}
            <hr className='byp-hr' />
          </Row>

          
          {!isOpen && <PackagePrice />}
          {isOpen && <PackageOpenPrice />}
          {service.getAdditions()?.length > 0 && <Additionals />}
          {(service.getArrayHealtInsurance()?.length > 0 || service.getPrices()?.extras?.length > 0) && (<OtherSection />)}
          {service.isAgency() && <AgencyCostSections />}
          {(service.getServiceAgency()?.length > 0 && service.isAgency()) && (<ServiceSection agencyServices={agencyServices} />)}
          {(service.getMedicalInsurance()?.length > 0 && service.isAgency()) && <MedicalSection />}
          <FieldCourseGoods />
        </Panel>
      </Collapse>

      {service.getIncludes() && service.getIncludes()?.length > 0 && (

        <Collapse bordered={false} defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8', 'incluye_1']} expandIconPosition='left'>
          <Panel
            header='Incluye'
            key='incluye_1'
            className='quote-byp__quote-panel'
          >
            <Row gutter={12} className='w-100'>
              <Col span={24}><p className='byp-m-0 paquete-concept' >Concepto</p></Col>
              <hr className='byp-hr' />
            </Row>
            <IncludesSection />
          </Panel>
        </Collapse>
      )}
    </>
  )
}

export { PackageQuoterForm }
