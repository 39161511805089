import { Button, Carousel, Col, List, Row } from "antd";
import { PersonalizedSearcher } from "../../home/components/PersonalizedSearcher";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { useGetCollections } from "../../../bussines/hooks/collections/useGetCollections";
import { useEffect, useState } from "react";
import { FavoriteCollectionCard } from "../../../common/components/FavoriteCollectionCard";
import { useFindCollection } from "../../../bussines/hooks/collections/useFindCollection";
import { CardFavoriteItem } from "./CardFavoriteItem";
import {
  removeFromStorage,
  retrieveFromStorage,
} from "../../../utils/localStorageUtils";
import { useAddItemToCollection } from "../../../bussines/hooks/collections/useAddItemToCollection";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { URL_QUOTER_PAGE } from "../../quoter";

function FavoriteContent() {
  const [collectionSelected, setCollectionSelected] = useState();
  const [page, setPage] = useState(1);
  const { data: collections, isLoading } = useGetCollections();
  const {
    data: collection,
    isLoading: isSearching,
    refetch,
  } = useFindCollection(collectionSelected);
  const {
    addItem,
    isLoading: isSavingItem,
    currentState: itemState,
    state,
  } = useAddItemToCollection();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && collections) {
      const all = collections.data?.find(
        (collection) => collection.name === "Todos"
      );
      setCollectionSelected(all?.id);

      const actionOnLoginSucces = retrieveFromStorage("actionOnLoginSucces");
      if (actionOnLoginSucces) {
        const action = JSON.parse(actionOnLoginSucces);
        console.log("🚀 ~ useEffect ~ action:", action);
        removeFromStorage("actionOnLoginSucces");
        if (action.name === "save-in-favorites") {
          addItem(all?.id, { item_id: action.item });
        }
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isSavingItem && itemState === state.STATE_SUCCESS) {
      refetch();
    }
  }, [isSavingItem]);

  useEffect(() => {
    if (!isSearching && collection) {
      console.log(
        "🚀 ~ file: favoriteContent.js:24 ~ useEffect ~ collection:",
        collection
      );
    }
  }, [isSearching]);

  function getSlidesPerRow() {
    if (window.screen.width >= 1040) return 6;
    if (window.screen.width >= 768) return 4;
    return 2;
  }

  const [slidesPerRow, setSlidesPerRow] = useState(
    getSlidesPerRow(window.innerWidth)
  );

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerRow(getSlidesPerRow(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function Collections() {
    if (collection?.data?.items.length > 0) {
      return (
        <List
          style={{ width: "100%" }}
          itemLayout="vertical"
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            total: collection?.data?.items?.length,
            pageSize: 3,
            hideOnSinglePage: true,
            current: page,
          }}
          dataSource={collection?.data?.items || []}
          renderItem={(item, key) => (
            <CardFavoriteItem
              item={item}
              key={item.id}
              onDelete={() => refetch()}
            />
          )}
          loading={isSearching}
        />
      );
    } else {
      return (
        <Row style={{ justifyContent: "center" }}>
          <p>Añade un favorito</p>
        </Row>
      );
    }
  }

  return (
    <div style={{ margin: "0 2%" }}>
      <Row>
        <Col>
          <div>
            <Button
              className="quote-byp__btn only-mobile"
              onClick={() => navigate(URL_QUOTER_PAGE)}
            >
              <ArrowLeftOutlined />
            </Button>
          </div>
        </Col>
        <Col span={24}>
          <div className="only-desktop">
            <PersonalizedSearcher />
          </div>
        </Col>
        <Col span={24}>
          <Carousel
            className="favorite-carrousel"
            autoplay={false}
            effect="fade"
            dots={false}
            slidesPerRow={slidesPerRow}
            nextArrow={
              <Button className="carrousel-bottoms">
                <RightCircleFilled
                  style={{ fontSize: "35px", color: " #373737" }}
                />
              </Button>
            }
            prevArrow={
              <Button className="carrousel-bottoms">
                <LeftCircleFilled
                  style={{ fontSize: "35px", color: " #373737" }}
                />
              </Button>
            }
            arrows
          >
            {collections?.data?.map((collection) => (
              <div key={collection.id} className="card-container">
                <FavoriteCollectionCard
                  collection={collection}
                  imagePreview={false}
                  onClick={() => {
                    setCollectionSelected(collection.id);
                  }}
                  isActive={collectionSelected === collection.id}
                />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>

      <Row className="header-container" justify="space-between" align="center">
        <Col>
          <h1 className="title">Todos</h1>
        </Col>

        <Col style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{
              borderColor: "#494949",
              color: "#494949",
              fontWeight: "600",
            }}
          >
            Guardar
          </Button>
        </Col>
      </Row>
      <Collections />
    </div>
  );
}

export { FavoriteContent };
