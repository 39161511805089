import { Button, Card, Col, Row, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { QuoteLocation } from "../../quote-preview/components/QuoteLocation";
import { Link, useNavigate } from "react-router-dom";
import { URL_OPINION_PAGE } from "../../opinion/OpinionPage";
import { useGetOfferExtras } from "../../../bussines/hooks/extras/useGetOfferExtras";
import { BasicSpinner } from "../../../common/components/BasicSpinner";
import { InfoQuoteCourse } from "./InfoQuoteCourse";
import { usePackage } from "../../../providers/package/package-course-context";
import { URL_REGISTER_PAGE_BETA } from "../../register";
import { saveInStorage } from "../../../utils/localStorageUtils";
import { CreateAndAddToCollection } from "./CreateAndAddToCollection";
import { HeartOutlined } from "@ant-design/icons";
import { URL_CITY_PAGE } from "../../programs/CountryPage";
import { InfoQuoteCampus } from "../../quote-course/components/InfoQuoteCampus";
import { InfoQuotePackageAccomodation } from "./InfoQuotePackageAccomodation";
import { PencilIcon } from "../../quote-preview/assets/imgs/Icons";
import { EditIcon } from "../../../common/theme/icons/EditIcon";
import { URL_QUOTER_PAGE } from "../../quoter";

const favButtonProps = {
  style: {},
  text: "Añadir a Favoritos",
  className: "quote-byp__btn-add-top",
  classNameSpan: "quotation__header-text",
  icon: <HeartOutlined />,
};

const InfoQuote = () => {
  const { course, prices, service, isPublicUser } = usePackage();
  const [offerId, setOfferId] = useState();
  const [selected, setSelected] = useState();
  const [skills, setSkills] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [targets, setTargets] = useState([]);
  const [characteristics, setCharacteristics] = useState([]);
  const [sports, setSports] = useState([]);
  const [extracurriculars, setExtracurriculars] = useState([]);
  const [activities, setActivities] = useState([]);
  const [extraDescription, setExtraDescription] = useState();
  const [isFixed, setIsFixed] = useState(false);
  const [accomodationIncluded, setAccomodationIncluded] = useState();

  const navigate = useNavigate();

  const cardRef = useRef(null);
  const cardRef2 = useRef(null);

  const { data, isLoading } = useGetOfferExtras(offerId);

  useEffect(() => {
    if (prices) {
      setOfferId(prices.offer_id ?? prices.id);
    } else {
      const p = service?.getPrices();
      setOfferId(p.id);
    }
  }, [prices]);

  useEffect(() => {}, [selected]);

  useEffect(() => {
    if (!isLoading && data) {
      const tags = data.course?.tags ?? [];
      const extras = data.course?.extras ?? [];
      const skills = tags.filter((extra) => extra.type === "skill");
      setSkills(skills);
      const requirements = extras.filter(
        (extra) => extra.type === "requirement"
      );
      setRequirements(requirements);
      const targets = extras.filter((extra) => extra.type === "target");
      setTargets(targets);
      const characteristics = extras.filter(
        (extra) =>
          extra.type === "characteristics" || extra.type === "characteristic"
      );
      setCharacteristics(characteristics);
      if (targets.length || requirements.length || skills.length) {
        setSelected("course");
      } else {
        setSelected("school");
      }
      const accomodation = extras.find((extra) => extra.type === "lodging");
      const accomodationDescriprion = extras.find(
        (extra) => extra.type === "lodging_description"
      );
      if (accomodation) {
        setAccomodationIncluded({
          name: accomodation.value,
          description: accomodationDescriprion?.value,
        });
      }
      setExtracurriculars(data.course?.properties?.extracurriculars);
      setSports(data.course?.properties?.sports);
      setActivities(data.course?.properties?.activities);

      const extraDescription = extras.find(
        (extra) => extra.type === "description"
      );
      setExtraDescription(extraDescription);
    }
  }, [isLoading, data]);

  useEffect(() => {
    const handleScroll = () => {
      if (!cardRef.current) return;
      const cardRect = cardRef.current.getBoundingClientRect();
      setIsFixed(cardRect.top <= 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!cardRef2.current) return;
      const cardRect = cardRef2.current.getBoundingClientRect();
      setIsFixed(cardRect.top <= 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpinionRedirection = () => {
    const opinionPage = URL_OPINION_PAGE.replace(
      ":campusSlug",
      course?.offer?.campus?.slug
    ).replace(":courseId", course?.offer?.id);
    saveInStorage("redirectOnLoginSucces", opinionPage);
    navigate(URL_REGISTER_PAGE_BETA);
  };
  const handleCita = () => {
    window.open("https://calendly.com/bookyourprogram", "_blank");
  };

  const handleDescription = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    let newText = doc.body.textContent || "";
    newText =
      newText.length > 440 ? newText.substring(0, 440) + "..." : newText;

    return newText;
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          {isLoading && <BasicSpinner />}
          {!isLoading && data && (
            <>
              <div ref={cardRef2} />
              <Card
                className={`quotation__transform ${isFixed ? "quotation__container-header" : ""}`}
                ref={cardRef}
                size="small"
              >
                <Row>
                  <Col span={24}>
                    <Row justify="space-between" align="middle">
                      <Col className="quotation__about">
                        <Button
                          className={
                            selected === "course"
                              ? "quote-byp__btn-info mr-05 active"
                              : "quote-byp__btn-info mr-05"
                          }
                          onClick={() => setSelected("course")}
                        >
                          Resumen del Paquete
                        </Button>
                        <Button
                          className={
                            selected === "school"
                              ? "quote-byp__btn-info mr-05 active"
                              : "quote-byp__btn-info mr-05"
                          }
                          onClick={() => setSelected("school")}
                        >
                          Sobre la Escuela
                        </Button>
                        {accomodationIncluded && (
                          <Button
                            className={
                              selected === "accommodation"
                                ? "quote-byp__btn-info mr-05 active"
                                : "quote-byp__btn-info mr-05"
                            }
                            onClick={() => setSelected("accommodation")}
                          >
                            Hospedaje
                          </Button>
                        )}
                        {/* {(requirements.length + targets.length + skills.length) > 0 && (
                    )} */}
                        {/* <Button
                      onClick={() => setSelected('accommodation')}
                    >Calificación y comentarios
                    </Button> */}
                        {data.hasOpinions > 0 && (
                          <Button
                            className="quote-byp__btn-info"
                            onClick={() => {
                              const offsetTop =
                                document.getElementById(
                                  "offer-opinions"
                                ).offsetTop;
                              window.scrollTo({
                                top: offsetTop - 100,
                                behavior: "smooth",
                              });
                            }}
                          >
                            Calificación y comentarios
                          </Button>
                        )}
                      </Col>
                      <Col className="flex-byp__group">
                        <Space>
                          {isPublicUser && (
                            <Button
                              className="quote-byp__btn-opinion hide_mobile "
                              onClick={handleOpinionRedirection}
                            >
                              <PencilIcon />
                              Deja tu Opinión
                            </Button>
                          )}
                          {!isPublicUser && (
                            <Button className="quote-byp__btn-opinion hide_mobile">
                              <PencilIcon />
                              <Link
                                to={URL_OPINION_PAGE.replace(
                                  ":campusSlug",
                                  course?.offer?.campus?.slug
                                ).replace(":courseId", course?.offer?.id)}
                                target="_blank"
                                state={{
                                  campus: course?.offer?.campus,
                                  course: {
                                    id: course?.offer?.id,
                                    name: course?.offer?.name,
                                  },
                                }}
                              >
                                Deja tu Opinión
                              </Link>
                            </Button>
                          )}
                          <a href="#qoute-course">
                            <Button className="quote-byp__btn-opinion">
                              Cotiza ahora
                            </Button>
                          </a>
                          {/* {service.userCanApply() && (
                        <Button onClick={submit} loading={isLoadingPost} className='quote-apply_now'>
                          Aplicar Ahora
                        </Button>
                      )} */}
                          {!isPublicUser && (
                            <Button
                              className="quote-apply_now"
                              style={{ padding: "0 1rem" }}
                              onClick={handleCita}
                            >
                              Agenda una Cita
                            </Button>
                          )}
                          <CreateAndAddToCollection
                            buttonProps={favButtonProps}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
              {isFixed && (
                <div style={{ height: "5rem", position: "relative" }} />
              )}
            </>
          )}
        </Col>

        <Col xs={24}>
          {!isLoading && data && (
            <Row gutter={[16, 16]} className="quote-byp__container">
              <Col xs={24} md={14}>
                {selected === "course" && (
                  <InfoQuoteCourse
                    course={course}
                    extraDescription={extraDescription}
                    skills={skills}
                    requirements={requirements}
                    characteristics={characteristics}
                    targets={targets}
                    sports={sports}
                    extracurriculars={extracurriculars}
                    activities={activities}
                  />
                )}
                {selected === "school" && (
                  <InfoQuoteCampus
                    course={course}
                    extras={data?.campus?.extras}
                    tags={data?.campus?.tags}
                  />
                )}
                {selected === "accommodation" && (
                  <InfoQuotePackageAccomodation
                    accomodation={accomodationIncluded}
                    campus={data?.campus}
                  />
                )}
              </Col>
              <Col xs={24} md={10}>
                <QuoteLocation campus={course?.offer?.campus} />
                <Card className="byp-mb-1">
                  <Row>
                    {/* <Col sm={24} md={6}>
                  <img src='' alt={course?.offer?.campus?.name} style={{ width: '6.375rem', aspectRatio: '1' }} />
                </Col> */}
                    <Col span={24} className="quote-byp__school">
                      <span>{course?.offer?.campus?.country?.name}</span>
                      <h4>{course?.offer?.campus?.city?.name}</h4>
                      {/* <div className='social-byp__group'>
                    {getSocialNetworkIcon('Facebook', 'http')}
                    {getSocialNetworkIcon('Facebook', 'http')}
                  </div> */}
                      {course?.offer?.description_city && (
                        <>
                          <div className="quotation__description-school">
                            {handleDescription(course?.offer?.description_city)}
                          </div>
                          <Link
                            to={URL_CITY_PAGE.replace(
                              ":code",
                              course?.offer?.campus?.country?.iso3
                            ).replace(":city", course?.offer?.campus?.city?.id)}
                            className="quotation__description-school-link"
                          >
                            Leer más
                          </Link>
                        </>
                      )}
                      {/* <Paragraph ellipsis={{ rows: 4, expandable: true }} className='quotation__description-school'>{course?.offer?.campus?.description}</Paragraph> */}
                      {/* <div dangerouslySetInnerHTML={{ __html: course?.offer?.description_city }} /> */}
                    </Col>
                  </Row>
                </Card>
                <Card className="quote-byp__new_search hide_mobile quote-byp__new_searchPack">
                  <h2 className="byp-m-0">Empezar de nuevo</h2>
                  <Button>
                    <EditIcon />
                    <Link style={{ color: "#797979" }} to={URL_QUOTER_PAGE}>
                      Nueva Búsqueda
                    </Link>
                  </Button>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export { InfoQuote };
