import React from 'react'
import { LoginForm } from './components/LoginForm'
import './assets/css/LoginPage.less'
import RandomImage from '../../utils/RandomImage'
import { Col, Row } from 'antd'

const URL_LOGIN_PAGE = '/login'

const LoginPage = () => {
  return (
    <div>
      <div style={{ overflow: 'hidden', height: '100vh' }}>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <div className='img-container-login'>
              <RandomImage />
            </div>
          </Col>
          <div className='form-container'>
            {/* <div className='img-logo-container'>
            <AppLogoNameHeader
              style={{
                width: '100%',
                objectFit: 'contain',
                height: '100%',
                paddingBottom: '18px'
              }}
            />
          </div> */}
            <div className='text-container'>
              <h1> Iniciar sesión</h1>
            </div>
            <LoginForm />
          </div>
        </Row>
      </div>
    </div>
  )
}

export { LoginPage, URL_LOGIN_PAGE }
