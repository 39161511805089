import { isSameOrAfter } from '../../../utils/isSameOrAfter'
import { isSameOrBefore } from '../../../utils/isSameOrBefore'

/**
 * Check is the course/service/fee start in the discount applicable dates
 * @param {array} discount
 * @param {*} start
 * @returns
 */
function isApplicable (discount, start) {
  if (!discount) return false
  const startDate = new Date(start)
  if (isSameOrAfter(startDate, new Date(discount.service_start)) &&
    isSameOrBefore(startDate, new Date(discount.service_start_limit))
  ) {
    return true
  }
  return false
}

/**
 * Return the discounts that can be applied
 * @param {array} discounts
 * @param {date} startDate
 * @returns
 */
function getApplicableDiscounts (discounts, startDate, weeks = undefined, nationality = {}) {
  const applicables = discounts?.filter((discount) => {
    if (weeks && discount.ranges?.length) {
      const keepGoing = discount.ranges.find((range) => {
        const valid = Number(weeks) >= range.from && Number(weeks) <= range.to
        return valid
      })
      if (!keepGoing) return false
    }
    if (nationality.country && discount.discount_exceptions) {
      const noApplicable = discount.discount_exceptions.includes(nationality.country)
      if (noApplicable) return false
    }
    if (nationality.region && discount.discount_regions) {
      const applicable = discount.discount_regions.includes(nationality.region)
      if (!applicable) return false
    }
    if (nationality.subregion && discount.discount_subregions) {
      const applicable = discount.discount_subregions.includes(nationality.subregion)
      if (!applicable) return false
    }
    if (discount.service_start) {
      return isApplicable(discount, startDate)
    } else return true
  })
  return applicables
}

/**
 * Calculate the discount total
 * @param {array} discounts
 * @param {*} cost
 * @param {*} weeks
 * @returns
 */
function calculateTotalDiscount (discounts, cost, weeks = null) {
  let totalDiscount = 0
  discounts.forEach(discount => {
    switch (discount.type) {
      case 'percentage':
        if (weeks) {
          totalDiscount += ((discount.value * (cost * weeks) / 100))
        } else {
          totalDiscount += ((discount.value * cost / 100))
        }
        break
      case 'amount':
        totalDiscount += discount.value
        break
      default:
        break
    }
  })
  return totalDiscount
}

export {
  isApplicable,
  calculateTotalDiscount,
  getApplicableDiscounts
}
