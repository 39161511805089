import React from 'react'

// const renderHTML = (rawHTML) => React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } })

const BlockText = ({ item }) => {
  if (item.hidded) {
    return null
  }
  return (
    <div className='BlockText' dangerouslySetInnerHTML={{ __html: item?.body ?? '' }} />
  )
}

export { BlockText }
