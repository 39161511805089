import { useQuery } from 'react-query'
import CollectionRepository from '../../repositories/CollectionRepository'

function useFindCollection (id, filters = {}) {
  return useQuery(
    ['useFindCollection', id, filters],
    () => CollectionRepository.find(id, filters),
    {
      retry: 2,
      enabled: !!id
    }
  )
}
export { useFindCollection }
