import { Avatar, Col, Row } from 'antd'
import { EmailIcon, PhoneIcon, TwitterIcon, TikTokIcon } from '../assets/imgs/Icons'

const handleUrl = (link) => {
  if (!link?.startsWith('http')) {
    link = 'http://' + link
  }

  return link
}

export const QuoteInstitution = ({ quote }) => (
  <div className='you-can-discover ycd-bg align-items' style={{ padding: '2rem' }}>
    <Row>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
        <div className='align-items description quote-institution-responsive-network-social'>
          <Avatar
            size={{
              xs: 24,
              sm: 32,
              md: 40,
              lg: 64,
              xl: 68
            }}
            style={{
              width: '110px',
              height: '110px',
              objectFit: 'contain'
            }}
            src={quote?.provider?.campus?.logo}
            icon={
              <img
                src={quote?.provider?.logo}
                className='img-rounded'
                alt={quote.provider.campus.name}
                style={{
                  width: '110px',
                  height: '110px',
                  objectFit: 'contain'
                }}
              />
            }
          />
        </div>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
        <div className='align-items quote-institution-responsive-network-social'>
          <h2 className='agent-name'>{quote.provider.campus.name}</h2>
          <div className='social'>
            {
              quote.agency.email && (
                <a href={`mailto:${quote.agency.email}`} className='social-media'>
                  <EmailIcon />
                </a>
              )
            }
            {
              quote.agent.whatsapp && (
                <a href={`https://wa.me/${quote.agency.whatsapp}`} className='social-media'>
                  <PhoneIcon />
                </a>
              )
            }
            {
              quote.agent.twitter && (
                <a href={quote.agency.twitter} className='social-media'>
                  <TwitterIcon />
                </a>
              )
            }
            {
              quote.agent.tiktok && (
                <a href={quote.agency.tiktok} target='_blank' className='social-media' rel='noreferrer noopener'>
                  <TikTokIcon />
                </a>
              )
            }
          </div>
          <div className='center-btn'>
            <a href={handleUrl(quote?.website)} target='_blank' rel='noreferrer'>
              <button className='quote-social-btn'>
                Know more
              </button>
            </a>
          </div>
        </div>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 11 }} xxl={{ span: 11 }}>
        <div className='align-items description byp-jst-rsp'>
          <p style={{ fontSize: '0.875rem' }}>{quote.agent.sede.description}</p>
        </div>
      </Col>
    </Row>
  </div>
)
