import axios from 'axios'
import api from '../../services/api'
import apiAutoEchanges from '../../services/apiAutoExchanges'
import apiFiles from '../../services/apiFiles'

class AgencyRepository {
  async register (args) {
    const response = await api.post('/register', args)
    return response.data
  }

  async getInfoStep () {
    const response = await api.get('/agency/steps')
    return response.data
  }

  async postSteps ({ step, data }) {
    const response = await api.post(`/agency/steps/${step}`, data)
    return response.data
  }

  async getBranches (params) {
    const response = await api.get('/agency/branches', { params })
    return response.data
  }

  async postDeleteLogo ({ data }) {
    const response = await api.post('/agency/delete/logo', data)
    return response.data
  }

  async find () {
    const response = await api.get('/agency/profile')
    return response.data
  }

  async update (data) {
    const response = await api.put('/agency/profile', data)
    return response.data
  }

  async getOffices (filtersQuery) {
    const response = await api.get('/offices?' + filtersQuery)
    return response.data
  }

  async findOffice (id) {
    const response = await api.get(`/offices/${id}`)
    return response.data
  }

  async createOffice ({ data }) {
    const response = await api.post('/offices', data)
    return response.data
  }

  async updateOffice ({ id, data }) {
    const response = await api.put(`/offices/${id}`, data)
    return response.data
  }

  async deleteOffice (id) {
    const response = await api.delete(`/offices/${id}`)
    return response.data
  }

  // Currencies
  async saveExchangeValues ({ data }) {
    const response = await api.post('/exchanges', data)
    console.log(
      '🚀 ~ file: AgencyRepository.js ~ line 67 ~ AgencyRepository ~ saveExchangeValues ~ response',
      response.data
    )
    return response.data
  }

  async generateExchangeValues (data) {
    const response = await api.post('/exchanges/autoexchange', data)
    return response.data
  }

  async activeDailyAutoExchange (data) {
    const response = await api.put(
      '/exchanges/activate-autoexchange',
      data
    )
    return response.data
  }

  async getExchangeValues (sede) {
    const response = await api.get(`/${sede}/exchanges`)
    return response.data
  }

  async getMyExchangeValues () {
    const response = await api.get('/exchanges')
    return response.data
  }

  async getMyExchangeValuesByCurrency (currency) {
    const response = await api.get(`/currencies/${currency}/exchanges`)
    return response.data
  }

  async getAgentExchangeValues (agentId) {
    const response = await api.get(`/exchanges/agent/${agentId}`)
    return response.data
  }

  async getExchangeValuesByCurrency ({
    currency,
    curreciesForAutoExchange = []
  }) {
    const response = await axios.all(
      curreciesForAutoExchange.map((code) =>
        apiAutoEchanges.get(`/pair/${code}/${currency}`)
      )
    )
    return response
  }

  async myCurrency (sede) {
    const response = await api.get(`/${sede}/currency`)
    return response.data
  }

  async getMatketingStatus (filters) {
    const response = await api.get('/marketing-status?' + filters)
    return response.data
  }

  async getMyExchangeHistoryByCurrency ({ currency }) {
    const response = await apiFiles.get(`/exchanges/${currency}/history`)
    console.log(
      '🚀 ~ file: AgencyRepository.js:104 ~ AgencyRepository ~ getMyExchangeHistoryByCurrency ~ response',
      response
    )
    return response.data
  }

  async getMyDownloadGeneralAnalysis ({ time }) {
    // const response = await apiFiles.get(
    //   `/dashboard/${time}/general-analysis-history`
    // )
    const response = await apiFiles.get(
      '/dashboard/general-analysis-history',
      {
        params: {
          time: time
        }
      }
    )
    console.log(
      '🚀 ~ file: AgencyRepository.js:125 ~ AgencyRepository ~ getMyDownloadGeneralAnalysis ~ response',
      response
    )
    return response.data
  }

  async institutions (filters) {
    const response = await api.get('/institutions?' + filters)
    return response.data
  }

  // Miscellaneous
  async saveMiscellaneous (data) {
    const response = await api.post('/services/agency_miscellaneous', data)
    return response.data
  }

  async getMiscellaneous (filters) {
    const response = await api.get('/services/agency_miscellaneous/list', {
      params: filters
    })
    return response.data
  }

  async updateMiscellaneous ({ id, data }) {
    const response = await api.put(`/services/${id}/update`, data)
    return response.data
  }

  // Agency institucion

  async getAgencyInstitutions (filterQuery) {
    const response = await api.get(`/allinstitutions?${filterQuery}`)
    return response.data
  }

  async getAgencyInstitutionsCountries (searchCountry) {
    const response = await api.get('/allinstitutionscountries', {
      params: {
        search: searchCountry
      }
    })
    return response.data
  }

  async saveInactiveInstitution (data) {
    const response = await api.post('/inactiveinstitution', data)
    return response.data
  }

  async activeInstitution (data) {
    const response = await api.delete('/activeinstitution', { data: data })
    return response.data
  }

  async saveInactiveHeartInstitution (data) {
    const response = await api.post('/inactiveheartinstitution', data)
    return response.data
  }

  async activeHeartInstitution (data) {
    const response = await api.delete('/activeheartinstitution', {
      data: data
    })
    return response.data
  }

  async activeType (data) {
    const response = await api.post('/active/type/institution', {
      data
    })
    return response.data
  }

  async getAllAgencies (filterQuery) {
    const response = await api.get(`/agencies?${filterQuery}`)
    return response.data
  }

  async getAllAgenciesNames () {
    const response = await api.get('/agencies/names')
    return response.data.data
  }

  async postPublicity (data) {
    const response = await api.post('/publicity/image', {
      ...data
    })
    return response.data
  }

  async getCurrencyToCurrency ({ base, toCurrency }) {
    if (!base || !toCurrency) return null
    if (base === 'undefined' || toCurrency === 'undefined') return null
    const response = await api.get(`/exchange/${base}/to/${toCurrency}`)
    return response.data
  }

  async getPublicity () {
    const response = await api.get('/get/publicity')
    return response.data
  }

  async postBigHome (data) {
    const response = await api.post('/big-home/save', {
      data
    })
    return response.data
  }

  async getPackage (ids, type, filters) {
    const response = await api.get('all/package', {
      params: {
        ids,
        type,
        ...filters
      }
    })
    return response.data
  }

  async getPageBigHome () {
    const response = await api.get('get/page/home')
    return response.data
  }

  async getConfiguration () {
    const response = await api.get('get/configuration')
    return response.data
  }
}

export default new AgencyRepository()
