import React from 'react'
import { Button, Result, Typography } from 'antd'
import { usePostResendVerificatonEmail } from '../../bussines/hooks/agency/usePostResendVerificatonEmail'
import { AppLogoNameHeader } from './AppLogoNameHeader'

const { Text } = Typography
const lang = 'en'
const title = {
  es: 'Esperando a que valides tu correo.',
  en: 'Waiting for you to validate your email.'
}

function FullPageUserVerification ({ message = '', logout, company }) {
  const { resend, isLoading, isSuccess, isError } = usePostResendVerificatonEmail()
  return (
    <div className='byp-full-container'>
      {/* <AppLogoNameHeader /> */}
      <Result
        icon={
          <AppLogoNameHeader
            style={{ width: '50%' }}
          />
        }
        title={title.en}
        extra={
          <div>
            <Button
              type='primary'
              ghost
              onClick={resend}
              disabled={isLoading}
              loading={isLoading}
            >
              {lang === 'es' && 'Reenviar correo de verificación'}
              {lang === 'en' && 'Resend verification email'}
            </Button>
            <Button
              type='primary'
              ghost
              onClick={logout}
              style={{ marginLeft: '1rem' }}

            >
              {lang === 'es' && 'Salir'}
              {lang === 'en' && 'Exit'}
            </Button>
            <div style={{ padding: '2rem' }}>
              <Text className='byp-fw-bold byp-title'>
                {
                  isSuccess && 'Email send!'
                }
                {
                  isError && 'There was an error sending the mail, plese try again'
                }
              </Text>
            </div>
          </div>
        }
      />
    </div>
  )
}

export { FullPageUserVerification }
