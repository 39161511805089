import { Button, Form, Input, List } from 'antd'
import { useEffect, useState } from 'react'
import { useGetPages } from '../../../../bussines/hooks/page/useGetPages'
import { FeaturedCard } from '../../blog/components/Card'
const { Search } = Input

export const BlogsByTagInfiniteList = ({ tag, search, onSearch }) => {
  const [blogs, setBlogs] = useState([{}, {}, {}])
  const [filters, setFilters] = useState(tag ? { limit: 12, tags: [tag], type: 'blog', search, status: 'Published' } : { limit: 12, search, type: 'blog', status: 'Published' })
  const { data: blogsData, isLoading } = useGetPages(filters)

  const onLoadMore = () => {
    const limit = filters.limit + 12
    setFilters({
      ...filters,
      limit
    })
  }

  const loadMore =
    !isLoading && filters.limit === blogs.length
      ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px'
          }}
        >
          <Button onClick={onLoadMore} className='byp-btn-red-50'>Cargar más</Button>
        </div>
        )
      : null

  useEffect(() => {
    if (!isLoading && blogsData) {
      setBlogs(blogsData)
    }
  }, [isLoading, blogsData])

  return (
    <>
      <Form.Item name='search' className='w-100'>
        <Search
          className='byp-search w-100 br-1'
          placeholder={search ?? 'Search'}
          onSearch={(value) => {
            if (value) {
              setFilters({
                ...filters,
                search: value
              })
            } else {
              const nFilters = {
                limit: filters.limit,
                tags: filters.tags,
                type: filters.type
              }
              setFilters(nFilters)
            }
            if (onSearch) onSearch(value)
          }}
          allowClear
          size='large'
        />
      </Form.Item>
      <List
        style={{ width: '100%' }}
        itemLayout='vertical'
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4
        }}
        loadMore={loadMore}
        dataSource={blogs || []}
        renderItem={(item, key) => (
          <List.Item>
            <FeaturedCard key={item.id} item={item} isFeatured={item.isFeatured} />
          </List.Item>
        )}
        loading={isLoading}
      />
    </>
  )
}
