import { Col, Image, Rate, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { useNavigate } from 'react-router-dom'
import { image } from '../../../../utils/fallbackImage'
import { BiTrash } from 'react-icons/bi'
import { EditIcon } from '../../../../common/theme/icons/EditIcon'
// import { FiMoreVertical } from 'react-icons/fi'
const { Paragraph } = Typography
export function CardCourse ({ item, editBlogs, removeItem, editItem, id }) {
  const navigate = useNavigate()
  const handleUrl = () => {
    let url = ''
    if (item.course?.type === 'package') {
      url = `/package-course/${item.course?.id}/${item.campus?.id}`
    }
    if (item.course?.type === 'short') {
      url = `/short-term-course/${item.course?.id}/${item.campus?.id}`
    }
    if (item.course?.type === 'course') {
      url = `/quote-course/${item.course?.id}/${item.campus?.id}`
    }
    if (item?.uuid) {
      return url + '?quote=' + item.uuid
    } else {
      return url
    }
  }

  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)

  useEffect(() => {
    if (item) {
      if (item?.translations && item?.translations?.length > 0) {
        const label = item?.translations?.find(item => item?.to === 'es' && item?.attribute === 'label')
        setTitle(label?.translation)
        const description = item?.translations?.find(item => item?.to === 'es' && item?.attribute === 'description')
        setDescription(description?.translation)
      } else {
        setTitle(item?.course?.label ?? item?.label)
        setDescription(item?.description ? item?.description : item?.course?.description ?? '')
      }
    }
  }, [item])

  const handleOnClick = () => {
    const url = handleUrl()
    navigate(url)
  }

  return (
    <div className='blog-card clickeable' onClick={handleOnClick}>
      {editBlogs && (
        <div className='blog-card__btns'>
          <BiTrash onClick={() => removeItem(id)} />
          <EditIcon onClick={() => editItem(id)} />
        </div>
      )}
      <div className='blog-card__header ant-image-w-100'>
        <Image
          width='100%'
          preview={false}
          src={item?.randomImage?.profile_photo_url ?? item?.campus?.profilePhotoUrl}
          fallback={image}
          style={{
            objectFit: 'cover',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            // height: '12rem',
            minWidth: '100%',
            maxWidth: '100%'
          }}
        />
        <span>{item?.program?.label}</span>
      </div>
      <div className='byp-mt-1 blog-card__body'>
        <h4>{title}</h4>
        <div className='extract'>
          <Paragraph ellipsis={{ rows: 3 }}>
            {description}
          </Paragraph>
        </div>
        <Row style={{ justifyContent: 'space-between' }}>
          <Row>
            <Rate value={item?.rate ?? 5} disabled className='byp-gray-rate' />
            <p style={{ fontSize: '0.75rem', lineHeight: '15px', marginBottom: '0', display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}>
              {item?.rate_count ?? 0} Opiniones
            </p>
          </Row>
          <Col style={{ fontSize: '0.75rem', lineHeight: '15px', marginBottom: '0', display: 'flex', alignItems: 'center', color: '#797979' }}>
            <ArrowRightAltIcon />
          </Col>
        </Row>
      </div>
    </div>
  )
}
