import { Avatar, Card, Col, Row, Typography } from 'antd'
import React, { } from 'react'
// import { LocationIcon } from '../../quote-preview/assets/imgs/Icons'
const { Paragraph } = Typography

const InfoQuotePackageAccomodation = ({ accomodation, campus }) => {
  console.log('🚀 ~ InfoQuotePackageAccomodation ~ accomodation, campus:', accomodation, campus)
  return (
    <Card style={{ height: '100%' }}>
      <h1>{accomodation?.name}</h1>
      <Row>
        <Col span={18}>
          <p className='quote-byp__mb'>
            <span>Escuela: </span>{campus?.name}
            {/* <LocationIcon /> {campus?.address} */}
          </p>
        </Col>
        <Col span={6} className='justify-end'>
          <Avatar src={campus?.profilePhotoUrl} size='large' />
        </Col>
      </Row>
      {accomodation?.description && <h3>Descripción</h3>}
      <Paragraph>{accomodation?.description}</Paragraph>
    </Card>
  )
}

export { InfoQuotePackageAccomodation }
