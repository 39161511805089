import { List } from 'antd'
import { CardItemCourse } from './CardItemCourse'
import { useEffect, useState } from 'react'
import { useGetCollections } from '../../../bussines/hooks/collections/useGetCollections'
import { useAuth } from '../../../providers/auth/auth-context-provider'

const FrontEndPaginatedOffersList = ({ data, showMap, program, status, isMobileView }) => {
  const { isPublicUser } = useAuth()
  const { data: collections, refetch: refetchCollections } = useGetCollections(undefined, !isPublicUser)
  const [allData, setAllData] = useState()
  const [page, setPage] = useState(1)

  useEffect(() => {
    setAllData(data?.data ?? [])
  }, [data])

  return (
    <List
      style={{ paddingTop: '10px' }}
      // className={showMap ? 'show-notshow p-0' : 'p-0'}
      className={`${isMobileView ? 'courses-list' : '' } ${showMap ? 'show-notshow p-0' : 'p-0'}`}
      itemLayout='vertical'
      pagination={{
        onChange: page => {
          setPage(page)
        },
        total: allData?.length ?? 0,
        pageSize: 6,
        hideOnSinglePage: true,
        current: page,
        // align: 'center'
        // position: 'bottom',
        // align: "center",
      }}
      dataSource={allData || []}
      renderItem={
        (item, key) => (
          <CardItemCourse
            key={key}
            item={item}
            status={status}
            program={program}
            collections={collections}
            refetchCollections={refetchCollections}
          />
        )
      }
    />
  )
}

export { FrontEndPaginatedOffersList }
