import { Statistic, Typography } from "antd";
import { usePackage } from "../../providers/package/package-course-context";

const { Text } = Typography;
function discountsApplied(discounts) {
  if (Array.isArray(discounts)) {
    return discounts.length > 0;
  }
  return discounts > 0;
}
export function StatisticWithDiscountsPackage({
  discounts,
  amount,
  subtotal,
  isHighSeasion,
  currency,
  active = false,
}) {
  const { service } = usePackage();
  if (
    service.getCourseProgram()?.name !== "language" &&
    active &&
    (amount === 0 || amount === "0.00")
  ) {
    return <Text>INCLUDED</Text>;
  }

  if (!active && (amount === 0 || amount === "0.00")) return null;

  return (
    <>
      {/* <Statistic
        prefix=''
        precision={2}
        value={`${amount}`}
        suffix={`${currency}`}
      /> */}

      <Statistic
        // prefix="$"
        precision={2}
        value={`${amount}`}
      />
      {isHighSeasion?.start && <b>Temporada alta</b>}
      {discountsApplied(discounts) > 0 && (
        <Statistic
          // prefix="$"
          className="price-after"
          precision={2}
          value={`${subtotal}`}
          // suffix={`${currency}`}
        />
      )}
    </>
  );
}
