import { useMutation } from 'react-query'
import UserRepository from '../../repositories/UserRepository'
import state from '../../utils/state-control'
import { useState } from 'react'

function useUpdateUserAvatarUrl () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(UserRepository.updateMyProfilePicture, {
    onSuccess: (d) => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * updateAvatar
   * @param id
   * @param url
   */
  const updateAvatar = (url) => {
    mutation.mutate(url)
  }

  return { ...mutation, state, currentState, updateAvatar }
}

export { useUpdateUserAvatarUrl }
