import { Button, Col, Row, Switch, Table, notification } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGetPages } from '../../../../bussines/hooks/page/useGetPages'
import { FiltersTableBlogs } from './FiltersTableBlogs'
import { usePostCreateNewPage } from '../../../../bussines/hooks/page/usePostCreateNewPage'
import { URL_EDIT_BLOG_PAGE } from '../PageEditBlog'
import { usePostChangePageStatus } from '../../../../bussines/hooks/page/usePostChangePageStatus'
import stateControl from '../../../../bussines/utils/state-control'
import { showErrorModal } from '../../../../utils/errorModal'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { useDeletePage } from '../../../../bussines/hooks/page/useDeletePage'

function TableBlogs () {
  const navigate = useNavigate()
  const [filters, setFilters] = useState({ page: 1 })
  const [updatingId, setUpdatingId] = useState()
  const { data: blogs, refetch, isLoading } = useGetPages(filters)
  const { create, isLoading: isCreating, error: errorAtCreate, data: dataCreated } = usePostCreateNewPage()
  const { changeStatus, isLoading: isUpdating, error, currentState } = usePostChangePageStatus()
  const { deletePage, isLoading: isDeleting, currentState: deleteState, error: errorDelete } = useDeletePage()

  const handleDateFormat = (date) => {
    const fechaHora = new Date(date)

    const dia = fechaHora.getDate()
    const mes = fechaHora.getMonth() + 1
    const año = fechaHora.getFullYear()
    const horas = fechaHora.getHours()
    const minutos = fechaHora.getMinutes()

    const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${año} a las ${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`

    return fechaFormateada
  }

  const handleSwitch = (publish, pageSlug) => {
    const status = publish ? 'Published' : 'Pending'
    changeStatus(pageSlug, status)
    setUpdatingId(pageSlug)
  }

  useEffect(() => {
    if (!isCreating && dataCreated) {
      console.log('🚀 ~ file: TableBlogs.js:32 ~ useEffect ~ dataCreated:', dataCreated)
      navigate(URL_EDIT_BLOG_PAGE.replace(':slug', dataCreated.blog.slug))
    }
    if (!isCreating && errorAtCreate) {
      showErrorModal(errorAtCreate.response.data?.message)
    }
  }, [isCreating, dataCreated, errorAtCreate])

  useEffect(() => {
    if (!isUpdating && currentState === stateControl.STATE_SUCCESS) {
      notification.success({
        message: 'El estado del blog ha sido actualizado'
      })
      refetch()
    } else if (!isUpdating && currentState === stateControl.STATE_ERROR && error) {
      showErrorModal(error.response.data?.message)
    }
  }, [isUpdating])

  useEffect(() => {
    if (!isDeleting && deleteState === stateControl.STATE_SUCCESS) {
      refetch()
      notification.success({
        message: 'El blog ha sido eliminado'
      })
    } else if (!isDeleting && deleteState === stateControl.STATE_ERROR) {
      showErrorModal(errorDelete.response?.data?.message)
    }
  }, [deleteState, isDeleting])

  const handleStatus = (status) => {
    let res
    if (status === 'Published') {
      res = 'Publicado'
    } else if (status === 'Deleted') {
      res = 'Borrado'
    } else {
      res = 'Borrador'
    }
    return res
  }

  const columns = [
    {
      title: 'Nombre',
      width: '30%',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => (
        <span className='table__link'>
          <Link to={URL_EDIT_BLOG_PAGE.replace(':slug', record.slug)}>
            {record.title.substring(0, 1).toUpperCase() + record.title.substring(1)}
          </Link>
        </span>
      )
    },
    {
      title: 'Estado',
      width: '10%',
      key: 'tags',
      render: (_, record) => (
        <span
          className={record?.status ? record?.status !== 'Pending' ? record?.status + '-table' : 'pendiente-table' : 'pendiente-table'}
        >
          {record?.status ? handleStatus(record?.status) : 'Borrador'}
        </span>
      )
    },
    {
      title: 'Autor',
      width: '10%',
      key: 'autor',
      render: (_, record) => (
        <span>{record.user?.name}</span>
      )
    },
    {
      title: 'Escuelas',
      width: '10%',
      render: (_, record) => (
        <ul style={{ maxHeight: '3rem', overflow: 'auto' }}>
          {record.campus?.map((campus) => (
            <li key={campus?.id}>{campus.name}</li>
          ))}
        </ul>
      )
    },
    {
      title: 'Fecha',
      width: '20%',
      key: 'fecha',
      render: (_, record) => (
        <span>{record?.updated_at ? handleDateFormat(record?.updated_at) : '-'}</span>
      )
    },
    {
      title: 'Activar',
      width: '10%',
      key: 'activar',
      render: (_, record) => (
        <Switch
          onChange={e => handleSwitch(e, record?.slug)}
          checked={record?.status === 'Published'}
          loading={isUpdating && updatingId === record?.slug}
          // disabled={isUpdating && updatingId !== record?.slug}
        />
      )
    },
    {
      title: 'Borrar',
      width: '10%',
      key: 'delete',
      render: (_, record) => (
        <ConfirmModal
          onOk={deletePage}
          isLoading={isDeleting}
          id={record.slug}
          btnClass='focus-none'
        />
      )
    }
  ]

  return (
    <>
      <Row gutter={20}>
        <Col>
          <h3 className='byp-title'>Carrusel para blog</h3>
        </Col>
        <Col style={{ lineHeight: '45px' }}>
          <Button
            onClick={() => create({ type: 'blog' })}
            loading={isCreating}
            className='byp-btn-gray-200-outline'
          >
            Nueva
          </Button>
        </Col>
      </Row>
      {/* <div className='space-between'>
      </div> */}
      <FiltersTableBlogs />
      <h5>Todas</h5>
      <Table
        columns={columns}
        dataSource={blogs?.data?.map((item, index) => ({ ...item, key: index }))}
        size='small'
        pagination={{
          current: blogs?.current_page,
          size: blogs?.last_page,
          total: blogs?.total,
          pageSize: blogs?.per_page,
          onChange: (val) => setFilters({ page: val })
        }}
        className='table-pages'
        loading={isLoading}
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'even-row' : 'odd-row'}
      />
    </>
  )
}

export { TableBlogs }
