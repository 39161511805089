import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'

function useGetQuoteActionsDetails (user, filters = {}) {
  for (const key in filters) {
    if (filters[key] === undefined || !filters[key]?.length === 0) {
      delete filters[key]
    }
  }

  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(
    ['useGetQuoteActionsDetails', filtersQuery, user],
    () => GeneralAnalysisRepository.getQuoteActionsByUser(user, filtersQuery),
    {
      enabled: !!user || !!filters.office
    }
  )
}

function useGetQuoteActionsDetailsReports (filters = {}) {
  // const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(
    ['useGetQuoteActionsDetailsReports', filters],
    () => GeneralAnalysisRepository.getQuoteActions(filters),
    {
      enabled: !!filters
    }
  )
}

export { useGetQuoteActionsDetails, useGetQuoteActionsDetailsReports }
