import { Col, Form, Row, Select } from 'antd'
// import { useState } from 'react'
import { useGetCountriesByRegions } from '../../../bussines/hooks/places/useGetCountriesByRegion'
import { useGetCountriesBySubRegions } from '../../../bussines/hooks/places/useGetCountriesBySubRegion'
import { useGetSubregionsByRegions } from '../../../bussines/hooks/places/useGetSubregionsByRegion'
import { SelectRegionsFormItem } from './SelectRegionsFormItem'

const { Option } = Select
export function SelectCountriesByRegionFormItem({ form }) {
  // const [region, setRegion] = useState()
  const regions = Form.useWatch('region', form)
  const sub_regions = Form.useWatch('subregions', form)
  //const { data: countries } = useGetCountriesByRegions(regions)
  const { data: countries } = useGetCountriesBySubRegions(sub_regions)
  const { data: subregions } = useGetSubregionsByRegions(regions)

  const handleSelectAll = (val) => {
    if (val.includes('all')) {
      const allSubRegions = subregions?.flat()?.map((subregion) => subregion.subregion)
      form.setFieldsValue({ subregions: allSubRegions })
    }
  }

  return (
    <div>
      <Row gutter={12}>
        <Col xs={24} md={8}>
          <SelectRegionsFormItem
            name='region' mode='multiple'
            label='Student location region'
            style={{ marginBottom: '2rem' }}
            global
            form={form}
          />
        </Col>
        <Col xs={24} md={16}>
          <Form.Item name='subregions' placeholder='Add more countries' label='Subregions'>
            <Select
              maxTagCount='responsive'
              mode='multiple'
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={handleSelectAll}
            >
              {subregions && (
                <Option key='all_subregions' value='all'>All</Option>
              )}
              {subregions?.flat()?.map((subregion) => (
                <Option key={subregion.subregion} value={subregion.subregion}>{subregion.subregion}</Option>
                /* <Option key={country.id} value={country.id}>{country.name}</Option> */
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Col xs={24} md={16} style={{ marginTop: '-10px', marginBottom: '-10px' }}>
        <Form.Item name='exclude_countries' placeholder='Add more countries' label='Excluded country'>
          <Select mode='multiple' maxTagCount= 'responsive' showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {countries?.flat()?.map((country) => (
              <Option key={country.id} value={country.id}>{country.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

    </div>
  )
}
