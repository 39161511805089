import { Form, Select } from 'antd'
import React, { useEffect } from 'react'
import { useGetDiscounts } from '../../../bussines/hooks/school/discounts/useGetDiscounts'
import { useGetDiscountsByProgram } from '../../../bussines/hooks/school/discounts/useGetDiscountsByProgram'
import { useLocation } from 'react-router-dom'

const { Option } = Select

function SelectDiscountFormItem ({ onChange, mode, program, setDiscounts, ...props }) {
  const { isLoading, data } = useGetDiscounts(program)
  const { isLoading: isLoadingDiscounts, data: discounts, refetch } = useGetDiscountsByProgram(program)

  useEffect(() => {
    if (setDiscounts && !isLoading && data) {
      setDiscounts(data)
    }
  }, [isLoading, data])

  useEffect(() => {
    if (program) {
      refetch()
    }
  }, [program])

  let defaultValue = null
  let defaultName = null

  const location = useLocation()
  const { discountId } = location.state ?? '1'

  if (discountId?.length > 0) {
    defaultValue = discountId[0].id
    defaultName = discountId[0].name
  }

  return (
    <Form.Item {...props}>
      <Select
        style={{ width: '100%' }}
        placeholder={defaultName}
        loading={isLoading || isLoadingDiscounts}
        mode={mode}
        className='byp-m-0'
        onChange={onChange}
        defaultValue={defaultValue}
        {...props}
      >
        {!program && data?.map(item => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))}
        {program && discounts?.map(item => (

          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>

        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectDiscountFormItem }
