import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { URL_DASHBOARD_PAGE } from '..'
import { useCreateOffice } from '../../../bussines/hooks/agency/offices/useCreateOffice'
import { useDeleteOffice } from '../../../bussines/hooks/agency/offices/useDeleteOffice'
import { useFindOffice } from '../../../bussines/hooks/agency/offices/useFindOffice'
import { useGetOffices } from '../../../bussines/hooks/agency/offices/useGetOffices'
import { useUpdateOffice } from '../../../bussines/hooks/agency/offices/useUpdateOffice'
import { useGetAllNationalities } from '../../../bussines/hooks/quoter/useGetAllNationalities'
import { SelectAsyncNationalitiesFormItem } from '../../../common/components/helper-form/SelectAsyncNationalitiesFormItem'
import { SelectCityByIsoFormItem } from '../../../common/components/helper-form/SelectCityByIsoFormItem'
import { SelectCurrencyFormItem } from '../../../common/components/helper-form/SelectCurrencyFormItem'
import { SelectPhoneCodeFormItem } from '../../../common/components/helper-form/SelectPhoneCodeFormItem'
import MapAddressFormItem from '../../../common/components/MapAddressFormItem'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { TableOffices } from './TableOffices'
import { UploadLogo } from '../../../common/components/UploadLogo'
import { usePostDeleteLogo } from '../../../bussines/hooks/agency/usePostDeleteLogo'
import { API_URL } from '../../../config'
import TextArea from 'antd/lib/input/TextArea'
import { cleanLogoUrl } from '../../../utils/cleanStorageUrl'

const { Search } = Input
const { Option } = Select

let readOnly = false
let canDelete = true

const required = { required: true, message: 'This element is required' }

function FormOffice () {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const [modal, modalContext] = Modal.useModal()
  const location = useLocation()
  const { officeId: findId } = location.state ?? {}

  const profilePhotoUrl = form.getFieldValue('profile_photo_url')
  const [officeId, setOfficeId] = useState(findId)
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const [filters, setFilters] = useState({})
  const [address, setAddress] = useState()
  const [coords, setCoords] = useState()

  const { createOffice, isLoading: isCreatingOffice, data: response } = useCreateOffice()
  const { updateOffice, isLoading: isUpdating, data: updateResponse } = useUpdateOffice()
  const { data: office, isLoading: isLoadingOffice } = useFindOffice(officeId)
  const { deleteOffice, isLoading: isDeleting, data: deleteResponse } = useDeleteOffice()

  const { data: offices, refetch } = useGetOffices(filters)

  const onFinish = (values) => {
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    values.logo = values.profile_photo_url
    if (isUpdateMode) {
      values.id = officeId
      if (values.logo) {
        values.logo = cleanLogoUrl(values.logo)
      }
      updateOffice(officeId, values)
    } else {
      createOffice(values)
    }
  }

  // TO DO: Move to utils ?
  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...filters,
      [key]: value
    }
    setFilters(nFilters)
  }

  const handleRemoveFilter = (key) => {
    setFilters(filters => {
      const copy = { ...filters }
      delete copy[key]
      return copy
    })
  }

  const addressChange = (newAddress, coords, addresComponents) => {
    if (newAddress !== undefined) {
      setAddress(newAddress)
      // setCountry(addresComponents?.country)
      // setCountryCode(addresComponents?.country_code)
      // setCity(addresComponents?.city)
      form.setFieldsValue({ mapAddress: newAddress, address: newAddress })
    }
  }

  useEffect(() => {
    if (updateResponse && !isUpdating) {
      refetch()
      modal.success({
        title: 'Success',
        content: 'Data has been updated'
      })
      form.resetFields()
      setIsUpdateMode(false)
      setOfficeId()
    }
  }, [isUpdating, updateResponse])

  useEffect(() => {
    if (response && !isCreatingOffice) {
      refetch()
      modal.success({
        title: 'Success',
        content: 'Data has been saved'
      })
    }
  }, [isCreatingOffice, response])

  useEffect(() => {
    if (!isLoadingOffice && office) {
      office.divisa = office.divisa_id
      office.country = office.country_id
      office.city = office.city_id
      form.setFieldsValue(office)
      setAddress(office.address)
      setIsUpdateMode(true)
    }
  }, [isLoadingOffice, office])

  useEffect(() => {
    if (!isDeleting && deleteResponse?.deleted) {
      refetch()
      modal.success({
        title: 'Success',
        content: 'Office has been deleted'
      })
    }
  }, [isDeleting, deleteResponse])

  useEffect(() => {
    const roles = user?.role_names ?? []
    if (roles.includes('agency-admin') || roles?.includes('agency-editor')) {
      canDelete = true
    }
    if (roles.includes('agency-office-manager')) {
      canDelete = false
    }
    if (roles.includes('agency-manager')) {
      canDelete = false
      readOnly = true
    }
    if (roles.includes('agency-editor')) {
      canDelete = false
    }
  }, [user])

  const { data: cities } = useGetAllNationalities()
  const [iso3, setIso3] = useState()

  function findIso (id) {
    const found = cities?.find((city) => {
      return city.id === id
    })
    if (found) {
      return setIso3(found?.iso3)
    }
  }

  const country = Form.useWatch('country', form)
  useEffect(() => {
    findIso(country)
  }, [country])

  return (
    <>
      {modalContext}
      <Row className='byp-dashboard-content' gutter={12}>
        <Col xs={24} md={10}>
          <Form
            layout='horizontal'
            scrollToFirstError
            name='OfficeForm'
            onFinish={onFinish}
            form={form}
            initialValues={{
            }}
            labelCol={{
              span: 6
            }}
            labelAlign='left'
            requiredMark={false}
          >
            <h3 className='byp-title'>Register your offices </h3>
            <p>
              Register your offices
            </p>

            <UploadLogo
              action={`${API_URL}/office/upload/logo`}
              usePostDeleteLogo={usePostDeleteLogo}
              deleteData={{
                office: officeId
              }}
              profileUrl={profilePhotoUrl}
              deleteDisable
            />

            <h6 className='byp-title byp-fw-bold'>Data</h6>
            <Form.Item name='name' label='Name' rules={[required]}>
              <Input placeholder='Name' />
            </Form.Item>
            <Form.Item name='description' label='Description'>
              <TextArea placeholder='Course description' showCount maxLength={600} />
            </Form.Item>
            <SelectAsyncNationalitiesFormItem name='country' label='Country' onChange={findIso} showSearch />
            <SelectCityByIsoFormItem name='city' label='City' iso={iso3} />
            <MapAddressFormItem
              name='address'
              TextArea='Example'
              label='Address'
              address={address}
              coords={coords}
              setCoords={setCoords}
              addressChange={addressChange}
            />
            <SelectCurrencyFormItem name='divisa' label='Currency' />
            <Form.Item name='tel' rules={[required]} label='Phone'>
              <Input
                placeholder='Phone'
                addonBefore={
                  <SelectPhoneCodeFormItem noStyle style={{ width: 90 }} name='phone_code' size='small' />
                }
              />
            </Form.Item>
            <Form.Item name='whatsapp' rules={[required]} label='WhatsApp'>
              <Input
                placeholder='WhatsApp'
                addonBefore={
                  <SelectPhoneCodeFormItem noStyle style={{ width: 90 }} name='phone_code' size='small' />
                }
              />
            </Form.Item>
            <Button
              className='byp-btn-blue-200 float-right'
              htmlType='submit'
              loading={isCreatingOffice || isUpdating}
            >
              {isUpdateMode ? 'Save' : 'Add'}
            </Button>
          </Form>
        </Col>
        <Col xs={0} md={14}>
          <Row gutter={6}>
            <Col span={12}>
              <Search className='byp-input-gray' onChange={(e) => handleAddFilter('search', e.target.value)} />
            </Col>
            <Col span={12}>
              <Select
                className='w-100' size='small'
                onChange={(val) => {
                  val !== null ? handleAddFilter('status', val) : handleRemoveFilter('status')
                }}
              >
                <Option value={null}>All Status</Option>
                <Option value='Active'>Active</Option>
                <Option value='Pending'>Pending</Option>
              </Select>
            </Col>
          </Row>
          <br />
          <TableOffices
            sedeLabel={user?.type === 'Institution' ? 'Campus' : 'Office'} data={offices}
            setOffice={setOfficeId}
            deleteOffice={deleteOffice}
            isDeleting={isDeleting}
            canDelete={canDelete}
          />
        </Col>
      </Row>
      <Row className='byp-dashboard-footer' gutter={12}>
        <Col span={24} className='byp-form-footer' style={{ paddingTop: '.5rem' }}>
          <Link
            to={URL_DASHBOARD_PAGE}
            state={{}}
            className='ant-btn byp-btn-blue-100 float-right'
          > Next
          </Link>
        </Col>
      </Row>
    </>
  )
}

export { FormOffice }
