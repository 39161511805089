import { useLocation, useParams } from 'react-router-dom'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { ValidateEnterpriseProfile } from '../../common/components/ValidateEnterpriseProfile'
import { MainTheme } from '../../common/theme/MainTheme'
import { SearchProvider } from '../../providers/search/search-context-provider'
import { CountryContent } from './components/CountryContent'

const URL_SEARCH_PAGE = '/country/:search/search'

const URL_COUNTRY_PAGE = '/country/:code'
const URL_CITY_PAGE = '/country/:code/:city'

const URL_COUNTRY_PREVIEW_PAGE = '/country/:code/preview'
const URL_CITY_PREVIEW_PAGE = '/country/:code/:city/preview'

function CountryPage () {
  const { code, city, search } = useParams()
  const location = useLocation()
  const containsPreview = location.pathname.includes('preview')

  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme activeCarousel showMainTheme={false} showMenuFooter>
        <SearchProvider>
          <CountryContent code={code} city={city} search={search} isPreview={containsPreview} />
        </SearchProvider>
      </MainTheme>
    </ValidateEnterpriseProfile>
  )
}

export { CountryPage, URL_COUNTRY_PAGE, URL_CITY_PAGE, URL_COUNTRY_PREVIEW_PAGE, URL_CITY_PREVIEW_PAGE, URL_SEARCH_PAGE }
