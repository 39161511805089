import { Row, Col, Form, Button, Divider, Carousel, Image } from 'antd'
import { SelectCampusAll } from '../../agency/components/SelectCampusAll'
import { useEffect, useState } from 'react'
import { MenuSavePicture } from './MenuSavePictures'
import { usePostSlider } from '../../../../bussines/hooks/slider/usePostSlider'
import homeTop from '../../../../common/assets/images/home_top.png'
import homeBottom from '../../../../common/assets/images/home_bottom.png'
import { useGetSlider } from '../../../../bussines/hooks/slider/useGetSlider'
import img01 from './../../../../common/assets/images/carousel/img-01.png'
import img02 from './../../../../common/assets/images/carousel/img-02.png'
import img03 from './../../../../common/assets/images/carousel/img-03.png'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { SelectBranchesFormItem } from '../../../../common/components/helper-form/SelectBranchesFormItem'

const carouselHeight = '25vh'
const contentStyle = {
  height: carouselHeight,
  color: '#fff',
  lineHeight: '100%',
  textAlign: 'center',
  backgroundColor: '#2a2f3394',
  fontSize: 35,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 20px'
}

const slides = [
  {
    text: '.',
    text_en: 'Your Success is Our Success. We’re Here to See You Grow',
    image: img01
  },
  {
    text: '.',
    text_en: 'We are Invested in Caring for You and the Ecosystem of International Education.',
    image: img02
  },
  {
    text: '.',
    text_en: 'An Easy-to-Use Platform that Connects You to the Right School.',
    image: img03
  }
]

function HomeSliderInterfaz () {
  const [form] = Form.useForm()
  const [gallery, setGallery] = useState([])
  const [url, setUrl] = useState([])
  const [id, setId] = useState([])
  const { saveSlider, isLoading } = usePostSlider()
  const sede = Form.useWatch('sede', form)
  const { user: _user } = useAuth()
  const { data } = useGetSlider({ sede })

  const handleOnFinish = (values) => {
    for (const i in id) {
      if (values?.sliders[i]) {
        values.sliders[i].file = id[i]
      }
    }
    values.type = _user.type

    saveSlider(values)
  }

  useEffect(() => {
    if (!isLoading) {
      form.setFieldsValue({ sede: undefined, sliders: [{}], applies_in: [] })
      setUrl([])
      setId([])
      setGallery([])
    }
  }, [isLoading])

  useEffect(() => {
    if (data?.images) {
      const newGallery = []
      const newInitualValues = []
      setUrl([])
      data?.images?.forEach(image => {
        newGallery.push({
          image: image?.src,
          text_en: image?.description
        })

        newInitualValues.push({ description: image?.description })
        setUrl(currentUrl => {
          return [...currentUrl, image?.src]
        })
        setId(currentUrl => {
          return [...currentUrl, image?.id]
        })
      })

      if (newInitualValues?.length > 0) {
        form.setFieldsValue({ sliders: newInitualValues })
      } else {
        form.setFieldsValue({ sliders: [{}] })
      }
      setGallery(newGallery)
    }
  }, [data])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form
            form={form}
            initialValues={{
              sliders: [{}]
            }}
            onFinish={handleOnFinish}
          >

            <h3 className='byp-title' style={{ paddingBottom: '25px' }}>Customize your home carrousel</h3>

            {_user.type !== "Agency" && (
              <SelectCampusAll
                name="sede"
                select
                form={form}
                isTag
                label="Schools"
              />
            )}
            {_user.type === "Agency" && (
              <SelectBranchesFormItem mode="multiple" showOptionAll name="sede" label="Office" form={form}  />
            )}

            <MenuSavePicture
              setGallery={setGallery}
              gallery={gallery}
              form={form}
              name='sliders'
              setId={setId}
              id={id}
              setUrl={setUrl}
              url={url}
            />

            {_user.type !== 'Agency' && <h4 className='byp-title' style={{ fontWeight: 'bold' }}>School pictures</h4>}
            {_user.type === 'Agency' && <h4 className='byp-title' style={{ fontWeight: 'bold' }}>Offices pictures</h4>}

            <Row style={{ paddingBottom: '20px' }}>
              <Col span={15}>
                {_user.type !== 'Agency' &&
                  <p style={{ fontSize: '.875em', color: 'var(--blue-100)' }}>
                    Remember that you've uploaded these pictures when uploading your school,
                    if you want to edit them, please click here
                  </p>}
                {_user.type === 'Agency' &&
                  <p style={{ fontSize: '.875em', color: 'var(--blue-100)' }}>
                    Remember that you've uploaded these pictures when uploading your office,
                    if you want to edit them, please click here
                  </p>}
              </Col>
            </Row>

            {_user.type !== 'Agency' && <h4 className='byp-title' style={{ fontWeight: 'bold', paddingBottom: '20px' }}>Schools where this applies to</h4>}
            {_user.type === 'Agency' && <h4 className='byp-title' style={{ fontWeight: 'bold', paddingBottom: '20px' }}>Offices where this applies to</h4>}

            {_user.type !== "Agency" && (
              <SelectCampusAll mode="multiple" select form={form} isTag name="applies_in" />
            )}
            {_user.type === "Agency" && (
              <SelectBranchesFormItem
                mode="multiple"
                name="applies_in"
                label="Office"
                showOptionAll
                form={form}
              />
            )}
            <Row style={{ paddingTop: '20px' }}>
              <Button
                style={{
                  display: 'flex',
                  backgroundColor: 'var(--blue-200)',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  alignItems: 'center',
                  padding: '1.25rem 1rem'
                }}
                loading={isLoading}
                htmlType='submit'
              >
                Save carrousel
              </Button>
            </Row>

          </Form>
        </Col>

        {/* 2da columna del diseño */}
        <Col span={12}>
          <h5 className='byp-title' style={{ fontWeight: 'bold', paddingBottom: '33px' }}>Preview</h5>
          <Image src={homeTop} />
          {gallery?.length > 0 && (
            <Carousel autoplay effect='fade' dots={{ className: 'byp-carousel-dots' }} style={{ height: carouselHeight }}>
              {gallery?.map((slide) => (
                <div key={slide?.text_en}>
                  <div style={{
                    backgroundImage: 'url(' + slide?.image + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%'
                  }}
                  >
                    <h3 style={contentStyle}>
                      {slide?.text_en}
                    </h3>
                  </div>
                </div>
              ))}
            </Carousel>
          )}

          {gallery?.length === 0 && (
            <Carousel autoplay effect='fade' dots={{ className: 'byp-carousel-dots' }} style={{ height: carouselHeight }}>
              {slides?.map((slide) => (
                <div key={slide?.text_en}>
                  <div style={{
                    backgroundImage: 'url(' + slide?.image + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%'
                  }}
                  >
                    <h3 style={contentStyle}>
                      {slide?.text_en}
                    </h3>
                  </div>
                </div>
              ))}
            </Carousel>
          )}
          <Image src={homeBottom} />
        </Col>

      </Row>

      {/* Parte baja del boton */}
      <div>
        <Col style={{ width: '100%' }}>
          <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

        </Col>
        <Row style={{ width: '100%', paddingTop: '20px', display: 'flex', justifyContent: 'flex-end', height: '75px' }}>
          <Button style={{ backgroundColor: 'var(--blue-100)', color: 'white', fontWeight: 'bold' }}>
            Next
          </Button>
        </Row>
      </div>
    </>
  )
}
export { HomeSliderInterfaz }
