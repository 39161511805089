/**
 * Removes the storage api base part from the url
 * @param {*} url http://....storage/something_urlesco_here
 * @returns url something_urlesco_here
 */
const cleanLogoUrl = (url) => {
  const logoUrl = url
  const urlParts = logoUrl.split('storage/')
  const partscount = urlParts.length
  const cleanedLogo = urlParts[partscount - 1]
  // console.log('🚀 ~ file: cleanStorageUrl.js:13 ~ cleanLogoUrl ~ cleanedLogo:', cleanedLogo, urlParts)
  return cleanedLogo
}

export {
  cleanLogoUrl
}
