import { Col, DatePicker, Form, Input, Row, TimePicker, Upload } from 'antd'
import React, { useState } from 'react'
import { getBase64, headersForUpload } from '../../../../utils/uploadImageUtils'
import { API_URL } from '../../../../config'

const { TextArea } = Input
const timeFormat = 'HH:mm'

function ContainerImage ({ remove, name, image, setImage, setImageUrl, imageUrl, form, fieldName, setText, haveTitle = true }) {
  const [id, setId] = useState()
  const handleChange = (event, index) => {
    if (event.file.status === 'done') {
      getBase64(event.file.originFileObj, (imageUrl) => {
        setImage(imageUrl)
        setImageUrl(event.file.response.name)
        setId(event.file.response.id)
        const currentValues = form.getFieldsValue([fieldName])
        currentValues[fieldName][name].id = event.file.response.id
        form.setFieldsValue(currentValues)
      })
    } else if (event.file.status === 'error') {
      console.log('🚀 ~ file error:', event.file.response)
    }
  }

  const handleRemove = () => {
    remove(name)
    setImage()
    setImageUrl()
    setId()
  }

  return (
    <div className='publicity-image'>
      <div className='publicity-image__img'>
        <label htmlFor={'upload-input-' + fieldName} style={{ cursor: 'pointer' }}>
          <div className='publicity-image__image'>
            {image && <img src={image} alt='avatar' style={{ width: '100%', height: 'auto' }} />}
          </div>
        </label>

        <Row gutter={[16, 16]} className='publicity-image__img-inputs'>
          <Col span={24} className='publicity-image__img-upload'>
            <label htmlFor={'upload-input-' + fieldName} className='upload__image-container'>
              <p>Subir foto:</p>
              <div className={imageUrl ? 'publicity-image__url' : 'h-input publicity-image__url'}>
                {imageUrl}
              </div>
            </label>

            <div style={{ display: 'none' }}>
              <Upload
                id={'upload-input-' + fieldName}
                name='image'
                listType='picture-card'
                className='upload-page__img'
                showUploadList={false}
                action={`${API_URL}/pages/images`}
                onChange={event => handleChange(event, 1)}
                headers={headersForUpload()}
              >
                {image ? <img src={image} alt='avatar' /> : <img src='' alt='avatar' />}
              </Upload>
              <Form.Item name={[name, 'id']}>
                <Input value={id} />
              </Form.Item>
            </div>

            <span onClick={() => handleRemove()} className='remove__image'>
              -
            </span>
          </Col>

          {haveTitle &&
            <Col span={24} className='publicity-image__inputs'>
              <label>Título:</label>
              <Form.Item
                name={[name, 'title']}
              >
                <TextArea rows={2} onChange={e => setText(e.target.value)} />
              </Form.Item>
            </Col>}

          <Col span={24} className='publicity-image__inputs'>
            <label>Link:</label>
            <Form.Item
              name={[name, 'link']}
            >
              <Input className='publicity-image__link' />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Row className='container__time'>
        <Col span={24}>
          <h5>Activar hasta el día y hora de:</h5>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name={[name, 'expired_date']}>
                <DatePicker
                  className='publicity__date'
                  placeholder='dd/mm/aaaa'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={[name, 'expired_time']}>
                <TimePicker
                  className='publicity__time'
                  format={timeFormat}
                  placeholder='00:00'
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default ContainerImage
