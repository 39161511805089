import React from 'react'
import { Col, Layout, Row } from 'antd'
import { AppLogoNameHeader } from '../components/AppLogoNameHeader'
import './../assets/css/main-theme.less'
import { MainThemeCarrousel } from './partials/MainThemeCarrousel'
import { MainThemeFooter } from './partials/MainThemeFooter'
import { UserMenuSwitcher } from './partials/UserMenuSwitcher'

const { Header, Footer, Content, Sider } = Layout

const PublicTheme = ({ children, activeCarousel = false, showMenu = false }) => {

  return (
    <Layout className='layout'>
      <Header className='header-theme logo headerLayout header-pc'>
        <Row>
          <Col xs={24} md={24} lg={3} style={{ textAlign: 'center' }}>
            <AppLogoNameHeader className='logo-small' />
          </Col>
          <Col xs={24} md={24} lg={1} style={{ textAlign: 'center' }}>
            <label className='qyp-label-main'>BETA</label>
          </Col>
          <Col xs={24} md={24} lg={19} className='header-bar'>
            <></>
          </Col>
        </Row>
      </Header>
      <Header className='header-theme logo headerLayout header-tablet'>
        <Row>
          <Col md={2} style={{ textAlign: 'center' }}>
            <AppLogoNameHeader className='logo-small' changeImage />
          </Col>
          <Col md={2} style={{ textAlign: 'center' }}>
            <label className='qyp-label-main'>BETA</label>
          </Col>
          <Col md={20} className='d-flex' style={{ justifyContent: 'space-between' }}>
            <></>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Row style={{ width: '100%' }}>
          {
            showMenu && (
              <Col md={4} lg={4} style={{ zIndex: 999, textAlign: 'center' }}>
                <Sider
                  width='100%'
                  className=''
                  breakpoint='lg'
                  collapsedWidth='0'
                  onBreakpoint={(broken) => {
                  }}
                  onCollapse={(collapsed, type) => {
                  }}
                >
                  <UserMenuSwitcher />
                </Sider>
              </Col>
            )
          }
          <Col xs={24} md={24} lg={showMenu ? 20 : 24} className='main-menu-container'>
            <Content>
              {activeCarousel && <MainThemeCarrousel />}
              <Content className='site-layout-content'>{children}</Content>
            </Content>
          </Col>
        </Row>
      </Layout>
      {
        !showMenu && (
          <Footer className='site-layout-footer'><MainThemeFooter /></Footer>
        )
      }
    </Layout>
  )
}

export { PublicTheme }
