import { useMutation } from 'react-query'
import PageRepository from '../../repositories/PageRepository'
import { useState } from 'react'
import stateControl from '../../utils/state-control'

function usePostChangePageStatus () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(PageRepository.changeStatus, {
    onSuccess: d => {
      setCurrentState(stateControl.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(stateControl.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const changeStatus = (pageSlug, status, data = {}) => {
    mutation.mutate({ page: pageSlug, data: { ...data, status } })
  }

  return { ...mutation, stateControl, currentState, changeStatus }
}

export { usePostChangePageStatus }
