import React, { useEffect, useState } from 'react'
import { Button, Form, Select } from 'antd'
import { useSearch } from '../../../../providers/search/search-context-provider'
import { useGetCountriesWithOffers } from '../../../../bussines/hooks/places/useGetCountriesWithOffers'
import { validateProgramName } from '../../../../utils/programUtils'
import { SelectGradesFormItem } from '../../../../common/components/helper-form/SelectGradesFormItem'
const { Option } = Select
const filterOption = (inputValue, option) => {
  return option.props['data-type']?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1
}

function AcademiYearsSearchForm ({ program }) {
  const { changeSearch, form } = useSearch()
  const programData = validateProgramName(program)
  const [searchFilter, setSearchFilter] = useState({
    program: null
  })
  const { data, isLoading } = useGetCountriesWithOffers(undefined, { ...programData }, !!program)
  const selectedCountries = Form.useWatch('country', form)
  const selectedGrade = Form.useWatch('grade', form)

  const handleOnSubmitSearch = () => {
    const countFilter = Object.keys(searchFilter).length
    if (isNaN(searchFilter?.sede)) {
      delete searchFilter.sede
    }
    if (isNaN(searchFilter?.lang)) {
      delete searchFilter.lang
    }
    const programData = validateProgramName(program)
    if (program) {
      const nFilter = {
        ...searchFilter,
        ...programData
      }
      setSearchFilter(nFilter)
    }
    if (countFilter) {
      form.resetFields([
        'date',
        'city',
        'materials',
        'lang',
        'school',
        'campus',
        'type_school',
        'is_legal',
        'study_area',
        'year',
        'seasons'
      ])
      changeSearch('LANG_FORM', {
        ...searchFilter,
        ...programData
      })
    }
  }

  useEffect(() => {
    const nFilter = {
      ...searchFilter,
      place: selectedCountries,
      place_type: 'country',
      country: selectedCountries
    }
    setSearchFilter(nFilter)
  }, [selectedCountries])

  useEffect(() => {
    const nFilter = {
      ...searchFilter,
      grade: selectedGrade
    }
    setSearchFilter(nFilter)
  }, [selectedGrade])

  return (
    <Form form={form}>
      <div compact className='language-from-responsive'>
        <Form.Item noStyle name='country' xs={24}>
          <Select
            className='school-search__item select-selection-item-br-1 select-selection-item-bg-gray w-100'
            showSearch
            mode='multiple'
            maxTagCount={3}
            filterOption={filterOption}
            placeholder='País'
            size='large'
            notFoundContent={isLoading ? 'Cargando opciones disponibles...' : 'No se encontró coincidencias'}
            showArrow
          >
            {data?.countries?.map(option => (
              <Option key={option.id} value={option.id} data-type={option.translations?.translation ?? option.name}>
                {option.translations?.translation ?? option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <SelectGradesFormItem
          selectProps={{
            className: 'school-search__item select-selection-item-br-1 select-selection-item-bg-gray w-100',
            showSearch: true,
            filterOption: { filterOption },
            placeholder: 'Grado',
            size: 'large',
            showArrow: true
          }}
          noStyle
          name='grade'
          filters={{
            ...programData,
            country: selectedCountries
          }}
        />
        <div className='button-without-border-start button-search-responsive' style={{ }}>
          <Button
            onClick={handleOnSubmitSearch}
            block
            size='large'
            className='byp-btn-red-50'
          >Buscar
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default AcademiYearsSearchForm
