import React from "react";
import { FullPageSpinner } from "../../common/components/FullPageSpinner";
import { useFetchUser } from "../../bussines/hooks/user/useFetchUser";
import SessionService from "../../services/SessionService";
import { FullPageUserVerification } from "../../common/components/FullPageUserVerification";
import { Unauthorized } from "../../pages/errors/Unauthorized";
import {
  validateUserAccess,
  validEmails,
} from "../../bussines/settings/validate-user-access";
import PropTypes from "prop-types";
import LottieCargando from "../../lotties/LottieCargando";
import { useLoginUser } from "../../bussines/hooks/user/useLoginUser";
import { BYP_PUBLIC_PASSWORD, BYP_PUBLIC_USER } from "../../config";
import {
  retrieveFromStorage,
  saveInStorage,
} from "../../utils/localStorageUtils";
import { getCountry, getCountryName } from "../../utils/getCountryName";
import { useGetNotifications } from "../../bussines/hooks/notification/useGetNotifications";
import { URL_REGISTER_PAGE_BETA } from "../../pages/register";
import { useLocation, useNavigate } from "react-router-dom";
import { URL_LOGIN_PAGE } from "../../pages/login";
import { URL_BIG_HOME } from "../../pages/home/big-home";

const AuthContext = React.createContext();
const localCurrencyKey = "___BYP_KEY_CURRENCY___";
const userLocationKey = "___BYP_KEY_LOCATION_COUNTRY___";
// const userFullLocationKey = '___BYP_KEY_FULL_LOCATION___'

function AuthProvider(props) {
  const {
    isLoading: isValidatingUser,
    isError,
    data,
    refetch,
  } = useFetchUser();
  const {
    isLoading: isLogginUser,
    onSubmit,
    currentState,
    state,
    error,
  } = useLoginUser();
  const { isLoading: isLoadingNotifications, data: notifications } =
    useGetNotifications();
  const [isLogout, setIsLogout] = React.useState(false);
  const [isPublicUser, setIsPublicUser] = React.useState(false);
  const [userLocalCurrency, setUserLocalCurrency] = React.useState();
  const [userCountryLocation, setUserCountryLocation] = React.useState();
  // const [retries, setRetries] = React.useState(0)
  // const [notifications, setNotifications] = React.useState()
  const [hasUnreadNotifications, setHasUnreadNotifications] = React.useState();

  const handleRedirectToWebsite = () => {
    SessionService.destroySession();
    window.location = "/";
  };

  React.useEffect(() => {
    if (isLogout) {
      handleRedirectToWebsite();
    }
  }, [isLogout]);

  React.useEffect(() => {
    if (!isValidatingUser && isError) {
      // Login with the public user
      onSubmit(BYP_PUBLIC_USER, BYP_PUBLIC_PASSWORD, true);
    } else if (!isValidatingUser && data) {
      setIsPublicUser(BYP_PUBLIC_USER === data?.user?.email);
      // console.log(
      //   '🚀 ~ file: auth-context-provider.js:40 ~ React.useEffect ~ BYP_PUBLIC_USER === data?.user?.email:',
      //   BYP_PUBLIC_USER === data?.user?.email,
      //   BYP_PUBLIC_USER, data?.user?.email
      // )
    }
  }, [isValidatingUser, data]);

  React.useEffect(() => {
    if (!isLoadingNotifications && notifications) {
      const hasUnread = notifications.some((notifi) => !notifi.read_at);
      hasUnread
        ? console.log("🚀 ~ React.useEffect ~ hasUnread: TRUE")
        : console.log("🚀 ~ React.useEffect ~ hasUnread: false");
      setHasUnreadNotifications(hasUnread);
    }
  }, [isLoadingNotifications, notifications]);

  // TODO: Config retries
  React.useEffect(() => {
    if (!isLogginUser && state.LOGIN_ERROR === currentState) {
      // console.log('LOGIN  FAILED')
      console.log(
        "🚀 ~ file: auth-context-provider.js:43 ~ React.useEffect ~ BYP_PUBLIC_USER, BYP_PUBLIC_PASSWORD:",
        BYP_PUBLIC_USER,
        BYP_PUBLIC_PASSWORD
      );
      console.log(
        "🚀 ~ file: auth-context-provider.js:43 ~ React.useEffect ~ error:",
        error?.response?.data?.message
      );
      handleRedirectToWebsite();
    } else if (!isLogginUser && state.LOGIN_SUCCESS === currentState) {
      setIsPublicUser(BYP_PUBLIC_USER === data?.user?.email);
      refetch();
    }
  }, [isLogginUser, currentState]);

  React.useEffect(() => {
    const userCountry = getUserCountryLocation();
    const newCurrency = getUserLocalCurrency();
    const getCurrency = async () => {
      let currency;
      if (!userCountry) {
        const res = await getCountry();
        if (res) {
          setUserCountryLocation(res);
          saveInStorage(userLocationKey, res);
        }
      }
      if (!newCurrency || newCurrency === "undefined") {
        currency = await getCountryName();
        if (currency) {
          setUserLocalCurrency(currency);
          saveInStorage(localCurrencyKey, currency);
        }
      } else {
        currency = newCurrency;
        setUserLocalCurrency(newCurrency);
      }
    };
    getCurrency();
  }, []);

  React.useEffect(() => {
    if (userLocalCurrency) {
      saveInStorage(localCurrencyKey, userLocalCurrency);
    }
  }, [userLocalCurrency]);

  const getUserLocalCurrency = () => {
    if (userLocalCurrency) {
      return userLocalCurrency;
    }
    const newCurrency = retrieveFromStorage(localCurrencyKey);
    if (newCurrency) {
      setUserLocalCurrency(newCurrency);
      return newCurrency;
    } else {
      return null;
    }
  };

  const getUserCountryLocation = () => {
    if (userCountryLocation) return userCountryLocation;
    const newLocation = retrieveFromStorage(userLocationKey);
    if (newLocation) {
      setUserCountryLocation(newLocation);
      return newLocation;
    }
    return null;
  };

  // ! ============ REDIRECT TO REGISTER PAGE IF USER IS NOT LOGGED IN START ========================
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    // if ((!data?.user && !isValidatingUser) || (data?.user.email === BYP_PUBLIC_USER && !isValidatingUser) ) {

    // No user and validation complete
    if (data?.user?.email === BYP_PUBLIC_USER && !isValidatingUser) {
      console.log("No user and validation complete");

      // Verifico si no estoy en las pantallas de autenticación
      if (
        location.pathname !== URL_LOGIN_PAGE &&
        location.pathname !== URL_REGISTER_PAGE_BETA
      ) {

        let firstVisitTimestamp = localStorage.getItem("firstVisitTimestamp");

        // console.log("firstVisitTimestamp", firstVisitTimestamp);

        if (!firstVisitTimestamp) {
          firstVisitTimestamp = Date.now();
          localStorage.setItem("firstVisitTimestamp", firstVisitTimestamp);
        }

        const timestamp = parseInt(firstVisitTimestamp, 10);
        const currentTime = Date.now();
        const timeElapsed = (currentTime - timestamp) / 1000; // en segundos

        const timeLimit = 2 * 60; // 2 minutos en segundos
        // const timeLimit = 1 * 60; // 2 minutos en segundos

        // console.log({timestamp, currentTime, timeElapsed, timeLimit})

        if (timeElapsed >= timeLimit) {
          // console.log("había excedido");
          localStorage.removeItem("firstVisitTimestamp");
          navigate(URL_REGISTER_PAGE_BETA, { replace: true }); // Redirige a la página de registro
        } else {
          setTimeout(
            () => {
              if (
                location.pathname !== URL_LOGIN_PAGE &&
                location.pathname !== URL_REGISTER_PAGE_BETA
              ) {
                // Elimino del localstorage el tiempo de la primera visita
                localStorage.removeItem("firstVisitTimestamp");
                navigate(URL_REGISTER_PAGE_BETA, { replace: true }); // Redirige a la página de registro
              }
            },
            (timeLimit - timeElapsed) * 1000
          ); // Espera el tiempo restante
        }
      }
    } 
  }, [data, isValidatingUser]);

  // ! ============ REDIRECT TO REGISTER PAGE IF USER IS NOT LOGGED IN END ========================


  if (isValidatingUser || isLogginUser) {
    return <LottieCargando />;
  }

  if (state.LOGIN_ERROR === currentState) {
    // return <FullPageSpinner title='Abriendo inicio de sesion...' />
    return <LottieCargando />;
  }

  if (isLogout) {
    return <FullPageSpinner title="Regresa pronto..." />;
  }

  const logout = () => {
    setIsLogout(true);
  };

  if (!data?.user) {
    const session = SessionService.getCurrentSession();
    // console.log('NOUSER', session)
    if (!session) onSubmit(BYP_PUBLIC_USER, BYP_PUBLIC_PASSWORD, true);
  }

  if (!data?.user?.email_verified_at) {
    return <FullPageUserVerification logout={logout} company={data?.company} />;
  }

  if (
    !validateUserAccess({
      roles: data?.user.roles ?? [],
      permissionTypes: props.permissionTypes,
    })
  ) {
    // console.log(data)
    return <Unauthorized />;
  }

  if (
    props.onlyEdulinks &&
    !validEmails.includes(data?.company?.email) &&
    data?.user?.type === "Agency"
  ) {
    // console.log('🚀 ~ file: auth-context-provider.js:65 ~ AuthProvider ~ props.onlyEdulinks', props.onlyEdulinks)
    return <Unauthorized />;
  }

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        company: data.company,
        companyStatus: data.user_company_status,
        registrationStatus: parseInt(data.registration_status),
        isPublicUser,
        logout,
        refetch,
        isValidatingUser,
        userLocalCurrency,
        setUserLocalCurrency,
        userCountryLocation,
        localCurrencyKey,
        hasUnreadNotifications,
        notifications,
      }}
      {...props}
    />
  );
}

AuthProvider.propTypes = {
  permissionTypes: PropTypes.array.isRequired,
};

AuthProvider.default = {
  permissionTypes: [],
  onlyEdulinks: false,
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
