import React from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { getDiscountSymbol, handlePrice } from '../../../utils/getDiscountSymbol'
import { Statistic, Switch, Tooltip } from 'antd'
// import { formatStringDate } from '../../../utils/formatStringDate'
import { IconPromocion } from '../../../common/assets/svg/icons'

function CampusPricesSection() {
  const { service, formConfig, setFormConfig } = useQuoter()

  const handleChange = (value, id, cost, frequency) => {
    let fees = []
    if (!formConfig.fees) {
      setFormConfig({
        ...formConfig,
        fees: []
      })
    }
    if (value) {
      fees = formConfig.fees ?? []
      fees?.push({ id, cost, frequency })
    } else {
      formConfig.fees?.map((fee) => (
        fee.id !== id ? fees.push(fee) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      fees
    })
  }

  return (
    <>
      {service.getPrices()?.base_prices?.map((item) => {
        return item.name !== 'Registration Fee' ? (
          <React.Fragment key={item.id}>
            <RowQuoter
              colA={
                <>
                  <p style={{ marginBottom: 0 }}>
                    {item.translations?.find(t => t.to === 'es')?.translation ?? item.name}
                    {item?.mandatory ?? handleChange(true, item.id, item.cost, item.frequency)}
                  </p>
                  {service.getFeeDiscounts(item.id).length > 0 && (
                    <Tooltip title={
                      <>
                        {service.getFeeDiscounts(item.id)?.map((d) => (
                          <div key={d.id}>
                            <p className='info-cost__discount w-100'>
                              {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en pago.
                            </p>
                            <p className='info-cost__discount w-100'>
                              Descuento valor monetario {handlePrice(d.value, service.getCourseAmount())} {service.getCurrency()} en pago.
                            </p>
                          </div>
                        ))}
                      </>
                    } placement="bottomLeft" color='white'>
                      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <IconPromocion style={{ verticalAlign: 'middle', marginRight: '.5rem' }} />
                        {item.translations?.find(t => t.to === 'es')?.translation ?? item.name}
                      </span>
                    </Tooltip>
                  )}
                </>
              }

              colB={
                <>
                  {/* Primer bloque colB */}
                  <Statistic
                    prefix='$'
                    precision={2}
                    value={`${service.getFeeCost(item.id)?.toFixed(2)}`}
                    // suffix={`${service.getCurrency()}`}
                  />
                  {/* Segundo bloque colB */}
                  {service.getFeeDiscounts(item.id).length > 0 && (
                    service.getFeeDiscounts(item.id)?.map((d) => (
                      <Statistic
                        key={d.id}
                        prefix='$'
                        className='price-after text-discount'
                        precision={2}
                        value={`${d.type === 'amount' ? '-' + d.value : '-' + handlePrice(d.value, service.getFeeCost(item.id)?.toFixed(2))}`}
                        // suffix={`${service.getCurrency()}`}
                      />
                    ))
                  )}
                </>
              }

              colC={
                <>
                  {!item?.mandatory && (
                    <Switch
                      onChange={(value) => { handleChange(value, item.id, item.cost, item.frequency) }}
                      className='byp-ml-1'
                      checked={service.isChecked(item.id)}
                    // checkedChildren='YES'
                    // unCheckedChildren='NO'
                    />
                  )}
                </>
                // <ButtonSwitch
                //   isActive={service.isChecked(item.id)}
                //   onChange={handleChange}
                //   item={item}
                // />
              }
            />

          </React.Fragment>
        )
          : null
      })}
    </>
  )
}

export { CampusPricesSection }
