import React from 'react'
import { PageProtected } from '../../../common/components/PageProtected'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { MainTheme } from '../../../common/theme/MainTheme'
import { useParams } from 'react-router-dom'
import { EditPageProgramInfo } from './components/EditPageProgramInfo'

const URL_EDIT_PAGE_PROGRAM_PAGE = '/edit/program/:program'

function EditPageProgramPage () {
  const { program } = useParams()

  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
    >
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content byp-align-centered'>
          <EditPageProgramInfo program={program} />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { EditPageProgramPage, URL_EDIT_PAGE_PROGRAM_PAGE }
