import { useState } from 'react'
import { useGetAgencyFeesStatus } from '../../../../bussines/hooks/fee/useGetAgencyFeesStatus'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { TableServicesStatus } from '../Tables/TableServicesStatus'

const URL_SERVICES_STATUS_PAGE = '/dashboard/agency/services-status'

const ServiceStatusPage = () => {
  const [searchFilter, setSearchFilter] = useState({})
  const { data: serviceList, isLoading: isLoadingList, isFetching, refetch } = useGetAgencyFeesStatus(searchFilter)

  return (
    <PageProtected permissionTypes={[userRoles.TYPE_AGENCY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content'>
          <h4 className='byp-title'>Agency's extras</h4>
          <br />
          <TableServicesStatus
            services={serviceList ?? []}
            isLoading={isLoadingList || isFetching}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            refetch={refetch}
          />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { ServiceStatusPage, URL_SERVICES_STATUS_PAGE }
