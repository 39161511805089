import { Button, Col, Row, Typography, notification } from 'antd'
import React, { useRef, useState, useEffect } from 'react'
import logo from '../../common/assets/images/book.png'
import { useQuote } from '../../bussines/hooks/quote/useQuote'
import LottieCargando from '../../lotties/LottieCargando'
import { QuoteOverviewCosts } from './components/QuoteOverviewCosts'
import { QuoteOverviewTotals } from './components/QuoteOverviewTotals'
import moment from 'moment'
import { Map } from '../../common/components/Map'
import { LocationIcon } from '../quote-preview/assets/imgs/Icons'
import { MdDownloadForOffline } from 'react-icons/md'
import generatePdf from './components/generatedPDF/generatePdf'
import { isMobile } from 'react-device-detect'
import { formatStartDate } from '../../utils/formatQuoteStartDate'
import { API_URL } from '../../config'

function ConfirmationQuoteInfo ({ id, btnRef }) {
  const pdfRef = useRef()
  // const isValidDate = (date) => {
  //   return moment(date, 'DD MMM, YYYY', true).isValid();
  // };
  const { isLoading, data: quote } = useQuote(id)
  const [isPdfMode, setIsPdfMode] = useState(false)

  useEffect(() => {
    if (isMobile) {
      setIsPdfMode(true) // Activa el modo PDF
      generatePdf(pdfRef.current, `Confirmación ${quote?.course?.name}.pdf`).then(() => {
        setIsPdfMode(false) // Desactiva el modo PDF una vez generado
      })
    }
  }, [quote])

  const handleFormatLodgingDates = (dateString) => {
    const date = moment(dateString)
    const nameDayWeek = date.format('dddd')
    const nameMonth = date.format('MMMM')
    const dateFormatted = `${nameDayWeek.charAt(0).toUpperCase() + nameDayWeek.slice(1)} ${date.format('DD')} de ${nameMonth.charAt(0).toUpperCase() + nameMonth.slice(1)}, ${date.format('YYYY')}`

    return dateFormatted
  }

  const [isDownloading] = React.useState(false)
  const [url] = React.useState(`${API_URL}/preview-pdf-confirmation/${id}`)

  if (isLoading || !quote) {
    return <LottieCargando />
  }
  const intakeDate = quote?.intake ? (quote?.intake + ' ' + quote?.year?.year) : formatStartDate(quote?.course?.start)
  const intakeDateEnd = quote?.intake ? (quote?.intake + ' ' + quote?.course?.length) : formatStartDate(quote?.course?.end)

  return (

    <Row justify='center' className={`confirmation-quote ${isPdfMode ? 'pdf-mode' : ''}`} ref={pdfRef}>
      <Col md={20} className='confirmation-quote__container'>
        <Row gutter={[16, 16]} justify='space-between' align='middle' className='confirmation-quote__header' style={{ marginBottom: '20px', marginTop: '30px' }}>
          <Col>
            <img src={logo} alt='BYP Book Your Program' width={250} />
          </Col>
        </Row>
        <Typography.Title className='byp-m-0'>Confirmación BYP</Typography.Title>

        <Row justify='space-between' className='byp-mb-1' align='middle'>
          <Col>
            <Col className='confirmation-quote__info'>
              <p className='byp-m-0'>Número de confirmación:  <span className='byp-m-0'>9698674</span></p>

            </Col>
            <Col className='confirmation-quote__info'>
              <p className='byp-m-0'>Código PIN: <span className='byp-m-0'>001</span></p>
            </Col>
          </Col>
          <Col>
            <a href={url} style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
              <Button
                // onClick={handleDownloadConfirm}
                size='small'
                type='primary'
                htmlType='submit'
                loading={isDownloading}
                ref={btnRef}
                // loading={loading}
                className='btn__register ignore-pdf'
                // disabled={!isFormFilled}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '25px'
                }}
              >
                {isDownloading ? 'Descargando...' : <><MdDownloadForOffline style={{ fontSize: '22px', marginRight: '5px', verticalAlign: 'middle' }} /> Descargar PDF</>}
              </Button>
            </a>

          </Col>
        </Row>

        <Row className='byp-mb-1'>
          <Col span={24} className='confirmation-quote__image'>
            <img src='https://images.unsplash.com/photo-1696229951930-df940c1e4255?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1933&q=80' />
          </Col>
        </Row>

        <Row gutter={[16, 16]} className='byp-mb-1'>
          <Col md={12} className='confirmation-quote__data'>
            <h1>{quote?.course?.name}</h1>
            <p className='school'><span>Escuela:</span> {quote?.provider?.campus?.name}</p>
            <p className='align_text-with-icon'><LocationIcon /> {quote?.provider?.campus?.address}</p>
          </Col>

          <Col md={12}>
            <div style={{ height: '100%', width: '100%' }}>
              <Map address={quote?.provider?.campus.address} coords={{ lat: quote?.provider?.campus?.city.coords?.lat, lng: quote?.provider?.campus?.city.coords?.lng }} addressChange={console.log} draggable={false} showInput={false} />
            </div>
          </Col>
        </Row>

        <Row className='byp-mb-1'>
          <Col span={24} className='antd-date'>
            <div className='cotizacion__start-end confirmation__dates'>
              <div>
                <label>Inicio de Clases</label>
                <p className='quote__border' style={{ padding: '3px' }}>{intakeDate}</p>
              </div>
              <div>
                <label>Termino de Clases</label>
                <p className='quote__border' style={{ padding: '3px' }}>{intakeDateEnd}</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='byp-mb-1'>
          <p className='confirmation-quote__duration'>
            Duración del curso:
            {quote?.course?.length
              ? (
                <span> {quote?.course?.length}</span>
                )
              : (
                <span> {quote?.course?.weeks} semanas</span>
                )}
          </p>
        </Row>

        <Row className='byp-mb-1'>
          <Col span={24}>
            <QuoteOverviewCosts
              data={quote.campus_costs}
              divisa={quote.provider.campus.divisa}
              type={quote.agent.type}
              agentDivisa={quote.agent.sede?.divisa}
            />
          </Col>
        </Row>

        <Row justify='space-between' className='byp-mb-1'>
          <Col>
            <h3>TOTAL</h3>
          </Col>
          <Col>
            <QuoteOverviewTotals
              totals={quote.cost.totals}
              discounts={quote?.discounts}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {quote?.lodging && (
            <Col md={12}>
              <div className='confirmation-quote__accomodation'>
                <p className='byp-m-0'>Sobre tu alojamiento</p>
                <p className='byp-m-0 type'>Tipo de hospedaje</p>
                <hr />
                <Row gutter={[16, 16]} justify='space-around'>
                  <Col span={11} className='confirmation-quote__date'>
                    <p className='byp-m-0'>Llegada</p>
                    <p className='byp-m-0'>{handleFormatLodgingDates(quote?.lodging?.start)}</p>
                  </Col>
                  <Col span={11} className='confirmation-quote__date'>
                    <p className='byp-m-0'>Salida</p>
                    <p className='byp-m-0'>{handleFormatLodgingDates(quote?.lodging?.end)}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          )}

        </Row>
      </Col>
    </Row>
  )
}

export default ConfirmationQuoteInfo
