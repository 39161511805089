import { Col, Form, Input, Row, Select } from 'antd'
import { useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

const { Search } = Input
const { Option } = Select

const FiltersTableBlogs = () => {
  const [searchFilter, setSearchFilter] = useState({})

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilters = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilters)
    }, 500)
  })

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...searchFilter,
      [key]: value
    }
    setSearchFilter(nFilters)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...searchFilter }
    delete nFilters[key]
    setSearchFilter(nFilters)
  }

  return (
    <Form>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={8} lg={8}>
          <Form.Item name='search'>
            <Search
              className='byp-input-red-50'
              placeholder='Buscar'
              onChange={(event) => debounceFilter('search', event.target.value)}
              allowClear
              size='large'
            />
          </Form.Item>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <Form.Item name='status' label='Estado'>
            <Select
              // style={{ width: '70%' }}
              placeholder='Todos'
              size='large'
              onChange={(e) => {
                e
                  ? handleAddFilter('status', e)
                  : handleRemoveFilter('status')
              }}
              className='w-100'
            >
              <Option key={0} value={null}>Todos</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <Form.Item name='place' label='Ubicación'>
            <Select
              // style={{ width: '70%' }}
              placeholder='Todos'
              size='large'
              onChange={(e) => {
                e
                  ? handleAddFilter('place', e)
                  : handleRemoveFilter('place')
              }}
              className='w-100'
            >
              <Option key={0} value={null}>Todos</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <Form.Item name='school' label='Escuela'>
            <Select
            placeholder='Todos'
              // style={{ width: '70%' }}
              size='large'
              onChange={(e) => {
                e
                  ? handleAddFilter('school', e)
                  : handleRemoveFilter('school')
              }}
              className='w-100'
            >
              <Option key={0} value={null}>Todos</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <Form.Item name='user' label='Autor'>
            <Select
              placeholder='Todos'
              size='large'
              onChange={(e) => {
                e
                  ? handleAddFilter('user', e)
                  : handleRemoveFilter('user')
              }}
              className='w-100'
            >
              <Option key={0} value={null}>Todos</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export { FiltersTableBlogs }
