import { useQuery } from 'react-query'
import OpinionRepository from '../../repositories/OpinionRepository'

function useFindOpinion (campus, course, filters = {}, enabled = true) {
  const params = { campus, course, ...filters }
  if (!course) {
    delete params.course
  }
  return useQuery(
    ['useFindOpinion', params],
    () => OpinionRepository.findOpinion(params),
    {
      retry: 1,
      enabled: !!campus && enabled
    }
  )
}

export { useFindOpinion }
