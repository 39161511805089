import React, { useEffect, useState } from 'react'
import { Alert, Avatar, Button, Card, Col, Collapse, Divider, Form, Input, Modal, notification, Row, Tooltip } from 'antd'
import { API_URL, URL_COPY } from '../../../config'
import { QuoteOverviewAssistCard } from './QuoteOverviewAssistCard'
import { QuoteOverviewCosts } from './QuoteOverviewCosts'
import { QuoteOverviewTotals } from './QuoteOverviewTotals'
import '../../../common/assets/css/main-theme.less'
import { CopyOutlined } from '@ant-design/icons'
import QRCode from 'react-qr-code'
import { useParams } from 'react-router-dom'
import { usePostSendQuoteEmail } from '../../../bussines/hooks/quote/usePostSendQuoteEmail'
import { useFetchUser } from '../../../bussines/hooks/user/useFetchUser'
import { QuoteOverviewAgencyCosts } from './QuoteOverviewAgencyCosts'
import { QuoteOverviewMedicalAgencyCosts } from './QuoteOverviewMedicalAgencyCost'
import { CoinsIcon } from '../assets/imgs/Icons'
import { useGetAgenExchangeValues } from '../../../bussines/hooks/currencies/useGetAgenExchangeValues'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { WithLoadignDates, WithOutLodging } from './QuoteOverviewDates'
// import { ApplyProgramModal } from './ApplyProgramModal'
import { ErrorBoundary } from '../../../providers/validation/ErrorBoundary'
import { QuoteOverviewHeader } from './QuoteOverviewHeader'

const { Panel } = Collapse

export const QuoteOverview = ({
  course, discounts, campus, lodging, cost, agent,
  quoteId, campusCosts, agency, assistCard, logo, intake, arrivalDate, t
}
) => {
  const { sendByEmail, isLoading, data: response, error } = usePostSendQuoteEmail()
  const { data: userData } = useFetchUser()
  const { data: exchangeValues, isLoading: loadingExchangeValues } = useGetAgenExchangeValues(agent?.profile?.user_id)

  const [modal, context] = Modal.useModal()
  const [mdl, setMdl] = useState(false)
  const [form] = Form.useForm()
  const [exchangeVal, setExchangeVal] = useState()
  const [costsInsuranceAgency, setCostsInsuranceAgency] = useState([])
  const [costsAdditionalServices, setCostsAdditionalServices] = useState([])

  const { id } = useParams()

  const handleSendEmail = () => {
    modal.confirm({
      title: 'Please, insert the email',
      content: (
        <Form form={form}>
          <Form.Item name='email'>
            <Input placeholder='email' />
          </Form.Item>
        </Form>
      ),
      onOk () {
        sendByEmail(quoteId, form.getFieldsValue())
      }
    })
  }

  const opnMdl = () => {
    setMdl(true)
  }

  const clsMdl = () => {
    setMdl(false)
  }

  useEffect(() => {
    if (!isLoading && response?.send) {
      notification.success({
        placement: 'top',
        message: 'Email send'
      })
    } else if (!isLoading && error) {
      notification.error({
        placement: 'top',
        message: error?.response?.data?.message ?? 'something went wrong, please, try again'
      })
    }
  }, [isLoading, response, error])

  useEffect(() => {
    if (!loadingExchangeValues && exchangeValues) {
      const found = exchangeValues.find((value) => value?.code === campus?.divisa)
      const valueFound = found?.exchanges_values?.find((values) => values.divisa_id === agency?.divisa_id)
      // console.log('🚀 ~ file: QuoteOverview.js:92 ~ useEffect ~ found:', found)
      setExchangeVal(valueFound?.value)
    }
    // eslint-disable-next-li ne react-hooks/exhaustive-deps
  }, [loadingExchangeValues, exchangeValues])

  const getExchangeVal = (currency) => {
    const found = exchangeValues?.find((value) => value?.code === currency)
    const valueFound = found?.exchanges_values?.find((values) => values.divisa_id === agency?.divisa_id)
    console.log('🚀 ~ file: QuoteOverview.js:95 ~ getExchangeVal ~ found:', currency, found)
    // return found?.exchange_value
    return valueFound?.value
  }

  function getDateComponent (lodging) {
    if (lodging) {
      if (course.program === 'graduate' || course.program === 'academic_years' || course.program === 'boarding_schools') {
        return (
          <WithLoadignDates
            lodging={lodging}
            courseStart={intake}
            courseEnd={course.length}
            program={course.program}
          />
        )
      }
      return (
        <WithLoadignDates
          lodging={lodging}
          courseStart={course.start}
          courseEnd={course.end}
          program={course.program}
        />
      )
    } else {
      if (course.program === 'graduate' || course.program === 'academic_years' || course.program === 'boarding_schools') {
        return <WithOutLodging courseStart={intake} courseEnd={course.length} program={course.program} />
      }
      return <WithOutLodging courseStart={course.start} courseEnd={course.end} program={course.program} />
    }
  }

  useEffect(() => {
    const resMedical = campusCosts?.filter(cost => {
      return cost?.isAgency === 'Agency' && cost?.type === 'insurance'
    })
    setCostsInsuranceAgency(resMedical)

    const divisasAdditional = {}
    campusCosts?.forEach(cost => {
      if (cost.isAgency === 'Agency' && cost.type === 'service') {
        if (divisasAdditional[cost.divisaAgency] === undefined) divisasAdditional[cost.divisaAgency] = []
        divisasAdditional[cost.divisaAgency].push(cost)
      }
    })
    setCostsAdditionalServices(divisasAdditional)
  }, [])

  return (
    <Card className='byp-course-overview-card ' bordered={false} style={{ height: '100%' }}>
      <Row style={{ padding: 0 }}>
        <Col
          xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}
          lg={{ span: course.program !== 'graduate' ? 12 : 15 }}
          span={lodging ? 8 : 16} style={{ display: 'flex', alignItems: 'center' }}
          className='byp-course-overview-card__gap'
        >
          <Avatar
            src={campus?.logo}
            style={{ minWidth: '35px', height: '35px', marginRight: '1rem' }}
            icon={<img src={logo} alt='' />}
          />
          <h3 className='byp-title-4 byp-text-primary byp-m-0'>
            {campus.name} <br /><small>{campus.group_name}</small>
          </h3>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: course.program !== 'graduate' ? 12 : 9 }} span={lodging ? 16 : 8}>
          <Row className='w-100 ant-card' style={{ paddingBottom: '7px' }}>
            <ErrorBoundary style={{ height: '100%' }}>
              {getDateComponent(lodging)}
            </ErrorBoundary>
          </Row>
        </Col>
      </Row>
      <Divider style={{ border: '1px solid #D4D4D4' }} />
      <QuoteOverviewHeader
        course={course}
        campus={campus}
        arrivalDate={arrivalDate}
      />
      <Collapse
        defaultActiveKey={['0', '2', 'agency_insurance_', 'additional_services']}
        expandIconPosition='left'
        className='course-prices'
      >
        <Panel
          header={'Cost of ' + campus.name}
          className='byp-p-0'
          key='0'
          extra={<span><CoinsIcon /> {cost.equivalence} </span>}
        >
          <QuoteOverviewCosts
            data={campusCosts}
            divisa={campus.divisa}
            type={agent.type}
            agentDivisa={agent.sede?.divisa}
            exchangeVal={exchangeVal}
            isLoading={loadingExchangeValues}
          />
        </Panel>
        {
          assistCard && (
            <Panel
              header='Assist Card'
              className='byp-p-0'
              key='1'
            >
              <QuoteOverviewAssistCard />
            </Panel>
          )
        }
        {
          agency?.services.length > 0 && (
            <Panel
              header={`Agency: ${agency?.name} cost`}
              className='byp-p-0'
              key='2'
              extra={<span><CoinsIcon /> {cost.equivalenceForServices} </span>}
            >
              <QuoteOverviewAgencyCosts
                data={agency?.services}
                divisa={agency?.divisa}
                userData={userData}
                agentData={agent}
                exchangeVal={exchangeVal}
                getExchangeVal={getExchangeVal}
              />
            </Panel>
          )
        }
        {
          Object.entries(costsAdditionalServices)?.length > 0 && Object.entries(costsAdditionalServices).map(([key, costAdditional]) => (
            <Panel
              header='Additional Services'
              className='byp-p-0'
              key='additional_services'
              extra={<span><CoinsIcon /> 1 {key} = {getExchangeVal(key)} {userData?.user?.divisa?.code} </span>}
            >
              <QuoteOverviewMedicalAgencyCosts
                data={costAdditional}
                userData={userData}
                agentData={agent}
                exchangeVal={exchangeVal}
                getExchangeVal={getExchangeVal}
              />
            </Panel>
          ))
        }
        {
          costsInsuranceAgency?.length > 0 && (
            <Panel
              header='Medical Insurance'
              className='byp-p-0'
              key='agency_insurance_'
              extra={<span><CoinsIcon /> {cost?.equivalencyForservicesMedical} </span>}
            >
              <QuoteOverviewMedicalAgencyCosts
                data={costsInsuranceAgency}
                userData={userData}
                agentData={agent}
                exchangeVal={exchangeVal}
                getExchangeVal={getExchangeVal}
              />
            </Panel>
          )
        }
      </Collapse>
      <Row>
        <Col span={24}>
          <QuoteOverviewTotals
            totals={cost.totals}
            divisa={campus.divisa}
            agentDivisa={agent.sede?.divisa}
            agent={agent}
            exchangeVal={exchangeVal}
            className='quote-overview-totals'
            soloTotals
          />
        </Col>
      </Row>
      <Row style={{ display: (course.program === 'language' || course.program === 'summer_camps') ? 'none' : '' }}>
        <Col>
          <p className='quote-he-txt-end byp-m-0 byp-mt-1'>
            These prices and start dates are only for refererence. The final price is when you get the letter of acceptance.
            To apply and be accepted you must have all the requirements from the institution.<br />
            Places are subject to change to college availability. The cost is only for the first year or first semester program
          </p>
        </Col>
      </Row>
      <Divider style={{ margin: '1rem 0' }} />
      <Row style={{ marginTop: '1rem' }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }} xxl={{ span: 5 }}>
          <div className='align-items description'>
            <QRCode value={`${process.env.REACT_APP_URL}/quote-preview/${id}`} size={110} level='H' />
            <Button id='btn-download-2' className='byp-btn-yellow btn-apply' style={{ width: '100%', marginRight: 'auto' }}>
              <a href={`${API_URL}/preview-pdf/${quoteId}`} style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer' className='quote-overview--color-blue'>
                Download PDF
              </a>
            </Button>
          </div>
        </Col>
        <Col className='byp-rsp-sep' xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 19 }} xl={{ span: 19 }} xxl={{ span: 19 }}>
          {
            discounts.length > 0 && (
              <>
                {discounts.map((disc) => {
                  return disc.discounts_expiration?.map((expiration) => (
                    <p key={'exp_' + expiration.name} className='txt-color-red'>{expiration.name} APPLYING BEFORE: {expiration.end}</p>
                  ))
                })}
                <QuoteOverviewTotals
                  totals={discounts}
                  divisa={campus.divisa}
                  agentDivisa={agent.sede?.divisa}
                  agent={agent}
                  exchangeVal={exchangeVal}
                  className='quote-overview-totals-discounts'
                />
              </>
            )
          }
          <Alert className='quote-alert byp-rsp-sep' message='All prices are subject to change without previous notice. Prices in local currency for reference only.' type='info' />
        </Col>
      </Row>
      <Divider style={{ margin: '1rem 0' }} />
      <Row className='byp-mt-1 buttons-quote' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col
          className='byp-rsp-sep'
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={course?.link ? { span: 6, order: 0 } : { span: 8, order: 0 }}
          lg={course?.link ? { span: 6, order: 0 } : { span: 8, order: 0 }}
          xl={course?.link ? { span: 6, order: 0 } : { span: 8, order: 0 }}
          xxl={course?.link ? { span: 6, order: 0 } : { span: 8, order: 0 }}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button id='btn-download' className='byp-btn-yellow btn-apply' style={{ width: '100%', marginRight: 'auto' }}>
            <a href={`${API_URL}/preview-pdf/${quoteId}`} style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer' className='quote-overview--color-blue'>
              Download PDF
            </a>
          </Button>
          {/*
            <Button className='byp-btn-blue-100-outline responsive-text'>
              <a href={`${API_URL}/preview-pdf/${quoteId}`} target='_blank' rel='noopener noreferrer' className='quote-overview--color-blue'>
                Payment plan
              </a>
            </Button>
          */}
        </Col>
        <Col
          xs={{ span: 24, order: 0 }}
          sm={{ span: 24, order: 0 }}
          md={course?.link ? { span: 18, order: 0 } : { span: 16, order: 0 }}
          lg={course?.link ? { span: 18, order: 0 } : { span: 16, order: 0 }}
          xl={course?.link ? { span: 18, order: 0 } : { span: 16, order: 0 }}
          xxl={course?.link ? { span: 18, order: 0 } : { span: 16, order: 0 }}
        >
          <Row gutter={[10, 10]} justify='end'>
            {course?.link && (
              <Col xs={12} sm={6}>
                <a
                  className='ant-btn byp-btn-blue-100-outline w-100 byp-mr-1 btn-apply '
                  href={'https://' + course?.link}
                  target='_blanck'
                  rel='noopener noreferrer'
                  style={{ paddingLeft: '3px', paddingRight: '3px', margin: '0', width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                > Program Link
                </a>
              </Col>
            )}
            {
              campus.website && (
                <Col xs={12} sm={6}>
                  <a
                    className='ant-btn byp-btn-blue-100 w-100'
                    href={'https://' + campus.website}
                    target='_blank'
                    rel='noreferrer'
                  >School link
                  </a>
                  {/* <Button
                    className='byp-btn-blue-100-outline responsive-text btn-apply'
                    block
                    style={{ paddingLeft: '3px', paddingRight: '3px', margin: '0' }}
                  >
                    School link
                  </Button> */}
                </Col>
              )
            }
            <Col xs={(campus?.website || course?.link) ? 12 : 9} sm={6}>
              <Button
                className='byp-btn-blue-100-outline btn-apply'
                block
                onClick={opnMdl}
                style={{ paddingLeft: '3px', paddingRight: '3px', margin: '0' }}
              >
                Share Quote
              </Button>
            </Col>
            <Col xs={(campus?.website || course?.link) ? 12 : 9} sm={6}>
              <Button
                style={{ margin: '0', paddingLeft: '3px', paddingRight: '3px' }}
                className='byp-btn-blue-100-outline btn-apply' block onClick={handleSendEmail}
                loading={isLoading}
              >
                Send Quote
              </Button>
            </Col>
            <Col xs={(campus?.website && course?.link) ? 24 : (campus?.website || course?.link) ? 12 : 6} sm={4}>
              {/* <ApplyProgramModal
                quoteUuid={quoteId}
                courseName={course.name}
                campusName={campus.name}
                logo={
                  <Avatar
                    src={campus?.logo} icon={<img src={logo} alt='logo' />}
                    style={{ minWidth: '45px', height: '45px', marginRight: '2rem' }}
                  />
                }
              /> */}
            </Col>
          </Row>

          <Modal
            title='Share'
            visible={mdl}
            footer=''
            onCancel={clsMdl}
          >
            <Form>
              <Col className='d-flex justify-center' style={{ gap: '1rem' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ margin: 20 }}>
                    <Avatar
                      src={campus?.logo}
                      style={{ minWidth: '80px', height: '80px' }}
                      icon={<img src={logo} alt='' />}
                    />
                  </div>
                  <div>
                    <p style={{ fontSize: 20 }}>{campus?.name}, {course?.name}</p>
                    <p>{course?.description}</p>
                  </div>
                </div>
              </Col>
              <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <Input.Group compact>
                  <Input
                    style={{
                      width: 'calc(120% - 200px)'
                    }}
                    defaultValue={`${URL_COPY}/quote-preview/${quoteId}`}
                    readOnly='true'
                  />
                  <Tooltip title='copy and share'>
                    <CopyToClipboard text={`${URL_COPY}/quote-preview/${quoteId}`}>
                      <Button icon={<CopyOutlined />} style={{ width: '40px', height: '40px' }} onClick={() => notification.info({ message: 'Texto copiado' })} />
                    </CopyToClipboard>
                    {/* <Button icon={<CopyOutlined /> } style={{width:'40px', height:'40px'}} onClick={copyLink}></Button> */}
                  </Tooltip>
                </Input.Group>
              </div>
            </Form>
          </Modal>
        </Col>
      </Row>
      {context}
    </Card>
  )
}
