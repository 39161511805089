import { Table } from "antd";
const { Column } = Table;

const QuoteOverviewAssistCard = ({ data, divisa }) => {
    return (
        <Table
            dataSource={data ?? []}
            className="byp-table-clear"
            bordered
            rowKey="name"
            pagination={{
                hideOnSinglePage: true,
            }}
        >
            <Column title="Concepto" dataIndex="" key=""/>
            <Column title="Duration" dataIndex="" key=""/>
            <Column title="CAD" dataIndex="" key=""/>
            <Column title ="Aprox MXN" dataIndex="" key=""/>
        </Table>
    );
};

export { QuoteOverviewAssistCard };
