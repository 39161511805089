export const getDiscountSymbol = (value, type, currency) => {
  if (type === 'percentage') {
    return value + '%'
  }

  return value + ' ' + currency
}

export const handlePrice = (discount, value) => {
  return (value * discount) / 100
}
