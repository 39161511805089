import { useQuery } from 'react-query'
import ProgramRepository from '../../repositories/ProgramRepository'

function useGetDiplomaSubtypes (filters = {}, enabled = true) {
  return useQuery(['useGetDiplomaSubtypes', filters], () => ProgramRepository.diplomaSubtypes(filters), {
    retry: 2,
    enabled
  })
}

export { useGetDiplomaSubtypes }
