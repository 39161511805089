import { Col, Row, Form, Select } from 'antd'
import { useGetPackages } from '../../../bussines/hooks/packages/useGetPackages'
import { useState } from 'react'
import { useGetPackageYears } from '../../../bussines/hooks/packages/useGetPackageYears'

export function SelectPackageFormItem ({ setYearId, form, ...props }) {
  const yearIdMap = {}
  const { Option } = Select
  const [packageId, setPackageId] = useState()
  const { data: packages, isLoading } = useGetPackages()
  const { data: years, isLoading: isLoadingYears } = useGetPackageYears(packageId)

  const handleYearChange = (value, option) => {
    const year = option.children
    const yearId = yearIdMap[year]

    if (setYearId) {
      setYearId({ year, yearId })
    }
  }
  return (
    <Row gutter={16}>
      <Col span={10}>
        <Form.Item name='package' label='Package name'>
          <Select className='byp-m-0' placeholder='Package name' size='small' loading={isLoading} onChange={setPackageId}>
            {packages?.data?.map((packageItem) => (
              <Option key={packageItem.id} value={packageItem.id}>{packageItem.label}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name='year' label='Year'>
          <Select className='byp-m-0' placeholder='2023' size='small' loading={isLoadingYears} onChange={handleYearChange}>
            {years?.map((year) => {
              yearIdMap[year.year] = year.id

              return (
                <Option key={year.id} value={year.year}>{year.year}</Option>
              )
            })}
          </Select>
        </Form.Item>
      </Col>
      {/* <Col>
        <Row>
          <label style={{ marginTop: 6, marginRight: 6 }}>Currency: </label>
          <Form.Item name='currency'>
            <Select className='byp-m-0' placeholder='Currency' size='small' disabled>
              <Option value='CAD'>(CAD) Canadian</Option>
            </Select>
          </Form.Item>
        </Row>
      </Col> */}
    </Row>
  )
}
