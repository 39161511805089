import { Switch } from 'antd'
import React from 'react'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
// import { ButtonSwitch } from './ButtonSwitch'

const calculateMinorCost = (cost, way) => {
  if (way === 'One Way') return cost
  return cost * 2
}
function TransportMinorCosts () {
  const { service, formConfig, setFormConfig } = useQuoter()

  const handleChange = (value, id, cost, frequency) => {
    let transportMinorCosts = []
    if (!formConfig.transportMinorCosts) {
      setFormConfig({
        ...formConfig,
        transportMinorCosts: []
      })
    }
    if (value) {
      transportMinorCosts = formConfig.transportMinorCosts ?? []
      transportMinorCosts?.push({ id, cost, frequency })
    } else {
      formConfig.transportMinorCosts?.map((fee) => (
        fee.id !== id ? transportMinorCosts.push(fee) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      transportMinorCosts
    })
  }

  return (
    <>
      {service.getTransportMinorCosts()?.map((item) => (
        <RowQuoter
          key={item.id}
          colA={
            <>
              <p>
                {item.name}
              </p>
            </>
          }
          colB={
            <StatisticWithDiscounts
              discounts={0}
              amount={
                service.isTransportMinorCostChecked(item.id)?.id ? calculateMinorCost(item.cost, formConfig.transportWaySelected) : 0
              }
              currency={service.getCurrency()}
              subtotal={service.calculateCost(item.cost, item.frequency)}
              active={service.isTransportMinorCostChecked(item.id)}
            />
          }
          colC={
            <Switch
              onChange={(value) => { handleChange(value, item.id, item.cost, item.frequency) }}
              className='byp-ml-1'
              checked={service.isTransportMinorCostChecked(item.id)}
              // checkedChildren='YES'
              // unCheckedChildren='NO'
            />
            // <ButtonSwitch isActive={service.isTransportMinorCostChecked(item.id)} onChange={handleChange} item={item} />
          }
        />
      ))}
    </>
  )
}

export { TransportMinorCosts }
