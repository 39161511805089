import { Form, Select } from 'antd'
const { Option } = Select
const SelectExtracurricularFormItem = ({ name, label, ...rest }) => {
  const extracurricular = ['History', 'Oral history', 'Chinese', 'German', 'French Literature', 'German Literature', 'Chemistry', 'Arts', 'Biology', 'Physics', 'Geography', 'Russian', 'Latin', 'Economics', 'Literature', 'English', 'English Literature', 'Political', 'Science', 'Mathematics', 'Business', 'Japanese', 'Typing', 'Kung Fu', 'Choir', 'Arts & Crafts', 'Trampoline', 'Hip Hop', 'Cooking', 'Baking', 'Portuguese', 'Religion', 'Ehtics', 'Social', 'Studies', 'Psychology', 'Computer', 'Native language', 'Sport', 'Theory', 'Watersports', 'Carpentry', 'Painting', 'Bookbinding', 'Gardening',
    'AP program', 'Astronomy', 'Audiovisual', 'Aviation',

    'Band',
    'Knitting',
    'Italian',
    'Guitar lessons',
    'Accounting',

    'Book Club',

    'Chorus',

    'Conservation Club',

    'Cosmetology',

    'Creative Writting',

    'Culinary Arts',

    'Dance',

    'Debate',

    'Digital Media',

    'Drama',

    'Duke of Edinburgh Award',

    'Dungeons & Dragons',

    'Environmental Club',

    'E-Sports',

    'Fashion Design',

    'Film',

    'French',

    'Gaming',

    'Health Sciences',

    'IB program',

    'Improv',

    'Information Technology',

    'Law',

    'Leadership Team',

    'Math Club',

    'Marine',

    'Mechanics',

    'Mock Trial',

    'Model UN',

    'Music',

    'Musical Theather',

    'National Honor Society',

    'Newspaper',

    'Orchestra',

    'Outdoor Education',

    'Philosophy',

    'Photography',

    'Plastic Arts',

    'Poetry',

    'Pottery',

    'Programming',

    'Robotic',

    'Spanish',

    'Student Council',

    'Textile Art',

    'Theater',

    'Yearbook',
    'Pysical Education',
    'Natural Science',
    'Design Technology',
    'Environmental Systems & Societies',
    'Visual Art',
    'Anime',
    'Board Games',
    'Greek',
    'Animation',
    'Graphic Design',
    'Public Speaking',
    'K-Pop',
  ]
  return (
    <Form.Item
      name={name}
      label={label}
    >
      <Select
        className='w-100'
        {...rest}
      >
         {extracurricular.sort().map((item) =>
          <Option key={`${item}`} >{item} </Option>)}
      </Select>
    </Form.Item>
  )
}

export { SelectExtracurricularFormItem }
