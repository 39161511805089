import { Col, Divider, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useFindUser } from '../../../../../bussines/hooks/user/useFindUser'
import MostPopularPrograms from '../general/MostPopularPrograms'
import MostPopularSchools from '../components/MostPopularSchools'
import TimeFilters from '../TimeFilters'
import MostPopularCities from './MostPopularCities'
import MostPopularCourses from '../components/MostPopularCourses'
import PopularRangeTime from '../components/PopularRangeTime'
import ProgramAverage from '../components/ProgramAverage'
import SchoolAverage from '../components/SchoolAverage'
import TotalQuotes from './TotalQuotes'
import UserInfo from './UserInfo'
import { UserSelects } from './UserSelects'
import { useLocation, useNavigate } from 'react-router-dom'
import { URL_USER_ANALYSIS_PAGE } from './UserAnalysisPage'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import { SelectAllUsersFormItem } from '../../../../../common/components/helper-form/SelectAllUsersFormItem'

function UserAnalysisInfo ({ idUser, nameRole, idSede, stateSede }) {
  const { user: _user } = useAuth()

  const [time, setTime] = useState({})
  const [user, setUser] = useState()
  const [sede, setSede] = useState()
  const [nameSede, setNameSede] = useState()
  const [role, setRole] = useState()
  const [activeCategory, setActiveCategory] = useState('')

  const location = useLocation()

  const { data: userData, isLoading, error, refetch } = useFindUser(user)
  const [, setOrderby] = useState()

  const navigate = useNavigate()

  const { menuItemSelected } = (location.state) ?? '0'

  const [isActiveTotalQuote, setIsActiveTotalQuote] = useState(menuItemSelected === 'reports')

  const { IDUSER, NAMEROLE, IDSEDE, SEDE } = location.state;

  useEffect(() => {
    if (isActiveTotalQuote) {
      navigate(URL_USER_ANALYSIS_PAGE, { state: { menuItemSelected: 'reports' } })
    } else {
      if (!menuItemSelected || menuItemSelected === 'reports') {
        navigate(URL_USER_ANALYSIS_PAGE, { state: { menuItemSelected: 'reports' } })
      } else {
        navigate(URL_USER_ANALYSIS_PAGE, { state: { menuItemSelected: 'user-analysis', openSub: 'analitic', idUser, nameRole, idSede, sede: stateSede } })
      }
    }
  }, [isActiveTotalQuote, navigate])

  useEffect(() => {
    setIsActiveTotalQuote(menuItemSelected === 'reports')
  }, [menuItemSelected])

  useEffect(() => {
    if (!isLoading) {
      if (userData) {
        // console.log('🚀 ~ file: UserAnalysisInfo.js:11 ~ useEffect ~ userData', userData)
      } else if (error) {
        console.log('🚀 ~ file: UserAnalysisInfo.js:13 ~ useEffect ~ error', error)
      }
    }
  }, [isLoading, userData, error])

  useEffect(() => {
    if (nameRole && idSede && idUser && stateSede) {
      setSede(idSede);
      setNameSede(stateSede);
      setRole(nameRole);
      setUser(idUser);
    } 
    else if (IDUSER !== undefined && NAMEROLE !== undefined && IDSEDE !== undefined && SEDE !== undefined) {
      setSede(IDSEDE);
      setNameSede(SEDE);
      setRole(NAMEROLE);
      setUser(IDUSER);
    }
    else {
      setSede(_user?.sede);
      setNameSede(_user?.sedes[0]);
      setRole(_user?.role_names[0]);
      setUser(_user?.id);
    }
  }, [_user, nameRole, idSede, idUser, stateSede]);

  return (
    <>
      <Row className='byp-dashboard-content'>
        <Col span={24}>
          <Row className='byp-mb-1'>
            <Col span={20} className='d-flex align-center'>
              <h3 className='byp-title'>User analysis</h3>
              {nameSede && <span className='byp-title byp-text-lg' style={{ marginLeft: '0.5rem' }}>({nameSede})</span>}
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              {/* {isActiveTotalQuote && <Button className='byp-btn-blue-200' onClick={handleCancel}>Go Back</Button>} */}
            </Col>
          </Row>

          <Row gutter={16} className='byp-mb-1'>
            <Col span={16}>
              {_user?.role_names?.includes('super-admin') && (
                <Row>
                  <Col className='selects-responsives-column' >
                    <SelectAllUsersFormItem
                      className='user-analysis__select'
                      onChange={(user) => {
                        setUser(user)
                      }}
                    />
                  </Col>
                </Row>
              )}
              {!_user?.role_names?.includes('super-admin') && (
                <UserSelects
                  setUser={setUser}
                  user={user}
                  setRole={setRole}
                  role={role}
                  setSede={setSede}
                  sede={sede}
                  setNameSede={setNameSede}
                  nameSede={nameSede}
                  roleType={_user?.role_names}
                />
              )}
            </Col>

            <Col className='time-filter'>
              <TimeFilters setTime={setTime}  />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col className='two-thirds-responsive'>
              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>User info</h4>
              <UserInfo user={userData} refetch={refetch} isLoadingUser={isLoading} noEdit />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Total quotes</h4>
              <TotalQuotes time={time} user={user} setActiveCategory={setActiveCategory} activeCategory={activeCategory} setIsActiveTotalQuote={setIsActiveTotalQuote} setOrderby={setOrderby} isMyReports={role && role.includes("agency")} isAgency isEducationGroup={_user.type==='Education group'}/>
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              {/* {isActiveTotalQuote && <TableTotalQuote activeCategory={activeCategory} user={user} time={time} setOrderby={setOrderby} orderby={orderby} />} */}

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most Popular Programs</h4>
              {user && <MostPopularPrograms time={time} user={user} />}
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              <div className={_user.type === 'Education group' ? 'byp-d-none' : ''}>
                <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most Popular Schools</h4>
                {user && <MostPopularSchools time={time} user={user} />}
                <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
              </div>

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most popular courses</h4>
              <MostPopularCourses time={time} user={user} />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Popular range of time</h4>
              <PopularRangeTime time={time} user={user} />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most popular cities</h4>
              <MostPopularCities time={time} user={user} />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
            </Col>

            <Col className='one-third-responsive'>
              <div className='user-average'>
                <h4 className='byp-title-4 byp-mb-1 byp-title byp-fw-bold'>Program Average</h4>
                <ProgramAverage firstOption time={time} user={user} />

                <h4 className='byp-title-4 byp-mb-1 byp-mt-1 byp-title byp-fw-bold'>School Average</h4>
                <SchoolAverage firstOption time={time} user={user} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export { UserAnalysisInfo }
