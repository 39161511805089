import { Col, Form, Modal, Row, Select } from 'antd'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { SelectProgramsFormItem } from '../../../../common/components/helper-form/SelectProgramsFormItem'
import { SelectCitiesWithOffers } from '../../../../common/components/places-helper-form/SelectCitiesWithOffers'
import { useGetCampusWithOffers } from '../../../../bussines/hooks/places/useGetCampusWithOffers'
import { useGetInstitutions, useGetServices } from '../../../../bussines/hooks/page/useGetServices'
import { useGetCountriesWithOffers } from '../../../../bussines/hooks/places/useGetCountriesWithOffers'

const { Option } = Select

function ModalAddCourse ({ isModalOpen, handleOk, handleCancel, country, city, setCourse, idSercices, isEditBlog }) {
  const [courseFilters, setCourseFilters] = React.useState({ hasOffers: true, country, city })
  const [code, setCode] = useState(country)
  const [search] = useState()
  const [countryFilters] = useState({ onlyCountries: true })

  const form = Form.useFormInstance()
  const programSelected = Form.useWatch('program', form)

  const { data } = useGetServices({ ...courseFilters, exclude_services: idSercices })
  const { data: institutionsData } = useGetInstitutions({ ...courseFilters, program: programSelected })
  const { data: campusData } = useGetCampusWithOffers({ ...courseFilters })
  const { isLoading, data: countries } = useGetCountriesWithOffers(search, { ...courseFilters, program: programSelected, ...countryFilters })

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      if (key === 'country') {
        setCode(value)
      }
    }, 500)
  })

  const handleChange = (value) => {
    if (value) {
      const courses = data?.find((course) => value === course.id)
      setCourse(courses)
      setCourseFilters({ hasOffers: true, country, city })
    }
  }

  return (
    <Modal
      title='Paquetes'
      visible={isModalOpen}
      width={360}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='Guardar'
      cancelText='Cancelar'
      className='modal__add-blog'
    >
      <h5>Mostrar Programas</h5>
      <Row>
        <Col span={24}>
          <label className='blog__modal-edit'>Program</label>
          <SelectProgramsFormItem
            name='program'
            filters={{ city, country }}
            selectProps={{
              className: 'edit-programs-blog__select'
            }}
          />
        </Col>

        {isEditBlog && (
          <Col span={24}>
            <label className='blog__modal-edit'>Pais</label>
            <Form.Item
              name='countries'
            >
              <Select
                className='edit-programs-blog__select'
                loading={isLoading}
                onSearch={(value) => {
                  debounceFilter('country', value)
                }}
                onChange={(values) => {
                  setCode(values)
                }}
                filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
              >
                {countries?.map((country) => (
                  <Option
                    key={country.id}
                    value={country.iso3}
                  >{country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {!city && (
          <Col span={24}>
            <label className='blog__modal-edit'>Ciudad</label>
            <SelectCitiesWithOffers
              name='city'
              filters={{
                countries: [code], program: programSelected
              }}
              selectProps={{
                className: 'edit-programs-blog__select',
                allowClear: true,
                showSearch: true,
                // mode: 'multiple',
                onChange: (val) => {
                  console.log('🚀 ~ file: EditProgramsBlog.js:146 ~ val:', val)
                  // setCampusFilters({ cities: val, whereHasOffers: true })
                  setCourseFilters({
                    ...courseFilters,
                    city: val
                  })
                }
              }}
            />
          </Col>
        )}
        <Col span={24}>
          <label className='blog__modal-edit'>Escuela</label>
          <Form.Item
            name='school'
          >
            <Select
              className='edit-programs-blog__select'
              onChange={(val) => {
                setCourseFilters({
                  ...courseFilters,
                  institution: val
                })
              }}
              filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
              showSearch
            >
              {institutionsData?.institutions?.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                >{item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <label className='blog__modal-edit'>Campus</label>
          <Form.Item
            name='campus'
          >
            <Select
              className='edit-programs-blog__select'
              onChange={(val) => {
                setCourseFilters({
                  ...courseFilters,
                  campus: val
                })
              }}
              filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
              showSearch
            >
              {campusData?.map((campus) => (
                <Option
                  key={campus.id}
                  value={campus.id}
                >{campus.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <label className='blog__modal-edit'>Cursos</label>
          <Form.Item
            style={{ margin: '0' }}
            name='courses'
          >
            <Select
              className='edit-programs-blog__select'
              onChange={handleChange}
              filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
              showSearch
            >
              {data?.map(service => (
                <Option value={service.id} key={service.id}>{service.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <a>
          <Link to='/'>
            Activar nueva curso
          </Link>
        </a>
      </Row>
    </Modal>
  )
}

export default ModalAddCourse
