import React, { useEffect, useState } from 'react'
import { AirPlaneIcon } from '../../quote-preview/assets/imgs/Icons'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useGetPublicity } from '../../../bussines/hooks/publicity/useGetPublicity'

export const FooterQuote = () => {
  const [image, setImage] = useState()
  const [text, setText] = useState()
  const [link, setLink] = useState()
  const { data } = useGetPublicity()

  function addHttpsIfNotExist (url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    return url
  }

  useEffect(() => {
    if (data && data?.publicityHorizontal && data?.publicityHorizontal?.length > 0) {
      if (data?.publicityHorizontal[0]?.title) setText(data?.publicityHorizontal[0]?.title)
      if (data?.publicityHorizontal[0]?.src) setImage(data?.publicityHorizontal[0]?.src)
      if (data?.publicityHorizontal[0]?.link) setLink(addHttpsIfNotExist(data?.publicityHorizontal[0]?.link))
    }
  }, [data])

  return (
    <>
      {image && (
        <a href={link} target='_blank' rel='noreferrer'>
          <div className='travel__footer' style={{ backgroundImage: `url(${image})`, marginTop: '1.75rem' }}>
            <AirPlaneIcon />
            <div>
              <h4>{text ?? 'Discover the world, and learn in the process'}</h4>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p className='byp-m-0'>1,925 different course</p>
                <ArrowRightOutlined />
              </div>
            </div>
          </div>
        </a>
      )}
    </>
  )
}
