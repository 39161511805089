import * as React from 'react'

export const ArtIcon = (props) => {
  return (
    <svg
      width={11}
      height={10}
      viewBox='0 0 11 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.985.142c.707.238-1.365 1.407-.07 2.191C5.643 2.774 7.232.78 8.03.331c.679-.378 1.869-.455 2.47.861.764 1.666.099 6-2.652 7.742-2.78 1.75-6.28.861-7.49-1.862C-1.05 3.902 2.542-.69 4.985.142zm.847 4.515c.51 1.148 3.29-.35 2.653-1.96-.413-1.043-3.136.875-2.653 1.96z'
        fill='currentColor'
      />
    </svg>
  )
}

export const FoodIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_4669)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M10.5 1.75a.583.583 0 01.58.515l.003.068v9.334a.583.583 0 01-1.162.068l-.005-.068V8.75h-.583a.583.583 0 01-.58-.515l-.003-.068v-3.5c0-1.29.875-2.917 1.75-2.917zm-3.5 0a.583.583 0 01.58.515l.003.068V5.25a2.335 2.335 0 01-1.75 2.26v4.157a.583.583 0 01-1.162.068l-.005-.068V7.51A2.334 2.334 0 012.92 5.37l-.002-.12V2.333a.583.583 0 011.162-.068l.004.068V5.25a1.167 1.167 0 00.583 1.01V2.333a.583.583 0 011.163-.068l.004.068V6.26a1.167 1.167 0 00.58-.911l.003-.099V2.333A.583.583 0 017 1.75z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_4669'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ToursIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_4001)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M7.875 3.208a1.17 1.17 0 001.167-1.166A1.17 1.17 0 007.875.875a1.17 1.17 0 00-1.167 1.167 1.17 1.17 0 001.167 1.166zM5.717 5.192l-1.634 8.225h1.225l1.05-4.667 1.225 1.167v3.5H8.75V9.042L7.525 7.875l.35-1.75a4.271 4.271 0 003.208 1.458V6.417c-1.108 0-2.041-.584-2.508-1.4l-.583-.934C7.758 3.733 7.408 3.5 7 3.5c-.175 0-.292.058-.467.058L3.5 4.842v2.741h1.167V5.6l1.05-.408z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_4001'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const EntertainmentIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_4675)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M8.476.846l-.63.63.933.933a.745.745 0 01.193.508.7.7 0 01-.193.501l-2.07 2.106.583.63 2.117-2.106c.31-.344.46-.723.46-1.131 0-.409-.15-.794-.46-1.138L8.476.846zM6.154 2.024l-.63.63.356.327c.128.128.192.303.192.52 0 .215-.064.39-.192.518l-.356.327.63.63.327-.356c.309-.344.466-.717.466-1.12 0-.42-.157-.799-.466-1.149l-.327-.327zm6.096.928c-.403 0-.776.157-1.12.466l-3.284 3.29.63.584 3.255-3.244a.715.715 0 011.038 0l.356.356.6-.63-.326-.356c-.344-.309-.73-.466-1.15-.466zM4.083 4.667l-2.917 8.166 8.167-2.916-5.25-5.25zm7 1.785c-.408 0-.781.157-1.132.466l-.927.928.63.63.928-.928a.687.687 0 01.501-.221c.193 0 .368.076.514.221l.944.928.613-.6-.933-.958c-.345-.309-.73-.466-1.138-.466z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_4675'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const SportsIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_4681)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M11.608 2.888c.236.28.43.583.583.901h-.001a1.4 1.4 0 01-1.701.218l1.119-1.12zm-.495-.495L9.994 3.51a1.4 1.4 0 01.216-1.7l.001-.001c.325.154.627.35.902.583zm.732 5.612a4.205 4.205 0 00.606-3.517 2.102 2.102 0 01-2.467.024l-.817.815 2.678 2.677zM9.489 4.017a2.101 2.101 0 01.024-2.468 4.204 4.204 0 00-3.517.607l2.676 2.677.817-.816zM5.455 2.606l-.024.024c-.502.5-.868 1.12-1.065 1.8.323.098.657.214.989.347.724.291 1.495.69 2.134 1.24l.689-.69-2.723-2.721zM9.582 9.63a4.181 4.181 0 001.788-1.06l.025-.026-2.723-2.722-.686.686c.567.648.972 1.433 1.265 2.17.126.32.236.642.33.952zm-.308 1.799A1.19 1.19 0 017.8 12.588c-1.228-.277-3.172-.865-4.332-2.025-1.157-1.157-1.755-3.109-2.04-4.345a1.287 1.287 0 01-.032-.291 1.192 1.192 0 011.459-1.162 13.64 13.64 0 012.24.662c.787.316 1.582.749 2.172 1.338.59.59 1.02 1.386 1.333 2.173.314.79.518 1.597.647 2.233.018.087.027.174.027.259zM4.798 7.803a.35.35 0 00-.495.495l1.4 1.4a.35.35 0 10.495-.495l-1.4-1.4z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_4681'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const PlacesIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_1606)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M7 0L.933 3.267v.466h12.133v-.466L7 0zM1.866 4.667v4.666l-.933 1.494v1.306h12.133v-1.306l-.933-1.494V4.667H1.866zm1.867.933h.933v5.133h-.933V5.6zm2.8 0h.933v5.133h-.933V5.6zm2.8 0h.933v5.133h-.933V5.6z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_1606'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const NatureIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_1612)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M9.894 7h.046c.473 0 .747-.53.479-.916L7.454 1.855a.583.583 0 00-.956 0l-2.94 4.23A.585.585 0 004.043 7h.023L2.374 9.602a.58.58 0 00.49.898h2.964v1.178c0 .636.519 1.155 1.155 1.155s1.155-.519 1.155-1.155V10.5h3.004a.58.58 0 00.484-.904L9.894 7z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_1612'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const EmailIcon = (props) => {
  return (
    <svg
      width={12}
      height={10}
      viewBox='0 0 12 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.107.875h-9.75a.375.375 0 00-.375.375v7.5c0 .207.168.375.375.375h9.75a.375.375 0 00.375-.375v-7.5a.375.375 0 00-.375-.375zm-.946 1.276L6.463 5.028a.252.252 0 01-.31 0L2.454 2.151A.084.084 0 012.506 2h7.603a.084.084 0 01.052.151z'
        fill='#fff'
      />
    </svg>
  )
}

export const WhatsapIcon = (props) => {
  return (
    <svg
      width={11}
      height={10}
      viewBox='0 0 11 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.312 2.42C7.872.2 4.932-.46 2.652.92c-2.22 1.38-2.94 4.38-1.5 6.6l.12.18-.48 1.8 1.8-.48.18.12c.78.42 1.62.66 2.46.66.9 0 1.8-.24 2.58-.72 2.22-1.44 2.88-4.38 1.5-6.66zm-1.26 4.62c-.24.36-.54.6-.96.66-.24 0-.54.12-1.74-.36-1.02-.48-1.86-1.26-2.46-2.16-.36-.42-.54-.96-.6-1.5 0-.48.18-.9.48-1.2.12-.12.24-.18.36-.18h.3c.12 0 .24 0 .3.24.12.3.42 1.02.42 1.08.06.06.06.18 0 .24.06.12 0 .24-.06.3s-.12.18-.18.24c-.12.06-.18.18-.12.3.24.36.54.72.84 1.02.36.3.72.54 1.14.72.12.06.24.06.3-.06s.36-.42.48-.54c.12-.12.18-.12.3-.06l.96.48c.12.06.24.12.3.18.06.18.06.42-.06.6z'
        fill='#fff'
      />
    </svg>
  )
}

export function TikTokIcon(props) {
  return (
    <svg
      width={16}
      height={10}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" fill="white"></path>
    </svg>
  )
}

export const TwitterIcon = (props) => {
  return (
    <svg
      width={11}
      height={8}
      viewBox='0 0 11 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.108.98a4.007 4.007 0 01-1.151.31c.417-.249.73-.64.879-1.102-.392.232-.82.396-1.268.482A1.993 1.993 0 007.108.04 1.998 1.998 0 005.11 2.038c0 .155.018.31.049.458A5.679 5.679 0 011.039.404C.858.71.764 1.06.766 1.414a2 2 0 00.89 1.665 2.016 2.016 0 01-.903-.255v.025c0 .971.686 1.776 1.602 1.962a2.116 2.116 0 01-.904.037c.253.792.99 1.368 1.868 1.386a4.005 4.005 0 01-2.48.855c-.168 0-.323-.006-.484-.025a5.659 5.659 0 003.07.897c3.675 0 5.686-3.045 5.686-5.688 0-.086 0-.173-.006-.26.389-.284.73-.637 1.002-1.033z'
        fill='#fff'
      />
    </svg>
  )
}

export const CoinsIcon = (props) => {
  return (
    <svg
      width={20}
      height={21}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.688 7.164V7c0-1.141-.844-2.149-2.383-2.844C11.008 3.57 9.297 3.25 7.5 3.25c-1.797 0-3.508.32-4.805.906C1.156 4.85.313 5.859.313 7v3.124c0 1.141.843 2.149 2.382 2.844.836.361 1.716.61 2.618.742v.164c0 1.141.843 2.149 2.382 2.844 1.297.586 3.008.907 4.805.907 1.797 0 3.508-.32 4.805-.907 1.539-.695 2.383-1.703 2.383-2.844V10.75c0-1.726-1.977-3.117-5-3.585zm3.124 3.585c0 .61-1.703 1.758-4.828 1.868 1.102-.664 1.704-1.532 1.704-2.493V9.063c2.023.367 3.124 1.18 3.124 1.688zM5.938 11.906v-1.242c.52.056 1.041.085 1.563.085s1.044-.029 1.563-.085v1.242a12.09 12.09 0 01-1.563.093 12.09 12.09 0 01-1.563-.093zm6.875-2.32v.538c0 .375-.648.954-1.874 1.368V10.32a8.26 8.26 0 001.367-.477c.174-.075.344-.16.508-.258zM7.5 5.124c3.438 0 5.313 1.242 5.313 1.875 0 .632-1.876 1.875-5.313 1.875-3.438 0-5.313-1.243-5.313-1.875 0-.633 1.876-1.875 5.313-1.875zm-5.313 5v-.54c.164.097.334.183.508.258.441.199.899.358 1.368.477v1.172c-1.227-.414-1.875-.993-1.875-1.368zm5 3.75H7.5c.281 0 .563-.008.844-.024.226.078.469.156.719.219v1.172c-1.227-.414-1.876-.993-1.876-1.368zm3.75 1.78v-1.241c.52.056 1.041.085 1.563.085s1.044-.029 1.563-.085v1.242a13.078 13.078 0 01-3.126 0zm5-.413V14.07a8.263 8.263 0 001.368-.477c.174-.075.344-.16.508-.258v.54c0 .374-.649.953-1.875 1.367z'
        fill='#1A171E'
      />
    </svg>
  )
}

export function CalendlyIcon(props) {
  return (
    <svg
      viewBox='7.4 0 344.6 360'
      width={18}
      height={21}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g fill='#fff'>
        <path d='M313.8 360H45.5c-21 0-38.1-17.1-38.1-38.1V53.5c0-21 17.1-38.1 38.1-38.1h268.3c21 0 38.1 17.1 38.1 38.1v268.3c.1 21.1-17 38.2-38.1 38.2zM45.5 36.5c-9.4 0-17 7.6-17 17v268.3c0 9.4 7.6 17 17 17h268.3c9.4 0 17-7.6 17-17V53.5c0-9.4-7.6-17-17-17z' />
        <path d='M256.6 72.4c-4.5 0-8.1-3.6-8.1-8.1V8.1c0-4.5 3.6-8.1 8.1-8.1s8.1 3.6 8.1 8.1v56.1c0 4.5-3.6 8.2-8.1 8.2zm-154.7 0c-4.5 0-8.1-3.6-8.1-8.1V8.1c0-4.5 3.6-8.1 8.1-8.1s8.1 3.6 8.1 8.1v56.1c.1 4.5-3.6 8.2-8.1 8.2zm87.5 181.4c-33.6 0-60.9-27.3-60.9-60.9s27.3-60.9 60.9-60.9c15.2 0 29.7 5.6 40.9 15.8 1.4 1.2 1.5 3.4.2 4.7-1.2 1.4-3.4 1.5-4.7.2-10-9.1-22.9-14.1-36.4-14.1-29.9 0-54.2 24.3-54.2 54.2s24.3 54.2 54.2 54.2c13.5 0 26.4-5 36.4-14.1 1.4-1.2 3.5-1.1 4.7.2 1.2 1.4 1.1 3.5-.2 4.7-11.2 10.4-25.7 16-40.9 16z' />
      </g>
    </svg>
  )
}

export function FacebookIcon(props) {
  return (
    <svg
      width={8}
      height={13}
      viewBox='0 0 8 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.647 7.21l.316-2.058H4.988V3.816c0-.563.275-1.112 1.16-1.112h.898V.951S6.231.813 5.452.813c-1.626 0-2.69.986-2.69 2.77v1.57H.954V7.21h1.808v4.976h2.226V7.212h1.659z'
        fill='#fff'
      />
    </svg>
  )
}

export function InstagramIcon(props) {
  return (
    <svg
      width={10}
      height={9}
      viewBox='0 0 10 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5 3.02c-.816 0-1.481.664-1.481 1.48 0 .815.665 1.48 1.48 1.48.816 0 1.482-.665 1.482-1.48 0-.816-.666-1.48-1.481-1.48zM9.44 4.5c0-.613.006-1.221-.028-1.833-.034-.711-.197-1.342-.716-1.862C8.175.285 7.545.123 6.834.09 6.22.054 5.613.059 5 .059c-.613 0-1.22-.005-1.833.03-.71.034-1.342.196-1.862.716-.52.521-.682 1.151-.716 1.862C.555 3.28.56 3.887.56 4.5c0 .612-.005 1.22.03 1.833.034.71.196 1.342.716 1.861.521.521 1.151.683 1.862.717.613.034 1.22.029 1.833.029s1.22.005 1.833-.029c.71-.034 1.342-.197 1.862-.717s.682-1.15.716-1.861c.036-.612.029-1.22.029-1.833zM5 6.778A2.275 2.275 0 012.72 4.5 2.275 2.275 0 015 2.22 2.275 2.275 0 017.278 4.5 2.275 2.275 0 015 6.778zM7.37 2.66a.531.531 0 110-1.063.531.531 0 010 1.063z'
        fill='#fff'
      />
    </svg>
  )
}

export function YoutubeIcon(props) {
  return (
    <svg
      width={12}
      height={9}
      viewBox='0 0 12 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.03 1.97a1.316 1.316 0 00-.927-.93C9.283.82 6 .82 6 .82s-3.284 0-4.103.22a1.314 1.314 0 00-.928.929C.75 2.789.75 4.5.75 4.5s0 1.71.22 2.53c.12.452.476.809.927.93.82.22 4.103.22 4.103.22s3.284 0 4.103-.22c.452-.121.807-.478.928-.93.219-.82.219-2.53.219-2.53s0-1.71-.22-2.53zm-6.073 4.1V2.93l2.719 1.558L4.957 6.07z'
        fill='#F7F7F7'
      />
    </svg>
  )
}

export function PhoneIcon(props) {
  return (
    <svg
      width={10}
      height={10}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.244 7.57L7.21 5.722a.5.5 0 00-.695.021L5.319 6.974c-.288-.055-.867-.236-1.463-.83-.596-.596-.776-1.177-.83-1.463l1.23-1.197a.5.5 0 00.021-.696L2.43.756a.5.5 0 00-.696-.043l-1.085.93a.5.5 0 00-.145.325c-.007.125-.15 3.086 2.146 5.383C4.653 9.354 7.162 9.5 7.853 9.5c.1 0 .163-.003.18-.004a.496.496 0 00.323-.146l.93-1.085a.498.498 0 00-.042-.695z"
        fill="#fff"
      />
    </svg>
  )
}

export function LinkedinIcon(props) {
  return (
    <svg
      width={10}
      height={10}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.715 3.485h1.857v.925c.267-.533.953-1.01 1.984-1.01C9.53 3.4 10 4.458 10 6.402V10H8V6.844c0-1.107-.268-1.73-.949-1.73-.944 0-1.337.672-1.337 1.73V10h-2V3.485zm-3.43 6.43h2V3.4h-2v6.515zm2.286-8.64a1.265 1.265 0 01-.376.901 1.295 1.295 0 01-1.819 0A1.274 1.274 0 010 1.275C0 .937.135.613.377.374a1.292 1.292 0 011.818 0c.241.239.377.563.377.901z"
        fill="#fff"
      />
    </svg>
  )
}

export function OtherSocialIcon(props) {
  return (
    <svg
      width={10}
      height={12}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.125 9.375a1.875 1.875 0 11-3.623-.68L3.34 7.308a1.875 1.875 0 110-2.616L5.5 3.305a1.88 1.88 0 11.408.628L3.75 5.32a1.89 1.89 0 010 1.36l2.16 1.387a1.875 1.875 0 013.216 1.308z"
        fill="#fff"
      />
    </svg>
  )
}

export function CreatedIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.258 3.867c.325-.325.325-.867 0-1.175l-1.95-1.95c-.308-.325-.85-.325-1.175 0L10.6 2.267l3.125 3.125M.5 12.375V15.5h3.125l9.217-9.225L9.717 3.15.5 12.375z"
        fill="currentColor"
      />
    </svg>
  )
}

export function SendedIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.319.681a.625.625 0 00-.656-.144l-13.75 5a.625.625 0 000 1.17L6.28 8.85l3.963-3.975.881.881-3.981 3.981 2.15 5.37a.625.625 0 00.581.393.625.625 0 00.575-.412l5-13.75A.624.624 0 0015.32.68z"
        fill="currentColor"
      />
    </svg>
  )
}

export function VisitedIcon(props) {
  return (
    <svg
      width={17}
      height={19}
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.166 14c.467 0 .834.367.834.833a.825.825 0 01-.834.834.825.825 0 01-.833-.834c0-.466.367-.833.833-.833zm0-2.5c-2.275 0-4.216 1.383-5 3.333.784 1.95 2.725 3.334 5 3.334s4.217-1.384 5-3.334c-.783-1.95-2.725-3.333-5-3.333zm0 5.417a2.083 2.083 0 110-4.167 2.083 2.083 0 010 4.167zm-6.55-1.459l-.241-.625.241-.616c1.075-2.667 3.634-4.384 6.55-4.384a7.16 7.16 0 012.5.467V5.667l-5-5H2A1.66 1.66 0 00.333 2.333v13.334A1.667 1.667 0 002 17.333h3.75a7.703 7.703 0 01-1.134-1.875zM7.833 1.917L12.416 6.5H7.833V1.917z"
        fill="#686868"
      />
    </svg>
  )
}

export function ApplicationsIcon(props) {
  return (
    <svg
      width={15}
      height={17}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.458 6.492l.525.108 3.475 1.733c.584.192.967.767.917 1.384v.216l-.75 5.1c-.05.359-.208.692-.5.925-.258.25-.6.375-.958.375H6.433c-.408 0-.783-.15-1.058-.441L.383 10.917l.75-.834c.2-.208.517-.325.817-.308h.242L5.5 10.5V1.75a1.667 1.667 0 113.333 0v4.742h.625z"
        fill="#686868"
      />
    </svg>
  )
}

export function UnionIcon(props) {
  return (
    <svg
      width={14}
      height={12}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.125 11.25s-.875 0-.875-.875.875-3.5 4.375-3.5S14 9.5 14 10.375s-.875.875-.875.875h-7zM9.625 6a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25zm-5.061 5.25a1.958 1.958 0 01-.189-.875c0-1.185.595-2.406 1.694-3.255a5.534 5.534 0 00-1.694-.245C.875 6.875 0 9.5 0 10.375s.875.875.875.875h3.689zm.92-5.89a2.188 2.188 0 11-3.093-3.094 2.188 2.188 0 013.093 3.093z"
        fill="#2D2F66"
      />
    </svg>
  )
}

export function DatesIcon(props) {
  return (
    <svg
      width={15}
      height={18}
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 10.667a.804.804 0 01-.593-.24.804.804 0 01-.24-.594c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239s.434.08.594.24c.16.16.24.357.24.593s-.08.434-.24.594a.807.807 0 01-.594.24zm-3.333 0a.807.807 0 01-.595-.24.806.806 0 01-.239-.594c0-.236.08-.434.24-.594.16-.16.358-.239.594-.239s.434.08.594.24c.16.16.239.357.239.593s-.08.434-.24.594a.807.807 0 01-.593.24zm6.666 0a.804.804 0 01-.593-.24.804.804 0 01-.24-.594c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239s.434.08.594.24c.16.16.24.357.24.593s-.08.434-.24.594a.804.804 0 01-.594.24zM7.5 14a.804.804 0 01-.593-.24.804.804 0 01-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24s.434.08.594.24c.16.16.24.358.24.594s-.08.434-.24.593A.807.807 0 017.5 14zm-3.333 0a.807.807 0 01-.595-.24.806.806 0 01-.239-.593c0-.236.08-.434.24-.594.16-.16.358-.24.594-.24s.434.08.594.24c.16.16.239.358.239.594s-.08.434-.24.593a.807.807 0 01-.593.24zm6.666 0a.804.804 0 01-.593-.24.804.804 0 01-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24s.434.08.594.24c.16.16.24.358.24.594s-.08.434-.24.593a.804.804 0 01-.594.24zm-9.166 3.333c-.459 0-.851-.163-1.178-.489A1.607 1.607 0 010 15.667V4c0-.458.163-.85.49-1.177.326-.326.718-.49 1.177-.49H2.5V.667h1.667v1.666h6.666V.667H12.5v1.666h.833c.459 0 .851.164 1.178.49.326.326.489.719.489 1.177v11.667c0 .458-.163.85-.49 1.177-.326.326-.718.49-1.177.49H1.667zm0-1.666h11.666V7.333H1.667v8.334z"
        fill="currentColor"
      />
    </svg>
  )
}

export function HomeLeftIcon(props) {
  return (
    <svg
      width={21}
      height={19}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.96 7.791h11.72a.196.196 0 00.195-.195V6.229a.196.196 0 00-.195-.196H7.96a.196.196 0 00-.194.196v1.367c0 .107.088.195.195.195zm-.194 4.98c0 .108.088.196.195.196H19.68a.196.196 0 00.195-.196v-1.367a.196.196 0 00-.195-.195H7.96a.196.196 0 00-.194.195v1.367zM20.07.906H.93a.196.196 0 00-.196.196v1.367c0 .107.088.195.196.195h19.14a.196.196 0 00.196-.195V1.102a.196.196 0 00-.196-.196zm0 15.43H.93a.196.196 0 00-.196.195v1.367c0 .108.088.196.196.196h19.14a.196.196 0 00.196-.196v-1.367a.196.196 0 00-.196-.195zm-18.593-3.66l3.815-3.005a.216.216 0 000-.34L1.477 6.325a.217.217 0 00-.352.168v6.013a.218.218 0 00.352.171z"
        fill="#2D2F66"
      />
    </svg>
  )
}

export function LanguageIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.167.833H2.5C1.583.833.833 1.583.833 2.5v10l2.5-2.5H7.5v-.833a3.338 3.338 0 013.334-3.334V2.5c0-.917-.75-1.667-1.667-1.667zm0 2.5h-1.25A6.927 6.927 0 016.4 6.05l-.017.017 1.05 1.041-.308.842-1.292-1.283L3.75 8.75l-.575-.608 2.109-2.075a7.15 7.15 0 01-1.234-1.9h.825c.258.5.575.975.958 1.4a6.409 6.409 0 001.309-2.234H2.5V2.5h2.917v-.833h.833V2.5h2.917v.833zM17.5 7.5h-6.666c-.917 0-1.667.75-1.667 1.667V15c0 .917.75 1.667 1.667 1.667h5.833l2.5 2.5v-10c0-.917-.75-1.667-1.667-1.667zm-1.142 8.333l-.708-1.875h-2.966l-.7 1.875h-1.25l2.808-7.5h1.25l2.816 7.5h-1.25zM14.168 10l1.017 2.708h-2.025L14.166 10z"
        fill="#686868"
      />
    </svg>
  )
}

export function ArrowUpIcons(props) {
  return (
    <svg
      width={12}
      height={7}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.983 7l-.816-.817 4.316-4.345L6.816 4.17 9.85 1.167H8.333V0h3.5v3.5h-1.167V1.983l-3.85 3.85L4.483 3.5.983 7z"
        fill="#6495ED"
      />
    </svg>
  )
}

export function ArrowDownIcons(props) {
  return (
    <svg
      width={13}
      height={7}
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.517 0l.817.817-4.317 4.345L5.684 2.83 2.65 5.833h1.517V7h-3.5V3.5h1.166v1.517l3.85-3.85L8.018 3.5l3.5-3.5z"
        fill="#E42A36"
      />
    </svg>
  )
}

export function LocationIcon(props) {
  return (
    <svg
      width={12}
      height={18}
      viewBox="0 0 12 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 8.583a2.083 2.083 0 110-4.166 2.083 2.083 0 010 4.166zM6 .667A5.833 5.833 0 00.167 6.5C.167 10.875 6 17.333 6 17.333s5.833-6.458 5.833-10.833A5.833 5.833 0 006 .667z"
        fill="currentColor"
      />
    </svg>
  )
}

export function CalendarIcon(props) {
  return (
    <svg
      width={16}
      height={18}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 10.667a.804.804 0 01-.593-.24.804.804 0 01-.24-.594c0-.236.08-.434.24-.594C7.567 9.08 7.764 9 8 9s.434.08.594.24c.16.16.24.357.24.593s-.08.434-.24.594a.807.807 0 01-.594.24zm-3.333 0a.807.807 0 01-.595-.24.806.806 0 01-.239-.594c0-.236.08-.434.24-.594.16-.16.358-.239.594-.239s.434.08.594.24c.16.16.239.357.239.593s-.08.434-.24.594a.807.807 0 01-.593.24zm6.666 0a.804.804 0 01-.593-.24.804.804 0 01-.24-.594c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239s.434.08.594.24c.16.16.24.357.24.593s-.08.434-.24.594a.804.804 0 01-.594.24zM8 14a.804.804 0 01-.593-.24.804.804 0 01-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24s.434.08.594.24c.16.16.24.357.24.594 0 .236-.08.434-.24.593A.807.807 0 018 14zm-3.333 0a.807.807 0 01-.595-.24.806.806 0 01-.239-.593c0-.236.08-.434.24-.594.16-.16.358-.24.594-.24s.434.08.594.24c.16.16.239.357.239.594 0 .236-.08.434-.24.593a.807.807 0 01-.593.24zm6.666 0a.804.804 0 01-.593-.24.804.804 0 01-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24s.434.08.594.24c.16.16.24.357.24.594 0 .236-.08.434-.24.593a.804.804 0 01-.594.24zm-9.166 3.333c-.459 0-.851-.163-1.178-.489A1.607 1.607 0 01.5 15.667V4c0-.458.163-.85.49-1.177.326-.326.718-.49 1.177-.49H3V.667h1.667v1.666h6.666V.667H13v1.666h.833c.459 0 .851.164 1.178.49.326.326.489.719.489 1.177v11.667c0 .458-.163.85-.49 1.177-.326.326-.718.49-1.177.49H2.167zm0-1.666h11.666V7.333H2.167v8.334z"
        fill="#686868"
      />
    </svg>
  )
}

export function PencilIcon({ width = '16', height = '16' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='currentColor'
        d='M13.807 4.693a.664.664 0 000-.94l-1.56-1.56a.664.664 0 00-.94 0l-1.227 1.22 2.5 2.5M2 11.5V14h2.5l7.373-7.38-2.5-2.5L2 11.5z'
      />
    </svg>
  )
}

export function DeletedIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='currentColor'
        d='M6.571 3.429H9.43a1.429 1.429 0 00-2.858 0zm-.857 0a2.286 2.286 0 014.572 0h3.571a.429.429 0 010 .857h-.748l-.696 8.344a2.429 2.429 0 01-2.42 2.227H6.007a2.429 2.429 0 01-2.42-2.227L2.89 4.286h-.748a.429.429 0 110-.857h3.571zm1.429 3.285a.429.429 0 00-.857 0v4.857a.429.429 0 00.857 0V6.714zm2.143-.428a.429.429 0 00-.429.428v4.857a.429.429 0 00.857 0V6.714a.429.429 0 00-.428-.428z'
      />
    </svg>
  )
}

export function HeartMessageIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M11.247 23.659a1 1 0 001.506 0l3.2-3.659H20a3 3 0 003-3V3a3 3 0 00-3-3H4a3 3 0 00-3 3v14a3 3 0 003 3h4.046l3.201 3.659zm3.5-5.317L12 21.482l-2.747-3.14A1 1 0 008.5 18H4a1 1 0 01-1-1V3a1 1 0 011-1h16a1 1 0 011 1v14a1 1 0 01-1 1h-4.5a1 1 0 00-.753.341zM8 8.476C8 7.67 8.663 7 9.5 7c.838 0 1.5.67 1.5 1.476a1 1 0 102 0C13 7.67 13.662 7 14.5 7s1.5.67 1.5 1.476c0 .985-.575 2.08-1.536 3.202-.747.872-1.647 1.668-2.464 2.331-.817-.663-1.717-1.459-2.464-2.33C8.575 10.555 8 9.46 8 8.475zM9.5 5c.976 0 1.863.399 2.5 1.043A3.505 3.505 0 0114.5 5C16.424 5 18 6.547 18 8.476c0 1.712-.96 3.27-2.017 4.504-1.077 1.257-2.393 2.335-3.369 3.095a1 1 0 01-1.228 0c-.976-.76-2.292-1.838-3.37-3.095C6.96 11.746 6 10.188 6 8.476 6 6.547 7.576 5 9.5 5z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export function AirPlaneIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 32 32'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='currentColor' transform='translate(-310 -309)'>
          <path d='M341.207 309.82c-1.246-1.25-3.436-.957-4.689.299l-6.377 6.362-11.828-4.42c-1.133-.293-2.274-.672-3.679.737-.717.718-2.207 2.212 0 4.423l8.11 6.64-5.277 5.266-4.924-1.231c-.73-.188-1.222-.041-1.597.373-.189.236-1.56 1.252-.604 2.21l5.725 4.454 4.454 5.725c.692.694 1.335.261 2.214-.574.557-.558.437-.845.269-1.658l-1.112-4.89 5.241-5.259 6.63 8.112c2.206 2.211 3.697.716 4.414 0 1.406-1.41 1.028-2.552.735-3.687l-4.383-11.848 6.351-6.373c1.253-1.255 1.574-3.412.327-4.661' />
        </g>
      </g>
    </svg>
  )
}

export function TeamIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='currentColor'
        d='M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 100-6 3 3 0 000 6z'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M5.216 14A2.238 2.238 0 015 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 005 9c-4 0-5 3-5 4s1 1 1 1h4.216z'
        clipRule='evenodd'
      />
      <path fill='currentColor' d='M4.5 8a2.5 2.5 0 100-5 2.5 2.5 0 000 5z' />
    </svg>
  )
}

export function IconsPages() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='10'
      fill='none'
      viewBox='0 0 12 10'
    >
      <path
        fill='currentColor'
        d='M0 1.5A1.5 1.5 0 011.5 0h3.995a1.5 1.5 0 011.5 1.5V2h3.555c.734.026 1.45.547 1.45 1.51v3.03a1.5 1.5 0 01-1.5 1.5H9.113l-.146.746c-.162.828-.814 1.216-1.467 1.213L1.5 10A1.5 1.5 0 010 8.5v-7zm6.995 6.999c0 .605.874.69.99.095l.892-4.58v-.002l.15-.783.006-.031.012-.054.01-.044.03-.1h-2.09v5.499zM2.485 2.99a.5.5 0 100-1 .5.5 0 000 1zm.01 1.5a.5.5 0 100 1h1.99a.501.501 0 100-1h-1.99zm0 2.51a.5.5 0 100 1h1.99a.5.5 0 00.262-.925l-.022-.013a.49.49 0 00-.129-.05l-.01-.002A.475.475 0 004.508 7H2.494h.001z'
      />
    </svg>
  )
}

export function EditIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='currentColor'
        d='M13.807 4.693a.664.664 0 000-.94l-1.56-1.56a.664.664 0 00-.94 0l-1.227 1.22 2.5 2.5M2 11.5V14h2.5l7.373-7.38-2.5-2.5L2 11.5z'
      />
    </svg>
  )
}

export function IconCamping() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4 1.5H4.7C3.6146 1.5 2 2.2191 2 4.2V16.8C2 18.7809 3.6146 19.5 4.7 19.5H18.2V17.7H4.7108C4.295 17.6892 3.8 17.5254 3.8 16.8C3.8 16.7091 3.8081 16.6281 3.8216 16.5543C3.9224 16.0359 4.3472 15.909 4.7108 15.9H18.2V3.3C18.2 2.82261 18.0104 2.36477 17.6728 2.02721C17.3352 1.68964 16.8774 1.5 16.4 1.5ZM16.4 9.6L14.6 8.7L12.8 9.6V3.3H16.4V9.6Z" fill="#373737" />
    </svg>
  )
}

export function IconLocationHome() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="bxs:map">
        <path id="Vector" d="M10.0007 2.16663C6.32489 2.16663 3.33405 5.15746 3.33405 8.82913C3.30989 14.2 9.74739 18.6533 10.0007 18.8333C10.0007 18.8333 16.6916 14.2 16.6674 8.83329C16.6674 5.15746 13.6766 2.16663 10.0007 2.16663ZM10.0007 12.1666C8.15905 12.1666 6.66739 10.675 6.66739 8.83329C6.66739 6.99163 8.15905 5.49996 10.0007 5.49996C11.8424 5.49996 13.3341 6.99163 13.3341 8.83329C13.3341 10.675 11.8424 12.1666 10.0007 12.1666Z" fill="#373737" />
      </g>
    </svg>


  )
}

export function MonitoringIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='currentColor'
      viewBox='0 0 470 470'
    >
      <g>
        <path d='M452.159 16.363H17.841C8.003 16.363 0 24.366 0 34.204v329.205c0 9.838 8.003 17.841 17.841 17.841h163.807v57.387h-70.739c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h248.182c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-70.738V381.25H452.16c9.838 0 17.841-8.003 17.841-17.841V34.204c-.001-9.838-8.004-17.841-17.842-17.841zM273.353 438.637h-76.705V381.25h76.705v57.387zM455 363.409c0 1.54-1.301 2.841-2.841 2.841H17.841c-1.54 0-2.841-1.301-2.841-2.841V34.204c0-1.54 1.301-2.841 2.841-2.841h434.318c1.54 0 2.841 1.301 2.841 2.841v329.205z' />
        <path d='M431.478 305.228H38.523c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h392.955c4.143 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5zM124.666 250.416c33.343 19.503 71.496 29.812 110.334 29.812 38.839 0 76.991-10.309 110.334-29.812 32.35-18.921 59.488-46.004 78.483-78.319a7.502 7.502 0 000-7.602c-18.995-32.315-46.134-59.398-78.483-78.32C311.991 66.672 273.839 56.363 235 56.363s-76.992 10.309-110.334 29.812c-32.35 18.922-59.489 46.005-78.483 78.32a7.502 7.502 0 000 7.602c18.994 32.315 46.133 59.398 78.483 78.319zm107.413 14.764c-52.103-1.55-94.011-44.413-94.011-96.884 0-52.472 41.909-95.335 94.011-96.885.974-.014 1.945-.048 2.921-.048.976 0 1.947.034 2.921.048 52.102 1.55 94.011 44.413 94.011 96.885 0 52.471-41.909 95.333-94.011 96.884-.974.014-1.945.048-2.921.048s-1.947-.034-2.921-.048zm176.517-96.884c-23.162 37.494-57.726 66.025-97.629 82.126 22.103-20.46 35.965-49.702 35.965-82.126S333.07 106.63 310.966 86.17c39.903 16.1 74.468 44.632 97.63 82.126zM159.034 86.169c-22.104 20.461-35.966 49.703-35.966 82.127 0 32.423 13.862 61.665 35.966 82.126-39.903-16.101-74.467-44.631-97.629-82.126 23.161-37.495 57.725-66.026 97.629-82.127z' />
        <path d='M284.918 168.295a49.52 49.52 0 00-4.423-20.536 7.5 7.5 0 10-13.668 6.178 34.63 34.63 0 013.091 14.358c0 19.254-15.664 34.918-34.918 34.918s-34.918-15.664-34.918-34.918 15.664-34.918 34.918-34.918c4.989 0 9.82 1.04 14.359 3.092a7.5 7.5 0 006.178-13.668A49.517 49.517 0 00235 118.377c-27.525 0-49.918 22.394-49.918 49.918s22.393 49.918 49.918 49.918c27.524 0 49.918-22.394 49.918-49.918z' />
      </g>
    </svg>
  )
}

export function PaypalIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      preserveAspectRatio='xMidYMid'
      viewBox='-23 0 302 302'
    >
      <path
        fill='#27346A'
        d='M217.168 23.507C203.234 7.625 178.046.816 145.823.816h-93.52A13.393 13.393 0 0039.076 12.11L.136 259.077c-.774 4.87 2.997 9.28 7.933 9.28h57.736l14.5-91.971-.45 2.88c1.033-6.501 6.593-11.296 13.177-11.296h27.436c53.898 0 96.101-21.892 108.429-85.221.366-1.873.683-3.696.957-5.477-1.556-.824-1.556-.824 0 0 3.671-23.407-.025-39.34-12.686-53.765'
      />
      <path
        fill='#27346A'
        d='M102.397 68.84a11.737 11.737 0 015.053-1.14h73.318c8.682 0 16.78.565 24.18 1.756a101.6 101.6 0 016.177 1.182 89.928 89.928 0 018.59 2.347c3.638 1.215 7.026 2.63 10.14 4.287 3.67-23.416-.026-39.34-12.687-53.765C203.226 7.625 178.046.816 145.823.816H52.295C45.71.816 40.108 5.61 39.076 12.11L.136 259.068c-.774 4.878 2.997 9.282 7.925 9.282h57.744L95.888 77.58a11.717 11.717 0 016.509-8.74z'
      />
      <path
        fill='#2790C3'
        d='M228.897 82.749c-12.328 63.32-54.53 85.221-108.429 85.221H93.024c-6.584 0-12.145 4.795-13.168 11.296L61.817 293.621c-.674 4.262 2.622 8.124 6.934 8.124h48.67a11.71 11.71 0 0011.563-9.88l.474-2.48 9.173-58.136.591-3.213a11.71 11.71 0 0111.562-9.88h7.284c47.147 0 84.064-19.154 94.852-74.55 4.503-23.15 2.173-42.478-9.739-56.054-3.613-4.112-8.1-7.508-13.327-10.28-.283 1.79-.59 3.604-.957 5.477z'
      />
      <path
        fill='#1F264F'
        d='M216.952 72.128a89.928 89.928 0 00-5.818-1.49 109.904 109.904 0 00-6.177-1.174c-7.408-1.199-15.5-1.765-24.19-1.765h-73.309a11.57 11.57 0 00-5.053 1.149 11.683 11.683 0 00-6.51 8.74l-15.582 98.798-.45 2.88c1.025-6.501 6.585-11.296 13.17-11.296h27.444c53.898 0 96.1-21.892 108.428-85.221.367-1.873.675-3.688.958-5.477-3.122-1.648-6.501-3.072-10.14-4.279a83.26 83.26 0 00-2.77-.865'
      />
    </svg>
  )
}

export function EditApplicationIcon({ color }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g id="jam:write">
        <path id="Vector" d="M5.14927 10.9702L6.32327 10.6316L13.3919 3.47824C13.4477 3.4211 13.4787 3.34433 13.4784 3.2645C13.478 3.18466 13.4462 3.10818 13.3899 3.05157L12.9666 2.62357C12.9392 2.59546 12.9066 2.57309 12.8705 2.55774C12.8344 2.5424 12.7956 2.53441 12.7564 2.53422C12.7171 2.53403 12.6783 2.54166 12.642 2.55666C12.6058 2.57166 12.5729 2.59372 12.5453 2.62157L5.49527 9.75624L5.1486 10.9696L5.14927 10.9702ZM13.8046 1.77557L14.2279 2.20424C14.8119 2.79557 14.8173 3.74957 14.2386 4.33491L6.9546 11.7069L4.44527 12.4296C4.29207 12.4725 4.12811 12.4528 3.98938 12.3749C3.85065 12.2971 3.74847 12.1674 3.70527 12.0142C3.67313 11.9042 3.67266 11.7872 3.70394 11.6769L4.43394 9.11691L11.6986 1.76424C11.8367 1.62514 12.0012 1.51495 12.1824 1.44007C12.3635 1.3652 12.5578 1.32715 12.7538 1.32814C12.9499 1.32914 13.1437 1.36915 13.3242 1.44586C13.5046 1.52256 13.6679 1.63509 13.8046 1.77557ZM6.12527 2.54424C6.45594 2.54424 6.72394 2.81557 6.72394 3.15024C6.72446 3.22935 6.70939 3.30779 6.67958 3.38107C6.64977 3.45436 6.60581 3.52105 6.55021 3.57733C6.49461 3.63361 6.42846 3.67838 6.35555 3.70908C6.28263 3.73978 6.20438 3.7558 6.12527 3.75624H3.7306C3.06927 3.75624 2.53327 4.29891 2.53327 4.96757V12.2382C2.53327 12.9076 3.06927 13.4502 3.7306 13.4502H10.9146C11.5759 13.4502 12.1126 12.9076 12.1126 12.2382V9.81491C12.1126 9.48024 12.3806 9.20891 12.7113 9.20891C13.0419 9.20891 13.3099 9.48024 13.3099 9.81557V12.2382C13.3099 13.5769 12.2373 14.6622 10.9146 14.6622H3.7306C2.40794 14.6622 1.33594 13.5769 1.33594 12.2382V4.96757C1.33594 3.62957 2.40794 2.54424 3.7306 2.54424H6.12527Z" />
      </g>
    </svg>

  )
}

export function IconAge() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <g fill='#000' clipPath='url(#clip0_6_2771)'>
        <path d='M7.488 22.45a3.732 3.732 0 100-7.465 3.732 3.732 0 000 7.465zM14.896 29.904c-.179-4.146-3.442-7.454-7.442-7.454-4.001 0-7.264 3.302-7.443 7.454L0 31.2a3.243 3.243 0 002.442 3.14v4.034c0 .855.693 1.548 1.547 1.548h6.923c.855 0 1.548-.693 1.548-1.548V34.34A3.243 3.243 0 0014.9 31.2l-.01-1.296h.005zM19.919 14.969a3.732 3.732 0 100-7.465 3.732 3.732 0 000 7.465z' />
        <path d='M27.367 22.54c-.09-4.23-3.386-7.639-7.448-7.639-3.727 0-6.817 2.872-7.359 6.621 2.744 1.727 4.621 4.789 4.778 8.354v.089l.01 1.313c0 1.91-.938 3.643-2.435 4.693v2.481c0 .855.693 1.548 1.547 1.548h6.923c.855 0 1.548-.693 1.548-1.548v-7.565a3.182 3.182 0 002.442-3.096v-5.263l-.006.011zM32.506 7.465a3.732 3.732 0 100-7.465 3.732 3.732 0 000 7.465z' />
        <path d='M39.954 15.052c-.134-4.19-3.414-7.548-7.443-7.548-3.66 0-6.699 2.766-7.33 6.409.62.402 1.207.882 1.743 1.43 1.855 1.905 2.906 4.448 2.967 7.152v5.313c0 1.906-.922 3.621-2.391 4.677v5.744c0 .978.793 1.771 1.77 1.771h6.471c.978 0 1.771-.793 1.771-1.771V27.16a3.182 3.182 0 002.442-3.095v-9.013z' />
      </g>
      <defs>
        <clipPath id='clip0_6_2771'>
          <path fill='#fff' d='M0 0H40V40H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export function IdiomaIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='41'
      height='41'
      fill='none'
      viewBox='0 0 41 41'
    >
      <g fill='#000' clipPath='url(#clip0_4337_2940)'>
        <path d='M12.72 18.533l-1.11 3.445h2.222l-1.112-3.445z' />
        <path d='M36.226 2h-20.13v6.186h5.033v2.474H4.774C2.635 10.66 1 12.268 1 14.37V27.98c0 2.104 1.635 3.712 3.774 3.712h1.258V38l7.926-6.31h8.43v-8.66h13.838c2.139 0 3.774-1.607 3.774-3.71V5.71C40 3.608 38.364 2 36.226 2zM14.587 26.619l-.629-1.98h-3.9l-.755 1.98h-3.02l4.152-11.01h3.02l4.152 11.01h-3.02zm19.123-9.774v2.475c-1.636 0-3.397-.495-4.907-1.238-1.51.743-3.27 1.114-5.032 1.238l-.126-2.475c.88 0 1.762-.123 2.642-.37a8.415 8.415 0 01-2.264-3.96h2.642c.377 1.114 1.132 1.98 2.012 2.722a6.366 6.366 0 002.39-4.577H23.52V8.186h3.775V5.71h2.516v2.475h4.151l.126 1.237a9.32 9.32 0 01-2.768 7.051c.881.248 1.636.371 2.39.371z' />
      </g>
      <defs>
        <clipPath id='clip0_4337_2940'>
          <path
            fill='#fff'
            d='M0 0H40V40H0z'
            transform='translate(.5 .2)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export function ModalityIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <g fill='#000' clipPath='url(#clip0_6_2764)'>
        <path d='M22.988 7.924a2.038 2.038 0 00-2.877-.178l-4.928 4.348c-.492.222-1.065.46-1.195.46H4.949A4.952 4.952 0 000 17.504v4.348c0 .747.606 1.353 1.352 1.353h1.504a1.182 1.182 0 01-.016-.195v-5.35c0-.697.568-1.265 1.265-1.265.698 0 1.266.568 1.266 1.265v5.35c0 .065-.005.13-.016.195h9.32v-5.23l.065-.05 8.08-7.129A2.038 2.038 0 0023 7.92l-.012.005z' />
        <path d='M9.915 12.457a3.478 3.478 0 100-6.956 3.478 3.478 0 000 6.956z' />
        <path d='M38.843 0h-28.55c-.74 0-1.34.6-1.34 1.341V3.24c.335-.06.675-.092 1.027-.092 3.05 0 5.56 2.358 5.81 5.344l2.839-2.504a4.394 4.394 0 016.193.384 4.395 4.395 0 01-.384 6.193l-7.345 6.486v4.165h21.755c.74 0 1.341-.6 1.341-1.342V1.341c0-.74-.6-1.341-1.341-1.341h-.005zm-3.365 15.816h-5.782a1.25 1.25 0 010-2.499h5.782a1.25 1.25 0 010 2.499zm0-4.765h-5.782a1.25 1.25 0 010-2.5h5.782a1.25 1.25 0 010 2.5zm1.25-6.02a1.25 1.25 0 01-1.25 1.249h-5.782a1.25 1.25 0 010-2.5h5.782c.692 0 1.25.563 1.25 1.25zM33.31 33.212a3.359 3.359 0 10-4.387-3.197c0 1.504.99 2.78 2.353 3.208a6.174 6.174 0 00-5.052 5.138 6.16 6.16 0 00-5.068-5.138 3.36 3.36 0 10-2.028 0 6.162 6.162 0 00-5.074 5.095 6.164 6.164 0 00-5.041-5.09 3.36 3.36 0 002.353-3.208 3.359 3.359 0 10-4.387 3.197 6.161 6.161 0 00-5.171 6.08c0 .39.314.703.703.703h35.256c.39 0 .703-.314.703-.703 0-3.067-2.24-5.61-5.17-6.08l.01-.005z' />
      </g>
      <defs>
        <clipPath id='clip0_6_2764'>
          <path fill='#fff' d='M0 0H40V40H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export function IntakeIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <g fill='#000' clipPath='url(#clip0_6_2744)'>
        <path d='M16.767 0H1.984A1.982 1.982 0 000 1.984v14.783c0 1.096.887 1.983 1.984 1.983h14.783a1.982 1.982 0 001.984-1.983V1.984A1.982 1.982 0 0016.767 0zm.812 16.761a.734.734 0 01-.737.737H1.99a.734.734 0 01-.736-.737V1.801c0-.409.328-.737.736-.737h14.853c.409 0 .737.328.737.737v14.96zM38.14 21.25H23.357a1.982 1.982 0 00-1.984 1.983v14.783c0 1.097.887 1.984 1.984 1.984H38.14a1.982 1.982 0 001.983-1.984V23.233a1.982 1.982 0 00-1.983-1.983zm.812 16.76a.734.734 0 01-.737.737H23.362a.734.734 0 01-.736-.736V23.05c0-.41.327-.737.736-.737h14.853c.409 0 .736.328.736.736v14.96z' />
        <path d='M14.482 9.461V8.15a3.945 3.945 0 00-3.946-3.946h-.307a3.952 3.952 0 00-3.945 3.779h-.527A3.157 3.157 0 002.6 11.139a3.157 3.157 0 003.156 3.155h8.08a2.456 2.456 0 00.64-4.827l.005-.006zM30.72 34.883a4.365 4.365 0 100-8.73 4.365 4.365 0 000 8.73zM31.346 23.669h-1.274v1.274h1.274v-1.274zM25.067 29.894h-1.274v1.274h1.274v-1.274zM31.361 36.167h-1.274v1.274h1.274v-1.274zM37.602 29.883h-1.274v1.274h1.274v-1.274zM36.008 26.122l-.901-.9-.9.9.9.901.9-.9zM27.173 26.087l-.9-.9-.902.9.901.901.901-.9zM27.183 34.979l-.9-.901-.902.9.901.902.901-.901zM36.043 34.943l-.9-.9-.901.9.9.901.901-.9zM16.842 21.159H2.059a1.982 1.982 0 00-1.984 1.983v14.783c0 1.097.887 1.984 1.984 1.984h14.783a1.982 1.982 0 001.983-1.984V23.142a1.982 1.982 0 00-1.983-1.983zm-3.403 4.531l.844.844-.844.844-.844-.844.844-.844zm-7.892.043l.844.844-.844.844-.844-.844.844-.844zm-.07 9.612l-.843-.844.843-.844.844.844-.844.844zm8.026.043l-.844-.844.844-.844.844.844-.844.844zm1.946-5.402v1.193h-.682l1.193 1.505-.935.742-1.785-2.247h-2.252l1.752 1.752-.844.844-1.881-1.881v2.64l2.327 1.843-.742.936-1.585-1.258v.747H8.82v-.683l-1.505 1.194-.742-.936 2.247-1.785v-2.59l-1.8 1.8-.845-.844 1.785-1.784H5.843L4.058 33.42l-.935-.742 1.193-1.505h-1.36V29.98h1.452L3.15 28.394l.935-.741L5.93 29.98h2.172l-1.769-1.768.844-.844 1.645 1.645v-1.817L6.574 25.41l.742-.936 1.505 1.194v-1.36h1.194v1.451l1.585-1.258.742.936-2.327 1.844v1.833l1.72-1.72.844.843-1.742 1.742h2.338l1.844-2.328.936.742-1.258 1.586h.747l.005.005zM32.608 5.204s3.085 3.386 3.655 5.574c.57 2.188-1.42 4.08-3.655 4.08-2.237 0-4.43-1.66-3.693-4.16.57-1.941 3.693-5.494 3.693-5.494z' />
        <path d='M38.14 0H23.357a1.982 1.982 0 00-1.984 1.984v14.783c0 1.096.887 1.983 1.984 1.983H38.14a1.982 1.982 0 001.983-1.983V1.984A1.982 1.982 0 0038.14 0zm-1.484 14.041c-.94 1.215-2.446 1.94-4.037 1.94-1.666 0-3.28-.762-4.198-1.999a4.028 4.028 0 01-.602-1.156l-.302-.027c-1.317-.129-2.51-.8-3.187-1.806-.586-.87-.742-1.935-.43-2.994.494-1.683 2.682-4.225 3.112-4.72l.925-1.053.94 1.032c.044.048 1 1.102 1.844 2.338.506-.634.909-1.091 1.016-1.22l.866-.984.881.968c.334.37 3.301 3.671 3.925 6.069.333 1.268.064 2.548-.753 3.607v.005z' />
        <path d='M27.818 10.563c.318-1.07 1.226-2.467 2.092-3.645-.844-1.317-1.963-2.542-1.963-2.542s-2.413 2.747-2.854 4.247c-.532 1.806.99 3.048 2.537 3.198 0-.414.06-.839.188-1.263v.005z' />
      </g>
      <defs>
        <clipPath id='clip0_6_2744'>
          <path fill='#fff' d='M0 0H40V40H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export function CollegeIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <path
        fill='#000'
        d='M20 2.667L0 12l5.333 2.4v4.533c-1.6.534-2.666 2.134-2.666 3.734s1.066 3.2 2.666 3.733v.267l-2.4 5.6c-.8 2.4-.266 5.066 3.734 5.066s4.533-2.666 3.733-5.066l-2.4-5.6c1.6-.8 2.667-2.134 2.667-4 0-1.867-1.067-3.2-2.667-3.734v-3.2l12 5.6L40 12 20 2.667zM31.733 20l-12 5.333-6.4-2.933v.267c0 1.866-.8 3.466-2.133 4.8l1.6 3.733v.267c.267 1.066.533 2.133.267 3.2 1.866.8 4 1.333 6.666 1.333 8.8 0 12-5.333 12-8v-8z'
      />
    </svg>
  )
}
