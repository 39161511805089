import React from 'react'
import { useQuote } from '../../bussines/hooks/quote/useQuote'
import { useNavigate } from 'react-router-dom'
import { Quote } from './components/Quote'
import LottieCargando from '../../lotties/LottieCargando'
import { useAuth } from '../../providers/auth/auth-context-provider'
import { URL_LOGIN_PAGE } from '../login'
import SessionService from '../../services/SessionService'

const QuoteApplyContent = ({ quoteUuid }) => {
  const navigate = useNavigate()
  const { isPublicUser } = useAuth()
  const { isLoading, data: quote } = useQuote(quoteUuid, !isPublicUser)

  React.useEffect(() => {
    if (SessionService.isPublicSession()) {
      // Save in somwhere this url for the direction when the login is succesfull
      navigate(URL_LOGIN_PAGE)
    }
  }, [])

  if (isLoading || !quote) {
    return <LottieCargando />
  }

  return (
    <Quote quote={quote} />
  )
}

export { QuoteApplyContent }
