import React from 'react'
import { TitleSectionQuote } from '../../../common/components/TitleSectionQuote'
import { PublicThemeNone } from '../../../common/theme/PublicThemeNone'
import { Col, Row } from 'antd'
import { QuoteOverview } from './QuoteOverview'
import { QuoteCampus } from './QuoteCampus'
import { QuoteLocation } from './QuoteLocation'
import '../../../common/assets/css/main-theme.less'
import '../../../common/assets/css/quote-gallery.less'
import { useNavigate } from 'react-router-dom'
import SessionService from '../../../services/SessionService'
import { URL_QUOTER_PAGE } from '../../quoter'
import { QuoteAgency } from './QuoteAgency'
import { QuoteInstitution } from './QuoteInstitution'
import { ErrorBoundary } from '../../../providers/validation/ErrorBoundary'
import GoogleTranslateButton from '../../home/components/GoogleTranslateButton'
import { QuoteGalleryTemplateA } from './QuoteGalleryTemplateA'
import { MainThemeCarrouselQyp, ThemeCarrousel } from '../../../common/theme/partials/MainThemeCarrousel'
import logoAb from '../../../common/assets/images/logo.png'

const Quote = ({ quote, preview = false }) => {
  const navigate = useNavigate()

  const handleImage = () => {
    if (quote?.sede?.name !== 'ApplyBoard') {
      return quote?.sede?.profile_photo_url
    } else {
      return logoAb
    }
  }

  return (
    <PublicThemeNone>
      <div className='quote-previe-main' style={{}}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {handleImage()}
            <TitleSectionQuote
              label={'Quote valid until ' + quote?.expiration_date}
              icon={
                <div className='quote-agency-img'>
                  <img
                    // src={quote?.agency?.profilePhotoUrl ?? quote.provider.campus.logo}
                    src={quote?.agent?.sede?.name !== 'ApplyBoard' ? quote?.agent?.sede?.profile_photo_url : logoAb}
                    style={{ width: '100%' }}
                    alt={quote.custom_name}
                  />
                </div>
              }
              extra2={
                (SessionService.existSession()) ?
                  <div className='quote__translate-goback'>
                    <button className='quote-main-btn' style={{ marginRight: '10px' }} onClick={() => navigate(URL_QUOTER_PAGE)}>Go back</button>
                    {/* <MenuLanguages i18n={i18n} language={language} setLanguage={setLanguage} /> */}
                    <GoogleTranslateButton className='google_translate_container-quote' />
                  </div>
                  :
                  <div className='quote__translate-goback'>
                    <button className='quote-main-btn' style={{ display: 'none', marginRight: '10px' }} onClick={() => navigate(-1)}>Go back</button>
                    {/* <MenuLanguages i18n={i18n} language={language} setLanguage={setLanguage} /> */}
                    <GoogleTranslateButton className='google_translate_container-quote' />
                  </div>
              }
            />
            {quote.template === '1' && (
              <ThemeCarrousel slides={quote.sliders} urlProperty='profile_photo_url' />
            )}
            {quote.template === '2' && (
              <QuoteGalleryTemplateA gallery={quote.gallery} videoUrl={quote.video?.url} />
            )}
            {(!quote.template || quote.template === '3') && (
              <div style={{ borderRadius: '16px', overflow: 'hidden', marginBottom: '16px' }}>
                <MainThemeCarrouselQyp textProperty='text' />
              </div>
            )}
            {(quote.custom_name || quote.custom_message) && (
              <div className='quote-regards'>
                <h4 className='byp-title-4'>{quote.custom_name}</h4>
                <p className='txt-color' style={{ fontSize: '0.875rem' }}>{quote.custom_message}</p>
              </div>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16, order: 0 }}>
            <ErrorBoundary>
              <QuoteOverview
                course={quote.course} campus={quote.provider.campus} cost={quote.cost}
                campusCosts={quote.campus_costs} intake={quote.intake}
                expirationDate={quote?.expiration_date} returnDate={quote.return_date}
                arrivalDate={quote.arrival_date} quoteId={quote.uuid} preview={preview}
                agency={quote?.agency} lodging={quote.lodging} agent={quote.agent}
                discounts={quote.discounts} logo={quote.provider.logo}

              />
            </ErrorBoundary>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8, order: 1 }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <QuoteLocation campus={quote.provider.campus} />
              </Col>
              <Col span={24}>
                <QuoteCampus provider={quote.provider} />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <DiscoverButtons /> */}
        {
          quote.agent.sede.parent?.type === 'Institution' ? <QuoteInstitution quote={quote} /> : <QuoteAgency quote={quote} />
        }
      </div>
    </PublicThemeNone>
  )
}

export { Quote }
