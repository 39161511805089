import moment from "moment";
import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Slider,
} from "antd";
import { useSearch } from "../../../../providers/search/search-context-provider";
// import { useGetStartdateAndHolidays } from '../../../../bussines/hooks/quoter/useGetSearchData'
import { useAuth } from "../../../../providers/auth/auth-context-provider";
import { addDays, addWeeks } from "date-fns";
import { useGetCurrencyToCurrency } from "../../../../bussines/hooks/currencies/useGetCurrencyToCurrency";
import { useDebounce } from "../../../../utils/useDebounce";
import { languagesEs } from "../../../../bussines/utils/LanguagesES";
import { useGetOfferFiltersByProgram } from "../../../../bussines/hooks/offer/useGetOfferFiltersByProgram";
import { saveInStorage } from "../../../../utils/localStorageUtils";
import { useLocation } from "react-router-dom";
import { SelectInstitutionsWithOffers } from "../../../../common/components/helper-form/SelectInstitutions";
import { CheckCampusFormItem } from "../../../../common/components/helper-form/CheckCampusFormItem";

// const { RangePicker } = DatePicker

const dateFormat = "DD/MM/YYYY";
function LanguageFilters({
  state,
  type,
  setState,
  handleMigas,
  clearMigas,
  setShowMovilFilters,
  isMobileView,
}) {
  const { search, changeSearch, form, width, breakpoint } = useSearch();
  const [searchFilter, setSearchFilter] = useState({ program: "language" });
  const [showCities, setShowCities] = useState(true);

  const location = useLocation();
  const [, setOptionsData] = useState([]);
  const [optionCountries, setOptionsCountries] = useState([]);
  const [optionCities, setOptionsCities] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [, setSearchCity] = useState();
  const [, setId] = useState();
  const [, setType] = useState();
  const [weeks, setWeeks] = useState(2);
  const [dateString, setDateString] = useState();
  const [dateEndString, setDateEndString] = useState();
  const [minCosts, setMinCosts] = useState(0);
  const [maxCosts, setMaxCosts] = useState(0);
  const [cost, setCost] = useState(0);
  const debouncedCost = useDebounce(cost, 1600);

  const {
    program,
    place_type: placeType,
    place,
    search: textSearch,
    arrayCities,
  } = search;

  const [isFirst, setIsFirst] = useState(true);
  const { userLocalCurrency } = useAuth();
  const { data: filterData, isLoading: loadingFilters } =
    useGetOfferFiltersByProgram({
      search: textSearch,
      country: placeType === "country" ? place : undefined,
      program: "language",
    });
  const { data: newdata, getCurrencyToCurrency } = useGetCurrencyToCurrency();
  // const { data: dates } = useGetStartdateAndHolidays(program, { ...search })

  const handleSearch = (key, values) => {
    const isMovil = width <= breakpoint;
    const nValues = {
      ...search,
      ...searchFilter,
    };
    if (key === "school") {
      nValues.place = values;
      nValues.place_type = "institution";
    } else if (key === "place") {
      if (values) {
        const place = values.split("-");
        setId(place[0]);
        // setType(place[1])
        if (place[1] === "country") {
          nValues.place = place[0];
          nValues.place_type = place[1];
          nValues.idCountry = place[0];
          nValues.country_type = place[1];
          nValues.arrayCities = null;
        } else {
          nValues.place = place[0];
          nValues.place_type = place[1];
        }
      } else {
        delete search.place;
        delete search.place_type;
        delete nValues.place;
        delete nValues.place_type;
        setType();
        setId();
      }
    } else {
      nValues[key] = values;
    }
    if (isMovil) {
      setSearchFilter(nValues);
    } else {
      changeSearch("LANG_FORM", nValues);
    }
  };

  const handleOnSubmitSearch = () => {
    const countFilter = Object.keys(searchFilter).length;
    if (isNaN(searchFilter?.sede)) {
      delete searchFilter.sede;
    }
    if (isNaN(searchFilter?.lang)) {
      delete searchFilter.lang;
    }
    if (countFilter) {
      changeSearch("LANG_FORM", {
        ...search,
        ...searchFilter,
      });
      setShowMovilFilters(false);
    }
  };

  const disabledDate = (current) => {
    const today = moment().startOf("day");
    // Obtener las fechas de holidays y start_dates
    /* if (dates) {
      const { holidays, start_dates: startDates } = dates

      // Deshabilitar fechas pasadas
      if (current < today) return true

      // Deshabilitar días que no son lunes
      if (current.day() !== 1) return true

      // Habilitar o deshabilitar fechas de inicio según
      const isStartDateEnabled = startDates.some((startDate) => current.isSame(startDate.start_date, 'day'))
      if (isStartDateEnabled) return false

      // Deshabilitar días festivos
      const isHoliday = holidays.some((holiday) => current.isSame(holiday.date, 'day'))
      if (isHoliday) return true
    } else {
      // Deshabilitar fechas pasadas
      if (current < today) return true
      // Deshabilitar días que no son lunes
      if (current.day() !== 1) return true
    } */
    // Deshabilitar fechas pasadas
    if (current < today) return true;
    // Deshabilitar días que no son lunes
    if (current.day() !== 1) return true;
  };

  const cleanSearch = () => {
    form.resetFields();
    clearMigas();
    const newSearch = {};
    Object.assign(newSearch, search);

    delete newSearch.idLanguage;
    delete newSearch.lang;
    delete newSearch.dateAll;
    delete newSearch.place;
    delete newSearch.place_type;
    delete newSearch.search;
    delete newSearch.rate;
    delete newSearch.age;
    delete newSearch.school;
    delete newSearch.is_legal;
    delete newSearch.arrayLang;
    delete newSearch.arrayCities;
    delete newSearch.school_id;
    delete newSearch.school_type;
    delete newSearch.idCountry;
    delete newSearch.country_type;

    setCost(maxCosts);

    setId();
    setType();
    setShowCities(true);

    const queryString = Object.keys(newSearch)
      .map((key) => key + "=" + newSearch[key])
      .join("&");
    window.history.pushState(
      newSearch,
      "",
      location.pathname + "?" + queryString
    );

    changeSearch("LANG_FORM", {
      ...newSearch,
    });
  };

  const formatMoney = (cost) => {
    const newCost = cost * newdata?.data?.value;
    return newCost.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const changeCostRanges = (schools) => {
    let minCost;
    let maxCost;
    schools.forEach((element) => {
      if (element.min_cost < minCost || !minCost) {
        minCost = element.min_cost;
      }
      if (element.max_cost > maxCost || !maxCost) {
        maxCost = element.max_cost;
      }
    });
    setMinCosts(minCost);
    setMaxCosts(maxCost);
    setCost(search.cost ?? maxCost);
  };

  const handleArrayCities = (values) => {
    const array = values.map((item) => {
      return Number(item.split("-")[0]);
    });
    handleSearch("arrayCities", array);
    let cities;
    if (array.length > 0) {
      cities = optionCities.filter((city) => array.includes(city.id));
    } else {
      cities = optionCities;
    }
    handleInfo(cities, "cities");
    const languages = formatResponseLanguages(cities);
    setLanguageOptions(removeDuplicates(languages));

    const schools = formatResponseSchools(languages);
    setOptionsData(removeDuplicates(schools));
  };

  const handleArrayLanguages = (values) => {
    handleSearch("arrayLang", values);

    const language = languageOptions?.filter((item) =>
      values?.includes(item.id)
    );
    handleInfo(language, "languages");

    // Check the select cities. Get all languages and then, get the schools
    const selectedCities = form.getFieldValue("city");
    let citiesData = optionCities;
    if (selectedCities?.length > 0) {
      const array = selectedCities.map((item) => {
        return Number(item.split("-")[0]);
      });
      citiesData = optionCities.filter((city) => array.includes(city.id));
    }
    const languages = formatResponseLanguages(citiesData);
    const schools = formatResponseSchools(languages);
    setOptionsData(removeDuplicates(schools));
  };

  const handleOnSchoolChange = (value) => {
    handleSearch("school", value);
    const id = value;
    // Check the select cities. Get all languages and then, get the schools
    const selectedCities = form.getFieldValue("city");
    let citiesData = optionCities;
    if (selectedCities?.length > 0) {
      const array = selectedCities.map((item) => {
        return Number(item.split("-")[0]);
      });
      citiesData = optionCities.filter((city) => array.includes(city.id));
    }
    const languages = formatResponseLanguages(citiesData);
    const allSchools = formatResponseSchools(languages);
    const schools = allSchools.filter((school) => school.id === Number(id));
    changeCostRanges(schools);
  };

  const handleAge = (values) => {
    if (values && values?.length === 1) {
      values.forEach((item) => {
        handleSearch("is_legal", item);
      });
    } else {
      handleSearch("is_legal", null);
    }
  };

  const tipFormatter = (value) => {
    // Multiplicar el valor por 2
    const formattedValue = value * newdata?.data?.value;
    return `${formattedValue}`;
  };

  function quitarAcentos(texto) {
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  function buscarSinAcentos(palabra, texto) {
    palabra = quitarAcentos(palabra);
    texto = quitarAcentos(texto);

    return texto.toLowerCase().includes(palabra.toLowerCase());
  }

  const formatResponseLanguages = (cities) => {
    const languageData = [];
    cities.forEach((city) => {
      const languages = city.languages ?? [];
      const keys = Object.keys(languages);
      keys.forEach((key) => {
        languageData.push(languages[key]);
      });
    });
    return languageData;
  };

  const formatResponseSchools = (languages) => {
    const schoolData = [];
    languages.forEach((lang) => {
      const schools = lang.schools ?? [];
      const keys = Object.keys(schools);
      keys.forEach((key) => {
        const campus = schools[key];
        schoolData.push({
          id: campus.id,
          value: campus.name,
          type: "campus",
          max_cost: campus.max_cost,
          min_cost: campus.min_cost,
        });
      });
    });
    return schoolData;
  };

  const removeDuplicates = (data) => {
    const uniqueData = [];
    data.forEach((dataItem) => {
      const isDuplicated = uniqueData.find((lang) => lang.id === dataItem.id);
      if (!isDuplicated) uniqueData.push(dataItem);
    });
    return uniqueData;
  };

  const selectedCountries = Form.useWatch("country", form);
  const selectedSchool = Form.useWatch("school", form);

  useEffect(() => {
    if (!loadingFilters && filterData) {
      const countries = filterData.countries;
      const keys = Object.keys(countries);

      const countryData = [];
      keys.forEach((key) => {
        const country = countries[key];

        const cityData = [];
        const cities = country.cities ?? [];
        const cityKeys = Object.keys(cities);
        cityKeys.forEach((key) => {
          const city = cities[key];
          cityData.push({
            id: city.id,
            value: city.name,
            type: "city",
            languages: city.languages,
            offers: city.offers,
          });
        });

        countryData.push({
          id: country.id,
          value: country.name,
          type: "country",
          offers: country.offers,
          cities: cityData,
        });
      });
      setOptionsCountries([...countryData]);

      const cityData = [];
      countryData.forEach((country) => {
        const cities = country.cities ?? [];
        cityData.push(...cities);
      });
      if (cityData.length <= 5) {
        setOptionsCities([...cityData]);
      } else {
        // TOP 5 cities
        const sorted = cityData.sort(function (a, b) {
          if (a.offers < b.offers) {
            return 1;
          }
          return -1;
        });
        const topCities = sorted.slice(0, 5);
        setOptionsCities(topCities);
      }

      // All Languages (here will be much duplicated values)
      const languages = formatResponseLanguages(cityData);
      setLanguageOptions(removeDuplicates(languages));

      // All schools (here will be much duplicated values)
      const schools = formatResponseSchools(languages);
      setOptionsData(removeDuplicates(schools));
      changeCostRanges(schools);

      if (state?.location && isFirst) {
        if (cityData?.length > 0 && state.type === "city") {
          const cities = cityData?.find((item) =>
            item.value.toLowerCase().includes(state?.location?.toLowerCase())
          );
          if (cities) {
            setIsFirst(false);
            form.setFieldsValue({
              city: [cities?.id + "-city"],
            });
          }
        } else if (countryData?.length > 0 && state.type === "country") {
          const countries = countryData?.find((item) =>
            item.value.toLowerCase().includes(state?.location?.toLowerCase())
          );
          if (countries) {
            form.setFieldsValue({
              place: countries?.id + "-country",
              country: [countries?.id],
            });
            setIsFirst(false);
          }
        }
      }

      if (state && state?.searchLanguage && isFirst) {
        const language = languages.find((item) =>
          buscarSinAcentos(languagesEs[item.name], state?.searchLanguage)
        );
        if (language) {
          form.setFieldsValue({
            lang: [language.id],
          });
        }
      }

      if (state?.searchLocation && isFirst) {
        const country = countryData?.find((item) =>
          item.value
            .toLowerCase()
            .includes(state?.searchLocation?.toLowerCase())
        );
        if (country) {
          form.setFieldsValue({
            place: country?.id + "-country",
          });
        } else {
          const city = cityData?.find((item) =>
            item.value
              .toLowerCase()
              .includes(state?.searchLocation?.toLowerCase())
          );
          if (city) {
            form.setFieldsValue({
              city: [city?.id + "-city"],
            });
          }
        }
        setIsFirst(false);
      }
    }
  }, [loadingFilters]);

  useEffect(() => {
    if (search) {
      if (search?.place && search?.place_type) {
        setId(search?.place);
        setType(search?.place_type);
        if (search?.place_type === "country") {
          form.setFieldsValue({
            place: search?.place + "-" + search?.place_type,
          });
        }
        if (search?.place_type === "city") {
          form.setFieldsValue({
            city: [search?.place + "-" + search?.place_type],
          });
        }
      }
      setSearchCity();
      // if (search.cost !== cost) {
      //   setCost(search.cost)
      // }
    }
  }, [search]);

  useEffect(() => {
    getCurrencyToCurrency(userLocalCurrency, "USD");
  }, []);

  useEffect(() => {
    if (debouncedCost > 0 && debouncedCost < maxCosts) {
      handleSearch("cost", debouncedCost);
      handleInfo(formatMoney(debouncedCost), "cost");
    }
  }, [debouncedCost]);

  useEffect(() => {
    if (weeks && dateString) {
      const day = new Date(dateString).getDay() ?? 1;
      let daysToFriday = 5 - day;

      if (daysToFriday < 0) {
        daysToFriday = daysToFriday + 7;
      }

      const endOfFirstWeek = addDays(new Date(dateString), daysToFriday);
      const endDate = addWeeks(endOfFirstWeek, weeks - 1);

      const formattedEndDate = endDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      handleSearch("dateEnd", moment(formattedEndDate).format("DD/MM/YYYY"));
      setDateEndString(moment(formattedEndDate));
    }
  }, [weeks, dateString]);

  useEffect(() => {
    if (selectedCountries) {
      // Set The cities
      let countries;
      countries = [];
      // if (placeType === 'city') {
      //   countries = optionCountries.filter((country) => country.cities?.find(city => Number(city.id) === Number(countryId)))
      // } else {
      // }
      countries = optionCountries.filter((country) =>
        selectedCountries.includes(Number(country.id))
      );
      if (!countries) {
        return console.log("Country not found", countries);
      }
      const cities = [];
      countries.forEach((element) => {
        cities.push(...element.cities);
      });
      if (cities.length <= 5) {
        setOptionsCities(cities);
      } else {
        // TOP 5 cities
        const sorted = cities.sort(function (a, b) {
          if (a.offers < b.offers) {
            return 1;
          }
          return -1;
        });
        const topCities = sorted.slice(0, 5);
        setOptionsCities(topCities);
      }
      // set the languages
      const languages = formatResponseLanguages(cities);
      setLanguageOptions(removeDuplicates(languages));

      const schools = formatResponseSchools(languages);
      setOptionsData(removeDuplicates(schools));
    }
  }, [selectedCountries]);

  const handleInfo = (e, type, option) => {
    if (type === "place") {
      handleMigas(option.children, type);
    } else {
      handleMigas(e, type);
    }
  };

  return (
    <Form form={form}>
      <Row gutter={[0, { xs: 16, sm: 16 }]} className="school-search__inner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h4>{isMobileView ? "Filtrar por" : "Búsqueda Avanzada"}</h4>
            </Col>
            <Col>
              <Button
                onClick={cleanSearch}
                type="ghost"
                className="btn-clean-filters"
              >
                Limpiar Filtros
              </Button>
            </Col>
          </Row>
        </Col>

        {/* Start And Duration */}
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <h6 className="school-search__title">
                ¿Cuándo te quieres ir a estudiar?
              </h6>
            </Col>

            {/* gutter={[0, { xs: 16, sm: 0 }]} */}
            <Col xs={24} md={14}>
              <label className="school-search__label">Inicio de clases</label>
              <Form.Item noStyle name="date">
                <DatePicker
                  className="school-search__item"
                  placeholder="Cualquier fecha"
                  size="large"
                  format={dateFormat}
                  disabledDate={disabledDate}
                  onChange={(date, dateString) => {
                    handleSearch("dateAll", dateString);
                    setDateString(date);
                    saveInStorage("search_course_start", date);
                    handleInfo(dateString, "dateAll");
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={10}>
              <label className="school-search__label">Duración</label>
              <Form.Item name="weeks" noStyle>
                <InputNumber
                  className="school-search__item"
                  size="large"
                  onChange={(weeks) => {
                    setWeeks(weeks);
                    handleSearch("weeks", weeks);
                    saveInStorage("search_course_weeks", weeks);
                    handleInfo(weeks, "weeks");
                  }}
                  addonAfter="semanas"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label
                className="school-search__label"
                style={{ marginTop: "12px" }}
              >
                {dateEndString && dateEndString?.format(dateFormat)}
              </label>
            </Col>
          </Row>
        </Col>

        {showCities && optionCities.length > 0 && (
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h6 className="school-search__title">Ciudades</h6>
              </Col>
              <Col span={24}>
                <Form.Item name="city" style={{ marginBottom: 0 }}>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    onChange={(e, option) => {
                      handleArrayCities(e);
                    }}
                  >
                    <Row>
                      {optionCities?.map((option) => (
                        <Col span={24} key={option.id + "-" + option.type}>
                          <Checkbox
                            className="school-search__checkbox"
                            value={option.id + "-" + option.type}
                          >
                            {option.value} {option.offers}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}
        {/* Language */}
        <Col span={24}>
          <Row>
            <Col span={24}>
              <h6 className="school-search__title">Idioma a estudiar</h6>
            </Col>
            <Col span={24}>
              <Form.Item noStyle name="lang">
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleArrayLanguages(e);
                  }}
                >
                  <Row>
                    {languageOptions?.map((item) => (
                      <Col span={24} key={item.id}>
                        <Checkbox
                          className="school-search__checkbox"
                          value={item.id}
                        >
                          {languagesEs[item.name] ?? item.name}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        {/* schools */}
        <Col span={24}>
          <Row>
            <Col span={24}>
              <h6 className="school-search__title">Escuela</h6>
            </Col>
            <Col span={24} >
              <SelectInstitutionsWithOffers
                name="school"
                showSearch
                placeholder="Escuela"
                size="large"
                style={{ borderRadius: "2px !important", marginBottom: 0 }}
                filters={{
                  program,
                  type,
                  whereHasOffers: true,
                  country: placeType === "country" ? place : undefined,
                  offersSearch: textSearch,
                  city: arrayCities,
                }}
                onChange={(e, option) => {
                  setShowCities(!e);
                  handleOnSchoolChange(e);
                  handleInfo(option?.children, "school");
                }}
              />
            </Col>
          </Row>
        </Col>
        {/* Campus */}
        {selectedSchool && (
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h6 className="school-search__title">Campus</h6>
              </Col>
              <CheckCampusFormItem
                filters={{
                  program: "language",
                  whereHasOffers: true,
                  institution: selectedSchool,
                }}
                onChange={(val) => {
                  const isMovil = width <= breakpoint;
                  if (isMovil) {
                    setSearchFilter({
                      ...searchFilter,
                      place: val,
                      place_type: "campus",
                    });
                  } else {
                    changeSearch("LANG_FORM", {
                      ...search,
                      place: val,
                      place_type: "campus",
                      page: 1,
                    });
                  }
                }}
                name="campus"
              />
            </Row>
          </Col>
        )}

        {/* Edad */}
        <Col span={24}>
          <Row>
            <Col span={24}>
              <h6 className="school-search__title">Edad</h6>
            </Col>
            <Col span={24}>
              <Form.Item name="is_legal" style={{ marginBottom: 0 }}>
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={(e, option) => {
                    handleAge(e);
                    handleInfo(e, "age");
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <Checkbox className="school-search__checkbox" value="no">
                        Menos de 16
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox className="school-search__checkbox" value="yes">
                        Más de 16
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        {/* Cost */}
        {maxCosts > minCosts && (
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h6 className="school-search__title">
                  Precio aproximado del curso
                </h6>
                <p className="school-search__cost">
                  {userLocalCurrency} {formatMoney(cost)}+
                </p>
                <Slider
                  className="school-search__slider"
                  value={cost}
                  min={minCosts}
                  max={maxCosts}
                  tipFormatter={tipFormatter}
                  onChange={(e) => {
                    setCost(e);
                  }}
                />
              </Col>
            </Row>
          </Col>
        )}

        {width <= breakpoint && (
          <Col span={24}>
            <Button
              onClick={handleOnSubmitSearch}
              block
              size="large"
              className="byp-btn-red-50 btn--text-responsive"
            >
              Buscar
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
}

export { LanguageFilters };
