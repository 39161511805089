import { Statistic, Typography } from 'antd'
import React from 'react'
import { usePackage } from '../../../providers/package/package-course-context'
import { RowQuoter } from '../../quote-course/components/RowQuoter'
import { getDiscountSymbol, handlePrice } from '../../../utils/getDiscountSymbol'
// import { format } from 'date-fns'
// import { formatStringDate } from '../../../utils/formatStringDate'

const { Text } = Typography

function PackagePrice() {
  const { service } = usePackage()

  return (
    <>
      <RowQuoter
        colA={
          <>
            {service.getPrices() && (
              service.getPrices().costs?.map((d) => (
                <Text className='conceptMonetario' key={d.id}>{d.name}</Text>
              ))
            )}
            {
              service.courseHasDiscount() && service.getCourseDiscounts()?.map(d => (
                <p key={d.id} className='byp-fw-bold'>
                  {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Curso.
                </p>
              ))
            }

          </>
        }
        colB={
          <>
            {service.getPrices() && (
              service.getPrices().costs?.map((d) => (
                <div key={d.id} className='conceptMonetario'>
                  <Statistic
                    prefix=''
                    precision={2}
                    value={`${d.cost}`}
                    // suffix={`${service.getCurrency()}`}
                  />
                </div>
              ))
            )}
            {
              service.courseHasDiscount() && service.getCourseDiscounts()?.map(d => (
                <Statistic
                  prefix=''
                  className='conceptMonetario'
                  precision={2}
                  value={`${d.type === 'amount' ? d.value : handlePrice(d.value, service.getAmountInscription()?.toFixed(2))}`}
                  // suffix={`${service.getCurrency()}`}
                />
              ))

            }
          </>
        }
      />
    </>
  )
}

export { PackagePrice }
