import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row, Select } from 'antd'
import { useGetCitiesBlog, useGetPages } from '../../../../bussines/hooks/page/useGetPages'
import { Link } from 'react-router-dom'
import { URL_TABLE_BLOGS_PAGE } from '../../blogs/PageTableBlogs'

const { Option } = Select

function ModalAddBlog ({ isModalOpen, handleOk, handleCancel, code, city, setBlog, blogsIds }) {
  const [cityId, setCityId] = useState(city)
  const { data: dataBlogs } = useGetPages({ country: code, city_id: cityId, exclude_blogs: blogsIds })
  const { data: citiesBlogs } = useGetCitiesBlog({ country: code, city_id: cityId, exclude_blogs: blogsIds })

  const handleChangeBlogs = (value) => {
    if (value) {
      const blog = dataBlogs?.data?.find((blog) => value === blog.id)
      setBlog(blog)
    }
  }

  useEffect(() => {
    setBlog()
  }, [])

  return (
    <Modal
      title='Blog'
      visible={isModalOpen}
      width={360}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='Guardar'
      cancelText='Cancelar'
      className='modal__add-blog'
    >
      <h5>Mostrar Entrada</h5>
      <Row>
        <Col span={24}>
          <label className='blog__modal-edit'>Ciudad</label>
          <Form.Item
            style={{ margin: '0' }}
            name='cities-blogs'
          >
            <Select
              className='edit-programs-blog__select'
              onChange={setCityId}
              value={cityId}
            >
              {citiesBlogs?.cities?.map((item) => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <label className='blog__modal-edit'>Entradas</label>
          <Form.Item
            style={{ margin: '0' }}
            name='blogs'
          >
            <Select
              className='edit-programs-blog__select'
              onChange={handleChangeBlogs}
            >
              {dataBlogs?.data?.map((blog) => (
                <Option key={blog.id} value={blog.id}>{blog.title}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <a>
          <Link to={URL_TABLE_BLOGS_PAGE}>
            Activar nueva entrada
          </Link>
        </a>
      </Row>
    </Modal>
  )
}

export default ModalAddBlog
