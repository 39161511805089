import { Form, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetAllNationalities } from '../../../bussines/hooks/quoter/useGetAllNationalities'
const { Option } = Select

function SelectCountriesFormItem ({ name, placeholder, limit = null, countries = null, global = false, all = false, setCountry, ...props }) {
  const { isLoading, data: dCountries } = useGetAllNationalities()
  const [optionsSelected, setOptionsSelected] = useState([])

  useEffect(() => {
    setOptionsSelected(countries || [])
  }, [countries])

  const handleChange = value => {
    setOptionsSelected(value)
    if (setCountry) {
      const found = dCountries?.find((country) => {
        return country.id === value
      })
      setCountry(found)
    }
  }

  return (
    <>
      <Form.Item name={name} {...props} style={{ marginBottom: 0 }}>
        <Select
          {...props}
          loading={isLoading}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          placeholder={placeholder ?? ''}
          onChange={props.onChange ?? handleChange}
          allowClear
        >
          {all && <Option key='all' value={null}>All</Option>}
          {global && <Option key='g' value='g'> Global </Option>}
          {dCountries?.map(item => (
            <Option
              key={item.id}
              value={item.id}
              disabled={limit ? optionsSelected.length === limit : false}
            >
              {`${item.name}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

export { SelectCountriesFormItem }
