import { addMonths } from 'date-fns'

/**
 * Get the base month of the intake
 * @param {String} intake
 * @returns {Number} Month number
 */
const getIntakeBaseMonth = (intake) => {
  switch (intake.toLowerCase()) {
    case 'spring':
      return 2 // Marzo
    case 'summer':
      return 5 // Junio
    case 'fall':
      return 8 // Septiembre
    case 'winter':
      return 11 // Diciembre
    default:
      console.log('🚀 ~ getIntakeBaseMonth ~ intake:', intake)
      throw new Error('Estación del año no válida')
  }
}

/**
 * Get the intake of a month
 * @param {Number} monthNumber
 * @returns {String} intake
 */
const getIntakeOfMonth = (monthNumber) => {
  if (monthNumber >= 2 && monthNumber < 5) {
    return 'Spring'
  }
  if (monthNumber >= 5 && monthNumber < 8) {
    return 'Summer' // summer
  }
  if (monthNumber >= 8 && monthNumber < 11) {
    return 'Fall' // fall
  }
  if (monthNumber >= 11 || monthNumber < 2) {
    return 'Winter' // winter
  }
  console.log('🚀 ~ getIntakeOfMonth ~ monthNumber:', monthNumber)
  throw new Error('Mes no válido')
}

const calcularIntakeFinal = (intake, mesesAdicionales, yearStart) => {
  let year = yearStart
  let intakeClean = intake
  intakeClean = intakeClean?.toLowerCase()

  // Check if the intake includes the year
  const splits = intake.split(' ')

  if (splits.length > 1) {
    year = Number(splits[1])
    intakeClean = splits[0]
  } else {
    const tempDate = new Date()
    year = tempDate.getFullYear()
  }

  const intakeMonth = getIntakeBaseMonth(intakeClean)
  // const intakeEndDate2 = addWeeks(intakeDate, 104)

  const intakeDate = new Date(yearStart + '/' + intakeMonth + '/01')
  const intakeEndDate = addMonths(intakeDate, mesesAdicionales)

  const endMonth = intakeEndDate.getMonth()
  const intakeEnd = getIntakeOfMonth(endMonth)
  year = intakeEndDate.getFullYear()

  return intakeEnd + ' ' + year
}

export { getIntakeBaseMonth, calcularIntakeFinal, getIntakeOfMonth }
