import { useQuery } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'
import removeInvalidValuesFromParams from '../../../utils/removeInvalidValuesFromParams'

function useGetGrades (filters = {}, enabled = true) {
  // const queryString = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  const validParams = removeInvalidValuesFromParams(filters)
  return useQuery(['useGetGrades', validParams],
    () => CourseRepository.getGrades(validParams), {
      retry: 2,
      enabled
    })
}

export { useGetGrades }
