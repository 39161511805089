import React, { useState } from 'react'
import { Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import { MainRegisterFieldsContainer } from '../steps/main-register/MainRegisterFieldsContainer'
import SessionService from '../../../../services/SessionService'
import { URL_QUOTER_PAGE } from '../../../quoter'
import { BYP_PUBLIC_USER } from '../../../../config'
import { URL_POLICY_PAGE } from '../../PolicyPage'
import { useCreateUser } from '../../../../bussines/hooks/agency/useCreateUser'
import { showErrorModal } from '../../../../utils/errorModal'
import { retrieveFromStorage, saveInStorage } from '../../../../utils/localStorageUtils'
import { getCountryData } from '../../../../utils/getCountryName'

/**
 * User register from
 * If the requireTermsAndonditions is set to true, it will send the user to
 * the policy page to continue with the process
 */
const requireTermsAndonditions = false
const userFullLocationKey = '___BYP_KEY_FULL_LOCATION___'

function RegisterForm ({ email, type, role, invitation = false, isOnModal = false }) {
  const navigate = useNavigate()
  const { register, currentState, state, isLoading, errorMessage } = useCreateUser()
  const [isGoogleUser, setIsGoogleUser] = useState(false)
  const [google, setGoogle] = useState()
  const [picture, setPicture] = useState()
  const [userCountryLocation, setUserCountryLocation] = useState()
  const [temp, setTemp] = useState({
    password: process.env.REACT_APP_PASSWORD || '',
    confirm: process.env.REACT_APP_PASSWORD || '',
    email,
    type,
    role,
    location: userCountryLocation
  })

  React.useEffect(() => {
    if (SessionService.existSession()) {
      const session = SessionService.getCurrentSession()
      const isGeneralUser = session?.user?.email === BYP_PUBLIC_USER
      if (!isGeneralUser) navigate(URL_QUOTER_PAGE)
    }
    if (!userCountryLocation) {
      const userLocation = retrieveFromStorage(userFullLocationKey)
      if (userLocation) {
        setUserCountryLocation(JSON.parse(userLocation))
      }
      const getLocation = async () => {
        if (!userLocation) {
          const res = await getCountryData()
          saveInStorage(userFullLocationKey, JSON.stringify(res))
          setUserCountryLocation(res)
        }
      }
      getLocation()
    }
  }, [])

  React.useEffect(() => {
    if (!isLoading && currentState === state.STATE_ERROR) {
      showErrorModal(null, errorMessage)
    }
    if (!isLoading && currentState === state.STATE_SUCCESS) {
      const route = process.env.REACT_APP_URL
      window.location.href = route
    }
  }, [currentState, isLoading])

  const onFinish = (values) => {
    if (userCountryLocation?.postal) userCountryLocation.postal = String(userCountryLocation.postal)
    const state = {
      ...values,
      invitation,
      role,
      type: temp.type || values.type,
      isGoogleUser,
      google,
      profile_photo_path: picture,
      location: userCountryLocation
    }
    if (requireTermsAndonditions) {
      setTemp(state)
      navigate(URL_POLICY_PAGE, { state })
    } else {
      register(state)
    }

        // Redirection logic based on form values
        if (values.programs.includes('Idiomas')) {
          window.location.href = `${process.env.REACT_APP_URL}/home?program=language&place=3&place_type=institution`;
        } else if (values.programs.includes('Diplomas')) {
          window.location.href = `${process.env.REACT_APP_URL}/home?all=true&program=graduate&type=diploma&place=3&institution=3&place_type=institution`;
        } else if(values.programs.includes('Certificados')){
          window.location.href = `${process.env.REACT_APP_URL}/home?all=true&program=graduate&type=diploma&place=3&institution=3&place_type=institution`;
        } else if(values.programs.includes('Licenciaturas')){
          window.location.href = `${process.env.REACT_APP_URL}/home?all=true&program=graduate&type=bachelor`;
        } else if(values.programs.includes('Maestrías')){
          window.location.href = `${process.env.REACT_APP_URL}/home?all=true&program=graduate&type=master`;
        } else if(values.programs.includes('Posgrados')){
          window.location.href = `${process.env.REACT_APP_URL}/home?all=true&program=graduate&type=postgraduate`;
        } else if(values.programs.includes('Preparatoria')){
          window.location.href = `${process.env.REACT_APP_URL}/home?all=true&program=academic_years`;
        } else if(values.programs.includes('Boardings')){
          window.location.href = `${process.env.REACT_APP_URL}/home?all=true&program=boarding_schools`;
        }
  }

  return (
    <Form layout='vertical' onFinish={onFinish} initialValues={temp}>
      <MainRegisterFieldsContainer
        type={type}
        invitation={invitation}
        displayCompaneNameField
        isGoogleUser={isGoogleUser}
        setIsGoogleUser={setIsGoogleUser}
        setGoogle={setGoogle}
        setPicture={setPicture}
        isOnModal={isOnModal}
        loading={isLoading}
      />
    </Form>
  )
}

export { RegisterForm }
