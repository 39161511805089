import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useGetGrades } from '../../../bussines/hooks/quoter/useGetGrades'
const { Option } = Select

// Return institutions types that has offers
const SelectGradesFormItem = ({ name, filters = {}, noStyle, onChange, selectProps = {}, ...formItemProps }) => {
  const [enabledFilters, setEnabledFilters] = useState(true)
  const { data, isLoading } = useGetGrades(filters, enabledFilters)

  useEffect(() => {
    if (data) {
      // console.log('🚀 ~ file: SelectGradesFormItem.js:10 ~ useEffect ~ data', data)
    }
  }, [data])

  const handleOnChange = (val) => {
    if (val) {
      setEnabledFilters(false)
    } else {
      setEnabledFilters(true)
    }
    if (onChange) onChange(val)
  }

  return (
    <Form.Item
      name={name}
      noStyle={noStyle}
      {...formItemProps}
    >
      <Select
        showSearch
        onChange={handleOnChange}
        notFoundContent={isLoading ? 'Cargando opciones disponibles...' : 'No se encontró coincidencias'}
        {...selectProps}
      >
        {data?.map((item) => (
          <Option key={'grade_' + item} value={item}>{item}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectGradesFormItem }
