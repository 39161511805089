import { Button, Carousel, Image } from 'antd'
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons'

const InfoQuoteCampusCarrousel = ({ slides, className = '', label, slidesPerRow = 4 }) => {
  return (
    <Carousel
      autoplay={false}
      effect='fade'
      dots={false}
      slidesPerRow={slidesPerRow}
      className={className}
      nextArrow={
        <Button className='carrousel-bottoms'>
          <RightCircleFilled className='carrousel-bottoms__right' style={{ fontSize: '35px', color: ' #373737' }} />
        </Button>
      }
      prevArrow={
        <Button className='carrousel-bottoms'>
          <LeftCircleFilled className='carrousel-bottoms__left' style={{ fontSize: '35px', color: ' #373737' }} />
        </Button>
      }
      arrows
    >
      {slides?.map((collection) => (
        <div
          key={collection.id}
          className='byp-centered'
        >
          <div className='byp-centered'>
            <Image
              src={collection.src}
              className='byp-image'
              height='7rem'
              style={{
                objectFit: 'cover'
              }}
            />
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export { InfoQuoteCampusCarrousel }
