import { Image, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { URL_BLOG_PAGE } from '../BlogPage'
import { useNavigate } from 'react-router-dom'
import { BiTrash } from 'react-icons/bi'
import { EditIcon } from '../../../../common/theme/icons/EditIcon'
import { ArrowRightIcon } from '../../../../common/assets/svg/icons'

const defaultImage = 'https://images.unsplash.com/photo-1694619361594-92128061b0a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
export function CardBlogSimple ({ item, editBlogs, removeItem, id, editItem }) {
  const [cover, setCover] = useState()
  const navigate = useNavigate()
  const handleClick = () => {
    if (item && item?.link) {
      window.location.href = item.link
    } else {
      navigate(URL_BLOG_PAGE.replace(':slug', item?.slug))
    }
  }

  const handleTitle = (title) => {
    if (typeof title === 'object' && title.rendered) {
      title = title.rendered
    }
    const arrayTitle = title?.split(' ')
    if (arrayTitle?.length > 5) {
      const newTitle = arrayTitle?.slice(0, 6)?.join(' ')
      return newTitle + '...'
    } else {
      return title
    }
  }

  useEffect(() => {
    if (item) {
      let cover = null

      if (item.thumbnail) {
        cover = item?.thumbnail
      } else {
        cover = item?.files?.find((file) => file.type === 'image' && file.pivot?.position === 'Cover')?.src
      }

      setCover(cover)
    }
  }, [item])
  return (
    <div className='blog-card-simple box-shadow-cards' onClick={handleClick}>
      {editBlogs && (
        <div className='blog-card__btns'>
          <BiTrash onClick={() => removeItem(id)} />
          <EditIcon onClick={() => editItem(id)} />
        </div>
      )}
      <Image
        // width='100%'
        //className='blog-cover'
        preview={false}
        src={cover}
        width={'100%'}
        height={'200px'}
      />
      <div className='blog-info'>
        <Typography.Title level={5} className='blog-title'>{handleTitle(item?.title)}</Typography.Title>
        <div className='blog-link' size='large'>
          Leer Más <ArrowRightIcon className='arrow-icon' />
        </div>
      </div>
    </div>
  )
}
