import { useQuery } from 'react-query'
import PageRepository from '../../repositories/PageRepository'
import removeInvalidValuesFromParams from '../../../utils/removeInvalidValuesFromParams'

function useGetPages (filters = {}) {
  const validFilters = removeInvalidValuesFromParams(filters)
  return useQuery(
    ['useGetPages', validFilters],
    () => PageRepository.pages(validFilters),
    {
      retry: 1
    }
  )
}

function useGetCitiesBlog (filters = {}) {
  return useQuery(
    ['useGetCitiesBlog', filters],
    () => PageRepository.getCitiesBlog(filters),
    {
      retry: 1
    }
  )
}

export { useGetPages, useGetCitiesBlog }
