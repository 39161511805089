import { useQuery } from 'react-query'
import CourseRepository from '../../../repositories/CourseRepository'

function useGetCoursesByProgram (program) {
  return useQuery(['useGetCoursesByProgram', program],
    () => CourseRepository.getCoursesByProgram(program), {
      retry: 2,
      disabled: !!program
    })
}

export { useGetCoursesByProgram }
