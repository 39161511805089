import { useQuery } from 'react-query'
import CollectionRepository from '../../repositories/CollectionRepository'

function useGetCollections (filters = {}, enabled = true) {
  return useQuery(['useGetCollections', filters], () => CollectionRepository.collections(filters), {
    retry: 2,
    enabled
  })
}
export { useGetCollections }
