import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { FeaturedCard } from './Card'
import { HomeCarrousel } from '../../../../common/components/HomeCarrousel'
import { useGetRelatedBlogs } from '../../../../bussines/hooks/page/useGetRelatedBlog'
import { BasicSpinner } from '../../../../common/components/BasicSpinner'
import { Link, useNavigate } from 'react-router-dom'
import { URL_BLOG_PAGE } from '../BlogPage'
import { URL_BLOGS_BY_TAG_PAGE, URL_BLOGS_SEARCH_PAGE } from '../../blogs/PageBlogs'

export function BlogAds({ tags, blogSlug }) {
  const navigate = useNavigate()
  const { isLoading: isSearchingBlogs, data: relatedData, errorBlogs } = useGetRelatedBlogs(blogSlug)

  useEffect(() => {
    if (!isSearchingBlogs && errorBlogs) {
      // Manejo de errores si es necesario
    }
  }, [isSearchingBlogs])

  if (isSearchingBlogs) {
    return <BasicSpinner />
  }

  return (
    <>
      <h3 className='blog-ads__title-tag byp-mt-1'>Etiquetas</h3>
      <div className='blog-ads__tags'>
        {tags?.map(tag => (
          <span key={tag.id}>
            <Link to={URL_BLOGS_BY_TAG_PAGE.replace(':tag', tag.slug)}>
              {tag.name}
            </Link>
          </span>
        ))}
      </div>

      <h3 className='blog-ads__title-tag byp-mt-1'>Artículos Relacionados</h3>
      <HomeCarrousel
        slides={relatedData?.related || []} 
        Slide={({ item }) => (
          <FeaturedCard
            item={item}
            onClick={() => navigate(URL_BLOG_PAGE.replace(':slug', item.slug))}
          />
        )} 
        className='quotation__footer-carrousel big-home__package edit_carousel edit_page-country' 
        slidesPerRow={4} 
      />
      {relatedData?.related && ( 
        <div className='quotation__footer-carrousel-responsive'>
          <div>
            {relatedData?.related.map(item => (
              <FeaturedCard item={item} key={item.id} />
            ))}
          </div>
        </div>
      )}

    </>
  )
}
