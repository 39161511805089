import React from 'react'
import { PageProtected } from '../../../common/components/PageProtected'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { MainTheme } from '../../../common/theme/MainTheme'
import PublicityCourseInfo from './PublicityCourseInfo'

const URL_PUBLICITY_COURSE_PAGE = '/publicidad/curso'

function PublicityCoursePage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
    >
      <MainTheme
        showMenu
        className='site-layout__padding'
      >
        <div className='byp-align-centered' style={{ padding: 0 }}>
          <PublicityCourseInfo />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { PublicityCoursePage, URL_PUBLICITY_COURSE_PAGE }
