import React from 'react'
import { useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../providers/validation/ErrorBoundary'
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { QuoteApplyContent } from './QuoteApplyContent'
import { Helmet } from 'react-helmet'

const URL_QUOTE_APPLY_PREVIEW = '/quote-apply/:id'

const QuotePreviewApply = () => {
  const { id } = useParams()
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
    >
      <ErrorBoundary>
        <Helmet>
          <title>Aparta tu lugar</title>
        </Helmet>
        <QuoteApplyContent quoteUuid={id} />
      </ErrorBoundary>
    </PageProtected>
  )
}

export { QuotePreviewApply, URL_QUOTE_APPLY_PREVIEW }
