import { Avatar, Card, Col, Rate, Row, Typography } from 'antd'
// import { LikeOutlined } from '@ant-design/icons'
import { BYP_PUBLIC_USER } from '../../../config'
import { useEffect, useState } from 'react'

function CardOpinions({ item }) {
  const [generalRate, setgeneralRate] = useState()
  useEffect(() => {
    if (item) {
      const general = item.rates?.find(rate => rate.type === 'general')
      setgeneralRate(general?.rate)
    }
  }, [])

  return (
    <Card className='card-opinion'>
      {/** CONTENIDO **/}
      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignSelf: 'stretch', padding: '6px' }}>
        {/** avatar + estrellas + opinion **/}
        <div>
          <Row>
            <Col span={24}>
              <Row style={{ width: '100%', marginBottom: '1rem' }}>

                <Col span={24} className='byp-mb-1' >
                  <Row gutter={6} style={{ width: '100%', justifyContent:'space-between', marginBottom:'6px'}}>
                    <Col>
                      <p className='card-opinion__user'> <Avatar src={item?.user?.profile_photo_url} className='card-opinion__avatar' /> {item?.user?.email === BYP_PUBLIC_USER ? 'Anónimo' : item?.user?.name}</p>
                    </Col>
                    <Col>
                      <Rate value={generalRate} allowHalf disabled className='byp-gray-rate byp-rate-sm' />
                    </Col>
                  </Row>
                </Col>

                {/** opinion **/}
                <Col span={24}>
                  <Typography.Title level={4} style={{ width: '100%', marginBottom: '6px' }}>
                    {item?.title}
                  </Typography.Title>
                  <Typography.Paragraph style={{ width: '100%', margin: '0', fontWeight: '400', color: '#373737' }}>
                    {item?.comment}
                  </Typography.Paragraph>
                </Col>

                {/* <Row className='byp-mt-1'>
                  <Col span={24}>
                    <Rate value={item?.rate} disabled className='byp-gray-rate' />
                  </Col>
                  <Col style={{ fontSize: '10px', lineHeight: '15px' }}>
                    2k opinions
                  </Col>
                </Row> */}

              </Row>
            </Col>
            {/* <Col span={24}>
            </Col> */}
          </Row>
        </div>

        <Typography.Paragraph style={{ width: '100%', margin: '0' }}>
          {/* Estudió {item?.rateable?.service?.label} */}
        </Typography.Paragraph>
      </div>
    </Card>
  )
}

export { CardOpinions }
