import React from 'react'
import '../../../common/assets/css/quote-gallery.less'
import { VimeoEmbed } from '../../../common/components/VimeoEmbed'

const getUrl = (img) => {
  if (img.src) return img.src
  if (img.profile_photo_url) return img.profile_photo_url
  if (img.url) return img.url
}
const getBGStyle = (gallery, index) => ({
  backgroundImage: `url(${gallery[index] ? getUrl(gallery[index]) : null})`
})

const QuoteGalleryTemplateA = ({ gallery, videoUrl }) => {
  const withGallary = () => (
    <>
    {/** nuevo diseño 
    <div className='site-card-wrapper'>
      <div className='grid-wrapper'>
        <div className='item item1'><VimeoEmbed videoID={videoUrl} /></div>
        <div className='item item2' style={getBGStyle(gallery, 0)} />
        <div className='item item3' style={getBGStyle(gallery, 1)} />
        <div className='item item4' style={getBGStyle(gallery, 2)} />
        <div className='item item5' style={getBGStyle(gallery, 3)} />
      </div>
    </div>
    **/}

    {/** nuevo diseño **/}
    <div className='fotos-portada'>
      <div className='video-preview'><VimeoEmbed videoID={videoUrl} /></div>
      <div className='two-pics'>
        <div style={getBGStyle(gallery, 0)}></div>
        <div style={getBGStyle(gallery, 1)}></div>
      </div>
      <div className='two-pics'>
        <div style={getBGStyle(gallery, 2)}></div>
        <div style={getBGStyle(gallery, 3)}></div>
      </div>
    </div>
    
    </>


    
  )

  return (
    // <div className='quote-gallery' style={{ paddingTop: '15px' }}>
    <div className='' style={{ paddingTop: '15px' }}>
    {withGallary()}
    </div>
  )
}

export { QuoteGalleryTemplateA }
