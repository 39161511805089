import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'

const RedirectPage = () => {
  const { shortCode } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchOriginalUrl = async () => {
      try {
        console.log('Fetching original URL for short code:', shortCode)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/redirect/${shortCode}`)
        console.log('API Response:', response.data)

        if (response.data && response.data.original_url) {
          const originalUrl = response.data.original_url
          console.log('Redirecting to:', originalUrl)
          window.location.href = originalUrl
        } else {
          console.error('Original URL not found in response:', response)
          navigate('/error')
        }
      } catch (error) {
        console.error('Error fetching original URL:', error)
        navigate('/error')
      }
    }

    fetchOriginalUrl()
  }, [shortCode, navigate])

  return null
}

export default RedirectPage
