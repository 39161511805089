import { useQuery } from 'react-query'
import PackagesRepository from '../../repositories/PackagesRepository'

function useGetHomePackageOffers (filters = {}) {
  return useQuery(['useGetHomePackageOffers', filters],
    () => PackagesRepository.packagesForHome(filters), {
      retry: 1
    })
}

function useGetCountServices () {
  return useQuery(['useGetCountServices'],
    () => PackagesRepository.getAllServices(), {
      retry: 1
    })
}

function useGetLanguagesOfBlogs (filters = {}) {
  return useQuery(['useGetLanguagesOfBlogs', filters],
    () => PackagesRepository.getLanguagesOfBlogs(filters), {
      retry: 1
    })
}

export { useGetHomePackageOffers, useGetCountServices, useGetLanguagesOfBlogs }
