import React, { useEffect, useState } from "react";
import { BackTop, Col, Row, Button, Form, Select, Space } from "antd";
import { ListSearchQuoteMap } from "./ListSearchQuoteMap";
import { useSearch } from "../../../providers/search/search-context-provider";
import {
  useGetCoursesByFilter,
  useGetCoursesLocationsByFilter,
} from "../../../bussines/hooks/quoter/useGetCoursesByFilter";
import "../../../common/assets/css/offer-list-theme.less";
import LottieCargando from "../../../lotties/LottieCargando";
import { useAuth } from "../../../providers/auth/auth-context-provider";
import { SchoolSearcher } from "./SchoolSearcher";
import { HolderOutlined } from "@ant-design/icons";
import { showErrorModal } from "../../../utils/errorModal";
import { LanguageFilters } from "./forms-search/LanguageFilters";
import GraduateFilters from "./forms-search/GraduateFilters";
import { FrontEndPaginatedOffersList } from "./FrontEndPaginatedOffersList";
import { FaAngleUp } from "react-icons/fa";
import BypGif from "../../../common/assets/images/byp_carga.gif";
import BoardingAndAcademicFilters from "./forms-search/BoardingAndAcademicFilters";

const { Option } = Select;
const breakpoint = 768;

const ListSearchQuoteResult = ({ setSubType, status, state, setState }) => {
  // const navigate = useNavigate()
  const [showMap, setShowMap] = useState(false);
  const [showMovilFilters, setShowMovilFilters] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [showDesktopFilters, setShowDesktopFilters] = useState(false);
  const [areaOptions, setAreaOptions] = useState([
    "Business",
    "Arts",
    "Sciences",
    "Humanities",
    "Health & Community Studies",
    "Other",
  ]);
  const [institutionTypes, setInstitutionTypes] = useState([
    "Public University",
    "Private University",
    "Public College",
    "Private College",
  ]);
  const [cityOptions, setCityOptions] = useState([]);
  const { user: _user } = useAuth();
  const {
    search,
    changeSearch,
    totalResults,
    setTotalResults,
    enableSearch,
    setEnableSearch,
    width,
    setWidth,
  } = useSearch();
  const { place, search: offerSearch } = search;
  const { isLoading, data, error, isError, refetch } = useGetCoursesByFilter(
    search,
    enableSearch
  );
  const { program, type } = search;
  const {
    isLoading: isLoadingL,
    data: locations,
    error: errorL,
  } = useGetCoursesLocationsByFilter(
    {
      ...search,
    },
    enableSearch
  );

  const handleGroupByCampus = (place) => {
    changeSearch("LANG_FORM", {
      ...search,
      place,
      place_type: "campus",
    });
  };

  const handleOrderBy = (value) => {
    const nSearch = search;
    if (!value) {
      delete nSearch.order_by;
    } else {
      nSearch.order_by = value;
    }
    changeSearch("LANG_FORM", {
      ...nSearch,
    });
    setOrderByValue(value);
  };

  const MapSection = () => {
    return (
      <ListSearchQuoteMap
        campusList={locations ?? []}
        onClickPopup={handleGroupByCampus}
      />
    );
  };

  const [migas, setMigas] = useState({});
  const handleMigas = (value, type) => {
    if (program === "language" && !migas?.weeks) {
      setMigas((currentValue) => {
        currentValue.weeks = "2 semanas";
        return currentValue;
      });
    }
    setMigas((currentValue) => {
      if (type === "weeks") {
        currentValue[type] = value + " semanas";
      } else {
        currentValue[type] = value;
      }
      return currentValue;
    });
  };

  const handleFormat = (migas) => {
    let text = "";
    Object.keys(migas)?.forEach((item) => {
      if (item === "cities") {
        migas[item]?.forEach((i) => {
          if (text?.length === 0) {
            text += i?.value;
          } else {
            text += "/" + i?.value;
          }
        });
      } else if (item === "languages") {
        migas[item]?.forEach((i) => {
          if (text?.length === 0) {
            text += i?.name;
          } else {
            text += "/" + i?.name;
          }
        });
      } else if (item === "age") {
        migas[item]?.forEach((i) => {
          const value = i === "no" ? "menos de 16" : "más de 16";
          if (text?.length === 0) {
            text += value;
          } else {
            text += "/" + value;
          }
        });
      } else if (
        item === "type_school" ||
        item === "sports" ||
        item === "extras"
      ) {
        migas[item]?.forEach((i) => {
          if (text?.length === 0) {
            text += i;
          } else {
            text += "/" + i;
          }
        });
      } else {
        if (text?.length === 0) {
          text += migas[item];
        } else {
          text += "/" + migas[item];
        }
      }
    });
    return text;
  };

  const clearMigas = () => {
    setMigas({});
  };

  const isEmptyMiga = (miga) => {
    return Object.keys(miga).length === 0 && miga.constructor === Object;
  };

  useEffect(() => {
    if (!isLoading && error) {
      const errorMessage = error?.response?.data?.message;
      showErrorModal(errorMessage);
    }
    if (!isLoading && data) {
      setTotalResults(data?.meta?.total);
      if (showMap) setShowMap(false);
      if (data?.data && !isFirst && _user === "Institution") {
        setIsFirst(true);
        setSubType(data?.data[0]?.course?.properties?.sub_type);
      }
      // get the study areas
      const areas = [];
      const institutionTypes = [];
      const cityOptions = [];
      data?.data?.forEach((offer) => {
        let isDuplicated = false;
        const offerAreas = offer.course.properties?.study_area ?? [];
        if (Array.isArray(offerAreas)) {
          areas.push(...offerAreas);
        } else {
          areas.push(offerAreas);
        }
        isDuplicated = institutionTypes.find(
          (type) => type === offer.campus.type
        );
        if (!isDuplicated) {
          institutionTypes.push(offer.campus.type);
        }
        // setCityOptions
        const city = offer.campus.city;
        isDuplicated = cityOptions.find((item) => item.id === city?.id);
        if (!isDuplicated && city) {
          cityOptions.push({
            id: city?.id,
            name: city?.name,
            offers: 1,
          });
        } else if (isDuplicated) {
          isDuplicated.offers = isDuplicated.offers + 1;
        }
      });
      const uniqueData = [];
      areas.forEach((area) => {
        const isDuplicated = uniqueData.find(
          (uniqueArea) => uniqueArea === area
        );
        if (!isDuplicated) uniqueData.push(area);
      });
      setAreaOptions(uniqueData);
      //
      setInstitutionTypes(institutionTypes);
      //
      const sorted = cityOptions?.sort(function (a, b) {
        if (a.offers < b.offers) {
          return 1;
        }
        return -1;
      });
      const topCities = sorted.slice(0, 5);
      setCityOptions(topCities);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!isLoadingL && errorL) {
      const errorMessage = errorL?.response?.data?.message;
      showErrorModal(errorMessage);
    }
    if (!isLoadingL && locations) {
      // console.log('🚀 ~ file: ListSearchQuoteResult.js:30 ~ useEffect ~ locations', locations)
    }
  }, [isLoadingL, locations, errorL]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);

    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    const placeType = search?.place_type;
    const country = search?.country;
    if (
      (placeType === "country" || offerSearch || country) &&
      !showDesktopFilters
    ) {
      setShowDesktopFilters(true);
    }

    if (!placeType && !offerSearch && showDesktopFilters) {
      setShowDesktopFilters(false);
    }
  }, [place, offerSearch]);

  const [orderByValue, setOrderByValue] = useState(null);

  // return null

  return (
    <div>
      {isLoading && (
        <div
          style={{
            display: "grid",
            justifyItems: "center",
            width: "100%",
            padding: "20px",
          }}
        >
          <img
            src={BypGif}
            alt="loading..."
            style={{ maxWidth: "800px", width: "100%" }}
          />
        </div>
      )}

      {!isLoading && !isLoadingL && (
        <div className="search-offers-results">
          <BackTop className="home-offers_back-top">
            <div className="ant-back-top-content">
              <FaAngleUp color="#FFF" />
            </div>
          </BackTop>
          <Row gutter={16} style={{padding: 0}} className="hidden-scroll">
            {/* Offers List */}
            <Col xs={24} md={24} lg={16}>
              {isLoading && <LottieCargando />}

              {/* <Col> */}
              {!isLoading && (
                <Col className="search-form-container--advance-search">
                {/* Movil and Desktop filters */}
                  <Button
                    block
                    type="ghost"
                    className={
                      showMovilFilters
                        ? "btn--text-responsive btn-filters active"
                        : "btn--text-responsive btn-filters"
                    }
                    onClick={() => setShowMovilFilters(!showMovilFilters)}
                  >
                    Búsqueda avanzada
                  </Button>
                  {/* TODO: Move this out of this col  */}

                  {/* It only will display on mobile devices */}
                  {/* FILTERS MOBILE */}
                  <div className="home-results-header--mobile">
                    {width <= breakpoint && (
                      <div
                        className={`breadcrumbs-container ${isEmptyMiga(migas) ? "empty" : ""}`}
                      >
                        <Row gutter={16} className="hidden-scroll">
                          <Col span={!showMovilFilters ? 24 : 0}>
                            <p className="breadcrumbs">{handleFormat(migas)}</p>
                          </Col>

                          <Col
                            className="filter-search-box-container"
                            span={showMovilFilters ? 24 : 0}
                          >
                            {program === "language" && (
                              <LanguageFilters
                                state={state}
                                status={status}
                                setState={setState}
                                setEnableSearch={setEnableSearch}
                                handleMigas={handleMigas}
                                clearMigas={clearMigas}
                                setShowMovilFilters={setShowMovilFilters}
                                isMobileView={width <= breakpoint}
                              />
                            )}
                            {program === "graduate" && (
                              <GraduateFilters
                                state={state}
                                status={status}
                                setState={setState}
                                setEnableSearch={setEnableSearch}
                                program={program}
                                type={type}
                                handleMigas={handleMigas}
                                clearMigas={clearMigas}
                                setShowMovilFilters={setShowMovilFilters}
                                areaOptions={areaOptions}
                                institutionTypes={institutionTypes}
                                cityOptions={cityOptions}
                              />
                            )}
                            {!!program &&
                              program !== "language" &&
                              program !== "graduate" && (
                                <SchoolSearcher
                                  state={state}
                                  setState={setState}
                                  setEnableSearch={setEnableSearch}
                                  clearMigas={clearMigas}
                                  handleMigas={handleMigas}
                                />
                              )}
                          </Col>
                        </Row>
                      </div>
                    )}

                    <h6 className="search-recommendation">
                      Elige entre más de {totalResults} resultados.
                    </h6>
                  </div>
                  
                  <Row gutter={10} className="orderBy">
                    <Col xs={12} sm={10} md={6} xxl={4}>
                      <Space className="orderBy_label">
                        <HolderOutlined
                          style={{
                            color: "var(--gray-100)",
                            WebkitTransform: "rotate(-90deg)",
                          }}
                        />
                        Ordenar por
                      </Space>
                    </Col>
                    <Col xs={12} sm={14} md={6} xxl={5}>
                      <Form.Item className="orderBy_selector">
                        <Select
                          placeholder="Recomendados"
                          value={orderByValue}
                          onChange={handleOrderBy}
                          allowClear
                          className="styled-select white"
                        >
                          <Option value={null}>Recomendados</Option>
                          <Option value="mejor_calificados">
                            Mejor calificados
                          </Option>
                          {/* {program === 'language' && (
                            <> */}
                          <Option value="menor_precio">Menor precio</Option>
                          <Option value="mayor_descuento">
                            Mayor Descuento
                          </Option>
                          {/* </>
                          )} */}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* {width <= breakpoint && (
                    <>
                      <Col span={!showMovilFilters ? 24 : 0}>
                        <p className="breadcrumbs">{handleFormat(migas)}</p>
                      </Col>
                      <Col span={showMovilFilters ? 24 : 0}>
                        {program === "language" && (
                          <LanguageFilters
                            state={state}
                            status={status}
                            setState={setState}
                            setEnableSearch={setEnableSearch}
                            handleMigas={handleMigas}
                            clearMigas={clearMigas}
                            setShowMovilFilters={setShowMovilFilters}
                          />
                        )}
                        {program === "graduate" && (
                          <GraduateFilters
                            state={state}
                            status={status}
                            setState={setState}
                            setEnableSearch={setEnableSearch}
                            program={program}
                            type={type}
                            handleMigas={handleMigas}
                            clearMigas={clearMigas}
                            setShowMovilFilters={setShowMovilFilters}
                            areaOptions={areaOptions}
                            institutionTypes={institutionTypes}
                            cityOptions={cityOptions}
                          />
                        )}
                        {!!program &&
                          program !== "language" &&
                          program !== "graduate" && (
                            <SchoolSearcher
                              state={state}
                              setState={setState}
                              setEnableSearch={setEnableSearch}
                              clearMigas={clearMigas}
                              handleMigas={handleMigas}
                            />
                          )}
                      </Col>
                    </>
                  )} */}
                  {/* Reload button */}
                  {isError && (
                    <Button onClick={refetch} className="byp-btn-red-50">
                      Intenta de nuevo
                    </Button>
                  )}
                  <FrontEndPaginatedOffersList
                    data={data}
                    isMobileView={width <= breakpoint}
                  />
                </Col>
              )}
            </Col>
            {/* DesktopFilters */}
            <Col xs={24} lg={8}>
              {/* Desktop filters */}
              {width > breakpoint && (
                <Col>
                  {program === "language" && (
                    <LanguageFilters
                      setShowMovilFilters={setShowMovilFilters}
                      state={state}
                      status={status}
                      setState={setState}
                      setEnableSearch={setEnableSearch}
                      handleMigas={handleMigas}
                      clearMigas={clearMigas}
                    />
                  )}
                  {program === "graduate" && (
                    <GraduateFilters
                      cityOptions={cityOptions}
                      setShowMovilFilters={setShowMovilFilters}
                      institutionTypes={institutionTypes}
                      areaOptions={areaOptions}
                      state={state}
                      status={status}
                      setState={setState}
                      setEnableSearch={setEnableSearch}
                      program={program}
                      type={type}
                      handleMigas={handleMigas}
                      clearMigas={clearMigas}
                    />
                  )}
                  {["boarding_schools", "academic_years"].includes(program) && (
                    <BoardingAndAcademicFilters
                      setShowMovilFilters={setShowMovilFilters}
                      institutionTypes={institutionTypes}
                      areaOptions={areaOptions}
                      state={state}
                      status={status}
                      setState={setState}
                      setEnableSearch={setEnableSearch}
                      program={program}
                      type={type}
                      handleMigas={handleMigas}
                      clearMigas={clearMigas}
                    />
                  )}
                  {!!program &&
                    ![
                      "language",
                      "graduate",
                      "academic_years",
                      "boarding_schools",
                    ].includes(program) && (
                      <SchoolSearcher
                        state={state}
                        setState={setState}
                        setEnableSearch={setEnableSearch}
                        clearMigas={clearMigas}
                        handleMigas={handleMigas}
                      />
                    )}
                </Col>
              )}
              <Col xs={24} className={showMap ? "" : "show-notshow"}>
                {!isLoadingL && (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderBottomLeftRadius: "22px",
                      borderBottomRightRadius: "22px",
                      borderTopLeftRadius: "22px",
                      borderTopRightRadius: "22px",
                      background: "white",
                      overflow: "hidden",
                    }}
                  >
                    <MapSection />
                    <h5
                      style={{
                        color: "black",
                        fontWeight: "700",
                        padding: "25px",
                      }}
                    >
                      Ver en el mapa
                    </h5>
                  </div>
                )}
              </Col>
            </Col>
          </Row>
          {/* See Map Movil Button
          {(width <= breakpoint) && (
            <Button block className='byp-btn-dark' onClick={() => setShowMap(!showMap)}>
              {showMap ? 'Ocultar mapa' : 'Ver Mapa'} <FaMapMarkerAlt style={{ marginLeft: '.5rem' }} />
            </Button>
          )} */}
          {/* Movil Stiky? Footer */}
          {width <= breakpoint && <></>}
        </div>
      )}
    </div>
  );
};

export { ListSearchQuoteResult };
