function calculateCost (cost, frequency, weeks) {
  const moths = Math.ceil(weeks / 4)
  const each4months = Math.ceil((moths) / 4)
  const semesters = Math.ceil(moths / 6)
  const years = Math.ceil(weeks / 52)
  switch (frequency) {
    case 'unic':
      return cost
    case 'week':
      return cost * weeks
    case 'weeks':
      return cost * weeks
    case 'weekly':
      return cost * weeks
    case 'monthly':
      return cost * moths
    case 'mont':
      return cost * moths
    case 'month':
      return cost * moths
    case 'every 4 months':
      return cost * each4months
    case 'semester':
      return cost * semesters
    case 'anual':
      return cost * years
    default:
      return cost
  }
}

function calculateHsCost (cost, frequency, weeks, days) {
  if (frequency === 'unic') return cost
  const weeksCost = calculateCost(cost, frequency, weeks)
  if (days <= 0 || !days) return weeksCost
  switch (frequency) {
    case 'week':
      return weeksCost + ((cost / 7) * days)
    case 'weeks':
      return weeksCost + ((cost / 7) * days)
    case 'weekly':
      return weeksCost + ((cost / 7) * days)
    case 'monthly':
      return weeksCost + ((cost / 30) * days)
    case 'mont':
      return weeksCost + ((cost / 30) * days)
    case 'month':
      return weeksCost + ((cost / 30) * days)
    case 'every 4 months':
      return weeksCost + ((cost / 120) * days)
    case 'semester':
      return weeksCost + ((cost / 180) * days)
    case 'anual':
      return weeksCost + ((cost / 365) * days)
    default:
      return cost
  }
}

function calculateCostByDays (cost, frequency, days) {
  const weeks = Math.ceil(days / 7)
  const moths = Math.ceil(weeks / 4)
  const each4months = Math.ceil((moths) / 4)
  const semesters = Math.ceil(moths / 6)
  const years = Math.ceil(weeks / 52)
  switch (frequency) {
    case 'unic':
      return cost
    case 'week':
      return cost * weeks
    case 'day':
      return cost * days
    case 'daily':
      return cost * days
    case 'weeks':
      return cost * weeks
    case 'weekly':
      return cost * weeks
    case 'monthly':
      return cost * moths
    case 'mont':
      return cost * moths
    case 'month':
      return cost * moths
    case 'every 4 months':
      return cost * each4months
    case 'semester':
      return cost * semesters
    case 'anual':
      return cost * years
    case 'year':
      return cost * years
    default:
      return cost
  }
}

/**
 * Calculate a cost based on a course Length
 * @param {Number} cost
 * @param {String} frequency
 * @param {Number} courseLength
 * @param {String} courseLengthType
 * @returns Number(cost)
 */
const calculateHECost = (cost, frequency, courseLength, courseLengthType) => {
  if (frequency === courseLengthType) return (cost * courseLength)
  if (frequency === 'unic') return cost
  if (courseLengthType === 'years' || courseLengthType === 'year') {
    if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
      return cost * (courseLength * 52) // Semanas in years
    }
    if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
      return cost * (courseLength * 12) // MOnths in years
    }
    if (frequency === 'semester') {
      return cost * (courseLength * 2) // MOnths in years
    }
    if (frequency === 'year' || frequency === 'anual') {
      return cost * (courseLength) // MOnths in years
    }
  }
  if (courseLengthType === 'semester') {
    if (frequency === 'year' || frequency === 'years') {
      return cost * (Math.ceil(courseLength / 2))
    }
    if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
      return cost * (courseLength * 6)
    }
    if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
      return cost * (courseLength * 26) // Semanas in semester
    }
  }
  if (courseLengthType === 'months') {
    if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
      return cost * (courseLength * 4) // Semanas in months
    }
    if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
      return cost * (courseLength)
    }
  }
  if (courseLengthType === 'days') {
    if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
      return (cost / 7) * (courseLength)
    }
    if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
      return (cost / 30) * (courseLength)
    }
    if (frequency === 'year' || frequency === 'anual') {
      return (cost / 365) * (courseLength)
    }
    if (frequency === 'day') {
      return cost * courseLength
    }
  }
  return cost
}

export {
  calculateCost,
  calculateHsCost,
  calculateCostByDays,
  calculateHECost
}
