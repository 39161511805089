import { useEffect, useState } from 'react'
import { useAddItemToCollection } from '../../../bussines/hooks/collections/useAddItemToCollection'
import { ModalSelectCollection } from '../../../common/components/ModalSelectCollection'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'

const CreateAndAddToCollection = ({ buttonProps }) => {
  const { prices, submit, isLoadingPost, isSuccessPost, quote, setFavorite, setFaileForm, faileForm, course } = useQuoter()
  const { addItem, isLoading: isSavingItem, currentState: itemState } = useAddItemToCollection()
  const { isPublicUser } = useAuth()
  const [collectionSelected, setCollectionSelected] = useState()
  const [id, setId] = useState()

  useEffect(() => {
    if (prices) {
      setId(prices?.offer_id)
    } else if (course) {
      if (course?.offer?.prices && course?.offer?.prices?.length > 0) {
        setId(course?.offer?.prices[0]?.offer_id)
      }
    }
  }, [prices, course])

  const handleOnSaveFavorite = (value) => {
    setCollectionSelected(value)
    setFavorite(true)
    submit()
  }

  useEffect(() => {
    if (!isLoadingPost && isSuccessPost) {
      addItem(collectionSelected, { item_id: quote.id, item_type: 'quote' })
    }
  }, [isLoadingPost, isSuccessPost])
  return (
    <ModalSelectCollection
      itemId={id}
      onSave={handleOnSaveFavorite}
      isSaving={isSavingItem}
      saveState={itemState}
      isPublicUser={isPublicUser}
      button={buttonProps}
      setFaileForm={setFaileForm}
      faileForm={faileForm}
    />
  )
}

export { CreateAndAddToCollection }
