import { isArray } from '@craco/craco/lib/utils'
import { Form, Select } from 'antd'
import React, { useEffect } from 'react'
import { useGetMyServiceCategories } from '../../../bussines/hooks/school/useGetMyServiceCategories'

const { Option } = Select

function SelectSchoolProgramsFormItem ({ showAll = false, setProgramName, program, form, setProgramID, ...props }) {
  const { isLoading, data } = useGetMyServiceCategories()
  const onChange = (value) => {
    if (!setProgramName) return
    if (isArray(value)) {
      const names = value.map((id) => {
        const found = data?.find((item) => item.id === id)
        return found?.name
      })
      setProgramName(names)
    } else {
      const found = data?.find((item) => item.id === value)
      if (found) {
        setProgramName(found.name)
        if (setProgramID) setProgramID(found.id)
      }
    }
  }

  useEffect(() => {
    if (!isLoading && data && program && form) {
      const found = data?.find((item) => item.name === program)
      if (form && found) {
        if (props.mode === 'multiple') {
          form.setFieldsValue({ [props.name]: [found.id] })
        } else {
          form.setFieldsValue({ [props.name]: found.id })
          if (setProgramID) setProgramID(found.id)
        }
        if (setProgramName) setProgramName(found.name)
      }
    }
  }, [isLoading, data, program])

  return (
    <Form.Item {...props}>
      <Select
        {...props}
        loading={isLoading}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        placeholder={props.placeholder ?? ''}
        onChange={props.onChange ?? onChange}
        size='small'
      >
        {showAll && <Option key={0} value={999}>All</Option>}
        {data?.map(item => (
          <Option key={item.id} value={item.id}>{`${item.label}`}</Option>

        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectSchoolProgramsFormItem }
