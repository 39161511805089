import { Statistic, Typography, Tooltip } from 'antd'
import React from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { discountsApplied } from '../../../common/components/StatisticWithDiscounts'
import { getDiscountSymbol, handlePrice } from '../../../utils/getDiscountSymbol'
// import { formatStringDate } from '../../../utils/formatStringDate'
import { IconPromocion } from '../../../common/assets/svg/icons'

const { Text } = Typography

function FieldPayTuition({ form }) {
  const { service } = useQuoter()
  return (
    <>
      <RowQuoter
        colA={
          <>
            {
              <Text>Cuota de inscripción</Text>
            }
            {
              service.getTuitionFeeDiscounts().length > 0 && (
                <Tooltip title={
                  <>
                    {service.getTuitionFeeDiscounts()?.map((d, index) => (
                      <div key={index}>
                        <p className='info-cost__discount w-100'>
                          {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en la Cuota de inscripción.
                        </p>
                        <p className='info-cost__discount w-100'>
                          Descuento valor monetario {handlePrice(d.value, service.getCourseAmount())} {service.getCurrency()} en la Cuota de inscripción.
                        </p>
                      </div>
                    ))}
                  </>
                } placement="bottomLeft" color='white'>
                  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IconPromocion style={{ verticalAlign: 'middle' }} />
                    {" "}Cuota de inscripción
                  </span>
                </Tooltip>
              )
            }
          </>
        }

        colB={
          <>
            {/* <StatisticWithDiscounts
              discounts={service.getTuitionFeeDiscounts()}
              amount={service.getAmountInscription()?.toFixed(2)}
              currency={service.getCurrency()}
              subtotal={service.getAmountInscription()?.toFixed(2) - service.getTuitionFeeDiscountsAmount()}
              active
            /> */}

            <Statistic
              prefix='$'
              precision={2}
              value={`${service.getAmountInscription()?.toFixed(2)}`}
              // suffix={`${service.getCurrency()}`}
            />

            {service.getTuitionFeeDiscounts().length > 0 && (
              service.getTuitionFeeDiscounts()?.map((d, index) => (
                <Statistic
                  key={index}
                  prefix='$'
                  className='price-after text-discount'
                  precision={2}
                  value={`${d.type === 'amount' ? '-' + d.value : '-' + handlePrice(d.value, service.getAmountInscription()?.toFixed(2))}`}
                  // suffix={`${service.getCurrency()}`}
                />
              ))
            )}
          </>

        }
      />

    </>
  )
}

export { FieldPayTuition }
