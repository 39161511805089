import api from '../../services/api'

class LanguageRepository {
  async all () {
    const response = await api.get('/get/languages/all')
    return response.data
  }

  async languages (filters) {
    const response = await api.get('/get/languages', {
      params: {
        ...filters
      }
    })
    return response.data
  }

  async languagesShort () {
    const response = await api.get('/get/languages/short')
    return response.data
  }
}

export default new LanguageRepository()
