import { Checkbox, Col, Form, Row, Select } from 'antd'
import { useState, useEffect } from 'react'
import { useGetAllCampus } from '../../../../bussines/hooks/school/useGetAllCampus'
const { Option } = Select

function SelectInstitutionsAll ({ program, name, onChange, single, allowClear, form, rules, ...props }) {
  const [checkAll, setCheckAll] = useState(false)
  const _name = name ?? 'schools'
  const { isLoading, data } = useGetAllCampus(program && { program })

  const onCheckAllChange = (e) => {
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    if (data) {
      console.log('🚀 ~ file: SelectInstitutionsAll.js:16 ~ useEffect ~ data', data)
      const ids = data.institutions.map(c => (c.id))
      checkAll ? form.setFieldsValue({ [_name]: ids }) : form.setFieldsValue({ [_name]: [] })
    }
  }, [checkAll, data])

  return (
    <>
      <Row>
        <Col style={{ paddingTop: '12px' }} xs={24} md={4}>
          {props.label + ':'}
        </Col>

        <Col xs={24} md={20}>
          <Row className='byp-dashboard-header byp-mb-1'>
            <Col xs={24} md={7} className='byp-justify-centered' style={{ borderRight: 'solid var(--gray-100) 1px' }}>
              <Form.Item className='byp-m-0 prefix' name={name + 'all'} valuePropName='checked'>
                <Checkbox onChange={onCheckAllChange}>
                  {'All ' + name}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={17} className='byp-justify-centered'>
              <Form.Item
                name={_name}
                rules={rules}
                className='byp-m-0'
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder=''
                  loading={isLoading}
                  mode='multiple'
                  className='byp-m-0'
                  maxTagCount='responsive'
                  filterOption={(input, option) =>
                    option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                  showSearch
                >
                  {data?.institutions?.map(item => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

      </Row>
    </>
  )
}

export { SelectInstitutionsAll }
