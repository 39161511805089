import React from 'react'
import { useNavigate } from 'react-router-dom'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import { Button, Col, Layout, Row, Space } from 'antd'
import FormAplication from './Components/FormAplication'
import { Helmet } from 'react-helmet'

const URL_APLICACION_PAGE = '/aplicacion/:id/:applicationId'
const URL_APLICACION_SHOW_PAGE = '/aplicacion/:id/:applicationId/show'

const AplicacionPage = () => {
  const navigate = useNavigate()

  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <Helmet>
        <title>Formato de aplicación</title>
      </Helmet>
      <MainTheme>
        <div className='application-form_container'>
          <Space direction='vertical' className='space-content space-content-my-courses' size={[0, 50]}>
            <Layout style={{ background: 'transparent' }}>
              <Row>
                <Col>
                  <Button className='btn__back br-06 fs-12' onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined style={{ width: '1rem' }} />
                    <span className='component_none'>Volver</span>
                  </Button>
                </Col>
              </Row>
            </Layout>
            <Layout style={{ background: 'transparent' }}>
              <FormAplication />
            </Layout>
          </Space>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { AplicacionPage, URL_APLICACION_PAGE, URL_APLICACION_SHOW_PAGE }
