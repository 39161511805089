import { Button, Form, Input, notification } from 'antd'
import { useEffect } from 'react'
import { usePostContactUs } from '../../../bussines/hooks/user/usePostContactUs'
const { TextArea } = Input

const rules = [{ required: true, message: 'This field is required' }]

const ConstactUsForm = () => {
  const { sendContactUsData, isLoading, error, data } = usePostContactUs()
  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: ContactUsForm.js:10 ~ useEffect ~ data:', data)
      notification.success({
        message: data?.message ?? 'Thanks for contac us!'
      })
    }
    if (!isLoading && error) {
      notification.error({
        message: 'Something went wrong :c'
      })
    }
  }, [isLoading, data, error])

  return (
    <Form onFinish={(values) => sendContactUsData(values)}>
      <Form.Item name='name' rules={rules}>
        <Input placeholder='Full name' />
      </Form.Item>
      <Form.Item name='type' rules={rules}>
        <Input placeholder='Are you agency, school, service provider, other?' />
      </Form.Item>
      <Form.Item name='email' rules={rules}>
        <Input placeholder='email' type='email' />
      </Form.Item>
      <Form.Item name='subject' rules={rules}>
        <Input placeholder='Tema' />
      </Form.Item>
      <Form.Item name='message' rules={rules}>
        <TextArea rows={6} />
      </Form.Item>

      <div className='w-100' style={{ justifyContent: 'end', alignContent: 'end', display: 'flex' }}>
        <Button className='byp-btn-blue-100' htmlType='submit' loading={isLoading}>
          Send
        </Button>
      </div>
    </Form>
  )
}

export { ConstactUsForm }
