import api from '../../services/api'

class PackagesRepository {
  static async packages (params) {
    const response = await api.get('/packages', { params })
    return response.data
  }

  static async packagesForHome (params) {
    const response = await api.get('home/packages', { params })
    return response.data
  }

  static async savePackage ({ data }) {
    const response = await api.post('/packages', data)
    return response.data
  }

  static async updatePackage ({ data }) {
    if (data.id === undefined || data === undefined) {
      return
    }
    const response = await api.put(`/packages/${data.id}`, data)
    return response.data
  }

  static async getAll () {
    const response = await api.get('/packages')
    return response.data
  }

  static async getPackage (id) {
    if (id === undefined || id === null) {
      return null
    }
    const response = await api.get(`/packages/${id}`)
    console.log(response.data)
    return response.data
  }

  static async deletePackage ({ id }) {
    const response = await api.delete(`/packages/${id}`)
    console.log('la respuesta es' + response.data + 'con id de ' + id)
    return response.data
  }

  static async getPrices (id, params) {
    const response = await api.get(`/packages/${id}/prices`, { params })
    return response.data
  }

  static async savePrices ({ id, data }) {
    const response = await api.post(`/packages/${id}/prices`, data)
    return response.data
  }

  static async getPackageYears (id) {
    const response = await api.get(`/packages/${id}/years`)
    return response.data
  }

  static async packagesOffices () {
    const response = await api.get('/packages/offices')
    return response.data
  }

  static async getAllServices () {
    const response = await api.get('services/count')
    return response.data
  }

  static async getLanguagesOfBlogs (params) {
    const response = await api.get('/get/languages/home', { params })
    return response.data
  }
}
export default PackagesRepository
