import { Button, Modal, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { IoIosCheckmarkCircle } from 'react-icons/io'

const { Paragraph, Title } = Typography

const OpinionSavedModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal open={isModalOpen} visible={isModalOpen} onCancel={() => setIsModalOpen(false)} closable={false} footer='' width='400px' style={{ top: '35%' }}>
      <center><IoIosCheckmarkCircle size={35} /></center>
      <Title level={5} style={{ textAlign: 'center' }}>Gracias por compartir tu opinión</Title>
      <Typography style={{ textAlign: 'center' }}>
        Será de mucha utilidad para otros estudiantes que desean viajar al extranjero
      </Typography>
      <Link to='/home'>
        <Button htmlType='submit' className='byp-btn-red-50 byp-mt-1' block>
          Hacer Cotización
        </Button>
      </Link>
    </Modal>
  )
}

export { OpinionSavedModal }
