import { Col, Menu, Row } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import '../../../../common/assets/css/dashboard-topbar-theme.less'
import { URL_CREATE_INSURANCE_PAGE } from '../../agency/insurances/CreateInsurancePage'
import { URL_CREATE_INSURANCE_PRICES_PAGE } from '../../agency/insurancePrices/InsurancePricesPage'
import { URL_UPLOAD_ADD_SERVICES_PAGE } from '../../agency/services/CreateAdditionalServices'
import { URL_UPLOAD_PRICES_ADD_SERVICES } from '../../agency/services/CreatePricesAdditionalServices'

function getItem (label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  }
}

function getMenuItems () {
  return [
    getItem(
      '1. ADDITIONAL SERVICES',
      'add-service', undefined,
      [
        getItem(
          <Link
            to={URL_UPLOAD_ADD_SERVICES_PAGE}
            state={{ menuItemSelected: 'upload-service', openSub: 'customs-quotes', openSub2: 'add-service' }}
          >Upload service
          </Link>,
          'upload-service'
        ),
        getItem(
          <Link
            to={URL_UPLOAD_PRICES_ADD_SERVICES}
            state={{ menuItemSelected: 'prices-service', openSub: 'customs-quotes', openSub2: 'add-service' }}
          >Prices for service
          </Link>,
          'prices-service'
        )
      ]
    ),
    getItem(
      '2. MEDICAL INSURANCE',
      'medical', undefined,
      [
        getItem(
          <Link
            to={URL_CREATE_INSURANCE_PAGE}
            state={{ menuItemSelected: 'medical_form', openSub: 'customs-quotes', openSub2: 'medical' }}
          >Upload medical insurance
          </Link>,
          'medical_form'
        ),
        getItem(
          <Link
            to={URL_CREATE_INSURANCE_PRICES_PAGE}
            state={{ menuItemSelected: 'medical_prices', openSub: 'customs-quotes', openSub2: 'medical' }}
          >Prices medical insurance
          </Link>,
          'medical_prices'
        )
      ]
    ),
    getItem(
      '3. PROMOS AND DISCOUNTS',
      'promos'
    )

  ]
}

function TopBarMenuCustom () {
  const location = useLocation()

  const { menuItemSelected, openSub } = location.state ?? 'upload-pack'
  const menuItems = getMenuItems()

  return (
    <Row className='byp-mb-1'>
      <Col xs={0} md={4} className='byp-justify-centered'>
        <h5 className='byp-title byp-fw-bold' style={{ fontSize: '14px', marginBottom: '0', textAlign: 'center' }}>We guide you, step by step</h5>
      </Col>
      <Col xs={24} md={20}>
        <Menu
          mode='horizontal'
          defaultSelectedKeys='packages'
          selectedKeys={[menuItemSelected]}
          defaultOpenKeys={[openSub]}
          className='byp-dashboard-top-bar'
          items={menuItems}

        />
      </Col>
    </Row>
  )
}

export { TopBarMenuCustom }
