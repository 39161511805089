import { Button, Carousel } from 'antd'
import img01 from './../../assets/images/carousel/img-01.png'
import img02 from './../../assets/images/carousel/img-02.png'
import img03 from './../../assets/images/carousel/img-03.png'
import img01q from './../../assets/images/carousel/img-01.jpg'
import img02q from './../../assets/images/carousel/img-02.jpg'
import img03q from './../../assets/images/carousel/img-03.jpg'
import img04q from './../../assets/images/carousel/img-04.jpg'
import img05q from './../../assets/images/carousel/img-05.jpg'
import img06q from './../../assets/images/carousel/img-06.jpg'
import img07q from './../../assets/images/carousel/img-07.jpg'
import img08q from './../../assets/images/carousel/img-08.jpg'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { useEffect, useState } from 'react'
import { IconRightSlider, IconLeftSlider } from '../../../common/assets/svg/icons'
// import { transform } from 'typescript'

const carouselHeight = '35vh'
const contentStyle = {
  height: carouselHeight,
  color: '#fff',
  lineHeight: '100%',
  textAlign: 'center',
  backgroundColor: '#2a2f3394',
  fontSize: 35,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 20px'
}

const slides = [
  {
    text: '.',
    text_en: 'Your Success is Our Success. We’re Here to See You Grow',
    image: img01
  },
  {
    text: '.',
    text_en: 'We are Invested in Caring for You and the Ecosystem of International Education.',
    image: img02
  },
  {
    text: '.',
    text_en: 'An Easy-to-Use Platform that Connects You to the Right School.',
    image: img03
  }
]

const slides2 = [
  {
    id: 'slide_header_qyp_01',
    text: 'Viajar es la manera más intensa de aprender.',
    text_en: 'Traveling is the most intense way to learn.',
    image: img01q
  },
  {
    id: 'slide_header_qyp_02',
    text: 'Para qué darle vueltas a las cosas, si se las podemos dar al mundo.',
    text_en: 'Why spin things, if we can give them to the world.',
    image: img02q
  },
  {
    id: 'slide_header_qyp_04',
    text: 'La educación es el pasaporte hacia el futuro.',
    text_en: 'Education is the passport to the future.',
    image: img03q
  },
  {
    id: 'slide_header_qyp_05',
    text: 'La educación es el arma más poderosa que puedes usar para cambiar al mundo.',
    text_en: 'Education is the most powerful weapon that you can use to change the world.',
    image: img04q
  },
  {
    id: 'slide_header_qyp_06',
    text: 'No hay nada como volver a un lugar que no ha cambiado, para darte cuenta cuánto has cambiado tu.',
    text_en: "There's nothing like going back to a place that hasn't changed, to realize how much you've changed.",
    image: img05q
  },
  {
    id: 'slide_header_qyp_07',
    text: 'La educación no cambia al mundo, cambia a las personas que cambiarán el mundo.',
    text_en: 'Education does not change the world, it changes the people who will change the world.',
    image: img06q
  },
  {
    id: 'slide_header_qyp_08',
    text: 'Las personas no hacen viajes, los viajes hacen a las personas.',
    text_en: "People don't make trips, trips make people.",
    image: img07q
  },
  {
    id: 'slide_header_qyp_09',
    text: 'No le llames sueño, llámalo tu próximo viaje.',
    text_en: "Don't call it a dream, call it your next trip.",
    image: img08q
  }
]

const MainThemeCarrouselWithImages = () => {
  const [slider, setSlider] = useState(slides)
  const { user: _user } = useAuth()

  useEffect(() => {
    const gallery = _user?.slider
    if (gallery?.length > 0) {
      const newGallery = gallery?.map((image, index) => (
        {
          text_en: image?.description,
          image: image?.src,
          text: index
        }
      ))

      setSlider(newGallery)
    }
  }, [_user])

  return <ThemeCarrousel slides={slider} urlProperty='image' />
}

const MainThemeCarrousel = () => {
  return <ThemeCarrousel slides={slides} urlProperty='image' />
}

const MainThemeCarrouselQyp = ({ textProperty = 'text_en' }) => {
  return <ThemeCarrousel slides={slides2} urlProperty='image' textProperty={textProperty ?? 'text_en'} />
}

const ThemeCarrousel = ({ slides = [], urlProperty = 'url', textProperty = 'description' }) => {
  return (
    <Carousel autoplay effect='fade' dots={{ className: 'byp-carousel-dots' }} style={{ height: carouselHeight }}>
      {slides.map((slide) => (
        <div key={slide.id}>
          <div style={{
            backgroundImage: 'url(' + slide[urlProperty] + ')',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%'
          }}
          >
            <h3 style={contentStyle}>
              {slide[textProperty]}
            </h3>
          </div>
        </div>
      ))}
    </Carousel>
  )
}

const ThemeCarrouselSmall = ({ slides = [], urlProperty = 'url', textProperty = 'description', inRandomOrder = false }) => {
  const [slideItems, setSlideItems] = useState(slides)
  useEffect(() => {
    if (inRandomOrder) {
      setSlideItems(slides.sort(() => Math.random() - 0.5))
    }
  }, [inRandomOrder])

  return (
    <div className='carousel-100 carousel-w-100 carousel__cards'>
      <Carousel
        effect='fade'
        dots={false}
        autoplay={false}
        arrows
        nextArrow={
          <Button className='carrousel-bottoms__small' style={{ right: '25px' }}>
            <div className='carousel__cards-buttons'>
              <IconRightSlider className='carrousel-bottoms__left' width='23' height='23' style={{ color: '#ffffff', transform: 'scale(2)' }} />
            </div>
          </Button>
        }
        prevArrow={
          <Button className='carrousel-bottoms__small' style={{ left: '10px', zIndex: '10' }}>
            <div className='carousel__cards-buttons'>
              <IconLeftSlider className='carrousel-bottoms__left' width='23' height='23' style={{ color: '#ffffff', transform: 'scale(2)' }} />
            </div>
          </Button>
        }
      >
        {!inRandomOrder && slides.map((slide) => (
          <div key={slide.id} style={{ overflow: 'hidden', height: '100%' }}>
            <div
              style={{
                backgroundImage: 'url(' + slide[urlProperty] + ')'
              }}
              className='carousel-item'
            >
              {/* <div style={{ lineHeight: '100%', minHeight: '25vh' }}> </div> */}
            </div>
          </div>
        ))}
        {inRandomOrder && slideItems.map((slide) => (
          <div key={slide.id} style={{ overflow: 'hidden', height: '100%' }}>
            <div
              style={{
                backgroundImage: 'url(' + slide[urlProperty] + ')'
              }}
              className='carousel-item'
            >
              {/* <div style={{ lineHeight: '100%', minHeight: '25vh' }}> </div> */}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export { MainThemeCarrousel, MainThemeCarrouselWithImages, ThemeCarrousel, ThemeCarrouselSmall, slides, MainThemeCarrouselQyp }
