import React from 'react'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { PageProtected } from '../../../../common/components/PageProtected'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { ReportsInfo } from './ReportsInfo'
import { Col, Row } from 'antd'

const URL_REPORTS_PAGE = '/dashboard/agency/reports'

function ReportsPage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_AGENCY,
        userRoles.TYPE_AGENCY_EDITOR,
        userRoles.TYPE_AGENCY_MANAGER,
        userRoles.TYPE_AGENCY_CONSULTANT,
        userRoles.TYPE_AGENCY_OFFICE_MANAGER,
        userRoles.TYPE_AGENCY_COMMERCIAL_MANAGER
      ]}
      isInBetaState
    >
      <MainTheme>
        <Row justify='center'>
          <Col xl={24} xxl={20}>
            <ReportsInfo />
          </Col>
        </Row>
      </MainTheme>
    </PageProtected>
  )
}

export { ReportsPage, URL_REPORTS_PAGE }
