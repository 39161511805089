import { Col, Row, Layout, Space, Button } from 'antd'
import { PersonalizedSearcher } from './PersonalizedSearcher'
import PlaceIcon from '@material-ui/icons/Place'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import { FilterCategory } from '../../quoter/components/FilterCategory'
import avion from '../../../common/assets/images/avion.png'
import { Link, useNavigate } from 'react-router-dom'
import { CardPlaces } from '../../../common/components/CardPlaces'
import { useGetCountServices } from '../../../bussines/hooks/packages/useGetHomePackageOffers'
import React, { useEffect, useState } from 'react'
import { HomeCarrousel } from '../../../common/components/HomeCarrousel'
import { CardWithImage } from './CardWithImage'
import { IconCamping, IconLocationHome } from '../../quote-preview/assets/imgs/Icons'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { useGetBigHomePage, useGetPackage } from '../../../bussines/hooks/big-home/useGetPackage'
import { CardBlogSimple } from '../../dashboards/blog/components/CardBlogSimple'
import { retrieveFromStorage, saveInStorage } from '../../../utils/localStorageUtils'
import { BlogFooter } from '../../dashboards/blogs/components/BlogFooter'
import { URL_QUOTER_PAGE } from '../../quoter'
import { validateProgramName } from '../../../utils/programUtils'
import removeInvalidValuesFromParams from '../../../utils/removeInvalidValuesFromParams'
// import { URL_BLOGS_SEARCH_V2_PAGE } from '../../dashboards/blogs/PageBlogs'
import { getBlogs } from '../../../services/apiBlog'

const URL_BLOGS_PAGE = 'https://blog.bookyourprogram.com/'

function BigHomeContent() {
  const navigate = useNavigate()
  const [idsPackages, setIdsPackage] = useState([])
  const { data: page, isLoading: isLoadingPage } = useGetBigHomePage()
  const { data: dataPackage } = useGetPackage(idsPackages ?? [], 'show', {}, idsPackages?.length > 0)
  const { data } = useGetCountServices()
  const { user: _user } = useAuth()
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [slides, setSlides] = useState(4)
  const [slidesBlog, setSlidesBlog] = useState(3)
  const [text, setText] = useState()
  const [link, setLink] = useState()

  function addHttpsIfNotExist(url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    return url
  }

  useEffect(() => {
    if (_user && _user?.publicity && _user?.publicity?.length > 0) {
      if (_user?.publicity[0]?.title) setText(_user?.publicity[0]?.title)
      // if (_user?.publicity[0]?.src) setUrlImage(_user?.publicity[0]?.src)
      if (_user?.publicity[0]?.link) setLink(addHttpsIfNotExist(_user?.publicity[0]?.link))
    }
  }, [_user])

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (windowSize.width <= 800) {
      setSlides(1)
      setSlidesBlog(1)
    } else {
      setSlides(4)
      setSlidesBlog(3)
    }
  }, [windowSize])

  const [programsSorted, setProgramsSorted] = useState(null)

  // banner section
  const [image, setImage] = useState(retrieveFromStorage('big-home-cover'))
  const [position, setPosition] = useState('center center')
  const [repeat, setRepeat] = useState('no-repeat')
  const [size, setSize] = useState('cover')

  // cities section
  const [cities, setCities] = useState([])
  const [iconCity, setIconCity] = useState(null)
  const [titleCity, setTitleCity] = useState()
  const [subTitleCity, setSubTitleCity] = useState()

  // package section
  const [iconPackage, setIconPackage] = useState(null)
  const [titlePackage, setTitlePackage] = useState()
  const [subTitlePackage, setSubTitlePackage] = useState()

  // blog section
  const [blogs, setBlogs] = useState([])
  const [iconBlog, setIconBlog] = useState(null)
  const [titleBlog, setTitleBlog] = useState()
  const [subTitleBlog, setSubTitleBlog] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogsData = await getBlogs()
        setBlogs(blogsData)
      } catch (error) {
        console.error('Error blogs:', error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!isLoadingPage && page) {
      const data = page?.page
      if (data) {
        data?.files?.forEach(item => {
          if (item.pivot.position === 'Header' && item.pivot.type === 'big-home') {
            setImage(item.src)
            saveInStorage('big-home-cover', item.src)
            const styles = JSON.parse(item.properties)
            if (styles?.style?.position) setPosition(styles?.style?.position)
            if (styles?.style?.repeat) setRepeat(styles?.style?.repeat)
            if (styles?.style?.size) setSize(styles?.style?.size)
          }
          if (item.pivot.position === 'Header' && item.pivot.type === 'icon-city') {
            setIconCity(item.src)
          }
          if (item.pivot.position === 'Header' && item.pivot.type === 'icon-package') {
            setIconPackage(item.src)
          }
          if (item.pivot.position === 'Header' && item.pivot.type === 'icon-blog') {
            setIconBlog(item.src)
          }
        })

        const cities = getBlock(data, 'city')
        const countries = getBlock(data, 'country')
        const dataCities = cities?.cities
        const dataCountries = countries?.countries
        setCities([
          ...dataCountries,
          ...dataCities
        ])
        if (cities?.title) setTitleCity(cities?.title ?? 'Elige tus propuestas por destinos')
        if (cities?.subtitle) setSubTitleCity(cities?.subtitle ?? 'Nuestras recomendaciones')

        const packageData = getBlock(data, 'package')
        const idsPackage = getIds(packageData?.courses, 'package')
        setIdsPackage(idsPackage)
        if (packageData?.title) setTitlePackage(packageData?.title ?? '¡Ya viene el verano!')
        if (packageData?.subtitle) setSubTitlePackage(packageData?.subtitle ?? 'Elige entre nuestro mejores campamentos')

        setTitleBlog(blogs?.title ?? 'Blog')
        setSubTitleBlog(blogs?.subtitle ?? 'Conoce más del mundo')

        // const blogs = getBlock(data, 'blog')
        // setBlogs(blogs?.blogs)
        // if (blogs?.title) setTitleBlog(blogs?.title ?? 'Blog')
        // if (blogs?.subtitle) setSubTitleBlog(blogs?.subtitle ?? 'Conoce más del mundo')

        if (data?.configurations && data?.configurations?.length > 0) setProgramsSorted(JSON.parse(data?.configurations[0]?.value))
      }
    }
  }, [page])

  const getBlock = (data, type) => {
    return data?.blocks?.find(item => item.type === type)
  }

  const getIds = (data, type) => {
    if (type === 'city' || type === 'country') {
      return data?.map(item => item.id + '-' + type)
    }
    return data?.map(item => item.id)
  }

  return (
    <div className='bg-bScreen'>
      <div className='layout-maxWidth'>
        <Space direction='vertical' className='space-content' size={[0, 50]}>
          {/** HERO + Search**/}
          <Layout style={{ background: 'transparent', padding: '20px 2.5vw' }}>
            <PersonalizedSearcher
              headerBigHome
              image={image}
              position={position}
              repeat={repeat}
              size={size}
            />
          </Layout>

          {/** PROGRAMAS **/}
          <Layout style={{}} className='layout-home'>
            <div className='mobile-home'>
              <Row justify='space-around' align='middle' className='byp-mb-1'>
                <Col xl={24} lg={24} md={24}>
                  <h4 className='big-home__question'>¿Qué programa te gustaría estudiar?</h4>
                </Col>
                {/**
                <Col xl={12} lg={12} md={12} align='right' className='big-home__currency'>
                  <PublicIcon className='big-home__currency-icon' /> Español (MXN) $MXN
                </Col>
                **/}
              </Row>

              <Row justify='space-around' className='byp-mb-1'>
                <Col xl={24} className='btns__programs snaps-inline btns_bigHome'>
                  <FilterCategory
                    style={{}}
                    onlyMenu
                    programsSorted={programsSorted}
                    onChange={(programValue) => {
                      // navigate(URL_PROGRAM_PAGE.replace(':program', programValue))
                      const programData = validateProgramName(programValue)
                      const validFilters = removeInvalidValuesFromParams(programData)
                      const filtersQuery = Object.keys(validFilters).map((key) => key + '=' + validFilters[key]).join('&')
                      navigate(URL_QUOTER_PAGE + '?' + filtersQuery)
                    }}
                    onlyProgram
                  />
                </Col>
              </Row>
            </div>
          </Layout>

          {/** CIUDADES **/}
          <Layout style={{}} className='layout-home'>
            <div>
              <h4 style={{ color: '#373737' }} className='title-with-icon'>
                {iconCity ? <img src={iconCity} /> : <IconLocationHome />}
                {titleCity}
              </h4>
              <p style={{ color: '#797979' }} className='subtitle__big-home'>{subTitleCity}</p>
            </div>
            <div>
              <HomeCarrousel
                slides={cities ?? []}
                Slide={CardPlaces}
                className='big-home__package'
                slidesPerRow={slides}
                slideCustomProps={{
                  redirecToHomeOffers: true
                }}
              />
              {/** <div className='big-home__cards-responsive'>
                {cities?.map(item => (
                  <React.Fragment key={item.id}>
                    <CardPlaces item={item} />
                  </React.Fragment>
                ))}
              </div>**/}
            </div>
          </Layout>

          {/** PAQUETES **/}
          {CardWithImage && (
            <Layout style={{}} className='layout-home'>
              <div>
                <h4 style={{ color: '#373737' }} className='title-with-icon'>
                  {iconPackage ? <img src={iconPackage} alt='decoration' /> : <IconCamping />}
                  {titlePackage}
                </h4>
                <p style={{ color: '#797979' }} className='subtitle__big-home'>{subTitlePackage}</p>
              </div>
              <div style={{ width: '100%' }}>
                <HomeCarrousel slides={dataPackage?.data ?? []} Slide={CardWithImage} className='big-home__package' slidesPerRow={slides} />
                {/** RESPONSIVE CARDS
              <div className='big-home__cards-responsive'>
                {dataPackage?.data?.map(item => (
                  <React.Fragment key={item.id}>
                    <CardWithImage item={item}/>
                  </React.Fragment>
                ))}
              </div>**/}
              </div>
            </Layout>
          )}

          {/** BANNER PUBLICIDAD **/}
          <Layout style={{}} className='layout-home'>
            <a href={link} className='mobile-home'>
              <Row
                className='big-home__info'
                style={{
                  padding: 0,
                  margin: 'auto',
                  borderRadius: 20,
                  backgroundImage: `url(${avion})`,
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                {/** <img src={urlImage ?? avion} alt='Avion' style={{backgroundSize:'cover'}}/>**/}
                <Row className='big-home__info-text' align='stretch' justify='start'>
                  <Col style={{ padding: '1% 15px' }}>
                    {/** <ImAirplane className='big-home__imairplane' />**/}
                  </Col>
                  <Col>
                    <h4 style={{ color: '#FFF' }}>{text ?? 'Descubre el mundo, y aprende en el proceso'}</h4>
                    <p style={{ color: '#FFF' }}>
                      {data?.toLocaleString()} diferentes cursos
                    </p>
                  </Col>
                </Row>
                {/** <Row className='big-home__info-text'>
                  <Col sm={2} lg={1} />
                  <Col>
                    <p style={{ color: '#FFF' }}>
                      {data?.tofLocaleString()} diferentes cursos
                    </p>
                  </Col>
                </Row>**/}
              </Row>
            </a>
          </Layout>

          {/** BLOG **/}
          <Layout style={{}} className='layout-home'>
            <div>
              <h4 style={{ color: '#373737' }} className='title-with-icon'>
                {iconBlog ? <img src={iconBlog} /> : <RecordVoiceOverIcon style={{ fontSize: '20px' }} />}
                {titleBlog === 'Elige un idioma, nosotros el lugar' ? 'Blog' : titleBlog}
              </h4>
              <p style={{ color: '#797979' }} className='subtitle__big-home'>{subTitleBlog === 'Expande tu visión del mundo' ? 'Conoce más del mundo' : subTitleBlog}</p>
            </div>
            <div>
              <HomeCarrousel slides={blogs ?? []} Slide={CardBlogSimple} className='big-home__package carrousel-blog' slidesPerRow={slidesBlog} />
              <div className='w-100 centered byp-centered'>
                <Link to={URL_BLOGS_PAGE}>
                  <Button className='byp-btn-red-50 byp-btn-blog'>
                    Ver Más
                  </Button>
                </Link>
              </div>
            </div>
          </Layout>

          {/** LINK A HOME CHIQUITO **/}
          <Layout style={{}} className='layout-home'>
            <div className='mobile-home'>
              <BlogFooter />
            </div>
          </Layout>
        </Space>
      </div>
    </div>
  )
}

export { BigHomeContent }
