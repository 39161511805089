import { Button, Card, Col, Row, Space } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { PencilIcon } from '../../quote-preview/assets/imgs/Icons'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { QuoteLocation } from '../../quote-preview/components/QuoteLocation'
import { EditIcon } from '../../../common/theme/icons/EditIcon'
import { Link, useNavigate } from 'react-router-dom'
import { URL_OPINION_PAGE } from '../../opinion/OpinionPage'
import { useGetOfferExtras } from '../../../bussines/hooks/extras/useGetOfferExtras'
import { BasicSpinner } from '../../../common/components/BasicSpinner'
import { InfoQuoteCourse } from './InfoQuoteCourse'
import { InfoQuoteCampus } from './InfoQuoteCampus'
import { InfoQuoteAccomodations } from './InfoQuoteAccomodations'
import { saveInStorage } from '../../../utils/localStorageUtils'
import { URL_REGISTER_PAGE_BETA } from '../../register'
import { CreateAndAddToCollection } from './CreateAndAddToCollection'
import { HeartOutlined } from '@ant-design/icons'
import { URL_CITY_PAGE } from '../../programs/CountryPage'
import { URL_QUOTER_PAGE } from '../../quoter'
import { validateProgramName } from '../../../utils/programUtils'
import removeInvalidValuesFromParams from '../../../utils/removeInvalidValuesFromParams'

// const { Paragraph } = Typography

const favButtonProps = {
  style: {},
  text: 'Añadir a Favoritos',
  className: 'quote-byp__btn-add-top',
  classNameSpan: 'quotation__header-text',
  icon: <HeartOutlined />
}

const InfoQuote = ({ hasComments }) => {
  const { course, prices, service, isPublicUser } = useQuoter()
  const navigate = useNavigate()
  const [offerId, setOfferId] = useState()
  const [selected, setSelected] = useState()
  const [skills, setSkills] = useState([])
  const [requirements, setRequirements] = useState([])
  const [extraDescription, setExtraDescription] = useState()
  const [targets, setTargets] = useState([])
  const [characteristics, setCharacteristics] = useState([])
  const [isFixed, setIsFixed] = useState(false)
  const [homeUrl, setHomeUrl] = useState(URL_QUOTER_PAGE)

  const cardRef = useRef(null)
  const cardRef2 = useRef(null)

  const { data, isLoading } = useGetOfferExtras(offerId)
  console.log('🚀 ~ InfoQuote ~ data(Hay que sacar camputra a este):', data)

  useEffect(() => {
    if (prices) {
      setOfferId(prices.offer_id)
    } else {
      const p = service?.getPrices()
      setOfferId(p.offer_id)
    }
  }, [prices])

  useEffect(() => {

  }, [selected])

  useEffect(() => {
    if (!isLoading && data) {
      const tags = data.course?.tags ?? []
      const extras = data.course?.extras ?? []
      const skills = tags.filter((extra) => extra.type === 'skill')
      setSkills(skills)
      const requirements = extras.filter((extra) => extra.type === 'requirement')
      setRequirements(requirements)
      const targets = extras.filter((extra) => extra.type === 'target')
      setTargets(targets)
      const characteristics = extras.filter((extra) => extra.type === 'characteristics')
      setCharacteristics(characteristics)
      const extraDescription = extras.find((extra) => extra.type === 'description')
      setExtraDescription(extraDescription)
      setSelected('course')
    }
  }, [isLoading, data])

  useEffect(() => {
    const handleScroll = () => {
      if (!cardRef.current) return
      const cardRect = cardRef.current.getBoundingClientRect()
      setIsFixed(cardRect.top <= 0)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (!cardRef2.current) return
      const cardRect = cardRef2.current.getBoundingClientRect()
      setIsFixed(cardRect.top <= 0)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const programParams = validateProgramName(course?.offer?.progra_type ?? course?.offer?.program?.name)
    const validParams = removeInvalidValuesFromParams(programParams)
    const filtersQuery = Object.keys(validParams).map((key) => key + '=' + validParams[key]).join('&')
    setHomeUrl(URL_QUOTER_PAGE + '?' + filtersQuery)
  }, [course])

  const handleOpinionRedirection = () => {
    const opinionPage = URL_OPINION_PAGE.replace(':campusSlug', course?.offer?.campus?.slug).replace(':courseId', course?.offer?.id)
    saveInStorage('redirectOnLoginSucces', opinionPage)
    navigate(URL_REGISTER_PAGE_BETA)
  }
  const handleCita = () => {
    window.open('https://calendly.com/bookyourprogram', '_blank')
  }

  const handleDescription = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    let newText = doc.body.textContent || ''
    newText = newText.length > 440 ? newText.substring(0, 440) + '...' : newText

    return newText
  }

  return (
    <>
      {isLoading && <BasicSpinner />}
      {!isLoading && data && (
        <>
          <div ref={cardRef2} />
          <Card className={`byp-mb-1 quotation__transform ${isFixed ? 'quotation__container-header' : ''}`} ref={cardRef} size='small'>
            <Row>
              <Col span={24}>
                <Row justify='space-between' align='middle'>
                  {/**  BOTONES DE INFORMACION **/}
                  <Col className='quotation__about'>
                    <Button
                      className={selected === 'school' ? 'quote-byp__btn-info mr-05 active' : 'quote-byp__btn-info mr-05'}
                      onClick={() => {
                        setSelected('school')
                        const offsetTop = document.getElementById('info-course').offsetTop
                        window.scrollTo({
                          top: offsetTop + 500,
                          behavior: 'smooth'
                        })
                      }}
                    >Sobre la Escuela
                    </Button>
                    <Button
                      className={selected === 'course' ? 'quote-byp__btn-info mr-05 active' : 'quote-byp__btn-info mr-05'}
                      onClick={() => {
                        setSelected('course')
                        const offsetTop = document.getElementById('info-course').offsetTop
                        window.scrollTo({
                          top: offsetTop + 500,
                          behavior: 'smooth'
                        })
                      }}
                    >Resumen del Curso
                    </Button>
                    {/* {(requirements.length + targets.length + skills.length) > 0 && (
                    )} */}
                    {data?.accommodations?.length > 0 && (
                      <Button
                        className={selected === 'accommodation' ? 'quote-byp__btn-info mr-05 active' : 'quote-byp__btn-info mr-05'}
                        onClick={() => {
                          setSelected('accommodation')
                          const offsetTop = document.getElementById('info-course').offsetTop
                          window.scrollTo({
                            top: offsetTop + 500,
                            behavior: 'smooth'
                          })
                        }}
                      >Tipos de Hospedaje
                      </Button>
                    )}
                    {hasComments && (
                      <Button
                        className='quote-byp__btn-info'
                        onClick={() => {
                          const offsetTop = document.getElementById('offer-opinions').offsetTop
                          window.scrollTo({
                            top: offsetTop - 100,
                            behavior: 'smooth'
                          })
                        }}
                      >Calificación y comentarios
                      </Button>
                    )}
                  </Col>
                  {/**  BOTONES DE ACCION **/}
                  <Col className='flex-byp__group'>

                    <Space>
                      {isPublicUser && (
                        <Button className='quote-byp__btn-opinion' onClick={handleOpinionRedirection}>
                          <PencilIcon />
                          Deja tu Opinión
                        </Button>
                      )}
                      {!isPublicUser && (
                        <Button className='quote-byp__btn-opinion'>
                          <PencilIcon />
                          <Link
                            to={URL_OPINION_PAGE.replace(':campusSlug', course?.offer?.campus?.slug).replace(':courseId', course?.offer?.id)}
                            state={{
                              campus: course?.offer?.campus,
                              course: {
                                id: course?.offer?.id,
                                name: course?.offer?.name
                              }
                            }}
                          >Deja tu Opinión
                          </Link>
                        </Button>
                      )}

                      <a href='#qoute-course'>
                        <Button className='quote-byp__btn-opinion'>
                          Cotiza ahora
                        </Button>
                      </a>
                      <Button className='quote-apply_now' style={{ padding: '0 1rem' }} onClick={handleCita}>
                        Agenda una Cita
                      </Button>
                      <CreateAndAddToCollection buttonProps={favButtonProps} />
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          {isFixed && (
            <div style={{ height: '5rem', position: 'relative' }} />
          )}
        </>
      )}

      {!isLoading && data && (
        <Row gutter={[16, 16]} className='byp-mb-1 quote-byp__container'>
          <Col xs={24} md={14}>
            <div id='info-course' style={{ height: '100%' }}>
              {selected === 'course' && (
                <InfoQuoteCourse
                  course={course}
                  extraDescription={extraDescription}
                  skills={skills}
                  requirements={requirements}
                  characteristics={characteristics}
                  targets={targets}
                />
              )}
              {selected === 'school' && (
                <InfoQuoteCampus
                  course={course}
                  campus={data?.campus}
                  extras={data?.campus?.extras}
                  tags={data?.campus?.tags}
                />
              )}
              {selected === 'accommodation' && (
                <InfoQuoteAccomodations
                  course={course}
                  accommodations={data?.accommodations ?? []}
                />
              )}
            </div>
          </Col>
          <Col xs={24} md={10}>
            <QuoteLocation campus={course?.offer?.campus} />
            <Card className='byp-mb-1'>
              <Row>
                {/* <Col sm={24} md={6}>
                  <img src='' alt={course?.offer?.campus?.name} style={{ width: '6.375rem', aspectRatio: '1' }} />
                </Col> */}
                <Col span={24} className='quote-byp__school'>
                  <span>{course?.offer?.campus?.country?.name}</span>
                  <h4 style={{ fontSize: '25px', fontWeight: '600' }}>{course?.offer?.campus?.city?.name}</h4>
                  {/* <div className='social-byp__group'>
                    {getSocialNetworkIcon('Facebook', 'http')}
                    {getSocialNetworkIcon('Facebook', 'http')}
                  </div> */}
                  {course?.offer?.description_city && (
                    <>
                      <div className='quotation__description-school'>{handleDescription(course?.offer?.description_city)}</div>
                      <a href={URL_CITY_PAGE.replace(':code', course?.offer?.campus?.country?.iso3).replace(':city', course?.offer?.campus?.city?.id)} className='quotation__description-school-link'>
                        Leer más
                      </a>
                    </>
                  )}
                  {/* <Paragraph ellipsis={{ rows: 4, expandable: true }} className='quotation__description-school'>{course?.offer?.campus?.description}</Paragraph> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: course?.offer?.description_city }} /> */}
                </Col>
              </Row>
            </Card>
            <Card className='quote-byp__new_search hide_mobile'>
              <h2 className='byp-m-0'>Empezar de nuevo</h2>
              <Button>
                <EditIcon />
                <Link style={{ color: '#797979' }} to={homeUrl}>
                  Nueva Búsqueda
                </Link>
              </Button>
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}

export { InfoQuote }
