import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Switch, Tag, Form, Modal } from 'antd'
import { UnionIcon } from '../../../../quote-preview/assets/imgs/Icons'
import { useGetAgencyUsers } from '../../../../../bussines/hooks/agency/analytics/general/useGetAgencyUsers'
import { EditIcon } from '../../../../../common/theme/icons/EditIcon'
import ConfirmModal from '../../../../../common/components/ConfirmModal'
import { useDeleteUser } from '../../../../../bussines/hooks/user/useDeleteUser'
import { useChangeStatusUser } from '../../../../../bussines/hooks/user/useChangeStatusUser'
import { SelectUserModal } from '../../../users/SelectUserModal'
import { Link } from 'react-router-dom'
import { URL_CREATE_USERS_PAGE } from '../../../users/CrudUserPage'
import { useDeleteInvitation } from '../../../../../bussines/hooks/invitation/useDeleteInvitation'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'
import { IconPeopleFill } from '../../../../../common/assets/svg/icons'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'

const AgencyUsers = ({ readOnly, office }) => {
  const [form] = Form.useForm()
  const [mdl, setMdl] = useState(false)
  const [userId, setUserId] = useState()
  const [validUsers, setValidUsers] = useState([])
  const [userList, setUserList] = useState([])
  const [active, setActive] = useState(0)
  const [inactive, setInactive] = useState(0)
  const [deleted, setDeleted] = useState(0)
  const [modal, contextHolder] = Modal.useModal()
  const { user: _user } = useAuth()

  const { data: agencyUsers, refetch, isLoading: isLoadingUser } = useGetAgencyUsers(office)
  const { deleteUser, isLoading: isDeletingUser, data: resp } = useDeleteUser()
  const { deleteInvitation, isLoading: isDeletingInvitation, data: deleteInvitationResp } = useDeleteInvitation()
  const { changeStatus, isLoading } = useChangeStatusUser()

  const handleChange = (value, id) => {
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    if (value) {
      changeStatus(id)
    } else {
      const validUsers = agencyUsers?.filter((item) => (item.status === 'Active' && item.id !== id))
      setValidUsers(validUsers)
      setMdl(true)
      setUserId(id)
    }
  }

  const closeModal = () => {
    setMdl(false)
  }

  const handleSubmit = () => {
    changeStatus(userId, form.getFieldValue('user'))
    setMdl(false)
  }

  useEffect(() => {
    if (!isDeletingUser && resp) {
      refetch()
    }
  }, [isDeletingUser, isLoading, refetch, resp])

  useEffect(() => {
    if (!isDeletingInvitation && deleteInvitationResp) {
      refetch()
    }
  }, [isDeletingInvitation, deleteInvitationResp])

  useEffect(() => {
    agencyUsers?.user_status_count?.forEach(users => {
      if (users.status === 'Active') {
        setActive(users.user_count)
      }
      if (users.status === 'Inactive') {
        setInactive(users.user_count)
      }
      if (users.deleted === 'Deleted') {
        setDeleted(users.user_count)
      }
    })
    if (agencyUsers?.users) {
      const users = agencyUsers?.users.sort(function (a, b) {
        if (a.created_at > b.created_at) {
          return -1
        }
        if (a.created_at < b.created_at) {
          return 1
        }
        // a must be equal to b
        return 0
      })
      setUserList(users.slice(0, 5))
    }
    if (agencyUsers?.users) {
      const activeUsers = agencyUsers?.users?.filter(user => user.status === "Active")
      setActive(activeUsers?.length)
    }
  }, [agencyUsers])

  if (isLoadingUser) {
    return <BasicSpinner />
  }

  return (
    <div>
      {contextHolder}
      <Row style={{ justifyContent: 'space-between' }}>
            <h4 className='byp-title-4 quote-overview--color-blue'>
              {_user?.type === 'Agency' ? 'Agency users' : 'Institution users'}
              <span className='byp-text-sm'>(last {agencyUsers?.users?.length} invited)</span>
            </h4>
            <Link to={URL_CREATE_USERS_PAGE}>
              <Button className='byp-btn-yellow btn-apply quote-overview--color-blue'>
                <span className='align-icon-btn'><IconPeopleFill />Add user</span>
                </Button>
            </Link>
      </Row>

      <Row>
        <Col span={24} className='byp-mt-1'>
          <div className='quote-list-item-card quote_general d-flex justify-center' style={{ padding: '0 0.75rem' }}>
            <div className='w-100'>
              <p className='margin-0 byp-text-sm byp-color-gray-100 heigth-35'>All users</p>
              <p className='margin-0'>{agencyUsers?.users?.length}</p>
            </div>
            <div className='w-100 byp-border-left' style={{ paddingLeft: '0.5rem' }}>
              <p className='margin-0 byp-text-sm byp-color-gray-100 heigth-35'>Invited</p>
              <p className='margin-0'>{agencyUsers?.invitations}</p>
            </div>
            <div className='w-100 byp-border-left' style={{ paddingLeft: '0.5rem' }}>
              <p className='margin-0 byp-text-sm byp-color-gray-100 heigth-35'>Active</p>
              <p className='margin-0'>{active}</p>
            </div>
            <div className='w-100 byp-border-left' style={{ paddingLeft: '0.5rem' }}>
              <p className='margin-0 byp-text-sm byp-color-gray-100 heigth-35'>Inactive</p>
              <p className='margin-0'>{inactive}</p>
            </div>
            <div className='w-100 byp-border-left' style={{ paddingLeft: '0.5rem' }}>
              <p className='margin-0 byp-text-sm byp-color-gray-100 heigth-35'>Deleted</p>
              <p className='margin-0'>{deleted}</p>
            </div>
          </div>
        </Col>

        <Col span={24} className='byp-mt-1'>
          <Row gutter={[0, 10]}>
            {
              userList?.map(user => (
                <Col span={24} key={user.id}>
                  <div className='quote-list-item-card quote_general border-radius-blue d-flex align-center box-shadow-agency-users' style={{ padding: '0.675rem', gap: '1rem' }}>
                    <img className='img-rounded display-none-responsive' style={{ width: '3.75rem', height: '3.75rem' }} src={user.profile_photo_url ?? `https://ui-avatars.com/api/?name=${user.name?.split('')[0]}&color=7F9CF5&background=EBF4FF`} alt={user.name} />
                    <div style={{ width: '7.5rem', wordWrap: 'break-word', flex: '1' }}>
                      <p className='margin-0 byp-blue-100 byp-fw-bold'>{user.name}</p>
                      <p className='margin-0 byp-fw-bold byp-text-sm'>{user.role}</p>
                      <p className='margin-0 byp-text-sm'>{user.sede}</p>
                      <p className='margin-0 byp-text-sm'>{user.email}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '90px' }}>
                      <div style={{ display: 'flex', paddingTop: '5px', justifyContent: 'center' }}>
                        {
                          (user.status === 'Active' || user.status === 'Inactive') && (
                            <Switch
                              style={{ alignItems: 'center', display: 'flex' }}
                              checkedChildren='ACTIVE'
                              unCheckedChildren='INACTIVE'
                              loading={isLoading}
                              onClick={
                                (value) => handleChange(value, user.id)
                              }
                              checked={user.status === 'Active'}
                            />
                          )
                        }
                        {
                          user.status === 'In Process' && (
                            <Tag className='byp-btn-sm byp-bg-blue-50 byp-fw-bold byp-color-white' style={{ textTransform: 'uppercase' }}>
                              IN PROCESS
                            </Tag>
                          )
                        }
                      </div>
                      <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', padding: '5px 0', gap: '10px' }} >
                        <Link to={URL_CREATE_USERS_PAGE}>
                          <Button
                            size='small'
                            className='byp-btn-edit'
                            icon={<EditIcon />}
                          />
                        </Link>
                        <ConfirmModal
                          onOk={user.status === 'In Process' ? deleteInvitation : deleteUser}
                          isLoading={isDeletingUser || isDeletingInvitation}
                          id={user.id}
                          hasPermision={!readOnly}
                        />
                      </div>
                    </div>
                  </div>

                </Col>

              ))

            }
          </Row>
        </Col>

        <Col span={24} className='byp-mt-1'>
          <Link to={URL_CREATE_USERS_PAGE}>
            <Button className='w-100 margin-0 byp-btn-back'>See all</Button>
          </Link>
        </Col>
      </Row>

      <SelectUserModal
        setMdl={setMdl}
        mdl={mdl}
        clsMdl={closeModal}
        handleSubmit={handleSubmit}
        validUsers={validUsers}
        form={form}
        isLoading={isLoading}
      />
    </div>
  )
}

export default AgencyUsers


/*


userList?.map(user => (
                <Col span={24} key={user.id}>
                  <div className='quote-list-item-card quote_general border-radius-blue d-flex align-center box-shadow-agency-users' style={{ padding: '0.675rem', gap: '1rem'}}>
                    <img className='img-rounded display-none-responsive' style={{ width: '3.75rem', height: '3.75rem' }} src={user.profile_photo_url ?? `https://ui-avatars.com/api/?name=${user.name?.split('')[0]}&color=7F9CF5&background=EBF4FF`} alt={user.name} />
                    <div style={{ width: '7.5rem', wordWrap: 'break-word', flex: '1' }}>
                      <p className='margin-0 byp-blue-100 byp-fw-bold'>{user.name}</p>
                      <p className='margin-0 byp-fw-bold byp-text-sm'>{user.role}</p>
                      <p className='margin-0 byp-text-sm'>{user.sede}</p>
                      <p className='margin-0 byp-text-sm'>{user.email}</p>
                    </div>
                    <div style={{ display:'flex', flexDirection:'column', width:'90px'}}>
                      <div style={{  display:'flex', paddingTop:'5px', justifyContent:'center' }}>
                        {
                          (user.status === 'Active' || user.status === 'Inactive') && (
                            <Switch
                              style={{ alignItems: 'center', display: 'flex' }}
                              checkedChildren='ACTIVE'
                              unCheckedChildren='INACTIVE'
                              loading={isLoading}
                              onClick={
                                (value) => handleChange(value, user.id)
                              }
                              checked={user.status === 'Active'}
                            />
                          )
                        }
                        {
                          user.status === 'In Process' && (
                            <Tag className='byp-btn-sm byp-bg-blue-50 byp-fw-bold byp-color-white' style={{ textTransform: 'uppercase' }}>
                              IN PROCESS
                            </Tag>
                          )
                        }
                      </div>
                      <div style={{ display:'flex', alignContent:'center', alignItems:'center', justifyContent:'center', padding:'5px 0', gap:'10px' }} >
                        <Link to={URL_CREATE_USERS_PAGE}>
                          <Button
                            size='small'
                            className='byp-btn-edit'
                            icon={<EditIcon />}
                          />
                        </Link>
                        <ConfirmModal
                          onOk={user.status === 'In Process' ? deleteInvitation : deleteUser}
                          isLoading={isDeletingUser || isDeletingInvitation}
                          id={user.id}
                          hasPermision={!readOnly}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))



*/