import './App.less'
import React from 'react'
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
import { PageRoutes } from './router/PageRoutes'
import { ReactQueryDevtools } from 'react-query/devtools'
import '../src/languageConfiguration/languageConfiguration'
import WhatsAppButton from './common/components/WhatsAppButton'
import { UrlProvider } from './pages/quote-course/context/UrlContext'
import { ActivityTimerProvider } from './providers/userActivity/activity-timer-provider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

// {/* <PageProtected permissionTypes={[userRoles.TYPE_ANY]}> */}

function App () {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ActivityTimerProvider> */}
      {/* <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}> */}
        <UrlProvider>
          <PageRoutes />
          <WhatsAppButton />
          <ReactQueryDevtools initialIsOpen={false} />
        </UrlProvider>
      {/* </ActivityTimerProvider> */}
    </QueryClientProvider>
  )
}

export default App
