import { Button, Col, DatePicker, Form, Input, Row } from 'antd'
import React from 'react'
// import { PlusOutlined } from '@ant-design/icons'
import { RiDeleteBin6Fill } from 'react-icons/ri'
import { SelectFamilyRelationshipFormItem } from '../../../common/components/helper-form/SelectFamilyRelationshipFormItem'
import { BasicRadioGroupFormItem } from '../../../common/components/helper-form/BasicRadioGroupFormItem'

const required = { required: true, message: 'Este dato es requerido' }

function ContactForm ({ disabled = false, listName = 'contacts', className, displayExtraFileds }) {
  const form = Form.useFormInstance()
  const values = Form.useWatch(listName, form) || []

  return (
    <Form.List
      name={listName}
      className={className}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} style={{ width: '100%' }} gutter={16} align='middle'>
              <Col span={24} className='d-flex'>
                <b className='fs-12 fw-6'>Contacto {index + 1}</b>
                <span
                  className='byp-ml-1 contact-form_delete br-4'
                  style={{ border: 'solid 1px var(--red-50)', borderRadius: '4px', display: 'inline-flex', width: '1.4rem', height: '1.4rem', padding: '.2rem', cursor: 'pointer' }}
                  onClick={() => remove(index)}
                >
                  <RiDeleteBin6Fill color='var(--red-50)' />
                </span>
              </Col>
              <Col xs={24} md={displayExtraFileds ? 8 : 12}>
                <label className='label-aplication'>Nombre Completo</label>
                <Form.Item
                  {...restField}
                  name={[name, 'full_name']}
                  rules={[required]}
                >
                  <Input className='aplication__input' disabled={disabled} />
                </Form.Item>
              </Col>

              <Col xs={24} md={displayExtraFileds ? 8 : 12}>
                <label className='label-aplication'>Parentesco</label>
                <SelectFamilyRelationshipFormItem
                  name={[name, 'kinship']}
                  size='large'
                  className='byp-select transparent br-1 aplication__input byp-p-0'
                  formItemProps={{
                    className: 'byp-select transparent br-1 aplication__input byp-p-0'
                  }}
                  disabled={disabled}
                />
              </Col>

              {displayExtraFileds && (
                <>
                  <Col xs={24} md={8}>
                    <label className='label-aplication'>Ocupación</label>
                    <Form.Item
                      {...restField}
                      name={[name, 'occupation']}
                      rules={[required]}
                    >
                      <Input className='aplication__input' disabled={disabled} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <label className='label-aplication'>Fecha de Nacimiento</label>
                    <Form.Item
                      {...restField}
                      name={[name, 'birthday']}
                      rules={[required]}
                    >
                      <DatePicker className='aplication__input-date' format='DD [de] MMMM [de] YYYY' disabled={disabled} />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col xs={24} md={displayExtraFileds ? 8 : 12}>
                <label className='label-aplication'>Teléfono Celular</label>
                <Form.Item
                  {...restField}
                  name={[name, 'cel']}
                  rules={[required]}
                >
                  <Input className='aplication__input' disabled={disabled} />
                </Form.Item>
              </Col>
              <Col xs={24} md={displayExtraFileds ? 8 : 12}>
                <label className='label-aplication'>Email</label>
                <Form.Item
                  {...restField}
                  name={[name, 'email']}
                  rules={[required]}
                >
                  <Input className='aplication__input' disabled={disabled} />
                </Form.Item>
              </Col>

              {displayExtraFileds && (
                <BasicRadioGroupFormItem
                  name={[name, 'sameAddress']}
                  label='¿Tu dirección es la misma que el estudiante?'
                  trueText='Sí'
                  falseText='No'
                  initialValue
                  radioGroupProps={{
                    className: 'byp-ant-radio',
                    disabled
                  }}
                />
              )}

              {displayExtraFileds && !values[name]?.sameAddress && (
                <>
                  <Col span={24}>
                    <label className='label-aplication'>Dirección completa</label>
                    <Form.Item
                      name={[name, 'address']}
                      rules={[required]}
                    >
                      <Input className='aplication__input' disabled={disabled} placeholder='Avenida 8 de Julio 879' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name={[name, 'colony']}
                      rules={[required]}
                    >
                      <Input className='aplication__input' placeholder='Colonia' disabled={disabled} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name={[name, 'zip_code']}
                      rules={[required]}
                    >
                      <Input className='aplication__input' placeholder='Código Postal' disabled={disabled} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name={[name, 'city']}
                      rules={[required]}
                    >
                      <Input className='aplication__input' placeholder='Ciudad' disabled={disabled} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name={[name, 'country']}
                      rules={[required]}
                    >
                      <Input className='aplication__input' placeholder='País' disabled={disabled} />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          ))}
          <Col span={24}>
            <Button className='byp-btn-gray-300 br-06 fs-12 fw-5' size='large' onClick={() => add()} disabled={disabled}> {/*  icon={<PlusOutlined />} */}
              Agregar otro contacto de emergencia
            </Button>
          </Col>
        </>
      )}
    </Form.List>
  )
}

export default ContactForm
