import { Button, Row } from 'antd'
import React, { useEffect } from 'react'
import SchoolIcon from '@material-ui/icons/School'
import { Diploma, IconAppleSchool, IconInternado } from '../../../common/assets/svg/icons'

const ButtonCategorySelector = ({ collection = [], onChange = null, selected, isHomeSmall, program, programsSorted, onlyProgram = false, setGhost }) => {
  const [select, setSelect] = React.useState()
  const [programs, setPrograms] = React.useState([])
  const handleOnSelect = (k) => {
    setSelect(k)
    if (onChange && (program !== k)) {
      onChange(k)
    }
  }

  useEffect(() => {
    const arrayPrograms = []
    if (collection && collection?.length > 0) {
      collection?.forEach(item => {
        if (onlyProgram) {
          if (item.key === 'language') {
            arrayPrograms.push(item)
          } else if (item.key === 'boarding_schools') {
            arrayPrograms.push(item)
          } else if (item.key === 'academic_years') {
            arrayPrograms.push(item)
          } else if (item.key === 'graduate') {
            arrayPrograms.push({ key: 'graduate_diploma', label: 'Diplomas y Certificados' })
            arrayPrograms.push({ key: 'bachelor', label: 'Licenciaturas' })
            arrayPrograms.push({ key: 'postgraduate', label: 'Posgrados' })
            arrayPrograms.push({ key: 'master', label: 'Maestrías' })
          }
        } else {
          if (item.key === 'graduate') {
            arrayPrograms.push({ key: 'graduate_diploma', label: 'Diplomas y Certificados' })
            arrayPrograms.push({ key: 'bachelor', label: 'Licenciaturas' })
            arrayPrograms.push({ key: 'postgraduate', label: 'Posgrados' })
            arrayPrograms.push({ key: 'master', label: 'Maestrías' })
          } else {
            arrayPrograms.push(item)
          }
        }
      })
    }

    const newOrder = [
      'language',
      'graduate_diploma',
      'academic_years',
      'boarding_schools',
      'bachelor',
      'master',
      'postgraduate',
      'short_term'
    ];

    const sortedPrograms = arrayPrograms.sort((a, b) => {
      const aIndex = newOrder.indexOf(a.key);
      const bIndex = newOrder.indexOf(b.key);

      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;

      return aIndex - bIndex;
    });

    setPrograms(sortedPrograms);
  }, [programsSorted, collection])

  useEffect(() => {
    setSelect(program)
  }, [program])

  function AEspañol({ palabra }) {
    switch (palabra) {
      case 'Languages':
        return (
          <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'><path d='M30.3 3h-16v5h4v2h-13c-1.7 0-3 1.3-3 3v11c0 1.7 1.3 3 3 3h1v5.1l6.3-5.1h6.7v-7h11c1.7 0 3-1.3 3-3V6c0-1.7-1.3-3-3-3M13.1 22.9l-.5-1.6H9.5l-.6 1.6H6.5L9.8 14h2.4l3.3 8.9zM28.3 15v2c-1.3 0-2.7-.4-3.9-1c-1.2.6-2.6.9-4 1l-.1-2c.7 0 1.4-.1 2.1-.3c-.9-.9-1.5-2-1.8-3.2h2.1c.3.9.9 1.6 1.6 2.2c1.1-.9 1.8-2.2 1.9-3.7h-6V8h3V6h2v2h3.3l.1 1c.1 2.1-.7 4.2-2.2 5.7c.7.2 1.3.3 1.9.3' className='clr-i-solid clr-i-solid-path-2' /></svg>
            Idiomas
          </Row>
        )
      case 'Short Term Courses':
        return (
          <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -960 960 960'><path d='M80-80v-183l363-491-67-90 49-35 55 75 56-75 48 35-66 90 362 491v183H80Zm242-60h316L480-361 322-140Z' /></svg>
            Cursos cortos
          </Row>
        )
      case 'High Schools':
        return (
          <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <IconAppleSchool /> Años academicos
          </Row>
        )

      case 'Boarding Schools':
        return (
          <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <IconInternado />
            Boardings
          </Row>
        )
      case 'Diplomas y Certificados':
        return (
          <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <Diploma />
            <span>Diplomas y Certificados</span> 
            
          </Row>
        )
      case 'Licenciaturas':
        return (
          <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <SchoolIcon />
            Licenciaturas
          </Row>
        )
      case 'Posgrados':
        return (
          <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -960 960 960' fill='#797979'><path d='M298-120v-60h152v-148q-54-11-96-46.5T296-463q-74-8-125-60t-51-125v-44q0-25 17.5-42.5T180-752h104v-88h392v88h104q25 0 42.5 17.5T840-692v44q0 73-51 125t-125 60q-16 53-58 88.5T510-328v148h152v60H298Zm-14-406v-166H180v44q0 45 29.5 78.5T284-526Zm392 0q45-10 74.5-43.5T780-648v-44H676v166Z' /></svg>
            Posgrados
          </Row>
        )
      case 'Maestrías':
        return (
          <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='256' height='256' viewBox='0 0 256 256'><path fill='currentColor' d='M216 96a88 88 0 1 0-144 67.83V240a8 8 0 0 0 11.58 7.16L128 225l44.43 22.21a8.07 8.07 0 0 0 3.57.79a8 8 0 0 0 8-8v-76.17A87.85 87.85 0 0 0 216 96M56 96a72 72 0 1 1 72 72a72.08 72.08 0 0 1-72-72m16 0a56 56 0 1 1 56 56a56.06 56.06 0 0 1-56-56' /></svg>
            Maestrías
          </Row>
        )
      default:
        return (<>{palabra}</>)
    }
  }

  const getButtons = (item, key) => {
    return (
      <Button
        onClick={() => {
          handleOnSelect(item.key)
        }}
        className='byp-btn-category'
        ghost={setGhost ? true : (select !== item.key && ((select === '' && selected !== item.key) || select !== ''))}
        key={key}
      >
        <AEspañol palabra={item.label} />
      </Button>
    )
  }

  return (
    <>
      {isHomeSmall &&
        <Button
          onClick={() => {
            handleOnSelect(null)
          }}
          className='byp-btn-category'
          ghost={select !== null && ((select === '' && selected !== null) || select !== '')}
        >
          <Row style={{ alignItems: 'center' }}>
            {/* <FaSchoolFlag style={{ marginRight: '.5rem' }} /> */}
            Todos
          </Row>
        </Button>}
      {programs.map((item, key) => { return getButtons(item, key) })}
    </>
  )
}

export { ButtonCategorySelector }
