import React, { useEffect, useState } from 'react'
import { Button, Carousel } from 'antd'
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons'
import CardEdit from './CardEdit'

const HomeCarrousel = ({ slides, Slide, className = '', label, slidesPerRow = 4, editSection = 0, editModal, removeItem, editItem, isEditing, slideCustomProps = {} }) => {
  const [noCardsEdit, setNoCardsEdit] = useState([])
  const [sortCards, setSortCards] = useState(slides ?? [])

  useEffect(() => {
    if (slides?.length > 0) {
      if ((editSection - slides?.length) > 0) {
        const noCards = new Array(editSection - slides?.length).fill('')
        setNoCardsEdit(noCards)
      } else {
        setNoCardsEdit([])
      }

      setSortCards(
        slides?.sort((a, b) => {
          return a?.pivot?.order - b?.pivot?.order
        })
      )
    } else {
      const noCards = new Array(editSection).fill('')
      setNoCardsEdit(noCards)
    }
  }, [slides])

  return (
    <div className='carrousel-container'>
      <Carousel
        autoplay={false}
        effect='fade'
        dots={false}
        slidesPerRow={slidesPerRow}
        className={className}
        arrows
        nextArrow={
          <Button className='carrousel-bottoms'>
            <RightCircleFilled className='carrousel-bottoms__right' style={{ fontSize: '35px', color: ' #373737' }} />
          </Button>
        }
        prevArrow={
          <Button className='carrousel-bottoms'>
            <LeftCircleFilled className='carrousel-bottoms__left' style={{ fontSize: '35px', color: ' #373737' }} />
          </Button>
        }
        responsive={[
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              arrows: false,
              centerMode: true,
              centerPadding: '20px',
              fade: false
            }
          }
        ]}
      >
        {sortCards?.map((collection, index) => (
          <div
            key={collection.id}
          >
            <div style={{ marginRight: '1rem' }}>
              <Slide
                item={collection}
                label={label}
                removeItem={removeItem}
                id={collection.id}
                editItem={editItem}
                editBlogs={isEditing}
                {...slideCustomProps}
              />
            </div>
          </div>
        ))}
        {noCardsEdit?.map((item, index) => (
          <div key={index}>
            <div>
              <CardEdit editModal={editModal} />
            </div>
          </div>
        ))}
      </Carousel>
    </div>

  )
}

export { HomeCarrousel }
