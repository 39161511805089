import { useLocation, useParams } from 'react-router-dom'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { ValidateEnterpriseProfile } from '../../common/components/ValidateEnterpriseProfile'
import { MainTheme } from '../../common/theme/MainTheme'
import { SearchProvider } from '../../providers/search/search-context-provider'
import { ContentProgramPage } from './components/contentProgramPage'

const URL_PROGRAM_PAGE = '/program/:program'
const URL_PROGRAM_PREVIEW_PAGE = '/program/:program/preview'

function ProgramPage () {
  const { program } = useParams()
  const location = useLocation()
  const containsPreview = location.pathname.includes('preview')

  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme activeCarousel showMainTheme={false} showMenuFooter>
        <SearchProvider>
          <ContentProgramPage program={program} isPreview={containsPreview} />
        </SearchProvider>
      </MainTheme>
    </ValidateEnterpriseProfile>
  )
}

export { ProgramPage, URL_PROGRAM_PAGE, URL_PROGRAM_PREVIEW_PAGE }
