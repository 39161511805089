import { Form, Input } from 'antd'

const PasswordFormItem = ({ className, name, confirmName, isRequired = true, label }) => {
  return (
    <>
      <Form.Item
        name={name ?? 'password'}
        label={label}
        rules={[
          {
            required: isRequired,
            message: 'Ingresa tu contraseña'
          }
        ]}
        hasFeedback
      >
        <Input.Password size='large' placeholder='Contraseña' className={className} />
      </Form.Item>

      <Form.Item
        name={confirmName ?? 'confirm'}
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: isRequired,
            message: 'Por favor, confirma tu contraseña!'
          },
          ({ getFieldValue }) => ({
            validator (_, value) {
              if (!value || getFieldValue(name ?? 'password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('Las contraseñas no conciden!')
              )
            }
          })
        ]}
      >
        <Input.Password size='large' placeholder='Confirma tu contraseña' className={className} />
      </Form.Item>
    </>
  )
}

export { PasswordFormItem }
