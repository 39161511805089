import React from 'react'
import { useAuth } from './auth-context-provider'

const EnterpriseStatusContext = React.createContext()

function EnterpriseStatusProvider (props) {
  const { companyStatus, registrationStatus } = useAuth()

  // useEffect(() => {
  //   const roles = user?.role_names ?? []
  // }, [companyStatus])

  return <EnterpriseStatusContext.Provider value={{ companyStatus, registrationStatus }} {...props} />
}

function useEnterpriseStatus () {
  const context = React.useContext(EnterpriseStatusContext)
  if (context === undefined) {
    throw new Error('useEnterpriseStatus must be used within a EnterpriseStatusProvider')
  }
  return context
}

export { EnterpriseStatusProvider, useEnterpriseStatus }
