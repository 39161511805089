import { useMutation } from 'react-query'
import state from '../../utils/state-control'
import { useState } from 'react'
import CollectionRepository from '../../repositories/CollectionRepository'

function useRemoveItemFromCollection () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(CollectionRepository.removeItem, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const removeItem = (itemId) => {
    mutation.mutate({ id: itemId })
  }

  return { ...mutation, state, currentState, removeItem }
}

export { useRemoveItemFromCollection }
