const retrieveFromStorage = (key) => {
  const data = localStorage.getItem(key)
  return data
}

const saveInStorage = (key, value) => {
  // console.log('🚀 ~ file: localStorageUtils.js:8 ~ saveInStorage ~ key, value:', key, value)
  if (!value || value === 'undefined') {
    return false
  }
  localStorage.setItem(key, value)
}

const removeFromStorage = (key) => {
  localStorage.removeItem(key)
}

const cutFromStorage = (key) => {
  const data = localStorage.getItem(key)
  localStorage.removeItem(key)
  return data
}

export { retrieveFromStorage, saveInStorage, removeFromStorage, cutFromStorage }
