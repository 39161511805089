import { Form, Select, Typography } from 'antd'
import { useGetTags } from '../../../../bussines/hooks/tag/useGetTags'
const { Option } = Select

const SelectTagsFormItem = ({ name, label, filters, ...rest }) => {
  const { data: tags } = useGetTags(filters)
  return (
    <>
      {label && <Typography.Title level={3} className='w-100'>{label}</Typography.Title>}
      <Form.Item
        name={name}
        // label={label}
      >
        <Select
          className='w-100'
          mode='tags'
          {...rest}
        >
          {tags?.data?.map((tag) => (
            <Option key={tag.name}>{tag.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

export { SelectTagsFormItem }
