import api from '../../services/api'

class CampusRespository {
  async campus (params) {
    const response = await api.get('/campus', { params })
    return response.data
  }

  async getCampusTable (category, queryString) {
    if (category === undefined) {
      return null
    }
    const response = await api.get(
      `/school/services/${category}/table?${queryString}`
    )
    return response?.data
  }

  async getFindCampus (id) {
    if (id === undefined || id === null) {
      return null
    }
    const response = await api.get(`/school/campus/${id}/find`)
    return response.data
  }

  async getCampusByProgram (program) {
    if (program === undefined) {
      return null
    }
    const response = await api.get(`/${program}/sedes`)
    return response.data
  }

  async createCampus ({ data }) {
    const response = await api.post('/school/campus/create', data)
    return response.data
  }

  async updateCampus ({ data }) {
    console.log(
      '🚀 ~ file: CampusRespository.js ~ line 35 ~ CampusRespository ~ updateCampus ~ data',
      data
    )
    const response = await api.put('/school/campus/update', data)
    return response.data
  }

  async activateCampus ({ data }) {
    const response = await api.post('/school/campus/activate', data)
    return response.data
  }

  async deleteCampus ({ data }) {
    const response = await api.delete(`/school/campus/${data.id}/delete`)
    return response.data
  }

  async getPrograms (id) {
    if (id === undefined) {
      return
    }
    const response = await api.get(`/school/campus/${id}/programs`)
    return response.data
  }

  async getYears (id) {
    if (id === undefined || id === 0) {
      return
    }
    const response = await api.get(`/school/campus/${id}/years`)
    return response.data
  }

  async getBasePrices (id, program, year) {
    if (id === undefined || program === undefined || year === undefined) {
      return
    }
    const response = await api.get(
      `/school/campus/${id}/prices/${program}?year=` + year
    )
    return response.data
  }

  async getCompletedCampus (program) {
    if (program === undefined) {
      return
    }
    const response = await api.get(`/school/campus/${program}/completed`)
    return response.data
  }

  /**
   * Campus Lodgings
   */
  async saveLodging ({ data }) {
    const response = await api.post('/fees/lodging', data)
    return response.data
  }

  async saveTransport ({ data }) {
    const response = await api.post('/fees/transport', data)
    return response.data
  }

  async saveSlider ({ values }) {
    const response = await api.post('/slider/image', values)
    return response.data
  }

  async getSlider (queryString) {
    const response = await api.get(`/slider?${queryString}`)
    return response.data
  }
}

export default new CampusRespository()
