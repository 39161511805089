import React from 'react'
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { MainTheme } from '../../common/theme/MainTheme'
import { AccountForm } from './components/AccountForm'

const URL_USER_ACCOUNT = '/account'

function MyAccountPage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme>
        <AccountForm />
      </MainTheme>
    </PageProtected>
  )
}

export { MyAccountPage, URL_USER_ACCOUNT }
