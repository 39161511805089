import React from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
import { formatStringDate } from '../../../utils/formatStringDate'

function DiscountText () {
  const { service } = useQuoter()
  return (
    <div className='discount-text'>
      {
        service?.getTuitionFeeDiscounts().length > 0 && (
          service.getTuitionFeeDiscounts()?.map((d, index) => (
            <p key={d.id}>*Descuento del {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Cuota de inscripción, válido para inscripciones antes del {formatStringDate(d.end, 'LLL dd yyyy')} aplican restricciones.</p>
          ))
        )
      }
      {service?.course?.offer?.program?.name !== 'language' && service?.courseCosts()?.map((item) => (
        <React.Fragment key={item.id}>
          {service.courseCostDiscounts(item.id)?.map((d, index) => (
            <p key={d.id}>*Descuento del {getDiscountSymbol(d.value, d.type, service.getCurrency())} en {item.name}, válido para inscripciones antes del {formatStringDate(d.end, 'LLL dd yyyy')} aplican restricciones.</p>
          ))}
        </React.Fragment>
      ))}

      {service?.course?.offer?.program?.name === 'language' && service.getDiscountsInKindGoods()?.map(
        d => (
          <p key={d.id} className='byp-fw-bold'>
            *Descuento {d.name}, válido para inscripciones antes del {formatStringDate(d.end, 'LLL dd yyyy')}
          </p>
        )
      )}

      {
        service.courseHasDiscount() && service.getCourseDiscounts()?.map(d => (
          <p key={d.id}>*Descuento del {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Colegiatura, válido para inscripciones antes del {formatStringDate(d.end, 'LLL dd yyyy')} aplican restricciones.</p>
        ))
      }

      {
        service.getMaterialDiscounts().length > 0 && (
          service.getMaterialDiscounts()?.map((d, index) => (
            <p key={d.id}>*Descuento del {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Materiales, válido para inscripciones antes del {formatStringDate(d.end, 'LLL dd yyyy')} aplican restricciones.</p>
          ))
        )
      }

      {service.getPrices()?.base_prices?.map((item) => {
        return item.name !== 'Registration Fee'
          ? (
            <>
              {
                service.getFeeDiscounts(item.id).length > 0 && (
                  service.getFeeDiscounts(item.id)?.map((d) => (
                    <p key={d.id}>*Descuento del {getDiscountSymbol(d.value, d.type, service.getCurrency())} en {item.name}, válido para inscripciones antes del {formatStringDate(d.end, 'LLL dd yyyy')} aplican restricciones.</p>
                  ))
                )
              }
            </>
            )
          : null
      })}

      {
        service.getLodgingDiscounts().length > 0 && service.getHighSeasonAmountLodging() > 0 && (
          service.getLodgingDiscounts()?.map((d) => (
            <p key={d.id}>*Descuento del {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Alojamiento, válido para inscripciones antes del {formatStringDate(d.end, 'LLL dd yyyy')} aplican restricciones.</p>
          ))
        )
      }

      {
        service.includeTransport() &&
          <>
            {service.getTransportDiscounts().length > 0 && (
              service.getTransportDiscounts()?.map((d, index) => (
                <p key={d.id}>*Descuento del {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Transporte, válido para inscripciones antes del {formatStringDate(d.end, 'LLL dd yyyy')} aplican restricciones.</p>
              ))
            )}
          </>
      }

      {
        service.getInsuranceDiscounts().length > 0 && (
          service.getInsuranceDiscounts()?.map((d, index) => (
            <p key={d.id}>*Descuento del {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Seguros, válido para inscripciones antes del {formatStringDate(d.end, 'LLL dd yyyy')} aplican restricciones.</p>
          ))
        )
      }
    </div>
  )
}

export default DiscountText
