import { Col, Collapse, Row, Form } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { AgencyCostSections } from './AgencyCostSections'
import { CampusPricesSection } from './CampusPricesSection'
import FieldCourseCosts from './FieldCourseCosts'
import { FieldCourseGoods } from './FieldCourseGoods'
import { FieldPayTuition } from './FieldPayTuition'
import { FieldSchoolMaterials } from './FieldSchoolMaterials'
import { LodgingSection } from './LodgingSection'
import { OtherSection } from './OtherSection'
import { ProgramIntake } from './ProgramIntake'
import { TransportSection } from './TransportSection'
import { MedicalSection } from './MedicalSection'
import { ServiceSection } from './ServiceSection'
import moment from 'moment'
import { useEffect, useState } from 'react'
import DatesLodging from './DatesLodging'
import { useGetCurrencyToCurrency } from '../../../bussines/hooks/currencies/useGetCurrencyToCurrency'
import { SelectLodgingStartAndDuration } from './SelectLodgingStartAndDuration'
const { Panel } = Collapse

function QuoterHighEducationForm () {
  const {
    service, course, form, setFormConfig, formConfig,
    isLoadingBaseQuote, baseQuote, changeCurrency, userLocalCurrency, prices
  } = useQuoter()

  const [, setYear] = useState()

  const agencyServices = Form.useWatch('services_agency', form)

  useEffect(() => {
    if (!isLoadingBaseQuote && baseQuote?.id) {
      form.setFieldsValue({
        intake_date: baseQuote.intake,
        intake: baseQuote.intake,
        transport: baseQuote.transport,
        transport_way: baseQuote.transport_ways,
        health_insurance: baseQuote.insurance,
        lodging: baseQuote.lodging,
        lodgingExtraStart: moment(baseQuote.lodging_extra_start),
        lodgingExtraNights: baseQuote.lodging_properties?.extra_nights_duration,
        extra_nigth: baseQuote.extra_nigth,
        expiration: moment(baseQuote.expiration) ?? null,
        student_nationality: baseQuote.student_nationality,
        student_location: baseQuote.student_location,
        materials: baseQuote.materials,
        material: baseQuote.material,
        services_agency: baseQuote.additional_services.map((item) => ({ service_agency: item.id })),
        medical: baseQuote.agency_insurance.length > 0 ? baseQuote.agency_insurance[0].id : null,

        client_name: baseQuote.client_name,
        message: baseQuote.message
      })

      console.log('🚀 ~ file: QuoterHighEducationForm.js:29 ~ useEffect ~ baseQuote', baseQuote)

      const agencyServices = []
      if (service.isAgency()) {
        baseQuote.agency_services?.map((item) => (
          agencyServices.push({ id: item.id, cost: service.getAgencyServiceCost(item.id) })
        ))
      }

      const bases = course?.offer?.base_prices || []
      const extras = course?.offer?.extras || []
      const allFees = [...bases, ...extras]
      const includedFees = []
      allFees?.map(({ id, cost }) => (
        baseQuote.fees.includes(id) ? includedFees.push({ id, cost }) : null
      ))
      setFormConfig({
        ...formConfig,
        courseCosts: baseQuote.course_costs,
        intake_date: baseQuote.intake,
        materials: baseQuote.materials,
        material: baseQuote.material,
        courseStart: moment(baseQuote.arrival_date),
        weeks: baseQuote.weeks,
        lodging: baseQuote.include_lodging,
        lodgingId: baseQuote.lodging,
        lodgingStart: moment(baseQuote.lodging_start) ?? null,
        lodgingWeeks: baseQuote.lodging_properties?.weeks,
        lodgingExtraStart: moment(baseQuote.lodging_extra_start),
        lodgingExtraNights: baseQuote.lodging_properties?.extra_nights_duration,
        lodgingExtras: baseQuote.lodging_extras,
        extra_nigth: baseQuote.extra_nigth,
        transport: baseQuote.include_transport,
        transportId: baseQuote.transport,
        transportWaySelected: baseQuote.transport_ways,
        transportMinorCosts: baseQuote.transport_minor_costs,
        fees: includedFees,
        insurance: baseQuote.include_insurance,
        healthInsuranceId: baseQuote.insurance,
        agencyServices,
        serviceAgency: baseQuote.additional_services?.length > 0,
        medical: baseQuote.agency_insurance?.length > 0
        // medicalId:
      })
    }
  }, [isLoadingBaseQuote, baseQuote])

  useEffect(() => {
    setYear(service.getPricesYear()?.year)
  }, [service.getPricesYear()?.year])

  const { data: newdata, getCurrencyToCurrency } = useGetCurrencyToCurrency()
  useEffect(() => {
    const getCurrency = async () => {
      getCurrencyToCurrency(userLocalCurrency, service.getCurrency())
    }
    getCurrency()
  }, [changeCurrency])

  useEffect(() => {
    console.log('🚀 ~ file: QuoterHighEducationForm.js:136 ~ useEffect ~ newdata:', newdata)
  }, [newdata])

  const handleFormat = (date) => {
    if (date) {
      const fechaMoment = moment(date)
      fechaMoment.subtract(3, 'days')
      return fechaMoment.format('DD/MM/YYYY')
    }
    return ''
  }

  return (
    <>
      <Row align='middle' justify='space-between'>
        <hr className='byp-hr__quote byp-mb-1 byp-mt-1' />
      </Row>
      <Row gutter={8} align='middle' justify='space-between'>
        <Col xs={24} md={service.getCourseProgram()?.name === 'graduate' ? 12 : 24}>
          <div className={service.getCourseProgram()?.name === 'graduate' ? 'program-intake-high-education' : ''}>
            <ProgramIntake />
          </div>
        </Col>
        <Col xs={24} md={service.getCourseProgram()?.name === 'graduate' ? 12 : 24} style={{ display: 'flex', flexDirection: 'column' }}>
          {service.hasLodging() && (
            <DatesLodging />
          )}
          {(service.getCourseProgram()?.name !== 'academic_years') && (
            <SelectLodgingStartAndDuration program={service.getCourseProgram()?.name} />
          )}
        </Col>
        <hr className='byp-hr__quote byp-mb-1 byp-mt-1' />
      </Row>
      <Collapse bordered={false} defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8']} expandIconPosition='left'>
        <Panel
          header='Costo total'
          key='1'
          className='quote-byp__quote-panel'
          extra={
            <>
              <p className='byp-m-0' style={{ fontWeight: '700', marginRight: '0.5rem' }}>Promoción valida inscribiéndote antes del: {handleFormat(prices?.year?.prices_end)}</p>
            </>
          }
        >
          <Row gutter={12} className='w-100'>
            <Col xs={12} md={14} lg={14}><p className='byp-mt-1'>Concepto</p></Col>
            <Col xs={12} md={6} lg={6} style={{ textAlign: 'end' }}><p className='byp-mt-1'>Total {service.getCurrency()}</p></Col>
            <Col xs={0} md={4} lg={4} className='byp-centered'><p className='byp-m-0' style={{ display: 'none' }}>-</p></Col>
            <hr className='byp-hr' />
          </Row>
          <FieldPayTuition form={form} />
          <CampusPricesSection />
          <FieldCourseCosts />
          {service.getSchoolMaterial() && <FieldSchoolMaterials />}
          <FieldCourseGoods />
        </Panel>
        {service.getCourseProgram()?.name !== 'boarding_schools' && service.hasLodging() && !service.isOnline() && service.includeLodging() && (
          <Panel
            header='Hospedaje'
            key='2'
            className='quote-byp__quote-panel'
            extra={
              <>
                <p className='byp-m-0' style={{ fontWeight: '700', marginRight: '0.5rem' }}>Promoción valida inscribiéndote antes del: {handleFormat(prices?.year?.prices_end)}</p>
              </>
            }
          >
            <Row gutter={12} className='w-100'>
              <Col xs={12} md={14} lg={14}><p className='byp-mt-1'>Concepto</p></Col>
              <Col xs={12} md={6} lg={6} style={{ textAlign: 'end' }}><p className='byp-mt-1'>Total {service.getCurrency()}</p></Col>
              <Col xs={0} md={4} lg={4} className='byp-centered'><p className='byp-m-0' style={{ display: 'none' }}>-</p></Col>
              <hr className='byp-hr' />
            </Row>
            <LodgingSection />
          </Panel>
        )}
        {service.getCourseProgram()?.name !== 'boarding_schools' && service.hasTransports() && !service.isOnline() && (
          <Panel
            header='Transporte'
            key='3'
            className='quote-byp__quote-panel'
            extra={
              <>
                <p className='byp-m-0' style={{ fontWeight: '700', marginRight: '0.5rem' }}>Promoción valida inscribiéndote antes del: {handleFormat(prices?.year?.prices_end)}</p>
              </>
            }
          >
            <Row gutter={12} className='w-100'>
              <Col xs={12} md={14} lg={14}><p className='byp-mt-1'>Concepto</p></Col>
              <Col xs={12} md={6} lg={6} style={{ textAlign: 'end' }}><p className='byp-mt-1'>Total {service.getCurrency()}</p></Col>
              <Col xs={0} md={4} lg={4} className='byp-centered'><p className='byp-m-0' style={{ display: 'none' }}>-</p></Col>
              <hr className='byp-hr' />
            </Row>
            {service.getCourseProgram()?.name !== 'boarding_schools' && (
              <>
                {
                  service.hasTransports() && !service.isOnline() &&
                    <TransportSection />
                }
              </>
            )}
          </Panel>
        )}
        {service.showAdditionalsPanel() && (
          <Panel
            header='Adicionales'
            key='4'
            className='quote-byp__quote-panel'
            extra={
              <>
                <p className='byp-m-0' style={{ fontWeight: '700', marginRight: '0.5rem' }}>Promoción valida inscribiéndote antes del: {handleFormat(prices?.year?.prices_end)}</p>
              </>
            }
          >
            <Row gutter={12} className='w-100'>
              <Col xs={12} md={14} lg={14}><p className='byp-mt-1'>Concepto</p></Col>
              <Col xs={12} md={6} lg={6} style={{ textAlign: 'end' }}><p className='byp-mt-1'>Total {service.getCurrency()}</p></Col>
              <Col xs={0} md={4} lg={4} className='byp-centered'><p className='byp-m-0' style={{ display: 'none' }}>-</p></Col>
              <hr className='byp-hr' />
            </Row>
            {(service.getArrayHealtInsurance()?.length > 0 || course?.offer?.extras?.length > 0) && (
              <OtherSection />
            )}
            {
            service.isAgency() &&
              <AgencyCostSections />
            }
            {
            (service.getServiceAgency()?.length > 0 && service.isAgency()) &&
              <ServiceSection services_agency={agencyServices} />
            }
            {
            (service.getMedicalInsurance()?.length > 0 && service.isAgency()) &&
              <MedicalSection />
            }
          </Panel>
        )}
      </Collapse>
    </>
  )
}

export { QuoterHighEducationForm }
