import React, { useEffect } from 'react'
import { useAuth } from './auth-context-provider'
import { removeFromStorage, retrieveFromStorage } from '../../utils/localStorageUtils'
import { useNavigate } from 'react-router-dom'
import { BYP_PUBLIC_USER } from '../../config'

const UserContext = React.createContext()

function UserProvider (props) {
  const { user = null } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const onSuccesLoginRedirect = retrieveFromStorage('redirectOnLoginSucces')
    if (onSuccesLoginRedirect && user?.email !== BYP_PUBLIC_USER) {
      removeFromStorage('redirectOnLoginSucces')
      return navigate(onSuccesLoginRedirect)
    }
  }, [])

  return <UserContext.Provider value={user} {...props} />
}

function useUser () {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUser }
