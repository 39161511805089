import React from "react";
import PropTypes from "prop-types";
import { Popover, Statistic } from "antd";
// import { GoInfo } from 'react-icons/go'
import { IconInfo } from "../../../common/assets/svg/icons";
import { getDiscountSymbol } from "../../../utils/getDiscountSymbol";
import { calculateHECost } from "../../quote-course/utils/calculateCost";

function InfoCostPopUp({
  cost,
  costDetails,
  discounts,
  currency,
  tuition,
  children,
}) {
  const content = (
    <div className="info-cost">
      <p>El precio en divisa local es como referencia</p>

      {/* {tuition?.discounts?.length > 0 && (
        <>
          <p className='info-cost__discount'>Descuentos aplicados a la inscripción</p>
          {tuition?.discounts?.map((discount) => (
            <div key={discount.id} className='info-cost__discount'>
              {getDiscountSymbol(Math.floor(discount.pivot?.value), discount.pivot?.type, currency)} {discount.name}
            </div>
          ))}
          <p>
            <b>
              * Este descuento es como referencia y varía de acuerdo a la fecha de inicio de tu viaje
            </b>
          </p>
        </>
      )} */}

      {/* Language Dsicounts logic */}
      {discounts?.length > 0 && (
        <>
          <p className="info-cost__discount">Descuentos aplicados al curso</p>
          {discounts?.map((discount) => (
            <div
              key={discount.id}
              className="info-cost__discount w-100 justify-between d-flex"
            >
              {getDiscountSymbol(
                Math.floor(discount.pivot?.value),
                discount.pivot?.type,
                currency
              )}{" "}
              {discount.name}
              {costDetails?.courseAmount > 0 &&
                discount.pivot?.type === "percentage" && (
                  <Statistic
                    value={(
                      (Number(discount.pivot?.value) / 100) *
                      costDetails.courseAmount
                    )?.toFixed(2)}
                    prefix={"- " + currency}
                  />
                )}
            </div>
          ))}
          {/* <p>
            Este descuento es como referencia y varía de acuerdo a la fecha de inicio de tu viaje
          </p> */}
        </>
      )}

      {/* HE Discounts Logic */}
      {costDetails?.courseCosts?.map?.((courseCost) => (
        <React.Fragment key={"corse_cost_" + courseCost.id}>
          {courseCost?.discounts?.map((discount) => (
            <div
              key={discount.id}
              className="info-cost__discount w-100 justify-between d-flex"
            >
              {getDiscountSymbol(
                Math.floor(discount.pivot?.value),
                discount.pivot?.type,
                currency
              )}{" "}
              in {courseCost.concept} {/* discount.name */}
              {discount.pivot?.type === "percentage" && (
                <Statistic
                  value={(
                    (Number(discount.pivot?.value) / 100) *
                    calculateHECost(
                      courseCost.cost,
                      courseCost.frequency,
                      courseCost.courseLength,
                      courseCost.courseLengthType
                    )
                  )?.toFixed(2)}
                  prefix={"- " + currency}
                />
              )}
            </div>
          ))}
        </React.Fragment>
      ))}

      {(tuition?.discounts?.length > 0 || discounts?.length > 0) && (
        <p className="info-cost__discount">
          Este descuento es como referencia y varía de acuerdo la fecha de
          inicio de tu programa.
        </p>
      )}

      <div className="w-100 d-flex justify-between">
        <span>
          <b>Total</b>
        </span>{" "}
        <span>
          <b>
            {currency} {cost}
          </b>
        </span>
      </div>
    </div>
  );

  return (
    <Popover content={content} trigger="hover" placement="bottom">
        <p className="price">{children} <IconInfo className="icon-info" /></p>
    </Popover>
  );
}

InfoCostPopUp.propTypes = {
  discounts: PropTypes.array,
};

export default InfoCostPopUp;
