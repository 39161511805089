import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import { MyTravelsList } from './components/MyTravelsList'

const URL_QUOTES_PAGE = '/quotes'

const QuotesPage = () => {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
    >
      <MainTheme
        className='site-layout__padding'
      >
        <div className='byp-align-centered' style={{ padding: '1% 2%' }}>
          <MyTravelsList />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { QuotesPage, URL_QUOTES_PAGE }
