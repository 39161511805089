import { Button, Form, Input, Modal, notification } from 'antd'
import { useEffect, useState } from 'react'
import { usePostPublishFee } from '../../bussines/hooks/fee/usePostPublishFee'

const ModalPublishInsurance = ({ isModalOpen, setIsModalOpen, id, year, onSuccess }) => {
  const [isThereError, setError] = useState(false)
  const [, isFinished] = useState(false)
  const [form] = Form.useForm()
  const {
    publishFee,
    isLoading: isPublishing,
    currentState, state,
    error
  } = usePostPublishFee()

  useEffect(() => {
    if (!isPublishing && currentState === state.STATE_SUCCESS) {
      onSuccess()
      setIsModalOpen(false)
    } else if (!isPublishing && currentState === state.STATE_ERROR) {
      notification.error({
        message: error.response?.data?.message
      })
    }
  }, [isPublishing, currentState])

  const Error = () => {
    setError(true)
  }

  const NoError = () => {
    setError(false)
  }

  function VerifyMistake () {
    if (form.getFieldValue('username')) {
      if (form.getFieldValue('username').trim().length === 0) {
        Error()
      } else {
        isFinished(true)
        publishFee(id, { year, user: form.getFieldValue('username') })
        NoError()
      }
    } else {
      Error()
    }
  }

  function ShowMessage () {
    if (isThereError) {
      return (
        <div style={{ color: 'red' }}>
          *Insert a name
        </div>
      )
    }
  }

  return (
    <Form
      // onFinish={finish}
      form={form}
      className='w-100'
    >
      <Modal open={isModalOpen} visible={isModalOpen} onCancel={() => setIsModalOpen(false)} closable={false} footer='' width='400px'>
        <Form.Item name='username' label='Insert your name'>
          <Input />
        </Form.Item>
        <ShowMessage />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '10px' }}>
          <Button onClick={() => setIsModalOpen(false)} style={{ fontWeight: 'bold' }}>
            Cancel
          </Button>
          <Button
            onClick={VerifyMistake}
            style={{ background: 'var(--blue-100)', color: 'white', fontWeight: 'bold' }}
            loading={isPublishing}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </Form>
  )
}

export { ModalPublishInsurance }
