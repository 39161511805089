import { useMutation } from 'react-query'
import state from '../../utils/state-control'
import { useState } from 'react'
import OpinionRepository from '../../repositories/OpinionRepository'

function useSaveOpinion () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(OpinionRepository.saveOpinion, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param plan Plan id
   * @param company Company id
   * @param type Company type
   */
  const saveOpinion = (data) => {
    mutation.mutate(data)
  }

  return { ...mutation, state, currentState, saveOpinion }
}

export { useSaveOpinion }
