import { useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Unauthorized } from "../errors/Unauthorized";
import { userRoles } from "../../bussines/settings/validate-user-access";
import { PageProtected } from "../../common/components/PageProtected";
import { MainTheme } from "../../common/theme/MainTheme";
import { PackageCourseProvider } from "../../providers/package/package-course-context";
import { Col, Row } from "antd";
// import { URL_QUOTER_PAGE } from '../quoter'
import FormContent from "./FormContent";
// import { QuoteFormDetails } from './components/QuoteFormDetails'
import { PackageFormConfigured } from "./components/PackageFormConfigured";
import { HeaderQuote } from "./components/HeaderQuote";
import { InfoQuote } from "./components/InfoQuote";
import { FooterQuote } from "./components/FooterQuote";

const URL_PACKAGE_COURSE_PAGE = "/package-course/:courseId/:campusId";
const URL_SHORT_COURSE_PAGE = "/short-term-course/:courseId/:campusId";
const PackageCoursePage = () => {
  // const navigate = useNavigate()
  const [params] = useSearchParams();
  const baseQuoteId = params.get("quote") ?? undefined;
  const { courseId, campusId, yearId } = useParams();
  const location = useLocation();
  const { quoteId } = location.state ?? { quoteId: baseQuoteId };
  const [hasPermision, setHasPermision] = useState(true);
  const [image, setImage] = useState();
  const [link, setLink] = useState();

  if (!hasPermision) {
    return <Unauthorized />;
  }
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme>
        <PackageCourseProvider
          courseId={courseId}
          campusId={campusId}
          yearId={yearId}
          quoteId={quoteId}
        >
          <PackageFormConfigured>
            {/* the component InfoQuote is using the useQuoter provider, thah breack in package cause the provider name was changet to usePackage  */}
            <Row gutter={[0, 16]}>
              <Col xs={24}>
                <Row gutter={[0, { xs: 16, md: 45 }]}>
                  <Col xs={24}>
                    <HeaderQuote />
                  </Col>
                  <Col xs={24}>
                    <InfoQuote />
                  </Col>
                </Row>
              </Col>

              <Col xs={24} lg={20} id="qoute-course">
                <FormContent
                  setHasPermision={setHasPermision}
                  setImage={setImage}
                  setLink={setLink}
                />
              </Col>
              <Col xs={24} lg={4} className="byp-mb-1">
                {/* <QuoteFormDetails /> */}
                {image && (
                  <a href={link} target="_blank" rel="noreferrer">
                    <div className="quoter-course__publicity">
                      <img src={image} alt="" />
                    </div>
                  </a>
                )}
              </Col>
            </Row>
            <FooterQuote />
          </PackageFormConfigured>
        </PackageCourseProvider>
      </MainTheme>
    </PageProtected>
  );
};
export { PackageCoursePage, URL_PACKAGE_COURSE_PAGE, URL_SHORT_COURSE_PAGE };
