import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { PasswordFormItem } from '../../../common/components/helper-form/PasswordFormItem'
const { Title } = Typography
const SecurityAccountFields = ({ isSaving }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title level={4}>Contraseña</Title>
      </Col>
      <Col span={24}>
        <Form.Item label='Contraseña Actual' name='old_password' className='transparent'>
          <Input.Password className='transparent aplication__input' size='large' placeholder='Contraseña Actual' />
        </Form.Item>
      </Col>
      <Col span={24}>
        <PasswordFormItem
          className='transparent aplication__input'
          name='new_password'
          label='Cambiar contraseña'
          confirmName='new_password_confirmation'
          isRequired={false}
        />
        <Button htmlType='submit' className='byp-btn-gray-200-outline byp-mb-1 br-06' loading={isSaving}>
          Guardar
        </Button>
      </Col>
    </Row>
  )
}

export { SecurityAccountFields }
