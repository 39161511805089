import { Col, Row } from 'antd'
import { PageProtected } from '../../../common/components/PageProtected'
import { MainTheme } from '../../../common/theme/MainTheme'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { useLocation, useParams } from 'react-router-dom'
import BlogPageInfo from './BlogPageInfo'
import { Helmet } from 'react-helmet'
import { URL_WEBSITE } from '../../../config'
import { logoEdit } from '../../../utils/fallbackImage'

const URL_BLOG_PAGE = '/blogs/:slug'
const URL_BLOG_PREVIEW_PAGE = '/blogs/:slug/preview'

function PageBlog () {
  const { slug } = useParams()
  const location = useLocation()
  const containsPreview = location.pathname.includes('preview')

  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
    >
      <MainTheme
        className='byp-m-0 byp-p-0'
      >
        <div>
          <Row style={{ width: '100%' }}>
            <Helmet>
              <meta name='title' content={slug} />
              <meta name='description' content='La plataforma que une a la industria de la educación, a través de una plataforma amigable, fácil de usar, intuitiva e integrable con los principales CRM y sitios web del mercado.' />
              {/* <!-- Open Graph / Facebook --> */}
              <meta property='og:type' content='website' />
              <meta property='og:url' content={URL_WEBSITE + location.pathname} />
              <meta property='og:title' content={slug} />
              <meta property='og:description' content='La plataforma que une a la industria de la educación, a través de una plataforma amigable, fácil de usar, intuitiva e integrable con los principales CRM y sitios web del mercado.' />
              <meta property='og:image' content={logoEdit} />

              {/* <!-- Twitter --> */}
              <meta property='twitter:card' content='summary_large_image' />
              <meta property='twitter:url' content={URL_WEBSITE} />
              <meta property='twitter:title' content='Book your program' />
              <meta property='twitter:description' content='La plataforma que une a la industria de la educación, a través de una plataforma amigable, fácil de usar, intuitiva e integrable con los principales CRM y sitios web del mercado.' />
              <meta property='twitter:image' content={logoEdit} />
            </Helmet>
            <Col span={24}>
              <BlogPageInfo blog={slug} isPreview={containsPreview} />
            </Col>
          </Row>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { PageBlog, URL_BLOG_PAGE, URL_BLOG_PREVIEW_PAGE }
