import { Col, Row } from 'antd'
import { PageProtected } from '../../../common/components/PageProtected'
import { MainTheme } from '../../../common/theme/MainTheme'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { FormEditBlog } from './components/FormEditBlog'
import { useParams } from 'react-router-dom'

const URL_EDIT_BLOG_PAGE = '/blogs/:slug/edit'

function PageEditBlog () {
  const { slug } = useParams()
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_AGENCY
      ]}
    >
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content byp-align-centered'>
          <Row style={{ width: '100%' }}>
            <Col span={24}>
              <FormEditBlog blog={slug} />
            </Col>
          </Row>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { PageEditBlog, URL_EDIT_BLOG_PAGE }
