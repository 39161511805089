import { Col, DatePicker, Form, Row } from 'antd'
import moment from 'moment'
import { useState } from 'react'

const SelectStartAndEndDatesFormItem = ({ handleChangeDate, startName, endName, format = 'MMMM DD, YYYY' }) => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const disabledStartDates = (current) => {
    let disabled = current < moment().endOf('day')
    if (endDate) {
      disabled = disabled || current > endDate
    }
    return disabled
  }

  const disabledEndDates = (current) => {
    let disabled = current < moment().endOf('day')
    if (startDate) {
      disabled = current < startDate
    }
    return disabled
  }

  return (
    <Row gutter={12} className='w-100'>
      <Col xs={12} md={12} className='antd-date'>
        <p style={{ marginBottom: '0' }}>Start</p>
        <Form.Item
          noStyle name={startName} rules={[{
            required: true,
            message: 'Start date is required'
          }]}
        >
          <DatePicker
            // locale={locale}
            disabledDate={disabledStartDates}
            format={format}
            onChange={(val) => {
              setStartDate(val)
              handleChangeDate(val, startName)
            }}
            className='quote__border'
          />
        </Form.Item>
      </Col>
      <Col xs={12} md={12} className='antd-date'>
        <p style={{ marginBottom: '0' }}>End</p>
        <Form.Item
          noStyle name={endName} rules={[{
            required: true,
            message: 'End date is required'
          }]}
        >
          <DatePicker
            // locale={locale}
            disabledDate={disabledEndDates}
            format={format}
            onChange={(val) => {
              setEndDate(val)
              handleChangeDate(val, endName)
            }}
            className='quote__border'
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

export { SelectStartAndEndDatesFormItem }
