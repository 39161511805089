import { Col, Row } from "antd";
import { is } from "date-fns/locale";
import { useEffect, useState } from "react";

function RowQuoter({ colA, colB, colC = "-", hr = true }) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isPhone, setIsPhone] = useState(false);

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (windowSize.width <= 800) {
      setIsPhone(true);
    } else {
      setIsPhone(false);
    }
  }, [windowSize]);
  return (
    <Row gutter={12} className="w-100" style={{ position: "relative" }}>
      {/* <Col xs={21} gutter={0}>
        <Row>
        <Col style={{ backgroundColor:"blue",display: "flex", justifyContent: "start"}} xs={20} md={8} lg={18} className='quotation__table byp-border-left byp-border-right'>
        {colA}
      </Col>

      <Col style={{ backgroundColor: "red", display: "flex", justifyContent: "end"}} xs={4} md={8} lg={2} className='quotation__table byp-border-left byp-border-right'>
        <span>{colB}</span>
      </Col>
        </Row>
      </Col> */}

      {/* ! =============== MOBILE FIRST START =============== */}
      {isPhone && (
        <Col
          xs={20}
          lg={20}
          style={{ display: "flex", justifyContent: "space-between" }}
          className="quotation__table byp-border-right"
        >
          {colA}

          <span style={{ position: "absolute", right: 10 }}>{colB}</span>
        </Col>
      )}

      {/* Palanca */}
      {isPhone && (
        <Col
          style={{
            display: "flex",
            justifyContent: "end",
          }}
          xs={4}
          md={8}
          lg={4}
          className="quotation__table byp-border-left byp-border-right"
        >
          {colC !== "-" && <span>{colC}</span>}
        </Col>
      )}
      {/* ! =============== MOBILE FIRST END =============== */}

      {/* ! =============== DESKTOP FIRST START =============== */}

      {!isPhone && (
        <Col
          style={{ display: "flex", justifyContent: "start" }}
          xs={18}
          md={8}
          lg={18}
          className="quotation__table byp-border-left byp-border-right"
        >
          {colA}
        </Col>
      )}

      {!isPhone && (
        <Col
          style={{ display: "flex", justifyContent: "end" }}
          xs={3}
          md={8}
          lg={2}
          className="quotation__table byp-border-left byp-border-right"
        >
          <span>{colB}</span>
        </Col>
      )}

      {/* Palanca */}
      {!isPhone && (
        <Col
          style={{
            display: "flex",
            justifyContent: "end",
          }}
          xs={4}
          md={8}
          lg={4}
          className="quotation__table byp-border-left byp-border-right"
        >
          {colC !== "-" && <span>{colC}</span>}
        </Col>
      )}


      {/* ! =============== DESKTOP FIRST END =============== */}

      {hr && !isPhone && <hr style={{ margin: 0 }} className="byp-hr" />}
    </Row>
  );
}

export { RowQuoter };
