import { useQuery } from 'react-query'
import LodgingRepository from '../../../repositories/LodgingRepository'

function useFindLodging (id) {
  return useQuery(['useFindLodging', id],
    () => LodgingRepository.findLodging(id), {
      retry: 2,
      disabled: !!id
    })
}

export { useFindLodging }
