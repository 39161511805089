import React, { useContext } from 'react'
import { QuoteCalculatorService } from '../../services/QuoteCalculatorService'
import { useFindOffer } from '../../bussines/hooks/quoter/useFindOffer'
import { Form, notification } from 'antd'
import { formatQuoterForm } from '../../pages/quote-course/utils/formatQuoterForm'
import { usePostQuote } from '../../bussines/hooks/quoter/usePostQuote'
import { useAuth } from '../auth/auth-context-provider'
import { useGetBaseQuote } from '../../bussines/hooks/quote/useGetBaseQuote'
import { useGetMyExchangeValues } from '../../bussines/hooks/currencies/useGetMyExcahngeValues'
import { HighEducationQuoterService } from '../../services/HighEducationQuoterService'
import LottieCargando from '../../lotties/LottieCargando'
import { UrlContext } from '../../pages/quote-course/context/UrlContext'
import { shortenUrl } from '../../utils/urlUtils'

const SearchContext = React.createContext()

function QuoteCourseProvider (props) {
  const [form] = Form.useForm()
  const {
    user,
    userLocalCurrency,
    setUserLocalCurrency,
    userCountryLocation,
    isPublicUser
  } = useAuth()

  const { setShortUrl } = useContext(UrlContext)
  const [formConfig, setFormConfig] = React.useState({})
  const [prices, setPrices] = React.useState()
  const [favorite, setFavorite] = React.useState(false)
  const [faileForm, setFaileForm] = React.useState(false)
  const [opinionsData, setOpinionsData] = React.useState()
  const [changeCurrency, setChangeCurrency] = React.useState(false)
  const [localIsLoadingPost, setIsLoadingPost] = React.useState(false)

  const { isLoading, data, refetch, error: offerError, isError: isOfferError } = useFindOffer(props?.courseId, props?.campusId)
  const { isLoading: isLoadingBaseQuote, data: baseQuote } = useGetBaseQuote(props?.quoteId)
  const { isLoading: isLoadingPost, isSuccess: isSuccessPost, create, isError: isErrorPost, data: dataQuote, error: errorPost } = usePostQuote()
  const { data: exchangeValues, isLoading: loadingExchangeValues } = useGetMyExchangeValues()

  if (!isLoading && offerError && isOfferError) {
    if (offerError?.response?.status === 401) {
      const exception = new Error()
      exception.name = 'CustomError'

      exception.response = {
        status: offerError?.response?.status,
        data: {
          detail: 'HTTP Error 401 unauthorized'
        }
      }

      throw exception
    }
    return (
      <div className='w-100 byp-centered' style={{ height: '60vh' }}>
        {offerError?.response?.data?.message}
      </div>
    )
  }

  let service
  if (data?.offer?.program?.name === 'language' || data?.offer?.program?.name === 'summer_camps') {
    service = new QuoteCalculatorService(data, formConfig, setFormConfig, user, exchangeValues?.exchanges, prices, setPrices)
  } else {
    service = new HighEducationQuoterService(data, formConfig, setFormConfig, user, exchangeValues?.exchanges, prices, setPrices)
  }

  const submit = () => {
    try {
      form.submit()
    } catch (error) {
      console.error('error: ', error)
    }
  }

  const handleShare = async () => {
    const newCurrency = userLocalCurrency
    const values = form.getFieldsValue()
    values.minorCosts = formConfig?.minorCosts
    values.fees = formConfig?.fees
    values.courseHSFees = formConfig?.courseHSFees
    values.lodgingExtras = formConfig?.lodgingExtras
    values.transportMinorCosts = formConfig?.transportMinorCosts
    values.courseCosts = formConfig?.courseCosts
    values.appliedGoods = formConfig?.appliedGoods
    values.lodgingExtras = formConfig?.lodgingExtras
    values.currency = newCurrency

    // Convertir los valores en una cadena de consulta
    const stringValue = JSON.stringify(values)
    const formatedLink = encodeURIComponent(stringValue)
    const fullUrl = `${window.location.href.split('?')[0]}?values=${formatedLink}`

    try {
      // Llamar a la función para acortar la URL
      const shortUrl = await shortenUrl(fullUrl)
      setShortUrl(shortUrl)
    } catch (error) {
      console.error('Error al acortar la URL:', error)
    }
  }

  const copyToClipboard = (shortUrl) => {
    navigator.clipboard.writeText(shortUrl).then(() => {
      notification.info({
        message: 'Enlace copiado al portapapeles',
        placement: 'top'
      })
    }).catch((error) => {
      console.error('Error al copiar al portapapeles:', error)
    })
  }

  const getLinkToShare = () => {
    const newCurrency = userLocalCurrency
    let urlActual = window.location.href
    urlActual = urlActual.split('?')[0]
    const values = form.getFieldsValue()
    values.minorCosts = formConfig?.minorCosts
    values.fees = formConfig?.fees
    values.courseHSFees = formConfig?.courseHSFees
    values.lodgingExtras = formConfig?.lodgingExtras
    values.transportMinorCosts = formConfig?.transportMinorCosts
    values.courseCosts = formConfig?.courseCosts
    values.appliedGoods = formConfig?.appliedGoods
    values.lodgingExtras = formConfig?.lodgingExtras
    values.currency = newCurrency
    // console.log('minorCosts', values)
    const stringValue = JSON.stringify(values)
    return urlActual + '?values=' + stringValue
  }

  const handleFinishForm = (values) => {
    if (!service.userCanApply()) {
      return notification.info({
        message: 'This quote canot be applied due to the course start date being less than 1 or 2 weeks from now'
      })
    }
    values.campus = props?.campusId
    values.year = props?.yearId
    values.favorite = favorite

    const format = formatQuoterForm(data?.offer?.id, values, service, data.offer?.program?.name)
    console.log('🚀 ~ file: quoter-course-context.js ~ line 39 ~ handleFinishForm ~ format', format)
    create(format)
  }

  if (isLoading) {
    return <LottieCargando style={{ minHeight: '75vh' }} />
  }

  return (
    <SearchContext.Provider
      value={{
        isLoadingOffer: isLoading,
        offerError,
        isOfferError,
        refetch,
        isErrorPost,
        errorPost,
        handleFinishForm,
        isLoadingPost,
        setIsLoadingPost,
        localIsLoadingPost, // no funciona
        isSuccessPost,
        submit,
        form,
        course: data,
        prices,
        quote: dataQuote,
        service,
        formConfig,
        setFormConfig,
        setFavorite,
        favorite,
        setFaileForm,
        faileForm,
        isLoadingBaseQuote,
        baseQuote,
        exchangeValues,
        loadingExchangeValues,
        handleShare,
        setShortUrl,
        changeCurrency,
        setChangeCurrency,
        opinionsData,
        setOpinionsData,
        userLocalCurrency,
        setUserLocalCurrency,
        userCountryLocation,
        isPublicUser,
        getLinkToShare,
        copyToClipboard
      }}
      {...props}
    />
  )
}

function useQuoter () {
  const context = React.useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useQuoter must be used within a QuoteCourseProvider')
  }
  return context
}

export { QuoteCourseProvider, useQuoter }
