import { useMutation } from 'react-query'
import state from './../../utils/state-control'
import { useState } from 'react'
import AgencyRepository from '../../repositories/AgencyRepository'

function usePostPublicity () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(AgencyRepository.postPublicity, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const savePublicity = (values) => {
    mutation.mutate({ values })
  }

  return { ...mutation, state, currentState, savePublicity }
}

export { usePostPublicity }
