import { Col, Form, Row, Statistic, Switch, Typography } from 'antd'
// import { format } from 'date-fns'
import { discountsApplied } from '../../../common/components/StatisticWithDiscounts'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { getDiscountSymbol, handlePrice } from '../../../utils/getDiscountSymbol'
// import { DeletedIcon } from '../../quote-preview/assets/imgs/Icons'
import { RowQuoter } from './RowQuoter'
// import { formatStringDate } from '../../../utils/formatStringDate'
const { Text } = Typography

// function ButtonSwitch ({ isActive, onChange, item }) {
//   const [active, setActive] = useState(isActive)
//   const handleChange = (value) => {
//     onChange(value, item.id, item.cost ?? null, item.frequency, item.name)
//     setActive(value)
//   }
//   return (
//     <>
//       {!active
//         ? (
//           <Button className='cotizacion_btn-add' onClick={() => handleChange(true)}><span className='icon-mas'>+</span> Añadir</Button>
//           )
//         : (
//           <Button className='cotizacion_btn-deleted' onClick={() => handleChange(false)}><DeletedIcon /> Eliminar</Button>
//           )}
//     </>
//   )
// }

function ItemCost ({ item }) {
  const { service, formConfig, setFormConfig } = useQuoter()

  const handleChange = (value, id, cost, frequency, name) => {
    let courseCosts = []
    if (!formConfig.courseCosts) {
      setFormConfig({
        ...formConfig,
        courseCosts: []
      })
    }
    if (value) {
      courseCosts = formConfig.courseCosts ?? []
      courseCosts?.push({ id, cost, frequency, name })
    } else {
      formConfig.courseCosts?.map((service) => (
        service.id !== id ? courseCosts.push(service) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      courseCosts
    })
  }

  return (
    <>
      <Row key={item.id} gutter={12} className='w-100'>
        <Col xs={8} md={14} lg={18}>
          <Text>{item.translations?.find(t => t.to === 'es')?.translation ?? item.name}</Text>
          {/* <br />
          {service.courseCostDiscounts(item.id)?.map((d) => (
            <Text key={'disc_course_cost_' + d.id}>
              <b>
                {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {format(new Date(d.end), 'LLL dd yyyy')}
              </b>
            </Text>
          ))} */}
        </Col>
        <Col xs={8} md={6} lg={4} className='byp-border-left' style={{ textAlign: 'end' }}>
          {/* <StatisticWithDiscounts
            discounts={service.courseCostDiscounts(item.id)}
            amount={service.courseCost(item.id)?.toFixed(2)}
            currency={service.getCurrency()}
            subtotal={service.courseCostSubtotal(item.id)}
            active={service.isCostChecked(item.id)}
          /> */}
          <Statistic
            prefix='$'
            precision={2}
            value={`${service.courseCost(item.id)?.toFixed(2)}`}
            // suffix={`${service.getCurrency()}`}
          />
        </Col>
        <Col xs={8} md={4} lg={2} className='byp-border-left byp-border-right' style={{ textAlign: 'end', padding: '5px' }}>
          {!item?.mandatory && (
            <Form.Item noStyle name={['costs', item.name]}>
              <Switch
                onChange={(value) => { handleChange(value, item.id, item.cost ?? null, item.frequency, item.name) }}
                className='byp-ml-1'
                checked={service.isCostChecked(item.id)}
              />
            </Form.Item>
          )}
        </Col>
        {/* <Col xs={24} md={4} lg={4} className='byp-border-left' style={{ textAlign: 'end' }}>
          <ButtonSwitch onChange={handleChange} isActive={service.isCostChecked(item.id)} item={item} />
        </Col> */}
        <hr className='byp-hr' />
      </Row>
      {service.courseCostDiscounts(item.id)?.map((d, index) => (
        <RowQuoter
          key={index}
          colA={
            <Text key={'disc_course_cost_' + d.id} className='text-discount'>
              <b>
                {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en costo de curso.
              </b>
            </Text>
          }
          colB={
            <Statistic
              prefix='$'
              className='price-after text-discount'
              precision={2}
              value={`${d.type === 'amount' ? '-' + d.value : '-' + handlePrice(d.value, service.courseCost(item.id)?.toFixed(2))}`}
              // suffix={`${service.getCurrency()}`}
            />
          }
        />
      ))}
    </>
  )
}

const FieldCourseCosts = () => {
  const { service } = useQuoter()
  return (
    <>
      {service.courseCosts()?.map((costItem) => (
        <ItemCost key={costItem.id} item={costItem} />
      ))}
    </>
  )
}

export default FieldCourseCosts
