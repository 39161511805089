import { Col, Form, Row, Select, Statistic, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { FieldHighSeason } from './FieldHighSeason'
// import { format } from 'date-fns'
import { TransportMinorCosts } from './TransportMinorCosts'
import { discountsApplied } from '../../../common/components/StatisticWithDiscounts'
import { getDiscountSymbol, handlePrice } from '../../../utils/getDiscountSymbol'
// import { formatStringDate } from '../../../utils/formatStringDate'
// import { DeletedIcon } from '../../quote-preview/assets/imgs/Icons'
import { waysTransport } from '../../../languageConfiguration/infoCourseTranslate'

const { Option } = Select

function TransportSection() {
  const [selected, setSelected] = useState()
  const [waySelected, setWaySelected] = useState()
  const { service, formConfig, setFormConfig } = useQuoter()
  // const [active, setActive] = useState(service.includeTransport())

  const handleChangeSwitch = (included) => {
    setFormConfig({
      ...formConfig,
      transport: included
    })
    // setActive(included)
  }

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      transportId: selected
    })
  }, [selected])

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      transportWaySelected: waySelected
    })
  }, [waySelected])

  return (
    <div>
      <RowQuoter
        colA={
          <Row gutter={12}>
            <Col span={24}>
              <p className='w-100'>
                ¿Deseas contratar servicio de Recepción de aeropuerto?
              </p>
            </Col>
          </Row>
        }
        colB={
          formConfig.transportWaySelected && (
            <Statistic
              prefix='$'
              precision={2}
              value={`${service.getAmountTransport()}`}
              // suffix={`${service.getCurrency()}`}
            />
          )
        }
        colC={
          <Switch
            onChange={handleChangeSwitch} checked={service.includeTransport()}
          />
        }
      />
      {
        service.includeTransport() && (
          <>
            {/* Bloque colA combinado */}
            <RowQuoter
              colA={
                <>
                  <Form.Item
                    label='Transporte'
                    name='transport'
                    rules={[{
                      required: true,
                      message: 'Field required'
                    }]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      placeholder='Sin seleccionar'
                      onChange={setSelected}
                      size='small'
                      className='quote__border'
                      dropdownMatchSelectWidth={false}

                    >
                      {service.getArrayTransport().map((itm) => {
                        return <Option key={itm.id} value={itm.id}>{itm.translations?.find(t => t.to === 'es')?.translation ?? itm.name}</Option>
                      })}
                    </Select>
                  </Form.Item>

                </>
              }
            />
          </>
        )
      }
      {
        service.includeTransport() && (
          <RowQuoter
            colA={
              <>
                <Form.Item
                  label='Viaje:'
                  name='transport_way'
                  rules={[{
                    required: true,
                    message: 'Field required'
                  }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    allowClear
                    placeholder='Sin seleccionar'
                    onChange={setWaySelected}
                    size='small'
                    className='quote__border'
                  >
                    {service.getWaysTransport().map((itm) => {
                      return <Option key={itm} value={itm}>{waysTransport.es[itm] ? waysTransport.es[itm] : itm}</Option>
                    })}
                  </Select>
                </Form.Item>
                {/* Descuentos de transporte */}
                {service.getTransportDiscounts().length > 0 && (
                  service.getTransportDiscounts()?.map((d, index) => (
                    <p key={index} className='byp-fw-bold text-discount'>
                      {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Transporte
                    </p>
                  ))
                )}
              </>
            }
            colB={
              <>
                {service.getTransportDiscounts().length > 0 && (
                  service.getTransportDiscounts()?.map((d, index) => (
                    <Statistic
                      key={index}
                      prefix='$'
                      className='price-after text-discount'
                      precision={2}
                      value={`${d.type === 'amount' ? '-' + d.value : '-' + handlePrice(d.value, service.getAmountTransport())}`}
                      // suffix={`${service.getCurrency()}`}
                    />

                  ))
                )}
              </>
            }
          />
        )
      }

      <TransportMinorCosts />
      {(service.getTransportIsHighSeason()?.start || service.getTransportIsHighSeason()?.end) && <FieldHighSeason forTransport />}
    </div>
  )
}

export { TransportSection }
