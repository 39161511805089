import { Empty } from 'antd'
import { Link } from 'react-router-dom'
import { URL_APLICACION_PAGE } from '../../../pages/aplicacion'

const MainThemeNotifications = ({ notifications = [] }) => {
  return (
    <div className='fs-14 fw-6'>
      {notifications.map(notification => (
        <div key={notification.id}>
          Recuerda <Link style={{ color: '#FF395C' }} to={URL_APLICACION_PAGE.replace(':id', notification.data.quote).replace(':applicationId', notification.data.id)}>llenar tu formato de Aplicación</Link> para el {notification.data.course}
        </div>
      ))}
      {notifications.length === 0 && (
        <Empty description='Sin notificaciones' />
      )}
    </div>
  )
}

export default MainThemeNotifications
