import { Form, Select, Statistic, Switch, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'

const { Option } = Select

function OtherSection () {
  const [selected, setSelected] = useState()
  const { service, formConfig, setFormConfig } = useQuoter()
  const [isMandatory] = useState(false)
  const [haveMandatory] = useState(false)

  const handleChange = (value, id, cost, frequency) => {
    let fees = []
    if (!formConfig.fees) {
      setFormConfig({
        ...formConfig,
        fees: []
      })
    }
    if (value) {
      fees = formConfig.fees ?? []
      fees?.push({ id, cost, frequency })
    } else {
      formConfig.fees?.map((fee) => (
        fee.id !== id ? fees.push(fee) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      fees
    })
  }

  const handleChangeSwitch = (included) => {
    setFormConfig({
      ...formConfig,
      insurance: included
    })
    // setActive(included)
  }

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      healthInsuranceId: selected
    })
  }, [selected])

  useEffect(() => {
    if (formConfig?.healthInsuranceId) {
      setSelected(formConfig?.healthInsuranceId)
    }
  }, [])

  return (
    <div>
      {service.getArrayHealtInsurance()?.length > 0 && (
        <RowQuoter
          colA={
            <>
              {!service.includeInsurance() && (
                <p className='w-100'>
                  Añadir seguro
                </p>
              )}
              {service.includeInsurance() && (
                <>
                  <Form.Item label='Nombre del seguro:' name='health_insurance' className='byp-mb-0' rules={[{ required: true, message: 'This field is required' }]}>
                    <Select
                      style={{ width: '95%' }}
                      allowClear
                      placeholder='Nombre del seguro'
                      onChange={setSelected}
                      disabled={isMandatory}
                      size='small'
                      className='quote__border'
                      dropdownMatchSelectWidth={false}

                    >
                      {service.getArrayHealtInsurance().map((itm) => {
                        return <Option key={itm.id} value={itm.id}>{itm.translations?.find(t => t.to === 'es')?.translation ?? itm.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  {
                    // Insurance discounts
                    <Tooltip title={
                      <>
                        {service.getInsuranceSelected()?.discounts?.map((d, index) => (
                          <p key={d.id} className='byp-fw-bold text-discount'>
                            {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Seguros
                          </p>
                        ))}
                      </>
                    } placement="bottomLeft" color="white">
                      <span>
                        {service.getInsuranceSelected()?.discounts?.map((d, index) => (
                          <p key={d.id} className='byp-fw-bold text-discount'>
                            {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Seguros
                          </p>
                        ))}
                      </span>
                    </Tooltip>
                  }
                </>
              )}
            </>
          }


          colB={
            // <StatisticWithDiscounts
            //   discounts={service.getInsuranceDiscounts()}
            //   amount={service.getAmountHealthInsurance()?.toFixed(2)}
            //   currency={service.getCurrency()}
            //   subtotal={service.getSubtotalInsurance()}
            //   active={service.includeInsurance()}
            // />
            <>
              {
                <Statistic
                  prefix='$'
                  precision={2}
                  value={`${service.getAmountHealthInsurance()?.toFixed(2)}`}
                  // suffix={`${service.getCurrency()}`}
                />
              }
              {
                service.getInsuranceDiscounts().length > 0 && (
                  service.getInsuranceDiscounts()?.map((d, index) => (
                    <Statistic
                      prefix='$'
                      className='price-after text-discount'
                      precision={2}
                      value={`${d.type === 'amount' ? '-' + d.value : '-' + d.value + '%'}`}
                      // suffix={`${service.getCurrency()}`}
                    />
                  ))
                )
              }
            </>
          }
          colC={
            <>
              {!haveMandatory && (
                <Switch
                  onChange={handleChangeSwitch} checked={service.includeInsurance()}
                />
              )}
            </>
            // <>
            //   {!active
            //     ? (
            //       <Button className='cotizacion_btn-add' onClick={() => handleChangeSwitch(true)}><span className='icon-mas'>+</span> Añadir</Button>
            //       )
            //     : (
            //       <Button className='cotizacion_btn-deleted' onClick={() => handleChangeSwitch(false)}><DeletedIcon /> Eliminar</Button>
            //       )}
            // </>
          }
        />
      )}

      {service.getPrices()?.extras?.map((item) => {
        return item.name !== 'Registration Fee'
          ? (
            <RowQuoter
              key={item.id}
              colA={
                <>
                  <p>
                    {item.translations?.find(t => t.to === 'es' && t.attribute === 'name')?.translation ?? item.name}
                  </p>
                  <p className='description-cost'>
                    {item.translations?.find(t => t.to === 'es' && t.attribute === 'description')?.translation ?? item.description}
                  </p>
                </>
              }
              colB={
                <StatisticWithDiscounts
                  discounts={service.getFeeDiscounts(item.id)}
                  amount={service.getFeeCost(item.id)?.toFixed(2)}
                  currency={service.getCurrency()}
                  subtotal={service.getFeeDiscontAmount(item.id)}
                  active={service.isChecked(item.id)}
                />
              }
              colC={
                <>
                  {!item?.mandatory && (
                    <Switch
                      onChange={(value) => { handleChange(value, item.id, item.cost, item.frequency) }}
                      className='byp-ml-1'
                      checked={service.isChecked(item.id)}
                    />
                  )}
                </>
              }
            />
          )
          : null
      })}
    </div>
  )
}

export { OtherSection }
