import { Spin } from 'antd'
import React from 'react'
// import Lottie from 'react-lottie'
// import animationData from './loading-qyp.json'

function LottieCargando ({ style = {} }) {
  // const defaultOptions = {
  //   animationData
  // }
  return (
    <div
      style={{
        // margin: '20px 0',
        marginBottom: '20px',
        padding: '30px 50px',
        textAlign: 'center',
        background: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        width: '100%',
        height: '100%',
        ...style
      }}
      className='byp-centered'
    >
      {/* <Lottie
        options={defaultOptions}
        height={550}
        width={550}
      /> */}
      <Spin />
    </div>
  )
}

export default LottieCargando
