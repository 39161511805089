import { message, Upload, Button, Row, Col, Form, Input, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import utilsState from '../../bussines/utils/state-control'
import { getBase64, headersForUpload, isJpgOrPng, isLt2M } from '../../utils/uploadImageUtils'
import ImgCrop from 'antd-img-crop'
// import ImgCrop from 'antd-img-crop'

function beforeUpload (file) {
  const _isJpgOrPng = isJpgOrPng(file)
  if (!_isJpgOrPng) {
    message.error('Solo puedes subir imágenes con formato jpeg o png!')
  }
  const _isLt2M = isLt2M(file)
  if (!_isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return _isJpgOrPng && _isLt2M
}

function UploadLogo ({
  isSaving = false,
  title = 'Add logo', action, usePostDeleteLogo, deleteData, data = {},
  profileUrl = '', name = 'profile_photo_url', fileFieldName = 'logo', form = null,
  className = '', btnUploadClassName, btnDeleteClassName, btnSaveClassName, disabled = false,
  forCampus = false, resp, showButtons = true, onUploaded, onSave, showDeleteButton = true
}) {
  const _form = Form.useFormInstance() || form
  const { isLoading, deleteLogo, currentState } = usePostDeleteLogo()
  const [state, setState] = useState({
    loading: false,
    imageUrl: profileUrl
  })

  const handleChange = ({ file }) => {
    if (file.status === 'uploading') {
      setState({ loading: true })
      return
    }
    if (file.status === 'done') {
      getBase64(file.originFileObj, (imageUrl) => {
        setState({
          imageUrl,
          loading: false
        })
        if (name && !forCampus) {
          _form.setFieldsValue({ [name]: file?.response?.url })
        }
        if (name && forCampus) _form.setFieldsValue({ [name]: file?.response?.url })
        if (onUploaded) onUploaded(file?.response)
      })
    } else if (file.status === 'error') {
      console.log('🚀 ~ file error:', file.response)
      setState({ loading: false })
      notification.error({
        message: 'something went wrong'
      })
    }
  }

  useEffect(() => {
    if (resp && resp.saved) {
      setState({
        imageUrl: null,
        loading: false
      })
    }
  })

  useEffect(() => {
    if (!isLoading && currentState === utilsState.STATE_SUCCESS) {
      setState({
        imageUrl: null,
        loading: false
      })
      _form?.setFieldsValue({ [name]: null })
    }
  }, [isLoading])

  useEffect(() => {
    setState({
      imageUrl: profileUrl,
      loading: false
    })
  }, [profileUrl])

  const { loading, imageUrl } = state

  return (
    <Row className='upload-profile-picture-container' gutter={12}>
      <Col>
        <Form.Item name={name} style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <ImgCrop
          aspect={120 / 120}
          // zoom
          // zoomSlider
        >
          <Upload
            name={fileFieldName ?? 'logo'}
            listType='picture-card'
            className={className ? 'avatar-uploader school-form' : 'avatar-uploader'}
            showUploadList={false}
            action={action}
            data={data}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={headersForUpload()}
            disabled={disabled}
          >
            {imageUrl
              ? (
                <img
                  src={imageUrl}
                  className={'avatar-upload ' + className}
                  alt='avatar'
                  style={{ width: '100%' }}
                />
                )
              : (
                <div>
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>{title}</div>
                </div>
                )}
          </Upload>
        </ImgCrop>
      </Col>
      {showButtons && (
        <>
          <Col className='byp-centered'>
            <ImgCrop
              aspect={120 / 120}
              // zoom
              // zoomSlider
            >
              <Upload
                name={fileFieldName ?? 'logo'}
                showUploadList={false}
                action={action}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                headers={headersForUpload()}
                disabled={disabled}
              >
                <Button size='large' type="submit" className={btnUploadClassName} disabled={disabled} loading={isSaving}>
                  {' '}
                  {title}
                </Button>
              </Upload>
            </ImgCrop>
          </Col>
        </>
      )}
      {showDeleteButton && (
        <Col className='byp-centered'>
          <Button
            size='large'
            className={btnDeleteClassName ?? 'byp-btn-back'}
            onClick={() => {
              if (usePostDeleteLogo) {
                deleteLogo(deleteData)
              } else {
                form.setFieldsValue({ [name]: null })
              }
            }}
            loading={isLoading}
            disabled={disabled}
          >
            Delete
          </Button>
        </Col>
      )}
      {onSave && (
        <Col className='byp-centered'>
          <Button size='large' className={btnSaveClassName} onClick={onSave} disabled={disabled} loading={isSaving}>
            Guardar
          </Button>
        </Col>
      )}
    </Row>
  )
}

export { UploadLogo }
