import { useQuery } from 'react-query'
import PageRepository from '../../repositories/PageRepository'

function useGetRandomCourses (program, filters = {}) {
  const params = { program, ...filters }
  return useQuery(
    ['useGetRandomCourses', params],
    () => PageRepository.getRandomCourses(params),
    {
      retry: 1,
      enabled: !!program
    }
  )
}

function useGetRandomBlogs (program, filters = {}) {
  const params = { program, ...filters }
  return useQuery(
    ['useGetRandomBlogs', params],
    () => PageRepository.getRandomBlogs(params),
    {
      retry: 1,
      enabled: !!program
    }
  )
}

export { useGetRandomCourses, useGetRandomBlogs }
