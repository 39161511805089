import { Form, Select } from 'antd'
import { useGetCitiesWithOffers } from '../../../bussines/hooks/places/useGetCitiesWithOffers'
const { Option } = Select

const SelectCitiesWithOffers = ({ filters, selectProps, ...props }) => {
  const { data: cities } = useGetCitiesWithOffers(filters)
  return (
    <>
      <Form.Item
        className='byp-m-0-important'
        {...props}
      >
        <Select
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          showSearch
          maxTagCount='responsive'
          {...selectProps}
        >
          {cities?.map(item => (
            <Option key={item.id} value={item.id}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

export { SelectCitiesWithOffers }
