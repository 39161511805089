import { useQuery } from 'react-query'
import OfferRespository from '../../repositories/OfferRespository'

// Return 8 recemended offers
function useGetOfferRecomendations (filters = {}, enabled = true) {
  return useQuery(['useGetOfferRecomendations', filters],
    () => OfferRespository.recomendedOffers(filters), {
      retry: 2,
      enabled
    })
}

export { useGetOfferRecomendations }
