import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { useSearch } from "../../../../providers/search/search-context-provider";
import { useGetCountriesWithOffers } from "../../../../bussines/hooks/places/useGetCountriesWithOffers";
import { validateProgramName } from "../../../../utils/programUtils";
import removeInvalidValuesFromParams from "../../../../utils/removeInvalidValuesFromParams";
const { Option } = Select;
const filterOption = (inputValue, option) => {
  return (
    option.props["data-type"]
      ?.toLowerCase()
      ?.indexOf(inputValue?.toLowerCase()) !== -1
  );
};

function SearchForm({ program, state }) {
  const { changeSearch, search, form } = useSearch();
  const programData = validateProgramName(program);
  const [searchFilter, setSearchFilter] = useState({
    program: null,
  });
  const { data, isLoading } = useGetCountriesWithOffers(
    undefined,
    { ...programData, response_language: "es" },
    !!program
  );
  const selectedCountries = Form.useWatch("country", form);

  const handleOnSubmitSearch = () => {
    const vals = searchFilter;
    vals.search = form.getFieldValue("search");
    const countFilter = Object.keys(searchFilter).length;
    if (isNaN(searchFilter?.sede)) {
      delete searchFilter.sede;
    }
    if (isNaN(searchFilter?.lang)) {
      delete searchFilter.lang;
    }
    const programData = validateProgramName(program);
    if (program) {
      const validParameters = removeInvalidValuesFromParams(searchFilter);
      const nFilter = {
        ...validParameters,
        ...programData,
      };
      setSearchFilter(nFilter);
    }
    if (countFilter) {
      form.resetFields([
        "date",
        "city",
        "materials",
        "lang",
        "school",
        "campus",
        "type_school",
        "is_legal",
        "study_area",
        "year",
        "seasons",
      ]);
      changeSearch("LANG_FORM", {
        ...searchFilter,
        ...programData,
      });
    }
  };

  const handleAddFilter = (key, value) => {
    const nFilter = {
      ...searchFilter,
      [key]: value,
      program,
    };
    setSearchFilter(nFilter);
  };

  useEffect(() => {
    if (!search?.search) {
      // form.resetFields()
    }
  }, [search]);

  useEffect(() => {
    if (state) {
      if (state?.search) {
        form.setFieldsValue({
          search: state?.search,
        });
      }
    }
  }, [state]);

  useEffect(() => {
    const programData = validateProgramName(program);
    const nFilter = {
      ...searchFilter,
      place: selectedCountries,
      place_type: "country",
      country: selectedCountries,
      ...programData,
    };
    setSearchFilter(nFilter);
  }, [selectedCountries]);

  return (
    <Form form={form}>
      <div compact className="language-from-responsive">
        {program !== "boarding_schools" && (
          <Form.Item noStyle name="search" xs={24}>
            <Input
              className="styled-input"
              size="large"
              onChange={(e) => {
                handleAddFilter("search", e.target.value);
              }}
              placeholder="¿Qué quieres estudiar?"
            />
          </Form.Item>
        )}

        <Form.Item noStyle name="country" xs={24} md={12}>
          <Select
            className="styled-select chips rounded w-100"
            showSearch
            mode="multiple"
            maxTagCount={3}
            filterOption={filterOption}
            placeholder="País"
            size="large"
            notFoundContent={
              isLoading
                ? "Cargando opciones disponibles..."
                : "No se encontró coincidencias"
            }
            showArrow
          >
            {data?.countries?.map((option) => (
              <Option
                key={option.id}
                value={option.id}
                data-type={option.translations?.translation ?? option.name}
              >
                {option.translations?.translation ?? option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div
          className="button-without-border-start button-search-responsive"
          style={{}}
        >
          <Button
            onClick={handleOnSubmitSearch}
            block
            size="large"
            className="byp-btn-red-50 btn--text-responsive"
          >
            Buscar
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default SearchForm;
