import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import '../../common/assets/css/form-vimeo.less'
import { calcularIntakeFinal } from '../../utils/dateUtils'
import { sumarSemanas } from '../../utils/getEndIntake'

const URL_TESTS_PAGE = '/admin/tests'

const intakeStart = 'Fall'
const intakeYearStart = '2023'
const intakeFinal = sumarSemanas(intakeStart, 104, intakeYearStart)
const intakeFinalc = calcularIntakeFinal(intakeStart, 26, intakeYearStart)
const TestPage = () => {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_AGENCY]}>
      <MainTheme>
        Test {intakeFinal} {intakeFinalc}
      </MainTheme>
    </PageProtected>
  )
}

export { TestPage, URL_TESTS_PAGE }
