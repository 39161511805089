import { Row, Select, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetCampusByInstitutionAndProgram } from '../../../../../../bussines/hooks/agency/analytics/program/useGetCampusByInstitutionAndProgram'
import { useGetInstitutionByProgram } from '../../../../../../bussines/hooks/agency/analytics/program/useGetInstitutionsByProgram'
import { SelectProgramsFormItem } from '../../../../../../common/components/helper-form/SelectProgramsFormItem'
import { useAuth } from '../../../../../../providers/auth/auth-context-provider'
import { SelectSchoolProgramsFormItem } from '../../../../../../common/components/helper-form/SelectSchoolProgramsFormItem'

const { Option } = Select
export function ProgramSelects ({ setProgramName, setInstitution, setCampus, institution, campus, firstOption = false }) {
  const [institutions, setInstitutions] = useState([])
  const [campusData, setCampusData] = useState([])
  const [status, setStatus] = useState()
  const [program, setProgram] = useState()
  const { user: _user } = useAuth()
  const { data, isLoading } = useGetInstitutionByProgram(program)
  const { data: campusDataApi, isLoading: isLoadingCampus } = useGetCampusByInstitutionAndProgram(program, institution)

  useEffect(() => {
    setCampusData(handleUniqueArr(campusDataApi))
  }, [campusDataApi])

  useEffect(() => {
    setInstitutions(handleUniqueArr(data))
  }, [data])

  useEffect(() => {
    const found = data?.find((School) => School.id === institution)
    if (found) {
      setStatus(found.status)
      console.log('🚀 ~ file: ProgramSelects.js:32 ~ useEffect ~ found.status:', found.status)
    }
  }, [institution])

  const handleUniqueArr = (array) => {
    return array?.filter((item, index) => {
      return array.findIndex(obj => obj.id === item.id) === index
    })
  }

  return (
    <div className='d-flex byp-mb-1 selects-responsives-column' style={{ gap: '0.5rem' }}>
      {_user?.type === 'Institution' && (
        <SelectSchoolProgramsFormItem
          className='user-analysis__select'
          placeholder=''
          style={{ width: 200 }}
          size='small'
          value={program}
          onChange={(val) => {
            setProgram(val)
            setInstitution()
            setCampus()
            setProgramName(val)
          }}
          setProgramName={setProgramName}
          setProgramID={setProgram}
          showLabel
          firstOption={firstOption}
        />
      )}
      {_user?.type === 'Agency' && (
        <SelectProgramsFormItem
          className='user-analysis__select'
          placeholder=''
          style={{ width: 200 }}
          size='small'
          value={program}
          onChange={(val) => {
            setProgram(val)
            setInstitution()
            setCampus()
            setProgramName(val)
          }}
          setProgramName={setProgramName}
          showLabel
          firstOption={firstOption}
        />
      )}
      <Select
        className='user-analysis__select'
        placeholder=''
        style={{ width: 120 }}
        size='small'
        value={institution}
        loading={isLoading}
        onChange={(val) => {
          setInstitution(val)
          setCampus('undefined')
        }}
        defaultValue='all'
      >
        <Option value='all'>All</Option>
        {institutions?.map(institution => (
          <Option key={institution.id} value={institution.id}>{institution.name}</Option>
        ))}
      </Select>
      <Select
        className='user-analysis__select'
        placeholder=''
        style={{ width: 120 }}
        size='small'
        value={campus}
        onChange={(val) => {
          setCampus(val)
          setProgramName(program)
        }}
        loading={isLoadingCampus}
        defaultValue='undefined'
      >
        <Option value='undefined'>All</Option>
        {campusData?.map(campus => (
          <Option key={campus.id} value={campus.id}>{campus.name}</Option>
        ))}
      </Select>
      {
        status === 'Deleted' && (
          <Row>
            <Tag
              style={{ marginLeft: '.5rem', fontSize: '10px' }}
              className='byp-bg-yelow-100 byp-color-white byp-fw-bold byp-centered'
            >Suspended
            </Tag>
          </Row>
        )
      }
    </div>
  )
}
