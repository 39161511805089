import { Avatar, Card, Col, Row, Typography } from 'antd'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { MyTravelsInfiniteList } from './MyTravelsInfiniteList'
const { Title, Text } = Typography

const MyCourses = ({ ...props }) => {
  const { user } = useAuth()

  return (
    <Row gutter={0} style={{ marginRight: '50px', marginLeft: '50px' }} className='my-courses my-courses-container'>
      <Col xs={24} md={6} lg={5} xxl={4}>
        <Card className='byp-bg-gray box-shadow-cards byp-mb-1 card-user-account bg-white'>
          <Row gutter={12}>
            <Col span={10}>
              <Avatar src={user?.profile_photo_url} style={{ width: '100%', height: 'auto' }} />
            </Col>
            <Col span={14} style={{ display: 'inline-grid', alignContent: 'center' }} className='text-black-50'>
              <Text className='fw-5 fs-14 text-black-50' style={{ marginBottom: '0' }}>Tu cuenta</Text>
              <Title level={4} className='text-black-50' style={{ marginTop: '0', marginBottom: '0' }} ellipsis>{user?.name}</Title>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} md={18} lg={19} xxl={20} className='bg-white my-courses-container'>
        <Title level={4}>Próximos Cursos</Title>
        <MyTravelsInfiniteList />
      </Col>
    </Row>
  )
}

export default MyCourses
