import { Form, Switch, Select, Statistic } from 'antd'
import React, { useState } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { getDiscountSymbol, handlePrice } from '../../../utils/getDiscountSymbol'

const { Option } = Select

function FieldSchoolMaterials() {
  const { service, setFormConfig, formConfig } = useQuoter()
  const materials = service.getSchoolMaterial()
  const [active, setActive] = useState(false)

  const handleChangeSwitch = (b) => {
    setFormConfig({
      ...formConfig,
      materials: b
    })
    setActive(b)
  }

  const handleChange = (id) => {
    setFormConfig({
      ...formConfig,
      material: id
    })
  }

  return (
    <>
      <RowQuoter
        colA={
          <>
            <p className=''>
              ¿Agregar materiales?
            </p>
          </>
        }
        // colB={
        //   <StatisticWithDiscounts
        //     discounts={service.getMaterialDiscounts()}
        //     amount={service.getAmountMaterials()}
        //     currency={service.getCurrency()}
        //     subtotal={service.getTotalMaterials()}
        //     active={formConfig.materials && formConfig.material}
        //   />
        // }
        colC={
          <>
            <Form.Item name='materials' noStyle valuePropName='checked'>
              <Switch
                onChange={handleChangeSwitch}
                className='byp-ml-1'
                // checkedChildren='YES'
                // unCheckedChildren='NO'
                checked={active}
              />
            </Form.Item>
            {/* {!active
              ? (
                <Button className='cotizacion_btn-add' onClick={() => handleChangeSwitch(true)}><span className='icon-mas'>+</span> Añadir</Button>
                )
              : (
                <Button className='cotizacion_btn-deleted' onClick={() => handleChangeSwitch(false)}><DeletedIcon /> Eliminar</Button>
                )} */}
          </>
        }
      />
      {
        formConfig.materials && (
          <>
            <RowQuoter
              colA={
                <> {
                  <Form.Item
                    name='material'
                    rules={[{
                      required: true,
                      message: 'Field required'
                    }]}
                    style={{ marginBottom: 0 }}
                  >
                    <Select onChange={handleChange} size='small' className='quote__border' dropdownMatchSelectWidth={false}
                    >
                      {materials?.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.translations?.find(t => t.to === 'es')?.translation ?? item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                  {
                    service.getMaterialDiscounts().length > 0 && (
                      service.getMaterialDiscounts()?.map((d, index) => (
                        <p key={d.id} className='byp-fw-bold text-discount'>
                          {d.name} {getDiscountSymbol(d.value, d.type, service.getCurrency())} en Materiales
                        </p>
                      ))
                    )

                  }
                </>
              }
              colB={
                <>{
                  // <StatisticWithDiscounts
                  //   discounts={service.getMaterialDiscounts()}
                  //   amount={service.getAmountMaterials()}
                  //   currency={service.getCurrency()}
                  //   subtotal={service.getTotalMaterials()}
                  //   active={formConfig.materials && formConfig.material}
                  // />
                  <Statistic
                    prefix='$'
                    precision={2}
                    value={`${service.getAmountMaterials()}`}
                    // suffix={`${service.getCurrency()}`}
                  />}
                  {
                    service.getMaterialDiscounts().length > 0 && (
                      service.getMaterialDiscounts()?.map((d, index) => (
                        <Statistic
                          prefix='$'
                          className='price-after text-discount'
                          precision={2}
                          value={`${d.type === 'amount' ? '-' + d.value : '-' + handlePrice(d.value, service.getAmountMaterials())}`}
                          // suffix={`${service.getCurrency()}`}
                        />
                      ))
                    )

                  }
                </>
              }
            />
          </>
        )
      }
    </>
  )
}

export { FieldSchoolMaterials }
