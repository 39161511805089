import { useQuery } from 'react-query'
import PageRepository from '../../repositories/PageRepository'

function useGetRelatedBlogs (slug) {
  return useQuery(
    ['useGetRelatedBlogs', slug],
    () => PageRepository.relatedBlogs(slug),
    {
      retry: 1,
      enabled: !!slug
    }
  )
}

export { useGetRelatedBlogs }
