import { Col, Form, Input, Row, Select } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { format } from 'date-fns'
import { formatStringDate } from '../../../utils/formatStringDate'
import { programIntakes } from '../../../languageConfiguration/infoCourseTranslate'
import { useEffect, useState } from 'react'
const { Option } = Select

export function ProgramIntake () {
  const [grades, setGrades] = useState([])
  const { service, formConfig, setFormConfig, course } = useQuoter()

  useEffect(() => {
    if (course?.offer?.campus?.grades) {
      setGrades([])
      const numbers = course?.offer?.campus?.grades
      for (let i = numbers[0]; i <= numbers[1]; i++) {
        setGrades(currentValues => {
          return [...currentValues, i]
        })
      }
    }
  }, [course])
  return (
    <Row gutter={12}>
      {!service.getCourseStartDates()?.length > 0 && (
        <Col md={24} lg={24}>
          {(service.getCourseProgram().name === 'graduate') && (
            <Form.Item label='Inicio de curso' name='intake' rules={[{ required: true, message: 'Field required' }]}>
              <Select
                className='quote__border'
                onChange={(intak) => {
                  setFormConfig({
                    ...formConfig,
                    intake: intak + ''
                  })
                  if (!intak) {
                    return
                  }
                  const dates = service.getDates(intak)
                  service?.searchPrices(dates[0])
                }}
                style={{ width: '8rem' }}
                size='small'
              >
                {service.getCourseIntakes()?.map((intake) => (
                  <Option key={'intake_' + intake} value={intake}>{programIntakes.es[intake] ?? intake}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {(service.getCourseProgram().name === 'academic_years' || service.getCourseProgram().name === 'boarding_schools') && (
            <Row gutter={8}>
              <Col xs={12}>
                <Row gutter={12} style={{ marginTop: '10px' }} align='middle'>
                  <label style={{ fontWeight: '600', padding: '0 6px 12px' }}>Fechas de Curso</label>
                  <Col span={24} className='antd-date'>
                    <div className='cotizacion__only-input'>
                      <div>
                        <label>Fecha de Inicio</label>
                        <Form.Item name='intake_date' rules={[{ required: true, message: 'Field required' }]}>
                          <Select
                            className='intake-byp__intake'
                            onChange={(intak) => {
                              setFormConfig({
                                ...formConfig, intake: intak
                              })
                              if (!intak) return
                              const nDate = new Date(intak)
                              service?.searchPrices(format(nDate, 'yyyy-LL-dd'))
                            }}
                            size='small'
                            readOnly
                          >
                            {service.getCourseStartDates()?.length > 0 && (
                              <Option
                                key='intake_null'
                                value={null}
                              >
                                None
                              </Option>
                            )}
                            {service.getCourseIntake()?.map((intake) => (
                              <Option
                                key={'intake_' + intake}
                                value={intake}
                              >
                                {formatStringDate(intake, 'MM/yyyy')}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row gutter={12} style={{ marginTop: '10px' }} align='middle'>
                  <label style={{ fontWeight: '600', padding: '0 6px 12px' }}>Grado</label>
                  <Col span={24} className='antd-date'>
                    <div className='cotizacion__only-input'>
                      <div>
                        <label>Grado</label>
                        <Form.Item name='grade' rules={[{ required: true, message: 'Field required' }]}>
                          <Select
                            className='intake-byp__intake'
                            onChange={(grade) => {
                              setFormConfig({
                                ...formConfig,
                                grade
                              })
                            }}
                            size='small'
                            readOnly
                          >
                            {grades?.map((grade) => (
                              <Option
                                key={grade}
                                value={grade}
                              >
                                {grade}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
      )}
      {
        service.getCourseStartDates()?.length > 0 && (
          <Col span={8}>
            <Form.Item name='startCourse' label='Date' rules={[{ required: true, message: 'Field required' }]}>
              <Select
                onChange={(start) => {
                  setFormConfig({
                    ...formConfig, startCourse: start
                  })
                  // console.log('🚀 ~ file: ProgramIntake.js:56 ~ ProgramIntake ~ start:', start)
                  service?.searchPrices(start)
                }}
                className='quote__border'
                size='small'
              >
                {service.getCourseStartDates()?.map((date) => (
                  <Option key={'start_date_' + date.date} value={date.date}>{formatStringDate(date.date, 'd LLLL, yyyy')}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )
      }
      <Col md={0} lg={0}>
        <Form.Item name='length'>
          <Input readOnly type='hidden' />
        </Form.Item>
      </Col>
      <Col md={0} lg={0}>
        <Form.Item name='duration_type'>
          <Input readOnly type='hidden' />
        </Form.Item>
      </Col>
    </Row>
  )
}
