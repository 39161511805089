import { Col, Form, Input, Radio, Row } from 'antd'
import React from 'react'

function ApplicationInsuranceSection ({ form, insurance, disabled }) {
  const haveInsurance = Form.useWatch('haveInsurance', form)

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <h2 className='subtitle-aplication byp-m-0'>Seguro Médico</h2>
      </Col>
      <Col span={24} className='byp-mb-1'>
        <Row align='middle' gutter={[8, 8]}>
          <Col>
            <label className='label-aplication'>¿Necesitas seguro médico?</label>
          </Col>
          <Col>
            <Form.Item className='byp-m-0' name='haveInsurance'>
              <Radio.Group name='radiogroup' defaultValue='no' disabled={!!insurance || disabled}>
                <Radio value='yes' className='radio_btn-quote'>Sí</Radio>
                <Radio value='no' className='radio_btn-quote'>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
      {haveInsurance === 'yes' && insurance && (
        <>
          <Col xs={24}>
            <label className='label-aplication'>Tipo de seguro</label>
            <Form.Item
              name='insurance'
            >
              <Input className='aplication__input' disabled />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  )
}

export default ApplicationInsuranceSection
