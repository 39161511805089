import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Radio, Row, notification } from 'antd'
import { usePostApplication } from '../../../bussines/hooks/application/usePostApplication'
import GeneralInformation from './GeneralInformation'
import ProgramaForm from './ProgramaForm'
import ContactForm from './ContactForm'
import AccommodationForm from './AccommodationForm'
import AdditionalInformation from './AdditionalInformation'
import stateControl from '../../../bussines/utils/state-control'
import { showErrorModal } from '../../../utils/errorModal'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuote } from '../../../bussines/hooks/quote/useQuote'
import LottieCargando from '../../../lotties/LottieCargando'
import moment from 'moment'
// import { URL_USER_ACCOUNT } from '../../user/MyAccountPage'
import { URL_CONFIRMATION_PAGE2 } from '../../confirmation/apply'
import { esProgramType } from '../../../utils/programUtils'
import { esLength } from '../../../utils/lengthTimeUtils'
import { BasicRadioGroupFormItem } from '../../../common/components/helper-form/BasicRadioGroupFormItem'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { momentToDateformat, stringDateToMoment } from '../../../utils/formatMoment'
import { cleanLogoUrl } from '../../../utils/cleanStorageUrl'
import ModalWantAccommodation from './ModalWantAccommodation'
import { useGetApplication } from '../../../bussines/hooks/application/useGetApplication'
import BrothersListForm from './BrothersListForm'
import { ApplicationAcademicFields } from './ApplicationAcademicFields'
import { programLabels } from '../../../languageConfiguration/infoCourseTranslate'
import { formatStringDate } from '../../../utils/formatStringDate'
import { MdDownloadForOffline } from 'react-icons/md'
import { API_URL } from '../../../config'
import { usePutApplication } from '../../../bussines/hooks/application/usePutApplication'
import { URL_DASHBOARD_PAGE } from '../../dashboards'
import { useUpdateUserProfile } from '../../../bussines/hooks/user/useUpdateUserProfile'
import ApplicationTransportSection from './ApplicationTransportSection'
import ApplicationInsuranceSection from './ApplicationInsuranceSection'

const URL_ABOUT_TERMS_AND_CONDITIONS_PAGE = '/about/terms_and_conditions'
const URL_ABOUT_POLICY_PAGE = '/about/policy'
const required = { required: true, message: 'Este dato es requerido' }
const programsForv2 = ['boarding_schools', 'academic_years']

const FormAplication = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const [form] = Form.useForm()
  const { id, applicationId } = useParams()
  // Get the info
  const { saveApplication, isLoading: isSaving, currentState, error: errorSaving } = usePostApplication()
  const { updateApplication, isLoading: isUpdating, currentState: updateStatus, error: errorUpdating } = usePutApplication()
  const { isLoading: isLoadingApplication, data: application } = useGetApplication(applicationId)
  const { isLoading, data: quote } = useQuote(id)
  const { user: _user } = useAuth()

  const [modal, contextHolder] = Modal.useModal()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isUpdateMode, setIsUpdateMode] = useState()
  const [canEdit, setCanEdit] = useState(false)
  const [openAccomodationModal, setOpenAccomodationModal] = useState(false)
  const [wantAccomodation, setWantAccomodation] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [program, setProgram] = useState()
  const [formId, setFormId] = useState()
  const [transport, setTransport] = useState()
  const [insurance, setInsurance] = useState()

  const acceptTermsAndConditions = Form.useWatch('acceptTermsAndConditions', form)
  const isMe = Form.useWatch('isMe', form)

  const [url] = React.useState(`${API_URL}/preview-pdf-application/${id}/${applicationId}`)

  const {
    nextStep : nextStepUpdateUser,
    // isLoading: isLoadingUpdateUser,
    // currentState: currentStateUpdateUser,
    // error: errorUpdateUser,
  } = useUpdateUserProfile();

  const handleSavePicture = () => {
    const {
      isMe,
      acceptTermsAndConditions,
      age,
      campus_name,
      contacts,
      start_date,
      course,
      school,
      medical_situation,
      duration,
      program_name,
      education_level,
      birthday,
      cel,
      ...restUserData
    } = form.getFieldsValue();

    // Formatting the date of birth
    const formattedDateOfBirth = momentToDateformat(birthday);
    const phone = cel;

    const data = {
      ...restUserData,
      date_of_birth: formattedDateOfBirth,
      phone,
    };

    // console.log('data', data)

    if (isMe) {
    nextStepUpdateUser(null, data)
    }
  };

  const handleOnFinish = (values) => {

    // todo: if is me, then I need to update mi user accound data profile
    handleSavePicture()
    
    
    if (acceptTermsAndConditions !== 'yes') {
      return setIsModalVisible(true)
    }
    if (!quote?.lodging) {
      return setOpenAccomodationModal(true)
    }
    handleSaveApplication()
  }

  const handleOnWantAccommodation = (wantAccomodationValue) => {
    handleSaveApplication(wantAccomodationValue)
  }

  const handleSaveApplication = (wantAccomodationValue = false) => {
    if (openAccomodationModal) {
      setOpenAccomodationModal(false)
    }
    const values = form.getFieldsValue()
    values.application_id = applicationId
    values.id = id
    values.program = quote?.course?.program
    values.birthday = momentToDateformat(values.birthday)
    if (values.profile_photo_url) {
      values.profile_photo_url = cleanLogoUrl(values.profile_photo_url)
    }
    if (wantAccomodationValue) {
      values.want_accommodation = true
      setWantAccomodation(true)
    } else {
      setWantAccomodation(false)
    }

    // Format contacts date of birth
    if (values.contacts?.length) {
      const contacts = values.contacts?.map(contact => {
        const DofB = contact.birthday ? momentToDateformat(contact.birthday) : null
        return {
          ...contact,
          birthday: DofB
        }
      })
      values.contacts = contacts
    }
    if (programsForv2.includes(quote?.course?.program)) {
      const siblings = values.siblings?.map(contact => {
        return {
          ...contact,
          kinship: 'sibling'
        }
      })
      values.siblings = siblings
    }

    if (isUpdateMode) {
      updateApplication(formId, values)
    } else {
      saveApplication(values)
    }
  }

  const handleOnFinishFailed = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const formatDate = (date) => {
    const parsedDate = moment(date, 'DD MMM, YYYY')
    return parsedDate.format('D [de] MMMM [de] YYYY')
  }

  const hanldeHelp = () => {
    const mensajePersonalizado = encodeURIComponent(`Hola, necesito ayuda con mi formato de aplicación para:\n\nCourse: ${quote?.course?.name}\nSchool: ${quote?.provider?.institution?.name}\nCity: ${quote?.provider?.campus?.city.name}\nCampus: ${quote?.provider?.campus?.country.name}`)
    const url = `https://api.whatsapp.com/send/?phone=5213310922610&text=${mensajePersonalizado}&type=phone_number&app_absent=0`
    window.open(url, '_blank')
  }

  const getAnswer = (values, type) => {
    const value = values?.find(item => item.question === type)
    return value?.answer ?? ''
  }

  useEffect(() => {
    if (application) {
      const form = application.form
      setIsUpdateMode(!!form)
      setFormId(form?.id)
      // const rolesUser = user?.role_names ?? []
      // if (rolesUser.includes('agency-admin') && isUpdateMode) {
      //   setCanEdit(false) // The edition will be added later
      // }
      if (!form) {
        setCanEdit(true)
      } else {
        setCanEdit(false)
      }
    }

    if (_user) {
      const role = _user?.roles
      if (role?.includes('Agency Administrator')) {
        setCanEdit(true)
        setIsAdmin(true)
      }
    }
  }, [isLoadingApplication, _user, application])

  useEffect(() => {
    if (!isSaving && currentState === stateControl.STATE_SUCCESS) {
      const createMessage = 'Application created succesfully'
      const updateMessage = 'Application updated succesfully'
      notification.success({
        message: isUpdateMode ? updateMessage : createMessage
      })

      form.resetFields()
      if (isUpdateMode) {
        setIsUpdateMode(false)
      }
      navigate(URL_CONFIRMATION_PAGE2, { state: { wantAccomodation, quoteId: id, applicationId, isAdmin } })
    }
  }, [isSaving, currentState])

  useEffect(() => {
    if (!isUpdating && updateStatus === stateControl.STATE_SUCCESS) {
      const updateMessage = 'Application updated succesfully'
      notification.success({
        message: updateMessage
      })

      form.resetFields()
      if (isUpdateMode) {
        setIsUpdateMode(false)
      }
      navigate(URL_DASHBOARD_PAGE)
    }
  }, [isUpdating, updateStatus])

  useEffect(() => {
    if (!isSaving && errorSaving?.response.data) {
      const errors = errorSaving.response.data.errors
      if (errors) {
        const messages = Object.values(errors)
        const firtValidation = messages[0] ?? []
        const message = firtValidation[0] ?? null
        showErrorModal(errorSaving.response.data.message, message)
      } else {
        showErrorModal(null, errorSaving.response.data.message)
      }
    }
    if (!isUpdating && errorUpdating?.response.data) {
      const errors = errorUpdating.response.data.errors
      if (errors) {
        const messages = Object.values(errors)
        const firtValidation = messages[0] ?? []
        const message = firtValidation[0] ?? null
        showErrorModal(errorUpdating.response.data.message, message)
      } else {
        showErrorModal(null, errorUpdating.response.data.message)
      }
    }
  }, [errorSaving, modal, isSaving, errorUpdating])
  // Set the user info
  useEffect(() => {
    // Only update values if isnt a filled form
    if (isMe && !application?.form) {
      const includeExtraData = programsForv2.includes(quote?.course?.program)
      const contacts = user.emergency_contacts?.filter(c => c.emergency).map(contact => {
        const birthday = contact.birthday ? moment(contact.birthday) : undefined
        const addressData = {}
        if (includeExtraData) {
          addressData.sameAddress = !contact.address
          addressData.address = contact.address
          addressData.colony = contact.colony
          addressData.city = contact.city
          addressData.country = contact.country
          addressData.zip_code = contact.zip_code
        }
        return {
          id: contact.id,
          full_name: contact.name,
          kinship: contact.role,
          cel: contact.tel,
          email: contact.email,
          birthday,
          emergency: contact.emergency,
          occupation: contact.occupation,
          ...addressData
        }
      })
      const siblings = user.emergency_contacts?.filter(c => !c.emergency).map(contact => {
        return {
          id: contact.id,
          full_name: contact.name
        }
      })

      // const allConstacts = user.emergency_contacts?.
      const languages = JSON.parse(user.profile?.languages ?? '[]')

      form.setFieldsValue({
        name: user.name,
        last_name: user.last_name,
        email: user.email,
        // profile_photo_url: user.profile_photo_url,
        profile_photo_url: user.profile_photo_url,
        birthday: stringDateToMoment(user.profile.date_of_birth),
        // nationality: user.profile.country_id,
        country: user.profile?.country_id,
        nationality: user.profile?.nationality_id,
        address: user.profile.address,
        colony: user.profile.colony,
        zip_code: user.profile.zip_code,
        city: user.profile.city?.name,
        // country: user.profile.country?.name,
        cel: user.profile.phone,
        education_level: user.profile.education_level,
        medical_situation: user.profile.medical_situation,
        contacts: contacts?.length > 0 ? contacts : [{}],
        siblings: siblings?.length > 0 ? siblings : [{}],
        languages
      })
    } else if (!application?.form) {
      form.setFieldsValue({
        name: null,
        last_name: null,
        email: null,
        profile_photo_url: null,
        birthday: null,
        nationality: null,
        cel: null,
        colony: null,
        zip_code: null,
        city: null,
        country: null,
        address: null,
        contacts: [{
          full_name: user.name + ' ' + user.last_name,
          kinship: null,
          cel: user.profile.phone,
          email: user.email
        }],
        siblings: [{}]
      })
    }
  }, [isMe])

  // Set program data
  useEffect(() => {
    if (quote) {
      let programName = quote?.course?.program
      let duration = quote?.course?.length ?? quote?.course?.weeks > 1 ? quote?.course?.weeks + ' semanas' : quote?.course?.weeks + ' semana'
      if (quote?.course?.program === 'graduate') {
        programName = esProgramType(quote?.course?.program_type)
        const parts = quote?.course?.length.split(' ')
        duration = parts[0] + ' ' + esLength(parts[1])
      } else {
        const labels = programLabels.es
        programName = labels[programName] ?? ''
      }
      const courseLength = quote.course?.length

      const accomodationPreferences = {}
      if (quote?.lodging) {
        if (user.profile.allergies) {
          accomodationPreferences.allergies = JSON.parse(user.profile?.allergies ?? '[]')
          accomodationPreferences.haveAllergies = accomodationPreferences.allergies?.length > 0 ? 'yes' : 'no'
        }
        if (user.profile.diet) {
          accomodationPreferences.not_eat = JSON.parse(user.profile?.diet ?? '[]')
          accomodationPreferences.haveRestriction = accomodationPreferences.not_eat?.length > 0 ? 'yes' : 'no'
        }
        if (user.profile.accept_pets) {
          accomodationPreferences.pets = JSON.parse(user.profile?.accept_pets ?? '[]')
          accomodationPreferences.havePets = accomodationPreferences.pets?.length > 0 ? 'yes' : 'no'
        }
      }

      let startDate = ''
      if (quote?.intake) {
        const isIntakeDate = quote.intake.includes('/')
        startDate = isIntakeDate ? formatStringDate(quote.intake, 'LLLL yyyy') : quote.intake + ' ' + quote.year?.year
      } else if (quote?.course?.start) {
        startDate = formatDate(quote?.course?.start)
      }

      const campusCosts = quote.campus_costs ?? []
      const insurance = campusCosts.find(item => item.type === 'insurance' && item.isAgency !== 'Agency')
      const transport = campusCosts.find(item => item.type === 'transport')
      setTransport(transport)
      setInsurance(insurance)
      accomodationPreferences.haveInsurance = 'no'
      accomodationPreferences.haveTransport = 'no'
      if (insurance) {
        accomodationPreferences.insurance = insurance.name
        accomodationPreferences.haveInsurance = 'yes'
      }
      if (transport) {
        accomodationPreferences.transport = transport.name
        accomodationPreferences.travel = transport.ways
        accomodationPreferences.haveTransport = 'yes'
      }

      form.setFieldsValue({
        program: quote?.course?.program_id,
        program_name: programName,
        school: quote?.provider?.institution?.id,
        campus: Number(quote?.provider?.campus?.id),
        campus_name: quote?.provider?.campus?.name,
        course: quote?.course?.name,
        duration: courseLength ?? duration,

        start_date: startDate,

        haveAccommodation: quote?.lodging ? 'yes' : 'no',
        accommodation: quote?.lodging?.name,
        duration_accomodation: quote?.lodging?.weeks > 1 ? quote?.lodging?.weeks + ' semanas' : quote?.lodging?.weeks + ' semana',
        start_date_accommodation: quote?.lodging?.start && formatDate(quote?.lodging?.start),
        end_date_accommodation: quote?.lodging?.end && formatDate(quote?.lodging?.end),
        ...accomodationPreferences
      })
      setProgram(quote?.course?.program)
    }
  }, [quote])

  useEffect(() => {
    // If the application has already a form
    if (!isLoadingApplication && application && application?.form) {
      const values = application?.form?.forms_answers
      const image = getAnswer(values, 'profile_photo_path')
      const isMe = getAnswer(values, 'isMe') !== '0'
      // HS y BS fields
      const academicAnswers = {}
      academicAnswers.sport_why = getAnswer(values, 'sport_why')
      const subjects = getAnswer(values, 'subjects')
      const instruments = getAnswer(values, 'instruments')
      const hobbies = getAnswer(values, 'hobbies')
      const sports = getAnswer(values, 'sports')
      const importantSports = getAnswer(values, 'important_sports')
      const religions = getAnswer(values, 'religions')
      const locations = getAnswer(values, 'locations')
      const aditionalLanguages = getAnswer(values, 'aditional_languages')

      if (subjects) academicAnswers.subjects = JSON.parse(subjects)
      if (instruments) academicAnswers.instruments = JSON.parse(instruments)
      academicAnswers.playInstruments = !!instruments
      if (hobbies) academicAnswers.hobbies = JSON.parse(hobbies)
      if (sports) academicAnswers.sports = JSON.parse(sports)
      academicAnswers.playSports = !!sports
      if (importantSports) academicAnswers.important_sports = JSON.parse(importantSports)
      academicAnswers.playSportsImportant = !!importantSports
      if (religions) academicAnswers.religions = JSON.parse(religions)
      academicAnswers.playReligions = !!sports
      if (locations) academicAnswers.locations = JSON.parse(locations)
      academicAnswers.hasTravels = !!locations
      if (aditionalLanguages) academicAnswers.aditional_languages = JSON.parse(aditionalLanguages)

      const includeExtraData = programsForv2.includes(quote?.course?.program)
      if (isMe) {
        academicAnswers.contacts = user.emergency_contacts?.filter(c => c.emergency).map(contact => {
          const birthday = contact.birthday ? moment(contact.birthday) : undefined
          const addressData = {}
          if (includeExtraData) {
            addressData.sameAddress = !contact.address
            addressData.address = contact.address
            addressData.colony = contact.colony
            addressData.city = contact.city
            addressData.country = contact.country
            addressData.zip_code = contact.zip_code
          }
          return {
            id: contact.id,
            full_name: contact.name,
            kinship: contact.role,
            cel: contact.tel,
            email: contact.email,
            birthday,
            emergency: contact.emergency,
            occupation: contact.occupation,
            ...addressData
          }
        })
      } else {
        academicAnswers.contacts = application.form.contacts?.filter(c => c.emergency)
        academicAnswers.contacts = academicAnswers.contacts?.map(contact => {
          const birthday = contact.birthday ? moment(contact.birthday) : undefined
          const addressData = {}
          if (includeExtraData) {
            addressData.sameAddress = !contact.address
            addressData.address = contact.address
            addressData.colony = contact.colony
            addressData.city = contact.city
            addressData.country = contact.country
            addressData.zip_code = contact.zip_code
          }
          return {
            id: contact.id,
            full_name: contact.name,
            kinship: contact.role,
            cel: contact.tel,
            email: contact.email,
            birthday,
            emergency: contact.emergency,
            occupation: contact.occupation,
            ...addressData
          }
        })
      }

      academicAnswers.siblings = getAnswer(values, 'siblings') && JSON.parse(getAnswer(values, 'siblings'))

      form.setFieldsValue({
        name: getAnswer(values, 'name'),
        last_name: getAnswer(values, 'last_name'),
        email: getAnswer(values, 'email'),
        birthday: moment(getAnswer(values, 'birthday')),
        nationality: getAnswer(values, 'nationality') && parseInt(getAnswer(values, 'nationality')),
        cel: getAnswer(values, 'cel'),
        address: getAnswer(values, 'address'),
        colony: getAnswer(values, 'colony'),
        zip_code: getAnswer(values, 'zip_code'),
        city: getAnswer(values, 'city'),
        country: getAnswer(values, 'country'),
        education_level: getAnswer(values, 'education_level'),
        languages: getAnswer(values, 'languages') && JSON.parse(getAnswer(values, 'languages')),
        name_contact: getAnswer(values, 'name_contact'),
        last_name_contact: getAnswer(values, 'last_name_contact'),
        cel_contact: getAnswer(values, 'cel_contact'),
        email_contact: getAnswer(values, 'email_contact'),
        haveAllergies: getAnswer(values, 'allergies') ? 'yes' : 'no',
        allergies: getAnswer(values, 'allergies') && JSON.parse(getAnswer(values, 'allergies')),
        haveRestriction: getAnswer(values, 'allergies') ? 'yes' : 'no',
        not_eat: getAnswer(values, 'not_eat') && JSON.parse(getAnswer(values, 'not_eat')),
        havePets: getAnswer(values, 'pets') ? 'yes' : 'no',
        pets: getAnswer(values, 'pets') && JSON.parse(getAnswer(values, 'pets')),
        medical_situation: getAnswer(values, 'medical_situation'),
        profile_photo_url: isMe ? user.profile_photo_url : image,
        isMe,
        haveTransport: getAnswer(values, 'haveTransport'),
        haveInsurance: getAnswer(values, 'haveInsurance'),
        ...academicAnswers
      })
    } else if (!isLoadingApplication && application && !application?.form) {
      //
    }
  }, [isLoadingApplication, application])

  if (isLoading || !quote) {
    return <LottieCargando style={{ width: '100vw', height: '50vh' }} />
  }

  return (
    <>
      <Modal
        title='Algo salió mal'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        Por favor, completa todos los campos requeridos y acepta la veracidad de la información proporcionada.
      </Modal>

      {contextHolder}
      <ModalWantAccommodation
        open={openAccomodationModal}
        handleOk={() => handleOnWantAccommodation(true)}
        handleCancel={() => handleSaveApplication(false)}
        handleCloseModal={() => setOpenAccomodationModal(false)}
      />
      <Form onFinish={handleOnFinish} form={form} onFinishFailed={handleOnFinishFailed} className='application-form'>
        {/* Title */}
        <Row className='byp-mb-1' justify='space-between' align='middle'>
          <Col xs={24} sm={24} md={20} className='center-responsive byp__location-title'>
            <h1 className='byp-m-0 title-aplication'>
              Formato de aplicación
            </h1>
          </Col>
          <Col xs={24} sm={24} md={3}>
            {isUpdateMode && (
              <a href={url} style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                <Button className='btn__pink' size='large' style={{ fontSize: '12px' }}>
                  <MdDownloadForOffline style={{ fontSize: '16px', marginRight: '5px', verticalAlign: 'middle' }} /> Descargar PDF
                </Button>
              </a>
            )}
            {!isUpdateMode && (
              <Button className='btn__pink' size='large' style={{ fontSize: '12px' }} onClick={hanldeHelp}>¿Necesitas Ayuda?</Button>
            )}
          </Col>

        </Row>
        {/* Radio isMe */}
        <Col className='radio_span'>
          <BasicRadioGroupFormItem
            name='isMe'
            label='¿Es para ti el curso?'
            trueText='Sí'
            falseText='No'
            radioGroupProps={{
              className: 'byp-ant-radio',
              disabled: !canEdit
            }}
          />
        </Col>
        <GeneralInformation disabled={!canEdit} />
        <ProgramaForm program={quote?.course?.program} disabled={!canEdit} />
        {programsForv2.includes(program) && <h2 className='subtitle-aplication'>Información de padres o tutores</h2>}
        {!programsForv2.includes(program) && <h2 className='subtitle-aplication'>Contacto de emergencia</h2>}
        <p className='fs-12 fw-4'>En caso de emergencia contáctamos a:</p>
        <br />
        <ContactForm disabled={!canEdit} displayExtraFileds={programsForv2.includes(program)} />
        <br />
        {programsForv2.includes(program) && (
          <>
            <h2 className='subtitle-aplication'>Información de hermanos</h2>
            <BrothersListForm listName='siblings' disabled={!canEdit} />
            <br />
            <h2 className='subtitle-aplication'>Información de tus estudios actuales</h2>
            <ApplicationAcademicFields disabled={!canEdit} />
          </>
        )}
        <br />
        {/* Accommodation */}
        {quote?.lodging && (
          <>
            <AccommodationForm form={form} disabled={!canEdit} />
            <br />
          </>
        )}
        {/* Transport */}
        <ApplicationTransportSection form={form} disabled={!canEdit} transport={transport} />
        <br />
        {/* School Insurance */}
        <ApplicationInsuranceSection form={form} disabled={!canEdit} insurance={insurance} />
        <br />

        <AdditionalInformation form={form} lodging={quote?.lodging} disabled={!canEdit} />

        <Col span={24}>
          <Row align='middle' gutter={[8, 8]}>
            <Col>
              <label className='fs-14 fw-4'>Declaro bajo protesta de decir verdad que la información y documentación proporcionada es verídica.</label>
            </Col>
            <Col>
              <Form.Item className='byp-m-0' name='acceptTermsAndConditions' rules={[required]}>
                <Radio.Group name='radiogroup' defaultValue={!canEdit ? 'yes' : 'no'} disabled={!canEdit}>
                  <Radio value='yes' className='radio_btn-quote'>Sí</Radio>
                  <Radio value='no' className='radio_btn-quote'>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <p className='fs-14 fw-4'>
          Al darle clic Guardar estoy aceptando los términos y condiciones de BYP. Ver <a className='text-black underline' href={URL_ABOUT_TERMS_AND_CONDITIONS_PAGE}>Términos y Condiciones</a> y <a className='text-black underline' href={URL_ABOUT_POLICY_PAGE}>Aviso de Privacidad</a>
        </p>
        <Button
          htmlType='submit'
          className='byp-mt-1 btn__gray br-06 fs-12 fw-5'
          size='large'
          loading={isSaving || isUpdating}
          disabled={!canEdit}
        >Guardar
        </Button>
      </Form>
    </>
  )
}

export default FormAplication
