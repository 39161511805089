import { Button, Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useGetCourseForPublish } from '../../../../../bussines/hooks/school/course/useGetCourseForPublish'
// import { useGetCampus } from '../../../../../bussines/hooks/school/useGetCampus'
import { PackageInfo } from './PackageInfo'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { CompletedCampusCourseYear } from '../../../../../common/components/helper-form/CompletedCampusCourseYear'
import { ModalPublishQuote } from '../../../../../common/components/ModalPublishQuote'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'

function PackageInfoContainer ({ category }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const { campusId: sedeId, courseId: serviceId, yearId: yearID } = location.state ? location.state : JSON.parse(localStorage.getItem('campus')) ? JSON.parse(localStorage.getItem('campus')) : { campusId: undefined, courseId: undefined, yearId: undefined }

  const [campusId, setCampusId] = useState()
  const [activeKeys, setActiveKeys] = useState(['base_prices'])

  const { data: course, isLoading, refetch } = useGetCourseForPublish(sedeId, serviceId, yearID)
  // const { data: campus } = useGetCampus(campusId)

  useEffect(() => {
    if (!isLoading && course) {
      const keys = ['course_prices']
      course?.lodgings?.map((lodging) => (
        lodging.status !== 'Aproved' ? keys.push(lodging.id) : null
      ))
      course?.transports?.map((transport) => (
        transport.status !== 'Aproved' ? keys.push(transport.id) : null
      ))
      setActiveKeys(keys)
      setCampusId(course.campus?.id)
    }
  }, [isLoading, course])

  const onClick = (url, state) => {
    navigate(url, {
      state: {
        campusId,
        yearId: yearID,
        ...state
      }
    })
  }

  if (isLoading) {
    return <BasicSpinner style={{ height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
  }
  return (
    <div style={{ width: '100%' }}>
      <CompletedCampusCourseYear course={course} campus={course?.campus?.name} program={course?.program.label} />
      <Row gutter={24}>
        <Col xs={24} md={18} lg={20} />
      </Row>
      {course && (
        <Row>
          <Col>
            <p style={{
              color: '#000000',
              lineHeight: '22px',
              fontSize: '14px',
              fontWeight: '700'
            }}
            >
              Confirm that every block is correct before publishing your quote
            </p>
          </Col>
        </Row>
      )}
      {course && (
        <PackageInfo
          onClick={onClick}
          course={course}
          yearId={yearID}
          divisa={course.campus?.divisa_code}
          activeKeys={activeKeys}
          setActiveKeys={setActiveKeys}
          category={category}
        />
      )}
      {course && (
        <div className='w-100' style={{ padding: '1rem' }}>

          <Button
            onClick={() => setIsModalOpen(true)}
            className='float-right byp-ml-1 byp-btn-blue-100'
          >
            {course.status === 'Published' ? 'Cancel publication' : 'Publish'}
          </Button>
          <ModalPublishQuote
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            sede={sedeId}
            course={serviceId}
            year={yearID}
            onSuccess={refetch}
          />
          <Link
            to={{
              pathname: `/package-course/${serviceId}/${campusId}`,
              state: {
                campusId,
                programId: course?.program_id,
                yearId: yearID
              }
            }}
          >
            <Button
              className='byp-btn-blue-200 float-right'
            >
              Preview
            </Button>
          </Link>
        </div>

      )}
    </div>
  )
}

export { PackageInfoContainer }
