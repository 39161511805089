import { useQuery } from 'react-query'
import AgencyRepository from '../../../repositories/AgencyRepository'

function useGetAgencyInstitutions (filters = {}) {
  const allFilters = { ...filters }
  const filtersQuery = Object.keys(allFilters)
    .map((key) => key + '=' + allFilters[key])
    .join('&')
  console.log(filtersQuery)
  return useQuery(['useGetAgencyInstitutions', filtersQuery], () =>
    AgencyRepository.getAgencyInstitutions(filtersQuery)
  )
}
export { useGetAgencyInstitutions }
