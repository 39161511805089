import {
  // parseISO,
  format, isDate, parseISO
} from 'date-fns'
import { es } from 'date-fns/locale'

// the date string needs to be in the m/d/Y format
/**
 *
 * @param {*} dateString
 * @param {*} formatDate dd - LLLL - yyyy
 * @param {*} locale
 * @returns Date | String
 */
const formatStringDate = (dateString, formatDate, locale = es) => {
  try {
    if (isDate(dateString)) {
      return format(dateString, formatDate, { locale })
    }
    if (!dateString) return null

    // Try to parseISO
    let parsed
    parsed = parseISO(dateString)
    if (isNaN(parsed.getTime())) parsed = dateString

    const date = new Date(parsed)
    return format(date, formatDate, { locale })
  } catch (error) {
    // console.log('🚀 ~ formatStringDate ~ dateString, formatDate:', dateString, formatDate)
    console.log(error)
    return dateString
  }
}

export { formatStringDate }
