import { Button, Col, Form, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { UploadImage } from './components/UploadImage'
import { usePostPublicity } from '../../../bussines/hooks/publicity/useSavePublicity'
import moment from 'moment'
import { useAuth } from '../../../providers/auth/auth-context-provider'

function PublicityInfo () {
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [text, setText] = useState(null)
  const [form] = Form.useForm()
  const { savePublicity, isLoading } = usePostPublicity()
  const { user: _user } = useAuth()

  useEffect(() => {
    if (_user && _user?.publicityUpdate && _user?.publicityUpdate?.length > 0) {
      const currentValues = form.getFieldsValue(['publicity'])
      const fieldName = 'publicity'
      if (_user?.publicityUpdate[0]?.title) currentValues[fieldName][0].title = _user?.publicityUpdate[0].title
      if (_user?.publicityUpdate[0]?.link) currentValues[fieldName][0].link = _user?.publicityUpdate[0].link
      if (_user?.publicityUpdate[0].id) currentValues[fieldName][0].id = _user?.publicityUpdate[0].id

      if (_user?.publicityUpdate[0]?.expirate_at) {
        const date = _user?.publicityUpdate[0]?.expirate_at?.slice(0, 10)
        currentValues[fieldName][0].expired_date = moment(date, 'YYYY-MM-DD')
        const time = _user?.publicityUpdate[0]?.expirate_at?.slice(11, 16)
        currentValues[fieldName][0].expired_time = moment(time, 'HH:mm')
      }

      if (_user?.publicityUpdate[0]?.title) setText(_user?.publicityUpdate[0].title)
      if (_user?.publicityUpdate[0]?.src) setImage(_user?.publicityUpdate[0].src)
      if (_user?.publicityUpdate[0]?.name) setImageUrl(_user?.publicityUpdate[0].name)
    }
  }, [_user])

  const onFinish = (values) => {
    values.publicity[0].time = values?.publicity[0]?.expired_time?.format('HH:mm')
    savePublicity(values)
  }

  return (
    <Row className='publicity'>
      <Col span={24}>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            publicity: [{}]
          }}
        >
          <Row gutter={[16, 16]}>
            <Col md={12} className='publicity__section'>
              <h4>Banner</h4>
              <h5>Añade publicidad al inicio</h5>
              <span>(Más de 1 image, aparecerá como un carrusel que cambia automáticamente)</span>
              <span style={{ marginBottom: '1rem' }}>4 de 8 añadidas</span>

              <UploadImage
                setImage={setImage}
                setImageUrl={setImageUrl}
                image={image}
                imageUrl={imageUrl}
                setText={setText}
                form={form}
              />

              <Row justify='end'>
                <Col>
                  <Button
                    htmlType='submit'
                    loading={isLoading}
                    className='publicity__submit'
                  >
                    Guardar
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col md={12} className='publicity__section'>
              <h5>Vista Previa</h5>
              <div>
                <div className='publicity-info__image'>
                  {image && (
                    <>
                      <img src={image} alt='publicity' />
                      <div className='publicity-info__info'>
                        <p>{text ?? 'Descubre el mundo, y aprende en el proceso'}</p>
                        <p>1,925 diferentes cursos</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default PublicityInfo
