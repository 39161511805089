import { useQuery } from 'react-query'
import PageRepository from '../../repositories/PageRepository'

function useGetServices (filters = {}, enabled = true) {
  return useQuery(['useGetServices', filters],
    () => PageRepository.getServices(filters), {
      retry: 2, enabled
    })
}

function useGetServicesById (idSercices) {
  return useQuery(['useGetServicesById', idSercices],
    () => PageRepository.getServicesById(idSercices), {
      retry: 2
    })
}

function useGetUsersByPage (idSercices) {
  return useQuery(['useGetUsersByPage', idSercices],
    () => PageRepository.getUsersByPage(idSercices), {
      retry: 2
    })
}

function useGetInstitutions (filters = {}) {
  return useQuery(['useGetInstitutions', filters],
    () => PageRepository.getInstitutions(filters), {
      retry: 2
    })
}

export { useGetServices, useGetServicesById, useGetUsersByPage, useGetInstitutions }
