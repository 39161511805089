import { Col, Row } from 'antd'
import { PageProtected } from '../../../common/components/PageProtected'
import { MainTheme } from '../../../common/theme/MainTheme'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { TableBlogs } from './components/TableBlogs'

const URL_TABLE_BLOGS_PAGE = '/table/blogs'

function PageTableBlogs() {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_AGENCY
      ]}
    >
      <MainTheme
        showMenu
      >
        <div className=' byp-align-centered'>
          <Row style={{ width: '100%', padding: '2.5rem' }}>
            <Col span={24}>
              <TableBlogs />
            </Col>
          </Row>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { PageTableBlogs, URL_TABLE_BLOGS_PAGE }
