import { Col, Row } from 'antd'
import { PublicTheme } from '../../common/theme/PublicTheme'
import { HomeSectionJ } from '../home/components/homeSectionJ'
import { HomeSectionK } from '../home/components/homeSectionK'
import header from './../../common/assets/images/banner-hero.png'
import { ConstactUsForm } from './components/ContactUsForm'

const URL_ABOUT_PAGE = '/contact'

function AboutPage() {
  return (
    <PublicTheme>
      <div style={{ display:'flex', justifyContent:'center', margin:'0 40px' }}>
        <img src={header} style={{ width: '90vw', height: 'auto', objectFit: 'cover', borderRadius: '.6rem' }} alt='' />
      </div>
      <div className='byp-centered text-about-us-responsive'>
        <h2 className='byp-yellow-100'><b>About us!</b></h2>
        <p>
          Lorem ipsum dolor sit amet consectetur. Odio vel sit proin orci feugiat. Venenatis duis pellentesque eget tempor vivamus lorem justo. Purus vitae quam urna tortor bibendum nec faucibus felis. Amet aliquet volutpat feugiat sed id velit adipiscing. Et et ut ut at blandit vitae arcu id. Ut vitae et vel quis. Vestibulum interdum pellentesque ut gravida erat. Nunc adipiscing et interdum eget. Vestibulum egestas aliquet id nulla. Viverra tincidunt id ultrices aliquet convallis faucibus. Ultrices pellentesque mattis nulla gravida odio integer fermentum.
        </p>
        <p>
          Arcu diam massa sit nullam id. Auctor sit penatibus viverra eu turpis. Adipiscing mauris adipiscing amet etiam tellus non ipsum lectus tincidunt. Arcu urna rhoncus sit ut dictum vestibulum pellentesque lectus suscipit. Sit orci sagittis purus fermentum habitant condimentum ultricies. Euismod in aliquam duis lorem lectus quis fames dolor. Lorem ipsum dolor sit amet consectetur. Odio vel sit proin orci feugiat. Venenatis duis pellentesque eget tempor vivamus lorem justo. Purus vitae quam urna tortor bibendum nec faucibus felis. Amet aliquet volutpat feugiat sed id velit adipiscing. Et et ut ut at blandit vitae arcu id. Ut vitae et vel quis. Vestibulum interdum pellentesque ut gravida erat. Nunc adipiscing et interdum eget. Vestibulum egestas aliquet id nulla. Viverra tincidunt id ultrices aliquet convallis faucibus. Ultrices pellentesque mattis nulla gravida odio integer fermentum.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur. Odio vel sit proin orci feugiat. Venenatis duis pellentesque eget tempor vivamus lorem justo. Purus vitae quam urna tortor bibendum nec faucibus felis. Amet aliquet volutpat feugiat sed id velit adipiscing. Et et ut ut at blandit vitae arcu id. Ut vitae et vel quis. Vestibulum interdum pellentesque ut gravida erat. Nunc adipiscing et interdum eget. Vestibulum egestas aliquet id nulla. Viverra tincidunt id ultrices aliquet convallis faucibus. Ultrices pellentesque mattis nulla gravida odio integer fermentum.
        </p>
        <Row className='w-100 byp-mt-1' gutter={24}>
          <Col xs={24} md={12} style={{ padding: '1rem' }} className='center-responsive'>
            <h2 className='byp-yellow-100' ><b>Contact us!</b></h2>
            <p >
              If you want to contact us for any questions or have a meeting to learn more about our platform, please feel free to contact us.
            </p>
          </Col>
          <Col xs={24} md={12}>
            <ConstactUsForm />
          </Col>
        </Row>
      </div>
      <HomeSectionJ />
      <HomeSectionK />
    </PublicTheme>
  )
}

export { AboutPage, URL_ABOUT_PAGE }
