import React, { useState, useEffect } from 'react'
import PlaceIcon from '@material-ui/icons/Place'
import { Form, Input, Select, Upload } from 'antd'
import { Link } from 'react-router-dom'
import { getBase64, headersForUpload } from '../../../../utils/uploadImageUtils'
import { API_URL } from '../../../../config'
// import { useGetCountriesWithOffers } from '../../../../bussines/hooks/places/useGetCountriesWithOffers'
import { useGetCitiesAndCountries } from '../../../../bussines/hooks/places/useGetCitiesWithOffers'
import { URL_TABLE_COUNTRY_CITIES_PAGE } from '../../tables-pages/TableCountryCitiesPage'

const { Option } = Select

function EditCitiesAndCountries({ image, setImage, setTitleCity, setIconCityId, setCities, citiesIds, setSubTitleCity }) {
  const [options, setOptions] = useState([])
  const { data, isLoading } = useGetCitiesAndCountries()

  useEffect(() => {
    if (data && !isLoading) {
      const countries = data.countries?.map((country) => ({
        id: country.id,
        value: country.name,
        iso: country.iso3,
        type: 'country'
      }))
      const cities = data.cities?.map((city) => ({
        id: city.id,
        value: city.name,
        iso: city.country?.iso3,
        type: 'city'
      }))
      setOptions([
        ...countries, ...cities
      ])
    }
  }, [isLoading, data])

  const handleChangeCities = (value) => {
    if (value) {
      const cities = []
      const countries = []
      value?.forEach(item => {
        const arrayItem = item.split('-')
        if (arrayItem[1] === 'country') {
          const country = data?.countries?.find(i => i.id === parseInt(arrayItem[0]))
          if (country) countries.push(country)
        }
        if (arrayItem[1] === 'city') {
          const city = data?.cities?.find(i => i.id === parseInt(arrayItem[0]))
          if (city) cities.push(city)
        }
      })
      setCities([...cities, ...countries])
    }
  }

  const handleChange = (event) => {
    if (event.file.status === 'done') {
      getBase64(event.file.originFileObj, (imageUrl) => {
        setImage(imageUrl)
        // console.log('🚀 ~ file: FormEditBlog.js:44 ~ getBase64 ~ imageUrl:', imageUrl)
        setIconCityId(event.file.response.id)
        // console.log('🚀 ~ file: FormEditBlog.js:46 ~ getBase64 ~ event.file.response:', event.file.response)
      })
    } else if (event.file.status === 'error') {
      console.log('🚀 ~ file error:', event.file.response)
    }
  }

  useEffect(() => {
    if (citiesIds) {
      handleChangeCities(citiesIds)
    }
  }, [citiesIds, data])

  return (
    <div className='edit-home__section'>
      <h4>Sección ciudades</h4>
      <span>Icon</span>

      <label className='edit-home__section-icon byp-mb-1' htmlFor='icon__city'>
        {image ? <img src={image} /> : <PlaceIcon />}
      </label>

      <div style={{ display: 'none' }}>
        <Upload
          name='image'
          listType='picture-card'
          className='upload-page__img'
          showUploadList={false}
          action={`${API_URL}/pages/images`}
          id='icon__city'
          onChange={event => handleChange(event)}
          headers={headersForUpload()}
        >
          <img src={image ?? ''} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
        </Upload>
      </div>

      <span>Título</span>
      <Form.Item
        className='edit-home__form-item'
        name='title_city'
      >
        <Input onChange={e => setTitleCity(e.target.value)} />
      </Form.Item>

      <span>Subtítulo</span>
      <Form.Item
        className='edit-home__form-item'
        name='subtitle_city'
      >
        <Input onChange={e => setSubTitleCity(e.target.value)} />
      </Form.Item>

      <span>Ciudades a mostrar</span>
      <Form.Item
        className='edit-home__form-item input-margin-0'
        name='cities'
      >
        <Select
          mode='multiple'
          optionFilterProp='children'
          onChange={handleChangeCities}
        >
          {options.map(option => <Option key={option.id + '-' + option.value} value={option.id + '-' + option.type}>{option.value}</Option>)}
        </Select>
      </Form.Item>

      <Link to={URL_TABLE_COUNTRY_CITIES_PAGE}>
        <a className='edit-home__link'>Dar de alta una ciudad</a>
      </Link>
    </div>
  )
}

export default EditCitiesAndCountries
