import api from '../../services/api'

class OpinionRepository {
  async saveOpinion (data) {
    const response = await api.post('opinions', data)
    return response.data
  }

  async findOpinion (params) {
    const response = await api.get('opinions', { params })
    return response.data
  }

  async opinionsList (params) {
    const response = await api.get('opinions/list', { params })
    return response.data
  }
}

export default new OpinionRepository()
