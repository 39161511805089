import { Button, Form, Input, Modal, Row, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useSaveCollection } from '../../bussines/hooks/collections/useSaveCollection'

const ModalCreateCollection = ({ onFinish }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const { saveCollection, isLoading, error, currentState, state } = useSaveCollection()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleOnFinish = (values) => {
    console.log('🚀 ~ file: ModalCreateCollection.js:18 ~ handleOnFinish ~ values:', values)
    saveCollection(values)
    form.resetFields();
  }

  useEffect(() => {
    if (!isLoading && currentState === state.STATE_SUCCESS) {
      notification.success({
        message: 'New Collection created'
      })
      setIsModalOpen(false)
      if (onFinish) onFinish()
    } else if (!isLoading && currentState === state.STATE_ERROR) {
      notification.error({
        message: error?.response?.data?.message ?? 'Algo salió mal, por favor vuelve a intentar'
      })
    }
  }, [isLoading, currentState])

  return (
    <>
      <Button onClick={showModal} style={{ color: '#373737', border: 'transparent' }}>Nueva colección</Button>
      <Modal
        style={{ padding: '0 10px' }} onCancel={handleCancel} visible={isModalOpen}
        footer={
          <Row style={{ justifyContent: 'space-between' }}>
            <Button
              onClick={handleCancel}
              style={{ borderColor: '#5D5D5D', color: '#5D5D5D', fontWeight: '500', borderWidth: '2px', backgroundColor: '#F4F4F4' }}
            >Cancelar
            </Button>
            <Button
              onClick={() => handleOnFinish(form.getFieldsValue())}
              loading={isLoading}
              style={{ borderColor: '#5D5D5D', color: '#f4f4f4', fontWeight: '500', backgroundColor: '#5D5D5D' }}
            >Guardar
            </Button>
          </Row>
        }
      >
        <Form onFinish={handleOnFinish} form={form} onFinishFailed={() => console.log('FAILEDD')}>
          <p>Nueva Colección</p>
          <Form.Item name='name'>
            <Input placeholder='Nombra tu colección' size='large' showCount maxLength={20} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export { ModalCreateCollection }