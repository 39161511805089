import { useQuery } from 'react-query'
import OfferRespository from '../../repositories/OfferRespository'

function useGetOfferFiltersByProgram (filters = {}, enabled = true) {
  return useQuery(['useGetOfferFiltersByProgram', filters],
    () => OfferRespository.offerFilters(filters), {
      retry: 2,
      enabled
    })
}

export { useGetOfferFiltersByProgram }
