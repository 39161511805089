import { addWeeks } from 'date-fns'
import { programIntakes } from '../languageConfiguration/infoCourseTranslate'
import { calcularIntakeFinal, getIntakeBaseMonth, getIntakeOfMonth } from './dateUtils'

const intakes = ['Spring', 'Summer', 'Fall', 'Winter']

function convertirFecha2 (intake, yearStart, duration) {
  const parts = duration.split(' ')
  const yearEnd = Number(parts[0]) + Number(yearStart)

  // Sumar dependiendo del tipo de duración
  switch (parts[1]) {
    case 'months':
      return sumarMeses(intake, Number(parts[0]), yearStart)
    case 'days':
      return sumarDias(intake, Number(parts[0]))
    case 'years':
      if (intakes.includes(intake)) {
        const intakeF = programIntakes.es[intake] ?? intake
        return intakeF + ' ' + yearEnd
      }
      return `${intake} ${yearEnd}`
    case 'weeks':
      return sumarSemanas(intake, Number(parts[0]), yearStart)
    case 'semester':
      return sumarSemestres(intake, yearEnd, Number(parts[0]), yearStart)
    default:
      return 'Duración no reconocida'
  }
}

// Función para sumar meses
function sumarMeses (intake, meses, year) {
  if (intakes.includes(intake)) {
    return calcularIntakeFinal(intake, meses, year)
  }
  const date = new Date(intake)
  date.setMonth(date.getMonth() + meses)
  const newYear = date.getFullYear()
  const newMonth = date.getMonth() + 1
  const newDay = date.getDate()
  return `${newMonth}/${newDay}/${newYear}`
}

// Función para sumar días
function sumarDias (intake, dias, year) {
  if (intakes.includes(intake)) {
    return intake + ' ' + year
  }
  const date = new Date(intake)
  date.setDate(date.getDate() + dias)
  const newYear = date.getFullYear()
  const newMonth = date.getMonth() + 1
  const newDay = date.getDate()
  return `${newDay}/${newMonth}/${newYear}`
}

// Función para sumar semanas
function sumarSemanas (intake, semanas, year) {
  if (semanas > 3 && intakes.includes(intake)) {
    const intakeMonth = getIntakeBaseMonth(intake.toLocaleLowerCase())
    const intakeDate = new Date(year + '/' + intakeMonth + '/01')
    const intakeEndDate = addWeeks(intakeDate, semanas)
    console.log('🚀 ~ sumarSemanas ~ intakeEndDate:', intakeEndDate)
    const endMonth = intakeEndDate.getMonth()
    const intakeEnd = getIntakeOfMonth(endMonth)
    return intakeEnd + ' ' + intakeEndDate.getFullYear()
  }
  return sumarDias(intake, semanas * 7, year)
}

// Función para sumar semestres
function sumarSemestres (intake, semestres, yearStart) {
  const mesesPorSemestre = 6
  return sumarMeses(intake, semestres * mesesPorSemestre, yearStart)
}

export { convertirFecha2, sumarSemanas }
