import { useQuery } from 'react-query'
import PageRepository from '../../repositories/PageRepository'

function useGetCountryPage (code, filters = {}) {
  const params = { type: filters.city ? 'city' : filters.search ? 'both' : 'country', search: code, ...filters }
  return useQuery(
    ['useGetCountryPage', params],
    () => PageRepository.searchPage(params),
    {
      retry: 1,
      enabled: !!code || !!filters.search
    }
  )
}

export { useGetCountryPage }
