import React from "react";
import { Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QuoterPage, URL_QUOTER_PAGE } from "../pages/quoter";
// import { QuoterPageStatic, URL_QUOTER_STATIC_PAGE } from '../pages/quoter/quote2'
import history from "./route-history";

import { PolicyPage, URL_POLICY_PAGE } from "../pages/register/PolicyPage";

import {
  EditAgencyProfilePage,
  URL_EDIT_AGENCY,
} from "../pages/agency/EditAgencyProfilePage";
import { QuotePreview, URL_QUOTE_PREVIEW } from "../pages/quote-preview";
import {
  QuotePreviewApply,
  URL_QUOTE_APPLY_PREVIEW,
} from "../pages/quote-preview-apply";
import {
  UserRegister,
  URL_USER_REGISTER,
} from "../pages/register/UserRegister";
import {
  InvitationRegister,
  URL_INVITATION_REGISTER_PAGE,
} from "../pages/register/InvitationRegister";
import {
  ContinueRegister,
  URL_CONTINUE_REGISTRATION,
} from "../pages/register/ContinueRegister";
import { DashboardPage, URL_DASHBOARD_PAGE } from "../pages/dashboards";

import {
  GeneralAnalysisPage,
  URL_GENERAL_ANALYSIS_PAGE,
} from "../pages/dashboards/agency/analitics/general/GeneralAnalysisPage";
import {
  UserAnalysisPage,
  URL_USER_ANALYSIS_PAGE,
} from "../pages/dashboards/agency/analitics/user/UserAnalysisPage";

import {
  EditUserProfilePage,
  URL_EDIT_USER,
} from "../pages/user/EditUserProfilePage";
import { MyQuotesPage, URL_MYQUOTES_PAGE } from "../pages/my-quotes";
// import { UploadDocumentsPage, URL_UPLOAD_DOCUMENTS_PAGE } from '../pages/dashboards/Institution/Documents/UploadDocumentsPage'
import {
  CrudUserPage,
  URL_CREATE_USERS_PAGE,
  URL_CREATE_USERS_PAGE_ID,
} from "../pages/dashboards/users/CrudUserPage";
import {
  CrudOfficePage,
  URL_CREATE_OFFICES_PAGE,
} from "../pages/dashboards/offices/CrudOfficePage";
import {
  CurrenciesPage,
  URL_CURRENCIES_PAGE,
} from "../pages/dashboards/currencies";
import {
  CreateInsurancePage,
  URL_CREATE_INSURANCE_PAGE,
} from "../pages/dashboards/agency/insurances/CreateInsurancePage";
import {
  InsurancePricesPage,
  URL_CREATE_INSURANCE_PRICES_PAGE,
} from "../pages/dashboards/agency/insurancePrices/InsurancePricesPage";
import {
  CreateAdditionalServices,
  URL_UPLOAD_ADD_SERVICES_PAGE,
} from "../pages/dashboards/agency/services/CreateAdditionalServices";
import {
  CreatePricesAdditionalServices,
  URL_UPLOAD_PRICES_ADD_SERVICES,
} from "../pages/dashboards/agency/services/CreatePricesAdditionalServices";
import {
  ServiceStatusPage,
  URL_SERVICES_STATUS_PAGE,
} from "../pages/dashboards/agency/service-status/ServiceStatusPage";
import {
  AproveInsurancePage,
  URL_APROVE_INSURANCE_PAGE,
} from "../pages/dashboards/agency/aprove/insurances/AproveInsurancePage";
import {
  AproveServicePage,
  URL_APROVE_SERVICE_PAGE,
} from "../pages/dashboards/agency/aprove/services/AproveServicePage";
// import {UpdateModality,URL_UPDATE_MODALITY} from '../bussines/repositories/UpdateModality.js'
import {
  ForgotPasswordPage,
  URL_FORGOT_PASSWORD_PAGE,
} from "../pages/password-recover/ForgotPassword";
import {
  ResetPasswordPage,
  URL_RESET_PASSWORD_PAGE,
} from "../pages/password-recover/ResetPassword";
import { PageNotFound } from "../pages/errors/PageNotFound";
import {
  ProgramAnaliticsPage,
  URL_PROGRAM_ANALITCIS_PAGE,
} from "../pages/dashboards/agency/analitics/program/ProgramAnaliticsPage";
import {
  OfficeAnaliticsPage,
  URL_OFFICE_ANALITCIS_PAGE,
} from "../pages/dashboards/agency/analitics/office/OfficeAnaliticsPage";
import {
  AboutPolicyPage,
  URL_ABOUT_POLICY_PAGE,
} from "../pages/about/PolicyPage";
import {
  AboutTermsAndConditionsPage,
  URL_ABOUT_TERMS_AND_CONDITIONS_PAGE,
} from "../pages/about/TermsAndConditionsPage";
import { AboutPage, URL_ABOUT_PAGE } from "../pages/about/AboutPage";
import {
  MiscellaneousPage,
  URL_CREATE_MISCELLANEOUS_PAGE,
} from "../pages/dashboards/agency/miscellaneous/MiscellaneousPage";
import {
  AboutTermsAndConditionsAgencyPage,
  URL_ABOUT_TERMS_AND_CONDITIONS_AGENCY_PAGE,
} from "../pages/about/TermsAndConditionsAgencyPage";
import {
  ReportsPage,
  URL_REPORTS_PAGE,
} from "../pages/dashboards/agency/reports/ReportsPage";
import {
  ReportsUniPage,
  URL_REPORTS_UNI_PAGE,
} from "../pages/dashboards/agency/reports/reports-unificado/ReportsUniPage";
// import { LoginABPage, URL_LOGIN_AB_PAGE } from '../pages/login-ab'
// import { RegisterABPage, URL_REGISTER_AB_PAGE } from '../pages/register-ab'
// import { RegisterABAgencyPage, URL_REGISTER_AGENCY_AB_PAGE } from '../pages/register-ab/RegisterABAgencyPage'
import {
  InstitutionsForAgencyPage,
  URL_INSTITUTIONS_FOR_AGENCY,
} from "../pages/agency/InstitutionsForAgencyPage";
// import { AgenciesAnaliticsPage, URL_USER_AGENCIES_PAGE } from '../pages/dashboards/Institution/analitics/AgenciesAnaliticsPage'
import {
  HomeSliderPage,
  URL_HOME_LANDER_PAGE,
} from "../pages/dashboards/home-slider/HomeSliderPage";
import {
  QuoterCoursePage,
  URL_QUOTE_COURSE_PAGE,
} from "../pages/quote-course/QuoterCoursePage";
// import { URL_QUOTE_COURSE_PAGE_EDIT, QuoterCoursePageEdit } from '../pages/quote-course'
import { TestPage, URL_TESTS_PAGE } from "../pages/test/TestPage";
// import { InvitationRegisterAB, URL_INVITATION_REGISTER_AB_PAGE } from '../pages/register/InvitationRegisterAB'
import {
  EduactionGroupStepByStepRegister,
  URL_STEP_BY_STEP_PAGE_EDUCATION_GROUP,
} from "../pages/register/EduactionGroupStepByStepRegister";
import { RegisterPage, URL_REGISTER_PAGE_BETA } from "../pages/register";
import {
  OpinionPage,
  URL_OPINION_CAMPUS_PAGE,
  URL_OPINION_PAGE,
} from "../pages/opinion/OpinionPage";
import { LoginPage, URL_LOGIN_PAGE } from "../pages/login";
import { BigHome, URL_BIG_HOME } from "../pages/home/big-home";
import {
  ProgramPage,
  URL_PROGRAM_PAGE,
  URL_PROGRAM_PREVIEW_PAGE,
} from "../pages/programs/programPage";
import {
  CountryPage,
  URL_CITY_PAGE,
  URL_CITY_PREVIEW_PAGE,
  URL_COUNTRY_PAGE,
  URL_COUNTRY_PREVIEW_PAGE,
  URL_SEARCH_PAGE,
} from "../pages/programs/CountryPage";
import {
  PackagesPage,
  URL_PACKAGES_PAGE,
} from "../pages/dashboards/packages/PackagesPage";
import {
  PackagesPricesPage,
  URL_PACKAGES_PRICES_PAGE,
} from "../pages/dashboards/packages/PackagesPricesPage";
import {
  URL_PACKAGES_PROMOTIONS_PAGE,
  UploadPromotionsPage,
} from "../pages/dashboards/packages/UploadPromotionsPage";
import {
  PromotionsRulesPage,
  URL_PACKAGES_PROMOTIONS_RULES_PAGE,
} from "../pages/dashboards/packagesPromotions/PromotionsRulesPage";
import {
  ActivateQuotesAgencyPage,
  URL_ACTIVATE_PACKAGE_AGENCY_PAGE,
} from "../pages/dashboards/agency/ActivationPackage/ActivatePackagePage";
import {
  ApprovePackagePage,
  URL_APPROVE_PACKAGE_PAGE,
} from "../pages/dashboards/agency/aprove/package/AprovePackage";
import {
  PackageCoursePage,
  URL_PACKAGE_COURSE_PAGE,
  URL_SHORT_COURSE_PAGE,
} from "../pages/quote-course-package";
import { FavoritePage, URL_FAVORITES } from "../pages/favorites/favoritePage";
import {
  EditPageProgramPage,
  URL_EDIT_PAGE_PROGRAM_PAGE,
} from "../pages/dashboards/edit-page/EditPageProgramPage";
import {
  EditPageCountryPage,
  URL_EDIT_PAGE_CITY_PAGE,
  URL_EDIT_PAGE_COUNTRY_PAGE,
} from "../pages/dashboards/edit-page/EditPageCountryPage";
import {
  TableCountryCitiesPage,
  URL_TABLE_COUNTRY_CITIES_PAGE,
} from "../pages/dashboards/tables-pages/TableCountryCitiesPage";
import {
  TableProgramPage,
  URL_TABLE_PROGRAMS_PAGE,
} from "../pages/dashboards/tables-pages/TableProgramsPage";
import {
  PageTableBlogs,
  URL_TABLE_BLOGS_PAGE,
} from "../pages/dashboards/blogs/PageTableBlogs";
import {
  PageEditBlog,
  URL_EDIT_BLOG_PAGE,
} from "../pages/dashboards/blogs/PageEditBlog";
import {
  PageBlog,
  URL_BLOG_PAGE,
  URL_BLOG_PREVIEW_PAGE,
} from "../pages/dashboards/blog/BlogPage";
import {
  PageBlogs,
  URL_BLOGS_BY_TAG_PAGE,
  URL_BLOGS_SEARCH_PAGE,
  URL_BLOGS_SEARCH_V2_PAGE,
} from "../pages/dashboards/blogs/PageBlogs";
import {
  EditHomePage,
  URL_EDIT_HOME_PAGE,
} from "../pages/dashboards/edit-page/EditHomePage";
import {
  PublicityPage,
  URL_PUBLICITY_PAGE,
} from "../pages/dashboards/publicity";
import { MyAccountPage, URL_USER_ACCOUNT } from "../pages/user/MyAccountPage";
import {
  PublicityCoursePage,
  URL_PUBLICITY_COURSE_PAGE,
} from "../pages/dashboards/publicityCourse";
import {
  ConfirmationPage,
  URL_CONFIRMATION_PAGE,
} from "../pages/confirmation/apartado";
import {
  ApplyConfirmation,
  URL_CONFIRMATION_PAGE2,
} from "../pages/confirmation/apply";
import { QuotesPage, URL_QUOTES_PAGE } from "../pages/quotes";
import {
  ConfirmationQuotePage,
  URL_CONFIRMATION_QUOTE_PAGE,
} from "../pages/confirmation-quote";
import {
  QuoteSummaryPage,
  URL_QUOTE_SUMMARY_PAGE,
} from "../pages/quote-summary";
import {
  AplicacionPage,
  URL_APLICACION_PAGE,
  URL_APLICACION_SHOW_PAGE,
} from "../pages/aplicacion";
import { MyCoursesPage, URL_USER_COURSES } from "../pages/user/MyCoursesPage";
import RedirectPage from "../pages/quote-course/components/RedirectPage";
import FormContent from "../pages/quote-course/FormContent";

const PageRoutes = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path='/' element={<BigHome />} />

       {/*<Route path={URL_BIG_HOME} element={<BigHome />} />*/}
       {/*<Route path="/" element={<Navigate to="/home?program=language&place=3&place_type=institution" replace />} />*/}
  
        <Route path={URL_QUOTER_PAGE} element={<QuoterPage />} />
        {/* <Route path={URL_LOGIN_AB_PAGE} element={<LoginABPage />} /> */}
        <Route path={URL_COUNTRY_PAGE} element={<CountryPage />} />
        <Route path={URL_SEARCH_PAGE} element={<CountryPage />} />
        <Route path={URL_CITY_PAGE} element={<CountryPage />} />
        <Route path={URL_COUNTRY_PREVIEW_PAGE} element={<CountryPage />} />
        <Route path={URL_CITY_PREVIEW_PAGE} element={<CountryPage />} />
        <Route path={URL_PROGRAM_PAGE} element={<ProgramPage />} />
        <Route path={URL_PROGRAM_PREVIEW_PAGE} element={<ProgramPage />} />
        <Route path={URL_LOGIN_PAGE} element={<LoginPage />} />
        <Route path={URL_FAVORITES} element={<FavoritePage />} />
        <Route path={URL_REGISTER_PAGE_BETA} element={<RegisterPage />} />
        {/* <Route path={URL_USER_AGENCIES_PAGE}>
          <Route path='agencies' element={<AgenciesAnaliticsPage />} />
          <Route path='agencies/:agencyId' element={<AgenciesAnaliticsPage />} />
        </Route> */}
        <Route path={URL_HOME_LANDER_PAGE} element={<HomeSliderPage />} />
        <Route
          path={URL_INSTITUTIONS_FOR_AGENCY}
          element={<InstitutionsForAgencyPage />}
        />
        {/* <Route path={URL_QUOTER_STATIC_PAGE} element={<QuoterPageStatic />} /> */}
        <Route path={URL_QUOTE_COURSE_PAGE} element={<QuoterCoursePage />} />
        <Route path={URL_QUOTES_PAGE} element={<QuotesPage />} />
        {/* <Route path={URL_QUOTE_COURSE_PAGE_EDIT} element={<QuoterCoursePageEdit />} /> */}
        {/* <Route path={URL_REGISTER_AB_PAGE} element={<RegisterABPage />} /> */}
        {/* <Route path={URL_REGISTER_AGENCY_AB_PAGE} element={<RegisterABAgencyPage />} /> */}
  
        <Route path={URL_POLICY_PAGE} element={<PolicyPage />} />
  
        <Route
          path={URL_ABOUT_TERMS_AND_CONDITIONS_AGENCY_PAGE}
          element={<AboutTermsAndConditionsAgencyPage />}
        />


        <Route path={URL_EDIT_AGENCY} element={<EditAgencyProfilePage />} />
        <Route
          path={URL_STEP_BY_STEP_PAGE_EDUCATION_GROUP}
          element={<EduactionGroupStepByStepRegister />}
        />
        <Route path={URL_QUOTE_PREVIEW} element={<QuotePreview />} />
        <Route path={URL_QUOTE_APPLY_PREVIEW} element={<QuotePreviewApply />} />
        <Route path={URL_USER_REGISTER} element={<UserRegister />} />
        <Route path={URL_EDIT_USER} element={<EditUserProfilePage />} />
        <Route path={URL_USER_ACCOUNT} element={<MyAccountPage />} />
        <Route path={URL_USER_COURSES} element={<MyCoursesPage />} />
        {/* checar */}
        <Route
          path={URL_INVITATION_REGISTER_PAGE}
          element={<InvitationRegister />}
        />
        {/* checar */}
        {/* <Route
          path={URL_INVITATION_REGISTER_AB_PAGE}
          element={<InvitationRegisterAB />}
        /> */}
  
        {/* checar si se utiliza al parecer no */}
        <Route path={URL_CONTINUE_REGISTRATION} element={<ContinueRegister />} />
  
        <Route path={URL_DASHBOARD_PAGE} element={<DashboardPage />} />
        {/* <Route path={URL_UPLOAD_DOCUMENTS_PAGE} element={<UploadDocumentsPage />} /> */}
  
        <Route path={URL_CREATE_USERS_PAGE} element={<CrudUserPage />} />
        <Route path={URL_CREATE_USERS_PAGE_ID} element={<CrudUserPage />} />
        <Route path={URL_CREATE_OFFICES_PAGE} element={<CrudOfficePage />} />
        <Route path={URL_CURRENCIES_PAGE} element={<CurrenciesPage />} />
  
        <Route
          path={URL_UPLOAD_ADD_SERVICES_PAGE}
          element={<CreateAdditionalServices />}
        />
        <Route
          path={URL_UPLOAD_PRICES_ADD_SERVICES}
          element={<CreatePricesAdditionalServices />}
        />
  
        <Route
          path={URL_GENERAL_ANALYSIS_PAGE}
          element={<GeneralAnalysisPage />}
        />
        <Route path={URL_USER_ANALYSIS_PAGE} element={<UserAnalysisPage />} />
        <Route
          path={URL_PROGRAM_ANALITCIS_PAGE}
          element={<ProgramAnaliticsPage />}
        />
        <Route
          path={URL_OFFICE_ANALITCIS_PAGE}
          element={<OfficeAnaliticsPage />}
        />
        <Route path={URL_MYQUOTES_PAGE} element={<MyQuotesPage />} />
  
        <Route
          path={URL_CREATE_INSURANCE_PAGE}
          element={<CreateInsurancePage />}
        />
        <Route
          path={URL_CREATE_INSURANCE_PRICES_PAGE}
          element={<InsurancePricesPage />}
        />
        <Route path={URL_SERVICES_STATUS_PAGE} element={<ServiceStatusPage />} />
        <Route
          path={URL_CREATE_MISCELLANEOUS_PAGE}
          element={<MiscellaneousPage />}
        />
  
        <Route
          path={URL_APROVE_INSURANCE_PAGE}
          element={<AproveInsurancePage />}
        />
        <Route path={URL_APROVE_SERVICE_PAGE} element={<AproveServicePage />} />
  
        <Route path={URL_PACKAGES_PAGE} element={<PackagesPage />} />
        <Route path={URL_PACKAGES_PRICES_PAGE} element={<PackagesPricesPage />} />
        <Route
          path={URL_PACKAGES_PROMOTIONS_PAGE}
          element={<UploadPromotionsPage />}
        />
        <Route
          path={URL_PACKAGES_PROMOTIONS_RULES_PAGE}
          element={<PromotionsRulesPage />}
        />
        <Route
          path={URL_ACTIVATE_PACKAGE_AGENCY_PAGE}
          element={<ActivateQuotesAgencyPage />}
        />
        <Route path={URL_APPROVE_PACKAGE_PAGE} element={<ApprovePackagePage />} />
  
        <Route path={URL_PACKAGE_COURSE_PAGE} element={<PackageCoursePage />} />
        <Route path={URL_SHORT_COURSE_PAGE} element={<PackageCoursePage />} />
  
        <Route path={URL_FORGOT_PASSWORD_PAGE} element={<ForgotPasswordPage />} />
        <Route path={URL_RESET_PASSWORD_PAGE} element={<ResetPasswordPage />} />
  
        <Route path={URL_ABOUT_PAGE} element={<AboutPage />} />
        <Route path={URL_ABOUT_POLICY_PAGE} element={<AboutPolicyPage />} />
        <Route
          path={URL_ABOUT_TERMS_AND_CONDITIONS_PAGE}
          element={<AboutTermsAndConditionsPage />}
        />
        <Route path={URL_REPORTS_PAGE} element={<ReportsPage />} />
        {/* Reports are neccesaty in BYP? */}
        <Route path={URL_REPORTS_UNI_PAGE} element={<ReportsUniPage />} />
        {/* Blogs */}
        <Route path={URL_BLOG_PAGE} element={<PageBlog />} />
        <Route path={URL_BLOG_PREVIEW_PAGE} element={<PageBlog />} />
        <Route path={URL_BLOGS_BY_TAG_PAGE} element={<PageBlogs />} />
        <Route path={URL_BLOGS_SEARCH_PAGE} element={<PageBlogs />} />
        <Route path={URL_BLOGS_SEARCH_V2_PAGE} element={<PageBlogs />} />
  
        {/* tables pages */}
        <Route
          path={URL_TABLE_COUNTRY_CITIES_PAGE}
          element={<TableCountryCitiesPage />}
        />
        <Route path={URL_TABLE_PROGRAMS_PAGE} element={<TableProgramPage />} />
        <Route path={URL_TABLE_BLOGS_PAGE} element={<PageTableBlogs />} />
        <Route path={URL_EDIT_HOME_PAGE} element={<EditHomePage />} />
  
        {/* Edit pages */}
        <Route
          path={URL_EDIT_PAGE_COUNTRY_PAGE}
          element={<EditPageCountryPage />}
        />
        <Route path={URL_EDIT_PAGE_CITY_PAGE} element={<EditPageCountryPage />} />
        <Route
          path={URL_EDIT_PAGE_PROGRAM_PAGE}
          element={<EditPageProgramPage />}
        />
        <Route path={URL_EDIT_BLOG_PAGE} element={<PageEditBlog />} />
  
        {/* Publicity pages */}
        <Route path={URL_PUBLICITY_PAGE} element={<PublicityPage />} />
        <Route
          path={URL_PUBLICITY_COURSE_PAGE}
          element={<PublicityCoursePage />}
        />
  
        {/* Confirmation page */}
        <Route path={URL_CONFIRMATION_PAGE} element={<ConfirmationPage />} />
        <Route path={URL_CONFIRMATION_PAGE2} element={<ApplyConfirmation />} />
        <Route
          path={URL_CONFIRMATION_QUOTE_PAGE}
          element={<ConfirmationQuotePage />}
        />
  
        {/* Aplicacion page */}
        <Route path={URL_APLICACION_PAGE} element={<AplicacionPage />} />
        <Route path={URL_APLICACION_SHOW_PAGE} element={<AplicacionPage />} />
  
        {/* Summary page */}
        <Route path={URL_QUOTE_SUMMARY_PAGE} element={<QuoteSummaryPage />} />
  
        <Route path={URL_OPINION_PAGE} element={<OpinionPage />} />
        <Route path={URL_OPINION_CAMPUS_PAGE} element={<OpinionPage />} />
        {/* Test pages */}
        <Route path={URL_TESTS_PAGE} element={<TestPage />} />
  
        {/* Redirect page */}
        <Route path="/redirect/:shortCode" element={<RedirectPage />} />
        <Route path="/" element={<FormContent />} />
      </Routes>
    </Router>
  )
}

export { PageRoutes };
