import { useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Unauthorized } from "../errors/Unauthorized";
import { userRoles } from "../../bussines/settings/validate-user-access";
import { PageProtected } from "../../common/components/PageProtected";
import { MainTheme } from "../../common/theme/MainTheme";
import { QuoteCourseProvider } from "../../providers/quoter/quoter-course-context";
import { QuoterFormConfigured } from "./components/QuoterFormConfigured";
import { Col, Row } from "antd";
import FormContent from "./FormContent";
// import { QuoteFormDetails } from './components/QuoteFormDetails'
import { HeaderQuote } from "./components/HeaderQuote";
import { InfoQuote } from "./components/InfoQuote";
import { FooterQuote } from "./components/FooterQuote";

const URL_QUOTE_COURSE_PAGE = "/quote-course/:courseId/:campusId";
const QuoterCoursePage = () => {
  const [params] = useSearchParams();
  const baseQuoteId = params.get("quote") ?? undefined;
  const { courseId, campusId, yearId } = useParams();
  const location = useLocation();
  const { quoteId } = location.state ?? { quoteId: baseQuoteId };
  const [hasPermision, setHasPermision] = useState(true);
  const [image, setImage] = useState();
  const [link, setLink] = useState();
  const [hasComments, setHasComments] = useState(false);
  console.log("🚀 ~ QuoterCoursePage ~ hasComments:", hasComments);

  if (!hasPermision) {
    return <Unauthorized />;
  }

  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme>
        <QuoteCourseProvider
          courseId={courseId}
          campusId={campusId}
          yearId={yearId}
          quoteId={quoteId}
        >
          <QuoterFormConfigured>
            <Row gutter={[0, 16]}>
              <Col xs={24}>
                <Row gutter={[0, { xs: 16, md: 45 }]}>
                  {/* <Row gutter={[0,  16]}> */}
                  <Col xs={24}>
                    {/* GALERIA */}
                    <HeaderQuote />
                  </Col>

                  <Col xs={24}>
                    {/* BOTONES */}
                    <InfoQuote hasComments={hasComments} />
                  </Col>
                </Row>
              </Col>

              {/* CONTENIDO */}
              <Col xs={24} lg={20} id="qoute-course">
                <FormContent
                  setHasPermision={setHasPermision}
                  setImage={setImage}
                  setLink={setLink}
                />
              </Col>
              <Col xs={24} lg={4} className="byp-mb-1">
                {/* <QuoteFormDetails /> */}
                {image && (
                  <a href={link} target="_blank" rel="noreferrer">
                    <div className="quoter-course__publicity">
                      <img src={image} alt="" />
                    </div>
                  </a>
                )}
              </Col>
            </Row>

            {/* <Row gutter={16} className="quote-course">
              
            </Row> */}
            <FooterQuote
              setHasComments={setHasComments}
              hasComments={hasComments}
            />
          </QuoterFormConfigured>
        </QuoteCourseProvider>
      </MainTheme>
    </PageProtected>
  );
};
export { QuoterCoursePage, URL_QUOTE_COURSE_PAGE };
