import { Result, Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function NotFoundPage () {
  const navigate = useNavigate()
  return (
    <Result
      status='404'
      title='404'
      subTitle='Lo siento, la página no ha sido encontrada.'
      extra={<Button type='primary' onClick={() => navigate('/')}>Regresar al principal</Button>}
    />
  )
}

export { NotFoundPage }
