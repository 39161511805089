import { Col, Layout, Row, notification, Space } from 'antd'
import { PersonalizedSearcher } from '../../home/components/PersonalizedSearcher'
import PublicIcon from '@material-ui/icons/Public'
import { FilterCategory } from '../../quoter/components/FilterCategory'
// import PlaceIcon from '@material-ui/icons/Place'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import { ChooseCourse } from './choose-course'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
// import { LanguageCard } from '../../home/components/languageCard'
// import ReplyIcon from '@material-ui/icons/Reply'
import { useGetProgramPage } from '../../../bussines/hooks/page/useGetProgramPage'
import React, { useEffect, useState } from 'react'
import { CardOpinions } from '../../quote-course/components/CardOpinions'
import { useNavigate } from 'react-router-dom'
import { URL_PROGRAM_PAGE } from '../programPage'
import { CardPlacesWithText } from '../../../common/components/CardPlaces'
import { HomeCarrousel } from '../../../common/components/HomeCarrousel'
import imageDefault from '../../../common/assets/images/bg-hero.png'
import imageLanguage from '../../../common/assets/images/home-language.png'
// import AcademicYearsChooseCourse from './AcademicYearsChooseCourse'
// import { HEChooseCourse } from './HEChooseCourse'
import { useGetConfiguration } from '../../../bussines/hooks/big-home/useGetPackage'
import { IconCamping } from '../../quote-preview/assets/imgs/Icons'
import { CardWithImage } from '../../home/components/CardWithImage'
import { useGetRandomBlogs, useGetRandomCourses } from '../../../bussines/hooks/page/useGetRandomCourses'
import { CardBlogSimple } from '../../dashboards/blog/components/CardBlogSimple'

function ContentProgramPage ({ program, isPreview }) {
  const [programsSorted, setProgramsSorted] = useState(null)
  const { isLoading, data: programPage, isError, error } = useGetProgramPage(program)
  const { isLoading: isLoadingCourses, data: courses, isErrorCourses, error: errorCourses } = useGetRandomCourses(program)
  const { isLoading: isLoadingBlogs, data: blogs, isErrorBlogs, error: errorBlogs } = useGetRandomBlogs(program)
  const { data: programs } = useGetConfiguration()
  const navigate = useNavigate()
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [slides, setSlides] = useState(4)

  useEffect(() => {
    if (!isLoading && programPage) {
      // console.log('🚀 ~ file: CountryContent.js:20 ~ useEffect ~ programPage:', programPage)
    }
    if (!isLoading && isError) {
      notification.error({
        message: error?.response?.data?.message ?? 'Not Found'
      })
    }
  }, [isLoading])

  useEffect(() => {
    if (!isLoadingCourses && courses) {
      // console.log('🚀 ~ file: CountryContent.js:20 ~ useEffect ~ courses:', courses)
    }
    if (!isLoadingCourses && isErrorCourses) {
      notification.error({
        message: errorCourses?.response?.data?.message ?? 'Not Found'
      })
    }
  }, [isLoadingCourses])

  useEffect(() => {
    if (!isLoadingBlogs && blogs) {
      console.log('🚀 ~ file: CountryContent.js:20 ~ useEffect ~ Blogs:', blogs)
    }
    if (!isLoadingBlogs && isErrorBlogs) {
      notification.error({
        message: errorBlogs?.response?.data?.message ?? 'Not Found'
      })
    }
  }, [isLoadingBlogs])

  useEffect(() => {
    if (programs && programs?.configuration && programs?.configuration?.length > 0) {
      setProgramsSorted(JSON.parse(programs?.configuration[0]?.value))
    }
  }, [programs])

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (windowSize.width <= 800) {
      setSlides(2)
    } else {
      setSlides(4)
    }
  }, [windowSize])

  return (
    <div className='bg-bScreen'>
      <div className='layout-maxWidth'>
        <Space direction='vertical' className='space-content' size={[0, 50]}>
          {/** HERO + Search**/}
          <Layout style={{ background: 'transparent', margin: '30px 0 30px' }} className='search_mobile_hidden'>
            <div style={{ margin: '0 40px 0 40px' }}>
              <PersonalizedSearcher />
            </div>
          </Layout>

          {/** Contenido Dinamico **/}
          <Layout style={{ rowGap: '50px' }} className='layout-home layout_home_program'>

            {/** NO HACE NADA??
            {!isLoading && ((error?.response?.status === 404 || programPage?.status !== 'Published') && !isPreview) && (
              <>
                <Row justify='center' style={{ marginTop: '2rem' }}>
                  <Col xs={{ order: 2 }} sm={{ order: 2 }} md={{ order: 1, span: 12 }} className='content-program__left'>
                    <h4 style={{ color: '#373737' }} className='title-with-icon'><RecordVoiceOverIcon />Te ayudamos a elegir tu curso</h4>
                    <p style={{ color: '#797979' }} className='subtitle__big-home'>
                      En base a tu perfil. Vamos seleccionando la mejor opción para ti.
                    </p>
                    {program === 'language' && <ChooseCourse program={program} />}
                    {(program === 'academic_years' || program === 'boarding_schools') && <AcademicYearsChooseCourse program={program} />}
                    {['graduate_diploma', 'bachelor', 'postgraduate', 'master'].includes(program) && <HEChooseCourse program={program} />}
                  </Col>
                  <Col xs={{ order: 1, span: 24 }} sm={{ order: 1, span: 24 }} md={{ order: 2, span: 12 }} className='content-program__right'>
                    <div className='programs__img-responsive'>
                      {program !== 'language' && <img src={imageDefault} alt='students' />}
                      {program === 'language' && <img src={imageLanguage} alt='students' />}
                    </div>
                  </Col>
                </Row>
              </>
            )}
            **/}

            {/** Contenido de PROGRAMA seleccionado **/}
            {!isLoading && ((programPage && programPage?.status === 'Published') || isPreview) && (
              <>
                {/** CONTENIDO **/}
                <Layout style={{}} className='layout-home'>
                  <Row
                    justify='center' rowGap='40px'
                    style={{
                      marginLeft: '-1%',
                      marginRight: '3%'
                    }}
                  >
                    {/** TITULO **/}
                    <Col xs={{ order: 2 }} sm={{ order: 2 }} md={{ order: 1, span: 24 }} className='byp__program-title'>
                      <h1 className='byp-mb-1 byp-mt-1 byp__program-title'>{programPage?.title}</h1>
                    </Col>
                    {/** TEXTO **/}
                    <Col xs={{ order: 4 }} sm={{ order: 2 }} md={{ order: 1, span: 24 }} className='byp__program-body'>
                      <div dangerouslySetInnerHTML={{ __html: programPage?.body }} />
                    </Col>
                    {/** Buscador **/}
                    <Col xs={{ order: 3 }} sm={{ order: 2 }} md={{ order: 1, span: 12 }} className='content-program__left'>
                      <div className='title-with-icon'>
                        <RecordVoiceOverIcon className='icon_hidden_mobile' style={{ color: '#373737' }} />
                        <h4 style={{ color: '#373737' }} className='title-with-icon'>Conoce nuestras propuestas</h4>
                      </div>
                      <p style={{ color: '#797979' }} className='subtitle__big-home'>Conoce nuestras propuestas, cursos desde 2 semanas</p>
                      {/** NO HACE NADA
                      {program !== 'language' && <h4 style={{ color: '#373737' }} className='title-with-icon'><RecordVoiceOverIcon />Te ayudamos a elegir tu curso</h4>}
                      {program !== 'language' && (
                        <p style={{ color: '#797979' }} className='subtitle__big-home'>
                          En base a tu perfil. Vamos seleccionando el mejor programa a tu medida.
                        </p>
                      )}**/}

                      {/** Input **/}
                      <ChooseCourse program={program} />
                    </Col>
                    {/** IAMGEN **/}
                    <Col xs={{ order: 1, span: 24 }} sm={{ order: 1, span: 24 }} md={{ order: 2, span: 12 }} className='content-program__right'>
                      <div className='programs__img-responsive h-12-rem'>
                        {(program !== 'language' && program !== 'graduate_diploma' && program !== 'bachelor' && program !== 'master' && program !== 'postgraduate') && <img src={imageDefault} alt='students' />}
                        {(program === 'language' || program === 'graduate_diploma' || program === 'bachelor' || program === 'master' || program === 'postgraduate') && <img src={imageLanguage} alt='students' />}
                      </div>
                    </Col>

                  </Row>
                </Layout>

                {/** Opiniones **/}
                {/** <Layout style={{backgroundColor: 'transparent'}}>
                  <div>
                    <h4 style={{ color: '#373737', marginTop: '15px' }} className='title-with-icon'><PeopleAltIcon style={{ fontSize: '20px' }} />Nuestros estudiantes opinan</h4>
                    <p style={{ color: '#797979' }} className='subtitle__big-home'>No te vayas sin saber que opinan</p>
                  </div>
                  <div>
                    <HomeCarrousel slides={programPage?.opinions ?? []} Slide={CardOpinions} className='big-home__package big-home__carousel-responsive' slidesPerRow={slides} />
                    PARA CELULAR
                    <div className='big-home__cards-responsive-x snaps-inline'>
                      {programPage?.opinions?.map(item => (
                        <React.Fragment key={item.id}>
                          <CardOpinions item={item} />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </Layout>**/}

                {/** Recomendacion Cursos **/}
                <Layout style={{}} className='layout-home'>
                  {/** {program !== 'language' && <h4 style={{ color: '#373737' }} className='title-with-icon'><PlaceIcon />Los mejores lugares para estudiar</h4>}
                    *   {program !== 'language' && <p style={{ color: '#797979' }} className='subtitle__big-home'>Descubre el mundo</p>}**/}
                  <Row
                    justify='center'
                    style={{
                      marginLeft: '-1%',
                      marginRight: '3%'
                    }}
                  >
                    <Col span={24}>
                      <h4 style={{ color: '#373737' }} className='title-with-icon'><IconCamping color='#373737' width='20px' height='20px' />Nuestra recomendación de cursos</h4>
                      <p style={{ color: '#797979' }} className='subtitle__big-home'>Descubre el mundo estudiando</p>
                    </Col>
                    <Col span={24}>
                      {/* *{program !== 'language' && <HomeCarrousel slides={programPage?.countries ?? []} Slide={CardPlacesWithText} className='big-home__package big-home__carousel-responsive' slidesPerRow={slides} />}* */}
                      {(program === 'language' || program === 'graduate_diploma' || program === 'bachelor' || program === 'master' || program === 'postgraduate') && <HomeCarrousel slides={courses?.courses ?? []} Slide={CardWithImage} className='carrousel_height big-home__carousel-responsive' label />}
                      {program !== 'language' && (
                        <div className='big-home__cards-responsive-x snaps-inline'>
                          {programPage?.countries?.map(item => (
                            <React.Fragment key={item.id}>
                              <CardPlacesWithText item={item} />
                            </React.Fragment>
                          ))}
                        </div>)}
                      {(program === 'language' || program === 'graduate_diploma' || program === 'bachelor' || program === 'master' || program === 'postgraduate') && (
                        <div className='big-home__cards-responsive-x snaps-inline'>
                          {courses?.courses?.map(item => (
                            <React.Fragment key={item.id}>
                              <CardWithImage item={item} />
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Layout>

                {/** PROGRAMAS -- SELECCIONADO **/}
                <Layout style={{ rowGap: '16px' }} className='layout-home'>
                  <Row
                    justify='center'
                    style={{
                      marginLeft: '-1%',
                      marginRight: '3%'
                    }}
                  >
                    <Col span={24} style={{ justifyContent: 'space-between', color: '#373737' }}>
                      <h4 className='big-home__question'>¿Buscas otro programa?</h4>
                      <Row className='big-home__currency'>
                        {/* *<PublicIcon style={{ fontSize: '15px' }} /> Español (MXN) $MXN* */}
                      </Row>
                    </Col>
                    <Col span={24} className='btns__programs btns_bigHome '>
                      <FilterCategory
                        onlyMenu
                        program={program}
                        programsSorted={programsSorted}
                        onChange={(programValue) => {
                          navigate(URL_PROGRAM_PAGE.replace(':program', programValue))
                        }}
                        onlyProgram
                        setGhost
                      />
                    </Col>
                  </Row>
                </Layout>

                {/** Blog **/}
                <Layout style={{}} className='layout-home'>
                  <Row
                    justify='center'
                    style={{
                      marginLeft: '-1%',
                      marginRight: '3%'
                    }}
                  >
                    <Col span={24}>
                      <h4 style={{ color: '#373737' }} className='title-with-icon'><RecordVoiceOverIcon style={{ fontSize: '20px' }} />Blog</h4>
                      <p style={{ color: '#797979' }} className='subtitle__big-home'>Conoce más del mundo</p>
                    </Col>
                    {/* <Row>
                      <p style={{ color: '#797979' }} className='subtitle__big-home'>Conoce más del mundo</p>
                      <Button style={{ width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: 'transparent' }}>
                        <ReplyIcon style={{ transform: 'rotateY(180deg)' }} />
                      </Button>
                    </Row> */}
                    <Col span={24}>
                      <HomeCarrousel slides={blogs?.blogs ?? []} Slide={CardBlogSimple} className='big-home__package edit_carousel' slidesPerRow={3} />
                      {/* <div className='big-home__cards-responsive'>
                        {blogs?.blogs?.map((item, index) => (
                          <React.Fragment key={index}>
                            <CardBlogSimple item={item} />
                          </React.Fragment>
                        ))}
                      </div> */}
                    </Col>
                  </Row>
                </Layout>

              </>
            )}

            {(isLoading || ((programPage && programPage?.status !== 'Published') && !isPreview)) && (
              <div style={{ height: '60vh' }}>
                {/* Para cubrir toda la pantalla cuando no haya contenido */}
              </div>
            )}
          </Layout>

        </Space>
      </div>
    </div>
  )
}

export { ContentProgramPage }
