import { useQuery } from 'react-query'
import PageRepository from '../../repositories/PageRepository'

function useGetProgramPage (program, filters = {}) {
  const params = { type: 'program', search: program, ...filters }
  return useQuery(
    ['useGetProgramPage', params],
    () => PageRepository.searchPage(params),
    {
      retry: 1,
      enabled: !!program
    }
  )
}

export { useGetProgramPage }
