import { userRoles } from '../../bussines/settings/validate-user-access'
import { ValidateEnterpriseProfile } from '../../common/components/ValidateEnterpriseProfile'
import { MainTheme } from '../../common/theme/MainTheme'
import { SearchProvider } from '../../providers/search/search-context-provider'
import { BigHomeContent } from './components/BigHomeContent'

const URL_BIG_HOME = ''

function BigHome () {
  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme activeCarousel showMainTheme={false}>
        <SearchProvider>
          <BigHomeContent />
        </SearchProvider>
      </MainTheme>
    </ValidateEnterpriseProfile>
  )
}

export { BigHome, URL_BIG_HOME }
