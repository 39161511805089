import React, { useEffect, useState } from 'react'
import { Avatar, Button, Col, Form, Image, Input, Layout, Modal, PageHeader, Row, Statistic } from 'antd'
import { PackageQuoterForm } from './components/PackageQuoterForm'
import { usePackage } from '../../providers/package/package-course-context'
import '../../common/assets/css/switch-theme.less'
import moment from 'moment'
import { StarFilled, HeartOutlined, ShareAltOutlined, LinkOutlined } from '@ant-design/icons'
import { handlePrice } from '../../utils/getCountryName'
import { SelectCurrencyFormItem } from '../../common/components/helper-form/SelectCurrencyFormItem'
import { useGetCurrencyToCurrency } from '../../bussines/hooks/currencies/useGetCurrencyToCurrency'
import { useAuth } from '../../providers/auth/auth-context-provider'
import DiscountText from './components/DiscountText'
import { QuoteDivisa } from '../quote-course/components/QuoteDivisa'
import LogoFacebook from '../../common/assets/images/facebook_logo.png'
import LogoWhatsapp from '../../common/assets/images/whatsapp_logo.png'
import { image } from '../../utils/fallbackImage'

const { Content } = Layout
// const { Option } = Select

export default function FormContent({ setHasPermision, setImage, setLink }) {
  const {
    course, offerError, service, isOfferError, form, setFormConfig, formConfig,
    isLoadingPost, handleShare, submit, changeCurrency, setChangeCurrency,
    userLocalCurrency, setUserLocalCurrency, userCountryLocation, opinionsData, getLinkToShare
  } = usePackage()
  const [lenght, setLenght] = useState(0)
  const [extraCoin, setExtraCoin] = useState()
  const [isOpen, setIsOpen] = useState(true)
  const [GeneralOpinion, setGeneralOpinion] = useState()
  const { user: _user } = useAuth()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (offerError && isOfferError) {
      console.log('🚀 ~ file: index.js ~ line 94 ~ useEffect ~ offerError', offerError)
    }
  }, [offerError, isOfferError])

  useEffect(() => {
    if (!course?.is_active) {
      setHasPermision(false)
    }

    if (course?.offer?.start_at && course?.offer?.end_at) {
      const lenghtDate = new Date(course?.offer?.end_at) - new Date(course?.offer?.start_at)
      if (!course?.offer?.min_weeks && !course?.offer?.max_weeks) {
        const differenceWeeks = Math.ceil(lenghtDate / (1000 * 60 * 60 * 24 * 7))
        setLenght(differenceWeeks + ' weeks')
        form.setFieldsValue({
          start_at: handleFormatDate(course?.offer?.start_at),
          end_at: handleFormatDate(course?.offer?.end_at),
          weeks: differenceWeeks
        })
        setFormConfig({
          ...formConfig,
          weeks: differenceWeeks,
          courseStart: course?.offer?.start_at,
          courseEnd: course?.offer?.end_at
        })
      }
    }

    if (!course?.offer?.min_weeks && !course?.offer?.max_weeks) {
      setIsOpen(false)
    }
  }, [course])

  useEffect(() => {
    if (_user && _user?.publicityVertical && _user?.publicityVertical?.length > 0) {
      if (_user?.publicityVertical[0]?.src) setImage(_user?.publicityVertical[0]?.src)
      if (_user?.publicityVertical[0]?.link) setLink(addHttpsIfNotExist(_user?.publicityVertical[0]?.link))
    }
  }, [_user])

  const [title, setTitle] = useState(null)

  useEffect(() => {
    if (course) {
      const item = course?.offer
      if (item?.translations && item?.translations?.length > 0) {
        const label = item?.translations?.find(item => item?.to === 'es' && item?.attribute === 'label')
        setTitle(label?.translation)
      } else {
        setTitle(item?.name)
      }
    }
  }, [course])

  function addHttpsIfNotExist(url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    return url
  }

  useEffect(() => {
    if (opinionsData) {
      const general = opinionsData?.rates?.find((rate) => rate.type === 'general')
      setGeneralOpinion(general)
    }
  }, [opinionsData])

  const { data: newdata, getCurrencyToCurrency } = useGetCurrencyToCurrency()
  useEffect(() => {
    const getCurrency = async () => {
      getCurrencyToCurrency(userLocalCurrency, service.getCurrency())
    }
    getCurrency()
  }, [changeCurrency])

  const [isChange, setIsChange] = useState(false)
  useEffect(() => {
    const getCurrency = async () => {
      setChangeCurrency(!changeCurrency)
    }
    getCurrency()
  }, [])

  useEffect(() => {
    const getCurrency = async () => {
      if (userLocalCurrency) {
        setChangeCurrency(!changeCurrency)
      }
    }
    getCurrency()
  }, [userLocalCurrency])

  const handleClick = (value) => {
    setUserLocalCurrency(value)
    setIsChange(false)
  }

  const handleShareFacebook = () => {
    const urlACompartir = getLinkToShare()
    const facebookCompartirUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(urlACompartir)
    console.log('🚀 ~ handleShareFacebook ~ facebookCompartirUrl:', facebookCompartirUrl)

    // window.open(facebookCompartirUrl, '_blank', 'width=600,height=400')
    return facebookCompartirUrl
  }

  const handleShareWhatsapp = () => {
    let urlACompartir = getLinkToShare()
    urlACompartir = urlACompartir.replace(/{/g, '%7B').replace(/}/g, '%7D').replace(/"/g, '%22').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\[/g, '%5B').replace(/\]/g, '%5D')
    const mensaje = '¡Echa un vistazo a este enlace: ' + urlACompartir + '!'
    const whatsappCompartirUrl = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(mensaje)

    window.open(whatsappCompartirUrl, '_blank')
  }

  if (offerError && isOfferError) {
    return 'ups'
  }

  const handleFormatDate = (date) => {
    if (date) {
      const inputDate = moment(date, 'YYYY-MM-DD')?.locale('en')
      const outputDateStr = inputDate?.format('ddd, D MMM YYYY')

      return outputDateStr
    }
  }

  return (
    <PageHeader
      className='antd-card content-quoter'
      ghost={false}
      title={
        <Row>
          <Col>
            <h3 className='byp-m-0 cotizacion__title'>Revisa tu cotización</h3>
          </Col>
        </Row>
      }
      extra={[
        <small key='3' className='byp-m-0 cotizacion__rate'>
          <span><StarFilled />{GeneralOpinion?.rate?.toFixed(1)}</span> ({GeneralOpinion?.total_opinions ?? 0} calificaciones) {GeneralOpinion?.total_opinions ?? 0} estudiantes
        </small>
      ]}
    >
      {/* <p className='cotizacion__description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dignissim orci venenatis urna vulputate eleifend. Phasellus ut lorem nisi. Nunc porttitor eget lorem pharetra elementum. </p> */}
      <Row justify='space-between' align='middle' gutter={12}>
        <Row>
          <Col style={{ marginRight: '1rem' }}>
            <Avatar
              style={{ width: '2.813rem', height: '2.813rem' }}
              src={course?.offer?.campus?.profilePhotoUrl}
              icon={<img src={course?.offer?.campus?.parent?.profile_photo_url} alt='' />}
            />
          </Col>
          <Col className='byp-justify-centered'>
            <h4 className='byp-m-0 quote-course--title__responsive cotizacion__name-campus'>{course?.offer?.campus?.name}</h4>
          </Col>
        </Row>

        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <small key='3'>{course?.offer?.campus?.address}</small>
          {!isChange && (
            <h6 style={{ color: 'var(--gray-100)', display: 'flex', alignItems: 'center' }}>
              {/* <PublicIcon style={{ fontSize: '15px' }} />  */}
              {/* {userCountryLocation} ({userLocalCurrency}) ${userLocalCurrency} <Button onClick={e => setIsChange(true)}>Cambiar</Button> */}
            </h6>
          )}
          {isChange && (
            <div style={{ width: '200px' }}>
              <SelectCurrencyFormItem
                name='currency'
                size='medium'
                setDivisa={handleClick}
                sizeWidth='100%'
                style={{ width: '100%', margin: '0' }}
              />
            </div>
          )}
        </Col>
      </Row>

      <h3 className='byp-title byp-text-md'>{course?.offer?.campus?.group_name}</h3>
      <h3 className='cotizacion__name-offer'>{title}</h3>
      {
        (!isOpen && course?.offer?.type_course !== 'package') && (
          <>
            <Row justify='space-between'>
              <Col>
                <p>Tipo de programa: <span style={{ fontWeight: '700' }}>{course?.offer?.program?.label}</span></p>
              </Col>
              <Col>
                <p><span>Longitud: </span><b>{lenght}</b></p>
              </Col>
            </Row>
            <Row gutter={[16, 16]} align='middle' justify='space-between'>
              <hr className='byp-hr__quote byp-mb-1 byp-mt-1' />
              <Col span={24}>
                <div className='cotizacion__start-end'>
                  <div style={{ width: '50%' }}>
                    <label>Inicio de Clases</label>
                    <Form.Item
                      labelAlign='left'
                      labelCol={{ span: 7 }}
                      name='start_at'
                    >
                      <Input size='small' className='package-date' style={{ width: '100%' }} disabled />
                    </Form.Item>
                  </div>
                  <div style={{ width: '50%' }}>
                    <label>Termino de Clases</label>
                    <Form.Item
                      labelAlign='left'
                      labelCol={{ span: 7 }}
                      name='end_at'
                    >
                      <Input size='small' className='package-date' style={{ width: '100%' }} disabled />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {/* <i className='byp-mt-1'>Fechas cerradas para grupos especiales</i> */}
              <hr className='byp-hr__quote byp-mb-1 byp-mt-1' />
            </Row>
          </>
        )
      }

      <br />
      <Content>
        <PackageQuoterForm isOpen={isOpen} handleFormatDate={handleFormatDate} />

        <Row gutter={[16, 16]} style={{ marginBottom: '1.25rem' }}>
          <Col md={16}>
            <DiscountText />
            {/* La promoción es valida para inscripciones, hasta el día {handleFormat(prices?.year?.end)}. */}
            <p className='byp-m-0 notice__quote'>
              <span>AVISO:</span> Precios sujetos a cambios sin previo aviso. Aplican restricciones. El hospedaje está sujeto a disponibilidad de lugares. Es
              responsabilidad del estudiante contar con los permisos de entrada al país destion. De acuerdo al país de
              origen se aplican restricciones de Visado, más información <a href='#'>aquí.</a><br />
              Precios en [{userLocalCurrency}] son como referencia sujetos al T.C. a la venta del día que se liquide el programa
            </p>
          </Col>
          <Col xs={24} md={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='cotizacion__total'>
              {userLocalCurrency && (
                <div className='cotizacion-currency'>
                  <span>Aprox.</span>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%' }}>
                    {service.getTotalDiscounts() === 0 &&
                      <Statistic
                        className='cotizacion__total-currency'
                        prefix=''
                        precision={2}
                        value={newdata?.data?.value ? `${handlePrice(service.getSubTotalQuoteAmount(), newdata.data?.value)}` : `${service.getSubTotalQuoteAmount()}`}
                        suffix={newdata?.data?.value ? `${userLocalCurrency}` : `${service.getCurrency()}`}
                      />}
                    {
                      service.getTotalDiscounts() > 0 && (
                        <Statistic
                          prefix=''
                          className='price-after cotizacion__total-currency'
                          precision={2}
                          value={newdata?.data?.value ? `${handlePrice(service.getTotalQuoteAmount(), newdata.data?.value)}` : `${service.getTotalQuoteAmount()}`}
                          suffix={newdata?.data?.value ? `${userLocalCurrency}` : `${service.getCurrency()}`}
                        />
                      )
                    }
                  </div>
                </div>
              )}
            </div>
            <div className='cotizacion__total'>
              <Statistic
                className={service.getTotalDiscounts() > 0 ? 'price-before cotizacion_total-discount' : null}
                prefix=''
                precision={2}
                value={`${service.getSubTotalQuoteAmount()}`}
                suffix={`${service.getCurrency()}`}
              />
              {
                service.getTotalDiscounts() > 0 && (
                  <Statistic
                    prefix=''
                    className='price-after cotizacion_total'
                    precision={2}
                    value={`${service.getTotalQuoteAmount()}`}
                    suffix={`${service.getCurrency()}`}
                  />
                )
              }
            </div>
            {/* <p className='byp-m-0' style={{ alignSelf: 'flex-end' }}>Costo Total</p> */}
          </Col>
        </Row>

        <Row justify='space-between' gutter={12}>
          <Col style={{ display: 'flex' }} className='quotation__footer-btns' xs={24} md={12}>
            <Button onClick={() => setShowModal(true)} loading={isLoadingPost} style={{ marginRight: '1rem' }} className='quote__btn-quote'><ShareAltOutlined /> Compartir</Button>
            <Button loading={isLoadingPost} className='quote__btn-quote'><HeartOutlined /> Añadir a Favoritos</Button>
          </Col>
          <Col xs={24} md={6} xxl={4} className='quotation__marging'>
            {service.userCanApply() && (
              <Button onClick={submit} loading={isLoadingPost} className='quote-apply_now'>Aplicar Ahora</Button>
            )}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <p className='byp-mt-1 byp-mb-1' style={{ fontWeight: '700' }}>¿Quieres verlo en otro divisa?</p>
            <QuoteDivisa
              divisa={service.getCurrency()}
              lastCurrency={service.getTotalQuoteAmount()}
              setExtraCoin={setExtraCoin}
              extraCoin={extraCoin}
            />
          </Col>
        </Row>
      </Content>
      <Modal title='Compartir' visible={showModal} footer='' onCancel={() => setShowModal(false)}>
        <Row>
          <Col span={24} className='info-quote__campus byp-mb-1'>
            <Image
              src={course?.offer?.campus?.profilePhotoUrl ?? image}
              style={{ width: '2.5rem', aspectRatio: '1' }}
              fallback={image}
            />
            {/* <img src={course?.offer?.campus?.profilePhotoUrl ?? image} alt={course?.offer?.campus?.name} style={{ width: '3.75rem', aspectRatio: '1' }} /> */}
            <h4>{course?.offer?.campus?.name}</h4>
          </Col>
          <Col span={24}>
            <p className='quote-byp__mb quote-byp__description'>
              {/* {description?.value ?? course?.offer?.campus?.description} */}
            </p>
          </Col>
          <Col span={24} style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '20px' }}>
            <LinkOutlined style={{ fontSize: '20px' }} />
            <Input
              id='myInput'
              value={`${window.location.href}`} onFocus={handleShare}
              suffix={
                <Button onClick={handleShare} style={{ color: 'var(--blue-100)', border: 'none', marginRight: '-10px' }}>COPY</Button>
              }
            />
          </Col>
          <Col span={24} className='byp-mt-2'>
            <h5 className='text-align-center'>Compartir</h5>
            <Row gutter={[16, 16]} align='middle' justify='center'>
              <Col>
                <a className='d-flex justify-center align-center flex-column' href={handleShareFacebook()} target='_blank' rel='noreferrer'>
                  <Image
                    src={LogoFacebook}
                    style={{ width: '2.5rem', aspectRatio: '1' }}
                    fallback={LogoFacebook}
                    preview={false}
                  />
                  <p>Facebook</p>
                </a>
              </Col>
              <Col>
                <div className='d-flex justify-center align-center flex-column' onClick={handleShareWhatsapp}>
                  <Image
                    src={LogoWhatsapp}
                    style={{ width: '2.5rem', aspectRatio: '1' }}
                    fallback={LogoWhatsapp}
                    preview={false}
                  />
                  <p>WhatsApp</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </PageHeader>
  )
}
