import { Form, Select } from 'antd'
import React, { useEffect } from 'react'
import { useGetAllServiceCategories } from '../../../bussines/hooks/school/useGetAllServiceCategories'
import { programTypes } from '../../../utils/programTypes'

const { Option } = Select
const graduateTypes = programTypes
// This allows the user to choose one of the programs and his subprograms
// If you want choose the programs form the database, use SelectServicesCategoriesFormItem
function SelectProgramsFormItem ({ filters, permitedIds = [], showSearch, loading, isListField, fieldKey, showLabel = false, firstOption = false, setProgramName, isReports, selectProps = {}, formItemProps = {}, ...props }) {
  const { isLoading, data } = useGetAllServiceCategories(filters)

  useEffect(() => {
    if (firstOption && data?.length > 0) {
      const { onChange } = props
      if (data[0].name !== 'graduate') {
        onChange(data[0].name)
        if (setProgramName) {
          setProgramName(data[0].name)
        }
      }
    }
  }, [data])

  return (
    <Form.Item {...props} {...formItemProps}>
      <Select
        {...props}
        {...selectProps}
        loading={isLoading}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {isReports && <Option value={null}>All programs</Option>}
        {!showLabel && data?.map(item => {
          if (item.name !== 'graduate') {
            return <Option disabled={permitedIds.length && !permitedIds.includes(item.id)} key={item.id} value={item.name}>{`${item.label}`}</Option>
          }
          return graduateTypes.map((type) => {
            if (type.subtypes?.length > 0) {
              return type.subtypes?.map((subtype) => (
                <Option disabled={permitedIds.length && !permitedIds.includes(item.id)} key={subtype.value} value={subtype.value}>{`${subtype.label}`}</Option>
              ))
            }
            return <Option disabled={permitedIds.length && !permitedIds.includes(item.id)} key={type.value} value={type.value}>{`${type.label}`}</Option>
          })
        })}
        {showLabel && data?.map(item => {
          return <Option disabled={permitedIds.length && !permitedIds.includes(item.id)} key={item.id} value={item.name}>{showLabel && 'Program '} {`${item.label}`}</Option>
        })}
      </Select>
    </Form.Item>
  )
}

export { SelectProgramsFormItem }
