import { useQuery } from 'react-query'
import UserRepository from '../../repositories/UserRepository'

function useGetNotifications (filters = {}, enabled = true) {
  return useQuery(['useGetNotifications', filters],
    () => UserRepository.getNotifications(filters), {
      retry: 1,
      enabled
    })
}

export { useGetNotifications }
